'use strict';

angular.module('casist.ucto')
  .factory('Ucet', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "ucty";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.ucet) {
            this.addError('ucet', 'Pole účet je povinné.');
            res = false;
          }
          if (!this.nazov) {
            this.addError('nazov', 'Pole názov je povinné.');
            res = false;
          }
          return res;
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ucto/ucty/ucty-detail.html',
        controller: 'UcetDetailCtrl',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'detail-sm',
        resolve: {
          ucet: function() {
            return item ? item : {};
          },
          ucty: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').ucet;
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(ucet, options) {
        var obj = parent.restangularize(angular.extend({ucet: ucet}, options));
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
