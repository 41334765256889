'use strict';

angular.module('casist.widgets.notification', [])
  .factory('Notification', ['$timeout', function ($timeout) {
    var notifs = [];

    return {
      add: function(msg, type, timeout, callback) {
        msg = msg.replace('\n', '<br/>');
        if (!angular.isDefined(type)) {
          type = 'info';
        }
        var notif = {type: type, msg: msg };
        var self = this;
        if (angular.isDefined(callback)) {
          notif.callback = function() {
            callback();
            self.close(notif);
          }
        }
        var idx = notifs.push(notif)-1;
        if (timeout) {
          notif.timer = $timeout(function() {
            var index;
            if ( (index = notifs.indexOf(notif)) !== -1) {
              notifs.splice(index, 1);
            }
          }, timeout*1000);
        }
        return idx;
      },
      notifications: function() {
        return notifs;
      },
      close: function(index) {
        if (angular.isObject(index)) {
          index = notifs.indexOf(index);
        }
        if (angular.isDefined(notifs[index].timer)) {
          $timeout.cancel(notifs[index].timer);
          notifs[index].timer = null;
        }
        notifs.splice(index, 1);
      },
      clear: function() {
        for (var i = notifs.length-1; i >= 0; i--) {
          notifs.splice(i, 1);
        }
      },
      clearSpecificTypes: function(types) {
        for (var i = notifs.length-1; i >= 0; i--) {
          if (types.includes(notifs[i].type)) {
            notifs.splice(i, 1);
          }
        }
      }
    };
  }]);
