'use strict';

angular.module('casist.core')
  .factory('TabService', ['$rootScope', '$translate', '$timeout', 'Profile', '$location', function ($rootScope, $translate, $timeout, Profile, $location) {
    var observerCallbacks = [], openCallbacks = [];
    var activeTab = 0;
    var defaultTab = {title: 'main.NEWTAB', id: 'newtab', content: 'components/navigation/new-tab.html'};
    var tabs = [];
    var modules = [
      {location: 'ucto/pohladavky', id: 'pohladavky', label: 'main.POHLADAVKY', endpoint: 'faktury'},
      {location: 'ucto/zavazky', id: 'zavazky', label: 'main.ZAVAZKY', endpoint: 'zavazky'},
      {location: 'ucto/pokladnicnedoklady', id: 'pokladnicnedoklady', label: 'main.POKLADNICNE_DOKLADY', endpoint: 'pokladna'},
      {location: 'ucto/penaznydennik', id: 'penaznydennik', label: 'main.PENAZNY_DENNIK', endpoint: 'penazny'},
      {location: 'ucto/bankovedoklady', id: 'bankovedoklady', label: 'main.BANKOVE_DOKLADY', endpoint: 'banka'},
      {location: 'ucto/prikazy', id: 'prikazy', label: 'main.PRIKAZY', endpoint: 'prikazy'},
      {location: 'ucto/internedoklady', id: 'internedoklady', label: 'main.INTERNE_DOKLADY', endpoint: 'interne'},
      {location: 'ucto/predkontacie', id: 'predkontacie', label: 'predkontacie.PREDKONTACIE', endpoint: 'predkontacie'},
      {location: 'ucto/hlavnakniha', id: 'hlavnakniha', label: 'main.HLAVNA_KNIHA', endpoint: 'hlavnakniha'},
      {location: 'ucto/uctosnova', id: 'uctosnova', label: 'uctosnova.UCTOSNOVA', endpoint: 'uctosnova'},
      {location: 'ucto/prehlady', id: 'prehlady', label: 'main.PREHLADY'},
      {location: 'ucto/uctdennik', id: 'uctdennik', label: "uctdennik.UCTDENNIK", endpoint: 'uctdennik'},
      {location: 'ucto/rozuctovanie', id: 'rozuctovanie', label: "rozuctovanie.ROZUCTOVANIE", endpoint: 'rozuctovanie'},
      {location: 'ucto/supisdph', id: 'supisdph', label: "supisdph.SUPISDPH", endpoint: 'supisdph'},
      {location: 'ucto/urgencie', id: 'urgencie', label: 'main.URGENCIE', endpoint: 'urgencie'},
      {location: 'sklad/cenniky', id: 'cenniky', label: 'main.CENNIKY', endpoint: 'cennikzaznamy'},
      {location: 'sklad/pozicie', id: 'pozicie', label: 'main.POZICIE', endpoint: 'pozicie'},
      {location: 'sklad/fakturacia', id: 'faktura', label: 'main.FAKTURACIA', endpoint: 'faktury'},
      {location: 'sklad/karty', id: 'karty', label: 'karty.KARTY', endpoint: 'karty'},
      {location: 'sklad/ponuky', id: 'ponuky', label: 'ponuky.PONUKY', endpoint: 'ponuky'},
      {location: 'sklad/zalohovefaktury', id: 'zalohovefaktury', label: 'zalohove.ZALOHOVEFAKTURY', endpoint: 'zalohovefaktury'},
      {location: 'core/firmy', id: 'firma', label: "firmy.ADRESAR_FIRIEM", endpoint: 'firmy'},
      {location: 'core/mailbox', id: 'mailbox', label: "main.MAILBOX", endpoint: 'mailbox'},
      {location: 'sklad/nakup', id: 'nakupy', label: "nakup.NAKUP", endpoint: 'nakup'},
      {location: 'sklad/mnozstva', id: 'mnozstva', label: "mnozstva.MNOZSTVA", endpoint: 'mnozstva'},
      {location: 'sklad/pohyb', id: 'pohyb', label: "pohyb.POHYB", endpoint: 'pohyb'},
      {location: 'sklad/sklady', id: 'sklad', label: "sklad.SKLADY", endpoint: 'sklady'},
      {location: 'svb/priestory', id: 'priestory', label: "svb.PRIESTORY", endpoint: 'priestory', url: '/priestory'},
      {location: 'svb/vlastnici', id: 'vlastnici', label: "svb.VLASTNICI", endpoint: 'vlastnici', url: '/vlastnici'},
      {location: 'svb/odpocty', id: 'odpocty', label: "svb.ODPOCTY_MERACOV", endpoint: 'odpocty', url: '/odpocty'},
      {location: 'svb/vyuctovanie', id: 'vyuctovanie', label: "svb.VYUCTOVANIE", endpoint: 'vyuctovanie', url: '/vyuctovanie'},
      {location: 'svb/prehlad', id: 'prehladnakladov', label: "svb.PREHLAD_NAKLADOV", endpoint: 'prehlad-nakladov'},
      {location: 'ubytovanie/ubytovanie', id: 'ubytovanie', label: "ubytovanie.UBYTOVANIE", endpoint: 'ubytovanie'},
      {location: 'ubytovanie/nastenka', id: 'nastenka', label: "Nástenka ubytovania", endpoint: 'ubytovanie'},
      {location: 'ubytovanie/izby', id: 'izby', label: "ubytovanie.IZBY", endpoint: 'ubytovanie/izby'}
    ];
    var busData = [];

    var notifyObservers = function() {
      angular.forEach(observerCallbacks, function(callback) {
        callback();
      });
    };

    var closeTabbedWindow = function(index) {
      tabs.splice(index, 1);
      notifyObservers();
      if (tabs.length == 0)
        tabs.push(defaultTab);
      return tabs;
    };

    var parseUrl = function(url) {
      var module = '';
      var section = url;
      if (url.match('/')) {
        var splitted = url.split('/');
        module = splitted[0]+'/';
        section = splitted[1];
      }
      return {module: module, section: section};
    };

    /**
     * Open new tab window
     * If opened with 4 arguments, the url is considered already parsed
     * @param  string id    [description]
     * @param  string title title of the window
     * @param  string url module/section, or direct html path
     * @return array list of tabs
     */
    var openTabbedWindow = function(id, title, url) {
      var content = url;
      var active = true;
      if (!title || !url) {
        var item = _.find(modules, {id: id});
        if (item) {
          title = item.label;
          url = item.location;
        } else {
          return;
        }
      }
      if (angular.isDefined(arguments[3])) {
        active = arguments[3];
      } else {
        var parsed = parseUrl(url);
        var module = parsed.module;
        var section = parsed.section;
        content = module+section+'/'+section+'.html';
      }
      var existing = _.find(tabs, {id: id});
      if (existing) {
        tabs.splice(tabs.indexOf(existing), 1);
      }
      var idx = tabs.push(angular.copy(_.find(modules, {id: id})))-1;
      tabs[idx].active = active;
      tabs[idx].title = tabs[idx].label;
      var parsed = parseUrl(url);
      var module = parsed.module;
      var section = parsed.section;
      tabs[idx].content = module+section+'/'+section+'.html';

      return tabs;
    };

    var closeAll = function() {
      tabs = [defaultTab];
    };

    var deregisterTabWatchers = function(tabID) {
      var tab = tabs[tabID];
      if (!tab || !tab.scope || tab.id === 'newtab') {
        return;
      }
      var watchers = {};
      var q = [tab.scope], scope, count = 0;
      while (q.length > 0) {
          scope = q.pop();
          if (scope.$$watchers) {
            watchers[scope.$id] = scope.$$watchers;
            count += scope.$$watchers.length;
            scope.$$watchers = [];
          }
          if (scope.$$childHead) {
            q.push(scope.$$childHead);
          }
          if (scope.$$nextSibling) {
            q.push(scope.$$nextSibling);
          }
      }
      tab.watchers = watchers;
      // tab.missed = [];
    };
    var registerTabWatchers = function(tabID) {
      var tab = tabs[tabID];
      if (!tab || !tab.scope || !tab.watchers || tab.id === 'newtab') {
        return;
      }
      var q = [tab.scope], scope;
      while (q.length > 0) {
        scope = q.pop();
        if (tab.watchers[scope.$id]) {
          scope.$$watchers = tab.watchers[scope.$id];
          delete tab.watchers[scope.$id];
        }
        if (scope.$$childHead) {
          q.push(scope.$$childHead);
        }
        if (scope.$$nextSibling) {
          q.push(scope.$$nextSibling);
        }
      }
      // Not necessary - handled automatically by reassigning watchers
      // if (tab.missed.length) {
      //   console.log('firing '+tab.missed.length+' missed events for '+tab.id);
      //   for (var i = 0; i < tab.missed.length; i++) {
      //     tab.missed[i]();
      //   }
      // }
    };

    return {
      registerObserverCallback: function (callback) {
        observerCallbacks.push(callback);
      },
      onTabOpen: function(id, callback) {
        openCallbacks.push({id: id, callback: callback});
      },
      openTab: function (id, title, url) {
        if (url) {
          $location.url(url);
        }
        return openTabbedWindow(id, title);
      },
      newTab: function() {
        var newtab = angular.copy(defaultTab);
        newtab.active = true;
        tabs.push(newtab);
        $('.nav-tabs').animate({
          scrollLeft: 5000
        }, 'slow');

        return tabs;
      },
      setTabContent: function (id, title, url) {
        /*var i = -1;
        var alreadyOpened = i;
        for (var t in tabs) {
          i++;
          tabs[t].active = false;
          if (tabs[t].title == title)
            alreadyOpened = i;
        }
        if (alreadyOpened > -1) {
          tabs[alreadyOpened].active = true;
          tabs.splice(activeTab, 1);
        } else {
          var parsed = parseUrl(url);
          var module = parsed.module;
          var section = parsed.section;
          tabs[activeTab] = {'id': id, 'title': title, 'content': module+section+'/'+section+'.html', active: true};
          notifyObservers();
        }*/
        var alreadyOpened = _.find(tabs, {id: id});
        if (alreadyOpened) {
          alreadyOpened.active = true;
          tabs.splice(activeTab, 1);
        } else {
          tabs[activeTab] = angular.copy(_.find(modules, {id: id}));
          tabs[activeTab].active = true;
          tabs[activeTab].title = tabs[activeTab].label;
          var parsed = parseUrl(url);
          var module = parsed.module;
          var section = parsed.section;
          tabs[activeTab].content = module+section+'/'+section+'.html';
          notifyObservers();
        }
        return tabs;
      },
      closeTab: function(index) {
        delete tabs[index].lastLocation;
        return closeTabbedWindow(index);
      },
      getTabs: function() {
        return tabs;
      },
      registerScope: function(id, scope) {
        var tab = _.find(tabs, {id: id});
        if (tab && !tab.scope) {
          tab.scope = scope;
          if (!tab.active) {
            $timeout(function() {
              deregisterTabWatchers(tabs.indexOf(tab));
            });
          }
        }
      },
      tabActivated: function (tab) {
        if (activeTab >= 0 && activeTab < tabs.length && $location.url().match('^'+tabs[activeTab].url)) {
          tabs[activeTab].lastLocation = $location.url();
        }
        deregisterTabWatchers(activeTab);
        activeTab = tab;
        registerTabWatchers(activeTab);
        notifyObservers();
        if (tabs[tab]) {
          angular.forEach(_.filter(openCallbacks, {id: tabs[tab].id}), function(callback) {
            callback.callback();
          });
        }
        if (tabs[tab] && tabs[tab].url && !$location.url().match('^'+tabs[tab].url)) {
          $location.url(tabs[tab].lastLocation || tabs[tab].url+'/list');
        }
        $timeout(function() {
          var lis = $('.nav-tabs li.active');
          for (var i = 0; i < lis.length; i++) {
            $('.nav-tabs').animate({
              scrollLeft: lis[i].offsetLeft-100
            }, 400);
            break;
          }
        });
      },
      getActiveTab: function() {
        return tabs[activeTab];
      },
      getTabsByEndpoint: function(endpoint) {
        return _.filter(tabs, {endpoint: endpoint});
      },
      loadWorkspace: function(workspace) {
        tabs = [];
        for (var w in workspace) {
          if (workspace[w].window_id !== 'newtab') {
            openTabbedWindow(workspace[w].window_id, workspace[w].title, workspace[w].content, workspace[w].active);
            if (workspace[w].active) {
              activeTab = w;
            }
          }
        }
        if (!tabs.length){
          tabs = [defaultTab];
        }
        // save to persistent session storage
        this.saveWorkspace();
        return tabs;
      },
      saveWorkspace: function() {
        var ws = _.map(this.getTabs(), function(obj) { return {active: obj.active, window_id: obj.id, content: obj.content, title: obj.title }; });
        Profile.set('workspace', ws, true);
        Profile.save();
        return ws;
      },

      bus: {
        send: function(receiver, sender, data) {
          busData.push({receiver: receiver, sender: sender, data: data});
        },
        get: function(id) {
          var data = angular.copy(_.filter(busData, {receiver: id}));
          busData = _.reject(busData, function(obj) { return obj.receiver === id; });
          return data;
        }
      }
    };
  }]);
