'use strict';

angular.module('casist.ubytovanie')
  .factory('Izba', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Upload', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Upload) {
    var ENDPOINT = "izby";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.izba) {
            this.addError('izba', 'Názov izby musí byť vyplnený.');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ubytovanie/izby/izby-detail.html',
        controller: 'IzbaDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object('ubytovanie/'+ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').izba;
      },
      getImportConfig: function() {
        return Restangular.all('ubytovanie/'+ENDPOINT).all('import').customGET();
      },
      import: function($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all('ubytovanie/'+ENDPOINT).all('import').getRestangularUrl(),
          data: {"fileName" : file.name, 'config': JSON.stringify(config)},
          file: file
        }).progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        }).then(function(data) {
          deferred.resolve(data.data);
        }, function (error) {
          deferred.reject(error);
        });
        return deferred.promise;
      },
      importData: function(data) {
        return Restangular.all('ubytovanie/'+ENDPOINT).all('import').post(data, {import: 1});
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(izba, otherParams) {
        var obj = parent.restangularize(angular.extend({izba: izba}, otherParams));
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query, otherParams) {
        return parent.objects().getList(angular.extend({search:query}, otherParams));
      }
    });
  }]);
