'use strict';

angular.module('casist.core', [
  'restangular',
  'casist.utils',
  'casist.search',
  'casist.auth',
  'tc.chartjs',
  'pascalprecht.translate',
  'ngStorage'
]);