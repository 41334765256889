'use strict';

angular.module('casist.utils')
  .filter('checkmark', function() {
    return function(input) {
      if (input === true || input === 'true' || input === 1) {
        return '<i class="fa fa-check text-success"></i>';
      } else {
        return '<i class="fa fa-times text-danger"></i>';
      }
    }
  });