'use strict';

angular.module('casist.sklad')
  .constant('VariantOptionTypes', [
      {id: 'farba', name: 'Farba'},
      {id: 'velkost', name: 'Veľkosť'},
    ])
angular.module('casist.sklad')
  .constant('EshopLabels', [
      {id: 'news', name: 'Nové produkty'},
      {id: 'promotions', name: 'Akčné ponuky'},
    ]);
angular.module('casist.sklad')
  .factory('Karta', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Upload', '$filter', '$http', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Upload, $filter, $http, Dialog) {
    var ENDPOINT = "karty";

    var getSposob = function(id) {
      var SPOSOBY_ROZUCTOVANIA = [
        {'id': 1, 'sposob': 'Nerozúčtovať'},
        {'id': 2, 'sposob': 'Pomerom meračov SV'},
        {'id': 3, 'sposob': 'Pomerom meračov TV'},
        {'id': 4, 'sposob': 'Pomerom meračov UK'},
        {'id': 5, 'sposob': 'Rovnako na byt/NP'},
        {'id': 6, 'sposob': 'Spoluvlastníckym podielom'},
        {'id': 7, 'sposob': 'Podielom celkovej plochy'},
        {'id': 8, 'sposob': 'Podielom vykurovacej plochy'},
        {'id': 9, 'sposob': 'Podielom vykurovacieho objemu'},
        {'id': 10, 'sposob': 'Osobodni'},
        {'id': 11, 'sposob': 'Výpočtom'}
      ];
      if (angular.isDefined(id)) {
        return _.find(SPOSOBY_ROZUCTOVANIA, {id: id});
      } else {
        return SPOSOBY_ROZUCTOVANIA;
      }
    };

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.kod) {
            this.addError('kod', 'Kód musí byť vyplnený.');
            return false;
          }
          if (!this.nazov) {
            this.addError('nazov', 'Názov musí byť vyplnený.');
            return false;
          }
          return BaseModel.validate();
        },
        getSposob: function() {
          return getSposob(this.sposob_rozuctovania);
        },
        mnozstvo: function() {
          if (!this.mnozstva || !this.mnozstva.length) {
            return 0;
          }
          var mnozstvo = 0;
          for (var i = 0; i < this.mnozstva.length; i++) {
            mnozstvo += parseFloat(this.mnozstva[i].mnozstvo);
          }
          return mnozstvo;
        },
        countZisk: function() {
          var self = this;
          var result = {};
          result.zisk = parseFloat(self.mo) - parseFloat(self.cena_sklad);
          if (self.cena_sklad) {
            result.marzaVO = $filter('suma')(((parseFloat(self.vo) / parseFloat(self.cena_sklad) - 1) * 100.0), 2) + ' %';
            result.marza = $filter('suma')(((parseFloat(self.mo) / parseFloat(self.cena_sklad) - 1) * 100.0), 2) + ' %';
          } else {
            result.marzaVO = '!';
            result.marza = '!';
          }
          return result;
        },
        countSums: function(evt) {
          if (this[evt.target.id] === 0) {
            return;
          }
          var self = this;
          var desatiny_part = String(self[evt.target.id]).split('.')[1];
          var desatiny = desatiny_part ? desatiny_part.length : 2;
          if (desatiny < 2) {
            desatiny = 2;
          }
          if (!self.dph) {
            return;
          }
          var cena_sdph = Math.round( (self.mo * ((parseInt(self.dph)+100)/100.0)) * Math.pow(10, desatiny)) / Math.pow(10, desatiny);
          var cena_bezdph = Math.round( (self.modph / ((parseInt(self.dph)+100)/100.0)) * Math.pow(10, desatiny)) / Math.pow(10, desatiny);

          var cenaCZ_sdph = Math.round( (self.mo_cz * ((parseInt(self.dph)+100)/100.0)) * Math.pow(10, desatiny)) / Math.pow(10, desatiny);
          var cenaCZ_bezdph = Math.round( (self.modph_cz / ((parseInt(self.dph)+100)/100.0)) * Math.pow(10, desatiny)) / Math.pow(10, desatiny);

          if (evt.target.id === 'dph') {
            if (self.mo) {
              self.modph = cena_sdph;
            } else if (self.modph) {
              self.mo = cena_bezdph;
            }
            if (self.mo_cz) {
              self.modph_cz = cenaCZ_sdph;
            } else if (self.modph) {
              self.mo_cz = cenaCZ_bezdph;
            }
          } else if (evt.target.id === 'mo') {
            self.modph = cena_sdph;
          } else if (evt.target.id === 'mo_cz') {
            self.modph_cz = cenaCZ_sdph;
          } else if (evt.target.id === 'modph_cz') {
            self.mo_cz = cenaCZ_bezdph;
          } else {
            self.mo = cena_bezdph;
          }
          self.countZisk();
        },
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'sklad/karty/karty-detail.html',
        controller: 'KartyDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          karta: function() {
            return item ? item : {};
          },
          karty: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      getSposob: function(id) {
        return getSposob(id);
      },
      getImportConfig: function() {
        return Restangular.all(ENDPOINT).all('import').customGET();
      },
      import: function($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all('import').getRestangularUrl(),
          data: {"fileName" : file.name, 'config': JSON.stringify(config)},
          file: file
        }).progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        }).then(function(data) {
          deferred.resolve(data.data);
        }, function (error) {
          deferred.reject(error);
        });
        return deferred.promise;
      },

      uploadImage: function (file, karta) {
        return Upload.upload({
          url: Restangular.all(ENDPOINT).all('image').getRestangularUrl(),
          data: { karta: karta.id },
          file: file,
          headers: {
            Authorization: $http.defaults.headers.common.Authorization,
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          },
        });
      },

      kartyMediaImport: function (file) {
        return Upload.upload({
          url: Restangular.all(ENDPOINT).all('import').all('media').getRestangularUrl(),
          file: file
        });
      },

      switchFields: function () {
        return Restangular.all(ENDPOINT).all('switchfields').customPOST();
      },
      removeImage: function (imageId) {
        return Upload.upload({
          method: 'delete',
          url: Restangular.all(ENDPOINT).all('image').getRestangularUrl(),
          data: { image: imageId },
          headers: {
            Authorization: $http.defaults.headers.common.Authorization,
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          },
        });
      },
      importData: function(data) {
        return Restangular.all(ENDPOINT).all('import').post(data, {import: 1});
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(kod, options) {
        var obj = parent.restangularize(angular.extend({kod: kod}, options));
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query, page, parentObject, otherParams) {
        return parent.objects().getList(angular.extend(angular.extend({search:query}, otherParams), page));
      },
      getCennikItems: function (karta) {
        return Restangular.all('cennikzaznamy').customGETLIST("", {karta: karta.id});
      },
      displayImage: function (karta, karty, searchCtrl) {
        $modal.open({
          templateUrl: 'core/components/image.html',
          controller: 'KartyDetailCtrl',
          backdrop: 'static',
          windowClass: 'detail',
          resolve: {
            karta: function() {
              return karta ? karta : {};
            },
            karty: function() {
              return karty || [];
            },
            SearchCtrl: function() {
              return searchCtrl;
            }
          }
        });
      }
    });
  }])
  .factory('KartaTyp', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "kartatypy";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var openDetail = function(list) {
      var modalInstance = $modal.open({
        templateUrl: 'sklad/karty/typy/typy.html',
        controller: 'KartaTypyCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        resolve: {
          list: function() {
            return list;
          }
        }
      });
      return modalInstance.result;
    };

    var parent = BaseCollectionModel.object('karty/kartatypy');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      },
      edit: function(list) {
        var promise = $q.defer();
        openDetail(list).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      }
    });
  }]).factory('Skupina', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Dialog) {
    var ENDPOINT = "kartaskupiny";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('karty/kartaskupiny');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      edit: function (skupina) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Skupina', 'Upraviť skupinu', skupina.nazov).then(function(str) {
          skupina.nazov = str;
          skupina.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(skupina) {
        return parent.create({nazov: skupina});
      },
      remove: function (skupina) {
        return skupina.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]).factory('Podskupina', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Dialog) {
    var ENDPOINT = "kartapodskupiny";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('karty/kartapodskupiny');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      edit: function (podskupina) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Podkupina', 'Upraviť podskupinu', podskupina.nazov).then(function(str) {
          podskupina.nazov = str;
          podskupina.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(podskupina, otherData) {
        return parent.create(angular.extend({nazov: podskupina}, otherData));
      },
      remove: function (podskupina) {
        return podskupina.remove();
      },
      get: function (query, otherParams) {
        return parent.objects().getList(angular.extend({search:query}, otherParams));
      }
    });
  }]).factory('Druh', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Dialog) {
    var ENDPOINT = "kartadruhy";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('karty/kartadruhy');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      edit: function (druh) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Druh', 'Upraviť druh', druh.nazov).then(function(str) {
          druh.nazov = str;
          druh.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(druh) {
        return parent.create({nazov: druh});
      },
      remove: function (druh) {
        return druh.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]).factory('Vyrobca', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "vyrobcovia";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('karty/vyrobcovia');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').karta;
      },
      edit: function (vyrobca) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Výrobca', 'Upraviť výrobcu', vyrobca.firma).then(function(str) {
          vyrobca.firma = str;
          vyrobca.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(vyrobca) {
        return parent.create({firma: vyrobca});
      },
      remove: function (vyrobca) {
        return vyrobca.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
