'use strict';

angular.module('casist.svb')
  .controller('PriestorVlastnikCtrl', ['$scope', '$stateParams', '$state', 'vlastnici', 'priestory', 'PriestorVlastnik', '$controller', 'Priestor', 'Notification', 'Dialog', 'Osoba', '$timeout', 'Karta', 'priestor', 'Firma', 'Priestor', 'vlastnik',
    function($scope, $stateParams, $state, vlastnici, priestory, Vlastnik, $controller, Priestor, Notification, Dialog, Osoba, $timeout, Karta, priestor, Firma, Profile, vlastnik) {
      //var vlastnik = _.find(vlastnici, {id: parseInt($stateParams.vlastnikID)});
      var defaultVlastnik = {sluzby: []};
      if (vlastnik) {
        $scope.vlastnik = Vlastnik.copy(vlastnik);
        $scope.osoby = Osoba.copy(vlastnik.osoby);
      } else {
        $scope.vlastnik = angular.copy(defaultVlastnik);
        $scope.osoby = [];
      }
      $scope.rokNaklady = parseInt(Profile.get('obdobie', new Date().getFullYear())) - 1;
      $scope.priestor = angular.copy(priestor);
      $scope.Firma = Firma;
      $scope.breadcrumb = [{title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.dom_data.nazov : '---', state: 'default.priestory.list', params: {}}, {title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.nazov : '---', state: 'default.priestory.list', params: {}}, {title: $scope.priestor.nazov, state: 'default.priestory.detail.summary', params: {'priestorID': $scope.priestor.id}}, {title: vlastnik ? $scope.vlastnik.meno : 'Nový vlastník', state: 'default.priestory.detail.vlastnik', params: {'priestorID': $scope.priestor.id, 'vlastnikID': $scope.vlastnik.id}}];
      $scope.isEdit = ($state.current.name !== 'default.priestory.detail.addVlastnik');

      $scope.goToBreadcrumb = function(item) {
        $state.go(item.state, angular.extend(item.params, {page: $stateParams.page}), {reload: $scope.priestoryList.page != $stateParams.page});
      };
      $scope.goBack = function() {
        $scope.goToBreadcrumb($scope.breadcrumb[2]);
      };
      $scope.sposobyRozuctovania = Karta.getSposob();

      $scope.save = function() {
        $scope.RecordCtrl.main.save().then(function(data) {
          //$scope.priestor.save().then(function(data) {
          if (data.type === 'created') {
            // toto by slo aj s location: true bez reloadu, ale ak to chce vlozit na dalsiu stranku, tak to handler to neprida do zoznamu a tym padom sa na to neda nastavit... istejsi je reload
            //$state.go('default.priestory.detail.summary', {page: $scope.priestoryList.page, priestorID: $scope.priestor.id}, {reload: true});
            $state.go('default.priestory.detail.addVlastnik', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page}, {reload: true});
          } else {
            $scope.goBack();
          }
          Notification.add('Vlastník bol uložený.', 'success', 5);
        });
      };
      $scope.cancel = function() {
        detectAndSaveChanges(vlastnik ? vlastnik.id : null, $scope.goBack);
      };
      $scope.remove = function() {
        var idx = vlastnici.indexOf(vlastnik)-1;
        var removalHandler = null;
        if (idx < 0 && vlastnici.length > 1) {
          idx = 1;
        }
        if (idx < 0) {
          removalHandler = function() {
            $state.go('default.priestory.detail.addVlastnik', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page}, {reload: true});
          }
        } else {
          var noveID = vlastnici[idx].id;
          removalHandler = function() {
            $state.go('default.priestory.detail.vlastnik', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page, vlastnikID: noveID}, {reload: true});
          }
        }
        $scope.RecordCtrl.main.remove().then(function() {
          removalHandler();
        });
      };

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        Model: Vlastnik.parent($scope.priestor)
      });
      $scope.RecordCtrl.main.init({
        endpoint: 'vlastnici',
        model: 'vlastnik',
        master: vlastnik,
        list: $scope.vlastnici,
        defaultItem: defaultVlastnik,
        preSave: function(obj) {
          obj.priestor = $scope.priestor.id;
          return obj;
        }
      });
      $scope.RecordCtrl.related.init({
        model: 'osoby',
        lookup: 'vlastnik',
        object: Osoba,
        // preSave: pohybPreSave,
        postSave: function(obj, created) {
          if (created) {
            $scope.cancelAddOsoba();
          }
        }
        // preEdit: pohybPreEdit,
        //postDelete: pohybPostSave,
        //searchCtrl: pohybSearchCtrl
      });
      $scope.newOsoba = null;
      $scope.addOsoba = function() {
        $scope.newOsoba = {vlastnik: $scope.vlastnik.id};
        $timeout(function() {
          $('#newOsoba_first').focus();
        });
      };
      $scope.cancelAddOsoba = function() {
        $scope.newOsoba = null;
      };
      $scope.sumy = {
        zaloha: 0,
        prepocitana: 0
      };
      $scope.recountSums = function() {
        $scope.sumy.zaloha = 0;
        $scope.sumy.prepocitana = 0;
        for (var i = 0; i < $scope.vlastnik.sluzby.length; i++) {
          $scope.sumy.zaloha += parseFloat($scope.vlastnik.sluzby[i].zaloha || 0);
          $scope.sumy.prepocitana += parseFloat($scope.vlastnik.sluzby[i].prepocitana || 0);
        }
      };
      $scope.recountSums();

      var isRecordChanged = function() {
        if ($scope.RecordCtrl.hasRelatedChanged()) {
          return true;
        }
        if ($scope.vlastnik.id) {
          return !objEquals(Vlastnik.stripRestangular(vlastnik), Vlastnik.stripRestangular($scope.vlastnik));
        } else {
          return !objEquals(defaultVlastnik, Vlastnik.stripRestangular($scope.vlastnik));
        }
      };
      var setRecord = function(id) {
        $state.go('default.priestory.detail.vlastnik', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page, vlastnikID: id});
      };
      var detectAndSaveChanges = function(id, handler) {
        if (isRecordChanged()) {
          Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
            if (!$scope.vlastnik.id) {
              $scope.save();
            } else {
              var promise = $scope.RecordCtrl.main.save(true);
              if (promise) {
                promise.then(function (success) {
                  if (handler) {
                    handler(id);
                  } else {
                    setRecord(id);
                  }
                }, function (error) {
                  Dialog.alert(error, 'Chyba').then(function () {
                    //focusedElement.focus();
                  });
                });
              }
            }
          }, function() {
            if (handler) {
              handler(id);
            } else {
              setRecord(id);
            }
          });
        } else {
          if (handler) {
            handler(id);
          } else {
            setRecord(id);
          }
        }
      };
      $scope.changePriestor = function(item) {
        detectAndSaveChanges(null, function() {
          $state.go('default.priestory.detail.summary', {priestorID: item.id, page: $scope.priestoryList.page});
        });
      };
      $scope.addPriestor = function() {
        detectAndSaveChanges(null, function() {
          $state.go('default.priestory.add');
        });
      };
      $scope.nextWindowRecord = function(index) {
        var list = vlastnici;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find(vlastnici, {id: parseInt($stateParams.vlastnikID)}));
        }
        var nextItem = null;
        if (index !== -1) {
          nextItem = list.length > index-1 ? list[index+1] : null;
        }
        if (!nextItem) {
          detectAndSaveChanges(null, function() {
            $state.go('default.priestory.detail.addVlastnik');
          });
        } else {
          detectAndSaveChanges(nextItem.id);
        }
      };
      $scope.prevWindowRecord = function(index) {
        var list = vlastnici;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find(vlastnici, {id: parseInt($stateParams.vlastnikID)}));
        }
        if (!vlastnik && list.length) {
          index = list.length;
        }
        var prevItem = null;
        if (index !== -1) {
          prevItem = list.length > 0 ? list[index-1] : null;
        }
        if (prevItem) {
          detectAndSaveChanges(prevItem.id);
        }
      };
    }
  ]);
