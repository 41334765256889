'use strict';

angular.module('casist.ubytovanie')
  .controller('UbytovanieCtrl', ['$scope', 'Restangular', 'Ubytovanie', 'Profile', '$controller', '$q', 'TabService', 'Outputs', 'Karta', 'Firma', 'UbytovanieOsoba', '$modal', 'Notification', 'Faktura', 'Dialog', 'Socket', 'Global', '$timeout', 'DokladTypy', 'PokladnicnyDoklad', '$filter', function ($scope, Restangular, Ubytovanie, Profile, $controller, $q, TabService, Outputs, Karta, Firma, UbytovanieOsoba, $modal, Notification, Faktura, Dialog, Socket, Global, $timeout, DokladTypy, PokladnicnyDoklad, $filter) {
    $scope.SearchCtrl = $controller('SearchCtrl');

    var getDefaultPeriod = function() {
      return {
        'prichod__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
        'prichod__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
      }
    };
    var defaultPeriod = getDefaultPeriod();
    $scope.ubytovanie = [];
    $scope.otherFilters = {};
    var filterChanged = function(filter) {
      filter = angular.extend(filter, $scope.otherFilters);
      $scope.jeFilterOsoba = angular.isDefined(filter.osoba);
      var promise = Ubytovanie.objects().getList(filter);
      $scope.ubytovanie.loading = true;
      promise.then(function(data) {
        TabService.registerScope('ubytovanie', $scope);
        $scope.ubytovanie = data;

        if ($scope.selectedAll) {
          for (var i = 0; i < $scope.ubytovanie.length; i++) {
            $scope.ubytovanie[i].selected = true;
          }
        } else {
          var ubytovanie;
          _.every($scope.selectedItems, function (obj) {
            if ((ubytovanie = _.find($scope.ubytovanie, {id: obj.id}))) {
              $scope.selectedItems[obj.id] = ubytovanie;
              $scope.selectedItems[obj.id].selected = true;
            }
          });
        }
      });
      return promise;
    };
    $scope.filterUkoncenie = function() {
      if ($scope.otherFilters.ukoncenie_pobytu) {
        delete $scope.otherFilters.ukoncenie_pobytu;
        $scope.SearchCtrl.setLastPage(true);
      } else {
        Dialog.prompt('Zadajte dátum:', 'Ukončenie pobytu', getCurrentDate(), {type: 'date'}).then(function(datum) {
          $scope.otherFilters.ukoncenie_pobytu = datum;
          $scope.SearchCtrl.setLastPage(true);
        });
      }
    };
    $scope.UbytovanieOsoba = UbytovanieOsoba;
    $scope.Firma = Firma;
    $scope.Karta = Karta;
    $scope.rezervacieData = [
      {id: 1, rezervacia: 'Rezervované'},
      {id: 2, rezervacia: 'Nerezervované'},
      {id: 3, rezervacia: 'Dnes končiace rezervácie'}
    ];
    $scope.nevyuctovaneData = [
      {id: 1, nevyuctovane: 'Nevyúčtované všetky'},
      {id: 2, nevyuctovane: 'Nevyúčtované hotovosť'},
      {id: 3, nevyuctovane: 'Nevyúčtované faktúra'}
    ];
    var loadPrevadzky = function() {
      $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
      $scope.prevadzky.splice(0, 0, {'id': _.map(_.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id}), prevadzka => prevadzka.id).join(','), 'prevadzka': 'Všetky prevádzky'});
    };
    loadPrevadzky();

    $scope.ubytovanieTable = [
      { },
      { selectAllAddon: true },
      { field: 'iddokd', type: 'int', label: 'Doklad', filterCollapse: 'right' },
      { field: 'osoba', sortField: 'osoba__meno', label: 'Meno', linked: { source: 'UbytovanieOsoba.get(query, pagination)', template: 'osoby-selection', lookup: 'meno', style: "{width: '400px'}", editable: false, paginateBy: 10 } },
      { field: 'osoba__rodne_cislo', sortField: 'osoba__rodne_cislo', label: 'Rodné číslo', linked: { source: 'UbytovanieOsoba.get(query, pagination)', template: 'osoby-selection', lookup: 'rodne_cislo', style: "{width: '400px'}", editable: false, paginateBy: 10 } },
      { field: 'osoba__biometric_card_id', label: 'Č. karty', },
      { field: 'prichod', label: 'Príchod', type: 'date' },
      { field: 'odchod', label: 'Odchod', type: 'date'},
      { field: 'izba', label: 'Izba'},
      { field: 'sluzba', label: 'Služba', linked: { source: 'Karta.get(query)', template: 'karty-selection', lookup: 'nazov', style: "{width: '400px'}", editable: false }},
      { field: 'pocet', label: 'Počet', type: 'number', sum: true},
      { field: 'cena', label: 'Cena/MJ', type: 'number'},
      { field: 'spolu', label: 'Suma s DPH', type: 'number', sum: true},
      { field: 'dan_ubytovania', label: 'Daň', type: 'number', sum: true},
      { field: 'poznamka', label: 'Poznámka' },
      { field: 'firma', sortField: 'firma__firma', label: 'Firma', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}", editable: false } },
      { field: 'faktura_field', type: 'int', label: 'Vyúčtované'},
      { field: 'pp_od', label: 'Nep. daň od', type: 'date'},
      { field: 'pp_do', label: 'Nep. daň do', type: 'date'},
      { field: 'je_cudzinec', sortField: 'cudzinec', icon: '<i class="fa fa-globe"></i>', placeholder: 'Cudzinec', tooltip: 'Cudzinec', filterCollapse: 'left'},
      { field: 'je_odidenec', sortField: 'odidenec', icon: '<i class="fa fa-pagelines"></i>', placeholder: 'Odídenec', tooltip: 'Odídenec', filterCollapse: 'left'},
      { field: 'je_znizena_dan', sortField: 'znizena_dan', icon: '<i class="fa fa-hand-o-down"></i>', placeholder: 'Znížená daň', tooltip: 'Znížená daň', filterCollapse: 'left'},
      { field: 'je_dlhodobo', sortField: 'dlhodobo', icon: '<i class="fa fa-calendar-check-o"></i>', placeholder: 'Dlhodobo', tooltip: 'Dlhodobo', filterCollapse: 'left'},
      { field: 'je_fakturacia', sortField: 'fakturacia', icon: '<i class="fa fa-file-text-o"></i>', placeholder: 'Fakturácia', tooltip: 'Fakturácia', filterCollapse: 'left'},
      { field: 'je_active_in_biometric', sortField: 'je_active_in_biometric', icon: '<i class="fa fa-address-card"></i>', placeholder: 'Biometric', tooltip: 'Je aktívny v systéme Biomectric', filterCollapse: 'left'}
    ];
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.ubytovanieTable,
      defaultPeriod: defaultPeriod,
      defaultFilter: {
        'prevadzka': _.map(_.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id}), prevadzka => prevadzka.id).join(',')
      },
      sort: {sort: 'iddokd', reverse: false},
      scope: $scope
    });
    var unregisterObservers = [];
    unregisterObservers.push(Profile.addObserver('obdobie', function(obdobie) {
      defaultPeriod = getDefaultPeriod();
      $scope.SearchCtrl.setDefaultPeriod(defaultPeriod);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));
    $scope.SearchCtrl.refresh(TabService.bus.get('ubytovanie'));

    var events = Ubytovanie.registerEvents(function() { return $scope.ubytovanie; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Ubytovanie.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    const prenosFinished = function(data) {
      Notification.clearSpecificTypes(['info']);

      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
      $scope.SearchCtrl.broadcastRefresh('ubytovanie');

      if (data.error) {
        Notification.add(data.error, 'danger');
      } else if (data.success) {
        Notification.add(data.success, 'success', 10);
      }
    }
    events.push(Socket.on("prenosFinished", prenosFinished));

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.create = function(item) {
      return Ubytovanie.edit(item, $scope.ubytovanie, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
      var copied = Ubytovanie.copy(item);
      _.each(['id', 'd_vyst', 'd_zdp', 'prichod', 'odchod', 'pocet', 'spolu', 'dan_ubytovania', 'locked'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };
    $scope.prenos = function() {
      $modal.open({
        templateUrl: 'ubytovanie/ubytovanie/prenos.html',
        controller: 'PrenosDlgCtrl',
        backdrop: 'static',
        windowClass: 'detail-sm'
      }).result.then(function(data) {
          Notification.add('Prosím počkajte, kým sa prenos ukončí...', 'info');

          Ubytovanie.prenos(data, $scope.SearchCtrl.getQuery())
          .then(function(created) {})
          .catch(function (error) {
            Notification.clear();
            if (error.status >= 400 && error.status < 500) {
              Notification.add(error.data, 'danger', 10);
            } else if (error.status >= 500) {
              Notification.add('Pri prenose nastala chyba.', 'danger', 5);
            }
          }
        );

      });
    };
    $scope.fakturacia = function() {
      $modal.open({
        templateUrl: 'ubytovanie/ubytovanie/fakturacia.html',
        controller: 'FakturaciaDlgCtrl',
        backdrop: 'static',
        windowClass: 'detail-sm'
      }).result.then(function(data) {
        Ubytovanie.fakturacia(data, $scope.SearchCtrl.getQuery()).then(function(created) {
          for (var i = 0; i < created.length; i++) {
            Faktura.syncCreate(created[i]);
          }
          $scope.SearchCtrl.refresh();
          TabService.bus.send('faktura', 'ubytovanie');
          TabService.openTab('faktura');
        })
      });
    };

    $scope.printPrehlad = function() {
      Dialog.prompt('Prehľad ubytovania ku dátumu', 'Tlač prehľadu ubytovania', getCurrentDate(), {type: 'date'}).then(function(date) {
        Outputs.openPrintUrl('ubytovanie', undefined, angular.extend({datum_ku: date}, $scope.SearchCtrl.getQuery()));
      });
    };
    $scope.printVolneIzby = function() {
      Dialog.prompt('Voľné izby ku dátumu', 'Prehľad voľných izieb', getCurrentDate(), {type: 'date'}).then(function(date) {
        Outputs.openPrintUrl('ubytovanie', undefined, angular.extend({datum_ku: date}, $scope.SearchCtrl.getQuery()), 'print-izby');
      });
    };
    $scope.printVykaz = function() {
      Dialog.prompt('Za mesiac', 'Mesačný výkaz', getCurrentDate(), {type: 'month'}).then(function(date) {
        Outputs.openPrintUrl('ubytovanie', undefined, angular.extend({datum_ku: date}, $scope.SearchCtrl.getQuery()), 'print-vykaz');
      });
    };
    $scope.printKniha = function() {
      Outputs.openPrintUrl('ubytovanie', undefined, {rok: Profile.get('obdobie', new Date().getFullYear())}, 'print-kniha');
    };

    $scope.uhradarp = {};
    $scope.stornorp = {};
    $scope.showRP = function(item, storno) {
      if (item.faktura_field && !storno) {
        return;
      }
      if (storno && !item.faktura_je_rp) {
        return;
      }
      var v = storno ? $scope.stornorp : $scope.uhradarp;
      v[item.id] = '<div><i class="fa fa-info"></i> Pred presunom do RP sa uistite, že máte spustený program registračnej pokladne a vybranú rovnakú agendu a prevádzku.</div><div class="text-center" style="margin-top: 5px;"><button class="btn btn-sm btn-default" ng-click="presunDoRP('+item.id+', '+storno+')"><i class="fa fa-money"></i> Uhradiť cez RP</button></div>';
    };
    $scope.uhradaClosed = function(item) {
      delete $scope.uhradarp[item.id];
    };
    $scope.stornoClosed = function(item) {
      delete $scope.stornorp[item.id];
    };
    var prepareRPData = function(rpData, pohybData, storno) {
      var pokladne = _.filter(Profile.get('pokladne'), function(obj) { return obj.typ === 2;});
      if ($scope.SearchCtrl.filters.top.prevadzka) {
        pokladne = _.filter(pokladne, {prevadzka: parseInt($scope.SearchCtrl.filters.top.prevadzka)});
      }
      if (!pokladne.length) {
        Dialog.alert('Nie sú zadefinované žiadne registračné pokladne.');
        return;
      }
      var uhradaData = {
        d_zdp: getCurrentDate(),
        d_vyst: getCurrentDate(),
        d_uctn: getCurrentDate(),
        hotovost: Math.round(rpData.spolu * 10000) / 10000.0,
        karta: 0,
        dd: DokladTypy.RP.dd,
        poznamka: '',
        sd1: Global.get('sd1'),
        sd2: Global.get('sd2'),
        firma: rpData.firma,
        prevadzka: rpData.prevadzka,
        blok: 0,
        zaklad_d1: 0,
        zaklad_d2: 0,
        zaklad_d0: 0,
        suma1: 0,
        suma2: 0,
        suma3: 0,
        suma4: 0,
        suma5: 0,
        storno: storno || false
      };
      if (Profile.get('agenda').jednoduche_ucto) {
        angular.extend(uhradaData, {ucet: pokladne[0].id});
      } else {
        angular.extend(uhradaData, {pokladna: pokladne[0].id});
      }
      var ubytovanieParovanie = [];
      _.each(pohybData, function(obj) {
        obj.cena_vydaj = Math.round( (obj.cena_vydaj / ((100+obj.dph)/100.0)) * 1000000) / 1000000.0;
        if (storno) {
          obj.mv *= -1;
        }
        if (obj.id) {
          ubytovanieParovanie.push({id: obj.id});
          delete obj.id;
        }
      });
      angular.extend(uhradaData, {pohyb: {added: pohybData}, ubytovanie: ubytovanieParovanie});
      return uhradaData;
    };
    $scope.presunDoRP = function(id, storno) {
      var item = _.find($scope.ubytovanie, {id: parseInt(id)});
      if (item) {
        if (!parseFloat(item.sadzba_dane) && parseFloat(item.dan_ubytovania)) {
          Dialog.alert('V zázname je zle zapísaná sadzba dane. Prosím, skúste záznam znova zapísať, aby sa prepočítali sumy.');
          return;
        }
        var ubytSuma = item.cena, ubytPocet = item.pocet;
        if (Global.get('ubytovanie_uctovat_sumarne', false)) {
          ubytPocet = 1;
          ubytSuma *= item.pocet;
        }
        var pohybData = [];
        pohybData.push({
          id: item.id,
          iddokd: item.iddokd,
          kod: item.sluzba ? item.sluzba_data.kod : '',
          nazov: item.sluzba ? item.sluzba_data.nazov : 'Ubytovanie',
          karta: item.sluzba,
          dph: item.sluzba ? item.sluzba_data.dph : 0,
          mv: ubytPocet,
          cena_vydaj: ubytSuma,
          mj: item.sluzba ? item.sluzba_data.mj : '',
          zlava: 0,
          poznamka: '',
          mp: 0,
          cena_prijem: 0
        });
        if (parseFloat(item.sadzba_dane)) {
          pohybData.push({
            kod: '',
            nazov: 'Daň z ubytovania',
            karta: null,
            dph: 0,
            mv: parseFloat(item.sadzba_dane) ? Math.round((item.dan_ubytovania / item.sadzba_dane) * 100)/100.0 : 0,
            cena_vydaj: item.sadzba_dane,
            mj: '',
            zlava: 0,
            poznamka: '',
            mp: 0,
            cena_prijem: 0
          });
        }
        var rpData = {
          user: Profile.get('userData').id,
          prevadzka: item.prevadzka,
          storno: angular.isDefined(storno) ? storno : false,
          meno: item.osoba_data.meno,
          spolu: item.spolu,
          sum_sluzby: ubytSuma,
          sum_dan: item.dan_ubytovania,
          pohyb: pohybData,
          firma: item.firma,
          firma_name: item.firma ? item.firma_data.firma : ''
        };
        if (Global.get('externa_rp')) {
          var uhradaData = prepareRPData(rpData, pohybData, storno);
          Ubytovanie.uhrada(uhradaData).then(function(data) {
            PokladnicnyDoklad.syncCreate(data);
            var syncObj = {
              id: item.id,
              partial_change: true,
              locked: !uhradaData.storno,
              faktura_je_rp: !uhradaData.storno,
              faktura_field: uhradaData.storno ? null : data.iddokd,
              dd: DokladTypy.UB.dd,
              agenda: data.agenda
            };
            Ubytovanie.syncChange(syncObj, syncObj);
          });
        } else {
          Socket.emit('payUbytovanie', rpData);
        }
      }
      $timeout(function() {
        storno ? delete $scope.stornorp[id] : delete $scope.uhradarp[id];
      });
    };

    $scope.presunVsetkoRP = function() {
      var items = _.filter($scope.ubytovanie, function(obj) { return !obj.faktura_field; });
      var meno = '', spolu = 0, sluzbySuma = 0, danSuma = 0, danPocet = 0, firma = '', firmaName = '', prevadzka = null;
      var danPlnaPocet = 0, danPlna = 0, danZnizenaPocet = 0, danZnizena = 0;
      var pohybData = [];
      if (items.length) {
        _.each(items, function(item) {
          meno = item.osoba_data.meno;
          firmaName = item.firma ? item.firma_data.firma : '';
          firma = item.firma;
          prevadzka = item.prevadzka;
          spolu += parseFloat(item.spolu);
          var ubytSuma = parseFloat(item.cena), ubytPocet = parseFloat(item.pocet);
          if (Global.get('ubytovanie_uctovat_sumarne', false)) {
            ubytPocet = 1;
            ubytSuma *= parseFloat(item.pocet);
          }
          var itemDan = (parseFloat(item.sadzba_dane) ? Math.round((item.dan_ubytovania / parseFloat(item.sadzba_dane)) * 100)/100.0 : 0);
          danPocet += itemDan;
          danSuma += item.sadzba_dane * itemDan;
          if (item.znizena_dan) {
            danZnizenaPocet += itemDan;
            danZnizena += item.sadzba_dane * itemDan;
          } else {
            danPlnaPocet += itemDan;
            danPlna += item.sadzba_dane * itemDan;
          }
          sluzbySuma += ubytSuma;
          pohybData.push({
            id: item.id,
            iddokd: item.iddokd,
            kod: item.sluzba ? item.sluzba_data.kod : '',
            nazov: item.sluzba ? item.sluzba_data.nazov : 'Ubytovanie',
            karta: item.sluzba,
            dph: item.sluzba ? item.sluzba_data.dph : 0,
            mv: ubytPocet,
            cena_vydaj: ubytSuma,
            mj: item.sluzba ? item.sluzba_data.mj : '',
            zlava: 0,
            poznamka: '',
            mp: 0,
            cena_prijem: 0
          });
        });
        if (danPlna) {
          pohybData.push({
            kod: '',
            nazov: 'Daň z ubytovania',
            karta: null,
            dph: 0,
            mv: Math.round(danPlnaPocet),
            cena_vydaj: Math.round( (danPlna / danPlnaPocet) * 1000000) / 1000000.0,
            mj: '',
            zlava: 0,
            poznamka: '',
            mp: 0,
            cena_prijem: 0
          });
        }
        if (danZnizena) {
          pohybData.push({
            kod: '',
            nazov: 'Znížená daň z ubytovania',
            karta: null,
            dph: 0,
            mv: Math.round(danZnizenaPocet),
            cena_vydaj: Math.round( (danZnizena / danZnizenaPocet) * 1000000) / 1000000.0,
            mj: '',
            zlava: 0,
            poznamka: '',
            mp: 0,
            cena_prijem: 0
          });
        }

        var rpData = {
          user: Profile.get('userData').id,
          prevadzka: prevadzka,
          storno: false,
          meno: meno,
          spolu: Math.round(spolu * 100) / 100.0,
          sum_sluzby: Math.round(sluzbySuma * 100) / 100.0,
          sum_dan: Math.round(danSuma * 100) / 100.0,
          pohyb: pohybData,
          firma: firma,
          firma_name: firmaName
        };
        if (Global.get('externa_rp')) {
          var uhradaData = prepareRPData(rpData, pohybData, false);
          Ubytovanie.uhrada(uhradaData).then(function(data) {
            PokladnicnyDoklad.syncCreate(data);
            _.each(items, function(item) {
              var syncObj = {
                id: item.id,
                partial_change: true,
                locked: !uhradaData.storno,
                faktura_je_rp: !uhradaData.storno,
                faktura_field: uhradaData.storno ? null : data.iddokd,
                dd: DokladTypy.UB.dd,
                agenda: data.agenda
              };
              Ubytovanie.syncChange(syncObj, syncObj);
            });
          });
        } else {
          Socket.emit('payUbytovanie', rpData);
        }
      }
    };
    $scope.exportData = function() {
      Outputs.openXLSExportUrl('ubytovanie', $scope.SearchCtrl.getQuery());
    };
    $scope.parseOsobaTooltip = function(item) {
      var content = '';
      if (item.osoba_data.tel) {
        content += '<br/>'+item.osoba_data.tel;
      }
      if (item.osoba_data.firma) {
        content += '<br/>'+item.osoba_data.firma_data.firma;
      }
      if (item.rezervacia) {
        content += '<br/>Rezervované do: '+$filter('time')(item.rezervovane_do);
      }
      if (item.osoba_data.pp_od && item.osoba_data.pp_do) {
        content += '<br/><br/>PP: '+$filter('date')(item.osoba_data.pp_od, 'dd.MM.yyyy')+' - '+$filter('date')(item.osoba_data.pp_do, 'dd.MM.yyyy');
      } else if (item.osoba_data.pp_od) {
        content += '<br/><br/>PP od: '+$filter('date')(item.osoba_data.pp_od, 'dd.MM.yyyy');
      } else if (item.osoba_data.pp_do) {
        content += '<br/><br/>PP do: '+$filter('date')(item.osoba_data.pp_do, 'dd.MM.yyyy');
      }
      content = content.replace(new RegExp('^<br\/>'), '');
      return content;
    };

    var updateOznacenych = function() {
      var selectedItemsCount = _.keys($scope.selectedItems).length;
      switch (selectedItemsCount) {
        case 0:
          $scope.selectedItemsText = '';
          break;
        case 1:
          $scope.selectedItemsText = 'Označený '+ selectedItemsCount +' záznam';
          break;
        case 2: case 3: case 4:
          $scope.selectedItemsText = 'Označené '+ selectedItemsCount +' záznamy';
          break;
        default:
          $scope.selectedItemsText = 'Označených '+ selectedItemsCount +' záznamov';
          break;
      }
    };

    $scope.selectedItems = {};
    $scope.selectedAll = false;
    $scope.selectAll = function() {
      var selectedAll = !$scope.selectedAll;
      $scope.selectedItems = {};
      for (var i = 0; i < $scope.ubytovanie.length; i++) {
        $scope.ubytovanie[i].selected = selectedAll;
        if (selectedAll) {
          $scope.selectedItems[$scope.ubytovanie[i].id] = $scope.ubytovanie[i];
        } else {
          delete $scope.selectedItems[$scope.ubytovanie[i].id];
        }
      }
      updateOznacenych();
    };

    $scope.selectUbytovanie = function(ubytovanie) {
      if ($scope.selectedAll) {
        $scope.selectedAll = false;
      }
      if ($scope.selectedItems[ubytovanie.id]) {
        delete $scope.selectedItems[ubytovanie.id];
      } else {
        $scope.selectedItems[ubytovanie.id] = ubytovanie;
      }
      updateOznacenych();
    };

    var _updateBulk = function (ubytovania) {
      var osoby = _.map(ubytovania, (ubytovanie) => ubytovanie.osoba_data);
      UbytovanieOsoba.updateBulk(osoby)
      .then(
        function () {

          Ubytovanie.updateBulk(ubytovania)
            .then(
              function () {
                _.each(ubytovania, function (ubytovanie) {
                  delete ubytovania[ubytovanie.id];
                  _.find($scope.ubytovanie, {id: parseInt(ubytovanie.id)}).selected = false;
                  Ubytovanie.syncChange(ubytovanie);
                });

                if ($scope.selectedAll) {
                  $scope.selectedAll = false;
                }
                updateOznacenych();
                $scope.SearchCtrl.refresh();
                Notification.add('Zvolené záznamy ubytovania boli úspešne upravené.', 'success', 10);
              }

            ).catch(
              function (error) {
                if (error.status >= 400 && error.status < 500) {
                  Notification.add('Záznamy ubtovania sa nepodarilo upraviť.', 'danger', 10);
                }
              }
            );
        }
      ).catch(
        function (error) {
          if (error.status >= 400 && error.status < 500) {
            Notification.add('Záznamy ubtovania sa nepodarilo upraviť.', 'danger', 10);
          }
        }
      );
    };

    $scope.setOdidenecStatus = function (status) {
      _.each($scope.selectedItems, function(ubytovanie) {
        ubytovanie.osoba_data.odidenec = status;
        ubytovanie.odidenec = status;
        if (ubytovanie.faktura_data) {
          ubytovanie.faktura_field = ubytovanie.faktura_data.id;
        }
      });
      _updateBulk($scope.selectedItems);
    };

  }]);
