'use strict';

angular.module('casist.widgets')
  .directive('inputSuma', ['$locale', '$timeout', function ($locale, $timeout) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function postLink(scope, element, attrs, ngModel) {
        if (!ngModel)
          return;

        var formats = $locale.NUMBER_FORMATS;

        var decimals = attrs.inputSuma || undefined;
        attrs.$observe('inputSuma', function(value) {
          if (value) {
            decimals = value;
          }
        });

        var replaceSeparator = function(viewValue) {
          var re = new RegExp(' ', 'g');
          var re2 = new RegExp(',', 'g');
          var viewValue2 = String(viewValue).replace(re, '').replace(re2, '.');
          viewValue2 = formatNumber(parseFloat(viewValue2), formats.PATTERNS[0], '', '.', decimals);

          return viewValue2;
        };

        if (!ngModel.$options || ngModel.$options.updateOn !== 'blur') {
          element.bind('change', function() {
            if (angular.isDefined(ngModel.$modelValue)) {
              element.val(formatNumber(ngModel.$modelValue, formats.PATTERNS[0], ' ', ',', decimals));
            }
          });
        }
        element.bind('keydown', function(evt) {
          if (evt.ctrlKey && [49, 50, 187].indexOf(evt.which) !== -1) {
            evt.preventDefault();
            if (evt.which === 50) {
              ngModel.$setViewValue(String(ngModel.$modelValue / 1.2));
            } else {
              ngModel.$setViewValue(String(ngModel.$modelValue * 1.2));
            }
            element.val(formatNumber(ngModel.$modelValue, formats.PATTERNS[0], ' ', ',', decimals));
          }
        });

        ngModel.$parsers.push(function(viewValue) {
          var viewValue2 = replaceSeparator(viewValue);

          var num = parseFloat(viewValue2);
          if (isNaN(num)) {
            return 0.00;
          }
          if (ngModel.$options && ngModel.$options.updateOn === 'blur') {
            element.val(formatNumber(num, formats.PATTERNS[0], ' ', ',', decimals));
          }
          return num;
        });
        ngModel.$formatters.push(function (modelValue) {
          if (!angular.isDefined(modelValue)) {
            return modelValue;
          }
          if (modelValue === "0") {
            ngModel.$setViewValue("0");
            return "0.00";
          }
          var val = formatNumber(parseFloat(modelValue), formats.PATTERNS[0], ' ', ',', decimals);
          return val;
        });

        element.addClass('text-right');
      }
    };
  }])
.directive('inputCislo', ['$locale', function ($locale) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function postLink(scope, element, attrs, ngModel) {
        if (!ngModel)
          return;

        ngModel.$parsers.push(function(viewValue) {
          if (!viewValue) {
            return undefined;
          }
          return viewValue;
        });
      }
    };
  }]);
