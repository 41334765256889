'use strict';

angular.module('casist.widgets')
  .directive('mctooltip', ['$compile', '$position', '$document', '$timeout', '$sce', function ($compile, $position, $document, $timeout, $sce) {
    return {
      // template: '<div ng-show="opened" class="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" ng-bind-html-unsafe="content"></div></div>',
      restrict: 'A',
      link: function postLink(originalScope, element, attrs) {
        // var value = undefined;
        // var parent = $compile(angular.element('<div>parent {{opened}}</div>'))(scope);
        // element.after(parent);
        var el = angular.element('<div class="tooltip {{placement}} fade in" ng-show="opened"><div class="tooltip-arrow"></div><div class="tooltip-inner" ng-bind-html="content"></div></div>');
        var tooltipLinker = $compile(el);
        var tooltip = null;
        var focused = false;
        var scope = originalScope.$new();
        var onlyOnFocus = angular.isDefined(attrs.mctooltipOnFocus) ? originalScope.$eval(attrs.mctooltipOnFocus) : true;
        var showEventName = angular.isDefined(attrs.mctooltipEvent) ? attrs.mctooltipEvent : 'mouseover';
        var hideEventName = showEventName === 'focus' ? 'blur' : 'mouseout';
        scope.placement = "top";

        scope.content = '';
        scope.opened = false;
        attrs.$observe("mctooltip", function(value) {
          if (value) {
            let evaluated = scope.$eval(value);
            if (angular.isString(evaluated)) {
              evaluated = $sce.trustAsHtml(scope.$eval(value));
            }
            scope.content = evaluated;
          }
        });
        attrs.$observe("mctooltipPlacement", function(value) {
          if (value)
            scope.placement = value;
        });
        var updateTooltipValue = function() {
          var value = originalScope.$eval(attrs.mctooltip);
          scope.content = $sce.trustAsHtml(value);
          if ((focused && onlyOnFocus) || !onlyOnFocus) {
            if (value) {
              $timeout(function() {
                show();
              });
            } else {
              $timeout(function() {
                hide();
              });
            }
          }
        };
        originalScope.$on('errorsChanged', updateTooltipValue);
        if (angular.isDefined(attrs.dynamic)) {
          originalScope.$watch(attrs.mctooltip, updateTooltipValue);
        }
        // originalScope.$on('tooltipsRefresh', updateTooltipValue);

        var positionTooltip = function () {
          var pos = $position.position(element);
          var ttPosition = $position.positionElements(element, tooltip, scope.placement, true);
          ttPosition.top += 'px';
          ttPosition.left += 'px';

          tooltip.css( ttPosition );
        };
        var createTooltip = function() {
          if (tooltip)
            removeTooltip();

          tooltip = tooltipLinker(scope, function () {});
        }
        var removeTooltip = function() {
          if (tooltip) {
            tooltip.remove();
            tooltip = null;
          }
        }
        var show = function() {
          createTooltip();
          scope.opened = true;
          scope.$digest();

          tooltip.css({ top: 0, left: 0, display: 'block' });

          $document.find( 'body' ).append( tooltip );

          positionTooltip();

          scope.opened = true;
          scope.$digest();
        }
        var hide = function() {
          scope.opened = false;
          scope.$digest();
          removeTooltip();
        }
        var showEvent = function() {
          focused = true;
          if (!scope.content)
            return;

          show();
        }

        var hideEvent = function() {
          focused = false;
          hide();
        };

        var iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
        if (!((element[0].localName === 'a' || element[0].localName === 'button') && showEventName === 'mouseover' && iOS)) {
          element.bind(showEventName, showEvent);
          element.bind(hideEventName, hideEvent);
        }

        var unregisterTriggers = function() {
          element.unbind(hideEventName, hideEvent);
          element.unbind(showEventName, showEvent);
        }

        // Make sure tooltip is destroyed and removed.
        originalScope.$on('$destroy', function() {
          scope.$destroy();
          unregisterTriggers();
          removeTooltip();
        });
      }
    };
  }])
  .directive("mcpopover", ['$timeout', '$compile', '$document', '$position', '$parse', function ($timeout, $compile, $document, $position, $parse) {
      return {
        restrict: "EA",
        scope: {
          title: "@",
          content: "@",
          style: '=mcpopoverStyle',
          closeCallback: '&mcpopoverOnClose'
        },
        templateUrl: "components/widgets/popover-html-unsafe-popup.html",
        link: function postLink(scope, element, attrs) {
          scope.$parent.$watch(attrs.mcpopover, function(val, oldVal) {
            if (val) {
              if (scope.content) {
                scope.content.remove();
              }
              if (tooltip) {
                removeTooltip();
              }
              scope.content = $compile(angular.element(val))(scope.$parent);
              $timeout(function() {
                show();
              });
            }
          });

          var el = angular.element('<div class="popover {{placement}} fade in" ng-style="style"><div class="arrow" style="{{arrowStyle}}"></div><div class="popover-inner"><h3 class="popover-title" ng-bind="title" ng-show="title"></h3><div class="popover-content" bind-html-unsafe="content"></div></div></div>');
          var tooltipLinker = $compile(el);
          var tooltip = null;

          var showEventName = angular.isDefined(attrs.mcpopoverEvent) ? attrs.mcpopoverEvent : 'mouseover';
          var hideEventName = showEventName === 'focus' ? 'blur' : 'mouseout';
          scope.placement = "top";

          scope.content = '';
          scope.opened = false;
          var focused = false;
          attrs.$observe("mcpopover", function(value) {
            if (value) {
              scope.content = scope.$eval(value);
            }
          });
          attrs.$observe("mcpopoverPlacement", function(value) {
            if (value) {
              scope.placement = value;
            }
          });
          attrs.$observe("mcpopoverTitle", function(value) {
            scope.title = value;
          });

          var positionTooltip = function () {
            var pos = $position.position(element);
            var ttPosition = $position.positionElements(element, tooltip, scope.placement, true);
            if (['left', 'right'].indexOf(scope.placement) !== -1 && ttPosition.top < 5) {
              var top = ((tooltip[0].clientHeight / 2 - (5 - ttPosition.top)) / tooltip[0].clientHeight * 100);
              ttPosition.top = 5;
              scope.arrowStyle = 'top: '+top+'%';
            }
            ttPosition.top += 'px';
            ttPosition.left += 'px';

            tooltip.css( ttPosition );
          };
          var createTooltip = function() {
            if (tooltip)
              removeTooltip();

            tooltip = tooltipLinker(scope, function () {});
          }
          var removeTooltip = function() {
            if (tooltip) {
              tooltip.remove();
              tooltip = null;
            }
          }
          var show = function() {
            createTooltip();
            scope.opened = true;
            scope.$digest();

            tooltip.css({ top: 0, left: 0, display: 'block' });

            $document.find( 'body' ).append( tooltip );

            positionTooltip();

            scope.opened = true;
            scope.$digest();
          }
          var hide = function() {
            if (scope.opened && angular.isDefined(attrs.mcpopoverOnClose)) {
              scope.$apply(function() {
                scope.closeCallback();
              });
            }
            scope.opened = false;
            scope.$digest();
            removeTooltip();
          }
          var showEvent = function() {
            focused = true;
            if (!scope.content)
              return;

            show();
          }

          var hideEvent = function(evt) {
            var parents = $(evt.target).parents('div.popover');
            if (parents && parents.length) {
              return;
            }
            focused = false;
            hide();
          };

          // element.bind(showEventName, showEvent);
          // element.bind(hideEventName, hideEvent);
          $document.bind('click', hideEvent);

          var unregisterTriggers = function() {
            $document.unbind('click', hideEvent);
            // element.unbind(hideEventName, hideEvent);
            // element.unbind(showEventName, showEvent);
          }

          // Make sure tooltip is destroyed and removed.
          scope.$on('$destroy', function() {
            unregisterTriggers();
            removeTooltip();
          });
        }
      };
    }]);
;
