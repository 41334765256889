'use strict';

angular.module('casist.ucto').controller('UCTDennikCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'UCTDennik', 'Firma', 'Outputs', 'TabService', 'Doklad', 'Ucet', 'DokladTypy', 'Predkontacia',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, UCTDennik, Firma, Outputs, TabService, Doklad, Ucet, DokladTypy, Predkontacia) {

  $scope.slideAnimation = true;

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  }
  $scope.sumarneTypy = [
    {id: 'vs', sumarne: 'Sumárne za VS'}
  ];
  $scope.medzisumyTypy = [
    {id: 'vs', text: 'Medzisumy za VS'}
  ];
  $scope.dokladyTypy = [];
  angular.forEach(_.filter(DokladTypy, {ucto: true}), function(val, key) {
    $scope.dokladyTypy.push({id: val.dd, dd: val.name});
  });

  $scope.filterVS = function(vs) {
    $scope.SearchCtrl.cancelFilter(false, true, true);
    $scope.SearchCtrl.addFilter('vs', vs);
  };
  $scope.medzisumyChanged = function(value) {
    if ($scope.SearchCtrl.filters.top.sumarne) {
      $scope.SearchCtrl.filterSelection('sumarne');
    }
  };
  $scope.dennik = [];
  var filterChanged = function(filter) {
    $scope.dennik.loading = true;
    if (filter.sumarne) {
      if ($scope.currentView !== 1) {
        $scope.currentView = 1;
        $scope.SearchCtrl.changeColumnsDef($scope.dennikTable[$scope.currentView]);
        $scope.containerStyle = {'max-width': '850px', align: 'left'};
      }
      if (filter.medzisumy) {
        $scope.SearchCtrl.filterSelection('medzisumy', undefined, false);
        delete filter.medzisumy;
      }
    } else if ($scope.currentView !== 0) {
      $scope.currentView = 0;
      $scope.SearchCtrl.changeColumnsDef($scope.dennikTable[$scope.currentView]);
      $scope.containerStyle = {};
    }
    var promise = UCTDennik.objects().getList(filter);
    promise.then(function(data) {
      TabService.registerScope('uctdennik', $scope);
      $scope.dennik = data;

      if (filter.medzisumy) {
        processSumLines($scope.dennik, data, filter.medzisumy, function(obj, lookup) { return obj.doklad_data[lookup]; });
      }
    });
    if (filter.zahranicne)
      $scope.currentView = 1;
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.Ucet = Ucet;
  $scope.Predkontacia = Predkontacia;

  var coreFields = [
    { },
    { field: 'd_zdp', label: 'ucto.SUPPLIED', width: 90, type: 'date', filterCollapse: 'right' },
    { field: 'iddokd', sortField: 'dd,iddokd', label: 'ucto.RECORD', width: 90, type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'ucet__ucet', label: 'uctdennik.UCET', placeholder: 'ucty.UCET_NAZOV', width: 100, linked: { source: 'Ucet.get(query)', template: 'ucet-selection', lookup: 'ucet', style: "{width: '400px'}" } },
    { field: 'suma_md', label: 'ucto.SUMAMD', type: 'number', sum: true },
    { field: 'suma_d', label: 'uctdennik.SUMAD', type: 'number', sum: true },
    { field: 'rozdielmdd', label: 'ucto.ROZDIEL', type: 'number', searchable: false, sortable: false, sum: function(sums) { return parseFloat(sums['suma_md']) - parseFloat(sums['suma_d']);} },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'predkontacia__nazov', label: 'predkontacie.PREDKONTACIA', linked: { source: 'Predkontacia.get(query)', lookup: 'nazov' } },
    { field: 'popis', label: 'predkontacie.POPIS' },
    { field: 'parovanie', sortField: 'parovanie', label: 'Párovanie', width: 90, type: 'int', filterCollapse: 'left' }
  ];
  if (Profile.get('jednoducheUcto')) {
    var filtered = ['rozdielmdd', 'suma_md'];
    coreFields = _.reject(coreFields, function(obj) { return filtered.indexOf(obj.field) !== -1; });
  }
  var sumFields = makeSumFields(coreFields, ['vs', 'iddokp']);
  _.find(sumFields, {field: 'vs'}).filterCollapse = 'right';

  $scope.dennikTable = [
    coreFields,
    sumFields,
  ];

  $scope.SearchCtrl.init({
    model: UCTDennik,
    defaultPeriod: defaultPeriod,
    onChange: filterChanged,
    columnsDef: $scope.dennikTable[0],
    sort: {sort: 'd_zdp', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh(TabService.bus.get('uctdennik'));

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.dennikTable[view]);
  };

  $scope.editDoklad = function(doklad) {
    Doklad.editRecord(doklad, null, $scope).then(function(data) {
      var affected = _.filter($scope.dennik, {doklad: data.id});
      for (var i = 0; i < affected.length; i++) {
        angular.copy(data, affected[i].doklad_data);
      }
    });
  };

  $scope.printList = function(url) {
    Outputs.openPrintUrl('uctdennik', null, $scope.SearchCtrl.getQuery(), url);
  };

  $scope.export = function() {
    Outputs.openXMLExportUrl('uctdennik', '', $scope.SearchCtrl.getQuery());
  };

  var events = [];
  var createHandler = function(data, observer) {
    if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
      return;
    }
    if (!isEmpty(data.uctovanie)) {
      $scope.SearchCtrl.refresh();
    }
  };
  var changeHandler = function(data, observer) {
    if (angular.isDefined(data.obj.agenda) && Profile.get('agenda').id !== data.obj.agenda) {
      return;
    }
    if (!objEquals(data.original, data.obj)) {
      $scope.SearchCtrl.refresh();
    }
  };
  var removeHandler = function(data) {
    if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
      return;
    }
    $scope.SearchCtrl.refresh();
  };
  // endpoints we are interested in
  var endpoints = ['faktury', 'zavazky', 'pokladna', 'banka', 'interne', 'penaznydennik'];

  for (var i in endpoints) {
    events.push(Socket.on(endpoints[i]+"Created", function(data) {
      createHandler(data, true);
    }));
    events.push(Socket.on(endpoints[i]+"Changed", function(data) {
      changeHandler(data, true);
    }));
    events.push(Socket.on(endpoints[i]+"Removed", function(data) {
      removeHandler(data);
    }));
  }
  events.push($scope.$on('casist:dataChanged', function(event, data) {
    if (endpoints.indexOf(data.type) === -1) {
      return;
    }
    switch (data.operation) {
      case 1:
        createHandler(data.payload, false);
        break;
      case 2:
        changeHandler(data.payload, false);
        break;
      case 3:
        removeHandler(data.payload);
        break;
      default:
        break;
    }
  }));

  $scope.$on('$destroy', function() {
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
    for (var i in events) {
      events[i]();
    }
  });
}]);
