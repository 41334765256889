'use strict';

angular.module('casist.svb')
  .controller('PrintPredpisCtrl', ['$scope', '$modalInstance', 'vlastnik', 'Vlastnik', 'Settings', 'Global',
    function($scope, $modalInstance, vlastnik, Vlastnik, Settings, Global) {
      $scope.errors = {
        vlastnik: {},
        settings: {}
      };
      $scope.vlastnik = Vlastnik.copy(vlastnik);
      $scope.settings = {
        text_predpis: Global.get('text_predpis')
      };
      Settings.objects().getList().then(function(data) {
        if (data.length) {
          $scope.settings = data[0];
        }
      });

      $scope.print = function () {
        copyWeakSrc($scope.vlastnik, vlastnik);
        Settings.create($scope.settings).then(function(data) {
          angular.copy(data, $scope.settings);
          Global.appendArray($scope.settings);
          $modalInstance.close();
        });
      };

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };
  }]);
