'use strict';

angular.module('casist.core').controller('BankAccountCtrl',
              ['$scope', '$modalInstance', 'list', '$modal', 'Global', '$controller', 'Profile', 'BankAccount',
              function ($scope, $modalInstance, list, $modal, Global, $controller, Profile, BankAccount) {

    if (list) {
      $scope.accounts = list;
    } else {
      $scope.accounts = [];
      BankAccount.objects().getList().then(function(data) {
        $scope.accounts = data;
      });
    }

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: BankAccount
    });

    $scope.TableEditor = $controller('TableEditor', {
      $scope: $scope,
      Model: BankAccount,
      List: $scope.accounts
    });

    $scope.RecordCtrl.main.init({
      endpoint: 'list',
      model: 'doklad',
      master: undefined,
      list: undefined,
      defaultItem: {},
      searchCtrl: undefined,
      $modalInstance: $modalInstance
    });

    $scope.getPrevadzkaName = function(prevadzka) {
      var prev = _.find($scope.prevadzky, {id: parseInt(prevadzka)});
      return prev ? prev.prevadzka : '?';
    };

    $scope.createBankAccount = function() {
      $scope.TableEditor.startCreating('newAccount');
      $scope.newAccount = {active: true};
    };

    $scope.ok = function () {
      var promise = $scope.TableEditor.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close({record: $scope.accounts});
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

  }]);
