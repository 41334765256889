'use strict';

angular.module('casist.sklad')
  .factory('Pozicie', [
    'Restangular',
    'BaseModel',
    'BaseCollectionModel',
    '$q',
    '$modal',
    'Profile',
    function (
      Restangular,
      BaseModel,
      BaseCollectionModel,
      $q,
      $modal,
      Profile,
    ) {

    var ENDPOINT = 'pozicie';
    var parent = BaseCollectionModel.object(ENDPOINT);

    var openDetail = function(item, items, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'sklad/pozicie/pozicie-detail.html',
        controller: 'PozicieDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return items || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };

    Restangular.extendModel(ENDPOINT, function(model) {
        angular.extend(model, BaseModel);
        return angular.extend(model, {
          validate: function () {
            var result = BaseModel.validate();
            return result;
          },
        });
    });

    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').pozicia;
      },
      get: function (query, page, parentObject, otherParams) {
        return parent.objects().getList(angular.extend(
          angular.extend({search:query}, otherParams), page)
        );
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function() {
        var obj = parent.restangularize();
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      getPozicie: function () {
        return Restangular.all('pozicie').customGETLIST();
      },
    });
  }]);
