'use strict';

angular.module('casist.sklad')
  .factory('Pata', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Dialog', 'Global', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog, Global) {
    Restangular.extendModel('paty', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.text) {
            this.addError('text', 'Text nemôže byť prázdny!');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('faktury/paty');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').faktura;
      },
      edit: function (pata) {
        var wasDefault = pata.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt('Text päty', 'Upraviť pätu', {userinput: pata.text, default: pata.default}, {type: 'textarea', rows: 8}, 'sklad/fakturacia/fakturacia-hlavicka-detail.html').then(function(userData) {
          pata.text = userData.userinput;
          pata.default = userData.default;
          if (!pata.validate()) {
            Dialog.alert(pata.errors().data.text[0], 'Chyba');
            return;
          }
          pata.update().then(function(data) {
            if (!data.typ) {
              if (data.default) {
                Global.set('faktura_pata', data.text);
              } else if (wasDefault) {
                Global.set('faktura_pata', null);
              }
            }
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(pata, attrs) {
        return parent.create(angular.extend({text: pata}, attrs));
      },
      remove: function (pata) {
        return pata.remove();
      },
      get: function (query, otherParams) {
        return parent.objects().getList(angular.extend({search:query}, otherParams));
      }
    });
  }]);