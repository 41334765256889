'use strict';

angular.module('casist.utils')
  .filter('doklad_dd', ['DokladTypy', function(DokladTypy) {
    return function(input, lookup) {
      input = parseInt(input);
      var typ = '?';
      angular.forEach(DokladTypy, function(val, key) {
        if (val.dd === input) {
          typ = lookup ? val[lookup] : val.shortName;
          return;
        }
      });
      return typ;
    }
  }])
  .filter('doklad_iddokd_deprecated', ['DokladTypy', function(DokladTypy) {
    return function(input) {
      if (!input) {
        return '';
      }
      // ak to nie su iba cisla, tak to vratim take ako to je, lebo to ma asi uz v sebe kod dokladu
      if (!/^([0-9]*)$/.test(input.iddokd)) {
        return input.iddokd;
      }
      var dd = parseInt(input.dd);
      var typ = '?';
      angular.forEach(DokladTypy, function(val, key) {
        if (val.dd === dd) {
          typ = val.shortName;
          return;
        }
      });
      return typ + input.iddokd;
    }
  }]);