'use strict';

angular.module('casist.ucto')
  .controller('UcetDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'Ucet', 'SearchCtrl', 'ucet', 'ucty', 'UctOsnova',
                function($scope, $modalInstance, $controller, Profile, Ucet, SearchCtrl, ucet, ucty, UctOsnova) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Ucet
    });

    var defaultUcet = {
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'ucty',
      model: 'ucet',
      master: ucet,
      list: ucty,
      defaultItem: defaultUcet,
      // preSave: function(obj) { obj.rok = 2014; return obj; },
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });
    if (!isEmpty(ucet)) {
      $scope.ucet = Ucet.copy(ucet);
      UctOsnova.objects().getList({ucet__ucet: $scope.ucet.ucet.substring(0, 3), ordering: 'ucet__ucet,-rok'}).then(function(data) {
        if (data.length && (data[0].rsa || data[0].rsp || data[0].rv || data[0].rdp)) {
          if (!$scope.ucet.osnova) {
            $scope.ucet.osnova = {};
          }
          angular.extend($scope.ucet.osnova, _.pick(data[0], ['rsa', 'rsp', 'rv', 'rdp']));
        }
      });
    } else {
      $scope.ucet = defaultUcet;
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: firma});
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };
  }]);
