'use strict';

angular.module('casist.auth')
  .directive('authApplication', ['$http', '$location', '$timeout', 'Global', 'Auth', '$rootScope', 'Notification', 'httpBuffer', function ($http, $location, $timeout, Global, Auth, $rootScope, Notification, httpBuffer) {
    return {
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        if (!Global.get('serverName') || isEmpty(Auth.profile().get('agendy'))) {
          element.addClass('background-blue');
        }
        scope.$on('event:auth-loginRequired', function () {
          Global.clear();
          Global.clearCookies();
          Auth.resetRenewalStatus();
          Notification.clear();
          $location.path("/login");
          element.addClass('background-blue');
        });
        scope.$on('event:auth-loginConfirmed', function () {
          if ($location.path() === '/login') {
            $location.path("/");
          }
          if (!isEmpty(Auth.profile().get('agendy'))) {
            element.removeClass('background-blue');
            $rootScope.isAuth = true;
          }
        });
        scope.$on("event:auth-tokenExpired", function() {
          Auth.renewTokenAsync().then(function() {
            httpBuffer.retryAll(function(config) {
              config.headers["Authorization"] = $http.defaults.headers.common['Authorization'];
              return config;
            });
          }).catch(() => null);
        });
      }
    };
  }]);
