'use strict';

angular.module('casist.sklad').controller('ZalohoveFakturyCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'ZalohovaFaktura', 'Firma', 'Outputs', 'DokladTypy', 'TabService', 'Doklad', 'Notification', 'Dialog',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, ZalohovaFaktura, Firma, Outputs, DokladTypy, TabService, Doklad, Notification, Dialog) {

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {
  };
  $scope.zalohovefaktury = [];
  var filterChanged = function(filter) {
    var promise = ZalohovaFaktura.objects().getList(filter);
    $scope.zalohovefaktury.loading = true;
    promise.then(function(data) {
      TabService.registerScope('zalohovefaktury', $scope);
      $scope.zalohovefaktury = data;
      for (var i = 0; i < data.length; i++) {
        Firma.restangularize($scope.zalohovefaktury[i].firma_data);
      }
    });
    if (filter.zahranicne)
      $scope.setCurrentView(1);
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    loadPrevadzky();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;

  var coreFields = [
    {},
    {},
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'd_zdp', label: 'ucto.ISSUED', type: 'date' },
    { field: 'd_splat', label: 'ucto.DUE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true }
  ];

  $scope.zfTable = [
    _.union(coreFields, [
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70 },
      { field: 'zlava', filterCollapse: 'left', label: 'ucto.ZLAVA', type: 'number' },
    ]),
    _.union(coreFields, [
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
      { field: 'mena', label: 'ucto.MENA', width: 90 },
      { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
      { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
      { field: 'objednavka', label: 'ucto.OBJEDNAVKA', filterType: 'icontains' },
      { field: 'doprava', label: 'ucto.DOPRAVA', filterType: 'icontains' },
      { field: 'predajca', label: 'ucto.PREDAJCA', filterType: 'icontains' },
      { field: 'poznamka', label: 'fakturacia.POZNAMKA', filterType: 'icontains' }
    ]),
  ];
  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'uplat_dph', 'typ_dph__typ', 'sd1', 'sd2'];
    var idx = $scope.zfTable[0].indexOf(_.find($scope.zfTable[0], {field: 'zaklad_d1'}));
    $scope.zfTable[0] = _.reject($scope.zfTable[0], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    $scope.zfTable[1] = _.reject($scope.zfTable[1], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    var moved = ['objednavka', 'doprava', 'predajca'];
    var movedItems = _.filter($scope.zfTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
    for (var i = movedItems.length-1; i >= 0; i--) {
      $scope.zfTable[0].splice(idx, 0, movedItems[i]);
    }
    $scope.zfTable[1] = _.reject($scope.zfTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
  }

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
  };
  loadPrevadzky();

  $scope.create = function(item) {
    ZalohovaFaktura.edit(item, $scope.zalohovefaktury, $scope.SearchCtrl);
  };
  $scope.copy = function(item) {
    var copied = ZalohovaFaktura.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'vs', 'd_vyst', 'd_zdp', 'd_splat', 'd_uctn', 'uplat_dph'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };
  $scope.fakturacia = function(ponuka) {
    if (ponuka.faktura) {
      TabService.bus.send('faktura', 'zalohovefaktury', {iddokd: ponuka.faktura});
      TabService.openTab('faktura');
    } else {
      ponuka.fakturacia().then(function(data) {
        Notification.add('Zálohová faktúra bola vyfaktúrovaná.', 'success', 5);
        Doklad.syncCreate(data);
        ponuka.faktura = data.iddokd;
        $scope.SearchCtrl.broadcastRefresh('zalohovefaktury');
        TabService.bus.send('faktura', 'zalohovefaktury', {iddokd: data.iddokd});
        TabService.openTab('faktura');
      }, function() {
        Notification.add('Nepodarilo sa vyfaktúrovať ponuku.', 'danger', 5);
      });
    }
  };
  var events = ZalohovaFaktura.registerEvents(function() { return $scope.zalohovefaktury; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    ZalohovaFaktura.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: ZalohovaFaktura,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.zfTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh();

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.zfTable[view]);
  };

  $scope.print = function(faktura) {
    Outputs.openPrintUrl('zalohovefaktury', faktura.id);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('zalohovefaktury', null, $scope.SearchCtrl.getQuery());
  };
}]);
