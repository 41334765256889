import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
declare var angular: angular.IAngularStatic;

@Injectable()
export class Phone {
  constructor() { }
  query(): string {
    return 'hello';
  }
}

angular.module('casist.proof_of_concept', []);
angular.module('casist.proof_of_concept')
  .factory('Phone', downgradeInjectable(Phone));
