'use strict';

angular.module('casist.ucto').controller('UrgencieHromadneCtrl',
              ['$scope', '$modalInstance', 'email', 'noEmail', 'text', '$timeout', 'Notification', 'BankovyDoklad', 'Firma', 'Outputs', 'Urgencia', 'Dialog', 'DokladTypy', 'Global', 'Prevadzka',
              function ($scope, $modalInstance, email, noEmail, text, $timeout, Notification, BankovyDoklad, Firma, Outputs, Urgencia, Dialog, DokladTypy, Global, Prevadzka) {

    $scope.email = email;
    $scope.noEmail = noEmail;
    $scope.Firma = Firma;

    var firmy = [];
    var firmy_data = [];
    var firmaFormatter = function(firma) {
      return firma.firma + ' <'+firma.email+'>';
    };
    for (var i = 0; i < email.length; i++) {
      firmy_data.push(email[i].firma_data);
      firmy.push(firmaFormatter(email[i].firma_data));
    }
    firmy = _.uniq(firmy);
    $scope.mail = {
      firmy: firmy.join(', '),
      subject: 'Urgencia',
      message: text
    };

    $scope.editFirma = function(firma) {
      Firma.edit(firma).then(function(data) {
        if (data.email && !_.find(firmy_data, {id: data.id})) {
          $scope.mail.firmy += ', '+firmaFormatter(data);
          $scope.mail.firmy = $scope.mail.firmy.replace(/^, /, '');
          firmy_data.push(data);
          var affected = _.filter($scope.noEmail, {firma: data.id});
          for (var i = 0; i < affected.length; i++) {
            $scope.email.push(affected[i]);
            $scope.noEmail.splice($scope.noEmail.indexOf(affected[i]), 1);
          }
          $scope.$broadcast('urgenciehromadneRefresh');
        }
      });
    };
    $scope.print = function() {
      Outputs.openPrintUrl('urgencie', null, {id: _.map(noEmail, urgencia => urgencia.id).join(',')});
    };
    $scope.sentAlready = false;
    $scope.send = function() {
      $scope.sendInProgress = true;
      Urgencia.send({send: 1}, {
        data: {
          komu: firmy_data,
          data: $scope.email,
          subject: $scope.mail.subject,
          message: $scope.mail.message
        }
      }).then(function() {
        $scope.sendInProgress = false;
        $scope.sentAlready = true;
        Notification.add('Urgencie boli úspešne odoslané.', 'success', 5);
      }, function() {
        $scope.sendInProgress = false;
        Notification.add('Vytvorenie urgencií sa nepodarilo!', 'danger', 5);
      });
    };
    $scope.ok = function () {
      $modalInstance.close();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
