'use strict';

angular.module('casist.core')
  .directive('mcNavbar', ['TabService', '$timeout', '$window', function(TabService, $timeout, $window) {
    return {
      link: function postLink(scope, element, attrs) {
        var iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
        // var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
        if (!iOS) {
          var updateOverflow = function() {
            var el = element.find('.nav-tabs');
            if (el.length) {
              $timeout(function() {
                if (el[0].offsetWidth < el[0].scrollWidth) {
                  element.addClass('scrollable');
                } else {
                  element.removeClass('scrollable');
                }
              });
            }
          }
          var win = angular.element($window);
          win.bind('resize', updateOverflow);
          scope.$on('$destroy', function() {
            win.unbind('resize', updateOverflow);
          });
          TabService.registerObserverCallback(updateOverflow);
        }
      }
    }
  }])
  .directive('newTab', ['$document', '$timeout', function ($document, $timeout) {
    return {
      templateUrl: 'components/navigation/new-tab.html',
      restrict: 'EA',
      scope: {
        tabhandler: '&newTabHandler',
        selected: '=selected'
      },
      link: function postLink(scope, element, attrs) {
        scope.isOpen = false;
        scope.isInit = true;

        var closeMenu = function() {
          scope.$apply(function() {
              scope.isOpen = false;
            });
            $document.unbind('click', onDocumentClick);
            $document.unbind('keydown', escapeKey);
            element.removeClass('opened');
        }
        scope.closeDrawer = function() {
          scope.isOpen = false;
          $document.unbind('click', onDocumentClick);
          $document.unbind('keydown', escapeKey);
          element.removeClass('opened');
        }

        var escapeKey = function(event) {
          if (scope.isOpen && event.which == 27) {
            closeMenu();
          }
        }

        var onDocumentClick = function(event) {
          var isInside = event.target == element[0] || element.has(event.target).length > 0;
          if (!isInside)
            closeMenu();
        }

        element.bind("click", function(event) {
          if (event.target != element.children()[0] && event.target.id != "new-tab")
            return;
          event.preventDefault();
          scope.$apply(function() {
            scope.isOpen = !scope.isOpen;
            if (scope.isOpen) {
              $document.bind('click', onDocumentClick);
              $document.bind('keydown', escapeKey);
              element.addClass('opened');
            } else {
              $document.unbind('click', onDocumentClick);
              $document.unbind('keydown', escapeKey);
              element.removeClass('opened');
            }
          });
        });
      }
    };
  }]);
