'use strict';

angular.module('casist.ucto')
  .controller('PrepocetUhradCtrl', ['$scope', '$modalInstance', 'query', 'Dialog', '$q', 'model', 'Notification', 'Faktura', 'Socket', function($scope, $modalInstance, query, Dialog, $q, model, Notification, Faktura, Socket) {
    $scope.calendarOpened = false;
    $scope.data = {
      userinput: getCurrentDate()
    };

    const events = [];
    const prepocetUhradFinished = function(data) {
      Notification.clear();

      if (data.result) {
        Notification.add('Globálny prepočet úhrad prebehol úspešne.', 'success', 10);
      } else {
        Notification.add('Globálny prepočet úhrad neprebehol úspešne.', 'danger', 10);
      }
    }
    events.push(Socket.on("prepocetUhradFinished", prepocetUhradFinished));

    var cancelPromise = $q.defer();
    $scope.ok = function() {
      Notification.add('Globálny prepočet úhrad sa spustil na pozadí, prosím počkajte kým sa ukončí...', 'info');
      model.prepocetUhrad($scope.data.userinput, query, cancelPromise.promise);
      $modalInstance.close();
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }]);
