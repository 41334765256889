'use strict';

angular.module('casist.ubytovanie')
  .factory('UbytovanieOsoba', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "osoby";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.meno) {
            this.addError('meno', 'Meno nemôže byť prázdne.');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var krajiny = [
      {id: '1008', krajina: 'Albánsko'},
      {id: '1056', krajina: 'Belgicko'},
      {id: '1112', krajina: 'Bielorusko'},
      {id: '1100', krajina: 'Bulharsko'},
      {id: '1070', krajina: 'Bosna a Hercegovina'},
      {id: '1203', krajina: 'Česká republika'},
      {id: '1208', krajina: 'Dánsko'},
      {id: '1233', krajina: 'Estónsko'},
      {id: '1246', krajina: 'Fínsko'},
      {id: '1250', krajina: 'Francúzsko'},
      {id: '1300', krajina: 'Grécko'},
      {id: '1528', krajina: 'Holandsko'},
      {id: '1191', krajina: 'Chorvátsko'},
      {id: '1372', krajina: 'Írsko'},
      {id: '1352', krajina: 'Island'},
      {id: '1688', krajina: 'Srbsko'},
      {id: '1499', krajina: 'Čierna Hora'},
      {id: '1438', krajina: 'Lichtenštajnsko'},
      {id: '1440', krajina: 'Litva'},
      {id: '1428', krajina: 'Lotyšsko'},
      {id: '1442', krajina: 'Luxembursko'},
      {id: '1807', krajina: 'Macedónsko'},
      {id: '1470', krajina: 'Malta'},
      {id: '1498', krajina: 'Moldavsko'},
      {id: '1348', krajina: 'Maďarsko'},
      {id: '1276', krajina: 'Nemecko'},
      {id: '1578', krajina: 'Nórsko'},
      {id: '1616', krajina: 'Poľsko'},
      {id: '1620', krajina: 'Portugalsko'},
      {id: '1040', krajina: 'Rakúsko'},
      {id: '1642', krajina: 'Rumunsko'},
      {id: '1643', krajina: 'Rusko'},
      {id: '1705', krajina: 'Slovinsko'},
      {id: '1724', krajina: 'Španielsko'},
      {id: '1756', krajina: 'Švajčiarsko'},
      {id: '1752', krajina: 'Švédsko'},
      {id: '1380', krajina: 'Taliansko'},
      {id: '1804', krajina: 'Ukrajina'},
      {id: '1826', krajina: 'Spojene kráľovstvo'},
      {id: '1999', krajina: 'Ostatné európske štáty (nepoužívať)'},
      {id: '4032', krajina: 'Argentína'},
      {id: '4076', krajina: 'Brazília'},
      {id: '4214', krajina: 'Dominikánska republika'},
      {id: '4484', krajina: 'Mexiko'},
      {id: '4124', krajina: 'Kanada'},
      {id: '4840', krajina: 'Spojené štáty'},
      {id: '4999', krajina: 'Ostatné americké štáty (nepoužívať)'},
      {id: '3818', krajina: 'Egypt'},
      {id: '3710', krajina: 'Južná Afrika'},
      {id: '3404', krajina: 'Keňa'},
      {id: '3788', krajina: 'Tunisko'},
      {id: '3999', krajina: 'Ostatné africké štáty (nepoužívať)'},
      {id: '2196', krajina: 'Cyprus'},
      {id: '2156', krajina: 'Čína'},
      {id: '2356', krajina: 'India'},
      {id: '2376', krajina: 'Izrael'},
      {id: '2392', krajina: 'Japonsko'},
      {id: '2410', krajina: 'Južná Kórea'},
      {id: '2764', krajina: 'Thajsko'},
      {id: '2792', krajina: 'Turecko'},
      {id: '2704', krajina: 'Vietnam'},
      {id: '2999', krajina: 'Ostatné ázijské štáty (nepoužívať)'},
      {id: '5997', krajina: 'Austrália'},
      {id: '5998', krajina: 'Oceánia'},
      {id: '5554', krajina: 'Nový Zéland'},
      {id: '9900', krajina: 'nešpecifikované (nepoužívať)'},
      {id: '1703', krajina: 'Slovenská republika'},
      {id: '1001', krajina: 'Afganistan'},
      {id: '1002', krajina: 'Alžírsko'},
      {id: '1003', krajina: 'Americká Samoa'},
      {id: '1004', krajina: 'Andorra'},
      {id: '1005', krajina: 'Angola'},
      {id: '1006', krajina: 'Anguilla'},
      {id: '1007', krajina: 'Antarktída'},
      {id: '1009', krajina: 'Antigua a Barbuda'},
      {id: '1010', krajina: 'Arménsko'},
      {id: '1011', krajina: 'Aruba'},
      {id: '1012', krajina: 'Azerbajdžan'},
      {id: '1013', krajina: 'Bahamy'},
      {id: '1014', krajina: 'Bahrajn'},
      {id: '1015', krajina: 'Bangladéš'},
      {id: '1016', krajina: 'Barbados'},
      {id: '1017', krajina: 'Belize'},
      {id: '1018', krajina: 'Benin'},
      {id: '1019', krajina: 'Bermudy'},
      {id: '1020', krajina: 'Bhután'},
      {id: '1021', krajina: 'Bolívia'},
      {id: '1022', krajina: 'Botswana'},
      {id: '1023', krajina: 'Bouvetov ostrov'},
      {id: '1024', krajina: 'Britské indickooceánske územie'},
      {id: '1025', krajina: 'Brunej'},
      {id: '1026', krajina: 'Burkina Faso'},
      {id: '1027', krajina: 'Burundi'},
      {id: '1028', krajina: 'Kambodža'},
      {id: '1029', krajina: 'Kamerun'},
      {id: '1030', krajina: 'Kapverdy'},
      {id: '1031', krajina: 'Kajmanie ostrovy'},
      {id: '1032', krajina: 'Stredoafrická republika'},
      {id: '1033', krajina: 'Čad'},
      {id: '1034', krajina: 'Čile'},
      {id: '1035', krajina: 'Vianočný ostrov'},
      {id: '1036', krajina: 'Kokosové ostrovy'},
      {id: '1037', krajina: 'Kolumbia'},
      {id: '1038', krajina: 'Komory'},
      {id: '1039', krajina: 'Konžská republika'},
      {id: '1041', krajina: 'Konžská demokratická republika'},
      {id: '1042', krajina: 'Cookove ostrovy'},
      {id: '1043', krajina: 'Kostarika'},
      {id: '1044', krajina: 'Pobrežie Slonoviny'},
      {id: '1045', krajina: 'Kuba'},
      {id: '1046', krajina: 'Džibutsko'},
      {id: '1047', krajina: 'Dominika'},
      {id: '1048', krajina: 'Ekvádor'},
      {id: '1049', krajina: 'Salvádor'},
      {id: '1050', krajina: 'Rovníková Guinea'},
      {id: '1051', krajina: 'Eritrea'},
      {id: '1052', krajina: 'Etiópia'},
      {id: '1053', krajina: 'Falklandy'},
      {id: '1054', krajina: 'Faerské ostrovy'},
      {id: '1055', krajina: 'Fidži'},
      {id: '1057', krajina: 'Francúzska Guayana'},
      {id: '1058', krajina: 'Francúzska Polynézia'},
      {id: '1059', krajina: 'Francúzske južné a antarktické územia'},
      {id: '1060', krajina: 'Gabon'},
      {id: '1061', krajina: 'Gambia'},
      {id: '1062', krajina: 'Gruzínsko'},
      {id: '1063', krajina: 'Ghana'},
      {id: '1064', krajina: 'Gibraltár'},
      {id: '1065', krajina: 'Grónsko'},
      {id: '1066', krajina: 'Grenada'},
      {id: '1067', krajina: 'Guadeloupe'},
      {id: '1068', krajina: 'Guam'},
      {id: '1069', krajina: 'Guatemala'},
      {id: '1071', krajina: 'Guinea'},
      {id: '1072', krajina: 'Guinea-Bissau'},
      {id: '1073', krajina: 'Guayana'},
      {id: '1074', krajina: 'Haiti'},
      {id: '1075', krajina: 'Heardov ostrov a Macdonaldove ostrovy'},
      {id: '1076', krajina: 'Vatikán'},
      {id: '1077', krajina: 'Honduras'},
      {id: '1078', krajina: 'Hongkong – OAO Číny'},
      {id: '1079', krajina: 'Indonézia'},
      {id: '1080', krajina: 'Irán'},
      {id: '1081', krajina: 'Irak'},
      {id: '1082', krajina: 'Jamajka'},
      {id: '1083', krajina: 'Jordánsko'},
      {id: '1084', krajina: 'Kazachstan'},
      {id: '1085', krajina: 'Kiribati'},
      {id: '1086', krajina: 'Severná Kórea'},
      {id: '1087', krajina: 'Kuvajt'},
      {id: '1088', krajina: 'Kirgizsko'},
      {id: '1089', krajina: 'Laos'},
      {id: '1090', krajina: 'Libanon'},
      {id: '1091', krajina: 'Lesotho'},
      {id: '1092', krajina: 'Libéria'},
      {id: '1093', krajina: 'Líbya'},
      {id: '1094', krajina: 'Macao – OAO Číny'},
      {id: '1095', krajina: 'Madagaskar'},
      {id: '1096', krajina: 'Malawi'},
      {id: '1097', krajina: 'Malajzia'},
      {id: '1098', krajina: 'Maldivy'},
      {id: '1099', krajina: 'Mali'},
      {id: '1101', krajina: 'Marshallove ostrovy'},
      {id: '1102', krajina: 'Martinik'},
      {id: '1103', krajina: 'Mauritánia'},
      {id: '1104', krajina: 'Maurícius'},
      {id: '1105', krajina: 'Mayotte'},
      {id: '1106', krajina: 'Mikronézia'},
      {id: '1107', krajina: 'Monako'},
      {id: '1108', krajina: 'Mongolsko'},
      {id: '1109', krajina: 'Montserrat'},
      {id: '1110', krajina: 'Maroko'},
      {id: '1111', krajina: 'Mozambik'},
      {id: '1113', krajina: 'Mjanmarsko'},
      {id: '1114', krajina: 'Namíbia'},
      {id: '1115', krajina: 'Nauru'},
      {id: '1116', krajina: 'Nepál'},
      {id: '1117', krajina: 'Nová Kaledónia'},
      {id: '1118', krajina: 'Nikaragua'},
      {id: '1119', krajina: 'Niger'},
      {id: '1120', krajina: 'Nigéria'},
      {id: '1121', krajina: 'Niue'},
      {id: '1122', krajina: 'Norfolk'},
      {id: '1123', krajina: 'Severné Mariány'},
      {id: '1124', krajina: 'Omán'},
      {id: '1125', krajina: 'Pakistan'},
      {id: '1126', krajina: 'Palau'},
      {id: '1127', krajina: 'Palestínske územia'},
      {id: '1128', krajina: 'Panama'},
      {id: '1129', krajina: 'Papua Nová Guinea'},
      {id: '1130', krajina: 'Paraguaj'},
      {id: '1131', krajina: 'Peru'},
      {id: '1132', krajina: 'Filipíny'},
      {id: '1133', krajina: 'Pitcairnove ostrovy'},
      {id: '1134', krajina: 'Portoriko'},
      {id: '1135', krajina: 'Katar'},
      {id: '1136', krajina: 'Réunion'},
      {id: '1137', krajina: 'Rwanda'},
      {id: '1138', krajina: 'Svätá Helena'},
      {id: '1139', krajina: 'Svätý Krištof a Nevis'},
      {id: '1140', krajina: 'Svätá Lucia'},
      {id: '1141', krajina: 'Saint Pierre a Miquelon'},
      {id: '1142', krajina: 'Svätý Vincent a Grenadíny'},
      {id: '1143', krajina: 'Samoa'},
      {id: '1144', krajina: 'San Maríno'},
      {id: '1145', krajina: 'Svätý Tomáš a Princov ostrov'},
      {id: '1146', krajina: 'Saudská Arábia'},
      {id: '1147', krajina: 'Senegal'},
      {id: '1148', krajina: 'Seychely'},
      {id: '1149', krajina: 'Sierra Leone'},
      {id: '1150', krajina: 'Singapur'},
      {id: '1151', krajina: 'Šalamúnove ostrovy'},
      {id: '1152', krajina: 'Somálsko'},
      {id: '1153', krajina: 'Južná Georgia a Južné Sandwichove ostrovy'},
      {id: '1154', krajina: 'Srí Lanka'},
      {id: '1155', krajina: 'Sudán'},
      {id: '1156', krajina: 'Surinam'},
      {id: '1157', krajina: 'Svalbard a Jan Mayen'},
      {id: '1158', krajina: 'Svazijsko'},
      {id: '1159', krajina: 'Sýria'},
      {id: '1160', krajina: 'Taiwan'},
      {id: '1161', krajina: 'Tadžikistan'},
      {id: '1162', krajina: 'Tanzánia'},
      {id: '1163', krajina: 'Východný Timor'},
      {id: '1164', krajina: 'Togo'},
      {id: '1165', krajina: 'Tokelau'},
      {id: '1166', krajina: 'Tonga'},
      {id: '1167', krajina: 'Trinidad a Tobago'},
      {id: '1168', krajina: 'Turkménsko'},
      {id: '1169', krajina: 'Turks a Caicos'},
      {id: '1170', krajina: 'Tuvalu'},
      {id: '1171', krajina: 'Uganda'},
      {id: '1172', krajina: 'Spojené arabské emiráty'},
      {id: '1173', krajina: 'Menšie odľahlé ostrovy USA'},
      {id: '1174', krajina: 'Uruguaj'},
      {id: '1175', krajina: 'Uzbekistan'},
      {id: '1176', krajina: 'Vanuatu'},
      {id: '1177', krajina: 'Venezuela'},
      {id: '1178', krajina: 'Britské Panenské ostrovy'},
      {id: '1179', krajina: 'Americké Panenské ostrovy'},
      {id: '1180', krajina: 'Wallis a Futuna'},
      {id: '1181', krajina: 'Západná Sahara'},
      {id: '1182', krajina: 'Jemen'},
      {id: '1183', krajina: 'Zambia'},
      {id: '1184', krajina: 'Zimbabwe'},
      {id: '1185', krajina: 'Alandy'},
      {id: '1186', krajina: 'Karibské Holandsko'},
      {id: '1187', krajina: 'Curaçao'},
      {id: '1188', krajina: 'Guernsey'},
      {id: '1189', krajina: 'Ostrov Man'},
      {id: '1190', krajina: 'Jersey'},
      {id: '1192', krajina: 'Svätý Bartolomej'},
      {id: '1193', krajina: 'Svätý Martin (fr.)'},
      {id: '1194', krajina: 'Svätý Martin (hol.)'},
      {id: '1195', krajina: 'Južný Sudán'},
      {id: '1196', krajina: 'Kosovo'},
    ];

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ubytovanie/osoby/osoby-detail.html',
        controller: 'UbytovanieOsobaDetailCtrl',
        backdrop: 'static',
        windowClass: 'osoby-detail',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object('ubytovanie/'+ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').ubytovanie;
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(meno) {
        var obj = parent.restangularize({meno: meno});
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query, pagination) {
        return parent.objects().getList(angular.extend({search:query}, pagination));
      },
      getKrajiny: function(kod) {
        if (!angular.isDefined(kod)) {
          return _.sortBy(krajiny, 'krajina');
        }
        return _.find(krajiny, {id: kod});
      },
      updateBulk: function (osoby) {
        var promises = [];
        _.each(osoby, function (osoba) {
          promises.push(
            Restangular.all('ubytovanie/' + ENDPOINT).all(osoba.id).customPUT(osoba)
          );
        });
        return $q.all(promises);
      },
    });
  }]);
