'use strict';

angular.module('casist.ucto')
  .controller('UrgencieCtrl', ['$scope', 'Restangular', 'Urgencia', 'Profile', '$controller', 'Outputs', 'Firma', 'Notification', '$modal', 'TabService', function ($scope, Restangular, Urgencia, Profile, $controller, Outputs, Firma, Notification, $modal, TabService) {
    $scope.urgencie = [];
    $scope.SearchCtrl = null;

    $scope.create = function(item) {
      return Urgencia.edit(item, $scope.urgencie, $scope.SearchCtrl);
    };

    $scope.slideAnimation = true;

    $scope.SearchCtrl = $controller('SearchCtrl');

    $scope.urgencie = [];
    var filterChanged = function(filter) {
      var promise = Urgencia.objects().getList(filter);
      $scope.urgencie.loading = true;
      promise.then(function(data) {
        TabService.registerScope('urgencie', $scope);
        $scope.urgencie = data;
      });
      return promise;
    };
    $scope.Firma = Firma;

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.urgencieTable = [
      { width: 50 },
      { field: 'iddokd', sortField: 'iddokd', label: 'ucto.RECORD', width: 90, type: 'int', filterCollapse: 'right' },
      { field: 'datum', label: 'main.DATUM', width: 90, type: 'date' },
      { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'suma', label: 'rozuctovanie.SUMA', type: 'number', sum: true },
      { field: 'uroky_od', label: 'Úroky od', type: 'date' },
      { field: 'uroky_do', label: 'Úroky do', type: 'date' },
      { field: 'urok', label: 'Ročný úrok %', type: 'number' }
    ];
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.urgencieTable,
      sort: {sort: 'iddokd'}
    });
    $scope.SearchCtrl.refresh();

    var events = Urgencia.registerEvents(function() { return $scope.urgencie; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Urgencia.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    $scope.send = function() {
      Urgencia.send($scope.SearchCtrl.getQuery()).then(function(data) {
        if (!data.email.length && !data.no_email.length) {
          Notification.add('Žiadne urgencie sa nenašli.', 'info', 5);
          return;
        }
        var modalInstance = $modal.open({
          templateUrl: 'ucto/urgencie/hromadne/urgenciehromadne.html',
          controller: 'UrgencieHromadneCtrl',
          backdrop: 'static',
          windowClass: 'detail-md',
          resolve: {
            email: function() {
              return data.email;
            },
            noEmail: function() {
              return data.no_email;
            },
            text: function() {
              return data.email_text;
            }
          }
        });
      }, function() {
        Notification.add('Vytvorenie urgencií sa nepodarilo!', 'danger', 5);
      });
    };

    $scope.print = function(faktura) {
      Outputs.openPrintUrl('urgencie', faktura.id);
    };
    $scope.printList = function() {
      Outputs.openPrintUrl('urgencie', null, $scope.SearchCtrl.getQuery(), 'printlist');
    };
  }]);
