'use strict';

angular.module('casist.admin')
  .factory('TypDPH', ['Restangular', 'BaseModel', 'BaseCollectionModel', '$q', function (Restangular, BaseModel, BaseCollectionModel, $q) {
    Restangular.extendModel('typdph', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });
    return angular.extend(BaseCollectionModel.object('settings/typdph'), {
      valid: function() {
        return BaseCollectionModel.object('settings/typdph/valid');
      },
      getValid: function(datum, modul, ordering) {
        var deferred = $q.defer();
        var typDPHMinPlatnost = new Date(2099, 12, 31), typDPHMaxPlatnost = new Date(1970, 1, 1);
        this.valid().objects().getList({datum: datum, modul: modul, ordering: ordering}).then(function(data) {
          var platne_od, platne_do;
          for (var i = 0; i < data.length; i++) {
            platne_od = parseDate(data[i].platne_od);
            platne_do = parseDate(data[i].platne_do);
            if (platne_od < typDPHMinPlatnost) {
              typDPHMinPlatnost = platne_od;
            }
            if (platne_do > typDPHMaxPlatnost) {
              typDPHMaxPlatnost = platne_do;
            }
          }
          deferred.resolve({data: data, minPlatnost: typDPHMinPlatnost, maxPlatnost: typDPHMaxPlatnost});
        }, function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
    });
  }]);
