'use strict';

angular.module('casist')
  .controller('RecordCtrl', ['$scope', '$rootScope', 'Global', 'Outputs', 'Profile', '$timeout', '$q', 'Restangular', '$http', '$document', 'Model', 'Dialog',
    function ($scope, $rootScope, Global, Outputs, Profile, $timeout, $q, Restangular, $http, $document, Model, Dialog) {

    if (!$scope || isEmpty($scope)) {
      return;
    }

    var main = null;
    var related = null;
      var $modalInstance = null;
    this.utils = {
      parent: this
    };
    this.main = {};
    $scope.errors_related = [];
    var savingInProgress = false;

    var addEnabled = true;

    var perms = (Profile.get('userData').is_superuser || Profile.get('userData').local_superuser === Profile.get('agenda').client) ? {add: true, change: true, delete: true, view: true} : Model.getPerms() || {change: true};
    var discardedChanges = [];
    // marker for deleted record, to prevent multiple notices
    var recordRemoved = false;

    var recordChangedUnsetter = $scope.$on('casist:recordChanged', function(event, data) {
      if ((angular.isDefined($scope[main.model].route) && $scope[main.model].route !== data.route) || discardedChanges.indexOf(data) !== -1) {
        return;
      }
      if (!isEmpty(main.master) && data.id === main.master.id && !objEquals(data, $scope[main.model])) {
        if (window.confirm("Záznam bol upravený. Chcete načítať aktuálne hodnoty?")) {
          $scope[main.model] = angular.copy(data);
          if (related) {
            for (var r = 0; r < related.length; r++) {
              related[r].dataProvider($scope[main.model]);
            }
          }
        } else {
          discardedChanges.push(data);
        }
      }
    });
    var recordRemovedUnsetter = $scope.$on('casist:recordRemoved', function(event, data) {
      if (recordRemoved || (angular.isDefined($scope[main.model].route) && $scope[main.model].route !== data.route)) {
        return;
      }
      if (!isEmpty(main.master) && data.id === main.master.id) {
        recordRemoved = true;
        $modalInstance.dismiss('removed');
        $timeout(function() {
          Dialog.alert('Záznam bol vymazaný.');
        });
      }
    });
    $scope.$on('$destroy', function() {
      recordChangedUnsetter();
      recordRemovedUnsetter();
    });

    var parseErrorMessage = function(errors) {
      var alertMsg = 'Záznam sa nepodarilo zapísať. ';
      if (errors && (errors.error || errors.obdobie || errors.non_field_errors)) {
        alertMsg += '<br>'+(errors.error || errors.obdobie || errors.non_field_errors);
      } else if (Array.isArray(errors)) {
        alertMsg += errors.join('<br>');
      }
      return alertMsg;
    };

    var unsubscribeChanges = function() {
      recordChangedUnsetter();
      recordRemovedUnsetter();
    };

    this.disableAdd = function(disable) {
      addEnabled = !disable;
    };

    var RESULT_OK = 1;
    var RESULT_ERROR = 2;
    var RESULT_VALIDATION = 3;
    var RESULT_PERMS = 4;
    var RESULT_UNCHANGED = 5;
    var RESULT_INPROGRESS = 6;

    this.main.validateAndSave = function() {
      var result = this.saveSynchronous();
      if (result !== RESULT_UNCHANGED && result !== RESULT_OK) {
        if (result === RESULT_ERROR) {
          var alertMsg = parseErrorMessage($scope.errors);
          Dialog.alert(alertMsg, 'Chyba');
          return false;
        }
      }
      return true;
    };

    this.main.print = function(endpoint, id, url) {
      if (!this.validateAndSave()) {
        return;
      }
      if (!id) {
        id = main.master.id;
      }
      Outputs.openPrintUrl(endpoint, id, undefined, url);
    };

    this.main.download = function(endpoint, id) {
      if (!this.validateAndSave()) {
        return;
      }
      if (!id) {
        id = main.master.id;
      }
      Outputs.openDownloadPDFUrl(endpoint, id);
    };

    this.main.init = function(data) {
      if (!data.preSave) {
        data.preSave = function(data, create) {
          return data;
        };
      }
      if (!data.postSave) {
        data.postSave = function(data, created, original) {
          return data;
        };
      }
      if (!data.postDelete) {
        data.postDelete = angular.noop;
      }
      // signal fired when data is changed (navigation, other version loaded)
      if (!angular.isDefined(data.dataChanged)) {
        data.dataChanged = angular.noop;
      }
      if (!angular.isDefined(data.endpoint)) {
        data.endpoint = Model.getEndpoint();
      }
      if (!angular.isDefined(data.searchCtrl)) {
        data.searchCtrl = {
          getPage: function() {
            return 1;
          },
          getNumPages: function() {
            return 1;
          }
        };
      }
      if (!angular.isDefined(data.validate)) {
        data.validate = function() { return true; };
      }
      if (angular.isFunction(data.list)) {
        data.listGetter = data.list;
        data.list = data.listGetter();
      }
      // aby sme nasli prvy non-disabled input, ktory mozeme focusnut
      if (data.focusField && angular.isArray(data.focusField)) {
        data._focusFields = data.focusField;
        data.focusField = data.focusField[0];
      }
      main = data;
      $modalInstance = data.$modalInstance;
    };
    var hasSomethingChanged = function() {
      return (hasRelatedChanged() && ((isEmpty(main.master) && !$scope[main.model].locked && perms.add) || (!isEmpty(main.master) && perms.change))) || ((!isEmpty(main.master) && !$scope[main.model].locked && perms.change && !objEquals(Restangular.stripRestangular(main.master), Restangular.stripRestangular($scope[main.model])))) || (isEmpty(main.master) && perms.add && !objEquals(main.defaultItem, Restangular.stripRestangular($scope[main.model])));
    };
    var closeDialogShown = false;
    this.main.dismiss = function(reason) {
      if (closeDialogShown) {
        return;
      }
      if (hasSomethingChanged()) {
        closeDialogShown = true;
        Dialog.confirm('Naozaj chcete zrušiť zmeny v okne bez uloženia?', 'Upozornenie', {cancelClass: 'default'}).then(function() {
          $modalInstance.dismiss(reason || 'cancel');
        }, function() {
          $timeout(function() {
            closeDialogShown = false;
          });
        });
      } else {
        $modalInstance.dismiss(reason || 'cancel');
      }
    };
    this.main.remove = function() {
      if (!main.master.id)
        return false;

      var deferred = $q.defer();
      Dialog.confirm('Chcete naozaj vymazať záznam?\nÚdaje sa nenávratne vymažú.', 'Pozor', {okClass: 'danger', cancelClass: 'default', icon: 'fa-warning text-danger'}).then(function() {
        var promise = $scope[main.model].delete();
        if (main.list) {
          promise.then(function() {
            var obj = angular.copy(main.master);
            if (main.searchCtrl && main.searchCtrl.tableRemove) {
              main.searchCtrl.tableRemove(main.list, main.master);
            } else {
              main.list.splice(main.list.indexOf(main.master), 1);
            }
            main.postDelete(obj);
          }, function(error) {
            if (error) {
              var alertMsg = 'Nepodarilo sa vymazať záznam.';
              if (error.data && (error.data.error || error.data.obdobie)) {
                alertMsg += '<br>'+(error.data.error || error.data.obdobie);
              }
              Dialog.alert(alertMsg, 'Chyba');
            }
          });
        }
        deferred.resolve(promise);
      }, function() {
        deferred.reject();
      });
      return deferred.promise;
    };

    this.main.save = function(stayInWindow) {
      if (savingInProgress) {
        console.log('Ukladanie uz prebieha...');
        return;
      }
      $scope.errors = [];
      var deferred = $q.defer();

      if (related) {
        for (var r = 0; r < related.length; r++) {
          if (!related[r].endpoint) {
            $scope[main.model][related[r].model] = {};
            var _addedItems = angular.copy(related[r].addedItems);
            var _editedItems = angular.copy(related[r].editedItems);
            var _deletedItems = angular.copy(related[r].deletedItems);
            if (_addedItems.length) {
              for (var i in _addedItems) {
                delete _addedItems[i].id;
              }
              $scope[main.model][related[r].model].added = _addedItems;
            }
            if (_editedItems.length) {
              for (var i in _editedItems) {
                _editedItems[i] = Restangular.stripRestangular(_editedItems[i]);
              }
              $scope[main.model][related[r].model].edited = _editedItems;
            }
            if (_deletedItems.length) {
              $scope[main.model][related[r].model].deleted = _deletedItems;
            }
            if (isEmpty($scope[main.model][related[r].model])) {
              delete $scope[main.model][related[r].model];
            }
          }
        }
      }

      if ($scope[main.model].id) {
        if (!perms.change) {
          $modalInstance.dismiss('cancel');
          return;
        }
        if (!main.validate()) {
          return;
        }
        delete $scope[main.model].created;
        var originalRecord = angular.copy(main.master);
        $scope[main.model] = main.preSave($scope[main.model], false);
        savingInProgress = true;
        $scope[main.model].update(originalRecord).then(function(success) {
          angular.copy(success, $scope[main.model]);
          if (related) {
            for (var r = 0; r < related.length; r++) {
              if (related[r].endpoint) {
                var _addedItems = angular.copy(related[r].addedItems);
                var _editedItems = angular.copy(related[r].editedItems);
                var _deletedItems = angular.copy(related[r].deletedItems);
                if (_addedItems.length) {
                  for (var i in _addedItems) {
                    delete _addedItems[i].id;
                  }
                  main.master.all(related[r].endpoint).post(_addedItems);
                }

                // adjust edited items before sending to the server
                if (_editedItems.length) {
                  for (var i in _editedItems) {
                    _editedItems[i] = Restangular.stripRestangular(_editedItems[i]);
                  }
                  main.master.all(related[r].endpoint).customPUT(_editedItems);
                }

                for (var i in _deletedItems) {
                  _deletedItems[i].remove();
                }
              }
              related[r].addedItems = [];
              related[r].editedItems = [];
              related[r].deletedItems = [];
            }
          }
          var beforePostSave = angular.copy($scope[main.model]);
          $scope[main.model] = main.postSave($scope[main.model], false, originalRecord);
          // fix when postSave doesn't return anything
          if (!angular.isDefined($scope[main.model])) {
            $scope[main.model] = beforePostSave;
          }
          if (!objEquals($scope[main.model], beforePostSave)) {
            Model.syncChange($scope[main.model], beforePostSave);
          }
          angular.copy($scope[main.model], main.master);

          if (!stayInWindow) {
            unsubscribeChanges();
          }
          savingInProgress = false;
          $scope.$broadcast('errorsRefresh');
          deferred.resolve({ type: 'updated', record: $scope[main.model] });
        }, function(error) {
          $scope.errors = error.data;
          var alertMsg = parseErrorMessage($scope.errors);
          $scope.$broadcast('errorsRefresh');
          savingInProgress = false;
          deferred.reject(alertMsg);
        });
      } else {
        if (!perms.add) {
          $modalInstance.dismiss('cancel');
          return;
        }
        if (!main.validate()) {
          return;
        }
        $scope[main.model] = main.preSave($scope[main.model], true);
        savingInProgress = true;
        Model.create($scope[main.model]).then(function(obj) {
          angular.copy(obj, $scope[main.model]);
          if (related) {
            for (var r = 0; r < related.length; r++) {
              if (related[r].endpoint) {
                var _addedItems = angular.copy(related[r].addedItems);
                if (_addedItems.length) {
                  for (var i in _addedItems) {
                    _addedItems[i][related[r].lookup] = data.id;
                    delete _addedItems[i].id;
                  }
                  obj.all(related[r].endpoint).post(_addedItems);
                }
              } else if (angular.isDefined(obj[related[r].model])) {
                related[r].paginateList(angular.copy(obj[related[r].model]), related[r].pagination.pageSize, related[r].pagination.sorter, false);
                delete $scope[main.model][related[r].model];
              }
              related[r].addedItems = [];
            }
          }
          var beforePostSave = angular.copy($scope[main.model]);
          $scope[main.model] = main.postSave($scope[main.model], true);
          // fix when postSave doesn't return anything
          if (!angular.isDefined($scope[main.model])) {
            $scope[main.model] = beforePostSave;
          }
          angular.copy($scope[main.model], main.master);
          if (!objEquals($scope[main.model], beforePostSave)) {
            Model.syncChange($scope[main.model], beforePostSave);
          }
          if (!stayInWindow) {
            unsubscribeChanges();
          }
          savingInProgress = false;
          $scope.$broadcast('errorsRefresh');
          deferred.resolve({ type: 'created', record: $scope[main.model] });
        }, function(error) {
          $scope.errors = error.data;
          var alertMsg = parseErrorMessage($scope.errors);
          savingInProgress = false;
          $scope.$broadcast('errorsRefresh');
          deferred.reject(alertMsg);
        });
      }

      deferred.promise.then(null, function(error) {
        Dialog.alert(error, 'Chyba');
      });

      return deferred.promise;
    }
    this.main.saveSynchronous = function(stayInWindow) {
      if (savingInProgress) {
        console.log('Ukladanie uz prebieha...');
        return RESULT_INPROGRESS;
      }
      $scope.errors = [];
      var result = RESULT_OK;
      var mainUrl = Global.get('serverAddress')+main.endpoint;
      if (main.master.id)
        mainUrl += "/"+main.master.id;
      var relatedUrl = '';
      if (related) {
        relatedUrl = mainUrl+"/"+related.endpoint;
      }
      var params = {
        url: mainUrl,
        async: false,
        type: 'POST',
        headers: {
          'Authorization': $http.defaults.headers.common['Authorization'],
          'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          'Content-Type': 'application/json'
        }
      };

      var relatedHasChange = false;
      if (related) {
        for (var r = 0; r < related.length; r++) {
          if (related[r].addedItems.length || related[r].editedItems.length || related[r].deletedItems.length) {
            relatedHasChange = true;
          }
          if (!related[r].endpoint) {
            $scope[main.model][related[r].model] = {};
            var _addedItems = angular.copy(related[r].addedItems);
            var _editedItems = angular.copy(related[r].editedItems);
            var _deletedItems = angular.copy(related[r].deletedItems);
            if (_addedItems.length) {
              for (var i in _addedItems) {
                delete _addedItems[i].id;
              }
              $scope[main.model][related[r].model].added = _addedItems;
            }
            if (_editedItems.length) {
              for (var i in _editedItems) {
                _editedItems[i] = Restangular.stripRestangular(_editedItems[i]);
              }
              $scope[main.model][related[r].model].edited = _editedItems;
            }
            if (_deletedItems.length) {
              $scope[main.model][related[r].model].deleted = _deletedItems;
            }
            if (isEmpty($scope[main.model][related[r].model])) {
              delete $scope[main.model][related[r].model];
            }
          }
        }
      }

      if ($scope[main.model].id) {
        // TODO: recursive check for arrays (rozuctovanie)
        // if (objEquals($scope[main.model], main.master) && !relatedHasChange) {
        //   return RESULT_UNCHANGED;
        // }
        if (!perms.change) {
          $modalInstance.dismiss('cancel');
          return RESULT_PERMS;
        }
        if (!main.validate()) {
          return RESULT_VALIDATION;
        }
        delete $scope[main.model].created;
        var originalRecord = angular.copy(main.master);
        $scope[main.model] = main.preSave($scope[main.model], false);
        savingInProgress = true;
        $scope[main.model].update_sync(originalRecord).then(function(data) {
          copyWeakSrc(data, $scope[main.model]);
          if (related) {
            for (var r = 0; r < related.length; r++) {
              if (related[r].endpoint) {
                var _addedItems = angular.copy(related[r].addedItems);
                var _editedItems = angular.copy(related[r].editedItems);
                var _deletedItems = angular.copy(related[r].deletedItems);
                params.url = relatedUrl;
                if (_addedItems.length) {
                  params.type = 'POST';
                  for (var i in _addedItems) {
                    delete _addedItems[i].id;
                  }
                  params.data = _addedItems;
                  $.ajax(params).fail(function() { result = RESULT_ERROR; });
                }

                if (_editedItems.length) {
                  params.type = 'PUT';
                  for (var i in _editedItems) {
                    _editedItems[i] = Restangular.stripRestangular(_editedItems[i]);
                  }
                  params.data = JSON.stringify(_editedItems);
                  $.ajax(params).fail(function() { result = RESULT_ERROR; });
                }

                if (_deletedItems.length) {
                  params.data = '';
                  for (var i in _deletedItems) {
                    params.url = relatedUrl + '/' + _deletedItems[i].id;
                    params.type = 'DELETE';
                    $.ajax(params).fail(function() { result = RESULT_ERROR; });
                  }
                }
              }
              related[r].addedItems = [];
              related[r].editedItems = [];
              related[r].deletedItems = [];
            }
          }
          if (result !== RESULT_OK) {
            savingInProgress = false;
            return;
          }
          var beforePostSave = angular.copy($scope[main.model]);
          $scope[main.model] = main.postSave($scope[main.model], false, originalRecord);
          // fix when postSave doesn't return anything
          if (!angular.isDefined($scope[main.model])) {
            $scope[main.model] = beforePostSave;
          }
          if (!objEquals($scope[main.model], beforePostSave)) {
            Model.syncChange($scope[main.model], beforePostSave);
          }
          angular.copy($scope[main.model], main.master);

          if (!stayInWindow) {
            unsubscribeChanges();
          }
          savingInProgress = false;
        }, function(error) {
          if (error) {
            $scope.errors = error.data ? error.data : error;
          }
          result = RESULT_ERROR;
          savingInProgress = false;
          $scope.$broadcast('errorsRefresh');
        });
      } else {
        if (!perms.add) {
          $modalInstance.dismiss('cancel');
          return;
        }
        if (!main.validate()) {
          return RESULT_VALIDATION;
        }
        $scope[main.model] = main.preSave($scope[main.model], true);
        savingInProgress = true;
        Model.create_sync($scope[main.model]).then(function(data) {
          var orig = angular.copy(data);
          var obj = Restangular.restangularizeElement(null, data, main.endpoint);
          angular.copy(obj, $scope[main.model]);
          mainUrl += "/"+data.id;
          if (related) {
            for (var r = 0; r < related.length; r++) {
              if (related[r].endpoint) {
                params.url = mainUrl+"/"+related[r].endpoint;
                var _addedItems = angular.copy(related[r].addedItems);
                if (_addedItems.length) {
                  for (var i in _addedItems) {
                    _addedItems[i][related[r].lookup] = data.id;
                  }
                  params.data = JSON.stringify(_addedItems);
                  $.ajax(params).fail(function() { result = RESULT_ERROR; });
                }
              // load current data returned by server (in case they were sent together with the main record)
              } else if (angular.isDefined(data[related[r].model])) {
                related[r].paginateList(angular.copy(data[related[r].model]), related[r].pagination.pageSize, related[r].pagination.sorter, false);
                delete $scope[main.model][related[r].model];
              }
              related[r].addedItems = [];
            }
          }
          if (result !== RESULT_OK) {
            return;
          }
          var beforePostSave = angular.copy($scope[main.model]);
          $scope[main.model] = main.postSave($scope[main.model], true);
          // fix when postSave doesn't return anything
          if (!angular.isDefined($scope[main.model])) {
            $scope[main.model] = beforePostSave;
          }
          angular.copy($scope[main.model], main.master);

          if (!objEquals($scope[main.model], beforePostSave)) {
            Model.syncChange($scope[main.model], beforePostSave);
          }
          if (!stayInWindow) {
            unsubscribeChanges();
          }
          savingInProgress = false;
        }, function(error) {
          if (error) {
            $scope.errors = error.data ? error.data : error;
          }
          result = RESULT_ERROR;
          savingInProgress = false;
          $scope.$broadcast('errorsRefresh');
        });
      }
      return result;
    }
    this.main.loadVersion = function (version, item, version_id) {
      $scope.errors = [];
      if (related) {
        for (var r = 0; r < related.length; r++) {
          if (angular.isDefined(item[related[r].model])) {
            $scope.RecordCtrl.related.loadListVersion(item[related[r].model]);
          }
        }
      }
      main.dataChanged(item, version_id);
      $scope.$broadcast('errorsRefresh');
    }

    this.related = {};

    this.related.init = function(data) {
      var self = this;
      if (angular.isArray(data)) {
        related = data;
      } else {
        related = [data];
      }
      for (var i = 0; i < related.length; i++) {
        related[i].master = undefined;
        related[i].addedItems = [];
        related[i].editedItems = [];
        related[i].originalItems = [];
        related[i].deletedItems = [];
        related[i].editingItems = [];
        if (!related[i].preEdit) {
          related[i].preEdit = function(item) {
            return item;
          };
        }
        if (!related[i].preSave) {
          related[i].preSave = function(item, create) {
            return item;
          };
        }
        if (!related[i].postSave) {
          related[i].postSave = function(item, created) {
            return item;
          };
        }
        if (!related[i].postDelete) {
          related[i].postDelete = angular.noop;
        }
        if (!related[i].dataProvider) {
          related[i].dataProvider = function(master) {
            var relObj = this;
            isRecordLoading = true;
            $scope[relObj.model] = [];
            relObj.pagination.fullList = [];
            master.getList(relObj.endpoint ? relObj.endpoint : relObj.object.getEndpoint()).then(function(data) {
              self.paginateList(data, relObj.pagination.pageSize, relObj.pagination.sorter, false);
              main.dataChanged();
              isRecordLoading = false;
            }, function() {
              isRecordLoading = false;
            });
          };
        }
        related[i].pagination = {
          fullList: [],
          pageSize: 99999999,
          sorter: undefined,
          columnsDef: related[i].columnsDef
        };
        related[i].paginateList = function(list, pageSize, sorter, performSort) {
          var self = this;
          if (!self.searchCtrl) {
            return;
          }
          if (!angular.isDefined(performSort)) {
            performSort = true;
          }
          if (sorter) {
            self.pagination.sorter = sorter;
            if (performSort) {
              list = sortList(list, sorter);
            }
          }
          if (pageSize) {
            self.pagination.pageSize = pageSize;
          }
          self.searchCtrl.setPerPage(self.pagination.pageSize);
          self.pagination.fullList = angular.copy(list);
          if (angular.isDefined(self.pagination.columnsDef)) {
            var numberFields = _.filter(self.pagination.columnsDef, {type: 'number'});
            for (var i = 0; i < numberFields.length; i++) {
              for (var j = 0; j < self.pagination.fullList.length; j++) {
                self.pagination.fullList[j][numberFields[i].field] = parseFloat(self.pagination.fullList[j][numberFields[i].field]);
              }
            }
          }
          if (!angular.isDefined(self.master)) {
            self.master = angular.copy(list);
          }
          if (self.pagination.pageSize === 99999999) {
            $scope[self.model] = angular.copy(self.pagination.fullList);
          } else {
            $timeout(function() {
              var page = self.searchCtrl.getPage();
              var numPages = Math.ceil(self.pagination.fullList.length / pageSize);
              if (!page || self.searchCtrl._page === 'last') {
                page = numPages || 1;
                self.searchCtrl.updateLastPage(page);
              }
              $scope[self.model] = [];
              var pageStart = (page-1) * pageSize;
              var pageEnd = Math.min(pageStart + pageSize, self.pagination.fullList.length);
              for (var i = pageStart; i < pageEnd; i++) {
                $scope[self.model].push(self.pagination.fullList[i]);
              }
            });
          }
        };
      }
      self.internalContext = related;
    }
    this.related.isAdded = function (ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      return angular.isDefined(_.find(related[ctx].addedItems, {id: item.id}));
    }
    this.related.paginateList = function(ctx, list, pageSize, sorter, performSort) {
      if (arguments.length <= 4) {
        performSort = sorter;
        sorter = pageSize;
        pageSize = list;
        list = ctx;
        ctx = 0;
      }
      related[ctx].paginateList(list, pageSize, sorter, performSort);
    };
    this.related.getFullList = function(ctx) {
      return related[ctx || 0].pagination.fullList;
    };
    this.related.getSearchCtrl = function(ctx) {
      return angular.isDefined(ctx) ? related[ctx].searchCtrl : related[0].searchCtrl;
    };
    this.related.renderListPage = function(ctx, sorter) {
      if (!angular.isDefined(sorter)) {
        sorter = ctx;
        ctx = 0;
      }
      var self = related[ctx];
      var performSort = false;
      if (sorter) {
        self.pagination.sorter = sorter;
        performSort = true;
      }
      this.paginateList(self.pagination.fullList, self.pagination.pageSize, self.pagination.sorter, performSort);
    };
    this.related.getListLength = function(ctx) {
      if (!angular.isDefined(ctx)) {
        ctx = 0;
      }
      return related[ctx].pagination.fullList.length;
    };
    this.related.hasMorePages = function(ctx) {
      if (!angular.isDefined(ctx)) {
        ctx = 0;
      }
      return (related[ctx].pagination.fullList.length > related[ctx].pagination.pageSize);
    };
    this.related.add = function(ctx, item) {
      // if called only with item argument, ctx is 0
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      $scope.errors_related[ctx] = [];
      var obj;
      if (related[ctx].object) {
        obj = related[ctx].object.restangularize(item);
      } else {
        obj = Restangular.restangularizeElement(null, item, related[ctx].endpoint);
      }
      if (!angular.equals(item, {}))
      {
        if (!obj.validate($scope[main.model])) {
          $scope.errors_related[ctx] = obj.errors().data;
          Dialog.alert('Chyba pri zápise položky.', 'Chyba');
          return;
        }
        var pol = angular.copy(item);
        pol = related[ctx].preSave(pol, true);
        if (!isEmpty(main.master) && main.master.id)
          pol[related[ctx].lookup] = main.master.id;

        related[ctx].addedItems.push(pol);
        // bind a virtual id field to the item by finding max id from the list and increasing it by one (to enable editing feature)
        pol.id = 0;
        if (!angular.isDefined($scope[related[ctx].model])) {
          $scope[related[ctx].model] = [];
        }
        for (var i = 0; i < $scope[related[ctx].model].length; i++) {
          if ($scope[related[ctx].model][i].id > pol.id)
            pol.id = $scope[related[ctx].model][i].id;
        }
        pol.id++;
        related[ctx].pagination.fullList.push(pol);
        $scope[related[ctx].model].push(pol);
        if ($scope[related[ctx].model].length > related[ctx].pagination.pageSize) {
          related[ctx].searchCtrl.setLastPage(true);
        }

        angular.copy({}, item);
        if (related[ctx].object) {
          related[ctx].object.restangularize(item);
        }
        related[ctx].postSave(pol, true);
      }
    }
    this.related.remove = function (ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      var index = $scope[related[ctx].model].indexOf(item);
      if (index == -1)
        return;

      // if item was just added, delete it from addedItems, else push it to deleted and delete it also from edited, in case it was there
      var addedItemIndex = -1;
      if ( (addedItemIndex = related[ctx].addedItems.indexOf(item)) > -1)
        related[ctx].addedItems.splice(addedItemIndex, 1);
      else if (!isEmpty(main.master)) {
        var editedItemIndex = -1;
        if ( (editedItemIndex = related[ctx].editedItems.indexOf(item)) > -1)
          related[ctx].editedItems.splice(editedItemIndex, 1);
        related[ctx].deletedItems.push(item);
      }
      $scope[related[ctx].model].splice(index, 1);
      related[ctx].pagination.fullList.splice(related[ctx].pagination.fullList.indexOf(item), 1);
      if ($scope[related[ctx].model].length === 0) {
        this.renderListPage(ctx);
      }
      related[ctx].postDelete();
    }
    this.related.edit = function(ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      // if creating, then added should be the only source of data
      var addedItemIndex = related[ctx].addedItems.indexOf(item);
      if (addedItemIndex > -1) {
        related[ctx].addedItems[addedItemIndex] = item;
      } else if (related[ctx].editedItems.indexOf(item) == -1) {
        related[ctx].editedItems.push(item);
      }
    }
    this.related.isEditing = function (ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      return related[ctx].editingItems[item.id] !== undefined;
    }
    this.related.getEditingItem = function(ctx, id) {
      if (!angular.isDefined(id)) {
        id = ctx;
        ctx = 0;
      }
      return related[ctx].editingItems[id];
    }
    this.related.getEditingItems = function(ctx) {
      if (!angular.isDefined(ctx)) {
        ctx = 0;
      }
      return related[ctx].editingItems;
    }
    this.related.startEditing = function(ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      related[ctx].originalItems[item.id] = angular.copy(item);
      item = related[ctx].preEdit(item);
      related[ctx].editingItems[item.id] = item;
    }
    this.related.finishEditing = function (ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      item = related[ctx].preSave(item, false);
      this.edit(item);

      delete related[ctx].editingItems[item.id];
      delete related[ctx].originalItems[item.id];

      item = related[ctx].postSave(item, false);
    }

    this.related.cancelEditing = function (ctx, item) {
      if (!angular.isDefined(item)) {
        item = ctx;
        ctx = 0;
      }
      angular.copy(related[ctx].originalItems[item.id], related[ctx].editingItems[item.id]);

      delete related[ctx].editingItems[item.id];
      delete related[ctx].originalItems[item.id];
    }
    /**
     * Load related list from version history
     * It calculates diff between master list and version list and populates added, edited and deleted items list accordingly.
     * @param  int ctx        related context
     * @param  array<pohyb> list       new list from version
     * @param  int version_id version ID
     */
    this.related.loadListVersion = function(ctx, list) {
      if (arguments.length === 1) {
        list = ctx;
        ctx = 0;
      }
      var self = related[ctx];
      this.paginateList(ctx, list, self.pagination.pageSize, related[ctx].pagination.sorter, true);
      self.addedItems = [];
      self.editedItems = [];
      self.deletedItems = [];
      var masterItem, edited;
      for (var i = 0; i < list.length; i++) {
        if (self.object) {
          list[i] = self.object.restangularize(list[i]);
        } else {
          list[i] = Restangular.restangularizeElement(null, list[i], self.endpoint);
        }
        masterItem = _.find(self.master, {id: list[i].id});
        if (!masterItem) {
          self.addedItems.push(list[i]);
        } else if (!objEquals(masterItem, list[i])) {
          edited = angular.copy(masterItem);
          angular.copy(list[i], edited);
          self.editedItems.push(edited);
        }
      }
      if (self.master) {
        for (var i = 0; i < self.master.length; i++) {
          if (!_.find(list, {id: self.master[i].id})) {
            self.deletedItems.push(self.master[i]);
          }
        }
      }
    };

    this.related.loadVersion = function (version, item, version_id) {
      var ctx = 0;
      var editIndex = -1;
      if ( (editIndex = related[ctx].editedItems.indexOf(item)) === -1) {
        if (version_id !== 0)
          related[ctx].editedItems.push(item);
      } else {
        if (version_id === 0)
          related[ctx].editedItems.splice(editIndex, 1);
        else related[ctx].editedItems[editIndex] = item;
      }
      item = related[ctx].postSave(item, false);
    }

    this.related.formSubmitter = function(evt, fc) {
      if (!evt.shiftKey && !evt.altKey && evt.which === 13) {
        var args = Array.prototype.splice.call(arguments, 2);
        var obj = this;
        $timeout(function() {
          // to apply ng-blur before function
          if (evt.target)
            evt.target.blur();
          $scope.$eval(fc).apply(obj, args);
        });
        evt.preventDefault();
        evt.stopPropagation();
      }
    };
    this.utils.formSubmitter = function(evt, fc) {
      if (!evt.shiftKey && !evt.altKey && evt.which == 13) {
        var args = Array.prototype.splice.call(arguments, 2);
        $timeout(function() {
          // to apply ng-blur before function
          if (evt.target)
            evt.target.blur();
          // $document.find('button').focus();
          $scope.$eval(fc).apply(null, args);
        });
      }
    }

    var isRecordLoading = false;
    this.main.recordLoading = function() {
      return isRecordLoading;
    }

    this.main.hasPrevRecord = function () {
      return (main.searchCtrl && main.searchCtrl.getPage() > 1) || ((!isEmpty(main.master) && main.list.indexOf(_.find(main.list, {id: main.master.id})) > 0) || ((isEmpty(main.master) || !main.master.id) && main.list.length));
    };
    var hasRelatedChanged = function() {
      if (!related) {
        return false;
      }
      for (var i = 0; i < related.length; i++) {
        if (related[i].addedItems.length || related[i].editedItems.length || related[i].deletedItems.length) {
          return true;
        }
      }
      return false;
    };
    var clearRelatedChanges = function() {
      if (!related) {
        return false;
      }
      for (var i = 0; i < related.length; i++) {
        related[i].addedItems = [];
        related[i].editedItems = [];
        related[i].deletedItems = [];
      }
    };
    this.clearRelatedChanges = clearRelatedChanges;
    this.hasRelatedChanged = hasRelatedChanged;
    var loadingInProgress = false;
    this.main.prevRecord = function (index) {
      if (main.listGetter) {
        main.list = main.listGetter();
      }
      if (!angular.isDefined(index))
        index = ((!isEmpty(main.master) && main.master.id) ? main.list.indexOf(_.find(main.list, {id: main.master.id})) : main.list.length);
      if (index <= 0) {
        if (main.searchCtrl.getPage() > 1) {
          var self = this;
          main.searchCtrl.setPreviousPage().then(function(data) {
            if (main.listGetter) {
              main.list = main.listGetter();
            } else {
              main.list = data;
            }
            index = main.list.length;
            self.prevRecord(index);
          });
        }
        return;
      }
      var focusedElement = angular.element('#'+((angular.isFunction(main.focusField) ? main.focusField() : main.focusField) || document.activeElement.id));;

      var setRecord = function(index) {
        clearRelatedChanges();
        $scope.errors = [];
        $scope.$broadcast('errorsRefresh');

        loadingInProgress = true;
        main.master = main.list[index-1];
        $scope[main.model] = Restangular.copy(main.master);
        if (related) {
          for (var r = 0; r < related.length; r++) {
            related[r].dataProvider(main.master);
          }
        }
        loadingInProgress = false;
        main.dataChanged();
        focusedElement = angular.element('#'+((angular.isFunction(main.focusField) ? main.focusField() : main.focusField) || document.activeElement.id));
        $timeout(function() {
          if (focusedElement[0] && focusedElement[0].disabled && main._focusFields) {
            var i = 1;
            while (i < main._focusFields.length && (focusedElement = angular.element('#'+(main._focusFields[i])))[0].disabled) {
              i++;
            }
          }
          focusedElement.focus();
        });
      }
      if (loadingInProgress || isRecordLoading) {
        return;
      }
      if (hasSomethingChanged())
      {
        if (isEmpty(main.master)) {
          console.log('default item', objDiffRecursive(angular.copy(Restangular.stripRestangular(main.defaultItem)), angular.copy(Restangular.stripRestangular($scope[main.model]))));
        } else {
          console.log('master', objDiffRecursive(angular.copy(Restangular.stripRestangular(main.master)), angular.copy(Restangular.stripRestangular($scope[main.model]))));
        }
        var self = this;
        Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
          var promise = self.save(true);
          if (promise) {
            promise.then(function(success) {
              setRecord(index);
            }, function(error) {
              Dialog.alert(error, 'Chyba').then(function() {
                focusedElement.focus();
              });
            });
          }
         }, function() {
            setRecord(index);
         });
      } else {
        setRecord(index);
      }
    }
    this.main.hasNextRecord = function() {
      var index = main.list.indexOf(_.find(main.list, {id: main.master.id}));
      return (index !== -1 && ((!isEmpty(main.master) && (index < main.list.length-1 || (addEnabled && perms.add))) || main.searchCtrl.getPage() < main.searchCtrl.getNumPages()) || (addEnabled && perms.add && !objEquals(main.defaultItem, Restangular.stripRestangular($scope[main.model]))));
    }
    this.main.nextRecord = function (index) {
      if (!angular.isDefined(index))
        index = main.list.indexOf(_.find(main.list, {id: main.master.id}));
      var parent = this;

      var focusedElement;

      var setRecord = function(index) {
        clearRelatedChanges();
        $scope.errors = [];
        $scope.$broadcast('errorsRefresh');

        if (main.listGetter) {
          main.list = main.listGetter();
        }

        if (index >= main.list.length-1) {
          if (!addEnabled) {
            return;
          }
          if (main.searchCtrl.getPage() < main.searchCtrl.getNumPages()) {
            // this will force parent to load new data and we will keep a copy of that in our main.list
            main.searchCtrl.setNextPage().then(function(data) {
              main.list = data;
              index = -1;
              parent.nextRecord(index);
            });
            return;
          }
          if (!perms.add) {
            return;
          }
          if (main.listGetter) {
            main.list = main.listGetter();
          }
          main.master = {};
          // angular.copy(main.defaultItem, $scope[main.model]);
          $scope[main.model] = Model.restangularize(angular.copy(main.defaultItem));
          if (related) {
            for (var r = 0; r < related.length; r++) {
              $scope[related[r].model] = [];
              related[r].pagination.fullList = [];
            }
          }
          focusedElement = angular.element('#'+((angular.isFunction(main.focusField) ? main.focusField() : main.focusField) || document.activeElement.id));
          main.dataChanged();
          $timeout(function() {
            if (focusedElement[0] && focusedElement[0].disabled && main._focusFields) {
              var i = 1;
              while (i < main._focusFields.length && (focusedElement = angular.element('#'+(main._focusFields[i])))[0].disabled) {
                i++;
              }
            }
            focusedElement.focus();
          });
          return;
        }

        loadingInProgress = true;
        main.master = main.list[index+1];
        $scope[main.model] = Restangular.copy(main.master);
        if (related) {
          for (var r = 0; r < related.length; r++) {
            related[r].dataProvider(main.master);
          }
        }
        loadingInProgress = false;
        main.dataChanged();
        focusedElement = angular.element('#'+((angular.isFunction(main.focusField) ? main.focusField() : main.focusField) || document.activeElement.id));
        $timeout(function() {
          if (focusedElement[0] && focusedElement[0].disabled && main._focusFields) {
            var i = 1;
            while (i < main._focusFields.length && (focusedElement = angular.element('#'+(main._focusFields[i])))[0].disabled) {
              i++;
            }
          }
          focusedElement.focus();
        });
      }
      if (isEmpty(main.master) || !$scope[main.model].id) {
        if (!objEquals(main.defaultItem, Restangular.stripRestangular($scope[main.model]))) {
          var self = this;
          Dialog.confirm('Chcete uložiť nový záznam?', 'Vytvorenie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
            var promise = self.save(true);
            if (promise) {
              promise.then(function(success) {
                index = main.list.length;
                setRecord(index);
              }, function(error) {
                Dialog.alert(error, 'Chyba').then(function() {
                  focusedElement.focus();
                });
              });
            }
          });
          return;
        } else {
          return;
        }
      }

      if (loadingInProgress || isRecordLoading) {
        return;
      }
      if (!$scope[main.model].locked && perms.change && (hasRelatedChanged() || !objEquals(Restangular.stripRestangular(main.master), Restangular.stripRestangular($scope[main.model])) ))
      {
        console.log(objDiffRecursive(angular.copy(Restangular.stripRestangular(main.master)), angular.copy(Restangular.stripRestangular($scope[main.model]))));
        var self = this;
        Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
          var promise = self.save(true);
          if (promise) {
            promise.then(function(success) {
              setRecord(index);
            }, function(error) {
              Dialog.alert(error, 'Chyba').then(function() {
                focusedElement.focus();
              });
            });
          }
        }, function() {
          setRecord(index);
        });
      } else {
        setRecord(index);
      }
    };
  }]);
