"use strict";

angular.module("casist.sklad").factory("ZalohovaFaktura", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate
  ) {
    var ENDPOINT = "zalohovefaktury";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst == "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma == "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          return result;
        },
        fakturacia: function () {
          return this.all("fakturacia").post({});
        },
      });
    });

    var openDetail = function (templateUrl, item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: "ZalohoveFakturyDetailCtrl",
        backdrop: "static",
        windowClass: "detail",
        keyboard: false,
        resolve: {
          zfaktura: function () {
            return item ? item : {};
          },
          zfaktury: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
        },
      });
      return modalInstance.result;
    };
    return angular.extend(BaseCollectionModel.object(ENDPOINT), {
      getPerms: function () {
        return Profile.get("perms").zalohovafaktura;
      },
      edit: function (doklad, list, searchCtrl) {
        var promise = $q.defer();
        if (doklad) {
          this.restangularize(doklad);
        }
        openDetail(
          "sklad/zalohovefaktury/zalohovefaktury-detail.html",
          doklad,
          list,
          searchCtrl
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
    });
  },
]);
