'use strict';

angular.module('casist.ucto')
  .factory('ZavazkyTyp', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel('zavazkytypy', function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          var result = BaseModel.validate();
          if (!this.typ) {
            this.addError('typ', 'Pole nemôže byť prázdne');
            result = false;
          }
          return result;
        }
      });
    });
    var parent = BaseCollectionModel.object('zavazky/zavazkytypy');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').zavazky;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      },
      add: function(typ) {
        var promise = parent.create({typ: typ});
        promise.then(function (result) {
          console.log('Typ added: ', result);
        }, function (error) {
          console.log('Chyba: ', error);
        });
        return promise;
      },
      edit: function (typ) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Typ', 'Upraviť typ', typ.typ).then(function(str) {
          typ.typ = str;
          typ.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      remove: function (typ) {
        console.log('Remove typ: ', typ);
        return typ.remove();
      }
    });
  }]);
