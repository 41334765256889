'use strict';

angular.module('casist.admin')
  .controller('SetupCtrl', ['$scope', 'Profile', 'Agenda', 'Notification', 'DanoveUrady',
    function($scope, Profile, Agenda, Notification, DanoveUrady) {
    $scope.slideAnimation = true;
    $scope.agenda = {};
    $scope.errors = [];
    $scope.agendaDone = false;
    $scope.danoveUrady = DanoveUrady;

    if (!isEmpty(Profile.get('agendy'))) {
      $location.path('/');
    }

    $scope.setup = function() {
      $scope.errors = [];

      var tmpAgenda = Agenda.restangularize(angular.copy($scope.agenda));
      if (!tmpAgenda.validate()) {
        $scope.errors = tmpAgenda.errors().data;
      } else {
        $scope.inProgress = true;
        Agenda.create(angular.extend($scope.agenda, {client: 0})).then(function() {
          $scope.agendaDone = true;
        }, function(error) {
          Notification.add('Nepodarilo sa vytvoriť firmu.', 'danger');
          $scope.inProgress = false;
        });
      }
    };
  }]);