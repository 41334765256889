"use strict";

angular.module("casist.ucto").controller("RozuctovanieCtrl", [
  "$scope",
  "$timeout",
  "$modal",
  "Restangular",
  "Socket",
  "$rootScope",
  "Profile",
  "$controller",
  "Rozuctovanie",
  "RozuctovanieSposob",
  "Firma",
  "Outputs",
  "Doklad",
  "TabService",
  function (
    $scope,
    $timeout,
    $modal,
    Restangular,
    Socket,
    $rootScope,
    Profile,
    $controller,
    Rozuctovanie,
    RozuctovanieSposob,
    Firma,
    Outputs,
    Doklad,
    TabService
  ) {
    $scope.slideAnimation = true;

    var defaultPeriod = {
      d_zdp__gte: Profile.get("obdobie", new Date().getFullYear()) + "-01-01",
      d_zdp__lte: Profile.get("obdobie", new Date().getFullYear()) + "-12-31",
    };
    var defaultSort = { sort: "dd,iddokd", reverse: false };
    $scope.sposoby = [];
    $scope.currentView = 0;
    $scope.rozuctovanieTable = [[]];
    var iddokdSort = function (field, reverse) {
      return "dd," + field;
    };
    RozuctovanieSposob.objects()
      .getList()
      .then(function (data) {
        $scope.sposoby = data;
        var coreFields = [
          {
            field: "cislo",
            label: "rozuctovanie.POLOZKA",
            colspan: $scope.sposoby.length,
            class: "text-center rozuctovanie-polozka-label",
            filterType: "icontains",
          },
          {
            field: "nazov",
            label: "rozuctovanie.NAZOV",
            filterType: "icontains",
            rowspan: 2,
          },
          {
            field: "naklady",
            label: "rozuctovanie.NAKLADY",
            type: "number",
            sum: true,
            rowspan: 2,
          },
          {
            field: "vynosy",
            label: "rozuctovanie.VYNOSY",
            type: "number",
            sum: true,
            rowspan: 2,
          },
          {
            field: "zisk",
            label: "rozuctovanie.ZISK",
            type: "number",
            sum: true,
            rowspan: 2,
          },
        ];
        var newTable = [
          _.union(
            [
              { width: 30, rowspan: 2 },
              {
                field: "iddokd",
                sortField: iddokdSort,
                label: "ucto.RECORD",
                width: 100,
                type: "int",
                filterCollapse: "right",
                rowspan: 2,
              },
              {
                field: "d_zdp",
                label: "ucto.SUPPLIED",
                type: "date",
                width: 90,
                filterCollapse: "right",
                rowspan: 2,
              },
              {
                field: "firma",
                label: "firmy.FIRMA",
                placeholder: "ucto.FIRMA_ICO",
                linked: {
                  source: "Firma.get(query)",
                  template: "linked-element-match-table",
                  lookup: "firma",
                  style: "{width: '400px'}",
                },
                rowspan: 2,
              },
            ],
            coreFields
          ),
          coreFields,
        ];
        $scope.rozuctovanieTable = newTable;
        $scope.SearchCtrl.changeColumnsDef(
          $scope.rozuctovanieTable[$scope.currentView]
        );
      });

    $scope.sortPolozka = function (pol) {
      var filter = $scope.SearchCtrl.getQuery();
      if (!filter.sposob || filter.sposob !== pol.id) {
        $scope.SearchCtrl.sorter.sort = "cislo";
        $scope.SearchCtrl.filterSelection("sposob", pol.id, true);
      } else {
        $scope.SearchCtrl.sorter.reverse = !$scope.SearchCtrl.sorter.reverse;
        $scope.SearchCtrl.setSort($scope.SearchCtrl.sorter);
      }
    };
    $scope.filterSumarne = function () {
      $scope.SearchCtrl.filterCheckbox("sumarne", "polozka__cislo", false);
      if ($scope.SearchCtrl.filters.top.sumarne) {
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.setSort({ sort: "cislo", reverse: false });
      } else {
        $scope.SearchCtrl.setLastPage(true);
      }
    };
    $scope.filterMedzisumy = function () {
      $scope.SearchCtrl.filterCheckbox("medzisumy", "polozka__cislo", false);
      if ($scope.SearchCtrl.filters.top.sumarne) {
        $scope.SearchCtrl.filterCheckbox("sumarne", "polozky", false);
      }
      if (
        $scope.SearchCtrl.filters.top.medzisumy ||
        $scope.SearchCtrl.filters.top.sumarne
      ) {
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.setSort({ sort: "cislo", reverse: false });
      } else {
        $scope.SearchCtrl.setLastPage(true);
      }
    };
    $scope.rozuctovanie = [];
    var filterChanged = function (filter) {
      var promise = Rozuctovanie.objects().getList(filter);
      $scope.rozuctovanie.loading = true;
      promise.then(function (data) {
        TabService.registerScope("rozuctovanie", $scope);
        if (filter.sumarne) {
          if ($scope.currentView !== 1) {
            $scope.currentView = 1;
            $scope.SearchCtrl.changeColumnsDef(
              $scope.rozuctovanieTable[$scope.currentView]
            );
            $scope.containerStyle = { "max-width": "850px", align: "left" };
          }
          if (filter.medzisumy) {
            $scope.SearchCtrl.filterCheckbox("medzisumy", true, false);
            delete filter.medzisumy;
          }
        } else if ($scope.currentView !== 0) {
          $scope.currentView = 0;
          $scope.SearchCtrl.changeColumnsDef(
            $scope.rozuctovanieTable[$scope.currentView]
          );
          $scope.containerStyle = {};
        }
        $scope.rozuctovanie = data;
        if (filter.medzisumy) {
          processSumLines(
            $scope.rozuctovanie,
            data,
            filter.medzisumy,
            function (obj, lookup) {
              return obj.cislo;
            }
          );
        }
      });
      return promise;
    };
    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(
      Profile.addObserver("agenda", function () {
        $scope.SearchCtrl.cancelFilter(false);
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.refresh();
      })
    );
    unregisterProfileObservers.push(
      Profile.addObserver("obdobie", function (obdobie) {
        defaultPeriod = {
          d_zdp__gte:
            Profile.get("obdobie", new Date().getFullYear()) + "-01-01",
          d_zdp__lte:
            Profile.get("obdobie", new Date().getFullYear()) + "-12-31",
        };
        $scope.SearchCtrl.init({
          defaultPeriod: defaultPeriod,
          onChange: filterChanged,
        });
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.refresh();
      })
    );
    $scope.SearchCtrl = $controller("SearchCtrl");
    $scope.Firma = Firma;

    $scope.SearchCtrl.init({
      model: Rozuctovanie,
      defaultPeriod: defaultPeriod,
      onChange: filterChanged,
      columnsDef: $scope.rozuctovanieTable,
      sort: defaultSort,
    });

    $scope.SearchCtrl.refresh();

    $scope.print = function () {
      Outputs.openPrintUrl("rozuctovanie");
    };
    $scope.printList = function () {
      Outputs.openPrintUrl("rozuctovanie", null, $scope.SearchCtrl.getQuery());
    };

    $scope.editDoklad = function (rozuct) {
      Doklad.editRecord(rozuct.doklad, rozuct.dd).then(function (data) {
        var item = _.find($scope.rozuctovanie, { doklad: data.id });
        item.d_zdp = data.d_zdp;
        if (data.firma) {
          item.firma = data.firma_data.firma;
        }
      });
    };

    var events = [];

    var vymazZaDoklad = function (data) {
      var affected = _.filter($scope.rozuctovanie, { doklad: data.id });
      var sumline;
      for (var i = 0; i < affected.length; i++) {
        sumline = _.find($scope.rozuctovanie, {
          medzisuma: true,
          polozka: affected[i].polozka,
        });
        if (sumline) {
          sumline.count = parseInt(sumline.count) - 1;
          sumline.naklady = parseFloat(sumline.naklady) - affected[i].naklady;
          sumline.vynosy = parseFloat(sumline.vynosy) - affected[i].vynosy;
          sumline.zisk = parseFloat(sumline.zisk) - affected[i].zisk;
        }
        $scope.SearchCtrl.tableRemove($scope.rozuctovanie, affected[i]);
        $scope.SearchCtrl.emitSumsChange(null, affected[i]);
      }
    };
    var vytvorZaDoklad = function (data, observer) {
      var sposob, polozka, newItem, sumline;
      for (var i = 0; i < data.rozuctovanie.length; i++) {
        sposob = data.rozuctovanie[i];
        for (var j = 0; j < sposob.rozpis.length; j++) {
          polozka = sposob.rozpis[j];
          var item = _.find($scope.rozuctovanie, { id: data.id });
          if (!angular.isDefined(item)) {
            newItem = {};
            newItem.doklad = data.id;
            newItem.dd = data.dd;
            newItem.iddokd = data.iddokd;
            newItem.d_zdp = data.d_zdp;
            if (data.firma) {
              newItem.firma = data.firma_data.firma;
            }
            if (sposob.typ) {
              newItem.naklady = parseFloat(polozka.suma);
              newItem.vynosy = 0;
            } else {
              newItem.vynosy = parseFloat(polozka.suma);
              newItem.naklady = 0;
            }
            newItem.sposob = sposob.id;
            newItem.cislo = polozka.cislo;
            newItem.nazov = polozka.nazov;
            newItem.zisk = newItem.vynosy - newItem.naklady;
            Rozuctovanie.restangularize(newItem);
            $scope.SearchCtrl.tableInsert(
              $scope.rozuctovanie,
              newItem,
              observer
            );
            $scope.SearchCtrl.emitSumsChange(newItem, null);
            sumline = _.find($scope.rozuctovanie, {
              medzisuma: true,
              polozka: polozka.polozka,
            });
            if (sumline) {
              sumline.count = parseInt(sumline.count) + 1;
              sumline.naklady = parseFloat(sumline.naklady) + newItem.naklady;
              sumline.vynosy = parseFloat(sumline.vynosy) + newItem.vynosy;
              sumline.zisk = parseFloat(sumline.zisk) + newItem.zisk;
            }
          }
        }
      }
    };
    var createHandler = function (data, observer) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      if (
        $scope.SearchCtrl.filters.top.medzisumy ||
        $scope.SearchCtrl.filters.top.sumarne
      ) {
        $scope.SearchCtrl.refresh();
        return;
      }
      if (!isEmpty(data.rozuctovanie)) {
        vytvorZaDoklad(data, observer);
      }
    };
    var changeHandler = function (data, observer) {
      if (
        angular.isDefined(data.obj.agenda) &&
        Profile.get("agenda").id !== data.obj.agenda
      ) {
        return;
      }
      if (!objEquals(data.original, data.obj)) {
        if (
          $scope.SearchCtrl.filters.top.medzisumy ||
          $scope.SearchCtrl.filters.top.sumarne
        ) {
          $scope.SearchCtrl.refresh();
          return;
        }
        vymazZaDoklad(data.obj);
        if (!isEmpty(data.obj.rozuctovanie)) {
          vytvorZaDoklad(data.obj, observer);
        }
      }
    };
    var removeHandler = function (data) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      // var sumline = _.find($scope.rozuctovanie, {medzisuma: true, typ_dph: parseInt(data.typ_dph)});
      vymazZaDoklad(data);
      // if (sumline) {
      //   sumline[sumy[i]] = parseFloat(sumline[sumy[i]]) - parseFloat(data[sumy[i]]);
      //   sumline.count = parseInt(sumline.count)-1;
      //   if (sumline.count === 0) {
      //     $scope.rozuctovanie.splice($scope.rozuctovanie.indexOf(sumline), 1);
      //   }
      //   $scope.SearchCtrl.emitSumsChange();
      // }
    };
    var endpoints = ["faktury", "zavazky", "pokladna", "banka", "interne"];

    for (var i in endpoints) {
      events.push(
        Socket.on(endpoints[i] + "Created", function (data) {
          createHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Changed", function (data) {
          changeHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Removed", function (data) {
          removeHandler(data);
        })
      );
    }
    events.push(
      $scope.$on("casist:dataChanged", function (event, data) {
        if (endpoints.indexOf(data.type) === -1) {
          return;
        }
        switch (data.operation) {
          case 1:
            createHandler(data.payload, false);
            break;
          case 2:
            changeHandler(data.payload, false);
            break;
          case 3:
            removeHandler(data.payload);
            break;
          default:
            break;
        }
      })
    );

    $scope.$on("$destroy", function () {
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
      for (var i in events) {
        events[i]();
      }
    });
  },
]);
