'use strict';

angular.module('casist.ucto')
  .controller('CustomizeNeuhradeneCtrl', ['$scope', '$modalInstance', 'neuhradene', 'Dialog', '$q', function($scope, $modalInstance, neuhradene, Dialog, $q) {
    $scope.data = {
      neuhradene: undefined,
      neuhradeneOd: 30,
      neuhradeneVsetky: '1'
    };
    if (neuhradene) {
      if (neuhradene === 'True') {
        $scope.data.neuhradeneVsetky = '1';
      } else {
        $scope.data.neuhradeneVsetky = '0';
        if (String(neuhradene).match(',')) {
          $scope.data.neuhradeneOd = neuhradene.split(',')[0];
          $scope.data.neuhradeneDo = neuhradene.split(',')[1];
        } else {
          $scope.data.neuhradeneOd = neuhradene;
        }
      }
    }

    var cancelPromise = $q.defer();
    $scope.inProgress = false;
    $scope.ok = function() {
      var value;
      if ($scope.data.neuhradeneVsetky === '1' || ($scope.data.neuhradeneVsetky === '0' && !$scope.data.neuhradeneOd && !$scope.data.neuhradeneDo)) {
        value = 'True';
      } else {
        if ($scope.data.neuhradeneOd && $scope.data.neuhradeneDo) {
          value = $scope.data.neuhradeneOd+','+$scope.data.neuhradeneDo;
        } else {
          value = $scope.data.neuhradeneOd || $scope.data.neuhradeneDo;
        }
      }
      $modalInstance.close(value);
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }]);
