'use strict';

angular.module("casist.widgets").directive("tableLoading", [
  function () {
    return {
      restrict: "EA",
      scope: {
        colspan: "=colspan",
        model: "=tableLoading",
      },
      template: require('./table-loading.html'),
      link: function postLink(scope, element, attrs) {
        scope.state = 'loading';
        var noData = function () {
          scope.state = "noData";
        };
        var dataLoaded = function () {
          scope.state = 'loaded';
        };
        var dataLoading = function () {
          scope.state = "loading";
        };

        scope.$watch("model.loading", function (val, oldVal) {
          if (val && scope.model && !scope.model.length) {
            dataLoading();
          } else if (!val) {
            if (scope.model && scope.model.length) {
              dataLoaded();
            } else {
              noData();
            }
          }
        });
        scope.$watch(
          "model",
          function (val, oldVal) {
            if (
              oldVal &&
              !oldVal.length &&
              !oldVal.loading &&
              val &&
              val.length
            ) {
              dataLoaded();
            } else if (val && !val.loading && !val.length) {
              noData();
            }
          },
          true
        );
      },
    };
  },
]);
