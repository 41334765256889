"use strict";

angular.module("casist.sklad").factory("Ponuka", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  "Upload",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate,
    Upload
  ) {
    var ENDPOINT = "ponuky";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst == "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma == "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          if (!this.typ) {
            this.addError("typ", $translate.instant("errors.TYP"));
            result = false;
          }
          return result;
        },
        fakturacia: function () {
          return this.all("fakturacia").post({});
        },
      });
    });

    var openDetail = function (templateUrl, item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: "PonukaDetailCtrl",
        backdrop: "static",
        windowClass: "detail",
        keyboard: false,
        resolve: {
          ponuka: function () {
            return item ? item : {};
          },
          ponuky: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
        },
      });
      return modalInstance.result;
    };
    return angular.extend(BaseCollectionModel.object(ENDPOINT), {
      getPerms: function () {
        return Profile.get("perms").ponuka;
      },
      getImportConfig: function () {
        return Restangular.all(ENDPOINT).all("import").customGET();
      },
      import: function ($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all("import").getRestangularUrl(),
          data: { fileName: file.name, config: JSON.stringify(config) },
          file: file,
        })
          .progress(function (evt) {
            deferred.notify(parseInt((100.0 * evt.loaded) / evt.total));
          })
          .then(
            function (data) {
              deferred.resolve(data.data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
      importData: function (data) {
        return Restangular.all(ENDPOINT)
          .all("import")
          .post(data, { import: 1 });
      },
      parovanie: function () {
        return Restangular.all("ponuky").all("parovanie");
      },
      fakturaciaHromadna: function (ids) {
        return Restangular.all("ponuky")
          .all("fakturacia")
          .post({ ponuky: ids });
      },
      edit: function (doklad, list, searchCtrl) {
        var promise = $q.defer();
        if (doklad) {
          this.restangularize(doklad);
        }
        openDetail(
          "sklad/ponuky/ponuky-detail.html",
          doklad,
          list,
          searchCtrl
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
    });
  },
]);

angular.module("casist.sklad").factory("PonukaTyp", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel("ponukatypy", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var result = BaseModel.validate();
          if (!this.typ) {
            this.addError("typ", "Pole nemôže byť prázdne");
            result = false;
          }
          return result;
        },
      });
    });
    var parent = BaseCollectionModel.object("ponuky/ponukatypy");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").ponuka;
      },
      get: function (query) {
        return parent.objects().getList({ search: query });
      },
      add: function (typ) {
        var deferred = $q.defer();
        Dialog.prompt(
          "Typ",
          "Vytvoriť typ",
          { userinput: typ, ratat_sklad: false },
          undefined,
          "sklad/ponuky/ponuky-typ-detail.html"
        ).then(
          function (userData) {
            parent
              .create({
                typ: userData.userinput,
                ratat_sklad: userData.ratat_sklad,
              })
              .then(
                function (data) {
                  deferred.resolve(data);
                },
                function () {
                  deferred.reject();
                }
              );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      edit: function (typ) {
        var deferred = $q.defer();
        Dialog.prompt(
          "Typ",
          "Upraviť typ",
          { userinput: typ.typ, ratat_sklad: typ.ratat_sklad },
          undefined,
          "sklad/ponuky/ponuky-typ-detail.html"
        ).then(
          function (userData) {
            typ.typ = userData.userinput;
            typ.ratat_sklad = userData.ratat_sklad;
            typ.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      remove: function (typ) {
        return typ.remove();
      },
    });
  },
]);

angular.module("casist.sklad").factory("PonukaUcel", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel("ponukaucely", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var result = BaseModel.validate();
          if (!this.text) {
            this.addError("text", "Pole nemôže byť prázdne");
            result = false;
          }
          return result;
        },
      });
    });
    var parent = BaseCollectionModel.object("ponuky/ponukaucely");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").ponuka;
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
      add: function (text, otherData) {
        return parent.create(angular.extend({ text: text }, otherData));
      },
      edit: function (ucel) {
        var deferred = $q.defer();
        var promise = Dialog.prompt("Účel", "Upraviť účel", ucel.text).then(
          function (str) {
            ucel.text = str;
            ucel.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      remove: function (typ) {
        return typ.remove();
      },
    });
  },
]);

angular.module("casist.sklad").factory("PredajHlavicka", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  "Global",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    Dialog,
    Global
  ) {
    Restangular.extendModel("hlavicky", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.text) {
            this.addError("text", "Text nemôže byť prázdny!");
            return false;
          }
          return BaseModel.validate();
        },
      });
    });

    var parent = BaseCollectionModel.object("ponuky/hlavicky");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").ponuka;
      },
      edit: function (hlavicka) {
        var wasDefault = hlavicka.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          "Text hlavičky",
          "Upraviť hlavičku",
          { userinput: hlavicka.text, default: hlavicka.default },
          { type: "textarea", rows: 8 },
          "sklad/fakturacia/fakturacia-hlavicka-detail.html"
        ).then(
          function (userData) {
            hlavicka.text = userData.userinput;
            hlavicka.default = userData.default;
            if (!hlavicka.validate()) {
              Dialog.alert(hlavicka.errors().data.text[0], "Chyba");
              return;
            }
            hlavicka.update().then(
              function (data) {
                if (!data.typ) {
                  if (data.default) {
                    Global.set("ponuka_hlavicka", data.text);
                  } else if (wasDefault) {
                    Global.set("ponuka_hlavicka", null);
                  }
                }
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      add: function (hlavicka, attrs) {
        return parent.create(angular.extend({ text: hlavicka }, attrs));
      },
      remove: function (hlavicka) {
        return hlavicka.remove();
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
    });
  },
]);

angular.module("casist.sklad").factory("PredajPata", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  "Global",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    Dialog,
    Global
  ) {
    Restangular.extendModel("paty", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.text) {
            this.addError("text", "Text nemôže byť prázdny!");
            return false;
          }
          return BaseModel.validate();
        },
      });
    });

    var parent = BaseCollectionModel.object("ponuky/paty");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").ponuka;
      },
      edit: function (pata) {
        var wasDefault = pata.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          "Text päty",
          "Upraviť pätu",
          { userinput: pata.text, default: pata.default },
          { type: "textarea", rows: 8 },
          "sklad/fakturacia/fakturacia-hlavicka-detail.html"
        ).then(
          function (userData) {
            pata.text = userData.userinput;
            pata.default = userData.default;
            pata.update().then(
              function (data) {
                if (!data.typ) {
                  if (data.default) {
                    Global.set("ponuka_pata", data.text);
                  } else if (wasDefault) {
                    Global.set("ponuka_pata", null);
                  }
                }
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      add: function (pata, attrs) {
        return parent.create(angular.extend({ text: pata }, attrs));
      },
      remove: function (pata) {
        return pata.remove();
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
    });
  },
]);
