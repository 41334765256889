"use strict";
import moment from "moment";

angular.module("casist.ubytovanie").controller("NastenkaCtrl", [
  "$scope",
  "Restangular",
  "Ubytovanie",
  "Prevadzka",
  "UbytovanieNastenka",
  "Izba",
  "Profile",
  "$controller",
  "$q",
  "TabService",
  "Outputs",
  "Karta",
  "Firma",
  "UbytovanieOsoba",
  "$modal",
  "Notification",
  "Faktura",
  "Dialog",
  "Socket",
  "Global",
  "$timeout",
  "DokladTypy",
  "PokladnicnyDoklad",
  "$filter",
  function (
    $scope,
    Restangular,
    Ubytovanie,
    Prevadzka,
    UbytovanieNastenka,
    Izba,
    Profile,
    $controller,
    $q,
    TabService,
    Outputs,
    Karta,
    Firma,
    UbytovanieOsoba,
    $modal,
    Notification,
    Faktura,
    Dialog,
    Socket,
    Global,
    $timeout,
    DokladTypy,
    PokladnicnyDoklad,
    $filter
  ) {
    $scope.SearchCtrl = $controller("SearchCtrl");
    $scope.nastenka = [];
    $scope.search = {
      datum_ku: getCurrentDate(),
      datum_do: getCurrentDate(moment().add(1, "day").toDate()),
    };
    $scope.doSearch = function () {
      $scope.nastenka.loading = true;
      var profilePrevadzky = Profile.get("prevadzky");
      var aktivnaPrevadzka = null;
      if (profilePrevadzky.length === 1) {
        aktivnaPrevadzka = Profile.get("prevadzky")[0];
      }
      var filter = $scope.search || {};
      if (aktivnaPrevadzka) {
        filter.prevadzka = aktivnaPrevadzka.id;
      }
      UbytovanieNastenka.objects()
        .getList($scope.search)
        .then(function (data) {
          for (var p = 0; p < data.length; p++) {
            for (var j = 0; j < data[p].poschodia.length; j++) {
              for (var i = 0; i < data[p].poschodia[j].izby.length; i++) {
                var izba = _.find($scope.nastenka[p].poschodia[j].izby, {
                  izbaId: data[p].poschodia[j].izby[i].izbaId,
                });
                if (izba) {
                  izba.ubytovanie = data[p].poschodia[j].izby[i].ubytovanie;
                }
              }
            }
          }
          $scope.nastenka.loading = false;
        });
    };
    $scope.izbaComparator = function (item) {
      if (!$scope.search.query) {
        return true;
      }
      var re = /[áäčďéěíĺóřŕšťúýž]/g;
      var translate = {
        á: "a",
        ä: "a",
        č: "c",
        ď: "d",
        é: "e",
        ě: "e",
        í: "i",
        ĺ: "l",
        ó: "o",
        ř: "r",
        ŕ: "r",
        š: "s",
        ť: "t",
        ú: "u",
        ý: "y",
        ž: "z",
      };
      function replaceDiakritika(str) {
        str = str.toLowerCase().replace(re, function (match) {
          return translate[match];
        });
        return str;
      }
      var query = replaceDiakritika($scope.search.query);
      return _.find(item.ubytovanie, function (ubyt) {
        return (
          replaceDiakritika(ubyt.osoba).indexOf(query.toLowerCase()) !== -1 ||
          item.izba.indexOf(query) !== -1
        );
      });
    };
    $scope.datumChanged = function () {
      var d_od = parseDate($scope.search.datum_ku);
      var d_do = parseDate($scope.search.datum_do);
      if (d_do <= d_od) {
        $scope.search.datum_do = getCurrentDate(
          moment(d_od).add(1, "day").toDate()
        );
      }
    };
    $scope.prevadzkaChanged = function (value) {
      if (value) {
        $scope.search.prevadzka = value.id;
      } else {
        delete $scope.search.prevadzka;
      }
      loadData($scope.search);
    };
    var profilePrevadzky = Profile.get("prevadzky");
    var aktivnaPrevadzka = null;
    if (profilePrevadzky.length === 1) {
      aktivnaPrevadzka = Profile.get("prevadzky")[0];
    }
    var loadData = function (filter) {
      var promise = UbytovanieNastenka.objects().getList(filter);
      $scope.nastenka.loading = true;
      promise.then(function (data) {
        $scope.nastenka = data;
        TabService.registerScope("nastenka", $scope);
      });
      return promise;
    };
    var filter = {};
    if (aktivnaPrevadzka) {
      filter.prevadzka = aktivnaPrevadzka.id;
    }
    loadData(filter);

    $scope.parseTooltip = function (item) {
      var content = item.osoba;
      if (item.tel) {
        content += "<br/>" + item.tel;
      }
      if (item.firma) {
        content += "<br/>" + item.firma;
      }
      content +=
        "<br/>" +
        $filter("date")(item.prichod, "dd.MM.yyyy") +
        " - " +
        $filter("date")(item.odchod, "dd.MM.yyyy");
      if (item.rezervacia) {
        content +=
          "<br/>Rezervované do: " + $filter("time")(item.rezervovane_do);
      }
      if (item.pp_od && item.pp_do) {
        content +=
          "<br/><br/>PP: " +
          $filter("date")(item.pp_od, "dd.MM.yyyy") +
          " - " +
          $filter("date")(item.pp_do, "dd.MM.yyyy");
      } else if (item.pp_od) {
        content +=
          "<br/><br/>PP od: " + $filter("date")(item.pp_od, "dd.MM.yyyy");
      } else if (item.pp_do) {
        content +=
          "<br/><br/>PP do: " + $filter("date")(item.pp_do, "dd.MM.yyyy");
      }
      return content;
    };

    $scope.isAnythingLoading = function () {
      return $scope.nastenka.loading === true;
    };

    var loadPrevadzky = function () {
      Prevadzka.objects()
        .getList()
        .then(function (data) {
          $scope.prevadzky = data;
          if (aktivnaPrevadzka) {
            $scope.SearchCtrl.filterSelection(
              "prevadzka",
              aktivnaPrevadzka.id,
              false
            );
          }
        });
    };
    loadPrevadzky();

    $scope.volneIzbyRange = function (item) {
      var range = [];
      for (
        var i = 0;
        i < parseInt(item.posteli) - item.ubytovanie.length;
        i++
      ) {
        range.push(i);
      }
      return range;
    };

    var parseUbytItem = function (item) {
      return {
        firma: item.firma ? item.firma_data.firma : null,
        osoba: item.osoba_data.meno,
        prichod: item.prichod,
        odchod: item.odchod,
        pp_od: item.pp_od,
        pp_do: item.pp_do,
        tel: item.osoba_data.tel,
        pohlavie: item.osoba_data.pohlavie,
        rezervacia: item.rezervacia,
        rezervovane_do: item.rezervovane_do,
        prevadzka: item.prevadzka,
        id: item.id,
      };
    };
    var findIzba = function (izba) {
      for (var i = 0; i < $scope.nastenka.length; i++) {
        for (var j = 0; j < $scope.nastenka[i].poschodia.length; j++) {
          for (
            var k = 0;
            k < $scope.nastenka[i].poschodia[j].izby.length;
            k++
          ) {
            if ($scope.nastenka[i].poschodia[j].izby[k].izbaId === izba) {
              return $scope.nastenka[i].poschodia[j].izby[k];
            }
          }
        }
      }
      return undefined;
    };
    var findIzbaByUbytovanie = function (ubytovanie) {
      for (var i = 0; i < $scope.nastenka.length; i++) {
        for (var j = 0; j < $scope.nastenka[i].poschodia.length; j++) {
          for (
            var k = 0;
            k < $scope.nastenka[i].poschodia[j].izby.length;
            k++
          ) {
            for (
              var l = 0;
              l < $scope.nastenka[i].poschodia[j].izby[k].ubytovanie.length;
              l++
            ) {
              if (
                $scope.nastenka[i].poschodia[j].izby[k].ubytovanie[l].id ===
                ubytovanie
              ) {
                return $scope.nastenka[i].poschodia[j].izby[k];
              }
            }
          }
        }
      }
      return undefined;
    };
    var handleCreate = function (item) {
      var record = findIzba(item.izba);
      var datumKu = parseDate($scope.search.datum_ku);
      if (
        record &&
        parseDate(item.prichod) <= datumKu &&
        parseDate(item.odchod) > datumKu &&
        item.sluzba_data.typ_data.ubytovanie === true
      ) {
        record.ubytovanie.push(parseUbytItem(item));
      }
    };
    var handleChange = function (item) {
      var record = findIzbaByUbytovanie(item.id);
      var datumKu = parseDate($scope.search.datum_ku);
      if (record) {
        var ubytRecord = _.find(record.ubytovanie, { id: item.id });
        if (ubytRecord) {
          record.ubytovanie.splice(record.ubytovanie.indexOf(ubytRecord), 1);
        }
      }
      record = findIzba(item.izba);
      if (
        record &&
        parseDate(item.prichod) <= datumKu &&
        parseDate(item.odchod) > datumKu &&
        item.sluzba_data.typ_data.ubytovanie === true
      ) {
        record.ubytovanie.push(parseUbytItem(item));
      }
    };
    var handleRemove = function (item) {
      var datumKu = parseDate($scope.search.datum_ku);
      var record = findIzba(item.izba);
      if (record) {
        var ubytRecord = _.find(record.ubytovanie, { id: item.id });
        if (ubytRecord) {
          record.ubytovanie.splice(record.ubytovanie.indexOf(ubytRecord), 1);
        }
      }
    };
    var events = Ubytovanie.registerCustomEvents(
      handleCreate,
      handleChange,
      handleRemove
    );
    $scope.$on("$destroy", function () {
      Ubytovanie.unregisterEvents(events);
    });

    $scope.create = function (item) {
      var deferred = $q.defer();
      Izba.objects()
        .one(item.izbaId)
        .get()
        .then(
          function (data) {
            deferred.resolve(
              Ubytovanie.add({
                izba: item.izba.id,
                rezervacia: true,
                izba_data: data,
                prevadzka: data.prevadzka,
                rezervaciaFixed: true,
                prevadzka_rezervacia: _.find($scope.prevadzky, {
                  id: data.prevadzka,
                }),
              })
            );
          },
          function () {
            deferred.reject();
          }
        );
      return deferred.promise;
    };
    $scope.edit = function (item) {
      if (
        !item.rezervacia &&
        _.find(Profile.get("prevadzky"), { id: item.prevadzka }) === undefined
      ) {
        Dialog.alert(
          "Vystavený záznam z inej prevádzky sa nedá upravovať.",
          "Chyba"
        );
        return;
      }
      var deferred = $q.defer();
      Ubytovanie.objects()
        .one(item.id)
        .get()
        .then(
          function (data) {
            deferred.resolve(
              Ubytovanie.edit(
                angular.extend(data, {
                  rezervaciaFixed:
                    _.find(Profile.get("prevadzky"), { id: item.prevadzka }) ===
                    undefined
                      ? true
                      : undefined,
                }),
                null,
                null
              )
            );
          },
          function () {
            deferred.reject();
          }
        );
      return deferred.promise;
    };
  },
]);
