'use strict';

angular.module('casist.admin')
  .factory('Prevadzka', ['Restangular', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = "settings/prevadzky";

    Restangular.extendModel('prevadzky', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.prevadzka) || this.prevadzka === '') {
            this.addError('prevadzka', 'Firma nemôže byť prázdna');
            result = false;
          }
          if (!angular.isDefined(this.ulica) || this.ulica === '') {
            this.addError('ulica', 'Ulica nemôže byť prázdna');
            result = false;
          }
          if (!angular.isDefined(this.cislo) || this.cislo === '') {
            this.addError('cislo', 'Číslo nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.psc) || this.psc === '') {
            this.addError('psc', 'PSČ nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.mesto) || this.mesto === '') {
            this.addError('mesto', 'Mesto nemôže byť prázdne');
            result = false;
          }
          this.adresa = this.ulica+' '+this.cislo;
          return result;
        }
      });
    });

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(BaseCollectionModel.object('settings/prevadzky'), {
      getPerms: function() {
        return Profile.get('perms').prevadzka;
      },
      get: function (query, page, parentObject, otherParams) {
        return parent.objects().getList(angular.extend(angular.extend({search:query}, otherParams), page));
      },
    });
  }]);
