"use strict";

angular.module("casist.sklad").factory("Nakup", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  "Upload",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate,
    Upload
  ) {
    var ENDPOINT = "nakupy";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst == "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma == "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          if (!this.typ) {
            this.addError("typ", $translate.instant("errors.TYP"));
            result = false;
          }
          return result;
        },
        fakturacia: function () {
          return this.all("fakturacia").post({});
        },
        recountNaklady: function (pohyb, mena) {
          var deferred = $q.defer();
          var self = this;
          var kurz = parseFloat(self.kurz || 0);
          function countIt(pohyb) {
            if (!pohyb.length) {
              deferred.resolve(pohyb);
              return deferred.promise;
            }
            var naklady = parseFloat(self.naklady);
            var spoluPrijem = 0;
            for (var i = 0; i < pohyb.length; i++) {
              if (self.primarna_zm && kurz > 0) {
                spoluPrijem +=
                  parseFloat(pohyb[i].cena_zm || 0) *
                  parseFloat(pohyb[i].mp || 0);
              } else {
               spoluPrijem +=
                  parseFloat(pohyb[i].cena_prijem || 0) *
                  parseFloat(pohyb[i].mp || 0);
              }
            }
            for (var i = 0; i < pohyb.length; i++) {
              if (self.mena_data && mena === 'eur' && kurz > 0) {
                pohyb[i].cena_naklady =
                  Math.round(
                    ((parseFloat(pohyb[i].cena_zm || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_zm || 0) / spoluPrijem)) / kurz) *
                      10000
                  ) / 10000.0;
              } else if (self.mena_data && mena === 'czk' && kurz > 0) {
                pohyb[i].cena_naklady =
                  Math.round(
                    ((parseFloat(pohyb[i].cena_zm || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_zm || 0) / spoluPrijem)) * kurz) *
                      10000
                  ) / 10000.0;
              } else {
                pohyb[i].cena_naklady =
                  Math.round(
                    (parseFloat(pohyb[i].cena_prijem || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_prijem || 0) / spoluPrijem)) *
                      10000
                  ) / 10000.0;
              }
            }
            self.recountSums(pohyb);
            deferred.resolve(pohyb);
          }

          if (!pohyb) {
            this.getList("pohyb").then(function (data) {
              countIt(data);
            });
          } else {
            countIt(pohyb);
          }
          return deferred.promise;
        },
      });
    });

    var openDetail = function (templateUrl, item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: "NakupDetailCtrl",
        backdrop: "static",
        windowClass: "detail",
        keyboard: false,
        resolve: {
          nakup: function () {
            return item ? item : {};
          },
          nakupy: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").nakup;
      },
      getImportConfig: function () {
        return Restangular.all(ENDPOINT).all("import").customGET();
      },
      import: function ($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all("import").getRestangularUrl(),
          data: { fileName: file.name, config: JSON.stringify(config) },
          file: file,
        })
          .progress(function (evt) {
            deferred.notify(parseInt((100.0 * evt.loaded) / evt.total));
          })
          .then(
            function (data) {
              deferred.resolve(data.data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
      importData: function (data) {
        return Restangular.all(ENDPOINT)
          .all("import")
          .post(data, { import: 1 });
      },
      parovanie: function () {
        return Restangular.all("nakupy").all("parovanie");
      },
      edit: function (doklad, list, searchCtrl) {
        var promise = $q.defer();
        if (doklad) {
          this.restangularize(doklad);
        }
        openDetail(
          "sklad/nakup/nakup-detail.html",
          doklad,
          list,
          searchCtrl
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      getSelection: function (query) {
        var year = Profile.get('obdobie', new Date().getFullYear());
        var params = {
          'd_zdp__gte': year + '-01-01',
          'd_zdp__lte': year + '-12-31',
          'selection': true
        };
        return parent.objects().getList(angular.extend({search: query}, params));
      }
    });
  },
]);

angular.module("casist.sklad").factory("NakupTyp", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel("nakuptypy", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var result = BaseModel.validate();
          if (!this.typ) {
            this.addError("typ", "Pole nemôže byť prázdne");
            result = false;
          }
          return result;
        },
      });
    });
    var parent = BaseCollectionModel.object("nakupy/nakuptypy");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").nakup;
      },
      get: function (query) {
        return parent.objects().getList({ search: query });
      },
      add: function (typ) {
        var deferred = $q.defer();
        Dialog.prompt(
          "Typ",
          "Vytvoriť typ",
          { userinput: typ, ratat_sklad: false },
          undefined,
          "sklad/ponuky/ponuky-typ-detail.html"
        ).then(
          function (userData) {
            parent
              .create({
                typ: userData.userinput,
                ratat_sklad: userData.ratat_sklad,
              })
              .then(
                function (data) {
                  deferred.resolve(data);
                },
                function () {
                  deferred.reject();
                }
              );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      edit: function (typ) {
        var deferred = $q.defer();
        Dialog.prompt(
          "Typ",
          "Upraviť typ",
          { userinput: typ.typ, ratat_sklad: typ.ratat_sklad },
          undefined,
          "sklad/ponuky/ponuky-typ-detail.html"
        ).then(
          function (userData) {
            typ.typ = userData.userinput;
            typ.ratat_sklad = userData.ratat_sklad;
            typ.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      remove: function (typ) {
        return typ.remove();
      },
    });
  },
]);

angular.module("casist.sklad").factory("NakupUcel", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel("nakupucely", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var result = BaseModel.validate();
          if (!this.text) {
            this.addError("text", "Pole nemôže byť prázdne");
            result = false;
          }
          return result;
        },
      });
    });
    var parent = BaseCollectionModel.object("nakupy/nakupucely");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").nakup;
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
      add: function (text, otherData) {
        return parent.create(angular.extend({ text: text }, otherData));
      },
      edit: function (ucel) {
        var deferred = $q.defer();
        var promise = Dialog.prompt("Účel", "Upraviť účel", ucel.text).then(
          function (str) {
            ucel.text = str;
            ucel.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      remove: function (typ) {
        return typ.remove();
      },
    });
  },
]);

angular.module("casist.sklad").factory("NakupHlavicka", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  "Global",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    Dialog,
    Global
  ) {
    Restangular.extendModel("hlavicky", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.text) {
            this.addError("text", "Text nemôže byť prázdny!");
            return false;
          }
          return BaseModel.validate();
        },
      });
    });

    var parent = BaseCollectionModel.object("nakupy/hlavicky");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").nakup;
      },
      edit: function (hlavicka) {
        var wasDefault = hlavicka.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          "Text hlavičky",
          "Upraviť hlavičku",
          { userinput: hlavicka.text, default: hlavicka.default },
          { type: "textarea", rows: 8 },
          "sklad/fakturacia/fakturacia-hlavicka-detail.html"
        ).then(
          function (userData) {
            hlavicka.text = userData.userinput;
            hlavicka.default = userData.default;
            if (!hlavicka.validate()) {
              Dialog.alert(hlavicka.errors().data.text[0], "Chyba");
              return;
            }
            hlavicka.update().then(
              function (data) {
                if (!data.typ) {
                  if (data.default) {
                    Global.set("nakup_hlavicka", data.text);
                  } else if (wasDefault) {
                    Global.set("nakup_hlavicka", null);
                  }
                }
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      add: function (hlavicka, attrs) {
        return parent.create(angular.extend({ text: hlavicka }, attrs));
      },
      remove: function (hlavicka) {
        return hlavicka.remove();
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
    });
  },
]);

angular.module("casist.sklad").factory("NakupPata", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  "Global",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    Dialog,
    Global
  ) {
    Restangular.extendModel("paty", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.text) {
            this.addError("text", "Text nemôže byť prázdny!");
            return false;
          }
          return BaseModel.validate();
        },
      });
    });

    var parent = BaseCollectionModel.object("nakupy/paty");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").nakup;
      },
      edit: function (pata) {
        var wasDefault = pata.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          "Text päty",
          "Upraviť pätu",
          { userinput: pata.text, default: pata.default },
          { type: "textarea", rows: 8 },
          "sklad/fakturacia/fakturacia-hlavicka-detail.html"
        ).then(
          function (userData) {
            pata.text = userData.userinput;
            pata.default = userData.default;
            pata.update().then(
              function (data) {
                if (!data.typ) {
                  if (data.default) {
                    Global.set("nakup_pata", data.text);
                  } else if (wasDefault) {
                    Global.set("nakup_pata", null);
                  }
                }
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      add: function (pata, attrs) {
        return parent.create(angular.extend({ text: pata }, attrs));
      },
      remove: function (pata) {
        return pata.remove();
      },
      get: function (query, otherParams) {
        return parent
          .objects()
          .getList(angular.extend({ search: query }, otherParams));
      },
    });
  },
]);
