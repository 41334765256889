'use strict';

angular.module('casist.sklad')
  .factory('Doprava', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel('doprava', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.text) {
            this.addError('text', 'Text nemôže byť prázdny!');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('faktury/doprava');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').faktura;
      },
      edit: function (doprava) {
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          'Doprava',
          'Upraviť dopravu',
          {doprava: doprava.text, dobierka: doprava.dobierka},
            undefined,
          'sklad/fakturacia/fakturacia-doprava-detail.html'
        ).then(function(response) {
          doprava.text = response.doprava;
          doprava.dobierka = response.dobierka;
          doprava.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(doprava) {
        return parent.create({text: doprava});
      },
      remove: function (doprava) {
        return doprava.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
