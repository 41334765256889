'use strict';

angular.module('casist.ubytovanie')
  .controller('IzbaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'SearchCtrl', 'item', 'items', '$timeout', '$modal', '$q', 'DokladTypy', 'Karta', 'Izba', 'Global',
    function($scope, $modalInstance, $controller, Profile, SearchCtrl, item, items, $timeout, $modal, $q, DokladTypy, Karta, Izba, Global) {

      $scope.items = items;
      $scope.Karta = Karta;

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        Model: Izba
      });

      var defaultRecord = {
        hranica: Global.get('hranica_dlhodobe'),
        aktivna: true
      };

      $scope.RecordCtrl.main.init({
        //endpoint: 'ubytovanie/izby',
        model: 'record',
        master: item,
        list: items,
        defaultItem: defaultRecord,
        //postSave: firmaPostSave,
        searchCtrl: SearchCtrl,
        $modalInstance: $modalInstance,
        focusField: 'izba'
      });
      if (!isEmpty(item)) {
        _.defaults(item, defaultRecord);
        $scope.record = Izba.copy(item);
        angular.extend(defaultRecord, _.pick($scope.record, 'prevadzka'));
        $scope.currentPrevadzka = _.find(Profile.get('prevadzky'), {id: $scope.record.prevadzka});
      } else {
        $scope.record = angular.copy(defaultRecord);
      }
      $scope.getKarty = function(query) {
        return Karta.get(query, undefined, undefined, {je_ubytovanie: true})
      };

      $scope.ok = function () {
        var promise = $scope.RecordCtrl.main.save();
        if (promise) {
          promise.then(function(result) {
            $modalInstance.close(result);
          });
        }
      };

      $scope.cancel = function () {
        $scope.RecordCtrl.main.dismiss();
      };
      $scope.deleteRecord = function () {
        $scope.RecordCtrl.main.remove().then(function () {
          $modalInstance.dismiss({type: 'removed', item: item});
        });
      };
    }]);
