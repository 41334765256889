'use strict';

angular.module('casist.svb')
  .controller('OdpocetBaseCtrl', ['$scope', '$timeout', '$location', 'Odpocet', 'TabService', '$state', '$stateParams', '$controller', 'Priestor', 'odpocty', 'Profile', 'Merac',
    function($scope, $timeout, $location, Odpocet, TabService, $state, $stateParams, $controller, Priestor, odpocty, Profile, Merac) {
      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.sposoby = Odpocet.getSposob();
      $scope.druhy = Merac.getDruhy();
      $scope.odpoctyTable = [
        { width: 40 },
        { field: 'datum', type: 'date', label: 'odpocty.DATUM', filterCollapse: 'right' },
        { field: 'priestor__nazov', label: 'priestory.PRIESTOR' },
        { field: 'merac__druh', label: 'merace.DRUH', select2: { source: 'druhy', lookup: 'druh' } },
        { field: 'merac__sn', label: 'merace.SN' },
        { field: 'sposob', label: 'odpocty.SPOSOB', select2: { source: 'sposoby', lookup: 'sposob' } },
        { field: 'koeficient', label: 'odpocty.KOEFICIENT', type: 'number' },
        { field: 'stav_posledny', label: 'odpocty.PRED_STAV', type: 'number', sortable: false, sum: function(sums) { return sums.stav - sums.spotreba; } },
        { field: 'stav', label: 'odpocty.STAV', type: 'number', sum: true },
        { field: 'spotreba', label: 'odpocty.SPOTREBA', type: 'number', sum: true },
        { width: 40 }
      ];
      $scope.odpocty = odpocty;
      $scope.filterChanged = function(filter) {
        var promise = Odpocet.objects().getList(filter);
        $scope.odpocty.loading = true;
        promise.then(function(data) {
          TabService.registerScope('odpocty', $scope);
          $scope.odpocty = data;
        });
        return promise;
      };
      $scope.defaultFilter = {};
      var defaultPeriod = {
        'datum__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
        'datum__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
      };
      var observers = [];
      observers.push(Profile.addObserver('obdobie', function(obdobie) {
        defaultPeriod = {
          'datum__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
          'datum__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
        };
        $scope.SearchCtrl.setDefaultPeriod(defaultPeriod);
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.refresh();
      }));
      $scope.SearchCtrl.init({
        onChange: $scope.filterChanged,
        columnsDef: $scope.odpoctyTable,
        sort: {sort: 'datum,priestor__nazov'},
        page: $stateParams.page || Math.ceil(odpocty.count / $scope.SearchCtrl.per_page) || 'last',
        scope: $scope,
        useLocation: true,
        defaultPeriod: defaultPeriod,
        defaultFilter: $scope.defaultFilter
      });

      $scope.domChanged = function(dom) {
        var searchParams = {page: $scope.SearchCtrl.page, dom: dom.id};
        $scope.refreshVchody(dom);
        if ($scope.vchody.length) {
          searchParams.vchod = $scope.vchody[0].id;
          $scope.SearchCtrl.filterSelection('vchod', $scope.vchody[0].id, false);
        }
        $scope.defaultFilter.dom = dom.id;
        $location.search(searchParams);
      };
      $scope.vchodChanged = function(vchod) {
        $location.search({page: $scope.SearchCtrl.page, vchod: vchod.id});
        $scope.defaultFilter.vchod = vchod.id;
        $scope.$broadcast('vchodChanged', function() {
          return vchod;
        });
      };

      var events = Odpocet.registerEvents(function() { return $scope.odpocty; }, function() { return $scope.SearchCtrl; });
      $scope.$on('$destroy', function() {
        Odpocet.unregisterEvents(events);
        for (var i in observers) {
          observers[i]();
        }
      });
    }
  ])
  .controller('OdpocetCtrl', ['$scope', '$timeout', '$location', 'Odpocet', 'TabService', '$state', '$stateParams', '$controller', 'Priestor', 'odpocty', 'Merac', 'Dialog', 'vchody', '$modal', 'Outputs', 'Notification',
    function($scope, $timeout, $location, Odpocet, TabService, $state, $stateParams, $controller, Priestor, odpocty, Merac, Dialog, vchody, $modal, Outputs, Notification) {
      $scope.TableEditor = $controller('TableEditor', {
        $scope: $scope,
        Model: Odpocet,
        List: $scope.odpocty
      });

      $scope.getDruhText = Merac.getDruhy;
      $scope.changePage = function() {
        $location.search(angular.extend($location.search(), {page: $scope.SearchCtrl.page}));
        $scope.SearchCtrl.refresh();
      };

      $scope.refreshVchody = function(dom) {
        $scope.firstSetup = $scope.domy.length === 0;
        if ($scope.firstSetup && $scope.SearchCtrl.filters.top.dom) {
          delete $scope.SearchCtrl.filters.top.dom;
        }
        if (!$scope.firstSetup && !$scope.SearchCtrl.filters.top.dom) {
          $scope.SearchCtrl.filterSelection('dom', $scope.domy[0], false);
          $scope.domChanged($scope.domy[0]);
        }
        if (!dom) {
          dom = _.find($scope.domy, {id: parseInt($scope.SearchCtrl.filters.top.dom)});
        }
        $scope.vchody = [];
        if (!dom) {
          return;
        }
        _.each(vchody, function(obj) {
          if (obj.dom === dom.id) {
            $scope.vchody.push(obj);
          }
        });
      };
      $scope.manageDomy = function() {
        $modal.open({
          templateUrl: 'svb/domy/domy.html',
          controller: 'DomyCtrl',
          backdrop: 'static',
          windowClass: 'detail-md',
          resolve: {
            domy: function() {
              return $scope.domy;
            },
            vchody: function() {
              return vchody;
            }
          }
        }).result.then(function() {
            $scope.refreshVchody();
          });
      };
      $scope.goToMerac = function(odpocet) {
        TabService.bus.send('priestory', 'odpocty');
        TabService.openTab('priestory', null, '/priestory/'+odpocet.priestor+'/merace/'+odpocet.merac);
      };
      $scope.saveEdit = function(odpocet) {
        var cp = Odpocet.copy(odpocet);
        cp.update().then(function(data) {
          $scope.TableEditor.finishEditing(odpocet);
          $scope.SearchCtrl.broadcastRefresh(odpocet);
        });
      };
      $scope.removeOdpocet = function(odpocet) {
        Dialog.confirm('Naozaj chcete vymazať odpočet? Vymažú sa všetky odpočty priestoru v tomto dátume.', 'Vymazanie odpočtu').then(function() {
          odpocet.delete().then(function() {
            $scope.SearchCtrl.refresh();
          });
        });
      };
      $scope.vchody = [];
      $scope.domy = [];
      $scope.firstSetup = false;
      Priestor.getQuery(vchody).then(function(vchod) {
        if (!vchod) {
          $scope.firstSetup = true;
          return;
        }
        _.each(vchody, function(obj) {
          if (!_.find($scope.domy, {id: obj.dom})) {
            $scope.domy.push({id: obj.dom, nazov: obj.dom_data.nazov});
          }
          if (obj.dom === vchod.dom) {
            $scope.vchody.push(obj);
          }
        });
        $scope.SearchCtrl.filterSelection('dom', vchod.dom, false);
        $scope.SearchCtrl.filterSelection('vchod', vchod.id, false);
        $scope.defaultFilter.vchod = vchod.id;
        $scope.defaultFilter.dom = vchod.dom;
      });

      $scope.exportData = function() {
        Outputs.openXLSExportUrl('odpocty', $scope.SearchCtrl.getQuery());
      };
      $scope.importData = function() {
        var modalInstance = $modal.open({
          templateUrl: 'core/import/import.html',
          controller: 'GenericImportCtrl',
          backdrop: 'static',
          windowClass: 'detail',
          resolve: {
            list: function() {
              return $scope.odpocty;
            },
            model: function() {
              return Odpocet;
            }
          }
        });
        modalInstance.result.then(function(data) {
          Notification.add('Záznamy boli aktualizované.', 'success', 5);
          $scope.SearchCtrl.refresh();
        });
      };
      $scope.print = function() {
        Outputs.openPrintUrl('odpocty', null, $scope.SearchCtrl.getQuery());
      };
      $scope.printVymena = function() {
        $modal.open({
          templateUrl: 'svb/odpocty/vymena-meracov-print.html',
          controller: 'GenerovaniePredpisovCtrl',
          backdrop: 'static',
          windowClass: 'detail-sm'
        }).result.then(function(data) {
            //var date = parseDate(data.userinput);
            Outputs.openPrintUrl('odpocty', null, angular.extend({datum_ku: data.userinput}, $scope.SearchCtrl.getQuery()), 'print-vymena');
        });
      };
    }
  ]);
