'use strict';

angular.module('casist.svb')
  .controller('VlastnikDetailCtrl', ['$scope', '$stateParams', '$state', 'vlastnici', 'Notification', 'Priestor', '$controller', 'Dialog', '$timeout', 'Vlastnik', 'Osoba', 'Karta', 'vlastnik', 'Firma', 'Profile', '$modal',
    function($scope, $stateParams, $state, vlastnici, Notification, Priestor, $controller, Dialog, $timeout, Vlastnik, Osoba, Karta, vlastnik, Firma, Profile, $modal) {
      var defaultVlastnik = {sluzby: []};
      if (vlastnik) {
        $scope.vlastnik = Vlastnik.copy(vlastnik);
        $scope.osoby = Osoba.copy(vlastnik.osoby);
      } else {
        $scope.vlastnik = angular.copy(defaultVlastnik);
        $scope.osoby = [];
      }
      $scope.rokNaklady = parseInt(Profile.get('obdobie', new Date().getFullYear())) - 1;
      $scope.Priestor = Priestor;
      $scope.Firma = Firma;
      $scope.getPriestor = function(query, pagination) {
        return Priestor.get(query, pagination, {vchod: $scope.SearchCtrl.filters.top.vchod});
      };
      $scope.priestor = $scope.vlastnik.priestor_data;
      $timeout(function() {
        $scope.breadcrumb = [{title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.dom_data.nazov : _.find($scope.domy, {id: $scope.SearchCtrl.filters.top.dom}).nazov, state: 'default.vlastnici.list', params: {}}, {title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.nazov : _.find($scope.vchody, {id: $scope.SearchCtrl.filters.top.vchod}).nazov, state: 'default.vlastnici.list', params: {}}, {title: vlastnik ? $scope.vlastnik.meno : 'Nový vlastník', state: 'default.vlastnici.detail', params: {'vlastnikID': $scope.vlastnik.id}}];
      });
      $scope.isEdit = ($state.current.name !== 'default.vlastnici.add');

      $scope.goToBreadcrumb = function(item) {
        $state.go(item.state, angular.extend(item.params, {page: $stateParams.page}), {reload: $scope.vlastniciList.page != $stateParams.page});
      };
      $scope.goBack = function() {
        $scope.goToBreadcrumb($scope.breadcrumb[1]);
      };
      $scope.sposobyRozuctovania = Karta.getSposob();

      $scope.printPredpis = function() {
        $modal.open({
          templateUrl: 'svb/vlastnici/print-predpis.html',
          controller: 'PrintPredpisCtrl',
          backdrop: 'static',
          windowClass: 'detail-md',
          resolve: {
            vlastnik: function() {
              return $scope.vlastnik;
            }
          }
        }).result.then(function(data) {
            $scope.RecordCtrl.main.print('vlastnici', vlastnik.id);
          //Notification.add('Mozeme tlacit', 'success', 5);
        });
      };

      $scope.save = function() {
        $scope.RecordCtrl.main.save().then(function(data) {
          if (data.type === 'created') {
            $state.go('default.vlastnici.add', $stateParams, {reload: true});
          } else {
            $scope.goBack();
          }
          Notification.add('Vlastník bol uložený.', 'success', 5);
        });
      };
      $scope.cancel = function() {
        detectAndSaveChanges(vlastnik ? vlastnik.id : null, $scope.goBack);
      };
      $scope.remove = function() {
        var idx = vlastnici.indexOf(vlastnik)-1;
        var removalHandler = null;
        if (idx < 0 && vlastnici.length > 1) {
          idx = 1;
        }
        if (idx < 0) {
          removalHandler = function() {
            $state.go('default.vlastnici.add', {page: $scope.vlastniciList.page}, {reload: true});
          }
        } else {
          var noveID = vlastnici[idx].id;
          removalHandler = function() {
            $state.go('default.vlastnici.detail', {page: $scope.vlastniciList.page, vlastnikID: noveID}, {reload: true});
          }
        }
        $scope.RecordCtrl.main.remove().then(function() {
          removalHandler();
        });
      };

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        Model: Vlastnik
      });
      $scope.RecordCtrl.main.init({
        endpoint: 'vlastnici',
        model: 'vlastnik',
        master: vlastnik,
        list: $scope.vlastnici,
        defaultItem: defaultVlastnik,
        focusField: function() { return $scope.vlastnik.id ? 'meno' : 'priestor'; },
        preSave: function(obj) {
          return obj;
        }
      });
      $scope.RecordCtrl.related.init({
        model: 'osoby',
        lookup: 'vlastnik',
        object: Osoba,
        // preSave: pohybPreSave,
        postSave: function(obj, created) {
          if (created) {
            $scope.cancelAddOsoba();
          }
        }
        // preEdit: pohybPreEdit,
        //postDelete: pohybPostSave,
        //searchCtrl: pohybSearchCtrl
      });
      $scope.newOsoba = null;
      $scope.addOsoba = function() {
        $scope.newOsoba = {vlastnik: $scope.vlastnik.id};
        $timeout(function() {
          $('#newOsoba_first').focus();
        });
      };
      $scope.cancelAddOsoba = function() {
        $scope.newOsoba = null;
      };
      $scope.sumy = {
        zaloha: 0,
        prepocitana: 0
      };
      $scope.recountSums = function() {
        $scope.sumy.zaloha = 0;
        $scope.sumy.prepocitana = 0;
        for (var i = 0; i < $scope.vlastnik.sluzby.length; i++) {
          $scope.sumy.zaloha += parseFloat($scope.vlastnik.sluzby[i].zaloha || 0);
          $scope.sumy.prepocitana += parseFloat($scope.vlastnik.sluzby[i].prepocitana || 0);
        }
      };
      $scope.recountSums();

      var isRecordChanged = function() {
        if ($scope.RecordCtrl.hasRelatedChanged()) {
          return true;
        }
        if ($scope.vlastnik.id) {
          return !objEquals(Priestor.stripRestangular(vlastnik), Priestor.stripRestangular($scope.vlastnik));
        } else {
          return !objEquals(defaultVlastnik, Priestor.stripRestangular($scope.vlastnik));
        }
      };
      var setRecord = function(id) {
        $state.go('default.vlastnici.detail', {page: $scope.vlastniciList.page, vlastnikID: id});
      };
      var detectAndSaveChanges = function(id, handler) {
        if (isRecordChanged()) {
          Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
            var promise = $scope.RecordCtrl.main.save(true);
            if (promise) {
              promise.then(function(success) {
                if (handler) {
                  handler(id);
                } else {
                  setRecord(id);
                }
              }, function(error) {
                Dialog.alert(error, 'Chyba');
              });
            }
          }, function() {
            if (handler) {
              handler(id);
            } else {
              setRecord(id);
            }
          });
        } else {
          if (handler) {
            handler(id);
          } else {
            setRecord(id);
          }
        }
      };
      $scope.changeVlastnik = function(item) {
        detectAndSaveChanges(item.id, function() {
          $state.go('default.vlastnici.detail', {vlastnikID: item.id, page: $scope.vlastniciList.page});
        });
      };
      $scope.addVlastnik = function() {
        detectAndSaveChanges(null, function() {
          $state.go('default.vlastnici.add', {page: $scope.vlastniciList.page});
        });
      };
      $scope.nextWindowRecord = function(index) {
        var list = $scope.vlastnici;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find($scope.vlastnici, {id: parseInt($stateParams.vlastnikID)}));
        }
        var nextItem = null;
        if (index !== -1) {
          nextItem = list.length > index-1 ? list[index+1] : null;
        }
        if (!nextItem) {
          if ($scope.vlastniciList.num_pages > $scope.vlastniciList.page) {
            $scope.vlastniciList.page++;
            $scope.refreshPriestoryList().then(function(data) {
              nextItem = data[0];
              detectAndSaveChanges(nextItem.id);
            });
          } else {
            detectAndSaveChanges(null, function() {
              $state.go('default.vlastnici.add');
            });
          }
        } else {
          detectAndSaveChanges(nextItem.id);
        }
      };
      $scope.prevWindowRecord = function(index) {
        var list = $scope.vlastnici;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find($scope.vlastnici, {id: parseInt($stateParams.vlastnikID)}));
        }
        if ($scope.vlastniciList.page == 1 && index === 0) {
          return;
        }
        if (!vlastnik && list.length) {
          index = list.length;
        }
        var prevItem = null;
        if (index !== -1) {
          prevItem = list.length > 0 ? list[index-1] : null;
        }
        if (prevItem) {
          detectAndSaveChanges(prevItem.id);
        } else {
          if ($scope.vlastniciList.page > 1) {
            $scope.vlastniciList.page--;
            $scope.refreshPriestoryList().then(function(data) {
              prevItem = data[data.length-1];
              detectAndSaveChanges(prevItem.id);
            });
          }
        }
      };
  }]);
