'use strict';

angular.module('casist.ucto').controller('PokladnicneDokladyCtrl',
  ['$scope', '$rootScope', 'Profile', '$controller', 'PokladnicnyDoklad', 'Firma', 'Outputs', '$q', 'DokladTypy', 'Pokladna', 'TypDPH', 'TabService', 'Dialog', 'Notification', 'Predkontacia', '$modal',
  function ($scope, $rootScope, Profile, $controller, PokladnicnyDoklad, Firma, Outputs, $q, DokladTypy, Pokladna, TypDPH, TabService, Dialog, Notification, Predkontacia, $modal) {

  $scope.slideAnimation = true;

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {};
  if (!isEmpty(Profile.get('pokladne'))) {
    defaultFilter = {
      pokladna: _.map(Profile.get('pokladne'), pokladna => pokladna.id).join(','),
      dd: DokladTypy.PD.dd
    }
  }
  $scope.uctovanieTypy = [
    {id: 1, uctovanie: 'Nezaúčtované'},
    {id: 2, uctovanie: 'Ručne účtované'},
    {id: 3, uctovanie: 'Predkontácia'},
    {id: 4, uctovanie: 'Zaúčtované'}
  ];
  $scope.rozuctovanieTypy = [
    {id: 1, rozuctovanie: 'Nerozúčtované'},
    {id: 2, rozuctovanie: 'Rozúčtované'}
  ];
  $scope.pociatocnyStav = undefined;
  $scope.pokladna = [];
  var filterChanged = function(filter) {
    var promise = PokladnicnyDoklad.objects().getList(filter);
    $scope.pociatocnyStav = undefined;
    $scope.pokladna.loading = true;
    promise.then(function(data) {
      TabService.registerScope('pokladnicnedoklady', $scope);
      if (data.sumline.length > 1) {
        var sums = angular.copy(data.sumline);
        $scope.pociatocnyStav = sums[1];
        data.sumline[1].spolu = parseFloat(data.sumline[0].spolu) + parseFloat(sums[1].poc_stav);
      }
      $scope.pokladna = data;
    });
    return promise;
  };
  $scope.parseSums = function(obj, key) {
    return parseFloat(obj[key]*obj.operacia);
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.getPredkontacia = function(query) {
    return Predkontacia.get(query, DokladTypy.PD.dd);
  };
  $scope.operacie = [
    {id: '1', operacia: '+'},
    {id: '-1', operacia: '-'}
  ];
  $scope.pokladne = Pokladna.objects().getList({active: 'True'}).$object;
  $scope.typydph = TypDPH.objects().getList({ordering: 'typ'}).$object;

  var coreFields = [
    {  },
    {  },
    { field: 'pokladna', label: 'pokladna.KP', filterCollapse: 'right', select2: { source: 'pokladne', lookup: 'kod' }, tooltip: 'pokladna.KOD_POKLADNE' },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'd_zdp', label: 'pokladna.UHRADENE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'operacia', label: 'pokladna.OPERACIA', width: 30, select2: { source: 'operacie', lookup: 'operacia' } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true }
  ];
  var view1Fields = [
    { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
    { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
    { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
    { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
    { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
    { field: 'faktura_field__iddokd', label: 'pokladna.UHRADA' },
    { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
    { field: 'typ_dph', label: 'ucto.TDPH', select2: { source: 'typydph', lookup: 'typ' } },
    { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
  ];

  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'typ_dph', 'uplat_dph'];
    view1Fields = _.filter(view1Fields, function(field) { return omitted.indexOf(field.field) === -1});
  }
  $scope.pokladnaTable = [
    _.union(coreFields, view1Fields),
    _.union(coreFields, [
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number', sum: true },
      { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
      { field: 'mena', label: 'ucto.MENA' },
      { field: 'suma1', label: 'fakturacia.SUMA1', type: 'number', sum: true },
      { field: 'suma2', label: 'fakturacia.SUMA2', type: 'number', sum: true },
      { field: 'suma3', label: 'fakturacia.SUMA3', type: 'number', sum: true },
      { field: 'suma4', label: 'fakturacia.SUMA4', type: 'number', sum: true },
      { field: 'suma5', label: 'fakturacia.SUMA5', type: 'number', sum: true },
    ]),
    _.union(_.filter(coreFields, function(field) { return field.field != 'vs'; }), [
      { field: 'hotovost', label: 'pokladna.HOTOVOST', type: 'number', sum: true },
      { field: 'karta', label: 'pokladna.KARTA', type: 'number', sum: true },
      { field: 'zlava_suma', label: 'fakturacia.ZLAVA_SUMA', type: 'number', sum: true },
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'blok', label: 'pokladna.BLOK', type: 'int' },
      { field: 'popis', label: 'Popis', filterCollapse: 'left' }
    ])
  ];

  $scope.create = function(item) {
    return PokladnicnyDoklad.edit(item, $scope.pokladna, $scope.SearchCtrl);
  };
  $scope.copy = function(item) {
    var copied = PokladnicnyDoklad.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'd_vyst', 'd_zdp', 'd_uctn', 'uplat_dph', 'faktura', 'faktura_data', 'locked'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };

  var events = PokladnicnyDoklad.registerEvents(function() { return $scope.pokladna; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    PokladnicnyDoklad.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: PokladnicnyDoklad,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.pokladnaTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh();

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE', 'Prehľad RP' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.SearchCtrl.changeColumnsDef($scope.pokladnaTable[view]);
    if (view === 2) {
      $scope.SearchCtrl.setDefaultFilter(angular.extend(defaultFilter, {dd: DokladTypy.RP.dd}));
      $scope.SearchCtrl.setLastPage(true);
    } else if ($scope.currentView === 2) {
      $scope.SearchCtrl.setDefaultFilter(angular.extend(defaultFilter, {dd: DokladTypy.PD.dd}));
      $scope.SearchCtrl.setLastPage(true);
    }
    $scope.currentView = view;
  };

  $scope.print = function(doklad, url) {
    Outputs.openPrintUrl('pokladna', doklad.id, undefined, url);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('pokladna', null, $scope.SearchCtrl.getQuery());
  };
  $scope.printDennyObrat = function() {
    var query = $scope.SearchCtrl.getQuery();
    delete query.ordering;
    Outputs.openPrintUrl('pokladna', null, query, 'print-denny-obrat');
  };

  $scope.globalneUctovanie = function() {
    if (!$scope.SearchCtrl.hasFilter()) {
      Dialog.alert('Globálne účtovanie je možné použiť len keď je použitý filter.', 'Upozornenie');
      return;
    }
    $modal.open({
      templateUrl: 'ucto/globalne-uctovanie/globalne-uctovanie.html',
      controller: 'GlobalneUctovanieCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        list: function() {
          return $scope.pokladna;
        },
        query: function() {
          return $scope.SearchCtrl.getQuery();
        },
        model: function() {
          return PokladnicnyDoklad;
        },
        predkontacieGetter: function() {
          return function(query) { return $scope.getPredkontacia(query); };
        }
      }
    }).result.then(function(data) {
      Notification.add('Záznamy boli zaúčtované.', 'success', 5);
      var faktura;
      for (var i = 0; i < data.length; i++) {
        faktura = _.find($scope.pokladna, {id: data[i].id});
        if (faktura) {
          PokladnicnyDoklad.syncChange(data[i], angular.copy(faktura));
        }
      }
    });
  };

  $scope.onDisplayAttachment = function (pokladna) {
    Outputs.openPrintUrl('pokladna', pokladna.id, undefined, 'attachment');
  };

  $scope.precislovanieDokladov = function () {
    PokladnicnyDoklad.precislovanieDokladov($scope.SearchCtrl.getQuery()).then(
      function (response) {
        Notification.add(response, 'success', 10);
        $scope.SearchCtrl.refresh();
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add(error.data, 'danger', 10);
        } else if (error.status >= 500) {
          Notification.add('Nastala serverová chyba.', 'danger', 10);
        }
      }
    );
  };
}]);
