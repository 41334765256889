'use strict';

angular.module('casist.core')
  .factory('BankAccount', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "settings_ucty";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.kod) {
            this.addError('kod', 'Pole kód je povinné.');
            res = false;
          }
          if (!this.iban) {
            this.addError('iban', 'Pole IBAN je povinné.');
            res = false;
          }
          if (!this.bic) {
            this.addError('bic', 'Pole BIC je povinné.');
            res = false;
          }
          return res;
        }
      });
    });
    var openDetail = function(list) {
      var modalInstance = $modal.open({
        templateUrl: 'core/ucty/bankAccount-detail.html',
        controller: 'BankAccountCtrl',
        backdrop: 'static',
        resolve: {
          list: function() {
            return list;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object('settings/settings_ucty');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').banka;
      },
      editUcty: function(list) {
        var promise = $q.defer();
        openDetail(list).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
    });
  }]);
