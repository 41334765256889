'use strict';

angular.module('casist.widgets')
  .directive('inputPercent', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.unshift(function(viewValue) {
          viewValue = viewValue.replace(/%/g, '').trim();
          return viewValue;
        });

        ngModelCtrl.$formatters.unshift(function(modelValue) {
          if (!modelValue)
            return modelValue;
          
          return modelValue+' %';
        });

        element.bind('blur', function() {
          element.val(ngModelCtrl.$modelValue+' %');
        });
      }
    };
  });
