'use strict';

angular.module('casist.sklad')
  .controller('KartyCtrl', ['$scope', 'Restangular', 'Karta', 'Profile', '$controller', 'TabService', 'Outputs', '$modal', 'Notification', 'Skupina', 'Podskupina', 'Vyrobca', 'Firma',
              function ($scope, Restangular, Karta, Profile, $controller, TabService, Outputs, $modal, Notification, Skupina, Podskupina, Vyrobca, Firma) {
    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.Skupina = Skupina;
    $scope.Podskupina = Podskupina;
    $scope.Vyrobca = Vyrobca;
    $scope.Firma = Firma;

    $scope.karty = [];
    var filterChanged = function(filter) {
      var promise = Karta.objects().getList(filter);
      $scope.karty.loading = true;
      promise.then(function(data) {
        TabService.registerScope('karta', $scope);
        $scope.karty = data;
      });
      return promise;
    };
    var coreFields = [
      { width: 70 },
      { selectAllAddon: true },
      { field: 'kod', label: 'karty.KOD'},
      { field: 'nazov', label: 'karty.NAZOV' },
    ];

    $scope.kartyTable = [
      _.union(coreFields, [
        { field: 'mo', label: 'karty.MO', type: 'number'},
        { field: 'modph', label: 'karty.MODPH', type: 'number'},
        { label: 'karty.MMO', type: 'number'},
        { field: 'vo', label: 'karty.VO', type: 'number'},
        { label: 'karty.MVO', type: 'number'},
        { field: 'mo_cz', label: 'karty.MO_CZ', type: 'number'},
        { field: 'modph_cz', label: 'karty.MODPH_CZ', type: 'number'},
        { field: 'cena_sklad', label: 'karty.CENA_SKLAD', type: 'number'},
        { field: 'kod_ean', label: 'karty.KOD_EAN', filterCollapse: 'right', width: 150 },
        { field: 'kod_dodavatela', label: 'karty.KOD_DODAVATELA' },
      ]),
      _.union(coreFields, [
        { field: 'typ__typ', label: 'karty.TYP' },
        { field: 'dph', label: 'karty.DPH', type: 'number'},
        { field: 'mj', label: 'karty.MJ'},
        { field: 'zaruka', label: 'karty.ZARUKA', type: 'int'},
        { field: 'min_mnozstvo', label: 'karty.MINMNOZSTVO', type: 'number'},
        { field: 'druh__nazov', label: 'karty.DRUH'},
        { field: 'skupina', label: 'karty.SKUPINA', linked: { source: 'Skupina.get(query)', lookup: 'nazov', editable: false }},
        { field: 'podskupina', label: 'karty.PODSKUPINA', linked: { source: 'Podskupina.get(query)', lookup: 'nazov', editable: false }},
        { field: 'balenie', label: 'karty.BALENIE', type: 'number'},
        { field: 'vaha_netto', label: 'karty.VAHA_NETTO', type: 'number'},
        { field: 'vaha_brutto', label: 'karty.VAHA_BRUTTO', type: 'number'},
        { field: 'objem', label: 'karty.OBJEM', type: 'number'},
        { field: 'vyrobca', label: 'karty.VYROBCA', linked: { source: 'Vyrobca.get(query)', lookup: 'firma', editable: false }},
        { field: 'dodavatel', label: 'karty.DODAVATEL', linked: { source: 'Firma.get(query)', lookup: 'firma', editable: false }},
        { field: 'popis', label: 'karty.POPIS'}
      ]),
    ];
    $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
    $scope.currentView = 0;
    $scope.setCurrentView = function(view) {
      $scope.currentView = view;
      $scope.SearchCtrl.changeColumnsDef($scope.kartyTable[view]);
    };

    if (Profile.get('svb')) {
      var omitted = ['mo', 'modph'];
      $scope.kartyTable[0] = _.filter($scope.kartyTable[0], function(field) { return omitted.indexOf(field.field) === -1});
    } else {
      var omitted = ['sposob_rozuctovania', 'cena_priemer', 'cena_dodanie', 'cena_namerane'];
      $scope.kartyTable[0] = _.filter($scope.kartyTable[0], function(field) { return omitted.indexOf(field.field) === -1});
    }
    if (!Profile.get('platcadph')) {
      var omitted = ['modph'];
      $scope.kartyTable[0] = _.filter($scope.kartyTable[0], function(field) { return omitted.indexOf(field.field) === -1});
    }
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.kartyTable,
      sort: {sort: 'kod'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(TabService.bus.get('karta'));

    var events = Karta.registerEvents(function() { return $scope.karty; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Karta.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    var updateOznacenych = function () {
      var l = _.keys($scope.selected).length;
      switch (l) {
        case 0:
          $scope.oznacenych = '';
          break;
        case 1:
          $scope.oznacenych = l + ' označený záznam';
          break;
        case 2:
        case 3:
        case 4:
          $scope.oznacenych = l + ' označené záznamy';
          break;
        default:
          $scope.oznacenych = l + ' označených záznamov';
          break;
      }
    };

    $scope.selected = {};
    $scope.selectedAll = false;
    var showMessage = false;
    $scope.selectAll = function() {
      var selectedAll = !$scope.selectedAll;
      $scope.selected = {};
      for (var i = 0; i < $scope.karty.length; i++) {
        $scope.karty[i].selected = selectedAll;
          if (selectedAll) {
            $scope.selected[$scope.karty[i].id] = $scope.karty[i];
          } else {
            delete $scope.selected[$scope.karty[i].id];
          }
      }

      showMessage = !showMessage;
      if (showMessage) {
        $scope.oznacenych = 'Označené všetky záznamy';
      } else {
        $scope.oznacenych = '';
      }
    };

    $scope.selectKarta = function (karta) {
      if ($scope.selectedAll) {
        $scope.selectedAll = false;
      }
      if ($scope.selected[karta.id]) {
        delete $scope.selected[karta.id];
      } else {
        $scope.selected[karta.id] = karta;
      }
      updateOznacenych();
    };

    $scope.barcodePrint = function(karta) {
    if (!angular.isDefined(karta)) {
      var ids = _.keys($scope.selected);
      Karta.barcode(ids).then(function(data) {
        for (var d = 0; d < data.length; d++) {
          for (var i in $scope.selected) {
              $scope.selected[i].selected = false;
          }
        }
        updateOznacenych();
      }, function() {
        Notification.add('Tlač čiarových kódov zlyhala.', 'danger', 5);
      });
    }
  };

    $scope.create = function(item) {
      return Karta.edit(item, $scope.karty, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
      var copied = Karta.copy(item);
      _.each(['id'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };

    $scope.display = function(item) {
      Karta.displayImage(item, $scope.karty, $scope.SearchCtrl);
    };

    $scope.switchFields = function() {
      Karta.switchFields().then(function () {
        Notification.add('Záznamy boli aktualizované.', 'success', 10);
        $scope.SearchCtrl.refresh();
      }).catch(function () {
        Notification.add('Aktualizovanie záznamov zlyhalo.', 'danger', 10);
      });
    };

    $scope.exportKarty = function() {
      Outputs.openXLSExportUrl('karty', $scope.SearchCtrl.getQuery());
    };

    $scope.kartyMediaExport = function() {
      Outputs.openXMLExportUrl('karty', 'media', $scope.SearchCtrl.getQuery());
    };

    $scope.printBarcode = function () {
      Outputs.openPrintUrl('karty', 'barcode', { ids: _.keys($scope.selected) });
    };

    $scope.openImport = function() {
      var modalInstance = $modal.open({
        templateUrl: 'core/import/import.html',
        controller: 'GenericImportCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          list: function() {
            return $scope.karty;
          },
          model: function() {
            return Karta;
          }
        }
      });
      modalInstance.result.then(function(data) {
        Notification.add('Záznamy boli aktualizované.', 'success', 5);
        $scope.SearchCtrl.refresh();
      });
    };

  $scope.kartyMediaImport = function($files) {
    if (!$files.length) {
      Notification.add('Súbor sa nepodarilo nahrať.', 'danger', 10);
      return;
    }

    var file = $files[0];

    var parts = file.name.split('.');
    if (parts[1] !== 'xls' && parts[1] !== 'xlsx') {
      Notification.add('Súbor bol nahratý v nesprávnom formáte. Požadovaný formát je xls/xlsx.', 'danger', 10);
      return;
    }

    Karta.kartyMediaImport(file).then(function (response) {
      Notification.add(response.data, 'success', 10);
      $scope.SearchCtrl.refresh();
    }).catch(function (error) {
      if (error.status >= 400 && error.status < 500) {
        Notification.add(error.data, 'danger', 10);
      } else if (error.status >= 500) {
        Notification.add('Nastala serverová chyba.', 'danger', 10);
      }
    });
  };

  }]);
