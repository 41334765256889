'use strict';

angular.module('casist.core')
  .factory('FirmaOsoba', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    Restangular.extendModel("osoby", function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.meno) {
            this.addError('meno', 'Pole nemôže byť prázdne');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });
    return angular.extend(BaseCollectionModel.object("firmy/osoby"), {
      getPerms: function() {
        return Profile.get('perms').firma;
      }
    });
  }]);
