"use strict";

angular.module("casist.sklad").controller("FakturaciaDetailCtrl", [
  "$scope",
  "$modalInstance",
  "faktura",
  "faktury",
  "config",
  "$timeout",
  "$modal",
  "Global",
  "$http",
  "$controller",
  "Profile",
  "SearchCtrl",
  "Faktura",
  "Prevadzka",
  "Firma",
  "TypDPH",
  "Predkontacia",
  "$q",
  "Pohyb",
  "DokladTypy",
  "Doprava",
  "Mena",
  "Predajca",
  "Hlavicka",
  "Pata",
  "Ucet",
  "Outputs",
  "Dialog",
  "$filter",
  "Mailbox",
  "Karta",
  "FakturyTyp",
  "Sklad",
  "CennikZaznam",
  function (
    $scope,
    $modalInstance,
    faktura,
    faktury,
    config,
    $timeout,
    $modal,
    Global,
    $http,
    $controller,
    Profile,
    SearchCtrl,
    Faktura,
    Prevadzka,
    Firma,
    TypDPH,
    Predkontacia,
    $q,
    Pohyb,
    DokladTypy,
    Doprava,
    Mena,
    Predajca,
    Hlavicka,
    Pata,
    Ucet,
    Outputs,
    Dialog,
    $filter,
    Mailbox,
    Karta,
    FakturyTyp,
    Sklad,
    CennikZaznam
  ) {
    $scope.hasList = faktury.length !== 0;
    $scope.jednoducheUctoClass = Profile.get("jednoducheUcto")
      ? "jednoduche-ucto"
      : "";

    $scope.Karta = Karta;
    $scope.FakturyTyp = FakturyTyp;

    $scope.skladChanged = function (item, parent) {
      if (!parent) {
        parent = $scope.newItem;
      }
      if (item) {
        parent.sklad = parseInt(item);
      }
      parent.sklad_data = _.find($scope.currentPrevadzka.sklady, {
        id: parent.sklad,
      });
    };

    if (config.predkontacie) {
      $scope.Predkontacia = Predkontacia;
      $scope.getPredkontacie = function (query) {
        return Predkontacia.get(query, DokladTypy.VF.dd);
      };
      $scope.addPredkontacia = function (nazov) {
        return Predkontacia.add(
          nazov,
          DokladTypy.VF.dd,
          $scope.faktura.uctovanie,
          undefined,
          $scope.faktura.popis
        );
      };
    }
    if (config.ucty) {
      var uctyQueries = [];
      Ucet.get().then(function (ucty) {
        $scope.ucty = ucty;

        $scope.getUcty = function (query, page, parent) {
          if (query === null) {
            query = undefined;
          }
          var re = new RegExp(query, "i");
          var matches = [];
          for (var i = 0; i < $scope.ucty.length; i++) {
            if (
              (!$scope.ucty[i].operacia || $scope.ucty[i].operacia === 1) &&
              (!$scope.ucty[i].only_md ||
                ($scope.ucty[i].only_md && parent.type === "md")) &&
              (!$scope.ucty[i].only_d ||
                ($scope.ucty[i].only_d && parent.type === "d")) &&
              ($scope.ucty[i].ucet.match(query) ||
                $scope.ucty[i].nazov.match(re))
            ) {
              matches.push(angular.copy($scope.ucty[i]));
            }
          }
          return matches;
        };
        var result;
        for (var i = 0; i < uctyQueries.length; i++) {
          result = $scope.getUcty(
            uctyQueries[i].query,
            uctyQueries[i].page,
            uctyQueries[i].parent
          );
          uctyQueries[i].deferred.resolve(result);
        }
      });
      $scope.getUcty = function (query, page, parent) {
        var deferred = $q.defer();
        uctyQueries.push({
          query: query,
          page: page,
          parent: parent,
          deferred: deferred,
        });
        return deferred.promise;
      };

      $scope.addUcet = function (ucet) {
        var promise = Ucet.add(
          ucet,
          Profile.get("jednoducheUcto") ? { only_d: true } : undefined
        );
        promise.then(function (data) {
          $scope.ucty.push(angular.copy(data));
        });
        return promise;
      };
    }
    $scope.Mena = Mena;
    if (config.hlavicky) {
      $scope.Hlavicka = Hlavicka;
      $scope.getHlavicky = function (query) {
        return Hlavicka.get(
          query,
          $scope.faktura.typ ? { typ: $scope.faktura.typ } : { typ__isnull: 1 }
        );
      };
      $scope.addHlavicka = function (hlavicka) {
        return Hlavicka.add(
          hlavicka,
          $scope.faktura.typ ? { typ: $scope.faktura.typ } : undefined
        );
      };
    }
    if (config.paty) {
      $scope.Pata = Pata;
      $scope.getPaty = function (query) {
        return Pata.get(
          query,
          $scope.faktura.typ ? { typ: $scope.faktura.typ } : undefined
        );
      };
      $scope.addPata = function (pata) {
        return Pata.add(
          pata,
          $scope.faktura.typ ? { typ: $scope.faktura.typ } : undefined
        );
      };
    }
    $scope.typChanged = function (typ, setDefault) {
      Hlavicka.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.faktura.hlavicka) || !setDefault) {
          if (data.length) {
            $scope.faktura.hlavicka = data[0].text;
          } else {
            $scope.faktura.hlavicka = "";
          }
        }
        if (setDefault) {
          defaultFaktura.hlavicka = $scope.faktura.hlavicka;
          Global.set("faktura_hlavicka", defaultFaktura.hlavicka);
        }
      });
      Pata.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.faktura.pata) || !setDefault) {
          if (data.length) {
            $scope.faktura.pata = data[0].text;
          } else {
            $scope.faktura.pata = "";
          }
        }
        if (setDefault) {
          defaultFaktura.pata = $scope.faktura.pata;
          Global.set("faktura_pata", defaultFaktura.pata);
        }
      });
    };
    if (config.predajca) {
      $scope.Predajca = Predajca;
    }
    if (config.doprava) {
      $scope.Doprava = Doprava;
    }
    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Faktura,
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      pohladavkyPohyb: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_pohyb",
      }),
      pohladavkyRozuctovanie: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_rozuct",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.pohladavkyPohyb)
        ? layouts.pohladavkyPohyb.value
        : true,
      false,
      angular.isDefined(layouts.pohladavkyRozuctovanie)
        ? layouts.pohladavkyRozuctovanie.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "pohladavky_" + (panel === 0 ? "pohyb" : "rozuct");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function () {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.pohladavkyRozuctovanie.value) {
        $scope.panels[2] = layouts.pohladavkyRozuctovanie.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31),
      typDPHMaxPlatnost = new Date(1970, 1, 1);
    var updateTypDPH = function (datum) {
      if (!Profile.get("platcadph")) {
        return;
      }
      TypDPH.getValid(datum, "1,0").then(function (data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        if ($scope.faktura.dd === DokladTypy.VF.dd) {
          if (
            data.data.length &&
            (!$scope.faktura.typ_dph ||
              !_.find(data.data, { id: $scope.faktura.typ_dph }))
          ) {
            $scope.faktura.typ_dph = data.data[0].id;
          } else if ($scope.faktura.id) {
            $scope.faktura.typ_dph = faktura.typ_dph;
          }
        }
        $timeout(function () {
          $scope.recountSums();
        }, 1000);

        if (data.data.length && !angular.isDefined(defaultFaktura.typ_dph)) {
          defaultFaktura.typ_dph = data.data[0].id;
        }
      });
    };
    $scope.help = {};
    $scope.helpClosed = function () {
      delete $scope.help.typdph;
    };
    $scope.displayHelpTypDPH = function () {
      var el = '<table class="table table-condensed">';
      for (var i = 0; i < $scope.typyDPH.length; i++) {
        el +=
          '<tr><td width="70">' +
          $scope.typyDPH[i].typ +
          "</td><td>" +
          $scope.typyDPH[i].popis +
          "</td></tr>";
      }
      el += "</table>";
      $scope.help.typdph = el;
    };
    $scope.$watch("faktura.d_vyst", function (val, oldVal) {
      if (val) {
        var date = parseDate(val);
        if (
          !isNaN(date) &&
          (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)
        ) {
          updateTypDPH(val);
        }
      }
    });
    if (Profile.get("platcadph")) {
      $scope.$watch("faktura.typ_dph", function (val) {
        if (
          angular.isDefined($scope.typyDPH) &&
          $scope.faktura.dd === DokladTypy.VF.dd
        ) {
          if (val) {
            $scope.faktura.typdph_data = _.find($scope.typyDPH, {
              id: parseInt(val),
            });
            if (
              !$scope.faktura.id &&
              !angular.isDefined(defaultFaktura.typdph_data)
            ) {
              defaultFaktura.typdph_data = $scope.faktura.typdph_data;
            }
          } else {
            $scope.faktura.typdph_data = undefined;
          }
        }
      });
    }
    $scope.setDodanie = function () {
      $scope.faktura.d_zdp = $scope.faktura.d_vyst;
      var d_vyst = parseDate($scope.faktura.d_vyst);
      $scope.faktura.d_splat = getCurrentDate(
        new Date(d_vyst.getFullYear(), d_vyst.getMonth(), d_vyst.getDate() + 1)
      );
      $scope.setUplatDPH();
    };
    $scope.setUplatDPH = function () {
      $scope.faktura.uplat_dph = $scope.faktura.d_zdp;
      $scope.faktura.d_uctn = $scope.faktura.d_zdp;
    };
    $scope.selectedPredkontacia = function (predkontacia) {
      var fields = Faktura.getUctoFields();
      $scope.faktura.uctovanie = {};
      var foundAllUcty = true;
      for (var i = 0; i < fields.length; i++) {
        if (
          predkontacia[fields[i] + "_md"] &&
          !_.find($scope.ucty, { id: predkontacia[fields[i] + "_md"] })
        ) {
          foundAllUcty = false;
        }
        if (
          predkontacia[fields[i] + "_d"] &&
          !_.find($scope.ucty, { id: predkontacia[fields[i] + "_d"] })
        ) {
          foundAllUcty = false;
        }
      }
      var prekopirovatUctovanie = function () {
        for (var i = 0; i < fields.length; i++) {
          $scope.faktura.uctovanie[fields[i] + "_md"] =
            predkontacia[fields[i] + "_md"];
          $scope.faktura.uctovanie[fields[i] + "_d"] =
            predkontacia[fields[i] + "_d"];
          $scope.faktura.uctovanie[fields[i] + "_popis"] =
            predkontacia[fields[i] + "_popis"];
        }
      };
      // aktualizovat ucty v pamati, ak tam nejaky z predkontacie nepozname
      if (!foundAllUcty) {
        Ucet.get().then(function (ucty) {
          $scope.ucty = ucty;
          prekopirovatUctovanie();
        });
      } else {
        prekopirovatUctovanie();
      }
      if (!$scope.faktura.popis) {
        $scope.faktura.popis = predkontacia.popis;
      }
      if (predkontacia.typ_dph) {
        $scope.faktura.typ_dph = predkontacia.typ_dph;
      }
    };

    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    var today = new Date();
    var splat = new Date(
      angular.copy(today).setDate(today.getDate() + Global.get("splatnost_fa"))
    );

    if (
      !angular.isDefined(Global.get("faktura_hlavicka")) ||
      !angular.isDefined(Global.get("faktura_pata"))
    ) {
      $scope.typChanged(undefined, true);
    }

    var defaultFaktura = {
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      d_uctn: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get("lastPrevadzka").id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      hlavicka: config.hlavicky ? Global.get("faktura_hlavicka") : undefined,
      konecny_prijemca: null,
      pata: config.paty ? Global.get("faktura_pata") : undefined,
      mena: null,
      sd1: Global.get("sd1"),
      sd2: Global.get("sd2"),
      d_splat: getCurrentDate(splat),
      rozuctovanie: [],
    };
    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.pohybColumns = [
      { width: 30 },
      { field: "kod", label: "pohyb.KOD", width: 120 },
      { field: "nazov", label: "pohyb.NAZOV" },
      { field: "sklad", label: "pohyb.SKLAD" },
      { field: "mv", label: "pohyb.MNOZSTVO", width: 90 },
      {
        field: "cena_vydaj",
        label: "pohyb.CENAMJ",
        width: 105,
        currencyAddon: "faktura",
      },
      { field: "marza", label: "fakturacia.MARZAP", width: 65 },
      { field: "zlava", label: "fakturacia.ZLAVAP", width: 65 },
      { field: "mj", label: "pohyb.MJ", width: 50 },
      { field: "dph", label: "pohyb.DPH", width: 60 },
      { field: "poznamka", label: "fakturacia.POZNAMKA", width: 150 },
      { width: 60 },
    ];
    if (!Profile.get("platcadph")) {
      var omitted = ["dph"];
      $scope.pohybColumns = _.reject($scope.pohybColumns, function (obj) {
        return omitted.indexOf(obj.field) !== -1;
      });
    }
    $scope.pohyb = [];

    var updateShowVAT = function () {
      $scope.showVATfields = $scope.faktura.id
        ? $scope.faktura.dd === DokladTypy.VF.dd
        : angular.isDefined(config.VATfields)
        ? config.VATfields
        : true;
      $scope.faktura.dd = $scope.showVATfields
        ? DokladTypy.VF.dd
        : DokladTypy.OP.dd;
      defaultFaktura.dd = $scope.faktura.dd;
    };

    if (!isEmpty(faktura)) {
      $scope.faktura = Faktura.copy(faktura);
      _.defaults($scope.faktura, defaultFaktura);
      if (
        $scope.faktura.firma_data &&
        $scope.faktura.firma_data.splatnost &&
        !$scope.faktura.d_splat
      ) {
        $scope.faktura.d_splat = getCurrentDate(
          new Date(
            angular
              .copy(today)
              .setDate(today.getDate() + $scope.faktura.firma_data.splatnost)
          )
        );
      }
      FakturyTyp.restangularize($scope.faktura.typ_data);
      $scope.prevadzky = _.filter($scope.prevadzky, {
        id: $scope.faktura.prevadzka,
      });
      if ($scope.prevadzky.length)
        $scope.currentPrevadzka = $scope.prevadzky[0];
      if (!faktura.pohyb || $scope.faktura.id) {
        $scope.faktura.getList("pohyb").then(function (pohyb) {
          $scope.RecordCtrl.related.paginateList(pohyb, 20, $scope.sorter);

          $timeout(function () {
            $scope.recountSums();
          });
        });
      } else {
        $timeout(function () {
          for (var i = 0; i < faktura.pohyb.length; i++) {
            $scope.RecordCtrl.related.add(faktura.pohyb[i]);
          }
          delete faktura.pohyb;
        });
      }
    } else {
      $scope.faktura = Faktura.restangularize(angular.copy(defaultFaktura));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.faktura.prevadzka,
      });
      $timeout(function () {
        $scope.RecordCtrl.related.paginateList([], 20, $scope.sorter);
      });
    }
    $scope.newItem = Pohyb.restangularize({
      dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
    });
    updateShowVAT();
    $scope.skladChanged(
      $scope.currentPrevadzka.sklady.length
        ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
        : undefined
    );
    var defaultPohybItem = angular.copy($scope.newItem);

    var pohybPreSave = function (item, create) {
      if (!item.karta_data) {
        item.kod = null;
        if (item.karta) {
          item.nazov = item.karta;
          delete item.karta;
        }
      }
      if (angular.isDefined(item.cena_edit)) {
        if ($scope.faktura.mena && $scope.faktura.kurz) {
          item.prepocitatKurzom($scope.faktura);
        } else {
          item.cena_vydaj = item.cena_edit;
          item.cena_zm = 0;
        }
        delete item.cena_edit;
      }
      $scope.skladChanged(item.sklad, item);

      return item;
    };
    var pohybPostSave = function (item, created) {
      $("#pohyb_kod").focus();
      $scope.recountSums();
      angular.extend($scope.newItem, {
        dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
      });
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
      Pohyb.updateSkladLabelsWithQty($scope);
      if (document.getElementById("pohyb_kod")) {
        document.getElementById("pohyb_kod").scrollIntoView();
      }
      return item;
    };
    var pohybPreEdit = function (item) {
      if ($scope.faktura.primarna_zm) item.cena_edit = item.cena_zm;
      else item.cena_edit = item.cena_vydaj;
      return item;
    };
    $scope.recountSums = function () {
      $scope.sumaFakturyDPH = 0;
      $scope.sumaFaktury = 0;
      var promise = $scope.faktura.recountSums(
        $scope.RecordCtrl.related.getFullList()
      );
      promise.then(function (sums) {
        $scope.sumaFakturyDPH = sums.spolu;
        $scope.sumaFaktury =
          Math.round((sums.zaklad_d1 + sums.zaklad_d2 + sums.zaklad_d0) * 100) /
          100.0;
      });
      return promise;
    };
    $scope.recountSumsDelayed = function () {
      $timeout(function () {
        $scope.recountSums();
      });
    };
    var fakturaDataChanged = function () {
      FakturyTyp.restangularize($scope.faktura.typ_data);
      $scope.recountSums();
      if ($scope.faktura.id)
        $scope.prevadzky = _.filter(angular.copy(Profile.get("prevadzky")), {
          id: $scope.faktura.prevadzka,
        });
      else $scope.prevadzky = angular.copy(Profile.get("prevadzky"));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.faktura.prevadzka,
      });
      updateShowVAT();
    };
    var fakturaPreSave = function (data) {
      // vynulovat ceny v ZM, ak sa medzicasom zrusila mena
      if (parseFloat($scope.faktura.kurz)) {
        $scope.kurzChanged();
      }
      return data;
    };
    var fakturaPostSave = function (data) {
      // $scope.recountSums().then(function(newSums) {
      //   copyWeakSrc(newSums, data);
      // });
      Profile.parseObdobie(data.d_vyst);
      return data;
    };
    var fakturaValidate = function () {
      if ($scope.faktura.uctovanie) {
        var uctoError = "Rozdiel MD-D musí byť 0!";
        // fakturacia, kde nie je prebraty rozdiel z tabulky uctovania
        if (Profile.get("jednoducheUcto")) {
          $scope.uctovanie_suma = {
            rozdiel: 0,
          };
        } else {
          if (!angular.isDefined($scope.uctovanie_suma.rozdiel)) {
            $scope.uctovanie_suma.sumaMD = 0;
            $scope.uctovanie_suma.sumaD = 0;
            var fields = Faktura.getUctoFields();
            for (var i = 0; i < fields.length; i++) {
              if ($scope.faktura.uctovanie[fields[i] + "_md"]) {
                $scope.uctovanie_suma.sumaMD += parseFloat(
                  $scope.faktura[fields[i]]
                );
              }
              if ($scope.faktura.uctovanie[fields[i] + "_d"]) {
                $scope.uctovanie_suma.sumaD += parseFloat(
                  $scope.faktura[fields[i]]
                );
              }
            }
            $scope.uctovanie_suma.rozdiel =
              Math.round(
                ($scope.uctovanie_suma.sumaMD - $scope.uctovanie_suma.sumaD) *
                  100
              ) / 100.0;
            uctoError = "Rozdiel v zaúčtovaní MD-D musí byť 0!";
          }
        }
        if ($scope.uctovanie_suma.rozdiel !== 0) {
          alert(uctoError);
          return false;
        }
      }
      if (
        $scope.faktura.d_vyst &&
        !Profile.checkObdobie($scope.faktura.d_vyst)
      ) {
        if (
          !window.confirm(
            "Dátum nespadá do prednastaveného obdobia!\nPokračovať?"
          )
        ) {
          return false;
        }
      }
      if (!isEmpty($scope.faktura.rozuctovanie)) {
        if (!$scope.rozuctovanie.valid) {
          $scope.errors.rozuctovanie = true;
          alert("Chyba v rozúčtovaní!\nRozúčtovaná musí byť plná suma.");
          return false;
        } else {
          $scope.errors.rozuctovanie = false;
        }
      }
      return true;
    };

    $scope.RecordCtrl.main.init({
      endpoint: "faktury",
      model: "faktura",
      master: faktura,
      list: faktury,
      defaultItem: defaultFaktura,
      dataChanged: fakturaDataChanged,
      focusField: "firma",
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      validate: fakturaValidate,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "pohyb",
      lookup: "faktura",
      object: Pohyb,
      preSave: pohybPreSave,
      postSave: pohybPostSave,
      preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    $scope.setPrevadzka = function (prevadzka) {
      $scope.faktura.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
    };

    $scope.mainFormSubmitter = function ($event) {
      if ($event.which === 13 && !$event.shiftKey) {
        if (angular.equals($scope.newItem, defaultPohybItem)) {
          $scope.ok();
        } else {
          $scope.RecordCtrl.related.add($scope.newItem);
        }
      }
    };
    $scope.sumaFaktury = 0;
    $scope.sumaFakturyDPH = 0;

    $scope.activeCurrency =
      $scope.faktura.mena && $scope.faktura.primarna_zm
        ? $scope.faktura.mena_data.mena
        : Global.get("mena");

    $scope.toggleCurrency = function (item) {
      var kurz = parseFloat($scope.faktura.kurz);

      if ($scope.faktura.primarna_zm)
        $scope.newItem.cena_edit =
          Math.round((parseFloat($scope.newItem.cena_edit) / kurz) * 10000) /
          10000.0;
      else
        $scope.newItem.cena_edit =
          Math.round(parseFloat($scope.newItem.cena_edit) * kurz * 10000) /
          10000.0;

      if (item) {
        item.prepocitatKurzom($scope.faktura);
      } else {
        $("#add_cena_vydaj").focus();
      }
      $scope.faktura.primarna_zm = !$scope.faktura.primarna_zm;
      $scope.activeCurrency = $scope.faktura.primarna_zm
        ? $scope.faktura.mena_data.mena
        : Global.get("mena");

      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.faktura.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_vydaj;
        }
      });
    };
    $scope.kurzChanged = function () {
      var list = $scope.RecordCtrl.related.getFullList();
      $scope.faktura.prepocitatPohybKurzom(list);
      for (var i = 0; i < list.length; i++) {
        $scope.RecordCtrl.related.edit(list[i]);
      }
      // update also items which are currently being edited
      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.faktura.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_prijem;
        }
      });
    };

    $scope.inProgress = false;
    $scope.send = function () {
      if (!$scope.faktura.firma_data || !$scope.faktura.firma_data.email) {
        Dialog.alert("Firma nemá zadaný e-mail.");
        return;
      }
      if ($scope.RecordCtrl.main.validateAndSave()) {
        Outputs.sendMail(
          $scope.faktura.firma_data.email,
          Global.get("text_email_fa_subject", "Faktúra č. $DOKLAD").replace(
            "$DOKLAD",
            $scope.faktura.iddokd
          ),
          Global.get("text_email_fa"),
          $scope.faktura,
          "Odoslanie faktúry"
        ).then(
          function (data) {
            Global.set("text_email_fa", data.mailData.message);
            Global.set("text_email_fa_subject", data.mailMessage.subject);
            Mailbox.syncCreate(data.mailMessage);
          },
          function () {
            console.log("cancelled");
          }
        );
      }
    };
    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function (firma) {
      $scope.faktura.firma_data = firma;
      $scope.faktura.zlava = firma.zlava;
      if (firma.splatnost) {
        today = angular.copy(parseDate($scope.faktura.d_vyst));
        $scope.faktura.d_splat = getCurrentDate(
          new Date(today.setDate(today.getDate() + firma.splatnost))
        );
      }
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: faktura });
      });
    };

    $scope.getKartyWithPrices = function (query, page, parent) {
      return Karta.get(
        query,
        page,
        parent,
        { cennik: $scope.faktura.firma_data.cennik }
      );
    };

    var selectKarta = function (item, parent) {
      var into = parent || $scope.newItem;
      delete into.zlava;
      copyWeakSrc(
        _.pick(item, "kod", "nazov", "dph", "mj", "cena_sklad"),
        into
      );
      into.karta = item.id;
      if (!Profile.get("svb")) {
        into.cena_edit =
          _.isNull($scope.faktura.firma_data) ||
          !$scope.faktura.firma_data.vo_cena
            ? item.mo
            : item.vo;
      }

      var cennikID = $scope.faktura.firma_data.cennik;
      CennikZaznam.getPriceItemInCennikForKarta(cennikID, item.id).then(function (data) {
        if (data.length === 1 && cennikID) {
          var cennik_item = data.shift();
          into.cena_edit = cennik_item.mo;
          if (cennik_item.poznamka) {
            into.poznamka = cennik_item.poznamka;
          }
          if (cennik_item.nazov_alt) {
            into.nazov = cennik_item.nazov_alt;
            $("#pohyb_kod").val(cennik_item.nazov_alt);
          }
        }

        if (!parent) {
          Pohyb.updateSkladLabelsWithQty($scope, item);
          $timeout(function () {
            $("#pohyb_mv").focus();
          });
        }
      });
    };
    $scope.parseCenaSkladTooltip = function (item) {
      var tt = item.karta
        ? "Cena sklad: " + $filter("suma")(item.cena_sklad, 4)
        : "";
      if (parseFloat(item.cena_sklad)) {
        tt += "<br/>Marža: " + $scope.itemMarza(item) + " %";
      }
      return tt;
    };

    $scope.itemMarza = function (item) {
      return $filter("suma")(
        (parseFloat(item.cena_vydaj) / parseFloat(item.cena_sklad) - 1) * 100.0,
        2
      );
    };

    $scope.changedItem = function () {
      $timeout(function () {
        if (!$scope.newItem.karta_data) {
          Pohyb.updateSkladLabelsWithQty($scope);
        }
      });
    };
    $scope.selectedItem = function (item) {
      selectKarta(item, null);
    };
    $scope.selectedEditItem = function (item, parent) {
      selectKarta(item, parent);
    };
    var extendKartaFromParent = function (parent) {
      if (!parent) {
        return {};
      }
      return {
        nazov: parent.nazov,
        mo: parent.cena_edit,
        dph: parent.dph,
        mj: parent.mj,
        modph:
          parseFloat(parent.cena_edit) * ((parseInt(parent.dph) + 100) / 100.0),
      };
    };
    $scope.addKartaKod = function (kod, parent) {
      return Karta.add(kod, extendKartaFromParent(parent));
    };
    $scope.addKartaNazov = function (nazov) {
      return Karta.add(
        undefined,
        angular.extend(extendKartaFromParent($scope.newItem), { nazov: nazov })
      );
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.saveAndContinue = function (item) {
      $scope.RecordCtrl.related.add(item);
      $scope.RecordCtrl.main.validateAndSave();
    }

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function () {
      var prevadzka = _.find($scope.prevadzky, {
        id: $scope.faktura.prevadzka,
      });
      var modalInstance = $modal.open({
        templateUrl: "admin/settings/printSettings.html",
        controller: "PrintSettingsCtrl",
        backdrop: "static",
        windowClass: "printsettings-detail",
        resolve: {
          record: function () {
            return prevadzka;
          },
          prevadzky: function () {
            return $scope.prevadzky;
          },
        },
      });
      modalInstance.result.then(function (data) {
        var profilePrevadzka = _.find(Profile.get("prevadzky"), {
          id: data.prevadzka.id,
        });
        if (profilePrevadzka) {
          angular.copy(data.prevadzka, profilePrevadzka);
          $scope.prevadzky = Prevadzka.restangularize(
            angular.copy(Profile.get("prevadzky"))
          );
        }
      });
    };
  },
]);
