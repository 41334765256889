'use strict';

angular.module('casist.ucto')
  .controller('RozuctovaniePolozkaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'RozuctovaniePolozka', 'polozka', 'polozky', 'parent',
                function($scope, $modalInstance, $controller, Profile, RozuctovaniePolozka, polozka, polozky, parent) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: RozuctovaniePolozka
    });

    var defaultPolozka = {
    };

    $scope.parentItem = parent;

    $scope.RecordCtrl.main.init({
      // endpoint: 'rozuctovanie/rozuctovanie-',
      model: 'polozka',
      master: polozka,
      list: polozky,
      defaultItem: defaultPolozka,
      searchCtrl: null,
      preSave: function(obj) { obj.rok = 2014; return obj; },
      $modalInstance: $modalInstance
    });
    if (!isEmpty(polozka)) {
      $scope.polozka = RozuctovaniePolozka.copy(polozka);
    } else {
      $scope.polozka = defaultPolozka;
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: firma});
      });
    };

    $scope.ok = function () {
      $modalInstance.close($scope.polozka);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
