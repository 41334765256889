"use strict";

angular.module("casist.sklad").controller("PonukaDetailCtrl", [
  "$scope",
  "$modalInstance",
  "ponuka",
  "ponuky",
  "$timeout",
  "$modal",
  "Global",
  "$http",
  "$controller",
  "Profile",
  "SearchCtrl",
  "Ponuka",
  "Prevadzka",
  "Firma",
  "TypDPH",
  "Predkontacia",
  "$q",
  "Pohyb",
  "DokladTypy",
  "Doprava",
  "Mena",
  "Predajca",
  "PredajHlavicka",
  "PredajPata",
  "Ucet",
  "Outputs",
  "Dialog",
  "$filter",
  "Mailbox",
  "Karta",
  "PonukaTyp",
  "Doklad",
  "PonukaUcel",
  "CennikZaznam",
  "Sklad",
  "Notification",
  function (
    $scope,
    $modalInstance,
    ponuka,
    ponuky,
    $timeout,
    $modal,
    Global,
    $http,
    $controller,
    Profile,
    SearchCtrl,
    Ponuka,
    Prevadzka,
    Firma,
    TypDPH,
    Predkontacia,
    $q,
    Pohyb,
    DokladTypy,
    Doprava,
    Mena,
    Predajca,
    PredajHlavicka,
    PredajPata,
    Ucet,
    Outputs,
    Dialog,
    $filter,
    Mailbox,
    Karta,
    PonukaTyp,
    Doklad,
    PonukaUcel,
    CennikZaznam,
    Sklad,
    Notification
  ) {
    $scope.hasList = ponuky.length !== 0;
    $scope.jednoducheUctoClass = Profile.get("jednoducheUcto")
      ? "jednoduche-ucto"
      : "";

    $scope.Karta = Karta;
    $scope.Mena = Mena;
    $scope.Hlavicka = PredajHlavicka;
    $scope.PonukaUcel = PonukaUcel;
    $scope.skladChanged = function (item, parent) {
      if (!parent) {
        if (item) {
          $scope.newItem.sklad = parseInt(item);
        }
        $scope.newItem.sklad_data = _.find($scope.currentPrevadzka.sklady, {
          id: $scope.newItem.sklad,
        });
        _.extend(
          defaultPohybItem,
          _.pick($scope.newItem, "sklad", "sklad_data")
        );
      } else {
        parent.sklad_data = _.find($scope.currentPrevadzka.sklady, {
          id: parent.sklad,
        });
      }
    };

    $scope.getHlavicky = function (query) {
      return $scope.Hlavicka.get(
        query,
        $scope.ponuka.typ ? { typ: $scope.ponuka.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addHlavicka = function (hlavicka) {
      return $scope.Hlavicka.add(
        hlavicka,
        $scope.ponuka.typ ? { typ: $scope.ponuka.typ } : undefined
      );
    };
    $scope.Pata = PredajPata;
    $scope.getPaty = function (query) {
      return $scope.Pata.get(
        query,
        $scope.ponuka.typ ? { typ: $scope.ponuka.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addPata = function (pata) {
      return $scope.Pata.add(
        pata,
        $scope.ponuka.typ ? { typ: $scope.ponuka.typ } : undefined
      );
    };
    $scope.typChanged = function (typ, setDefault) {
      if (
        $scope.ponuka &&
        $scope.ponuka.ucel &&
        (!typ || $scope.ponuka.ucel_data.typ !== typ.id)
      ) {
        delete $scope.ponuka.ucel;
      }
      $scope.Hlavicka.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.ponuka.hlavicka) || !setDefault) {
          if (data.length) {
            $scope.ponuka.hlavicka = data[0].text;
          } else {
            $scope.ponuka.hlavicka = "";
          }
        }
        if (setDefault) {
          defaultPonuka.hlavicka = $scope.ponuka.hlavicka;
        }
      });
      $scope.Pata.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.ponuka.pata) || !setDefault) {
          if (data.length) {
            $scope.ponuka.pata = data[0].text;
          } else {
            $scope.ponuka.pata = "";
          }
        }
        if (setDefault) {
          defaultPonuka.pata = $scope.ponuka.pata;
        }
      });
    };
    $scope.Predajca = Predajca;
    $scope.Doprava = Doprava;
    $scope.Firma = Firma;
    $scope.PonukaTyp = PonukaTyp;
    $scope.Sklad = Sklad;

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Ponuka,
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      pohladavkyPohyb: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_pohyb",
      }),
      pohladavkyRozuctovanie: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_rozuct",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.pohladavkyPohyb)
        ? layouts.pohladavkyPohyb.value
        : true,
      false,
      angular.isDefined(layouts.pohladavkyRozuctovanie)
        ? layouts.pohladavkyRozuctovanie.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "pohladavky_" + (panel === 0 ? "pohyb" : "rozuct");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function () {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.pohladavkyRozuctovanie.value) {
        $scope.panels[2] = layouts.pohladavkyRozuctovanie.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31),
      typDPHMaxPlatnost = new Date(1970, 1, 1);
    var updateTypDPH = function (datum) {
      if (!Profile.get("platcadph")) {
        return;
      }
      TypDPH.getValid(datum, "1,0").then(function (data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        if (
          data.data.length &&
          (!$scope.ponuka.typ_dph ||
            !_.find(data.data, { id: $scope.ponuka.typ_dph }))
        ) {
          $scope.ponuka.typ_dph = data.data[0].id;
        } else if ($scope.ponuka.id) {
          $scope.ponuka.typ_dph = ponuka.typ_dph;
        }
        $timeout(function () {
          $scope.recountSums();
        }, 1000);
        if (data.data.length && !angular.isDefined(defaultPonuka.typ_dph)) {
          defaultPonuka.typ_dph = data.data[0].id;
        }
      });
    };
    $scope.help = {};
    $scope.helpClosed = function () {
      delete $scope.help.typdph;
    };
    $scope.displayHelpTypDPH = function () {
      var el = '<table class="table table-condensed">';
      for (var i = 0; i < $scope.typyDPH.length; i++) {
        el +=
          '<tr><td width="70">' +
          $scope.typyDPH[i].typ +
          "</td><td>" +
          $scope.typyDPH[i].popis +
          "</td></tr>";
      }
      el += "</table>";
      $scope.help.typdph = el;
    };
    $scope.$watch("ponuka.d_vyst", function (val, oldVal) {
      if (val) {
        var date = parseDate(val);
        if (
          !isNaN(date) &&
          (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)
        ) {
          updateTypDPH(val);
        }
      }
    });
    if (Profile.get("platcadph")) {
      $scope.$watch("ponuka.typ_dph", function (val) {
        if (angular.isDefined($scope.typyDPH)) {
          if (val) {
            $scope.ponuka.typdph_data = _.find($scope.typyDPH, {
              id: parseInt(val),
            });
            if (
              !$scope.ponuka.id &&
              !angular.isDefined(defaultPonuka.typdph_data)
            ) {
              defaultPonuka.typdph_data = $scope.ponuka.typdph_data;
            }
          } else {
            $scope.ponuka.typdph_data = undefined;
          }
        }
      });
    }
    $scope.setDodanie = function () {
      $scope.ponuka.d_zdp = $scope.ponuka.d_vyst;
      $scope.setUplatDPH();
    };
    $scope.setUplatDPH = function () {
      $scope.ponuka.uplat_dph = $scope.ponuka.d_zdp;
    };

    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    var today = new Date();
    var splat = new Date(
      today.setDate(today.getDate() + Global.get("splatnost_fa"))
    );

    if (
      !angular.isDefined(Global.get("ponuka_hlavicka")) ||
      !angular.isDefined(Global.get("ponuka_pata"))
    ) {
      $scope.typChanged(undefined, true);
    }

    var defaultPonuka = {
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get("lastPrevadzka").id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      hlavicka: Global.get("ponuka_hlavicka"),
      konecny_prijemca: null,
      pata: Global.get("ponuka_pata"),
      mena: null,
      sd1: Global.get("sd1"),
      sd2: Global.get("sd2"),
      d_splat: getCurrentDate(splat),
      rozuctovanie: [],
    };
    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.pohybColumns = [
      { width: 30 },
      { field: "kod", label: "pohyb.KOD", width: 120 },
      { field: "nazov", label: "pohyb.NAZOV" },
      { field: "sklad", label: "pohyb.SKLAD" },
      { field: "mv", label: "pohyb.MNOZSTVO", width: 90 },
      {
        field: "cena_vydaj",
        label: "pohyb.CENAMJ",
        width: 105,
        currencyAddon: "ponuka",
      },
      { field: "marza", label: "fakturacia.MARZAP", width: 65 },
      { field: "zlava", label: "fakturacia.ZLAVAP", width: 65 },
      { field: "mj", label: "pohyb.MJ", width: 50 },
      { field: "dph", label: "pohyb.DPH", width: 60 },
      { field: "poznamka", label: "fakturacia.POZNAMKA", width: 150 },
      { width: 60 },
    ];
    if (!Profile.get("platcadph")) {
      var omitted = ["dph"];
      $scope.pohybColumns = _.reject($scope.pohybColumns, function (obj) {
        return omitted.indexOf(obj.field) !== -1;
      });
    }
    $scope.pohyb = [];

    var updateShowVAT = function () {
      $scope.showVATfields = true;
      $scope.ponuka.dd = DokladTypy.PO.dd;
      defaultPonuka.dd = $scope.ponuka.dd;
    };

    if (!isEmpty(ponuka)) {
      $scope.ponuka = Ponuka.copy(ponuka);
      _.defaults($scope.ponuka, defaultPonuka);
      PonukaTyp.restangularize($scope.ponuka.typ_data);
      $scope.prevadzky = _.filter($scope.prevadzky, {
        id: $scope.ponuka.prevadzka,
      });
      if ($scope.prevadzky.length)
        $scope.currentPrevadzka = $scope.prevadzky[0];
      if (!ponuka.pohyb || $scope.ponuka.id) {
        $scope.ponuka.getList("pohyb").then(function (pohyb) {
          $scope.RecordCtrl.related.paginateList(pohyb, 20, $scope.sorter);

          $timeout(function () {
            $scope.recountSums();
          });
        });
      } else {
        $timeout(function () {
          for (var i = 0; i < ponuka.pohyb.length; i++) {
            $scope.RecordCtrl.related.add(ponuka.pohyb[i]);
          }
          delete ponuka.pohyb;
        });
      }
    } else {
      $scope.ponuka = Ponuka.restangularize(angular.copy(defaultPonuka));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.ponuka.prevadzka,
      });
      $timeout(function () {
        $scope.RecordCtrl.related.paginateList([], 20, $scope.sorter);
      });
    }
    $scope.newItem = Pohyb.restangularize({
      dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
    });
    updateShowVAT();
    $scope.skladChanged(
      $scope.currentPrevadzka.sklady.length
        ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
        : undefined
    );
    var defaultPohybItem = angular.copy($scope.newItem);

    var pohybPreSave = function (item, create) {
      if (!item.karta_data) {
        item.kod = null;
        if (item.karta) {
          item.nazov = item.karta;
          delete item.karta;
        }
      }
      if (angular.isDefined(item.cena_edit)) {
        if ($scope.ponuka.mena && $scope.ponuka.kurz) {
          item.prepocitatKurzom($scope.ponuka);
        } else {
          item.cena_vydaj = item.cena_edit;
          item.cena_zm = 0;
        }
        delete item.cena_edit;
      }
      $scope.skladChanged(item.sklad, item);

      return item;
    };
    var pohybPostSave = function (item, created) {
      $("#pohyb_kod").focus();
      $scope.recountSums();
      angular.extend($scope.newItem, {
        dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
      });
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
      Pohyb.updateSkladLabelsWithQty($scope);
      if (document.getElementById("pohyb_kod")) {
        document.getElementById("pohyb_kod").scrollIntoView();
      }
      return item;
    };
    var pohybPreEdit = function (item) {
      if ($scope.ponuka.primarna_zm) item.cena_edit = item.cena_zm;
      else item.cena_edit = item.cena_vydaj;
      return item;
    };
    $scope.recountSums = function () {
      $scope.sumaFakturyDPH = 0;
      $scope.sumaFaktury = 0;
      $scope.sumaNaklady = 0;
      $scope.sumaZisk = 0;
      $scope.sumaMarza = "!";
      var promise = $scope.ponuka.recountSums(
        $scope.RecordCtrl.related.getFullList()
      );
      promise.then(function (sums) {
        $scope.sumaFakturyDPH = sums.spolu || 0;
        $scope.sumaFaktury =
          Math.round((sums.zaklad_d1 + sums.zaklad_d2 + sums.zaklad_d0) * 100) /
            100.0 || 0;

        angular.forEach($scope.RecordCtrl.related.getFullList(), function (p) {
          $scope.sumaNaklady +=
            parseFloat(p.mv) * parseFloat(p.cena_sklad || 0);
        });
        $scope.sumaNaklady = Math.round($scope.sumaNaklady * 100) / 100.0;
        $scope.sumaZisk = ($scope.sumaFaktury || 0) - ($scope.sumaNaklady || 0);
        if ($scope.sumaNaklady) {
          $scope.sumaMarza =
            $filter("suma")(
              ($scope.sumaFaktury / $scope.sumaNaklady - 1) * 100.0,
              2
            ) + " %";
        } else {
          $scope.sumaMarza = "!";
        }
      });
      return promise;
    };
    $scope.recountSumsDelayed = function () {
      $timeout(function () {
        $scope.recountSums();
      });
    };
    var fakturaDataChanged = function () {
      PonukaTyp.restangularize($scope.ponuka.typ_data);
      $scope.recountSums();
      if ($scope.ponuka.id)
        $scope.prevadzky = _.filter(angular.copy(Profile.get("prevadzky")), {
          id: $scope.ponuka.prevadzka,
        });
      else $scope.prevadzky = angular.copy(Profile.get("prevadzky"));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.ponuka.prevadzka,
      });
      updateShowVAT();
    };
    var fakturaPreSave = function (data) {
      delete data.doklad;
      delete data.faktura;
      if ($scope.ponuka.d_vyst && !Profile.checkObdobie($scope.ponuka.d_vyst))
        alert("Dátum nespadá do prednastaveného obdobia!");
      // vynulovat ceny v ZM, ak sa medzicasom zrusila mena
      if (parseFloat($scope.ponuka.kurz)) {
        $scope.kurzChanged();
      }
      return data;
    };
    var fakturaPostSave = function (data) {
      // $scope.recountSums().then(function(newSums) {
      //   copyWeakSrc(newSums, data);
      // });
      Profile.parseObdobie(data.d_vyst);
      return data;
    };

    $scope.RecordCtrl.main.init({
      endpoint: "ponuky",
      model: "ponuka",
      master: ponuka,
      list: ponuky,
      defaultItem: defaultPonuka,
      dataChanged: fakturaDataChanged,
      focusField: function () {
        return $scope.ponuka.id ? "firma" : "typ";
      },
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "pohyb",
      lookup: "doklad",
      object: Pohyb,
      preSave: pohybPreSave,
      postSave: pohybPostSave,
      preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    $scope.setPrevadzka = function (prevadzka) {
      $scope.ponuka.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
    };

    $scope.mainFormSubmitter = function ($event) {
      if ($event.which === 13 && !$event.shiftKey) {
        if (angular.equals($scope.newItem, defaultPohybItem)) {
          $scope.ok();
        } else {
          $scope.RecordCtrl.related.add($scope.newItem);
        }
      }
    };
    $scope.sumaFaktury = 0;
    $scope.sumaFakturyDPH = 0;
    $scope.sumaNaklady = 0;
    $scope.sumaZisk = 0;
    $scope.sumaMarza = "!";

    $scope.activeCurrency =
      $scope.ponuka.mena && $scope.ponuka.primarna_zm
        ? $scope.ponuka.mena_data.mena
        : Global.get("mena");

    $scope.toggleCurrency = function (item) {
      var kurz = parseFloat($scope.ponuka.kurz);

      if ($scope.ponuka.primarna_zm)
        $scope.newItem.cena_edit =
          Math.round((parseFloat($scope.newItem.cena_edit) / kurz) * 10000) /
          10000.0;
      else
        $scope.newItem.cena_edit =
          Math.round(parseFloat($scope.newItem.cena_edit) * kurz * 10000) /
          10000.0;

      if (item) {
        item.prepocitatKurzom($scope.ponuka);
      } else {
        $("#add_cena_vydaj").focus();
      }
      $scope.ponuka.primarna_zm = !$scope.ponuka.primarna_zm;
      $scope.activeCurrency = $scope.ponuka.primarna_zm
        ? $scope.ponuka.mena_data.mena
        : Global.get("mena");

      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.ponuka.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_vydaj;
        }
      });
    };
    $scope.kurzChanged = function () {
      var list = $scope.RecordCtrl.related.getFullList();
      $scope.ponuka.prepocitatPohybKurzom(list);
      for (var i = 0; i < list.length; i++) {
        $scope.RecordCtrl.related.edit(list[i]);
      }
      // update also items which are currently being edited
      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.ponuka.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_prijem;
        }
      });
    };

    $scope.inProgress = false;
    $scope.send = function () {
      if (!$scope.ponuka.firma_data || !$scope.ponuka.firma_data.email) {
        Dialog.alert("Firma nemá zadaný e-mail.");
        return;
      }
      if ($scope.RecordCtrl.main.validateAndSave()) {
        Outputs.sendMail(
          $scope.ponuka.firma_data.email,
          Global.get("text_email_ponuka_subject", "Ponuka č. $DOKLAD").replace(
            "$DOKLAD",
            $scope.ponuka.iddokd
          ),
          Global.get("text_email_ponuka"),
          $scope.ponuka,
          "Odoslanie faktúry"
        ).then(
          function (data) {
            Global.set("text_email_ponuka", data.mailData.message);
            Global.set("text_email_ponuka_subject", data.mailMessage.subject);
            Mailbox.syncCreate(data.mailMessage);
          },
          function () {
            console.log("cancelled");
          }
        );
      }
    };
    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function (firma) {
      $scope.ponuka.firma_data = firma;
      $scope.ponuka.zlava = firma.zlava;
      if (firma.splatnost) {
        today = angular.copy(parseDate($scope.ponuka.d_vyst));
        $scope.ponuka.d_splat = getCurrentDate(
          new Date(today.setDate(today.getDate() + firma.splatnost))
        );
      }
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: ponuka });
      });
    };

    $scope.getKartyWithPrices = function (query, page, parent) {
      return Karta.get(
        query,
        page,
        parent,
        { cennik: $scope.ponuka.firma_data.cennik }
      );
    };

    var selectKarta = function (item, parent) {
      var into = parent || $scope.newItem;
      copyWeakSrc(
        _.pick(item, "kod", "nazov", "dph", "mj", "cena_sklad"),
        into
      );
      into.karta = item.id;
      if (!Profile.get("svb")) {
        into.cena_edit =
          _.isNull($scope.ponuka.firma_data) ||
          !$scope.ponuka.firma_data.vo_cena
            ? item.mo
            : item.vo;
      }

      var cennikID = $scope.ponuka.firma_data.cennik;
      CennikZaznam.getPriceItemInCennikForKarta(cennikID, item.id).then(function (data) {
        if (data.length === 1 && cennikID) {
          var cennik_item = data.shift();
          into.cena_edit = cennik_item.mo;
          if (cennik_item.poznamka) {
            into.poznamka = cennik_item.poznamka;
          }
          if (cennik_item.nazov_alt) {
            into.nazov = cennik_item.nazov_alt;
            $("#pohyb_kod").val(cennik_item.nazov_alt);
          }
        }

        if (!parent) {
          Pohyb.updateSkladLabelsWithQty($scope, item);
          $timeout(function () {
            $("#pohyb_mv").focus();
          });
        }
      });
    };
    $scope.parseCenaSkladTooltip = function (item) {
      var tt = item.karta
        ? "Cena sklad: " + $filter("suma")(item.cena_sklad, 4)
        : "";
      if (parseFloat(item.cena_sklad)) {
        tt += "<br/>Marža: " + $scope.itemMarza(item) + " %";
      }
      return tt;
    };

    $scope.itemMarza = function (item) {
      return $filter("suma")(
        (parseFloat(item.cena_vydaj) / parseFloat(item.cena_sklad) - 1) * 100.0,
        2
      );
    };

    $scope.selectedSklad = function (sklad) {
      $scope.ponuka.sklad_data = sklad;
    };

    $scope.changedItem = function () {
      $timeout(function () {
        if (!$scope.newItem.karta_data) {
          Pohyb.updateSkladLabelsWithQty($scope);
        }
      });
    };
    $scope.selectedItem = function (item) {
      selectKarta(item, null);
    };
    $scope.selectedEditItem = function (item, parent) {
      selectKarta(item, parent);
    };
    var extendKartaFromParent = function (parent) {
      if (!parent) {
        return {};
      }
      return {
        nazov: parent.nazov,
        mo: parent.cena_edit,
        dph: parent.dph,
        mj: parent.mj,
        modph:
          parseFloat(parent.cena_edit) * ((parseInt(parent.dph) + 100) / 100.0),
      };
    };
    $scope.addKartaKod = function (kod, parent) {
      return Karta.add(kod, extendKartaFromParent(parent));
    };
    $scope.addKartaNazov = function (nazov) {
      return Karta.add(
        undefined,
        angular.extend(extendKartaFromParent($scope.newItem), { nazov: nazov })
      );
    };

    $scope.getParovanie = function (query, pageQuery) {
      if (/^(VF|DF)[0-9]+$/.test(query)) {
        query = query.replace("VF", "").replace("DF", "");
      }
      var original = $scope.ponuka.id
        ? _.find(angular.isFunction(ponuky) ? ponuky() : ponuky, {
            id: parseInt($scope.ponuka.id),
          })
        : undefined;
      return Ponuka.parovanie().getList(
        angular.extend(
          {
            firma: $scope.ponuka.firma,
            search: query,
            include: original ? original.parovanie : undefined,
          },
          pageQuery
        )
      );
    };
    $scope.editParovaciDoklad = function (doklad) {
      Doklad.editRecord(doklad);
    };

    $scope.getUcel = function (query) {
      return PonukaUcel.get(
        query,
        $scope.ponuka.typ ? { typ: $scope.ponuka.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addUcel = function (text) {
      return PonukaUcel.add(text, { typ: $scope.ponuka.typ });
    };

    $scope.print = function (endpoint, id) {
      Dialog.selection("Vyberte zostavu:", "Výber zostavy", [
        { value: "print", text: "Ponuka" },
        { value: "print-zaloha", text: "Zálohová faktúra" },
      ]).then(function (data) {
        $scope.RecordCtrl.main.print(endpoint, id, data);
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.saveAndContinue = function (item) {
      $scope.RecordCtrl.related.add(item);
      $scope.RecordCtrl.main.validateAndSave();
    }

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.prevodka = function () {
      if (!$scope.ponuka.sklad || !$scope.ponuka.sklad_data) {
        Notification.add('Sklad pre prevodku nebol zvolený.', 'danger', 10);
        return;
      }

      if (!$scope.RecordCtrl.main.validateAndSave()) {
        Notification.add('Položky sa nepodarilo uložiť.', 'danger', 10);
        return;
      }

      var pohyby = $scope.RecordCtrl.related.getFullList();
      for (var i = 0; i < pohyby.length; i++) {
        var newPohyb = angular.copy(pohyby[i]);

        newPohyb.sklad = $scope.ponuka.sklad_data.id;
        newPohyb.sklad_data = $scope.ponuka.sklad_data;
        newPohyb.mv = pohyby[i].mv * (-1);

        setTimeout(function (newPohyb) {
            $scope.RecordCtrl.related.add(newPohyb);
        }, 1000, newPohyb);
      }
    };

    $scope.zmenaSkladu = function () {
      if (!$scope.ponuka.sklad || !$scope.ponuka.sklad_data) {
        Notification.add('Sklad pre prevodku nebol zvolený.', 'danger', 10);
        return;
      }

      if (!$scope.RecordCtrl.main.validateAndSave()) {
        Notification.add('Položky sa nepodarilo uložiť.', 'danger', 10);
        return;
      }

      var pohyby = $scope.RecordCtrl.related.getFullList();
      for (var i = 0; i < pohyby.length; i++) {
        pohyby[i].sklad = $scope.ponuka.sklad_data.id;
        pohyby[i].sklad_data = $scope.ponuka.sklad_data;

        setTimeout(function (pohyb) {
            $scope.RecordCtrl.related.edit(pohyb);
        }, 1000, pohyby[i]);
      }
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };

    $scope.editPrintSettings = function () {
      var prevadzka = _.find($scope.prevadzky, { id: $scope.ponuka.prevadzka });
      var modalInstance = $modal.open({
        templateUrl: "admin/settings/printSettings.html",
        controller: "PrintSettingsCtrl",
        backdrop: "static",
        windowClass: "printsettings-detail",
        resolve: {
          record: function () {
            return prevadzka;
          },
          prevadzky: function () {
            return $scope.prevadzky;
          },
        },
      });
      modalInstance.result.then(function (data) {
        var profilePrevadzka = _.find(Profile.get("prevadzky"), {
          id: data.prevadzka.id,
        });
        if (profilePrevadzka) {
          angular.copy(data.prevadzka, profilePrevadzka);
        }
      });
    };
  },
]);
