"use strict";

angular.module("casist.ucto").controller("HlavnaKnihaCtrl", [
  "$scope",
  "UctOsnova",
  "$controller",
  "Profile",
  "$translate",
  "Ucet",
  "Outputs",
  "Socket",
  "TabService",
  "Notification",
  "$timeout",
  "Dialog",
  "$modal",
  function (
    $scope,
    UctOsnova,
    $controller,
    Profile,
    $translate,
    Ucet,
    Outputs,
    Socket,
    TabService,
    Notification,
    $timeout,
    Dialog,
    $modal
  ) {
    $scope.slideAnimation = true;

    var lastLoadedData = undefined;
    var mesiac = new Date().getMonth() + 1;
    var loadData = function (data) {
      lastLoadedData = data;
      $scope.hlavnakniha = [];
      var suma_analytika = {
        psmd: 0,
        psd: 0,
        ksmd: 0,
        ksd: 0,
        kumulativmd: 0,
        kumulativd: 0,
        mesiacmd: 0,
        mesiacd: 0,
        ksrozdiel: 0,
      };
      var suma_syntetika = angular.copy(suma_analytika);
      $scope.suma_04 = angular.copy(suma_analytika);
      $scope.suma_56 = angular.copy(suma_analytika);
      var syntetika;

      for (var i = 0; i < data.length; i++) {
        data[i].psmd = parseFloat(data[i].psmd);
        data[i].psd = parseFloat(data[i].psd);
        data[i].kumulativmd = data[i].psmd;
        data[i].kumulativd = data[i].psd;
        for (var j = 1; j < mesiac; j++) {
          data[i].kumulativmd += parseFloat(
            data[i]["m" + ("0" + j).slice(-2) + "md"]
          );
          data[i].kumulativd += parseFloat(
            data[i]["m" + ("0" + j).slice(-2) + "d"]
          );
        }
        data[i].mesiacmd = parseFloat(
          data[i]["m" + ("0" + mesiac).slice(-2) + "md"]
        );
        data[i].mesiacd = parseFloat(
          data[i]["m" + ("0" + mesiac).slice(-2) + "d"]
        );
        data[i].ksmd = data[i].mesiacmd + data[i].kumulativmd;
        data[i].ksd = data[i].mesiacd + data[i].kumulativd;
        data[i].ksrozdiel = data[i].ksmd - data[i].ksd;

        $scope.hlavnakniha.push(angular.copy(data[i]));
        // medzisumy
        angular.forEach(suma_analytika, function (value, key) {
          suma_analytika[key] += data[i][key];
          suma_syntetika[key] += data[i][key];
        });
        syntetika = data[i].ucet_name.substring(0, 1);
        switch (syntetika) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
            angular.forEach(suma_analytika, function (value, key) {
              $scope.suma_04[key] += data[i][key];
            });
            break;
          case "5":
          case "6":
            angular.forEach(suma_analytika, function (value, key) {
              $scope.suma_56[key] += data[i][key];
            });
            break;
          default:
            break;
        }
        if (syntetika !== "7") {
          if (
            !data[i + 1] ||
            data[i + 1].ucet_name.substring(0, 3) !==
              data[i].ucet_name.substring(0, 3)
          ) {
            $scope.hlavnakniha.push({
              suma_analytika: true,
              ucet_name: data[i].ucet_name.substring(0, 3) + "*",
              psmd: suma_analytika.psmd,
              psd: suma_analytika.psd,
              ksmd: suma_analytika.ksmd,
              ksd: suma_analytika.ksd,
              kumulativmd: suma_analytika.kumulativmd,
              kumulativd: suma_analytika.kumulativd,
              mesiacmd: suma_analytika.mesiacmd,
              mesiacd: suma_analytika.mesiacd,
              ksrozdiel: suma_analytika.ksrozdiel,
            });
            angular.forEach(suma_analytika, function (value, key) {
              suma_analytika[key] = 0;
            });
          }
          if (
            !data[i + 1] ||
            data[i + 1].ucet_name.substring(0, 1) !==
              data[i].ucet_name.substring(0, 1)
          ) {
            $scope.hlavnakniha.push({
              suma_syntetika: true,
              ucet_name: data[i].ucet_name.substring(0, 1) + "*",
              psmd: suma_syntetika.psmd,
              psd: suma_syntetika.psd,
              ksmd: suma_syntetika.ksmd,
              ksd: suma_syntetika.ksd,
              kumulativmd: suma_syntetika.kumulativmd,
              kumulativd: suma_syntetika.kumulativd,
              mesiacmd: suma_syntetika.mesiacmd,
              mesiacd: suma_syntetika.mesiacd,
              ksrozdiel: suma_syntetika.ksrozdiel,
            });
            angular.forEach(suma_analytika, function (value, key) {
              suma_syntetika[key] = 0;
            });
          }
        }
      }
      $scope.suma_04_zostatok = angular.copy($scope.suma_04);
      $scope.suma_56_zostatok = angular.copy($scope.suma_56);
      var zostatky = [$scope.suma_04_zostatok, $scope.suma_56_zostatok];
      for (var i in zostatky) {
        if (zostatky[i].psmd > zostatky[i].psd) {
          zostatky[i].psmd = zostatky[i].psmd - zostatky[i].psd;
          zostatky[i].psd = 0;
        } else {
          zostatky[i].psd = zostatky[i].psd - zostatky[i].psmd;
          zostatky[i].psmd = 0;
        }
        if (zostatky[i].ksmd > zostatky[i].ksd) {
          zostatky[i].ksmd = zostatky[i].ksmd - zostatky[i].ksd;
          zostatky[i].ksd = 0;
        } else {
          zostatky[i].ksd = zostatky[i].ksd - zostatky[i].ksmd;
          zostatky[i].ksmd = 0;
        }
        if (zostatky[i].kumulativmd > zostatky[i].kumulativd) {
          zostatky[i].kumulativmd =
            zostatky[i].kumulativmd - zostatky[i].kumulativd;
          zostatky[i].kumulativd = 0;
        } else {
          zostatky[i].kumulativd =
            zostatky[i].kumulativd - zostatky[i].kumulativmd;
          zostatky[i].kumulativmd = 0;
        }
        if (zostatky[i].mesiacmd > zostatky[i].mesiacd) {
          zostatky[i].mesiacmd = zostatky[i].mesiacmd - zostatky[i].mesiacd;
          zostatky[i].mesiacd = 0;
        } else {
          zostatky[i].mesiacd = zostatky[i].mesiacd - zostatky[i].mesiacmd;
          zostatky[i].mesiacmd = 0;
        }
      }
      $scope.SearchCtrl.broadcastRefresh("hlavnakniha");
    };
    $scope.zobrazeniaChanged = function (value) {
      var layout = _.find(Profile.get("layouts"), { layout_id: "hlavnakniha" });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: "hlavnakniha",
          value: value.id,
        });
      } else {
        layout.value = value.id;
      }
    };
    var updateTableTranslations = function (filter) {
      mesiac = filter.mesiac || new Date().getMonth() + 1;
      _.find($scope.hlavnaknihaTable, {
        field: "ps",
      }).translationData.rok = $scope.SearchCtrl.getSelectionValue("rok");
      _.find($scope.hlavnaknihaTable, {
        field: "mesiac",
      }).translationData.mesiac = $translate.instant(
        "mesiace." + monthToName(mesiac)
      );
      $scope.hlavnaknihaTable = angular.copy($scope.hlavnaknihaTable); // to re-render the labels
    };
    var filterChanged = function (filter) {
      var promise = UctOsnova.objects().getList(filter);
      updateTableTranslations(filter);

      promise.then(function (data) {
        loadData(data);
        TabService.registerScope("hlavnakniha", $scope);
      });
      return promise;
    };

    var unregisterAuthObservers = [];

    // unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    //   loadPrevadzky();
    // }));
    unregisterAuthObservers.push(
      Profile.addObserver("obdobie", function (obdobie) {
        $scope.SearchCtrl.filterSelection(
          "rok",
          Profile.get("obdobie", new Date().getFullYear())
        );
      })
    );
    $scope.SearchCtrl = $controller("SearchCtrl");

    var setObdobia = function () {
      $scope.obdobia = Profile.get("obdobia");
      if ($scope.obdobia && $scope.obdobia.length) {
        $scope.obdobie =
          Profile.get("obdobie") &&
          _.find($scope.obdobia, { rok: Profile.get("obdobie") })
            ? { rok: Profile.get("obdobie") }
            : $scope.obdobia[0];
      }
    };
    Profile.addObserver("obdobia", setObdobia);
    setObdobia();
    $scope.mesiace = [
      { id: 1, mesiac: "Január" },
      { id: 2, mesiac: "Február" },
      { id: 3, mesiac: "Marec" },
      { id: 4, mesiac: "Apríl" },
      { id: 5, mesiac: "Máj" },
      { id: 6, mesiac: "Jún" },
      { id: 7, mesiac: "Júl" },
      { id: 8, mesiac: "August" },
      { id: 9, mesiac: "September" },
      { id: 10, mesiac: "Október" },
      { id: 11, mesiac: "November" },
      { id: 12, mesiac: "December" },
    ];

    $scope.hlavnaknihaTable = [
      { width: 30, rowspan: 2 },
      {
        field: "ucet.ucet",
        label: "ucty.UCET",
        rowspan: 2,
        width: 100,
        filterCollapse: "right",
      },
      { field: "ucet.nazov", label: "ucty.NAZOV", rowspan: 2, sortable: false },
      {
        field: "ps",
        label: "uctosnova.PS",
        translationData: { rok: "" },
        colspan: 2,
        class: "text-center",
        searchable: false,
        sortable: false,
        sum: true,
      },
      {
        field: "kumulativ",
        label: "uctosnova.KUMULATIV",
        colspan: 2,
        class: "text-center",
        searchable: false,
        sortable: false,
        sum: true,
      },
      {
        field: "mesiac",
        label: "uctosnova.OBRAT",
        colspan: 2,
        class: "text-center",
        translationData: { mesiac: "" },
        searchable: false,
        sortable: false,
        sum: true,
      },
      {
        field: "ks",
        label: "uctosnova.KSMD",
        colspan: 3,
        class: "text-center",
        searchable: false,
        sortable: false,
        sum: true,
      },
    ];
    $scope.SearchCtrl.init({
      model: UctOsnova,
      onChange: filterChanged,
      pagination: false,
      columnsDef: $scope.hlavnaknihaTable,
      sort: { sort: "ucet.ucet", reverse: false },
      scope: $scope,
    });

    var layout = _.find(Profile.get("layouts"), { layout_id: "hlavnakniha" });
    $scope.SearchCtrl.filterSelection(
      "zobrazenie",
      layout ? layout.value : 1,
      false
    );
    $scope.SearchCtrl.filterSelection("rok", $scope.obdobie.rok, false);
    $scope.SearchCtrl.filterSelection("mesiac", new Date().getMonth() + 1);

    $scope.editUcet = function (ucet) {
      UctOsnova.edit(ucet).then(function (data) {
        angular.copy(data, ucet);
        filterChanged($scope.SearchCtrl.getQuery());
      });
    };

    $scope.typyZobrazenia = [
      { id: 1, zobrazenie: "Hlavná kniha" },
      { id: 2, zobrazenie: "Hospodársky výsledok" },
    ];

    $scope.printList = function () {
      Outputs.openPrintUrl("hlavnakniha", null, $scope.SearchCtrl.getQuery());
    };
    $scope.printListPodrobne = function () {
      Outputs.openPrintUrl(
        "hlavnakniha",
        null,
        $scope.SearchCtrl.getQuery(),
        "print-podrobne"
      );
    };
    $scope.prepocetProgress = false;
    $scope.prepocet = function () {
      $scope.prepocetProgress = true;
      UctOsnova.prepocetHlavnaKniha($scope.SearchCtrl.getQuery()).then(
        function (data) {
          $scope.prepocetProgress = false;
          loadData(data);
          Notification.add(
            "Prepočet hlavnej knihy prebehol úspešne.",
            "success",
            5
          );
        },
        function () {
          $scope.prepocetProgress = false;
          Notification.add(
            "Nastala chyba pri prepočte hlavnej knihy.",
            "danger",
            5
          );
        }
      );
    };
    $scope.uzavierka = function () {
      var nextYear = $scope.SearchCtrl.getSelectionValue("rok") + 1;
      Dialog.confirm(
        "Uzávierka roku skopíruje konečné stavy účtov do počiatočných stavov v roku " +
          nextYear +
          " a uzamkne rok " +
          (nextYear - 1) +
          ".<br />Naozaj chcete vykonať uzávierku?",
        "Uzávierka roku"
      ).then(function () {
        $scope.prepocetProgress = true;
        UctOsnova.uzavierka($scope.SearchCtrl.getQuery()).then(
          function (data) {
            $scope.prepocetProgress = false;
            Profile.parseObdobie(nextYear + "-01-01");
            Notification.add("Uzávierka roku prebehla úspešne.", "success", 5);
            // loadData(data);
            // $scope.SearchCtrl.filterSelection('rok', nextYear, false);
            // updateTableTranslations($scope.SearchCtrl.getQuery());
            Profile.set("obdobie", nextYear);
          },
          function () {
            $scope.prepocetProgress = false;
            Notification.add("Nastala chyba pri uzávierke roku.", "danger", 5);
          }
        );
      });
    };
    $scope.vykazy = [
      { id: "uzmikro", vykaz: "Účtovná závierka mikro" },
      { id: "uz", vykaz: "Účtovná závierka" },
    ];
    $scope.vykazSelected = function (value) {
      $modal.open({
        templateUrl: "ucto/hlavnakniha/uzavierka/uzavierka.html",
        controller: "UctovnaZavierkaCtrl",
        backdrop: "static",
        windowClass: "detail-sm",
        resolve: {
          query: function () {
            return $scope.SearchCtrl.getQuery();
          },
          typ: function () {
            return value.id;
          },
        },
      });
    };

    var vymazNulovy = function (data, ucet) {
      var isNull =
        parseFloat(ucet["psmd"]) === 0 && parseFloat(ucet["psd"]) === 0;
      if (isNull) {
        for (var j = 1; j <= 12; j++) {
          if (
            parseFloat(ucet["m" + ("0" + j).slice(-2) + "md"]) !== 0 ||
            parseFloat(ucet["m" + ("0" + j).slice(-2) + "d"]) !== 0
          ) {
            isNull = false;
            break;
          }
        }
      }
      if (isNull) {
        data.splice(data.indexOf(ucet), 1);
      }
    };

    var priuctuj = function (data, record) {
      var changedSomething = 0;
      var recordDate = parseDate(record.d_zdp);
      if (recordDate.getFullYear() !== $scope.obdobie.rok) {
        return changedSomething;
      }
      var spl, field, typ, ucet, ucetField;
      angular.forEach(record.uctovanie, function (value, key) {
        if (changedSomething === 2) {
          return;
        }
        if (value) {
          changedSomething = 1;
          spl = key.split("_");
          typ = spl[spl.length - 1];
          spl.splice(spl.length - 1);
          field = spl.join("_");
          if (typ !== "popis") {
            ucet = _.find(data, { id: value });
            if (!ucet) {
              changedSomething = 2;
              return;
            }
            ucetField =
              "m" + ("0" + (recordDate.getMonth() + 1)).slice(-2) + typ;
            ucet[ucetField] =
              parseFloat(ucet[ucetField]) + parseFloat(record[field]);
            vymazNulovy(data, ucet);
          }
        }
      });
      return changedSomething;
    };
    var oductuj = function (data, record) {
      var changedSomething = 0;
      var recordDate = parseDate(record.d_zdp);
      if (recordDate.getFullYear() !== $scope.obdobie.rok) {
        return changedSomething;
      }
      var spl, field, typ, ucet, ucetField;
      angular.forEach(record.uctovanie, function (value, key) {
        if (changedSomething === 2) {
          return;
        }
        if (value) {
          changedSomething = 1;
          spl = key.split("_");
          typ = spl[spl.length - 1];
          spl.splice(spl.length - 1);
          field = spl.join("_");
          if (typ !== "popis") {
            ucet = _.find(data, { id: value });
            if (!ucet) {
              changedSomething = 2;
              return;
            }
            ucetField =
              "m" + ("0" + (recordDate.getMonth() + 1)).slice(-2) + typ;
            ucet[ucetField] =
              parseFloat(ucet[ucetField]) - parseFloat(record[field]);
            vymazNulovy(data, ucet);
          }
        }
      });
      return changedSomething;
    };

    var events = [];
    var createHandler = function (data, observer) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      if (!isEmpty(data.uctovanie)) {
        var newData = angular.copy(lastLoadedData);

        var changed = priuctuj(newData, data);

        if (changed === 2) {
          filterChanged($scope.SearchCtrl.getQuery());
          return;
        }

        if (changed) {
          loadData(newData);
        }
      }
    };
    var changeHandler = function (data, observer) {
      if (
        angular.isDefined(data.obj.agenda) &&
        Profile.get("agenda").id !== data.obj.agenda
      ) {
        return;
      }
      if (!objEquals(data.original, data.obj)) {
        var newData = angular.copy(lastLoadedData);

        var changedOld = oductuj(newData, data.original);
        var changedNew = priuctuj(newData, data.obj);

        if (changedOld === 2 || changedNew === 2) {
          filterChanged($scope.SearchCtrl.getQuery());
          return;
        }

        if (changedOld || changedNew) {
          loadData(newData);
        }
      }
    };
    var removeHandler = function (data) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      if (!isEmpty(data.uctovanie)) {
        var newData = angular.copy(lastLoadedData);

        if (oductuj(newData, data)) {
          loadData(newData);
        }
      }
    };
    var endpoints = ["faktury", "zavazky", "pokladna", "banka", "interne"];

    for (var i in endpoints) {
      events.push(
        Socket.on(endpoints[i] + "Created", function (data) {
          createHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Changed", function (data) {
          changeHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Removed", function (data) {
          removeHandler(data);
        })
      );
    }
    events.push(
      $scope.$on("casist:dataChanged", function (event, data) {
        if (endpoints.indexOf(data.type) === -1) {
          return;
        }
        switch (data.operation) {
          case 1:
            createHandler(data.payload, false);
            break;
          case 2:
            changeHandler(data.payload, false);
            break;
          case 3:
            removeHandler(data.payload);
            break;
          default:
            break;
        }
      })
    );

    $scope.openPohyb = function (ucet) {
      TabService.bus.send("uctdennik", "hlavnakniha", { ucet__ucet: ucet });
      TabService.openTab("uctdennik");
    };

    $scope.$on("$destroy", function () {
      for (var i in unregisterAuthObservers) {
        unregisterAuthObservers[i]();
      }
      for (var i in events) {
        events[i]();
      }
    });
  },
]);
