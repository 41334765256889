'use strict';

angular.module('casist.widgets')
  .directive('tableSums', ['$compile', '$timeout', '$parse', function ($compile, $timeout, $parse) {
    return {
      template: '<tfoot></tfoot>',
      restrict: 'EA',
      replace: true,
      scope: {
        tableSums: '=',
        data: '=',
        view: '=',
        search: '=search',
        labels: '=',
        classes: '='
      },
      link: function postLink(scope, element, attrs) {
        var cols = '';

        var sumParser = angular.isDefined(attrs.sumsParser) ? function(obj, key) {
          return $parse(attrs.sumsParser)(scope.$parent, {obj: obj, key: key});
        } : function(obj, key) {
          return parseFloat(obj[key]);
        };
        var calculatedFields = [];
        var firstSumField = -1, lastSumField = 0;
        scope.sums = [];

        var computeCalculatedSums = function() {
          for (var i in calculatedFields) {
            for (var j = 0; j < scope.sums.length; j++) {
              scope.sums[j][calculatedFields[i].field] = calculatedFields[i].sum(scope.sums[j]);
            }
          }
        };

        var renderSumline = function(val) {
          if (!scope.tableSums) {
            return;
          }
          var table = angular.isDefined(scope.view) ? scope.tableSums[scope.view || 0] : scope.tableSums;
          if (!table) {
            return;
          }
          var f;
          cols = '<tr ng-repeat="sum in sums" class="'+(angular.isDefined(scope.classes) ? '{{classes[$index]}}' : 'warning')+'">';
          var firstColspan = 0;
          firstSumField = -1, lastSumField = 0;
          var hiddenCount = 0;

          for (var i = 0; i < table.length; i++) {
            if (table[i].sum) {
              lastSumField = i;
            }
            if (table[i].hidden) {
              hiddenCount++;
            }
          }
          var lastColsNum = (table.length-hiddenCount-lastSumField)-1;
          for (var i = 0; i < table.length; i++) {
            f = table[i];
            if (f.hidden) {
              continue;
            }
            firstColspan += f.colspan || 1;
            if (f.sum) {
              if (firstSumField === -1) {
                cols += '<td colspan="'+(firstColspan-1)+'" class="text-bold">'+(angular.isDefined(scope.labels) ? '{{labels[$index]}}:' : '&nbsp;')+'</td>';
                firstSumField = i;
              }
              cols += '<td class="text-right" ng-bind="sum.'+f.field+' | suma:2"></td>';
              if (angular.isFunction(f.sum)) {
                calculatedFields.push(f);
              }
            } else if (firstSumField !== -1 && i < lastSumField) {
              cols += '<td>&nbsp;</td>';
            }
          }
          if (lastColsNum > 0) {
            cols += '<td colspan="'+lastColsNum+'">&nbsp;</td>';
          }
          cols += '</tr>';
          const $cols = $compile(angular.element(cols))(scope);
          element.empty();
          element.append($cols);
        };
        scope.$watch('tableSums', renderSumline);
        scope.$watch('view', renderSumline);

        scope.$watch('data', function(data) {
          // ak sa zmena urobila len kvoli refreshu (bind-once)
          if (data.refreshing) {
            return;
          }
          if (data && angular.isDefined(data.sumline)) {
            var sumy = angular.isArray(data.sumline) ? data.sumline : [data.sumline];
            for (var j = 0; j < sumy.length; j++) {
              scope.sums[j] = {};
              angular.forEach(sumy[j], function(value, key) {
                if (!angular.isFunction(value)) {
                  if (!value) {
                    value = 0;
                  }
                  scope.sums[j][key] = value;
                }
              });
            }
            scope.sums.splice(sumy.length, scope.sums.length-sumy.length);
            computeCalculatedSums();
          } else {
            scope.sums.splice(0, scope.sums.length);
          }
        });

        var sumsChanged = function(add, subtract) {
          if (!add && !subtract) {
            scope.search.refresh();
            return;
          }
          if (add) {
            for (var j = 0; j < scope.sums.length; j++) {
              angular.forEach(scope.sums[j], function(value, key) {
                if (angular.isDefined(add[key])) {
                  scope.sums[j][key] = parseFloat(scope.sums[j][key])+sumParser(add, key);
                }
              });
            }
          }
          if (subtract) {
            for (var j = 0; j < scope.sums.length; j++) {
              angular.forEach(scope.sums[j], function(value, key) {
                if (angular.isDefined(subtract[key])) {
                  scope.sums[j][key] = parseFloat(scope.sums[j][key])-sumParser(subtract, key);
                }
              });
            }
          }
          computeCalculatedSums();
        };
        scope.search.addSumsObserver(sumsChanged);
      }
    }
  }]);
