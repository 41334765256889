'use strict';

angular.module('casist.ucto')
  .controller('UctOsnovaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'Ucet', 'UctOsnova', 'SearchCtrl', 'ucet', 'ucty',
                function($scope, $modalInstance, $controller, Profile, Ucet, UctOsnova, SearchCtrl, ucet, ucty) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: UctOsnova
    });

    var defaultUcet = {
      ucet_data: {}
    };

    $scope.operacie = [
      {id: 1, operacia: '+'},
      {id: -1, operacia: '-'}
    ];

    $scope.RecordCtrl.main.init({
      endpoint: 'uctosnova',
      model: 'ucet',
      master: ucet,
      focusField: ['ucet_name', 'ucet_nazov'],
      list: ucty,
      defaultItem: defaultUcet,
      preSave: function(obj) {
        if (!obj.rok) {
          obj.rok = new Date().getFullYear();
        }
        obj.ucet_data = {
          ucet: obj.ucet_name,
          nazov: obj.ucet_nazov
        }
        return obj;
      },
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });
    if (!isEmpty(ucet)) {
      $scope.ucet = UctOsnova.copy(ucet);
      _.defaults($scope.ucet, defaultUcet);
    } else {
      $scope.ucet = angular.copy(defaultUcet);
    }

    $scope.doplnSyntetiku = function() {
      UctOsnova.objects().getList({ucet__ucet: $scope.ucet.ucet_name.substring(0, 3), ordering: 'ucet__ucet,-rok'}).then(function(data) {
        if (data.length && (data[0].rsa || data[0].rsp || data[0].rv || data[0].rdp)) {
          angular.extend($scope.ucet, _.pick(data[0], ['rsa', 'rsp', 'rv', 'rdp']));
        }
      });
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: ucet});
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
