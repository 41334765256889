'use strict';

angular.module('casist.auth')
  .controller('PasswordResetCtrl', ['$scope', '$location', '$http', 'Notification', '$window', function ($scope, $location, $http, Notification, $window) {
    $scope.errors = {};

    var search = $location.search();

    $scope.resetdata = {
      token: search.token,
      username: search.email
    };

    $scope.sendReset = function() {
      $scope.errors = {};

      if (!$scope.resetdata.username) {
        $scope.errors['username'] = ["E-mail nie je zadaný!"];
      }
      if ($scope.resetdata.token) {
        if ($scope.resetdata.password !== $scope.resetdata.password_check) {
          $scope.errors['password_check'] = ["Heslá sa nerovnajú!"];
        } else if ($scope.resetdata.password.length < 8) {
          $scope.errors['password'] = ["Heslo musí mať aspoň 8 znakov!"];
        }
      }
      if (isEmpty($scope.errors)) {
        $scope.inProgress = true;
        var data = angular.copy($scope.resetdata);
        $http.post(constants.authServer+'settings/users/reset-password', data).then(function(_response) {
          Notification.add($scope.resetdata.token ? 'Heslo bolo úspešne nastavené.' : 'E-mail s odkazom na reset hesla bol úspešne odoslaný.', 'success', 5);
          $scope.inProgress = false;
          $location.url("/login");
        }, function(error) {
          Notification.add(($scope.resetdata.token ? 'Nastala chyba pri nastavení hesla. ' : 'Pri odosielaní e-mailu nastala chyba. ')+(error.error ? error.error : ''), 'danger', 5);
          $scope.inProgress = false;
        });
      } else {
        $scope.$broadcast('errorsRefresh');
      }
    };
  }]);
