'use strict';

angular.module('casist.admin')
  .factory('Permission', ['Restangular', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, BaseModel, BaseCollectionModel, Profile) {
    Restangular.extendModel('permissions', function(model) {
      return angular.extend(model, BaseModel);
    });
    return angular.extend(BaseCollectionModel.object('settings/permissions'), {
      parseUserPermissions: function(perms, user_data) {
        Profile.clearTplCache();
        var permissions = {};
        for (var i in perms) {
          var parsed = perms[i].codename.split('_');
          if (!angular.isDefined(permissions[parsed[1]])) {
            permissions[parsed[1]] = {};
          }
          permissions[parsed[1]][parsed[0]] = true;
        }
        if (user_data.is_superuser) {
          permissions['is_superuser'] = true;
        }
        if (user_data.local_superuser) {
          permissions['local_superuser'] = user_data.local_superuser;
        }

        return permissions;
      },
      buildPermissionMap: function(perms) {
        var permissions_map = {};
        for(var i = 0; i < perms.length; i++) {
          var parsed = perms[i].codename.split('_');
          permissions_map[perms[i].id] = {module: parsed[1], type: parsed[0]};
        }
        return permissions_map;
      },
      getAllModules: function() {
        return {
          availableAll: [
            {id: 8, name: 'Firmy', codename: 'firma', hasExport: true},
            {id: 1, name: 'Fakturácia', codename: 'faktura', hasExport: true, hasPrint: true},
            {id: 2, name: 'Karty', codename: 'karta', hasExport: true},
            {id: 3, name: 'Pohľadávky', codename: 'pohladavka', hasExport: true, hasPrint: true},
            {id: 4, name: 'Záväzky', codename: 'zavazok', hasPrint: true, hasExport: true},
            {id: 9, name: 'Pokladničné doklady', codename: 'pokladnicnydoklad', hasExport: false, hasPrint: true},
            {id: 11, name: 'Peňažný denník', codename: 'penaznydennik', hasPrint: true},
            {id: 10, name: 'Bankové doklady', codename: 'bankovydoklad', hasExport: false, hasPrint: true},
            {id: 32, name: 'Interné doklady', codename: 'internydoklad', hasExport: false, hasPrint: true},
            {id: 12, name: 'Príkazy', codename: 'prikaz', hasExport: true},
            {id: 19, name: 'Urgencie', codename: 'urgencia', hasPrint: true},
            {id: 33, name: 'Záznamy cenníkov', codename: 'cennikzaznam', hasExport: true},
            {id: 36, name: 'Pozície', codename: 'pozicia'},
            {id: 35, name: 'Cenníky', codename: 'cennik'},
            {id: 34, name: 'Sklady', codename: 'sklad'},
            {id: 13, name: 'Predkontácie', codename: 'predkontacia'},
            {id: 15, name: 'Účtovná osnova / Členenie sumárne', codename: 'uctosnova', hasPrint: true, hasExport: true},
            {id: 17, name: 'Účtovný denník / Členenie úhrad', codename: 'uctdennik', hasPrint: true},
            {id: 20, name: 'Hlavná kniha', codename: 'hlavnakniha', hasPrint: true},
            {id: 16, name: 'Rozúčtovanie', codename: 'rozuctovanie', hasPrint: true},
            {id: 18, name: 'Súpis dokladov DPH', codename: 'supisdph', hasPrint: true, hasExport: true},
            {id: 14, name: 'Mailová schránka', codename: 'mailmessage', hasExport: true},
            {id: 21, name: 'Odbyt', codename: 'ponuka', hasPrint: true},
            {id: 22, name: 'Nákup', codename: 'nakup', hasPrint: true},
            {id: 23, name: 'Zálohové faktúry', codename: 'zalohovafaktura', hasPrint: true},
            {id: 24, name: 'Množstvá', codename: 'kartamnozstvo', hasPrint: true, hasExport: true},
            {id: 25, name: 'Prehľad pohybu', codename: 'pohyb', hasPrint: true, hasExport: true},
            {id: 26, name: 'Priestory', codename: 'priestor'},
            {id: 27, name: 'Vlastníci', codename: 'vlastnik'},
            {id: 28, name: 'Odpočty meračov', codename: 'odpocetmeraca', hasPrint: true, hasExport: true},
            {id: 29, name: 'Vyúčtovanie', codename: 'vyuctovanie', hasPrint: true},
            {id: 30, name: 'Ubytovanie', codename: 'ubytovanie', hasExport: true, hasPrint: true},
            {id: 31, name: 'Izby', codename: 'izba', hasExport: true}
            // {id: 5, name: 'Prevádzky', codename: 'prevadzka'},
            // {id: 6, name: 'Používatelia', codename: 'myuser', hasExport: true},
            // {id: 7, name: 'Skupiny', codename: 'mygroup', hasExport: true}
          ]
        };
      },
      getAllTypes: function() {
        return ['add', 'change', 'delete', 'view', 'print', 'export'];
      }
    });
  }]);
