'use strict';

angular.module('casist.ucto')
  .controller('PozicieDetailCtrl', [
    '$scope',
    '$modalInstance',
    '$controller',
    'Profile',
    'Pozicie',
    'KartaTyp',
    'SearchCtrl',
    'item',
    'items',
    'Karta',
    'Sklad',
    function(
      $scope,
      $modalInstance,
      $controller,
      Profile,
      Pozicie,
      KartaTyp,
      SearchCtrl,
      item,
      items,
      Karta,
      Sklad
    ) {

    $scope.items = items;
    $scope.Karta = Karta;
    $scope.Sklad = Sklad;

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Pozicie
    });

    var defaultItem = {
        nazov: ''
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'pozicie',
      model: 'item',
      master: item,
      list: $scope.items,
      defaultItem: defaultItem,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });

    if (!isEmpty(item)) {
      $scope.item = Pozicie.copy(
        copyMissing(defaultItem, item)
      );
    } else {
      $scope.item = angular.copy(Pozicie.restangularize(defaultItem));
    }

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
     };

    $scope.selectedKarta = function (karta) {
      $scope.item.karta_data = karta;
    };

    $scope.selectedSklad = function (sklad) {
      $scope.item.sklad_data = sklad;
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: item});
      });
    };

  }]);
