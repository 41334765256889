'use strict';

angular.module('casist.auth')
  .controller('LogoutCtrl', ['$location', '$http', '$rootScope', 'Socket', 'Auth', 'Global', '$q', '$window', 'Notification', '$sessionStorage', 'TabService', function ($location, $http, $rootScope, Socket, Auth, Global, $q, $window, Notification, $sessionStorage, TabService) {
    var removeAuthData = function() {
      $rootScope.isAuth = false;
      Notification.clear();
      Auth.clearAuthData();
      delete $http.defaults.headers.common['Authorization'];
      delete $http.defaults.headers.common['X-Agenda'];
      $rootScope.$broadcast('event:auth-loginRequired');
    }
    var workspace = TabService.saveWorkspace();
    $http.post(Global.get('serverAddress') + "logout", {workspace: workspace, layouts: Auth.profile().get('layouts')}, {timeout: 5000}).then(function() {
      removeAuthData();
      $location.path("/login");
    }, function(error) {
      removeAuthData();
      $location.path("/login");
    });
  }]);
