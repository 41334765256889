'use strict';

angular.module('casist.admin')
  .controller('UserProfileCtrl', ['$scope', 'User', 'Profile', 'Notification', function ($scope, User, Profile, Notification) {
    $scope.slideAnimation = true;
    $scope.errors = {};
    $scope.profile = {
      first_name: Profile.data().userData.first_name,
      last_name: Profile.data().userData.last_name,
      username: Profile.data().userData.username
    };

    $scope.saveProfile = function() {
      $scope.errors = {};
      if (!$scope.profile.oldPassword) {
        $scope.errors.oldPassword = ['Pôvodné heslo musí byť vyplnené'];
      }
      if (!$scope.profile.password || $scope.profile.password.length < 8) {
        $scope.errors.password = ['Heslo musí mať aspoň 8 znakov!'];
      }
      if ($scope.profile.password !== $scope.profile.passwordCheck) {
        $scope.errors.passwordCheck = ['Heslá sa nezhodujú'];
      }
      if (!isEmpty($scope.errors)) {
        $scope.$broadcast('errorsRefresh');
        return;
      }
      var user = User.restangularize(Profile.data().userData);
      $scope.updating = true;
      user.changePassword($scope.profile.oldPassword, $scope.profile.password).then(function() {
        Notification.add('Heslo bolo úspešne zmenené.', 'success', 5);
        $scope.updating = false;
        delete $scope.profile.oldPassword;
        delete $scope.profile.password;
        delete $scope.profile.passwordCheck;
      }, function(error) {
        if (error.status == 403) {
          Notification.add('Nesprávne pôvodné heslo.', 'danger', 5);
        } else {
          Notification.add('Nastala chyba pri zmene hesla.', 'danger', 5);
        }
        $scope.updating = false;
      });
    };
  }]);
