'use strict';

angular.module('casist.svb', ['ui.router'])
  .config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state("default.priestory", {
        url: "priestory?page&dom&vchod",
        abstract: true,
        reloadOnSearch: true,
        data: {
          priestory: [],
        },
        resolve: {
          vchody: [
            "Vchod",
            function (Vchod) {
              return Vchod.objects().getList();
            },
          ],
          priestory: [
            "Priestor",
            "$state",
            "$stateParams",
            "TabService",
            "vchody",
            "$q",
            "Global",
            function (
              Priestor,
              $state,
              $stateParams,
              TabService,
              vchody,
              $q,
              Global
            ) {
              var activeVchod = {};
              Priestor.getQuery(vchody, $stateParams).then(
                function (vchod) {
                  activeVchod = vchod;
                  var vchodObj = {};
                  if (activeVchod) {
                    vchodObj = { vchod: activeVchod.id, dom: activeVchod.dom };
                  }
                  deferred.resolve(
                    Priestor.objects().getList(
                      angular.extend(
                        vchodObj,
                        angular.extend(
                          {
                            page: $stateParams.page || "last",
                            page_size: Global.get("page_size", 20),
                            ordering: "nazov",
                          },
                          TabService.bus.get("priestory")
                        )
                      )
                    )
                  );
                },
                function () {
                  deferred.reject();
                }
              );
              var deferred = $q.defer();

              return deferred.promise;
            },
          ],
        },
        views: {
          priestory: {
            templateUrl: "svb/priestory/priestory.html",
            controller: "PriestoryBaseCtrl",
          },
        },
      })
      .state("default.priestory.list", {
        url: "/list",
        templateUrl: "svb/priestory/priestory-list.html",
        controller: "PriestoryCtrl",
      })
      .state("default.priestory.add", {
        url: "/add",
        templateUrl: "svb/priestory/priestory-detail.html",
        controller: "PriestorDetailCtrl",
        resolve: {
          vlastnici: function () {
            return [];
          },
          merace: function () {
            return [];
          },
          priestor: function () {
            return undefined;
          },
        },
      })
      .state("default.priestory.detail", {
        url: "/:priestorID",
        template: "<ui-view/>",
        abstract: true,
        resolve: {
          vlastnici: [
            "$stateParams",
            "$state",
            "priestory",
            "PriestorVlastnik",
            "priestor",
            "Profile",
            function (
              $stateParams,
              $state,
              priestory,
              PriestorVlastnik,
              priestor,
              Profile
            ) {
              if (!priestor) {
                return [];
              }
              return priestor.all("vlastnici").getList({
                rok: Profile.get("obdobie", new Date().getFullYear()),
              });
            },
          ],
          priestor: [
            "Priestor",
            "$stateParams",
            function (Priestor, $stateParams) {
              return Priestor.objects().one($stateParams.priestorID).get();
            },
          ],
          merace: [
            "$stateParams",
            "priestory",
            "Merac",
            "priestor",
            function ($stateParams, priestory, Merac, priestor) {
              //var priestor = _.find(priestory, {id: parseInt($stateParams.priestorID)});
              if (!priestor) {
                return [];
              }
              return priestor.all("merace").getList();
              //return Merac.parent(priestor).objects().getList();
            },
          ],
        },
      })
      .state("default.priestory.detail.summary", {
        url: "/detail",
        templateUrl: "svb/priestory/priestory-detail.html",
        controller: "PriestorDetailCtrl",
      })
      .state("default.priestory.detail.vlastnik", {
        url: "/vlastnici/{vlastnikID:int}",
        templateUrl: "svb/priestory/priestory-vlastnik-detail.html",
        controller: "PriestorVlastnikCtrl",
        resolve: {
          vlastnik: [
            "Vlastnik",
            "$stateParams",
            "Profile",
            function (Vlastnik, $stateParams, Profile) {
              return Vlastnik.objects()
                .one($stateParams.vlastnikID)
                .get({ rok: Profile.get("obdobie", new Date().getFullYear()) });
            },
          ],
        },
      })
      .state("default.priestory.detail.addVlastnik", {
        url: "/vlastnici/add",
        templateUrl: "svb/priestory/priestory-vlastnik-detail.html",
        controller: "PriestorVlastnikCtrl",
        resolve: {
          vlastnik: function () {
            return null;
          },
        },
      })
      .state("default.priestory.detail.merac", {
        url: "/merace/{meracID:int}",
        templateUrl: "svb/priestory/priestory-merac-detail.html",
        controller: "PriestorMeracCtrl",
      })
      .state("default.priestory.detail.addMerac", {
        url: "/merace/add",
        templateUrl: "svb/priestory/priestory-merac-detail.html",
        controller: "PriestorMeracCtrl",
      })
      .state("default.odpocty", {
        url: "odpocty?page&vchod",
        reloadOnSearch: false,
        abstract: true,
        resolve: {
          vchody: [
            "Vchod",
            function (Vchod) {
              return Vchod.objects().getList();
            },
          ],
          odpocty: [
            "Odpocet",
            "$state",
            "$stateParams",
            "TabService",
            "Global",
            function (Odpocet, $state, $stateParams, TabService, Global) {
              return Odpocet.objects().getList(
                angular.extend(
                  angular.extend(
                    Odpocet.getQuery($stateParams),
                    {
                      page: $stateParams.page || "last",
                      page_size: Global.get("page_size", 20),
                      ordering: "datum,priestor__nazov",
                    },
                    TabService.bus.get("odpocty")
                  )
                )
              );
            },
          ],
        },
        views: {
          odpocty: {
            templateUrl: "svb/odpocty/odpocty.html",
            controller: "OdpocetBaseCtrl",
          },
        },
      })
      .state("default.odpocty.list", {
        url: "/list",
        reloadOnSearch: false,
        views: {
          list: {
            templateUrl: "svb/odpocty/odpocty-list.html",
            controller: "OdpocetCtrl",
          },
          add: {
            templateUrl: "svb/odpocty/odpocty-detail.html",
            controller: "OdpocetDetailCtrl",
          },
        },
      })
      .state("default.vlastnici", {
        url: "vlastnici?page&vchod",
        abstract: true,
        reloadOnSearch: false,
        resolve: {
          vchody: [
            "Vchod",
            function (Vchod) {
              return Vchod.objects().getList();
            },
          ],
          vlastnici: [
            "Vlastnik",
            "$state",
            "$stateParams",
            "TabService",
            "vchody",
            "Profile",
            "Global",
            function (
              Vlastnik,
              $state,
              $stateParams,
              TabService,
              vchody,
              Profile,
              Global
            ) {
              return Vlastnik.objects().getList(
                angular.extend(
                  { rok: Profile.get("obdobie", new Date().getFullYear()) },
                  angular.extend(
                    Vlastnik.getQuery(vchody, $stateParams),
                    angular.extend(
                      {
                        page: $stateParams.page || "last",
                        page_size: Global.get("page_size", 20),
                        ordering: "meno",
                      },
                      TabService.bus.get("vlastnici")
                    )
                  )
                )
              );
            },
          ],
        },
        views: {
          vlastnici: {
            templateUrl: "svb/vlastnici/vlastnici.html",
            controller: "VlastniciBaseCtrl",
          },
        },
      })
      .state("default.vlastnici.list", {
        url: "/list",
        reloadOnSearch: false,
        views: {
          vlastnici: {
            templateUrl: "svb/vlastnici/vlastnici-list.html",
            controller: "VlastniciCtrl",
          },
        },
      })
      .state("default.vlastnici.add", {
        url: "/add",
        templateUrl: "svb/vlastnici/vlastnici-detail.html",
        resolve: {
          vlastnik: function () {
            return undefined;
          },
        },
        controller: "VlastnikDetailCtrl",
      })
      .state("default.vlastnici.detail", {
        url: "/:vlastnikID",
        templateUrl: "svb/vlastnici/vlastnici-detail.html",
        resolve: {
          vlastnik: [
            "Vlastnik",
            "$stateParams",
            "Profile",
            function (Vlastnik, $stateParams, Profile) {
              return Vlastnik.objects()
                .one($stateParams.vlastnikID)
                .get({ rok: Profile.get("obdobie", new Date().getFullYear()) });
            },
          ],
        },
        controller: "VlastnikDetailCtrl",
      })
      .state("default.vyuctovanie", {
        url: "vyuctovanie?page&vchod",
        reloadOnSearch: false,
        abstract: true,
        resolve: {
          vchody: [
            "Vchod",
            function (Vchod) {
              return Vchod.objects().getList();
            },
          ],
          vyuctovanie: [
            "Vyuctovanie",
            "$state",
            "$stateParams",
            "TabService",
            "vchody",
            "Global",
            function (
              Vyuctovanie,
              $state,
              $stateParams,
              TabService,
              vchody,
              Global
            ) {
              return Vyuctovanie.objects().getList(
                angular.extend(
                  angular.extend(
                    Vyuctovanie.getQuery(vchody, $stateParams),
                    {
                      page: $stateParams.page || "last",
                      page_size: Global.get("page_size", 20),
                      ordering:
                        "sluzbavlastnika__vlastnik__meno, sluzbavlastnika__sluzba__nazov",
                    },
                    TabService.bus.get("vyuctovanie")
                  )
                )
              );
            },
          ],
        },
        views: {
          vyuctovanie: {
            templateUrl: "svb/vyuctovanie/vyuctovanie.html",
            controller: "VyuctovanieBaseCtrl",
          },
        },
      })
      .state("default.vyuctovanie.list", {
        url: "/list",
        reloadOnSearch: false,
        views: {
          vyuctovanie: {
            templateUrl: "svb/vyuctovanie/vyuctovanie-list.html",
            controller: "VyuctovanieCtrl",
          },
        },
      });
  }]);
