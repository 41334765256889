'use strict';

angular.module('casist.utils')
  .filter('mesiacSlovom', function() {
    return function(input) {
      if (!input) {
        return input;
      }
      var parsed = parseDate(input);
      if (isNaN(parsed)) {
        return input;
      }
      var month = parsed.getMonth();
      var text;
      switch (month) {
        case 0:
          text = 'Január';
          break;
        case 1:
          text = 'Február';
          break;
        case 2:
          text = 'Marec';
          break;
        case 3:
          text = 'Apríl';
          break;
        case 4:
          text = 'Máj';
          break;
        case 5:
          text = 'Jún';
          break;
        case 6:
          text = 'Júl';
          break;
        case 7:
          text = 'August';
          break;
        case 8:
          text = 'September';
          break;
        case 9:
          text = 'Október';
          break;
        case 10:
          text = 'November';
          break;
        case 11:
          text = 'December';
          break;
        default:
          text = '?';
      }
      return text + ' ' + parsed.getFullYear();
    }
  });