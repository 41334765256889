"use strict";

angular.module("casist.ucto").controller("BankoveDokladyCtrl", [
  "$scope",
  "$rootScope",
  "Profile",
  "$controller",
  "BankovyDoklad",
  "Firma",
  "Outputs",
  "$q",
  "DokladTypy",
  "BankAccount",
  "Upload",
  "$modal",
  "Doklad",
  "TabService",
  "Notification",
  "Dialog",
  "Predkontacia",
  function (
    $scope,
    $rootScope,
    Profile,
    $controller,
    BankovyDoklad,
    Firma,
    Outputs,
    $q,
    DokladTypy,
    BankAccount,
    Upload,
    $modal,
    Doklad,
    TabService,
    Notification,
    Dialog,
    Predkontacia
  ) {
    $scope.slideAnimation = true;

    var defaultPeriod = {
      d_zdp__gte: Profile.get("obdobie", new Date().getFullYear()) + "-01-01",
      d_zdp__lte: Profile.get("obdobie", new Date().getFullYear()) + "-12-31",
    };
    var defaultFilter = {};
    if (!isEmpty(Profile.get("ucty"))) {
      defaultFilter = {
        ucet: _.map(Profile.get("ucty"), (ucet) => ucet.id).join(","),
      };
    }
    $scope.uctovanieTypy = [
      { id: 1, uctovanie: "Nezaúčtované" },
      { id: 2, uctovanie: "Ručne účtované" },
      { id: 3, uctovanie: "Predkontácia" },
      { id: 4, uctovanie: "Zaúčtované" },
    ];
    $scope.rozuctovanieTypy = [
      { id: 1, rozuctovanie: "Nerozúčtované" },
      { id: 2, rozuctovanie: "Rozúčtované" },
    ];
    $scope.pociatocnyStav = undefined;
    $scope.banka = [];

    var filterChanged = function (filter) {
      var promise = BankovyDoklad.objects().getList(filter);
      $scope.pociatocnyStav = undefined;
      $scope.banka.loading = true;
      promise.then(function (data) {
        TabService.registerScope("bankovedoklady", $scope);
        if (data.sumline.length > 1) {
          var sums = angular.copy(data.sumline);
          $scope.pociatocnyStav = sums[1];
          data.sumline[1].spolu =
            parseFloat(data.sumline[0].spolu) + parseFloat(sums[1].poc_stav);
        }
        $scope.banka = data;
      });
      return promise;
    };
    $scope.parseSums = function (obj, key) {
      return parseFloat(obj[key] * obj.operacia);
    };
    var unregisterAuthObservers = [];

    unregisterAuthObservers.push(
      Profile.addObserver("agenda", function () {
        $scope.SearchCtrl.cancelFilter(false);
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.refresh();
      })
    );
    unregisterAuthObservers.push(
      Profile.addObserver("obdobie", function (obdobie) {
        defaultPeriod = {
          d_zdp__gte:
            Profile.get("obdobie", new Date().getFullYear()) + "-01-01",
          d_zdp__lte:
            Profile.get("obdobie", new Date().getFullYear()) + "-12-31",
        };
        $scope.SearchCtrl.init({
          defaultPeriod: defaultPeriod,
          onChange: filterChanged,
        });
        $scope.SearchCtrl.setLastPage();
        $scope.SearchCtrl.refresh();
      })
    );
    $scope.SearchCtrl = $controller("SearchCtrl");
    $scope.Firma = Firma;
    $scope.getPredkontacia = function (query) {
      return Predkontacia.get(query, DokladTypy.BD.dd);
    };
    $scope.operacie = [
      { id: "1", operacia: "+" },
      { id: "-1", operacia: "-" },
    ];
    $scope.ucty = [];
    BankAccount.objects()
      .getList()
      .then(function (data) {
        angular.copy(data, $scope.ucty);
        $scope.ucty_neinterne = _.reject(data, function (obj) {
          return obj.interny;
        });
      });

    var coreFields = [
      {},
      {},
      {
        field: "ucet",
        label: "banka_ucty.KU",
        filterCollapse: "right",
        select2: { source: "ucty", lookup: "kod" },
        tooltip: "banka_ucty.KOD_UCTU",
      },
      {
        field: "iddokd",
        label: "ucto.RECORD",
        width: 110,
        type: "int",
        filterCollapse: "right",
      },
      {
        field: "vypis",
        label: "banka.VYPIS",
        width: 80,
        type: "int",
        filterType: "icontains",
      },
      { field: "vs", label: "ucto.VS", type: "int", filterType: "icontains" },
      { field: "d_zdp", label: "pokladna.UHRADENE", width: 90, type: "date" },
      {
        field: "firma__firma",
        label: "firmy.FIRMA",
        placeholder: "ucto.FIRMA_ICO",
        linked: {
          source: "Firma.get(query)",
          template: "linked-element-match-table",
          lookup: "firma",
          style: "{width: '400px'}",
        },
      },
      {
        field: "operacia",
        label: "pokladna.OPERACIA",
        width: 30,
        select2: { source: "operacie", lookup: "operacia" },
      },
      { field: "spolu", label: "ucto.SPOLU", type: "number", sum: true },
      { field: "suma1", label: "fakturacia.SUMA1", type: "number", sum: true },
      { field: "faktura_field__iddokd", label: "pokladna.UHRADA" },
      // { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
      {
        field: "predkontacia__nazov",
        label: "ucto.UCTOVANIE",
        width: 150,
        filterCollapse: "left",
        filterType: "iexact",
        linked: {
          source: "getPredkontacia(query)",
          template: "predkontacia-selection",
          lookup: "nazov",
          style: "{width: '400px'}",
        },
      },
      { field: "spolu_zm", label: "ucto.SPOLUZM", type: "number", sum: true },
      { field: "kurz", label: "ucto.KURZ", type: "number" },
      { field: "mena", label: "ucto.MENA", filterCollapse: "left" },
    ];
    // if (!Profile.get('platcadph')) {
    //   var omitted = ['uplat_dph'];
    //   coreFields = _.filter(coreFields, function(field) { return omitted.indexOf(field.field) === -1});
    // }

    $scope.bankaTable = coreFields;

    $scope.create = function (item) {
      return BankovyDoklad.edit(item, $scope.banka, $scope.SearchCtrl);
    };
    $scope.copy = function (item) {
      var copied = BankovyDoklad.copy(item);
      _.each(
        [
          "id",
          "faktura",
          "faktura_data",
          "locked",
        ],
        function (field) {
          delete copied[field];
        }
      );
      $scope.create(copied);
    };

    var events = BankovyDoklad.registerEvents(
      function () {
        return $scope.banka;
      },
      function () {
        return $scope.SearchCtrl;
      }
    );
    $scope.$on("$destroy", function () {
      BankovyDoklad.unregisterEvents(events);
      for (var i in unregisterAuthObservers) {
        unregisterAuthObservers[i]();
      }
    });

    $scope.SearchCtrl.init({
      model: BankovyDoklad,
      defaultPeriod: defaultPeriod,
      defaultFilter: defaultFilter,
      onChange: filterChanged,
      columnsDef: $scope.bankaTable,
      sort: { sort: "iddokd", reverse: false },
      scope: $scope,
    });

    $scope.SearchCtrl.refresh();

    $scope.print = function (doklad) {
      Outputs.openPrintUrl("banka", doklad.id);
    };
    $scope.printList = function () {
      Outputs.openPrintUrl("banka", null, $scope.SearchCtrl.getQuery());
    };
    $scope.printDennyObrat = function () {
      var query = $scope.SearchCtrl.getQuery();
      delete query.ordering;
      Outputs.openPrintUrl("banka", null, query, "print-denny-obrat");
    };

    $scope.uploadImport = function (ucet, $files) {
      $scope.uploadInProgress = true;
      BankovyDoklad.import(ucet, $files).then(
        function (data) {
          $scope.uploadInProgress = false;
          var modalInstance = $modal.open({
            templateUrl: "ucto/bankovedoklady/import/bankaimport.html",
            controller: "BankaImportCtrl",
            backdrop: "static",
            windowClass: "detail",
            resolve: {
              ucty: function () {
                return $scope.ucty_neinterne;
              },
              ucet: function () {
                return ucet;
              },
              list: function () {
                return data;
              },
              filename: function () {
                return $files[0].name;
              },
            },
          });
          modalInstance.result.then(
            function (data) {
              BankovyDoklad.importData(data).then(function (importData) {
                for (var i = 0; i < importData.length; i++) {
                  BankovyDoklad.syncCreate(importData[i]);
                  if (importData[i].faktura) {
                    Doklad.syncChange(
                      importData[i].faktura_data,
                      importData[i].faktura_data
                    );
                  }
                  Profile.parseObdobie(importData[i].d_zdp);
                }
              });
            },
            function () {}
          );
        },
        function (error) {
          Notification.add(
            error ? error.data.error || error : "Nastala chyba pri importe.",
            "danger",
            10
          );
          $scope.uploadInProgress = false;
        }
      );
    };

    $scope.globalneUctovanie = function () {
      if (!$scope.SearchCtrl.hasFilter()) {
        Dialog.alert(
          "Globálne účtovanie je možné použiť len keď je použitý filter.",
          "Upozornenie"
        );
        return;
      }
      $modal
        .open({
          templateUrl: "ucto/globalne-uctovanie/globalne-uctovanie.html",
          controller: "GlobalneUctovanieCtrl",
          backdrop: "static",
          windowClass: "detail-sm",
          resolve: {
            list: function () {
              return $scope.banka;
            },
            query: function () {
              return $scope.SearchCtrl.getQuery();
            },
            model: function () {
              return BankovyDoklad;
            },
            predkontacieGetter: function () {
              return function (query) {
                return $scope.getPredkontacia(query);
              };
            },
          },
        })
        .result.then(function (data) {
          Notification.add("Záznamy boli zaúčtované.", "success", 5);
          var faktura;
          for (var i = 0; i < data.length; i++) {
            faktura = _.find($scope.banka, { id: data[i].id });
            if (faktura) {
              BankovyDoklad.syncChange(data[i], angular.copy(faktura));
            }
          }
        });
    };
  },
]);
