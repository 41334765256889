'use strict';

angular.module('casist.utils')
  .filter('textcut', function () {
    return function (input, length) {
      if (!input)
        return input;
      if (input.length < length) {
        return input;
      }
      return input.substring(0, length);
    };
  });
