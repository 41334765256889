'use strict';

angular.module('casist.ucto').controller('PrikazyCtrl',
  ['$scope', '$rootScope', 'Profile', '$controller', 'Prikaz', 'Firma', 'Outputs', '$q', 'DokladTypy', 'BankAccount', 'TabService',
  function ($scope, $rootScope, Profile, $controller, Prikaz, Firma, Outputs, $q, DokladTypy, BankAccount, TabService) {

  $scope.slideAnimation = true;

  var defaultPeriod = {
    'd_vyst__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_vyst__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {};
  if (!isEmpty(Profile.get('ucty'))) {
    defaultFilter = {
      ucet: _.map(Profile.get('ucty'), ucet => ucet.id).join(',')
    }
  }
  $scope.prikazy = [];

  var filterChanged = function(filter) {
    var promise = Prikaz.objects().getList(filter);
    $scope.prikazy.loading = true;
    promise.then(function(data) {
      TabService.registerScope('prikazy', $scope);
      $scope.prikazy = data;
    });
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_vyst__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_vyst__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.ucty = BankAccount.objects().getList().$object;

  $scope.prikazyTable = [
    {  },
    { field: 'prikaz', label: 'prikazy.DAVKA', width: 70, type: 'int', filterCollapse: 'right' },
    { field: 'd_splat', label: 'fakturacia.SPLATNOST', type: 'date', width: 90 },
    { field: 'd_vyst', label: 'fakturacia.VYSTAVENIE', type: 'date', width: 90 },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'suma', label: 'urgencie.SUMA', type: 'number', sum: true },
    { field: 'ucet', label: 'prikazy.DEBET_UCET', select2: { source: 'ucty', lookup: 'kod' } },
    { field: 'ucet_kredit', label: 'prikazy.KREDIT_UCET', filterType: 'icontains' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'ks', label: 'ucto.KS', type: 'int', filterType: 'icontains' },
    { field: 'ss', label: 'ucto.SS', type: 'int', filterType: 'icontains' },
    { field: 'zavazok__iddokd', label: 'pokladna.UHRADA' },
    { field: 'poznamka', label: 'fakturacia.POZNAMKA' }
  ];

  $scope.create = function(item) {
    return Prikaz.edit(item, $scope.prikazy, $scope.SearchCtrl);
  };

  var events = Prikaz.registerEvents(function() { return $scope.prikazy; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Prikaz.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Prikaz,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.prikazyTable,
    sort: {sort: 'prikaz', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh(TabService.bus.get('prikazy'));

  $scope.export = function(doklad) {
    Outputs.openTxtExportUrl('prikazy', angular.extend($scope.SearchCtrl.getQuery(), {prikaz: doklad.prikaz}));
  };
}]);
