"use strict";

angular.module("casist.ucto").factory("Zavazok", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  "Upload",
  "Dialog",
  "$http",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate,
    Upload,
    Dialog,
    $http
  ) {
    var ENDPOINT = "zavazky";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          var promise = Restangular.all("zavazky").customGETLIST("", {
            poradove_cislo: this.poradove_cislo,
          });
          var id = this.id;
          var editing = false;

          promise.then(function (response) {
            for (var i = 0; i < response.length; i++) {
              if (id === response[i].id) {
                editing = true;
                break;
              }
            }

            if (response.length > 0 && !editing) {
              Dialog.alert("Poradové číslo už existuje", "Upozornenie");
            }
          });

          if (!this.d_vyst) {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma === "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          if (!angular.isDefined(this.vs) || this.vs === "") {
            this.addError("vs", "Variabilný symbol musí byť zadaný!");
            result = false;
          }
          if (!/^[0-9]*$/.test(this.vs)) {
            this.addError("vs", "Variabilný symbol musí obsahovať iba čísla!");
            result = false;
          }
          return result;
        },
        listUhradene: function () {
          return this.getList("uhrady");
        },
        prepocetUhrad: function () {
          return this.all("uhrady").all("prepocet").post({});
        },
        recountNaklady: function (pohyb, mena) {
          var deferred = $q.defer();
          var self = this;
          var kurz = parseFloat(self.kurz || 0);
          function countIt(pohyb) {
            if (!pohyb.length) {
              deferred.resolve(pohyb);
              return deferred.promise;
            }
            var naklady = parseFloat(self.naklady);
            var spoluPrijem = 0;
            for (var i = 0; i < pohyb.length; i++) {
              if (self.primarna_zm && kurz > 0) {
                spoluPrijem +=
                  parseFloat(pohyb[i].cena_zm || 0) *
                  parseFloat(pohyb[i].mp || 0);
              } else {
               spoluPrijem +=
                  parseFloat(pohyb[i].cena_prijem || 0) *
                  parseFloat(pohyb[i].mp || 0);
              }
            }
            for (var i = 0; i < pohyb.length; i++) {
              if (self.mena_data && mena === 'eur' && kurz > 0) {
                pohyb[i].cena_naklady =
                  Math.round(
                    ((parseFloat(pohyb[i].cena_zm || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_zm || 0) / spoluPrijem)) / kurz) *
                      10000
                  ) / 10000.0;
              } else if (self.mena_data && mena === 'czk' && kurz > 0) {
                pohyb[i].cena_naklady =
                  Math.round(
                    ((parseFloat(pohyb[i].cena_zm || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_zm || 0) / spoluPrijem)) * kurz) *
                      10000
                  ) / 10000.0;
              } else {
                pohyb[i].cena_naklady =
                  Math.round(
                    (parseFloat(pohyb[i].cena_prijem || 0) +
                      naklady *
                        (parseFloat(pohyb[i].cena_prijem || 0) / spoluPrijem)) *
                      10000
                  ) / 10000.0;
              }
            }
            self.recountSums(pohyb);
            deferred.resolve(pohyb);
          }

          if (!pohyb) {
            this.getList("pohyb").then(function (data) {
              countIt(data);
            });
          } else {
            countIt(pohyb);
          }
          return deferred.promise;
        },
      });
    });

    var openDetail = function (templateUrl, item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: "ZavazkyDetailCtrl",
        backdrop: "static",
        keyboard: false,
        windowClass: "detail",
        resolve: {
          faktura: function () {
            return item ? item : {};
          },
          faktury: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return angular.extend({ predkontacie: true, ucty: true }, config);
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").zavazok;
      },
      getUctoFields: function () {
        return [
          "zaklad_d1",
          "d1",
          "zaklad_d2",
          "d2",
          "zaklad_d0",
          "spolu",
          "suma1",
          "suma2",
          "suma3",
          "suma4",
          "suma5",
        ];
      },
      uhrada: function (query) {
        var promise = Restangular.all("zavazky").all("uhrada").post({}, query);
        return promise;
      },
      prepocetUhrad: function (datum, query, canceller) {
        if (canceller) {
          return Restangular.all("zavazky")
            .all("prepocetuhrad")
            .withHttpConfig({ timeout: canceller })
            .post({ datum: datum }, query);
        } else {
          return Restangular.all("zavazky")
            .all("prepocetuhrad")
            .post({ datum: datum }, query);
        }
      },
      getImportConfig: function () {
        return Restangular.all(ENDPOINT).all("import").customGET();
      },
      import: function ($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all("import").getRestangularUrl(),
          data: { fileName: file.name, config: JSON.stringify(config) },
          file: file,
        })
          .progress(function (evt) {
            deferred.notify(parseInt((100.0 * evt.loaded) / evt.total));
          })
          .then(
            function (data) {
              deferred.resolve(data.data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
      importData: function (data) {
        return Restangular.all(ENDPOINT)
          .all("import")
          .post(data, { import: 1 });
      },
      globalneUctovanie: function (predkontacia, query, canceller) {
        if (canceller) {
          return Restangular.all("zavazky")
            .withHttpConfig({ timeout: canceller })
            .customPUT(
              { predkontacia: predkontacia },
              "globalneuctovanie",
              query
            );
        } else {
          return Restangular.all("zavazky").customPUT(
            { predkontacia: predkontacia },
            "globalneuctovanie",
            query
          );
        }
      },
      edit: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(
          "ucto/zavazky/zavazky-detail.html",
          doklad,
          list,
          searchCtrl,
          config
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },

      uploadAttachment: function (file, zavazok) {
        Upload.upload({
          url: Restangular.all(ENDPOINT).all('attachment').getRestangularUrl(),
          data: { zavazokId: zavazok.id },
          file: file,
          headers: {
            Authorization: $http.defaults.headers.common.Authorization,
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          },
        })
        .then(
          function (response) {
            zavazok.attachment = response.data.filename;
          },
          function (error) {
            Dialog.alert('Formát súboru nie je podporovaný.', 'Chyba');
            console.log(error);
          }
        );
      },

      removeAttachment: function (zavazok) {
        Upload.upload({
          method: 'delete',
          url: Restangular.all(ENDPOINT).all('attachment').getRestangularUrl(),
          data: { zavazokId: zavazok.id },
          headers: {
            Authorization: $http.defaults.headers.common.Authorization,
            'X-Agenda': $http.defaults.headers.common['X-Agenda'],
          },
        })
        .then(
          function (response) {
            zavazok.attachment = '';
          },
          function (error) {
            console.log(error);
          }
        );
      },

      itemsImport: function (file) {
        return Upload.upload({
          url: Restangular.all(ENDPOINT).all('import-poloziek').getRestangularUrl(),
          data: { locationOrigin: constants.authServer },
          file: file
        });
      },
    });
  },
]);
