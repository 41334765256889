'use strict';

angular.module('casist.svb')
  .controller('GenerovaniePredpisovCtrl', ['$scope', '$modalInstance', function($scope, $modalInstance) {
    $scope.calendarOpened = false;
    $scope.data = {
      userinput: getCurrentDate()
    };
    $scope.ok = function() {
      $modalInstance.close($scope.data);
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }]);
