'use strict';

angular.module('casist.svb')
  .controller('VyuctovanieBaseCtrl', ['$scope', '$timeout', 'vyuctovanie', '$location', 'Priestor', 'TabService', '$state', '$stateParams', '$controller', 'vchody', 'Profile', 'Vyuctovanie', '$modal', 'Karta', 'Vlastnik', 'Notification', 'Faktura', 'Outputs',
    function($scope, $timeout, vyuctovanie, $location, Priestor, TabService, $state, $stateParams, $controller, vchody, Profile, Vyuctovanie, $modal, Karta, Vlastnik, Notification, Faktura, Outputs) {
      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.vyuctovanie = vyuctovanie;
      $scope.vyuctovanieList = {page: $stateParams.page || Math.ceil(vyuctovanie.count / $scope.SearchCtrl.per_page) || 'last', num_pages: 0};
      var setObdobia = function() {
        $scope.obdobia = Profile.get('obdobia');
        if ($scope.obdobia && $scope.obdobia.length) {
          $scope.obdobie = (Profile.get('obdobie') && _.find($scope.obdobia, {rok: Profile.get('obdobie')})) ? {rok: Profile.get('obdobie')} : $scope.obdobia[0];
        }
      };
      Profile.addObserver('obdobia', setObdobia);
      setObdobia();
      $scope.SearchCtrl.filterSelection('rok', $scope.obdobie.rok, false);

      $scope.defaultFilter = {};
      $scope.filterChanged = function(filter) {
        var promise = Vyuctovanie.objects().getList(filter);
        $scope.vyuctovanie.loading = true;
        promise.then(function(data) {
          TabService.registerScope('vyuctovanie', $scope);
          $scope.vyuctovanie = data;
          delete $scope.vyuctovanie.loading;
        });
        return promise;
      };
      $scope.refreshPriestoryList = function() {
        return $scope.filterChanged(angular.extend($scope.SearchCtrl.getQuery(), {page: $scope.vyuctovanieList.page}));
      };
      $scope.refreshVchody = function(dom) {
        $scope.firstSetup = $scope.domy.length === 0;
        if ($scope.firstSetup && $scope.SearchCtrl.filters.top.dom) {
          delete $scope.SearchCtrl.filters.top.dom;
        }
        if (!$scope.firstSetup && !$scope.SearchCtrl.filters.top.dom) {
          $scope.SearchCtrl.filterSelection('dom', $scope.domy[0], false);
          $scope.domChanged($scope.domy[0]);
        }
        if (!dom) {
          dom = _.find($scope.domy, {id: parseInt($scope.SearchCtrl.filters.top.dom)});
        }
        $scope.vchody = [];
        if (!dom) {
          return;
        }
        _.each(vchody, function(obj) {
          if (obj.dom === dom.id) {
            $scope.vchody.push(obj);
          }
        });
      };
      $scope.domChanged = function(dom) {
        var searchParams = {page: 'last', dom: dom.id};
        $scope.refreshVchody(dom);
        if ($scope.vchody.length) {
          searchParams.vchod = $scope.vchody[0].id;
          $scope.SearchCtrl.filterSelection('vchod', $scope.vchody[0].id, true);
        }
        $location.search(searchParams);
        $scope.defaultFilter.dom = dom.id;
        $scope.SearchCtrl.filterSelection('dom', dom.id, false);
      };
      $scope.vchodChanged = function(vchod) {
        $location.search({page: 'last', vchod: vchod.id});
        $scope.defaultFilter.vchod = vchod.id;
        $scope.$broadcast('vchodChanged', function() {
          return vchod;
        });
      };
      $scope.vchody = [];
      $scope.domy = [];
      $scope.firstSetup = false;
      Priestor.getQuery(vchody).then(function(vchod) {
        if (!vchod) {
          $scope.firstSetup = true;
          return;
        }
        _.each(vchody, function(obj) {
          if (!_.find($scope.domy, {id: obj.dom})) {
            $scope.domy.push({id: obj.dom, nazov: obj.dom_data.nazov});
          }
          if (obj.dom === vchod.dom) {
            $scope.vchody.push(obj);
          }
        });
        $scope.SearchCtrl.filterSelection('dom', vchod.dom, false);
        $scope.SearchCtrl.filterSelection('vchod', vchod.id, false);
        $scope.defaultFilter.dom = vchod.dom;
        $scope.defaultFilter.vchod = vchod.id;
      });
      $scope.changePage = function() {
        $location.search(angular.extend($location.search(), {page: $scope.SearchCtrl.page}));
        $scope.SearchCtrl.refresh();
      };

      $scope.manageDomy = function() {
        $modal.open({
          templateUrl: 'svb/domy/domy.html',
          controller: 'DomyCtrl',
          backdrop: 'static',
          windowClass: 'detail-md',
          resolve: {
            domy: function() {
              return $scope.domy;
            },
            vchody: function() {
              return vchody;
            }
          }
        }).result.then(function() {
            $scope.refreshVchody();
          });
      };

      $scope.Vlastnik = Vlastnik;

      $scope.vyuctovanieTable = [
        { width: 40 },
        { field: 'vlastnik', label: 'vlastnici.VLASTNIK', filterCollapse: 'right', linked: { source: 'Vlastnik.get(query)', lookup: 'meno' } },
        { field: 'sluzba', label: 'sluzby.SLUZBA'},
        { field: 'sposob_rozuctovania', label: 'sluzby.ROZUCTOVANIE', select2: { source: 'getRozuct()', lookup: 'sposob' } },
        { field: 'mj', label: 'sluzby.MJ' },
        { field: 'objekt', label: 'sluzby.OBJEKT', type: 'number', sum: true },
        { field: 'priestor', label: 'sluzby.PRIESTOR', type: 'number', sum: true },
        { field: 'zalohy', label: 'sluzby.ZALOHY', type: 'number', sum: true },
        { field: 'rozdiel', label: 'sluzby.ROZDIEL', type: 'number', sum: true },
        { field: 'objekt_mj', label: 'sluzby.MJOBJEKT', type: 'number', sum: true },
        { field: 'priestor_mj', label: 'sluzby.MJPRIESTOR', type: 'number', sum: true },
        { field: 'sluzbavlastnika__zaloha', label: 'sluzby.ZALOHA', type: 'number', sum: true },
        { field: 'prepocitana_zaloha', label: 'sluzby.PREPOCITANA', type: 'number', sum: true, filterCollapse: 'left' }
      ];
      $scope.SearchCtrl.init({
        onChange: $scope.filterChanged,
        columnsDef: $scope.vyuctovanieTable,
        sort: {sort: 'sluzbavlastnika__vlastnik__meno, sluzbavlastnika__sluzba__nazov'},
        page: $scope.vyuctovanieList.page,
        scope: $scope,
        useLocation: true,
        defaultFilter: $scope.defaultFilter
      });
      var events = Priestor.registerEvents(function() { return $scope.vyuctovanie; }, function() { return $scope.SearchCtrl; });
      $scope.$on('$destroy', function() {
        Priestor.unregisterEvents(events);
      });

      $scope.vyuctovanieProgress = false;
      $scope.vypocitatVyuctovanie = function() {
        $scope.vyuctovanieProgress = true;
        Vyuctovanie.vypocet($scope.SearchCtrl.filters.top, {ordering: $scope.SearchCtrl.getOrdering(), page: $scope.SearchCtrl.page}).then(function(data) {
          $scope.vyuctovanieProgress = false;
          data.results.sumline = data.sumline;
          data.results.count = data.count;
          $scope.vyuctovanie = data.results;
          $timeout(function() {
            $scope.SearchCtrl.broadcastRefresh('vyuctovanie');
          });
          Notification.add('Vyúčtovanie bolo prepočítané.', 'success', 5);
        }, function() {
          $scope.vyuctovanieProgress = false;
          Notification.add('Vyúčtovanie sa nepodarilo vypočítať.', 'danger', 5);
        });
      };
      $scope.generovatPredpisy= function() {
        $modal.open({
          templateUrl: 'svb/vyuctovanie/generovanie_predpisov.html',
          controller: 'GenerovaniePredpisovCtrl',
          backdrop: 'static',
          windowClass: 'detail-sm'
        }).result.then(function(data) {
            var date = parseDate(data.userinput);
            $scope.predpisyProgress = true;
            Vyuctovanie.predpisy(angular.extend(angular.copy($scope.SearchCtrl.filters.top), {mesiac: date.getMonth()+1, rok: date.getFullYear()})).then(function(data) {
              $scope.predpisyProgress = false;
              for (var i = 0; i < data.faktury.length; i++) {
                Faktura.syncCreate(data.faktury[i]);
                Profile.parseObdobie(data.faktury[i].d_vyst);
              }
              Notification.add('Predpisy boli vygenerované.', 'success', 5);
            }, function(error) {
              $scope.predpisyProgress = false;
              Notification.add((error && error.data.error) ? error.data.error : 'Predpisy sa nepodarilo vygenerovať.', 'danger', 10);
            });
        });
      };
      $scope.generovatVyuctovanie = function() {
        $scope.vyuctovanieFaProgress = true;
        Vyuctovanie.fakturovatVyuctovanie($scope.SearchCtrl.filters.top).then(function(data) {
          $scope.vyuctovanieFaProgress = false;
          for (var i = 0; i < data.faktury.length; i++) {
            Faktura.syncCreate(data.faktury[i]);
            Profile.parseObdobie(data.faktury[i].d_vyst);
          }
          Notification.add('Vyúčtovacie faktúry boli vygenerované.', 'success', 5);
        }, function(error) {
          $scope.vyuctovanieFaProgress = false;
          Notification.add((error && error.data.error) ? error.data.error : 'Vyúčtovacie faktúry sa nepodarilo vygenerovať.', 'danger', 10);
        });
      };
      $scope.getRozuct = Karta.getSposob;

      $scope.goToVlastnik = function(vlastnik) {
        TabService.bus.send('vlastnici', 'vyuctovanie');
        TabService.openTab('vlastnici', null, '/vlastnici/'+vlastnik);
      };

      $scope.print = function(item) {
        Outputs.openPrintUrl('vyuctovanie', item.vlastnik, {rok: item.rok});
      };
      $scope.printAll = function() {
        Outputs.openPrintUrl('vyuctovanie', null, {rok: $scope.SearchCtrl.filters.top.rok});
      };
      $scope.printSumar = function() {
        Outputs.openPrintUrl('vyuctovanie-sumar', null, {rok: $scope.SearchCtrl.filters.top.rok});
      };
      $scope.sendVyuctovanieJednotlivo = function() {
        Vyuctovanie.sendVyuctovanieJednotlivo($scope.SearchCtrl.filters.top.rok)
        .then(
          function (response) {
            Notification.add(response, 'success', 10);
          }
        ).catch(
          function (error) {
            if (error.status >= 400 && error.status < 500) {
              Notification.add(error.data, 'danger', 20);
            }
          }
        );
      };

      $scope.export = function() {
        Outputs.openXLSExportUrl('vyuctovanie', $scope.SearchCtrl.getQuery());
      };
    }
  ])
  .controller('VyuctovanieCtrl', ['$scope', 'Restangular', 'Priestor', 'Profile', '$controller', '$q', 'TabService', 'Outputs', '$modal', 'Notification', '$state', '$stateParams', 'vchody',
    function ($scope, Restangular, Priestor, Profile, $controller, $q, TabService, Outputs, $modal, Notification, $state, $stateParams, vchody) {

    }]);
