'use strict';

angular.module('casist.widgets')
  .directive('formElement', ['$compile', '$timeout', '$parse', function ($compile, $timeout, $parse) {
    return {
      restrict: 'EA',
      template: '<div></div>',
      replace: true,
      scope: {
        label: "@",
        model:'=ngModel',
        autocomplete: '@',
        linkedLookup: '@',
        linkedOnEdit: '&',
        linkedOnAdd: '&',
        linkedOnSelect: '&',
        linkedOnRemove: '&',
        linkedTemplate: '@',
        ngChange:'&',
        ngBlur:'&',
        ngKeypress:'&',
        optionsLookup: '@',
        onSelectionButton:'&',
        optionsTemplate: '&'
      },
      link: {
        post: function postLink(scope, element, attrs) {
          var showSelectionButton = angular.isDefined(attrs.linkedShowSelection) ? scope.$eval(attrs.linkedShowSelection) : true;
          var findControlParent = function(el) {
            var i = 0;
            var obj = el.parent();
            // go max 5 levels deep
            while (i < 5 && !obj.hasClass('form-group')) {
              obj = obj.parent();
              i++;
            }
            if (obj.hasClass('form-group'))
              return obj;
            else return null;
          }
          var parent = undefined;
          scope.calendar = {
            opened: false
          };
          scope.openCal = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            scope.calendar.opened = !scope.calendar.opened;
          }
          scope.$on('errorsRefresh', function() {
            parseErrors(scope.$parent.$eval(attrs.formError));
          });
          var parseErrors = function(errors) {
            scope.errorMessages = "";
            if (errors) {
              scope.errorMessages = "<div class='text-left'>";
              for (var i in errors) {
                scope.errorMessages += "<li>"+errors[i]+"</li>";
              }
              scope.errorMessages += "</div>";
              if (parent)
                parent.addClass('has-feedback has-error');
            } else if (parent) {
              parent.removeClass('has-feedback has-error');
            }
            $timeout(function() {
              scope.$broadcast('errorsChanged');
            });
          }

          // forward edit & add callbacks for linked elements
          scope._linkedOnEdit = function() {
            return scope.linkedOnEdit().apply(null, arguments);
          }
          scope._linkedOnAdd = function() {
            return scope.linkedOnAdd().apply(null, arguments);
          }
          scope._linkedOnRemove = function() {
            return scope.linkedOnRemove().apply(null, arguments);
          }
          scope._linkedOnSelected = function() {
            scope.linkedOnSelect().apply(null, arguments);
          }
          scope.onBlur = function(evt) {
            scope.ngBlur({'$event': evt});
          }

          var hasRequestedFocus = angular.isDefined(attrs.requestFocus) ? scope.$eval(attrs.requestFocus) : false;
          // remove these attributes so they stay only on child input element
          if (attrs.requestFocus) {
            if (parent) {
              parent.attr('request-focus', null);
            }
            delete attrs.requestFocus;
          }
          scope.ngDisabled = false;
          if (angular.isDefined(attrs.ngDisabled)) {
            scope.$parent.$watch(attrs.ngDisabled, function(val) {
              scope.ngDisabled = val;
            });
          }

          var fieldClass = (angular.isDefined(attrs.fieldClass) ? attrs.fieldClass : "col-xs-7");
          var labelClass = (angular.isDefined(attrs.labelClass) ? attrs.labelClass : "col-xs-5");
          var inputClass = (angular.isDefined(attrs.inputClass) ? ' '+attrs.inputClass : '');
          var placeholder = (angular.isDefined(attrs.placeholder) && attrs.placeholder == 'true') ? scope.label : attrs.placeholder;
          var type = (angular.isDefined(attrs.type) ? attrs.type : "text");
          var field = attrs.ngModel;
          var editable = angular.isDefined(attrs.editable);
          var editable_static = angular.isDefined(attrs.static);

          var fieldName = '';
          if (angular.isDefined(field)) {
            if (field.indexOf('.') != -1)
              fieldName = field.split(".")[1];
            else fieldName = field;
          }
          var collapsible = angular.isDefined(attrs.collapsible) ? attrs.collapsible : 60;

          scope.errorClass = '';
          scope.showDaySelector = false;
          scope.linkedValue = {};

          var el = '<label class="'+labelClass+' control-label form-element-label"'+(angular.isDefined(attrs.labelTooltip) ? ' mctooltip="\''+attrs.labelTooltip+'\' | translate"' : '')+' for="'+fieldName+'">'+(angular.isDefined(attrs.dynamicLabel) ? '{{label'+(angular.isDefined(attrs.juLabel) ? ' | jednoducheUctoLabels' : '')+' | translate}}' : '{{::(label'+(angular.isDefined(attrs.juLabel) ? ' | jednoducheUctoLabels' : '')+' | translate)}}')+':</label>';
          el += '<div class="'+fieldClass+'" bindonce refresh-on="\'errorsChanged\'">';
          // el += '<div tooltip-html-unsafe="{{errorMessages}}" tooltip-toggle="errorMessages" class="tooltip-form-error"></div>';
          if (editable_static) {
            if (angular.isDefined(attrs.suma)) {
              el += '<p class="form-control-static text-right" ng-bind="model | suma"></p>';
            } else {
              el += '<p class="form-control-static" ng-bind="model"></p>';
            }
          } else {
            var closingTag = "/>";
            var form_feedback_date = '';
            if (type == "date") {
              form_feedback_date = ' form-control-feedback-date';
              el += '<div casist-date ng-model="model" name="'+fieldName+'" id="'+fieldName+'" error-model="errorMessages"';
              if (angular.isDefined(attrs.showDaySelector)) {
                scope.$parent.$watch(attrs.showDaySelector, function(val) {
                  scope.showDaySelector = val;
                });
                el += ' show-day-selector="showDaySelector"';
              }
              if (angular.isDefined(attrs.showCalendar)) {
                el += ' show-calendar="'+attrs.showCalendar+'"';
              }
              closingTag = "></div>";
            } else if (attrs.linkedSource) {
              var setValue = $parse(attrs.linkedValue).assign || angular.noop;
              scope.$parent.$watch(attrs.linkedSource, function(val) {
                scope.linkedSource = val;
              });
              scope.$parent.$watch(attrs.linkedValue, function(val) {
                scope.linkedValue = val;
              });
              scope.$watch('linkedValue', function(val) {
                setValue(scope.$parent, val);
              });
              el += '<div linked type="'+type+'" input-name="'+fieldName+'" input-id="'+fieldName+'" error-class="errorClass" source="linkedSource" lookup="{{linkedLookup}}" popup-style="'+attrs.linkedStyle+'" popup-template="{{linkedTemplate}}" ng-model="model" error-model="errorMessages"';
              if (angular.isDefined(attrs.linkedValue)) {
                 el += ' value="linkedValue"';
              }
              if (angular.isDefined(attrs.linkedEditable))
                el += ' editable="'+attrs.linkedEditable+'"';
              if (angular.isDefined(attrs.linkedWait))
                el += ' wait="'+attrs.linkedWait+'"';
              if (angular.isDefined(attrs.linkedOnAdd))
                el += ' on-add="_linkedOnAdd"';
              if (angular.isDefined(attrs.linkedOnEdit))
                el += ' on-edit="_linkedOnEdit"';
              if (angular.isDefined(attrs.linkedOnSelect))
                el += ' on-select="_linkedOnSelected"';
              if (angular.isDefined(attrs.linkedOnRemove))
                el += 'on-remove="_linkedOnRemove"';
              if (angular.isDefined(attrs.linkedShowSelection)) {
                el += ' show-selection-button="'+attrs.linkedShowSelection+'"';
              }
              if (angular.isDefined(attrs.linkedShowOnFocus)) {
                el += ' show-on-focus="'+attrs.linkedShowOnFocus+'"';
              }
              if (angular.isDefined(attrs.linkedPaginateBy)) {
                el += ' paginate-by="'+attrs.linkedPaginateBy+'"';
              }
              if (angular.isDefined(attrs.linkedRoute)) {
                el += ' route="'+attrs.linkedRoute+'"';
              }
              if (angular.isDefined(attrs.linkedMinSearch)) {
                el += ' min-search="'+attrs.linkedMinSearch+'"';
              }
              if (angular.isDefined(attrs.collapsible)) {
                el += ' collapsible="'+collapsible+'"';
              } else if (angular.isDefined(attrs.collapsed)) {
                el += ' collapsed="'+attrs.collapsed+'"';
              }
              closingTag = "></div>";
              if (showSelectionButton)
                form_feedback_date = ' form-control-feedback-date';
            } else if (type == "textarea") {
              var attributes = '';
              if (angular.isDefined(attrs.collapsible)) {
                attributes = ' collapsible="'+collapsible+'"';
              } else if (angular.isDefined(attrs.collapsed)) {
                attributes = ' collapsed="'+attrs.collapsed+'"';
              }
              el += '<mctextarea'+attributes+' type="'+type+'" name="'+fieldName+'" id="'+fieldName+'" class="form-control input-sm" ng-model="model" mctooltip-event="focus" mctooltip-placement="top" mctooltip="errorMessages"';
              closingTag = "></mctextarea>";
            } else if (type === 'select') {
              scope.$parent.$watch(attrs.options, function(val) {
                scope.options = val;
              });
              scope.select2changed = function() {
                if (scope.model === '') {
                  scope.model = null;
                }
                if (angular.isDefined(attrs.ngChange)) {
                  scope.ngChange();
                }
              };
              el += '<select ng-options="option.id as option[optionsLookup] for option in options" ng-change="select2changed()" class="input-sm'+inputClass+'" ng-model="model"';
              closingTag = '>';
              if (!angular.isDefined(attrs.ngRequired)) {
                closingTag += '<option value="">---</option>';
              }
              closingTag += '</select>';
              if (angular.isDefined(attrs.showSelectionButton)) {
                closingTag += '<button type="button" tabindex="-1" class="btn btn-primary btn-sm" style="margin-left: 3px; margin-bottom: 5px;" ng-click="onSelectionButton()" mctooltip="\'Otvoriť ponuku (Alt+Enter)\'" mctooltip-placement="bottom"><i class="fa fa-bars"></i></button>';
              }
            } else if (type === 'month') {
              el += '<input type="text" casist-month name="'+fieldName+'" id="'+fieldName+'" class="form-control casist-month input-sm'+inputClass+'" ng-model="model" mctooltip-event="focus" mctooltip-placement="top" mctooltip="errorMessages"';
            } else {
              el += '<input type="'+type+'" name="'+fieldName+'" id="'+fieldName+'" class="'+(type == 'checkbox' ? 'checkbox' : 'form-control')+' input-sm'+inputClass+'" ng-model="model" mctooltip-event="focus" mctooltip-placement="top" mctooltip="errorMessages"';
            }

            if (placeholder)
              el += ' placeholder="'+placeholder+'"';
            // if (scope.linkedField)
            //   el += ' linked-field="linkedField"';
            if (hasRequestedFocus) {
              el += ' request-focus="'+hasRequestedFocus+'"';
            }
            if (angular.isDefined(attrs.unifilter)) {
              el += ' search-utils-unifilter';
            }
            if (attrs.autocomplete)
              el += ' autocomplete="'+attrs.autocomplete+'"';

            var suma = angular.isDefined(attrs.suma);
            if (suma) {
              el += ' input-suma="'+attrs.suma+'"';
              element.attr('suma', null);
              delete attrs.suma;
            }
            if (angular.isDefined(attrs.cislo)) {
              el += ' input-cislo="'+attrs.cislo+'"';
              element.attr('cislo', null);
              delete attrs.cislo;
            }
            if (angular.isDefined(attrs.maxlength)) {
              el += ' maxlength="'+attrs.maxlength+'"';
              element.attr('maxlength', null);
              delete attrs.maxlength;
            }
            if (angular.isDefined(attrs.ngDisabled)) {
              el += ' ng-disabled="ngDisabled"';
            }
            if (angular.isDefined(attrs.ngChange) && type !== 'select') {
              el += ' ng-change="ngChange()"';
            }
            if (angular.isDefined(attrs.ngBlur))
              el += ' ng-blur="onBlur($event)"';
            // if (angular.isDefined(attrs.ngKeypress))
              // el += ' ng-keypress="$parent.'+attrs.ngKeypress+'"';
            if (angular.isDefined(attrs.tabindex)) {
              el += ' tabindex="'+attrs.tabindex+'"';
              element.attr('tabindex', null);
              delete attrs.tabindex;
            }
            if (angular.isDefined(attrs.casistTime)) {
              el += ' casist-time';
              element.attr('casist-time', null);
              delete attrs.casistTime;
            }
            if (angular.isDefined(attrs.readonly)) {
              el += ' readonly';
              element.attr('readonly', null);
              delete attrs.readonly;
            }

            el += closingTag;
            el += '<span class="glyphicon glyphicon-remove form-control-feedback'+form_feedback_date+'" ng-class="errorClass" bo-show="errorMessages"></span>';
          }
          el += '</div>';
          // scope.$watch(attrs.editable, function(val) {
          //   scope.editable = val;
          // });
          var $el = $compile(el)(scope);
          element.html($el);
          parent = $el.parent();
        }
      }
    };
  }]);
