'use strict';

angular.module('casist.utils')
.directive('dropdownFix', ['$window', function($window) {
    return {
      restrict: 'A',
      link: function postLink(sscope, element, attrs) {
        var $parent = $(element).parent();
        if (!$parent.hasClass('searchbar-item')) {
          $parent = $parent.parent();
        }
        var $menu = $parent.find('.dropdown-menu');
        $parent.on('show.bs.dropdown', function() {
          var offset = $parent.position();
          var left = offset.left - $(window).scrollLeft() - element[0].offsetWidth + $parent.width();
          if (left+$menu.width() > $(window).width()) {
            left -= (left+$menu.width() - $(window).width()) + 10;
          }
          var top = offset.top - $(window).scrollTop() + $parent.height();
          if (top + $menu.height() > $(window).height()) {
            top -= $menu.height();
          }
          $menu.css({
              'top': top,
              'left': left,
              'right': 'inherit',
              'position': 'fixed'
          });
        });
      }
    }
  }]);