'use strict';

angular.module('casist.ucto').controller('PrikazyDetailCtrl',
              ['$scope', '$modalInstance', 'doklad', 'list', '$timeout', '$modal', 'Global', '$http', '$controller', 'Profile', 'SearchCtrl', 'Prikaz', 'Doklad', 'Prevadzka', 'Firma', 'Predkontacia', '$q', 'DokladTypy', 'BankAccount', 'Ucet', 'Mena',
              function ($scope, $modalInstance, doklad, list, $timeout, $modal, Global, $http, $controller, Profile, SearchCtrl, Prikaz, Doklad, Prevadzka, Firma, Predkontacia, $q, DokladTypy, BankAccount, Ucet, Mena) {

    $scope.hasList = list.length !== 0;

    $scope.getParovanie = function(query, pageQuery) {
      return Prikaz.parovanie().getList(angular.extend({firma: $scope.doklad.firma, search: query}, pageQuery));
    };
    $scope.clearParovanie = function() {
      $scope.doklad.faktura_field = undefined;
    };
    $scope.selectedFirma = function(data) {
      $scope.doklad.ucet_kredit = data.ucet;
    };
    $scope.selectedParovanie = function(parovanie) {
      $scope.doklad.suma = parseFloat(parovanie.spolu) - parseFloat(parovanie.uhradene);
      copyWeakSrc(_.pick(parovanie, 'firma', 'vs', 'firma_data'), $scope.doklad);
      $scope.doklad.ucet_kredit = $scope.doklad.firma_data.ucet;
      $timeout(function() {
        $('#suma').select();
      });
    };
    $scope.showUcty = function() {
      BankAccount.editUcty($scope.bankaucty).then(function(data) {
        var ucet;
        if (!(ucet = _.find($scope.bankaucty, {id: parseInt($scope.doklad.ucet)}))) {
          if ($scope.bankaucty.length) {
            $scope.doklad.ucet = $scope.bankaucty[0].id;
          } else {
            $scope.doklad.ucet = undefined;
          }
        } else {
          $scope.doklad.ucet = ucet.id;
        }
      });
    };


    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Prikaz
    });

    $scope.setSplatnost = function() {
      $scope.doklad.d_splat = $scope.doklad.d_vyst;
    };

    BankAccount.objects().getList().then(function(data) {
      $scope.bankaucty = data;
      defaultPrikaz.ucet = $scope.bankaucty.length ? $scope.bankaucty[0].id : undefined;
      if (Profile.get('lastUcet') && _.find($scope.bankaucty, {id: Profile.get('lastUcet').id})) {
        defaultPrikaz.ucet = Profile.get('lastUcet').id;
      }
      if (!$scope.doklad.ucet && data.length) {
        $scope.doklad.ucet = data[0].id;
      }
    });

    var prikazDataChanged = function(data) {
      if (!data && !defaultPrikaz.prikaz) {
        Prikaz.objects().getList({page_size: 1, ordering: '-id'}).then(function(data) {
        if (data.length) {
          defaultPrikaz.prikaz = data[0].prikaz;
          if (!$scope.doklad.prikaz) {
            $scope.doklad.prikaz = defaultPrikaz.prikaz;
          }
        }
      });
      }
    };

    var defaultPrikaz = {
      d_vyst: getCurrentDate(),
      d_splat: getCurrentDate()
    };

    if (!isEmpty(doklad)) {
      $scope.doklad = Prikaz.copy(doklad);
    } else {
      $scope.doklad = Prikaz.restangularize(angular.copy(defaultPrikaz));
      prikazDataChanged();
    }

    var dokladPostSave = function(data) {
      Profile.set('lastUcet', data.ucet);
      if (data.zavazok) {
        Doklad.syncChange(data.zavazok_data, data.zavazok_data);
      }
      return data;
    }

    $scope.RecordCtrl.main.init({
      endpoint: 'list',
      model: 'doklad',
      master: doklad,
      list: list,
      defaultItem: defaultPrikaz,
      dataChanged: prikazDataChanged,
      postSave: dokladPostSave,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: doklad});
        if (doklad.zavazok) {
          doklad.zavazok_data.prikaz = null;
          Doklad.syncChange(doklad.zavazok_data, doklad.zavazok_data);
        }
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };
  }]);
