'use strict';

angular.module('casist.sklad')
  .factory('CennikZaznam', [
    'Restangular',
    'BaseModel',
    'BaseCollectionModel',
    '$q',
    '$modal',
    'Profile',
    'Upload',
    function (
      Restangular,
      BaseModel,
      BaseCollectionModel,
      $q,
      $modal,
      Profile,
      Upload
    ) {

    var ENDPOINT = "cennikzaznamy";
    var parent = BaseCollectionModel.object(ENDPOINT);

    var openDetail = function(item, items, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'sklad/cenniky/cenniky-detail.html',
        controller: 'CennikyDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return items || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };

    Restangular.extendModel(ENDPOINT, function(model) {
        angular.extend(model, BaseModel);
        return angular.extend(model, {
          validate: function () {
            var result = BaseModel.validate();
            if (!this.mo) {
              this.addError("text", "Predajná cena nemôže byť prázdna");
              result = false;
            }
            return result;
          },
        });
    });

    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').cenniky;
      },
      get: function (query, page, parentObject, otherParams) {
        return parent.objects().getList(angular.extend(angular.extend({search:query}, otherParams), page));
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },

      getCenniky: function () {
        return Restangular.all('cenniky').customGETLIST();
      },

      getImportConfig: function() {
        return Restangular.all(ENDPOINT).all('import').customGET();
      },

      getPriceItemInCennikForKarta: function (cennikID, kartaID) {
        var params = {
          'cennik': cennikID,
          'karta': kartaID
        };

        return Restangular.all(ENDPOINT).customGETLIST("", params);
      },

      import: function($files, config) {
        var deferred = $q.defer();

        if (!$files.length) {
          return;
        }

        var file = $files[0];

        Upload.upload({
          url: Restangular.all(ENDPOINT).all('import').getRestangularUrl(),
          data: {'fileName': file.name, 'config': JSON.stringify(config), 'includeFile': 1},
          file: file
        }).progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        }).success(function(data) {
          deferred.resolve(data);
        }).error(function (error) {
          deferred.reject(error);
        });

        return deferred.promise;
      },

      importData: function(data) {
        return Restangular.all(ENDPOINT).all('import').post(data, {import: 1});
      },

    });
  }]);
