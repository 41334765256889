'use strict';

angular.module('casist.svb')
  .factory('PrehladNakladov', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = "prehladnakladov";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').prehladnakladov;
      }
    });
  }]);
