'use strict';

angular.module('casist.widgets')
  .directive('mcSelectM2m', ['$http', '$templateCache', '$compile', '$parse', 'Notification', function ($http, $templateCache, $compile, $parse, Notification) {
    return {
      templateUrl: 'components/widgets/select_m2m.html',
      restrict: 'EA',
      replace: true,
      scope: {
        model: '=ngModel',
        dataAll: '=data',
        lookup: '@',
        heading: '@',
        onChange: '&'
      },
      link: function postLink(scope, element, attrs) {
        var deleteCallback = angular.isDefined(attrs.onDelete) ? $parse(attrs.onDelete) : undefined;
        if (deleteCallback) {
          scope.colspan = 3;
        } else {
          scope.colspan = 2;
        }
        scope.vertical = angular.isDefined(attrs.vertical);
        scope.showSort = angular.isDefined(attrs.showSort);
        var tplUrl = (angular.isDefined(attrs.panel) && scope.$eval(attrs.panel)) ? 'components/widgets/select_m2m_panel.html' : 'components/widgets/select_m2m.html';
        $http.get(tplUrl, {cache: $templateCache}).then(function(response){
           element.replaceWith($compile(response.data.trim())(scope));
        });

        scope.animateClass = {};
        if (angular.isDefined(attrs.animateClass)) {
          scope.animateClass[attrs.animateClass] = true;
        }

        if (!angular.isDefined(attrs.selectionLabel)) {
          scope.selectionLabel = 'main.VYBRANE';
        } else {
          scope.selectionLabel = attrs.selectionLabel;
        }
        if (!angular.isDefined(attrs.availableLabel)) {
          scope.availableLabel = 'main.DOSTUPNE';
        } else {
          scope.availableLabel = attrs.availableLabel;
        }

        if (!angular.isDefined(scope.lookup)) {
          scope.lookup = attrs.ngModel;
        }
        scope.collapsible = angular.isDefined(attrs.collapsible) ? scope.$eval(attrs.collapsible) : false;
        scope.collapsed = angular.isDefined(attrs.collapsed) ? scope.$eval(attrs.collapsed) : false;

        scope.data = angular.copy(scope.dataAll);
        var dataAll = angular.copy(scope.dataAll);
        scope.$watch('dataAll', function(newData) {
          scope.data = newData;
          dataAll = angular.copy(newData);
          if (scope.data && scope.model) {
            var item;
            for(var i = 0; i < scope.model.length; i++) {
              item = _.find(scope.data, {id: scope.model[i].id});
              if (item) {
                scope.data.splice(scope.data.indexOf(item), 1);
              }
            }
          }
        });
        scope.$watch('model', function(val, oldVal) {
          if (scope.data) {
            if (oldVal) {
              // put back to all data
              var item;
              for(var i = 0; i < oldVal.length; i++) {
                if (!_.find(scope.data, {id: oldVal[i].id})) {
                  item = _.find(dataAll, {id: oldVal[i].id});
                  if (item) {
                    scope.data.push(angular.copy(item));
                  }
                }
              }
            }
            if (val) {
              var item;
              for(var i = 0; i < val.length; i++) {
                item = _.find(scope.data, {id: val[i].id});
                if (item) {
                  scope.data.splice(scope.data.indexOf(item), 1);
                }
              }
            }
          }
        });

        scope.addSelection = function(item) {
          if (!angular.isDefined(scope.model)) {
            scope.model = [];
          }
          scope.model.splice(_.sortedIndex(scope.model, item, scope.lookup), 0, item);
          scope.data.splice(scope.data.indexOf(item), 1);
          if (angular.isDefined(scope.onChange)) {
            scope.onChange();
          }
        };

        scope.deleteItem = function(item) {
          if (deleteCallback) {
            if (!window.confirm('Naozaj chcete vymazať tento záznam?')) {
              return;
            }
            var promise = deleteCallback(scope.$parent, {item: item});
            if (promise) {
              promise.then(function() {
                scope.data.splice(scope.data.indexOf(_.find(scope.data, {id: item.id})), 1);
              }, function(error) {
                if (error.data && error.data.error === 'Item is in use') {
                  Notification.add('Na tento záznam sa odvolávajú iné záznamy a preto sa nedá vymazať.', 'danger', 5);
                } else {
                  Notification.add('Záznam sa nepodarilo vymazať.', 'danger', 5);
                }
              });
            }
          }
        };

        scope.removeSelection = function(item) {
          scope.data.splice(_.sortedIndex(scope.data, item, scope.lookup), 0, _.find(dataAll, {id: item.id}));
          scope.model.splice(scope.model.indexOf(item), 1);
          if (angular.isDefined(scope.onChange)) {
            scope.onChange();
          }
        };

        scope.moveUp = function(item) {
          var idx = scope.model.indexOf(item);
          if (idx > 0) {
            scope.model.splice(idx - 1, 0, scope.model.splice(idx, 1)[0]);
          }
        };

        scope.moveDown = function(item) {
          var idx = scope.model.indexOf(item);
          if (idx < scope.model.length - 1) {
            scope.model.splice(idx + 1, 0, scope.model.splice(idx, 1)[0]);
          }
        };
      }
    };
  }]);
