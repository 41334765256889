'use strict';

angular.module('casist.utils')
  .filter('jednoducheUctoLabels', ['Profile', function (Profile) {
    return function (input) {
      if (!input || !Profile.get('jednoducheUcto')) {
        return input;
      }
      var replaceMap = {
        'fakturacia.PREDKONTACIA': 'penaznydennik.UCEL',
        'fakturacia.ROZDIELMDD': 'penaznydennik.ROZDIELMDD',
        'ucto.SUMAMD': 'Daň./Nedaň.',
        'ucto.SUMAD': 'Členenie',
        'uctdennik.SUMAD': 'Suma',
        'uctdennik.UCET': 'Členenie',
        'ucto.UCTOVANIE_MD': 'Daň./Nedaň.',
        'ucto.UCTOVANIE_D': 'Členenie',
        'uctdennik.UCTDENNIK': 'Členenie úhrad',
        'uctosnova.UCTOSNOVA': 'Členenie sumárne',
        'uctosnova.KSMD_SHORT': "Daň./Nedaň.",
        'uctosnova.KSD_SHORT': "Členenie"
      };
      angular.forEach(replaceMap, function(value, key) {
        input = input.replace(key, value);
      });
      return input;
    };
  }]);
