"use strict";

angular.module("casist.sklad").factory("Faktura", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  "Upload",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate,
    Upload
  ) {
    var ENDPOINT = "faktury";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst == "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma == "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          return result;
        },
        listUhradene: function () {
          return this.getList("uhrady");
        },
        prepocetUhrad: function () {
          return this.all("uhrady").all("prepocet").post({});
        },
      });
    });

    var openDetail = function (templateUrl, item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: "FakturaciaDetailCtrl",
        backdrop: "static",
        keyboard: false,
        windowClass: "detail",
        resolve: {
          faktura: function () {
            return item ? item : {};
          },
          faktury: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return angular.extend({ predkontacie: true, ucty: true }, config);
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    var parent_copy = angular.copy(BaseCollectionModel.object(ENDPOINT));
    return angular.extend(parent, {
      stripRestangular: function (obj) {
        return parent_copy.stripRestangular(obj, [
          "listUhradene",
          "prepocetUhrad",
        ]);
      },
      getPerms: function () {
        var perms = Profile.get("perms").faktura || {};
        angular.forEach(Profile.get("perms").pohladavka, function (val, key) {
          if (val) {
            perms[key] = val;
          }
        });
        return perms;
      },
      getUctoFields: function () {
        return [
          "zaklad_d1",
          "d1",
          "zaklad_d2",
          "d2",
          "zaklad_d0",
          "spolu",
          "suma1",
          "suma2",
          "suma3",
          "suma4",
          "suma5",
        ];
      },
      editPohladavka: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          this.restangularize(doklad);
        }
        openDetail(
          "ucto/pohladavky/pohladavky-detail.html",
          doklad,
          list,
          searchCtrl,
          config
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      prepocetUhrad: function (datum, query, canceller) {
        if (canceller) {
          return Restangular.all("faktury")
            .all("prepocetuhrad")
            .withHttpConfig({ timeout: canceller })
            .post({ datum: datum }, query);
        } else {
          return Restangular.all("faktury")
            .all("prepocetuhrad")
            .post({ datum: datum }, query);
        }
      },
      globalneUctovanie: function (predkontacia, query, canceller) {
        if (canceller) {
          return Restangular.all("faktury")
            .withHttpConfig({ timeout: canceller })
            .customPUT(
              { predkontacia: predkontacia },
              "globalneuctovanie",
              query
            );
        } else {
          return Restangular.all("faktury").customPUT(
            { predkontacia: predkontacia },
            "globalneuctovanie",
            query
          );
        }
      },
      getImportConfig: function () {
        return Restangular.all(ENDPOINT).all("import").customGET();
      },
      import: function ($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all("import").getRestangularUrl(),
          data: { fileName: file.name, config: JSON.stringify(config) },
          file: file,
        })
          .progress(function (evt) {
            deferred.notify(parseInt((100.0 * evt.loaded) / evt.total));
          })
          .then(
            function (data) {
              deferred.resolve(data.data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
      sendEmails: function (ids) {
        return Restangular.all(ENDPOINT).all('send-emails').post({faktura_ids: ids});
      },
      updateBulk: function (faktury) {
        var promises = []
        _.each(faktury, function (faktura) {
          promises.push(
            Restangular.all(ENDPOINT).all(faktura.id).customPUT(faktura)
          )
        });
        return $q.all(promises);
      },
      importData: function (data) {
        return Restangular.all(ENDPOINT)
          .all("import")
          .post(data, { import: 1 });
      },
      createOrderShipment: function (faktura, shipmentType) {
        return Restangular.all(ENDPOINT).all("shipment").all(shipmentType).post(faktura);
      },
      createOrderShipments: function (faktury, shipmentType) {
        var promises = [];
        _.each(faktury, function (faktura) {
          promises.push(
            Restangular.all(ENDPOINT).all("shipment").all(shipmentType).post(faktura)
          );
        });
        return $q.all(promises);
      },
      sendToReFIS: function (faktury) {
        var promises = [];
        _.each(faktury, function (faktura) {
          promises.push(
            Restangular.all(ENDPOINT).all("refis").post(faktura)
          );
        });
        return $q.all(promises);
      },
      editFaktura: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          this.restangularize(doklad);
        }
        openDetail(
          "sklad/fakturacia/fakturacia-detail.html",
          doklad,
          list,
          searchCtrl,
          config
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      edit: function (doklad) {
        return this.editPohladavka(doklad);
      },
    });
  },
]);

angular.module("casist.sklad").factory("FakturyTyp", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "Dialog",
  function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel("fakturytypy", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var result = BaseModel.validate();
          if (!this.typ) {
            this.addError("typ", "Pole nemôže byť prázdne");
            result = false;
          }
          return result;
        },
      });
    });
    var parent = BaseCollectionModel.object("faktury/fakturytypy");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").faktura;
      },
      get: function (query) {
        return parent.objects().getList({ search: query });
      },
      add: function (typ) {
        var promise = parent.create({ typ: typ });
        promise.then(
          function (result) {
            console.log("Typ added: ", result);
          },
          function (error) {
            console.log("Chyba: ", error);
          }
        );
        return promise;
      },
      edit: function (typ) {
        var deferred = $q.defer();
        Dialog.prompt("Typ", "Upraviť typ", typ.typ).then(
          function (str) {
            typ.typ = str;
            typ.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      remove: function (typ) {
        console.log("Remove typ: ", typ);
        return typ.remove();
      },
    });
  },
]);
