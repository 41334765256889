'use strict';
declare var angular: angular.IAngularStatic;

import { isEmpty } from './components/utils/utils-helpers';
import * as _ from 'lodash';

angular.module('casist')
  .controller('MainCtrl', ['$rootScope', '$scope', 'Global', '$translate', '$location', 'TabService', '$timeout', 'Auth', 'Restangular', 'Socket', 'Notification', 'Permission', '$sessionStorage', 'Dialog', '$state',
    function($rootScope, $scope, Global, $translate, $location, TabService, $timeout, Auth, Restangular, Socket, Notification, Permission, $sessionStorage, Dialog, $state) {
      $scope.lang = 'sk';
      $scope.isAuth = (Global.get('serverName') !== null && !isEmpty(Auth.profile().get('agendy')));

      if ($scope.isAuth) {
        $scope.slideAnimation = true;
      }
      $scope.countWatchers = function() {
        var q = [$rootScope], watchers = 0, scope;
        while (q.length > 0) {
          scope = q.pop();
          if (scope.$$watchers) {
            watchers += scope.$$watchers.length;
          }
          if (scope.$$childHead) {
            q.push(scope.$$childHead);
          }
          if (scope.$$nextSibling) {
            q.push(scope.$$nextSibling);
          }
        }
        return watchers;
      };
      $scope.alerts = Notification.notifications();

      $scope.closeAlert = function(index) {
        Notification.close(index);
      };

      $scope.$on('event:auth-loginConfirmed', function() {
        if (!isEmpty(Auth.profile().get('agendy'))) {
          $scope.isAuth = true;
        }
        setAgendy();
        setObdobia();
        $scope.user = Auth.profile().data().userData;
      });
      $scope.windows = {
        tabs: TabService.loadWorkspace(Auth.profile().get('workspace')),
      };
      $scope.$on('event:auth-loginRequired', function() {
        $scope.isAuth = false;
      });

      // for mobile display
      var closeToggleNav = function() {
        if ($('.navbar-toggle').css('display') === 'none') {
          return;
        }
        $timeout(function() {
          $('.navbar-toggle').trigger('click');
        });
      };
      $scope.closeToggleNav = closeToggleNav;

      var setAgendy = function() {
        $scope.agendy = Auth.profile().get('agendy');
        $scope.agendyExternal = Auth.profile().get('agendyExternal');
        $scope.agenda = Auth.profile().get('agenda');
      };
      Auth.profile().addObserver('agenda', setAgendy);
      Auth.profile().addObserver('agendy', setAgendy);

      var setObdobia = function() {
        $scope.obdobia = Auth.profile().get('obdobia');
        if ($scope.obdobia && $scope.obdobia.length) {
          $scope.obdobie = (Auth.profile().get('obdobie') && _.find($scope.obdobia, { rok: Auth.profile().get('obdobie') })) ? { rok: Auth.profile().get('obdobie') } : $scope.obdobia[0];
          // update profile value only if it's changed, to prevent firing unwanted handlers
          if (Auth.profile().get('obdobie') !== $scope.obdobie.rok) {
            Auth.profile().set('obdobie', $scope.obdobie.rok);
          }
        }
      };
      Auth.profile().addObserver('obdobia', setObdobia);
      Auth.profile().addObserver('obdobie', setObdobia);
      Socket.on("obdobiaCreated", function(data) {
        var obdobia = Auth.profile().get('obdobia');
        obdobia.push(data);
        Auth.profile().set('obdobia', obdobia);
      });

      $scope.windows = {};
      $scope.windows.tabs = TabService.getTabs();

      $scope.windows.closeTab = function(index) {
        $scope.windows.tabs = TabService.closeTab(index);
        TabService.saveWorkspace();
      };
      $scope.isAt = function(location) {
        if (location === '/') {
          if ($location.path().match('/(priestory|odpocty|vyuctovanie|vlastnici)')) {
            return true;
          }
        }
        return $location.path() === location;
      };
      $scope.toHomeIfNecessary = function() {
        if (!$scope.isAt('/')) {
          $location.path('/');
        }
      };
      $scope.currentTabChanged = function(activeTab) {
        TabService.tabActivated(activeTab);
        $timeout(function() {
          TabService.saveWorkspace();
        });
      };
      $scope.openTab = function(id, title, url) {
        $scope.windows.tabs = TabService.setTabContent(id, title, url);
        TabService.saveWorkspace();
      };
      $scope.openNewTab = function() {
        $scope.windows.tabs = TabService.newTab();
      };
    }]);
