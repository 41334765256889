'use strict';

angular.module('casist.svb')
  .factory('Odpocet', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Upload', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Upload) {
    var sposoby = [
      {id: 1, sposob: 'Odpočet'},
      {id: 2, sposob: 'Odhad'},
      {id: 3, sposob: 'Priemer'}//,
      //{id: 4, sposob: 'Údaj z minulého roku'}
    ];
  Restangular.extendModel('odpocty', function(model) {
    angular.extend(model, BaseModel);
    return angular.extend(model, {
      validate: function() {
        //if (!this.meno) {
        //  this.addError('meno', 'Pole meno je povinné.');
        //  return false;
        //}
        return BaseModel.validate();
      },
      getSposobText: function() {
        return _.find(sposoby, {id: parseInt(this.sposob)}).sposob;
      }
    });
  });

  var parent = BaseCollectionModel.object('odpocty');
  return angular.extend(parent, {
    getPerms: function() {
      return Profile.get('perms').odpocetmeraca;
    },
    get: function (query) {
      return parent.objects().getList({search:query});
    },
    getQuery: function(params) {
      return {
        vchod: params.vchod,
        datum__gte: Profile.get('obdobie', new Date().getFullYear())+'-01-01',
        datum__lte: Profile.get('obdobie', new Date().getFullYear())+'-12-31',
        ordering: 'datum,priestor__nazov'
      };
    },
    getSposob: function(sposob) {
      if (sposob) {
        return _.find(sposoby, {id: sposob});
      } else {
        return sposoby;
      }
    },
    getPriestory: function(query) {
      return Restangular.all('odpocty').all('odpocetpriestory').getList(query);
    },
    getImportConfig: function() {
      return Restangular.all('odpocty').all('import').customGET();
    },
    import: function($files, config) {
      var deferred = $q.defer();
      if (!$files.length) {
        return;
      }
      var file = $files[0];
      Upload.upload({
        url: Restangular.all('odpocty').all('import').getRestangularUrl(),
        data: {"fileName" : file.name, 'config': JSON.stringify(config)},
        file: file
      }).progress(function(evt) {
        deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
      }).then(function(data) {
        deferred.resolve(data.data);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    },
    importData: function(data) {
      return Restangular.all('odpocty').all('import').post(data, {import: 1});
    }
  });
}]);
