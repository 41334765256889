'use strict';

angular.module('casist.sklad')
  .factory('Mena', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    Restangular.extendModel('meny', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.mena) {
            this.addError('mena', 'Názov meny nemôže byť prázdny!');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('settings/meny');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').faktura;
      },
      edit: function (mena) {
        var str = null;
        if ( (str = window.prompt("Upravit menu:", mena.mena)) ) {
          mena.mena = str;
          return mena.update();
        }
      },
      add: function(mena) {
        var promise = parent.create({mena: mena});
        promise.then(function (result) {
          console.log('Mena added: ', mena);
        }, function (error) {
          console.log('Chyba: ', error);
        });
        return promise;
      },
      remove: function (mena) {
        console.log("Remove mena: ", mena);
        return mena.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);