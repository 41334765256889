'use strict';

angular.module('casist.auth')
  .controller('ActivateCtrl', ['$scope', '$location', '$http', 'Notification', '$window', function ($scope, $location, $http, Notification, $window) {
    if ($scope.isAuth) {
      $location.path("/logout");
    }
    var search = $location.search();
    if (!search.token || !search.email) {
      $location.path('/login');
    }
    $scope.activation = {
      token: search.token,
      username: search.email
    };
    $scope.errors = {};

    $scope.sendActivation = function() {
      $scope.errors = {};
      var required = ['password', 'password_check'];

      if ($scope.activation.password) {
        if ($scope.activation.password != $scope.activation.password_check)
          $scope.errors['password_check'] = ["Heslá sa nerovnajú!"];
        if ($scope.activation.password.length < 8)
          $scope.errors['password'] = ["Heslo musí mať aspoň 8 znakov!"];
      }
      for (var i in required) {
        var key = required[i];
        if (!$scope.activation[key])
          $scope.errors[key] = ["Pole nemôže byť prázdne."];
      }
      if (isEmpty($scope.errors)) {
        $scope.inProgress = true;
        var data = angular.copy($scope.activation);
        delete data.password_check;
        $http.post($window.location.protocol+'//'+constants.applicationServer+'/settings/users/activate', data).then(function(_response) {
          Notification.add('Aktivácia prebehla úspešne, môžete sa prihlásiť.', 'success', 5);
          $scope.inProgress = false;
          $location.url("/login");
        }, function(error) {
          Notification.add('Pri aktivácii nastala chyba: '+(error.error ? error.error : error), 'danger');
          $scope.inProgress = false;
        });
      } else {
        $scope.$broadcast('errorsRefresh');
      }
    };
  }]);
