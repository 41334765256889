'use strict';

angular.module('casist.sklad').controller('NakupCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'Nakup', 'NakupTyp', 'NakupUcel', 'Firma', 'Outputs', 'DokladTypy', 'TabService', 'Doklad', 'Notification', 'Dialog',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, Nakup, NakupTyp, NakupUcel, Firma, Outputs, DokladTypy, TabService, Doklad, Notification, Dialog) {

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {
    vybavene: 'False'
  };
  $scope.nakupy = [];
  $scope.vybaveneTypy = [
    {id: 'False', vybavene: 'Nevybavené'},
    {id: 'True', vybavene: 'Vybavené'},
    {id: 0, vybavene: 'Všetko'}
  ];
  var filterChanged = function(filter) {
    var promise = Nakup.objects().getList(filter);
    $scope.nakupy.loading = true;
    promise.then(function(data) {
      TabService.registerScope('nakupy', $scope);
      $scope.nakupy = data;
      for (var i = 0; i < data.length; i++) {
        Firma.restangularize($scope.nakupy[i].firma_data);
      }
    });
    if (filter.zahranicne) {
      $scope.setCurrentView(1);
    }
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    loadPrevadzky();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.nakuptypy = NakupTyp.objects().getList().$object;
  $scope.ucely = [];
  NakupUcel.objects().getList().then(function(data) {
    angular.forEach(data, function(obj) {
      $scope.ucely.push({id: obj.id, text: obj.typ ? obj.typ_name + '/' + obj.text : obj.text});
    });
  });

  var coreFields = [
    {},
    {},
    { field: 'typ', label: 'zavazky.TYP', filterCollapse: 'right', select2: { source: 'nakuptypy', lookup: 'typ' } },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'd_zdp', label: 'ucto.ISSUED', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
  ];

  $scope.nakupyTable = [
    _.union(coreFields, [
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70 },
      { field: 'zlava', filterCollapse: 'left', label: 'ucto.ZLAVA', type: 'number' },
      { field: 'naklady', filterCollapse: 'left', label: 'ucto.NAKLADY', type: 'number' },
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'spolu_zavazky', label: 'ucto.SPOLU_ZAVAZKY', type: 'number', sum: true },
      { field: 'rozdiel_zavazky', label: 'ucto.ROZDIEL_ZAVAZKY', type: 'number', sum: true },
    ]),
    _.union(coreFields, [
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
      { field: 'mena', label: 'ucto.MENA', width: 90 },
      { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
      { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
      { field: 'dodaci_list', label: 'nakup.DODACI_LIST', filterType: 'icontains' },
      { field: 'doprava', label: 'ucto.DOPRAVA', filterType: 'icontains' },
      { field: 'cislo_fa', label: 'nakup.CISLO_FA', filterType: 'icontains' },
      { field: 'poznamka', label: 'fakturacia.POZNAMKA', filterType: 'icontains' }
    ]),
  ];
  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'uplat_dph', 'typ_dph__typ', 'sd1', 'sd2'];
    var idx = $scope.nakupyTable[0].indexOf(_.find($scope.nakupyTable[0], {field: 'zaklad_d1'}));
    $scope.nakupyTable[0] = _.reject($scope.nakupyTable[0], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    $scope.nakupyTable[1] = _.reject($scope.nakupyTable[1], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    var moved = ['dodaci_list', 'doprava', 'cislo_fa'];
    var movedItems = _.filter($scope.nakupyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
    for (var i = movedItems.length-1; i >= 0; i--) {
      $scope.nakupyTable[0].splice(idx, 0, movedItems[i]);
    }
    $scope.nakupyTable[1] = _.reject($scope.nakupyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
  }

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
  };
  loadPrevadzky();

  $scope.create = function(item) {
    Nakup.edit(item, $scope.nakupy, $scope.SearchCtrl);
  };
  $scope.copy = function(item) {
    var copied = Nakup.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'vs', 'd_vyst', 'd_zdp', 'd_splat', 'd_uctn', 'uplat_dph', 'parovanie', 'parovanie_data', 'faktura', 'faktura_data'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };
  $scope.fakturacia = function(nakup) {
    if (nakup.faktura) {
      TabService.bus.send('zavazky', 'nakupy', {iddokd: nakup.faktura});
      TabService.openTab('zavazky');
    } else {
      nakup.fakturacia().then(function(data) {
        Notification.add('Záznam bol vyfaktúrovaný.', 'success', 5);
        Doklad.syncCreate(data);
        nakup.faktura = data.iddokd;
        nakup.vybavene = true;
        Doklad.syncChange(nakup);
        $scope.SearchCtrl.broadcastRefresh('nakupy');
        TabService.bus.send('zavazky', 'nakupy', {iddokd: data.iddokd});
        TabService.openTab('zavazky');
      }, function(error) {
        var errorMsg = '';
        if (error.data && error.data.error) {
          errorMsg = ' '+error.data.error;
        }
        Notification.add('Nepodarilo sa vyfaktúrovať záznam.'+errorMsg, 'danger', 7);
      });
    }
  };
  var events = Nakup.registerEvents(function() { return $scope.nakupy; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Nakup.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Nakup,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.nakupyTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh();

  $scope.ucelChanged = function() {
    $scope.SearchCtrl.filterSelection('ucel', $scope.SearchCtrl.filters.top.ucel);
  };

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.nakupyTable[view]);
  };

  $scope.print = function(faktura) {
    Outputs.openPrintUrl('nakupy', faktura.id);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('nakupy', null, $scope.SearchCtrl.getQuery());
  };
  $scope.openImport = function() {
    var modalInstance = $modal.open({
      templateUrl: 'core/import/import.html',
      controller: 'GenericImportCtrl',
      backdrop: 'static',
      windowClass: 'detail',
      resolve: {
        list: function() {
          return $scope.nakupy;
        },
        model: function() {
          return Nakup;
        }
      }
    });
    modalInstance.result.then(function(data) {
      Notification.add('Záznamy boli aktualizované.', 'success', 5);
      $scope.SearchCtrl.refresh();
    });
  };
}]);
