'use strict';

angular.module('casist.ucto')
  .factory('UctOsnova', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Upload', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Upload) {
    var ENDPOINT = "uctosnova";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.ucet_data || !this.ucet_data.ucet) {
            this.addError('ucet', 'Pole účet je povinné.');
            res = false;
          }
          return res;
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ucto/uctosnova/uctosnova-detail.html',
        controller: 'UctOsnovaDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail-sm',
        resolve: {
          ucet: function() {
            return item ? item : {};
          },
          ucty: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').uctosnova;
      },
      prepocetHlavnaKniha: function(query) {
        return Restangular.all('hlavnakniha').all('prepocet').post({}, _.omit(query, 'page', 'page_size'));
      },
      uzavierka: function(query) {
        return Restangular.all('uctosnova').all('uzavierka').post({}, query);
      },
      getImportConfig: function() {
        return Restangular.all(ENDPOINT).all('import').customGET();
      },
      import: function($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all('import').getRestangularUrl(),
          data: {"fileName" : file.name, 'config': JSON.stringify(config)},
          file: file
        }).progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        }).then(function(data) {
          deferred.resolve(data.data);
        }, function (error) {
          deferred.reject(error);
        });
        return deferred.promise;
      },
      importData: function(data) {
        return Restangular.all(ENDPOINT).all('import').post(data, {import: 1});
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      }
    });
  }]);
