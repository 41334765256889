'use strict';

angular.module('casist.core')
  .constant('DokladTypy', {
      'VF': {dd: 1, name: 'Vydané faktúry', shortName: 'VF', operacia: 1, ucto: true},
      'PO': {dd: 6, name: 'Ponuky', shortName: 'PO', operacia: 1},
      'NA': {dd: 8, name: 'Nákup', shortName: 'NA', operacia: -1},
      'ZF': {dd: 7, name: 'Zálohové faktúry', shortName: 'ZF', operacia: 1},
      'OP': {dd: 13, name: 'Ostatné pohľadávky', shortName: 'OP', operacia: 1, ucto: true},
      'DF': {dd: 2, name: 'Došlé faktúry', shortName: 'DF', operacia: -1, ucto: true},
      'OZ': {dd: 14, name: 'Ostatné záväzky', shortName: 'OZ', operacia: -1, ucto: true},
      'PD': {dd: 3, name: 'Pokladničné doklady', shortName: 'PD', ucto: true},
      'BD': {dd: 4, name: 'Bankové doklady', shortName: 'BD', ucto: true},
      'RP': {dd: 16, name: 'Registračná pokladňa', shortName: 'RP', operacia: 1},
      'UB': {dd: 17, name: 'Ubytovanie', shortName: 'UB'},
      'IN': {dd: 5, name: 'Interné doklady', shortName: 'IN', ucto: true}//,
      // 'ID': {dd: 15, name: 'Interné doklady s DPH', shortName: 'ID'}
    }
  )
  .constant('DokladTypyPrijem', [2, 14, 8])
  .constant('DokladTypyBezDPH', [13, 14, 5]);
