'use strict';

angular.module('casist.ucto')
  .controller('UctovnaZavierkaCtrl', ['$scope', '$modalInstance', 'query', 'Dialog', '$q', 'Outputs', 'typ', function($scope, $modalInstance, query, Dialog, $q, Outputs, typ) {
    $scope.calendarOpened = false;
    var today = new Date();
    $scope.data = {
      obdobieOd: getCurrentDate(new Date(query.rok || today.getFullYear(), 0, 1)),
      obdobieDo: getCurrentDate(new Date(query.rok || today.getFullYear(), 11, 31))
    };

    var cancelPromise = $q.defer();
    $scope.ok = function() {
      Outputs.openXMLExportUrl('hlavnakniha', typ, angular.extend(query, {od: $scope.data.obdobieOd, do: $scope.data.obdobieDo}));
      $modalInstance.close($scope.data);
    };
    $scope.cancelRequest = function() {
      cancelPromise.resolve();
      $scope.inProgress = false;
    };
    $scope.cancel = function() {
      if ($scope.inProgress) {
        $scope.cancelRequest();
      }
      $modalInstance.dismiss('cancelled');
    };
  }]);
