'use strict';

angular.module('casist.ucto')
  .controller('UctOsnovaCtrl', ['$scope', 'Restangular', 'Ucet', 'UctOsnova', 'Profile', '$controller', '$modal', '$q', 'TabService', 'PenaznyDennik', 'Outputs', 'Notification', function ($scope, Restangular, Ucet, UctOsnova, Profile, $controller, $modal, $q, TabService, PenaznyDennik, Outputs, Notification) {
    $scope.osnova = [];

    $scope.create = function(item) {
      return UctOsnova.edit(item, $scope.osnova, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
      var copied = UctOsnova.copy(item);
      _.each(['id', 'ksmd', 'ksd', 'm01md', 'm01d', 'm02md', 'm02d', 'm03md', 'm03d', 'm04md', 'm04d', 'm05md', 'm05d', 'm06md', 'm06d', 'm07md', 'm07d', 'm08md', 'm08d', 'm09md', 'm09d', 'm10md', 'm10d', 'm11md', 'm11d', 'm12md', 'm12d'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };

    $scope.slideAnimation = true;

    $scope.SearchCtrl = $controller('SearchCtrl');

    var filterNulove = function(filter) {
      return angular.extend(filter, {nulove: true})
    };

    var filterChanged = function(filter) {
      var promise = UctOsnova.objects().getList(filterNulove(filter));
      $scope.osnova.loading = true;
      promise.then(function(data) {
        TabService.registerScope('uctosnova', $scope);
        angular.forEach(data, function(val) {
          val.ksmd = parseFloat(val.ksmd);
          val.ksd = parseFloat(val.ksd);
          if (val.ksmd > val.ksd) {
            val.ksmd = val.ksmd - val.ksd;
            val.ksd = 0;
          } else if (val.ksd > val.ksmd) {
            val.ksd = val.ksd - val.ksmd;
            val.ksmd = 0;
          } else {
            val.ksmd = 0;
            val.ksd = 0;
          }
        });
        $scope.osnova = data;
      });
      return promise;
    };
    $scope.prepocetProgress = false;
    $scope.prepocet = function() {
      $scope.prepocetProgress = true;
      UctOsnova.prepocetHlavnaKniha(filterNulove($scope.SearchCtrl.getQuery())).then(function(data) {
        $scope.prepocetProgress = false;
        $scope.osnova = data.results;
        Notification.add('Prepočet členenia prebehol úspešne.', 'success', 5);
      }, function() {
        $scope.prepocetProgress = false;
        Notification.add('Nastala chyba pri prepočte členenia.', 'danger', 5);
      });
    };

    var setObdobia = function() {
      $scope.obdobia = Profile.get('obdobia');
      if ($scope.obdobia && $scope.obdobia.length) {
        $scope.obdobie = (Profile.get('obdobie') && _.find($scope.obdobia, {rok: Profile.get('obdobie')})) ? {rok: Profile.get('obdobie')} : $scope.obdobia[0];
      }
    };
    Profile.addObserver('obdobia', setObdobia);
    setObdobia();

    var defaultFilter = {
      rok: $scope.obdobie ? $scope.obdobie.rok : undefined
    };
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.osnovaTable,
      sort: {sort: Profile.get('jednoducheUcto') ? 'rju,ucet__operacia' : 'ucet__ucet'},
      defaultFilter: defaultFilter
    });

    $scope.SearchCtrl.filterSelection('rok', $scope.obdobie.rok, false);
    $scope.SearchCtrl.refresh();

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.operacie = [
      {id: '1', operacia: '+'},
      {id: '-1', operacia: '-'}
    ];

    $scope.osnovaTable = [
      {  },
      { field: 'ucet__ucet', label: 'ucty.UCET', filterCollapse: 'right', width: 100 },
      { field: 'ucet__nazov', label: 'ucty.NAZOV' },
      { field: 'ucet__operacia', label: 'pokladna.OPERACIA', width: 50, select2: { source: 'operacie', lookup: 'operacia' } },
      { field: 'psmd', label: 'uctosnova.PSMD_SHORT', type: 'number', sum: true},
      { field: 'psd', label: 'uctosnova.PSD_SHORT', type: 'number', sum: true},
      { field: 'ksmd', label: 'uctosnova.KSMD_SHORT', searchable: false, sortable: false, type: 'number', sum: true},
      { field: 'ksd', label: 'uctosnova.KSD_SHORT', searchable: false, sortable: false, type: 'number', sum: true},
      { field: 'rsa', label: 'ucty.RSA_SHORT', tooltip: 'ucty.RSA'},
      { field: 'rsp', label: 'ucty.RSP_SHORT', tooltip: 'ucty.RSP'},
      { field: 'rv', label: 'ucty.RV_SHORT', tooltip: 'ucty.RV'},
      { field: 'rju', label: 'ucty.RJU_SHORT', tooltip: 'ucty.RJU'},
      { field: 'rdp', label: 'ucty.RDP_SHORT', tooltip: 'ucty.RDP'}
    ];
    $scope.tableWidth = 1300;
    var filtered = [];
    if (Profile.get('jednoducheUcto')) {
      filtered = ['rsa', 'rsp', 'rv', 'psmd', 'psd', 'ksmd'];
      $scope.tableWidth = 800;
    } else {
      filtered = ['rju', 'ucet__operacia'];
    }
    $scope.osnovaTable = _.reject($scope.osnovaTable, function(obj) { return filtered.indexOf(obj.field) !== -1; });

    var refreshData = function() {
      $scope.SearchCtrl.refresh();
    };
    var events = UctOsnova.registerEvents(function() { return $scope.osnova; }, function() { return $scope.SearchCtrl; });
    _.union(events, Ucet.registerCustomEvents(refreshData));
    _.union(events, PenaznyDennik.registerCustomEvents(refreshData, refreshData, refreshData));
    $scope.$on('$destroy', function() {
      UctOsnova.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    $scope.printList = function() {
      Outputs.openPrintUrl('uctosnova', null, $scope.SearchCtrl.getQuery());
    };

    $scope.exportData = function() {
      Outputs.openXLSExportUrl('uctosnova', $scope.SearchCtrl.getQuery());
    };
    $scope.openImport = function() {
      var modalInstance = $modal.open({
        templateUrl: 'core/import/import.html',
        controller: 'GenericImportCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          list: function() {
            return $scope.osnova;
          },
          model: function() {
            return UctOsnova;
          }
        }
      });
      modalInstance.result.then(function(data) {
        Notification.add('Záznamy boli aktualizované.', 'success', 5);
        $scope.SearchCtrl.refresh();
      });
    };

  }]);
