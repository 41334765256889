'use strict';

angular.module('casist.sklad')
  .controller('PohybCtrl', ['$scope', 'Restangular', 'Pohyb', 'Profile', 'Karta', 'Socket', 'KartaTyp', 'Sklad', 'Pokladna', '$controller', 'TabService', 'Outputs', '$modal', 'Notification', 'Firma', 'Doklad', 'Skupina', 'Podskupina', 'Druh', function ($scope, Restangular, Pohyb, Profile, Karta, Socket, KartaTyp, Sklad, Pokladna, $controller, TabService, Outputs, $modal, Notification, Firma, Doklad, Skupina, Podskupina, Druh) {
    $scope.SearchCtrl = $controller('SearchCtrl');

    var defaultPeriod = {
    'datum__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'datum__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
    $scope.pohyb = [];
    var filterChanged = function(filter) {
      var promise = Pohyb.objects().getList(filter);
      $scope.pohyb.loading = true;
      promise.then(function(data) {
        TabService.registerScope('pohyb', $scope);
        $scope.pohyb = data;
      });
      return promise;
    };
    $scope.kartatypy = KartaTyp.objects().getList().$object;
    $scope.sklady = Sklad.objects().getList().$object;
    $scope.skupiny = Skupina.objects().getList().$object;
    $scope.podskupiny = Podskupina.objects().getList().$object;
    $scope.druhy = Druh.objects().getList().$object;
    $scope.pokladne = _.filter(Pokladna.restangularize(angular.copy(Profile.get('pokladne'))), {active: true, typ: 2});

    $scope.Firma = Firma;
    $scope.pohybTable = [
      { },
      { selectAllAddon: true },
      { field: 'datum', label: 'ucto.ISSUED', type: 'date', filterCollapse: 'right' },
      { field: 'iddokd', sortField: 'doklad__dd,doklad__iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
      { field: 'typ', label: 'karty.TYP', filterCollapse: 'right', select2: { source: 'kartatypy', lookup: 'typ' } },
      { field: 'kod', label: 'karty.KOD' },
      { field: 'karta__kod_ean', label: 'karty.KOD_EAN' },
      { field: 'nazov', label: 'karty.NAZOV' },
      { field: 'doklad__firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'sklad', sortField: 'sklad__kod', label: 'karty.SKLAD', select2: { source: 'sklady', lookup: 'kod' } },
      { field: 'mp', label: 'Mn. P', tooltip: 'pohyb.MNOZSTVO_P', type: 'number', sum: true},
      { field: 'cena_naklady', label: 'Cena P', tooltip: 'pohyb.CENA_P', type: 'number', sum: true},
      { field: 'mv', label: 'Mn. V', tooltip: 'pohyb.MNOZSTVO_V', type: 'number', sum: true},
      { field: 'cena_vydaj', label: 'Cena V', tooltip: 'pohyb.CENA_V', type: 'number', sum: true},
      { field: 'cena_sklad', label: 'karty.CENA_SKLAD', type: 'number', sum: true},
      { field: 'karta__mo', label: 'pohyb.CENA_PREDAJ', type: 'number', filterCollapse: 'left'},
      { field: 'poznamka', label: 'pohyb.POZNAMKA', width: 200 },
    ];
    // if (!Profile.get('platcadph')) {
    //   var omitted = ['modph'];
    //   $scope.pohybTable = _.filter($scope.pohybTable, function(field) { return omitted.indexOf(field.field) === -1});
    // }
    $scope.SearchCtrl.init({
      // defaultFilter: {emptykod: 'False'},
      onChange: filterChanged,
      defaultPeriod: defaultPeriod,
      columnsDef: $scope.pohybTable,
      sort: {sort: 'datum'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(TabService.bus.get('pohyb'));

    var events = [];
    var endpoints = ['faktury', 'zavazky', 'pokladna', 'ponuky'];

    var refreshFunction = function(data) {
      $scope.SearchCtrl.refresh();
    };

    for (var i in endpoints) {
      events.push(Socket.on(endpoints[i]+"Created", refreshFunction));
      events.push(Socket.on(endpoints[i]+"Changed", refreshFunction));
      events.push(Socket.on(endpoints[i]+"Removed", refreshFunction));
    }
    events.push($scope.$on('casist:dataChanged', function(event, data) {
      if (endpoints.indexOf(data.type) === -1) {
        return;
      }
      refreshFunction(data);
    }));
    $scope.$on('$destroy', function() {
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
      for (var i in events) {
        events[i]();
      }
    });

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('obdobie', function(obdobie) {
      defaultPeriod = {
        'datum__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
        'datum__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
      }
      $scope.SearchCtrl.setDefaultPeriod(defaultPeriod);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.edit = function(karta) {
      return Karta.edit(karta);
    };

    $scope.prepocetProgress = false;
    $scope.prepocetSkladu = function() {
      $scope.prepocetProgress = true;
      Pohyb.prepocet(Profile.get('obdobie')).then(function(data) {
        $scope.prepocetProgress = false;
        Notification.add('Globálny prepočet prebehol úspešne.', 'success', 5);
        $scope.pohyb = data.results;
        $scope.pohyb.sumline = data.sumline;
      }, function() {
        $scope.prepocetProgress = false;
        Notification.add('Pri globálnom prepočte nastala chyba.', 'danger', 5);
      });
    };

    var updateOznacenych = function () {
      var l = _.keys($scope.selected).length;
      switch (l) {
        case 0:
          $scope.oznacenych = '';
          break;
        case 1:
          $scope.oznacenych = l + ' označený záznam';
          break;
        case 2:
        case 3:
        case 4:
          $scope.oznacenych = l + ' označené záznamy';
          break;
        default:
          $scope.oznacenych = l + ' označených záznamov';
          break;
      }
    };

    $scope.selected = {};
    $scope.selectedAll = false;
    var showMessage = false;

    $scope.selectAll = function() {
      var selectedAll = !$scope.selectedAll;
      $scope.selected = {};
      for (var i = 0; i < $scope.pohyb.length; i++) {
        $scope.pohyb[i].selected = selectedAll;
          if (selectedAll) {
            $scope.selected[$scope.pohyb[i].id] = $scope.pohyb[i];
          } else {
            delete $scope.selected[$scope.pohyb[i].id];
          }
      }

      showMessage = !showMessage;
      if (showMessage) {
        $scope.oznacenych = 'Označené všetky záznamy';
      } else {
        $scope.oznacenych = '';
      }
    };

    $scope.selectPohyb = function (pohyb) {
      if ($scope.selectedAll) {
        $scope.selectedAll = false;
      }
      if ($scope.selected[pohyb.id]) {
        delete $scope.selected[pohyb.id];
      } else {
        $scope.selected[pohyb.id] = pohyb;
      }
      updateOznacenych();
    };

    $scope.deleteRecords = function() {
      console.log($scope.selected)
      Pohyb.deleteBulk($scope.selected)
      .then(
        function () {
          _.each($scope.selected, function (pohyb) {
            delete $scope.selected[pohyb.id];
            _.find($scope.pohyb, {id: parseInt(pohyb.id)}).selected = false;
            Pohyb.syncDelete(pohyb);
          });

          if ($scope.selectedAll) {
            $scope.selectedAll = false;
          }
          updateOznacenych();
          $scope.SearchCtrl.refresh();
          Notification.add('Zvolené pohyby boli úspešne odstránené.', 'success', 10);
        }
      ).catch(
        function (error) {
          if (error.status >= 400 && error.status < 500) {
            Notification.add('Zvolené pohyby sa nepodarilo odstrániť.', 'danger', 10);
          }
        }
      );
    };

    $scope.editDoklad = function(doklad) {
      Doklad.editRecord(doklad, null, $scope).then(function(data) {
        refreshFunction();
      });
    };

    $scope.exportPohyb = function() {
      Outputs.openXLSExportUrl('pohyb', $scope.SearchCtrl.getQuery());
    };

    $scope.print = function(typ, field) {
      Outputs.openPrintUrl('pohyb', undefined, angular.extend({group_by: field}, $scope.SearchCtrl.getQuery()), typ ? 'print-' + typ : undefined);
    };

    $scope.printBarcode = function () {
      Outputs.openPrintUrl('pohyb', 'barcode', { ids: _.keys($scope.selected) });
    };

    $scope.sumLabels = ['Spolu', 'Zostatok P-V'];
  }]);
