'use strict';

import { buildTemplateCache } from '../../templates-helper';

angular.module('casist.core').factory('Global', [
  '$templateCache',
  '$sessionStorage',
  function ($templateCache, $sessionStorage) {
    var defaultVars = {
      serverAddress: null,
      serverName: null,
    };
    var vars = {};

    var clear = function () {
      vars = {};
      vars = defaultVars;
    };
    var clearCookies = function () {
      delete $sessionStorage.profile;
      delete $sessionStorage.settings;
      delete $sessionStorage.accesstoken;
      delete $sessionStorage.refreshtoken;
    };
    // set default vars
    clear();

    return {
      get: function () {
        if (!arguments[0]) return vars;
        return vars[arguments[0]]
          ? vars[arguments[0]]
          : arguments[1]
          ? arguments[1]
          : vars[arguments[0]];
      },
      set: function (key, value) {
        vars[key] = value;
      },
      remove: function (key) {
        if (angular.isArray(key)) {
          for (var i = 0; i < key.length; i++) {
            delete vars[key[i]];
          }
        } else {
          delete vars[key];
        }
      },
      list: function () {
        for (var i in vars) {
          if (vars.hasOwnProperty(i)) {
            console.log(i + ': ' + vars[i]);
          }
        }
      },
      setArray: function (obj) {
        vars = obj;
      },
      appendArray: function (obj) {
        vars = angular.extend(vars, obj);
      },
      clear: function () {
        clear();
      },
      clearCookies: function () {
        clearCookies();
      },
      clearTplCache: function (cached) {
        // clear cached templates to reload permission-based attributes
        for (var i in cached) {
          $templateCache.remove(cached[i]);
        }
        buildTemplateCache($templateCache);
      },
    };
  },
]);
