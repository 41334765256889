'use strict';

angular.module('casist.svb')
  .controller('VlastniciBaseCtrl', ['$scope', '$timeout', 'vlastnici', '$location', 'Priestor', 'TabService', '$state', '$stateParams', '$controller', 'vchody', 'Vlastnik',
    function($scope, $timeout, vlastnici, $location, Priestor, TabService, $state, $stateParams, $controller, vchody, Vlastnik) {
      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.vlastnici = vlastnici;
      $scope.vlastniciList = {page: $stateParams.page || Math.ceil(vlastnici.count / $scope.SearchCtrl.per_page) || 'last', num_pages: 0};
      $scope.filterChanged = function(filter) {
        var promise = Vlastnik.objects().getList(filter);
        $scope.vlastnici.loading = true;
        promise.then(function(data) {
          TabService.registerScope('vlastnici', $scope);
          $scope.vlastnici = data;
          delete $scope.vlastnici.loading;
        });
        return promise;
      };
      $scope.addVlastnik = function() {
        $state.go('default.vlastnici.add', $stateParams);
      };
      $scope.defaultFilter = {};
      $scope.refreshVlastniciList = function() {
        return $scope.filterChanged(angular.extend($scope.SearchCtrl.getQuery(), {page: $scope.vlastniciList.page}));
      };
      $scope.refreshVchody = function(dom) {
        $scope.firstSetup = $scope.domy.length === 0;
        if ($scope.firstSetup && $scope.SearchCtrl.filters.top.dom) {
          delete $scope.SearchCtrl.filters.top.dom;
        }
        if (!$scope.firstSetup && !$scope.SearchCtrl.filters.top.dom) {
          $scope.SearchCtrl.filterSelection('dom', $scope.domy[0], false);
          $scope.domChanged($scope.domy[0]);
        }
        if (!dom) {
          dom = _.find($scope.domy, {id: parseInt($scope.SearchCtrl.filters.top.dom)});
        }
        $scope.vchody = [];
        if (!dom) {
          return;
        }
        _.each(vchody, function(obj) {
          if (obj.dom === dom.id) {
            $scope.vchody.push(obj);
          }
        });
      };
      $scope.domChanged = function(dom) {
        var searchParams = {page: 'last', dom: dom.id};
        $scope.refreshVchody(dom);
        if ($scope.vchody.length) {
          searchParams.vchod = $scope.vchody[0].id;
          $scope.SearchCtrl.filterSelection('vchod', $scope.vchody[0].id, true);
        }
        $location.search(searchParams);
        $scope.SearchCtrl.filterSelection('dom', dom.id, false);
      };
      $scope.vchodChanged = function(vchod) {
        $location.search({page: 'last', vchod: vchod.id});
        $scope.defaultFilter.vchod = vchod.id;
        $scope.$broadcast('vchodChanged', function() {
          return vchod;
        });
      };
      $scope.vchody = [];
      $scope.domy = [];
      $scope.firstSetup = false;
      Priestor.getQuery(vchody).then(function(vchod) {
        if (!vchod) {
          $scope.firstSetup = true;
          return;
        }
        _.each(vchody, function(obj) {
          if (!_.find($scope.domy, {id: obj.dom})) {
            $scope.domy.push({id: obj.dom, nazov: obj.dom_data.nazov});
          }
          if (obj.dom === vchod.dom) {
            $scope.vchody.push(obj);
          }
        });
        $scope.SearchCtrl.filterSelection('dom', vchod.dom, false);
        $scope.SearchCtrl.filterSelection('vchod', vchod.id, false);
      });
      $scope.Priestor = Priestor;

      $scope.vlastniciTable = [
        { width: 40 },
        { field: 'meno', label: 'vlastnici.MENO', filterCollapse: 'right' },
        { field: 'email', label: 'vlastnici.EMAIL', filterCollapse: 'right' },
        { field: 'priestor__nazov', label: 'priestory.PRIESTOR', linked: { source: 'Priestor.get(query)', template: 'priestor-selection', lookup: 'nazov', style: "{width: '400px'}" } },
        { field: 'vs', label: 'vlastnici.VS' },
        { field: 'datum_od', label: 'vlastnici.DATUM_OD', type: 'date' },
        { field: 'datum_do', label: 'vlastnici.DATUM_DO', type: 'date' },
        { field: 'poznamka', label: 'vlastnici.POZNAMKA' }
      ];
      $scope.SearchCtrl.init({
        onChange: $scope.filterChanged,
        columnsDef: $scope.vlastniciTable,
        sort: {sort: 'meno'},
        page: $scope.vlastniciList.page,
        scope: $scope,
        useLocation: true,
        defaultFilter: $scope.defaultFilter
      });
      var events = Vlastnik.registerEvents(function() { return $scope.vlastnici; }, function() { return $scope.SearchCtrl; });
      $scope.$on('$destroy', function() {
        Vlastnik.unregisterEvents(events);
      });
    }
  ])
  .controller('VlastniciCtrl', ['$scope', 'Restangular', 'Priestor', 'Profile', '$controller', '$q', 'TabService', 'Outputs', '$modal', 'Notification', '$state', '$stateParams', 'vchody', '$location',
    function ($scope, Restangular, Priestor, Profile, $controller, $q, TabService, Outputs, $modal, Notification, $state, $stateParams, vchody, $location) {
    $scope.changePage = function() {
      $scope.SearchCtrl.setPage();
      $location.search(angular.extend($location.search(), {page: $scope.SearchCtrl.page}));
    };

    $scope.manageDomy = function() {
      $modal.open({
        templateUrl: 'svb/domy/domy.html',
        controller: 'DomyCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        resolve: {
          domy: function() {
            return $scope.domy;
          },
          vchody: function() {
            return vchody;
          }
        }
      }).result.then(function() {
          $scope.refreshVchody();
      });
    };
  }]);
