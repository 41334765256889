'use strict';

angular.module('casist.svb')
  .controller('DomyCtrl', ['$scope', '$modalInstance', 'domy', 'vchody', 'Dialog', '$q', '$controller', 'Dom', 'Vchod', function($scope, $modalInstance, domy, vchody, Dialog, $q, $controller, Dom, Vchod) {
    $scope.data = {
      domy: domy,
      vchody: vchody
    };
    var defaultDom = {vchody: []};
    $scope.novyDom = angular.copy(defaultDom);
    $scope.novyVchod = {};
    $scope.TableEditor = $controller('TableEditor', {
      $scope: $scope,
      Model: Dom,
      List: $scope.data.domy
    });
    $scope.TableVchodEditor = $controller('TableEditor', {
      $scope: $scope,
      Model: Vchod.bulk(),
      List: $scope.data.vchody
    });
    for (var i = 0; i < $scope.data.domy.length; i++) {
      $scope.data.domy[i].vchody = [];
    }
    Dom.restangularize($scope.data.domy);
    for (i = 0; i < vchody.length; i++) {
      var dom = _.find($scope.data.domy, {id: vchody[i].dom});
      if (dom) {
        dom.vchody.push(vchody[i]);
      }
    }
    $scope.addDom = function() {
      Dom.create($scope.novyDom).then(function(data) {
        $scope.novyDom = angular.copy(defaultDom);
        Vchod.restangularize(data.vchody);
        for (var i = 0; i < data.vchody.length; i++) {
          $scope.data.vchody.push(data.vchody[i]);
        }
        $scope.data.domy.push(data);
      });
    };
    $scope.finishDomEditing = function(evt, dom) {
      if (!evt || (!evt.shiftKey && !evt.altKey && evt.which === 13)) {
        $scope.TableEditor.finishEditing(dom);
        dom.update().then(null, function() {
          Dialog.alert('Záznam sa nepodarilo uložiť.', 'Chyba');
        });
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      }
    };
    $scope.removeDom = function(dom) {
      Dialog.confirm('Chcete naozaj vymazať záznam?\nVšetky údaje tohto domu sa nenávratne vymažú.', 'Pozor', {okClass: 'danger', cancelClass: 'default', icon: 'fa-warning text-danger'}).then(function() {
        dom.delete().then(function() {
          $scope.data.domy.splice($scope.data.domy.indexOf(dom), 1);
        });
      });
    };
    $scope.addVchod = function(dom) {
      Vchod.create(angular.extend($scope.novyVchod[dom.id], {dom: dom.id})).then(function(data) {
        $scope.novyVchod = {};
        dom.vchody.push(data);
        $scope.data.vchody.push(data);
      });
    };
    $scope.finishVchodEditing = function(evt, vchod) {
      if (!evt || (!evt.shiftKey && !evt.altKey && evt.which === 13)) {
        $scope.TableVchodEditor.finishEditing(vchod);
        vchod.update().then(null, function() {
          Dialog.alert('Záznam sa nepodarilo uložiť.', 'Chyba');
        });
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      }
    };
    $scope.removeVchod = function(vchod) {
      Dialog.confirm('Chcete naozaj vymazať záznam?\nVšetky údaje tohto vchodu sa nenávratne vymažú.', 'Pozor', {okClass: 'danger', cancelClass: 'default', icon: 'fa-warning text-danger'}).then(function() {
        var dom = _.find($scope.data.domy, {id: vchod.dom});
        vchod.delete().then(function() {
          $scope.data.vchody.splice($scope.data.vchody.indexOf(vchod));
          if (dom) {
            dom.vchody.splice(dom.vchody.indexOf(vchod), 1);
          }
        });
      });
    };

    $scope.ok = function() {
      $modalInstance.close($scope.data);
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }]);
