'use strict';

angular.module('casist.sklad').controller('PonukyCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'Ponuka', 'PonukaTyp', 'PonukaUcel', 'Firma', 'Outputs', 'DokladTypy', 'TabService', 'Doklad', 'Notification', 'Dialog',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, Ponuka, PonukaTyp, PonukaUcel, Firma, Outputs, DokladTypy, TabService, Doklad, Notification, Dialog) {

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {
    vybavene: 'False'
  };
  $scope.ponuky = [];
  $scope.vybaveneTypy = [
    {id: 'False', vybavene: 'Nevybavené'},
    {id: 'True', vybavene: 'Vybavené'},
    {id: 0, vybavene: 'Všetko'}
  ];
  var filterChanged = function(filter) {
    if (filter.nesparovane) {
      filter = _.omit(filter, 'd_zdp__lte', 'd_zdp__gte');
    }
    var promise = Ponuka.objects().getList(filter);
    $scope.ponuky.loading = true;
    promise.then(function(data) {
      TabService.registerScope('ponuky', $scope);
      $scope.ponuky = data;
      for (var i = 0; i < data.length; i++) {
        Firma.restangularize($scope.ponuky[i].firma_data);
      }
    });
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    loadPrevadzky();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.ponukatypy = PonukaTyp.objects().getList().$object;
  $scope.ucely = [];
  // $scope.ucely = PonukaUcel.objects().getList().$object;
  PonukaUcel.objects().getList().then(function(data) {
    angular.forEach(data, function(obj) {
      $scope.ucely.push({id: obj.id, text: obj.typ ? obj.typ_name + '/' + obj.text : obj.text});
    });
  });

  var updateOznacenych = function() {
    var l = _.keys($scope.selected).length;
    switch (l) {
      case 0:
        $scope.oznacenych = '';
        break;
      case 1:
        $scope.oznacenych = l+' označený záznam';
        break;
      case 2: case 3: case 4:
        $scope.oznacenych = l+' označené záznamy';
        break;
      default:
        $scope.oznacenych = l+' označených záznamov';
        break;
    }
  };
  $scope.selected = {};
  $scope.selectItem = function(ponuka) {
    if ($scope.selected[ponuka.id]) {
      delete $scope.selected[ponuka.id];
      ponuka.selected = false;
    } else {
      $scope.selected[ponuka.id] = ponuka;
      ponuka.selected = true;
    }
    updateOznacenych();
  };

  var coreFields = [
    {},
    {},
    { field: 'typ', label: 'zavazky.TYP', filterCollapse: 'right', select2: { source: 'ponukatypy', lookup: 'typ' } },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' }
  ];

  $scope.ponukyTable = [
    _.union(coreFields, [
      { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
      { field: 'd_zdp', label: 'ucto.ISSUED', type: 'date' },
      { field: 'd_splat', label: 'ucto.DUE', type: 'date' },
      { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70 },
      { field: 'zlava', filterCollapse: 'left', label: 'ucto.ZLAVA', type: 'number' },
    ]),
    _.union(coreFields, [
      { field: 'd_zdp', label: 'ucto.ISSUED', type: 'date' },
      { field: 'ucel', label: 'Účel', select2: { source: 'ucely', lookup: 'text' } },
      { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
      { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
      { field: 'objednavka', label: 'ucto.OBJEDNAVKA', filterType: 'icontains' },
      { field: 'doprava', label: 'ucto.DOPRAVA', filterType: 'icontains' },
      { field: 'predajca', label: 'ucto.PREDAJCA', filterType: 'icontains' },
      { field: 'faktura_field', label: 'Faktúra' },
      { field: 'parovanie', label: 'Párovanie' },
      { field: 'poznamka', label: 'fakturacia.POZNAMKA', filterType: 'icontains' }
    ]),
  ];
  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'uplat_dph', 'typ_dph__typ', 'sd1', 'sd2'];
    var idx = $scope.ponukyTable[0].indexOf(_.find($scope.ponukyTable[0], {field: 'zaklad_d1'}));
    $scope.ponukyTable[0] = _.reject($scope.ponukyTable[0], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    $scope.ponukyTable[1] = _.reject($scope.ponukyTable[1], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    var moved = ['objednavka', 'doprava', 'predajca'];
    var movedItems = _.filter($scope.ponukyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
    for (var i = movedItems.length-1; i >= 0; i--) {
      $scope.ponukyTable[0].splice(idx, 0, movedItems[i]);
    }
    $scope.ponukyTable[1] = _.reject($scope.ponukyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
  }

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
  };
  loadPrevadzky();

  $scope.create = function(item) {
    Ponuka.edit(item, $scope.ponuky, $scope.SearchCtrl);
  };
  $scope.copy = function(item) {
    var copied = Ponuka.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'vs', 'd_vyst', 'd_zdp', 'd_splat', 'd_uctn', 'uplat_dph', 'parovanie', 'parovanie_data', 'faktura', 'faktura_data'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };
  $scope.fakturacia = function(ponuka) {
    if (!angular.isDefined(ponuka)) {
      var ids = _.keys($scope.selected);
      Ponuka.fakturaciaHromadna(ids).then(function(data) {
        Notification.add('Ponuky boli vyfaktúrované.', 'success', 5);
        for (var d = 0; d < data.length; d++) {
          Doklad.syncCreate(data[d]);
          for (var i in $scope.selected) {
            if ($scope.selected[i].firma === data[d].firma) {
              $scope.selected[i].faktura = data[d].iddokd;
              $scope.selected[i].selected = false;
              $scope.selected[i].vybavene = true;
              Doklad.syncChange($scope.selected[i]);
              delete $scope.selected[i];
            }
          }
        }
        updateOznacenych();
        $scope.SearchCtrl.broadcastRefresh('ponuky');
        if (data.length === 1) {
          TabService.bus.send('faktura', 'ponuky', {iddokd: data[0].iddokd});
        }
        TabService.openTab('faktura');
      }, function() {
        Notification.add('Nepodarilo sa vyfaktúrovať ponuky.', 'danger', 5);
      });
      return;
    }
    if (ponuka.faktura) {
      TabService.bus.send('faktura', 'ponuky', {iddokd: ponuka.faktura});
      TabService.openTab('faktura');
    } else {
      ponuka.fakturacia().then(function(data) {
        Notification.add('Ponuka bola vyfaktúrovaná.', 'success', 5);
        Doklad.syncCreate(data);
        ponuka.faktura = data.iddokd;
        ponuka.vybavene = true;
        Doklad.syncChange(ponuka);
        $scope.SearchCtrl.broadcastRefresh('ponuky');
        TabService.bus.send('faktura', 'ponuky', {iddokd: data.iddokd});
        TabService.openTab('faktura');
      }, function() {
        Notification.add('Nepodarilo sa vyfaktúrovať ponuku.', 'danger', 5);
      });
    }
  };
  var events = Ponuka.registerEvents(function() { return $scope.ponuky; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Ponuka.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Ponuka,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.ponukyTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.filterSelection('vybavene', 'False');

  $scope.ucelChanged = function() {
    $scope.SearchCtrl.filterSelection('ucel', $scope.SearchCtrl.filters.top.ucel);
  };

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.ponukyTable[view]);
  };

  $scope.print = function(faktura, url) {
    Outputs.openPrintUrl('ponuky', faktura.id, undefined, url);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('ponuky', null, $scope.SearchCtrl.getQuery());
  };

  $scope.popoverWindow = null;
  $scope.sendToRegisterCashPopover = function () {
    $scope.popoverWindow = '<div><i class="fa fa-info"></i> Pred presunom do RP sa uistite, že máte spustený program registračnej pokladne a vybranú rovnakú agendu.</div><div class="text-center" style="margin-top: 5px;"><button class="btn btn-sm btn-default" ng-click="sendToRegisterCash()"><i class="fa fa-money"></i> Presun do RP</button></div>';
  };

  $scope.sendToRegisterCash = function () {
    var ids = _.keys($scope.selected).map(function(key) { return parseInt(key); });

    ids.forEach(function(ponukaId) {
      var ponuka = _.find($scope.ponuky, {id: ponukaId});
      ponuka.getList('pohyb').then(function(pohyb) {
        Socket.emit('payOrder', {
          user: Profile.get('userData').id,
          id: ponuka.id,
          iddokd: ponuka.vs,
          spolu: ponuka.spolu,
          firma: ponuka.firma,
          firma_name: ponuka.firma_data.firma,
          zaklad_d0: ponuka.zaklad_d0,
          zaklad_d1: ponuka.zaklad_d1,
          zaklad_d2: ponuka.zaklad_d2,
          d1: ponuka.d1,
          d2: ponuka.d2,
          discount: ponuka.zlava,
          pohyb: pohyb
        });
      });

      $scope.selected[ponukaId].vybavene = true;
      $scope.selected[ponukaId].selected = false;
      Doklad.syncChange($scope.selected[ponukaId]);
      delete $scope.selected[ponukaId];
      Notification.add('Ponuka ' + ponuka.iddokd + ' bola odoslaná na uhradenie do eKasy.', 'success', 5);
    });

    $timeout(function() {
      updateOznacenych();
      $scope.uhradaClosed();
    });
  };

  $scope.uhradaClosed = function() {
    $scope.popoverWindow = null;
  };

  $scope.openImport = function() {
    var modalInstance = $modal.open({
      templateUrl: 'core/import/import.html',
      controller: 'GenericImportCtrl',
      backdrop: 'static',
      windowClass: 'detail',
      resolve: {
        list: function() {
          return $scope.ponuky;
        },
        model: function() {
          return Ponuka;
        }
      }
    });
    modalInstance.result.then(function() {
      Notification.add('Záznamy boli aktualizované.', 'success', 5);
      $scope.SearchCtrl.refresh();
    }).catch(function (error) {
      console.log(error);
    });
  };
}]);
