'use strict';

angular.module('casist.svb')
  .controller('PriestorMeracCtrl', ['$scope', '$stateParams', '$state', 'merace', 'priestory', 'Merac', 'Dialog', 'Notification', '$controller', 'Odpocet', 'priestor',
    function($scope, $stateParams, $state, merace, priestory, Merac, Dialog, Notification, $controller, Odpocet, priestor) {
      $scope.druhyMeracov = Merac.getDruhy();
      var merac = _.find(merace, {id: parseInt($stateParams.meracID)});
      var defaultMerac = {druh: $scope.druhyMeracov[0].id};
      if (merac) {
        $scope.merac = Merac.copy(merac);
      } else {
        $scope.merac = angular.copy(defaultMerac);
      }
      $scope.priestor = angular.copy(priestor);
      $scope.breadcrumb = [{title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.dom_data.nazov : '---', state: 'default.priestory.list', params: {}}, {title: ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.nazov : '---', state: 'default.priestory.list', params: {}}, {title: $scope.priestor.nazov, state: 'default.priestory.detail.summary', params: {'priestorID': $scope.priestor.id}}, {title: merac ? $scope.merac.nazov : 'Nový merač', state: 'default.priestory.detail.merac', params: {'priestorID': $scope.priestor.id, 'meracID': $scope.merac.id}}];
      $scope.isEdit = $state.current.name !== 'default.priestory.detail.addMerac';

      if (merac) {
        $scope.odpocty = Odpocet.objects().getList({merac: merac.id}).$object;
      }
      //$scope.odpocty = [
      //  {id: 1, datum: new Date(2015, 4, 5), spotreba: 20, sposob: 'odpocet', koeficient: 1, poznamka: 'ou yeah', stav: 134215},
      //  {id: 2, datum: new Date(2015, 3, 5), spotreba: 10, sposob: 'odpocet', koeficient: 0.8, poznamka: 'ou yeah 1', stav: 13421245}
      //];
      $scope.goToBreadcrumb = function(item) {
        $state.go(item.state, angular.extend(item.params, {page: $stateParams.page}), {reload: $scope.priestoryList.page != $stateParams.page});
      };

      $scope.goBack = function() {
        $scope.goToBreadcrumb($scope.breadcrumb[2]);
      };

      $scope.save = function() {
        $scope.RecordCtrl.main.save().then(function(data) {
          if (data.type === 'created') {
            $state.go('default.priestory.detail.addMerac', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page}, {reload: true});
          } else {
            $scope.goBack();
          }
          Notification.add('Merač bol uložený.', 'success', 5);
        });
      };
      $scope.cancel = function() {
        detectAndSaveChanges(merac ? merac.id : null, $scope.goBack);
      };
      $scope.remove = function() {
        var idx = merace.indexOf(merac)-1;
        var removalHandler = null;
        if (idx < 0 && merace.length > 1) {
          idx = 1;
        }
        if (idx < 0) {
          removalHandler = function() {
            $state.go('default.priestory.detail.addMerac', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page}, {reload: true});
          }
        } else {
          var noveID = merace[idx].id;
          removalHandler = function() {
            $state.go('default.priestory.detail.merac', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page, meracID: noveID}, {reload: true});
          }
        }
        $scope.RecordCtrl.main.remove().then(function() {
          removalHandler();
        });
      };

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        Model: Merac.parent($scope.priestor)
      });
      $scope.RecordCtrl.main.init({
        endpoint: 'merace',
        model: 'merac',
        master: merac,
        list: $scope.merace,
        defaultItem: defaultMerac,
        preSave: function(obj) {
          obj.priestor = $scope.priestor.id;
          return obj;
        }
      });

      var isRecordChanged = function() {
        if ($scope.merac.id) {
          return !objEquals(Merac.stripRestangular(merac), Merac.stripRestangular($scope.merac));
        } else {
          return !objEquals(defaultMerac, Merac.stripRestangular($scope.merac));
        }
      };
      var setRecord = function(id) {
        $state.go('default.priestory.detail.merac', {priestorID: $scope.priestor.id, page: $scope.priestoryList.page, meracID: id});
      };
      var detectAndSaveChanges = function(id, handler) {
        if (isRecordChanged()) {
          Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
            if (!$scope.merac.id) {
              $scope.save();
            } else {
              var promise = $scope.RecordCtrl.main.save(true);
              if (promise) {
                promise.then(function () {
                  if (handler) {
                    handler(id);
                  } else {
                    setRecord(id);
                  }
                }, function (error) {
                  Dialog.alert(error, 'Chyba');
                });
              }
            }
          }, function() {
            if (handler) {
              handler(id);
            } else {
              setRecord(id);
            }
          });
        } else {
          if (handler) {
            handler(id);
          } else {
            setRecord(id);
          }
        }
      };
      $scope.changePriestor = function(item) {
        detectAndSaveChanges(null, function() {
          $state.go('default.priestory.detail.summary', {priestorID: item.id, page: $scope.priestoryList.page});
        });
      };
      $scope.addPriestor = function() {
        detectAndSaveChanges(null, function() {
          $state.go('default.priestory.add');
        });
      };
      $scope.nextWindowRecord = function(index) {
        var list = merace;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find(merace, {id: parseInt($stateParams.meracID)}));
        }
        var nextItem = null;
        if (index !== -1) {
          nextItem = list.length > index-1 ? list[index+1] : null;
        }
        if (!nextItem) {
          detectAndSaveChanges(null, function() {
            $state.go('default.priestory.detail.addMerac');
          });
        } else {
          detectAndSaveChanges(nextItem.id);
        }
      };
      $scope.prevWindowRecord = function(index) {
        var list = merace;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find(merace, {id: parseInt($stateParams.meracID)}));
        }
        if (!merac && list.length) {
          index = list.length;
        }
        var prevItem = null;
        if (index !== -1) {
          prevItem = list.length > 0 ? list[index-1] : null;
        }
        if (prevItem) {
          detectAndSaveChanges(prevItem.id);
        }
      };
    }
  ]);
