"use strict";

angular.module("casist.ucto").factory("Urgencia", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladTypy",
  "$timeout",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladTypy,
    $timeout,
    $translate
  ) {
    var ENDPOINT = "urgencie";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          var res = BaseModel.validate();
          if (!this.hlavicka) {
            this.addError("hlavicka", "Pole hlavička je povinné.");
            res = false;
          }
          if (!angular.isDefined(this.firma) || this.firma == "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            res = false;
          }
          if (!this.datum) {
            this.addError("datum", "Pole nemôže byť prázdne");
            res = false;
          }
          return res;
        },
        recountSums: function (rozpis) {
          var deferred = $q.defer();
          var self = this;
          var countIt = function (rozpis) {
            var suma = 0;
            if (!rozpis.length) {
              var sums = { suma: suma };
              $timeout(function () {
                copyWeakSrc({ suma: suma }, self);
                deferred.resolve(sums);
              });
              return deferred.promise;
            }
            for (var i = 0; i < rozpis.length; i++) {
              suma +=
                parseFloat(rozpis[i].spolu) - parseFloat(rozpis[i].uhradene);
            }
            copyWeakSrc({ suma: suma }, self);
            deferred.resolve(sums);
          };
          if (!rozpis) {
            this.getList("rozpis").then(function (data) {
              countIt(data);
            });
          } else {
            countIt(rozpis);
          }
          return deferred.promise;
        },
      });
    });

    var openDetail = function (item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: "ucto/urgencie/urgencie-detail.html",
        controller: "UrgencieDetailCtrl",
        backdrop: "static",
        keyboard: false,
        windowClass: "detail-md",
        resolve: {
          urgencia: function () {
            return item ? item : {};
          },
          urgencie: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return config;
          },
        },
      });
      return modalInstance.result;
    };

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").urgencia;
      },
      neuhradene: function () {
        return Restangular.all("urgencie").all("neuhradene");
      },
      hromadne: function (query) {
        var promise = Restangular.all("urgencie")
          .all("hromadne")
          .post({}, query);
        promise.then(function (data) {
          for (var i = 0; i < data.length; i++) {
            parent.syncCreate(data[i]);
          }
        });
        return promise;
      },
      send: function (query, sendData) {
        return Restangular.all("urgencie")
          .all("mail")
          .post(sendData || {}, query);
      },
      edit: function (item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      add: function (item) {
        var novaUrgencia = item || {};
        var obj = parent.restangularize(novaUrgencia);
        var promise = $q.defer();
        openDetail(obj).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      remove: function (urgencia) {
        return urgencia.remove();
      },
      get: function (query) {
        return parent.objects().getList({ search: query });
      },
    });
  },
]);

angular.module("casist.ucto").factory("UrgenciaRozpis", [
  "Restangular",
  "BaseModel",
  "BaseCollectionModel",
  "DokladTypyPrijem",
  function (Restangular, BaseModel, BaseCollectionModel, DokladTypyPrijem) {
    Restangular.extendModel("urgenciarozpis", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function (record) {
          var ret = BaseModel.validate();
          return ret;
        },
      });
    });
    return BaseCollectionModel.object("urgenciarozpis");
  },
]);

angular.module("casist.ucto").factory("UrgenciaText", [
  "Restangular",
  "BaseModel",
  "BaseCollectionModel",
  "DokladTypyPrijem",
  "$q",
  "Dialog",
  function (
    Restangular,
    BaseModel,
    BaseCollectionModel,
    DokladTypyPrijem,
    $q,
    Dialog
  ) {
    Restangular.extendModel("urgencietexty", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.text) {
            this.addError("text", "Text nemôže byť prázdny!");
            return false;
          }
          return BaseModel.validate();
        },
      });
    });
    var parent = BaseCollectionModel.object("urgencie/urgencietexty");
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").urgencia;
      },
      edit: function (text) {
        var deferred = $q.defer();
        var promise = Dialog.prompt(
          "Text urgencie",
          "Upraviť text urgencie",
          text.text,
          { type: "textarea", rows: 10 }
        ).then(
          function (str) {
            text.text = str;
            text.update().then(
              function (data) {
                deferred.resolve(data);
              },
              function () {
                deferred.reject();
              }
            );
          },
          function () {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      add: function (text) {
        return parent.create({ text: text });
      },
      remove: function (text) {
        return text.remove();
      },
      get: function (query) {
        return parent.objects().getList({ search: query });
      },
    });
  },
]);
