'use strict';

angular.module('casist.svb')
  .factory('Vyuctovanie', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = 'vyuctovanie';
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
   });

  var parent = BaseCollectionModel.object(ENDPOINT);
  return angular.extend(parent, {
    getPerms: function() {
      return Profile.get('perms').vyuctovanie;
    },
    getQuery: function(vchody, params) {
      return {
        vchod: params.vchod
      };
    },
    vypocet: function(params, query, canceller) {
      if (canceller) {
        return Restangular.all('vyuctovanie').all('vypocet').withHttpConfig({timeout: canceller}).post(params, query);
      } else {
        return Restangular.all('vyuctovanie').all('vypocet').post(params, query);
      }
    },
    predpisy: function(params, query, canceller) {
      if (canceller) {
        return Restangular.all('vyuctovanie').all('predpisy').withHttpConfig({timeout: canceller}).post(params, query);
      } else {
        return Restangular.all('vyuctovanie').all('predpisy').post(params, query);
      }
    },
    fakturovatVyuctovanie: function(params, query, canceller) {
      if (canceller) {
        return Restangular.all('vyuctovanie').all('fakturacia').withHttpConfig({timeout: canceller}).post(params, query);
      } else {
        return Restangular.all('vyuctovanie').all('fakturacia').post(params, query);
      }
    },
    sendVyuctovanieJednotlivo: function(year) {
      return Restangular.all(ENDPOINT).all('send-individually').post({year: year});
    },
  });
}]);
