'use strict';

angular.module('casist.ucto').controller('PohladavkyCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Auth', '$controller', 'Faktura', 'Pohyb', 'Firma', 'Outputs', '$q', 'DokladTypy', 'Urgencia', 'TabService', 'Doklad', 'FakturyTyp', 'Notification', 'Dialog', 'Predkontacia', '$filter',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Auth, $controller, Faktura, Pohyb, Firma, Outputs, $q, DokladTypy, Urgencia, TabService, Doklad, FakturyTyp, Notification, Dialog, Predkontacia, $filter) {

  var getDefaultPeriod = function() {
    return {
      'd_zdp__gte': Auth.profile().get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Auth.profile().get('obdobie', new Date().getFullYear())+'-12-31'
    }
  };
  var defaultPeriod = getDefaultPeriod();
  var defaultFilter = {
    'dd': DokladTypy.VF.dd
  };
  $scope.uctovanieTypy = [
    {id: 1, uctovanie: 'Nezaúčtované'},
    {id: 2, uctovanie: 'Ručne účtované'},
    {id: 3, uctovanie: 'Predkontácia'},
    {id: 4, uctovanie: 'Zaúčtované'}
  ];
  $scope.rozuctovanieTypy = [
    {id: 1, rozuctovanie: 'Nerozúčtované'},
    {id: 2, rozuctovanie: 'Rozúčtované'}
  ];
  $scope.DokladTypy = DokladTypy;
  $scope.pohladavkyTypy = [
    {id: DokladTypy.VF.dd, dd: 'Vydané faktúry'},
    {id: DokladTypy.OP.dd, dd: 'Ostatné pohľadávky'},
    {id: 0, dd: 'Všetky'}
  ];
  $scope.typChanged = function(value) {
    if (value.id === DokladTypy.OP.dd && $scope.currentView !== 1) {
      $scope.currentView = 1;
    } else if ($scope.currentView !== 0) {
      $scope.currentView = 0;
    }
  };
  $scope.neuhradeneValue = 'True';
  $scope.customizeNeuhradene = function() {
    $modal.open({
      templateUrl: 'ucto/customize_neuhradene/customizeNeuhradene.html',
      controller: 'CustomizeNeuhradeneCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        neuhradene: function() {
          return $scope.neuhradeneValue;
        }
      }
    }).result.then(function(data) {
      $scope.neuhradeneValue = data;

      $scope.SearchCtrl.filters.top.neuhradene = data;
      $scope.SearchCtrl.refresh();
    });
  };
  $scope.faktury = [];
  var filterChanged = function(filter) {
    if (filter.neuhradene) {
      $scope.SearchCtrl.setDefaultPeriod(undefined);
      if (_.every(_.keys(getDefaultPeriod()), function(key) {
        return filter[key] === getDefaultPeriod()[key];
      })) {
        filter = _.omit(filter, 'd_zdp__gte');
      }
    } else {
      $scope.SearchCtrl.setDefaultPeriod(getDefaultPeriod());
      if (!$scope.SearchCtrl.hasDateFilter()) {
        filter = _.extend(filter, getDefaultPeriod());
      }
    }
    var promise = Faktura.objects().getList(filter);
    $scope.faktury.loading = true;
    promise.then(function(data) {
      TabService.registerScope('pohladavky', $scope);
      $scope.faktury = data;
      for (var i = 0; i < data.length; i++) {
        Firma.restangularize($scope.faktury[i].firma_data);
      }
    });
    if (filter.zahranicne) {
      $scope.currentView = 1;
    }
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Auth.profile().addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Auth.profile().addObserver('prevadzky', function() {
    loadPrevadzky();
  }));
  unregisterAuthObservers.push(Auth.profile().addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Auth.profile().get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Auth.profile().get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.getPredkontacia = function(query) {
    return Predkontacia.get(query, DokladTypy.VF.dd);
  };
  $scope.fakturytypy = FakturyTyp.objects().getList().$object;

  var coreFields = [
    {},
    {},
    { field: 'iddokd', sortField: 'dd,iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'd_zdp', label: 'ucto.SUPPLIED', type: 'date' },
    { field: 'd_splat', label: 'ucto.DUE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
    { field: 'uhradene', label: 'ucto.UHRADENE', type: 'number', sum: true },
    { field: 'rozdiel', label: 'ucto.ROZDIEL', type: 'number', sum: function(sums) { return parseFloat(sums['spolu']) - parseFloat(sums['uhradene']);} }
  ];

  var view1Fields = [
    { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
    { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
    { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
    { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
    { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
    { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
    { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70 },
    { field: 'typ', label: 'zavazky.TYP', select2: { source: 'fakturytypy', lookup: 'typ' }},
    { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
  ];

  if (!Auth.profile().get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'typ_dph__typ', 'uplat_dph'];
    view1Fields = _.filter(view1Fields, function(field) { return omitted.indexOf(field.field) === -1});
  }
  $scope.pohladavkyTable = [
    _.union(coreFields, view1Fields),
    _.union(coreFields, [
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
      { field: 'mena', label: 'ucto.MENA', width: 90 },
      { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
      { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
      { field: 'zlava', label: 'ucto.ZLAVA', type: 'number' },
      { field: 'objednavka', label: 'ucto.OBJEDNAVKA', filterType: 'icontains' },
      { field: 'doprava', label: 'ucto.DOPRAVA', filterType: 'icontains' },
      { field: 'predajca', label: 'ucto.PREDAJCA', filterType: 'icontains', filterCollapse: 'left' }
    ]),
  ];

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Auth.profile().get('prevadzky'), {agenda: Auth.profile().get('agenda').id});
  };
  loadPrevadzky();

  $scope.create = function(item, dd) {
    var config = {};
    // Pre ostatne pohladavky dame do configu typ okna bez DPHckovych poli
    if (dd === 2 || $scope.SearchCtrl.filters.top.dd === DokladTypy.OP.dd) {
      config.VATfields = false;
    }
    return Faktura.editPohladavka(item, $scope.faktury, $scope.SearchCtrl, config);
  };
  $scope.copy = function(item) {
    var copied = Faktura.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'vs', 'd_vyst', 'd_zdp', 'd_splat', 'd_uctn', 'uplat_dph', 'uhradene', 'locked'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };

  var events = Faktura.registerEvents(function() { return $scope.faktury; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Faktura.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Faktura,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.pohladavkyTable[0],
    sort: {sort: 'dd,iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh();

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.pohladavkyTable[view]);
  };

  $scope.print = function(faktura, url) {
    Outputs.openPrintUrl('faktury', faktura.id, undefined, url);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('faktury', null, $scope.SearchCtrl.getQuery());
  };
  $scope.printPrehlad = function() {
    Outputs.openPrintUrl('faktury', null, $scope.SearchCtrl.getQuery(), 'print-prehlad');
  };

  $scope.createUrgencia = function(typ) {
    var query = $scope.SearchCtrl.getQuery();
    if (typ === 3) {
      query = _.omit(query, 'd_zdp__gte');
    }
    Urgencia.hromadne(angular.extend({typ_urgencii: typ}, query)).then(function() {
      TabService.bus.send('urgencie', 'pohladavky');
      TabService.openTab('urgencie');
    }, function() {
      alert('Vytvorenie urgencií sa nepodarilo!');
    });
  };

  $scope.exportSaldo = function() {
    var query = $scope.SearchCtrl.getQuery();
    query = _.omit(query, 'd_zdp__gte');
    Outputs.openXLSExportUrl('faktury/saldo', query);
  };

  $scope.parovanie = {};
  $scope.showParovanie = function(faktura) {
    if (!parseFloat(faktura.uhradene)) {
      $scope.prepocetUhrad(faktura.id);
      return;
    }
    faktura.listUhradene().then(function(data) {
      var el = '<table class="table table-bordered table-condensed table-striped"><tr><th></th><th>Kód</th><th>Doklad</th><th>Dátum</th><th>Suma</th><th>Suma v ZM</th></tr>';
      for (var i = 0; i < data.length; i++) {
        var kod = data[i].ucet_kod ? data[i].ucet_kod : data[i].pokladna_kod;
        el += '<tr><td width="30" class="text-center"><a class="record-icon" ng-click="editUhrada('+data[i].id+', \''+data[i].dd+'\', '+faktura.id+')"><i class="fa fa-edit"></i></a></td><td>'+kod+'</td><td>'+data[i].iddokd+'</td><td>{{\''+data[i].d_zdp+'\' | date:\'dd.MM.yyyy\'}}</td><td width="100" class="text-right">{{'+data[i].spolu+' | suma:2}}</td><td width="100" class="text-right">{{'+data[i].spolu_zm+' | suma:2}}</td></tr>';
      }
      el += '</table><div class="text-center" style="margin-top: 5px;"><button class="btn btn-sm btn-default" ng-click="prepocetUhrad('+faktura.id+')"><i class="fa fa-refresh"></i> Prepočítať {{nu}}</button></div>';
      $scope.parovanie[faktura.id] = el;
    });
  };
  $scope.parovanieClosed = function(faktura) {
    delete $scope.parovanie[faktura.id];
  };
  $scope.editUhrada = function(id, dd, fakturaID) {
    var preservePopover = function() {
      var faktura = _.find($scope.faktury, {id: fakturaID});
      if (faktura) {
        $scope.showParovanie(faktura);
      }
    };
    Doklad.editRecord(id, dd).then(preservePopover, preservePopover);
  };

  $scope.prepocetUhrad = function(id) {
    if (id) {
      var faktura = _.find($scope.faktury, {id: parseInt(id)});
      faktura.prepocetUhrad().then(function(data) {
        faktura.uhradene = parseFloat(data.uhradene);
        $scope.SearchCtrl.broadcastRefresh('faktury');
        $scope.parovanieClosed(faktura);
      });
    } else {
      $modal.open({
        templateUrl: 'ucto/prepocetuhrad/prepocetuhrad.html',
        controller: 'PrepocetUhradCtrl',
        backdrop: 'static',
        windowClass: 'detail-sm',
        resolve: {
          query: function() {
            return $scope.SearchCtrl.getQuery();
          },
          model: function() {
            return Faktura;
          }
        }
      })
    }
  };

  $scope.globalneUctovanie = function() {
    if (!$scope.SearchCtrl.hasFilter()) {
      Dialog.alert('Globálne účtovanie je možné použiť len keď je použitý filter.', 'Upozornenie');
      return;
    }
    $modal.open({
      templateUrl: 'ucto/globalne-uctovanie/globalne-uctovanie.html',
      controller: 'GlobalneUctovanieCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        list: function() {
          return $scope.faktury;
        },
        query: function() {
          return $scope.SearchCtrl.getQuery();
        },
        model: function() {
          return Faktura;
        },
        predkontacieGetter: function() {
          return function(query) { return $scope.getPredkontacia(query); };
        }
      }
    }).result.then(function(data) {
      Notification.add('Záznamy boli zaúčtované.', 'success', 5);
      var faktura;
      for (var i = 0; i < data.length; i++) {
        faktura = _.find($scope.faktury, {id: data[i].id});
        if (faktura) {
          Faktura.syncChange(data[i], angular.copy(faktura));
        }
      }
    });
  };
  $scope.openImport = function() {
    var modalInstance = $modal.open({
      templateUrl: 'core/import/import.html',
      controller: 'GenericImportCtrl',
      backdrop: 'static',
      windowClass: 'detail',
      resolve: {
        list: function() {
          return $scope.faktury;
        },
        model: function() {
          return Faktura;
        }
      }
    });
    modalInstance.result.then(function(data) {
      Notification.add('Záznamy boli aktualizované.', 'success', 5);
      $scope.SearchCtrl.refresh();
    });
  };

  $scope.itemMarza = function(item) {
    return $filter('suma')((parseFloat(item.cena_vydaj) / parseFloat(item.cena_sklad) - 1) * 100.0, 2);
  };

  $scope.exportPohladavky = function () {
    Outputs.openXLSExportUrl('faktury', $scope.SearchCtrl.getQuery());
  };

   const prepocetUhradFinished = function(data) {
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
      $scope.SearchCtrl.broadcastRefresh('faktury');
    }
    events.push(Socket.on("prepocetUhradFinished", prepocetUhradFinished));
}]);
