'use strict';

angular.module('casist.widgets')
  .directive('mctextarea', function () {
    return {
      template: '<textarea style="" ng-trim="false"></textarea>',
      restrict: 'E',
      replace: true,
      link: function postLink(scope, element, attrs) {
        var collapsedHeight = 0;
        var origStyle = "";
        attrs.$observe('collapsible', function(value) {
          collapsedHeight = value;
          origStyle = element.attr('style');
        });
        attrs.$observe('collapsed', function(value) {
          element.attr('style', origStyle+'height: '+value+'px');
        });
        if (angular.isDefined(attrs.collapsible)) {
          element.bind('focus', function() {
            element.attr('style', origStyle+'height: '+collapsedHeight+'px');
          });
          element.bind('blur', function() {
            element.attr('style', origStyle);
          });
        }
      }
    };
  });
