'use strict';

angular.module('casist.ubytovanie')
  .factory('UbytovanieNastenka', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "nastenka";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          return true;
        }
      });
    });

    var parent = BaseCollectionModel.object('ubytovanie/nastenka');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').ubytovanie;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
