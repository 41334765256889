"use strict";

angular.module("casist.sklad").controller("NakupDetailCtrl", [
  "$scope",
  "$modalInstance",
  "nakup",
  "nakupy",
  "$timeout",
  "$modal",
  "Global",
  "$http",
  "$controller",
  "Profile",
  "SearchCtrl",
  "Nakup",
  "Prevadzka",
  "Firma",
  "TypDPH",
  "Predkontacia",
  "$q",
  "Pohyb",
  "DokladTypy",
  "Doprava",
  "Mena",
  "Predajca",
  "NakupHlavicka",
  "NakupPata",
  "Ucet",
  "Outputs",
  "Dialog",
  "$filter",
  "Mailbox",
  "Karta",
  "NakupTyp",
  "Doklad",
  "NakupUcel",
  function (
    $scope,
    $modalInstance,
    nakup,
    nakupy,
    $timeout,
    $modal,
    Global,
    $http,
    $controller,
    Profile,
    SearchCtrl,
    Nakup,
    Prevadzka,
    Firma,
    TypDPH,
    Predkontacia,
    $q,
    Pohyb,
    DokladTypy,
    Doprava,
    Mena,
    Predajca,
    NakupHlavicka,
    NakupPata,
    Ucet,
    Outputs,
    Dialog,
    $filter,
    Mailbox,
    Karta,
    NakupTyp,
    Doklad,
    NakupUcel
  ) {
    $scope.hasList = nakupy.length !== 0;
    $scope.jednoducheUctoClass = Profile.get("jednoducheUcto")
      ? "jednoduche-ucto"
      : "";

    $scope.Karta = Karta;
    $scope.Mena = Mena;
    $scope.Hlavicka = NakupHlavicka;
    $scope.Pata = NakupPata;
    $scope.NakupUcel = NakupUcel;
    $scope.skladChanged = function (item, parent) {
      if (!parent) {
        parent = $scope.newItem;
      }
      if (item) {
        parent.sklad = parseInt(item);
      }
      parent.sklad_data = _.find($scope.currentPrevadzka.sklady, {
        id: parent.sklad,
      });
    };

    $scope.getHlavicky = function (query) {
      return $scope.Hlavicka.get(
        query,
        $scope.nakup.typ ? { typ: $scope.nakup.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addHlavicka = function (hlavicka) {
      return $scope.Hlavicka.add(
        hlavicka,
        $scope.nakup.typ ? { typ: $scope.nakup.typ } : undefined
      );
    };
    $scope.getPaty = function (query) {
      return $scope.Pata.get(
        query,
        $scope.nakup.typ ? { typ: $scope.nakup.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addPata = function (pata) {
      return $scope.Pata.add(
        pata,
        $scope.nakup.typ ? { typ: $scope.nakup.typ } : undefined
      );
    };
    $scope.typChanged = function (typ, setDefault) {
      if (!$scope.nakup) {
        return;
      }
      $scope.Hlavicka.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.nakup.hlavicka) || !setDefault) {
          if (data.length) {
            $scope.nakup.hlavicka = data[0].text;
          } else {
            $scope.nakup.hlavicka = "";
          }
        }
        if (setDefault) {
          defaultNakup.hlavicka = $scope.nakup.hlavicka;
        }
        delete $scope.nakup.ucel;
      });
      $scope.Pata.get(
        undefined,
        typ
          ? { typ: typ.id, default: "True" }
          : { typ__isnull: 1, default: "True" }
      ).then(function (data) {
        if (!angular.isDefined($scope.nakup.pata) || !setDefault) {
          if (data.length) {
            $scope.nakup.pata = data[0].text;
          } else {
            $scope.nakup.pata = "";
          }
        }
        if (setDefault) {
          defaultNakup.pata = $scope.nakup.pata;
        }
      });
    };
    $scope.Predajca = Predajca;
    $scope.Doprava = Doprava;
    $scope.Firma = Firma;
    $scope.NakupTyp = NakupTyp;

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Nakup,
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      pohladavkyPohyb: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_pohyb",
      }),
      pohladavkyRozuctovanie: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_rozuct",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.pohladavkyPohyb)
        ? layouts.pohladavkyPohyb.value
        : true,
      false,
      angular.isDefined(layouts.pohladavkyRozuctovanie)
        ? layouts.pohladavkyRozuctovanie.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "pohladavky_" + (panel === 0 ? "pohyb" : "rozuct");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function () {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.pohladavkyRozuctovanie.value) {
        $scope.panels[2] = layouts.pohladavkyRozuctovanie.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31),
      typDPHMaxPlatnost = new Date(1970, 1, 1);
    var updateTypDPH = function (datum) {
      if (!Profile.get("platcadph")) {
        return;
      }
      TypDPH.getValid(datum, "2,0").then(function (data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        if (
          data.data.length &&
          (!$scope.nakup.typ_dph ||
            !_.find(data.data, { id: $scope.nakup.typ_dph }))
        ) {
          $scope.nakup.typ_dph = data.data[0].id;
        } else if ($scope.nakup.id) {
          $scope.nakup.typ_dph = nakup.typ_dph;
        }
        if (data.data.length && !angular.isDefined(defaultNakup.typ_dph)) {
          defaultNakup.typ_dph = data.data[0].id;
        }
      });
    };
    $scope.help = {};
    $scope.helpClosed = function () {
      delete $scope.help.typdph;
    };
    $scope.displayHelpTypDPH = function () {
      var el = '<table class="table table-condensed">';
      for (var i = 0; i < $scope.typyDPH.length; i++) {
        el +=
          '<tr><td width="70">' +
          $scope.typyDPH[i].typ +
          "</td><td>" +
          $scope.typyDPH[i].popis +
          "</td></tr>";
      }
      el += "</table>";
      $scope.help.typdph = el;
    };
    $scope.$watch("nakup.d_vyst", function (val, oldVal) {
      if (val) {
        var date = parseDate(val);
        if (
          !isNaN(date) &&
          (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)
        ) {
          updateTypDPH(val);
        }
      }
    });
    if (Profile.get("platcadph")) {
      $scope.$watch("nakup.typ_dph", function (val) {
        if (angular.isDefined($scope.typyDPH)) {
          if (val) {
            $scope.nakup.typdph_data = _.find($scope.typyDPH, {
              id: parseInt(val),
            });
            if (
              !$scope.nakup.id &&
              !angular.isDefined(defaultNakup.typdph_data)
            ) {
              defaultNakup.typdph_data = $scope.nakup.typdph_data;
            }
          } else {
            $scope.nakup.typdph_data = undefined;
          }
        }
      });
    }
    $scope.setDodanie = function () {
      $scope.nakup.d_zdp = $scope.nakup.d_vyst;
      $scope.setUplatDPH();
    };
    $scope.setUplatDPH = function () {
      $scope.nakup.uplat_dph = $scope.nakup.d_zdp;
    };

    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    var today = new Date();
    var splat = new Date(
      today.setDate(today.getDate() + Global.get("splatnost_fa"))
    );

    if (
      !angular.isDefined(Global.get("nakup_hlavicka")) ||
      !angular.isDefined(Global.get("nakup_pata"))
    ) {
      $scope.typChanged(undefined, true);
    }

    var defaultNakup = {
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get("lastPrevadzka").id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      hlavicka: Global.get("nakup_hlavicka"),
      konecny_prijemca: null,
      pata: Global.get("nakup_pata"),
      mena: null,
      sd1: Global.get("sd1"),
      sd2: Global.get("sd2"),
      d_splat: getCurrentDate(splat),
      rozuctovanie: [],
    };
    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.pohybColumns = [
      { width: 30 },
      { field: "kod", label: "pohyb.KOD", width: 120 },
      { field: "nazov", label: "pohyb.NAZOV" },
      { field: "sklad", label: "pohyb.SKLAD" },
      { field: "mp", label: "pohyb.MNOZSTVO", width: 90 },
      { field: "cena_prijem", label: "pohyb.CENAMJ", width: 105 },
      { field: "zlava", label: "fakturacia.ZLAVAP", width: 65 },
      { field: "mj", label: "pohyb.MJ", width: 50 },
      { field: "dph", label: "pohyb.DPH", width: 60 },
      { field: "poznamka", label: "fakturacia.POZNAMKA", width: 150 },
      { width: 60 },
    ];
    if (!Profile.get("platcadph")) {
      var omitted = ["dph"];
      $scope.pohybColumns = _.reject($scope.pohybColumns, function (obj) {
        return omitted.indexOf(obj.field) !== -1;
      });
    }
    $scope.pohyb = [];

    var updateShowVAT = function () {
      $scope.showVATfields = true;
      $scope.nakup.dd = DokladTypy.NA.dd;
      defaultNakup.dd = $scope.nakup.dd;
    };

    if (!isEmpty(nakup)) {
      $scope.nakup = Nakup.copy(nakup);
      _.defaults($scope.nakup, defaultNakup);
      NakupTyp.restangularize($scope.nakup.typ_data);
      $scope.prevadzky = _.filter($scope.prevadzky, {
        id: $scope.nakup.prevadzka,
      });
      if ($scope.prevadzky.length)
        $scope.currentPrevadzka = $scope.prevadzky[0];
      if (!nakup.pohyb || $scope.nakup.id) {
        $scope.nakup.getList("pohyb").then(function (pohyb) {
          $scope.RecordCtrl.related.paginateList(pohyb, 20, $scope.sorter);

          $timeout(function () {
            $scope.recountSums();
          });
        });
      } else {
        $timeout(function () {
          for (var i = 0; i < nakup.pohyb.length; i++) {
            $scope.RecordCtrl.related.add(nakup.pohyb[i]);
          }
          delete nakup.pohyb;
        });
      }
    } else {
      $scope.nakup = Nakup.restangularize(angular.copy(defaultNakup));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.nakup.prevadzka,
      });
      $timeout(function () {
        $scope.RecordCtrl.related.paginateList([], 20, $scope.sorter);
      });
    }
    $scope.newItem = Pohyb.restangularize({
      dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
    });
    updateShowVAT();
    $scope.skladChanged(
      $scope.currentPrevadzka.sklady.length
        ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
        : undefined
    );
    var defaultPohybItem = angular.copy($scope.newItem);

    var pohybPreSave = function (item, create) {
      if (!item.karta_data) {
        item.kod = null;
        if (item.karta) {
          item.nazov = item.karta;
          delete item.karta;
        }
      }
      if (angular.isDefined(item.cena_edit)) {
        if ($scope.nakup.primarna_zm && $scope.nakup.kurz > 0) {
          item.prepocitatKurzom($scope.nakup);
        } else {
          item.cena_prijem = item.cena_edit;
          item.cena_zm = 0;
        }
        delete item.cena_edit;
      }
      $scope.skladChanged(item.sklad, item);

      return item;
    };
    var pohybPostSave = function (item, created) {
      $("#pohyb_kod").focus();
      $scope.recountSums();
      angular.extend($scope.newItem, {
        dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
      });
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
      Pohyb.updateSkladLabelsWithQty($scope);
      if (document.getElementById("pohyb_kod")) {
        document.getElementById("pohyb_kod").scrollIntoView();
      }
      return item;
    };
    var pohybPreEdit = function (item) {
      if ($scope.nakup.primarna_zm) item.cena_edit = item.cena_zm;
      else item.cena_edit = item.cena_prijem;
      return item;
    };
    $scope.recountSums = function () {
      $scope.sumaFakturyDPH = 0;
      $scope.sumaFaktury = 0;
      var promise = $scope.nakup.recountSums(
        $scope.RecordCtrl.related.getFullList()
      );
      promise.then(function (sums) {
        $scope.sumaFakturyDPH = sums.spolu;
        $scope.sumaFaktury =
          Math.round((sums.zaklad_d1 + sums.zaklad_d2 + sums.zaklad_d0) * 100) /
          100.0;
      });
      return promise;
    };
    $scope.recountSumsDelayed = function () {
      $timeout(function () {
        $scope.recountSums();
      });
    };
    var fakturaDataChanged = function () {
      NakupTyp.restangularize($scope.nakup.typ_data);
      $scope.recountSums();
      if ($scope.nakup.id)
        $scope.prevadzky = _.filter(angular.copy(Profile.get("prevadzky")), {
          id: $scope.nakup.prevadzka,
        });
      else $scope.prevadzky = angular.copy(Profile.get("prevadzky"));
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.nakup.prevadzka,
      });
      updateShowVAT();
    };
    var fakturaPreSave = function (data) {
      delete data.doklad;
      delete data.faktura;
      if ($scope.nakup.d_vyst && !Profile.checkObdobie($scope.nakup.d_vyst))
        alert("Dátum nespadá do prednastaveného obdobia!");
      // vynulovat ceny v ZM, ak sa medzicasom zrusila mena
      if (parseFloat($scope.nakup.kurz)) {
        $scope.kurzChanged();
      }
      return data;
    };
    var fakturaPostSave = function (data) {
      // $scope.recountSums().then(function(newSums) {
      //   copyWeakSrc(newSums, data);
      // });
      Profile.parseObdobie(data.d_vyst);
      return data;
    };

    $scope.RecordCtrl.main.init({
      endpoint: "nakupy",
      model: "nakup",
      master: nakup,
      list: nakupy,
      defaultItem: defaultNakup,
      dataChanged: fakturaDataChanged,
      focusField: function () {
        return $scope.nakup.id ? "firma" : "typ";
      },
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "pohyb",
      lookup: "doklad",
      object: Pohyb,
      preSave: pohybPreSave,
      postSave: pohybPostSave,
      preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    $scope.setPrevadzka = function (prevadzka) {
      $scope.nakup.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? Profile.get('userData').predvoleny_sklad || $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
    };

    $scope.mainFormSubmitter = function ($event) {
      if ($event.which === 13 && !$event.shiftKey) {
        if (angular.equals($scope.newItem, defaultPohybItem)) {
          $scope.ok();
        } else {
          $scope.RecordCtrl.related.add($scope.newItem);
        }
      }
    };
    $scope.sumaFaktury = 0;
    $scope.sumaFakturyDPH = 0;

    $scope.updateCurrency = function () {
      $scope.nakup.primarna_zm = false;
      if ($scope.nakup.mena_data) {
          $scope.nakup.primarna_zm = true;
      }
    };
    $scope.kurzChanged = function () {
      this.updateCurrency();
      var list = $scope.RecordCtrl.related.getFullList();
      $scope.nakup.prepocitatPohybKurzom(list);
      for (var i = 0; i < list.length; i++) {
        $scope.RecordCtrl.related.edit(list[i]);
      }
      // update also items which are currently being edited
      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.nakup.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_prijem;
        }
      });
    };

    $scope.inProgress = false;
    $scope.send = function () {
      if (!$scope.nakup.firma_data || !$scope.nakup.firma_data.email) {
        Dialog.alert("Firma nemá zadaný e-mail.");
        return;
      }
      if ($scope.RecordCtrl.main.validateAndSave()) {
        Outputs.sendMail(
          $scope.nakup.firma_data.email,
          Global.get(
            "text_email_nakup_subject",
            "Objednávka č. $DOKLAD"
          ).replace("$DOKLAD", $scope.nakup.iddokd),
          Global.get("text_email_nakup"),
          $scope.nakup,
          "Odoslanie faktúry"
        ).then(
          function (data) {
            Global.set("text_email_nakup", data.mailData.message);
            Global.set("text_email_nakup_subject", data.mailMessage.subject);
            Mailbox.syncCreate(data.mailMessage);
          },
          function () {
            console.log("cancelled");
          }
        );
      }
    };
    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function (firma) {
      $scope.nakup.firma_data = firma;
      $scope.nakup.zlava = firma.zlava;
      if (firma.splatnost) {
        today = angular.copy(parseDate($scope.nakup.d_vyst));
        $scope.nakup.d_splat = getCurrentDate(
          new Date(today.setDate(today.getDate() + firma.splatnost))
        );
      }
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: nakup });
      });
    };

    var selectKarta = function (item, parent) {
      var into = parent || $scope.newItem;
      copyWeakSrc(_.pick(item, "kod", "nazov", "dph", "mj", "mo"), into);
      into.karta = item.id;
      if (!Profile.get("svb")) {
        into.cena_edit = item.cena_sklad;
      }
      if (!parent) {
        Pohyb.updateSkladLabelsWithQty($scope, item);
        $timeout(function () {
          $("#pohyb_mp").focus();
        });
      }
    };
    $scope.changedItem = function () {
      $timeout(function () {
        if (!$scope.newItem.karta_data) {
          Pohyb.updateSkladLabelsWithQty($scope);
        }
      });
    };
    $scope.selectedItem = function (item) {
      selectKarta(item, null);
    };
    $scope.selectedEditItem = function (item, parent) {
      selectKarta(item, parent);
    };
    var extendKartaFromParent = function (parent) {
      if (!parent) {
        return {};
      }
      return {
        nazov: parent.nazov,
        mo: parent.cena_edit,
        dph: parent.dph,
        mj: parent.mj,
        modph:
          parseFloat(parent.cena_edit) * ((parseInt(parent.dph) + 100) / 100.0),
      };
    };
    $scope.addKartaKod = function (kod, parent) {
      return Karta.add(kod, extendKartaFromParent(parent));
    };
    $scope.addKartaNazov = function (nazov) {
      return Karta.add(
        undefined,
        angular.extend(extendKartaFromParent($scope.newItem), { nazov: nazov })
      );
    };
    $scope.recountNaklady = function () {
      this.updateCurrency();
      var promise = $scope.nakup.recountNaklady(
        $scope.RecordCtrl.related.getFullList(),
        Global.get("mena").toLowerCase()
      );
      promise.then(function (data) {
        for (var i = 0; i < data.length; i++) {
          $scope.RecordCtrl.related.edit(data[i]);
        }
      });
      return promise;
    };
    $scope.parseCenaSkladTooltip = function (item) {
      var tt = "Cena s nákladmi: " + $filter("suma")(item.cena_naklady);
      if (item.karta) {
        var mo = item.mo || item.karta_data.mo;
        tt += "<br/>Predajná cena: " + $filter("suma")(mo, 2);
        if (parseFloat(mo)) {
          tt +=
            "<br/>Marža: " +
            $filter("suma")(
              (parseFloat(mo) / parseFloat(item.cena_prijem) - 1) * 100.0,
              2
            ) +
            " %";
        }
      }
      return tt;
    };

    $scope.getParovanie = function (query, pageQuery) {
      if (/^(VF|DF)[0-9]+$/.test(query)) {
        query = query.replace("VF", "").replace("DF", "");
      }
      var original = $scope.nakup.id
        ? _.find(angular.isFunction(nakupy) ? nakupy() : nakupy, {
            id: parseInt($scope.nakup.id),
          })
        : undefined;
      return Nakup.parovanie().getList(
        angular.extend(
          {
            firma: $scope.nakup.firma,
            search: query,
            include: original ? original.parovanie : undefined,
          },
          pageQuery
        )
      );
    };
    $scope.editParovaciDoklad = function (doklad) {
      Doklad.editRecord(doklad);
    };

    $scope.getUcel = function (query) {
      return NakupUcel.get(
        query,
        $scope.nakup.typ ? { typ: $scope.nakup.typ } : { typ__isnull: 1 }
      );
    };
    $scope.addUcel = function (text) {
      return NakupUcel.add(text, { typ: $scope.nakup.typ });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.saveAndContinue = function (item) {
      $scope.RecordCtrl.related.add(item);
      $scope.RecordCtrl.main.validateAndSave();
    }

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function () {
      var prevadzka = _.find($scope.prevadzky, { id: $scope.nakup.prevadzka });
      var modalInstance = $modal.open({
        templateUrl: "admin/settings/printSettings.html",
        controller: "PrintSettingsCtrl",
        backdrop: "static",
        windowClass: "printsettings-detail",
        resolve: {
          record: function () {
            return prevadzka;
          },
          prevadzky: function () {
            return $scope.prevadzky;
          },
        },
      });
      modalInstance.result.then(function (data) {
        var profilePrevadzka = _.find(Profile.get("prevadzky"), {
          id: data.prevadzka.id,
        });
        if (profilePrevadzka) {
          angular.copy(data.prevadzka, profilePrevadzka);
        }
      });
    };
  },
]);
