'use strict';

import { isEmpty } from '../utils/utils-helpers';
import * as _ from 'lodash';

declare var angular: angular.IAngularStatic;

angular.module('casist.core').directive('appMenu', [
  '$rootScope',
  'Global',
  '$translate',
  '$location',
  'TabService',
  '$timeout',
  'Auth',
  'Restangular',
  '$window',
  'Socket',
  'Notification',
  'Permission',
  '$sessionStorage',
  '$route',
  '$http',
  'Dialog',
  '$state',
  function(
    $rootScope,
    Global,
    $translate,
    $location,
    TabService,
    $timeout,
    Auth,
    Restangular,
    $window,
    Socket,
    Notification,
    Permission,
    $sessionStorage,
    $route,
    $http,
    Dialog,
    $state
  ) {
    return {
      template: require('./appmenu.html'),
      restrict: 'EA',
      scope: {},
      link: function postLink(scope: any, element, attrs) {
        scope.lang = 'sk';
        scope.isAuth =
          Global.get('serverName') !== null &&
          !isEmpty(Auth.profile().get('agendy'));

        scope.$on('event:auth-loginConfirmed', function() {
          if (!isEmpty(Auth.profile().get('agendy'))) {
            scope.isAuth = true;
          }
          setAgendy();
          setObdobia();
          scope.user = Auth.profile().data().userData;
        });
        scope.$on('event:auth-loginRequired', function() {
          scope.isAuth = false;
        });

        // for mobile display
        var closeToggleNav = function() {
          if ($('.navbar-toggle').css('display') === 'none') {
            return;
          }
          $timeout(function() {
            $('.navbar-toggle').trigger('click');
          });
        };
        scope.closeToggleNav = closeToggleNav;

        var setAgendy = function() {
          scope.agendy = Auth.profile().get('agendy');
          scope.agendyExternal = Auth.profile().get('agendyExternal');
          scope.agenda = Auth.profile().get('agenda');
        };
        Auth.profile().addObserver('agenda', setAgendy);
        Auth.profile().addObserver('agendy', setAgendy);

        var setObdobia = function() {
          scope.obdobia = Auth.profile().get('obdobia');
          if (scope.obdobia && scope.obdobia.length) {
            scope.obdobie =
              Auth.profile().get('obdobie') &&
                _.find(scope.obdobia, { rok: Auth.profile().get('obdobie') })
                ? { rok: Auth.profile().get('obdobie') }
                : scope.obdobia[0];
            // update profile value only if it's changed, to prevent firing unwanted handlers
            if (Auth.profile().get('obdobie') !== scope.obdobie.rok) {
              Auth.profile().set('obdobie', scope.obdobie.rok);
            }
          }
        };
        setAgendy();
        setObdobia();
        scope.user = Auth.profile().data().userData;
        Auth.profile().addObserver('obdobia', setObdobia);
        Auth.profile().addObserver('obdobie', setObdobia);
        Socket.on('obdobiaCreated', function(data) {
          var obdobia = Auth.profile().get('obdobia');
          obdobia.push(data);
          Auth.profile().set('obdobia', obdobia);
        });

        scope.switchDirectory = function(directory) {
          var data = { agenda: directory.id };
          // extend with the current workspace
          angular.extend(data, { workspace: TabService.saveWorkspace() });
          Restangular.all('settings')
            .all('session')
            .post(data)
            .then(
              function(data) {
                Auth.setAgendaHeader(directory.id);
                Auth.profile().set('prevadzky', data.prevadzky);
                Auth.profile().set('pokladne', data.pokladne);
                Auth.profile().set('lastPrevadzka', data.lastPrevadzka);
                Auth.profile().set('lastPokladna', data.lastPokladna);
                Auth.profile().set('lastUcty', data.lastUcty);
                Auth.profile().set('lastVypis', data.lastVypis);
                Auth.profile().set('obdobia', data.obdobia);
                TabService.loadWorkspace(
                  data.workspace && data.workspace.workspace
                    ? JSON.parse(data.workspace.workspace)
                    : null
                );
                TabService.saveWorkspace();
                var perms = Permission.parseUserPermissions(
                  data.perms,
                  Auth.profile().data().userData
                );
                Auth.profile().set('perms', perms);
                delete data.settings.id;
                delete data.settings.agenda;
                Global.appendArray(data.settings);
                Global.remove([
                  'faktura_hlavicka',
                  'faktura_pata',
                  'ponuka_hlavicka',
                  'ponuka_pata',
                ]);
                Auth.profile().set('layouts', data.layouts);
                Auth.profile().set('platcadph', directory.icdph !== '');
                Auth.profile().set(
                  'jednoducheUcto',
                  data.agenda.jednoduche_ucto
                );
                Auth.profile().set('svb', data.agenda.svb);
                Auth.profile().set('ubytovanie', data.agenda.ubytovanie);
                Auth.profile().set('agenda', directory, true);

                $sessionStorage.settings = Global.get();
                $sessionStorage.profile = Auth.profile().data();
                Socket.emit('unregisterClient', {
                  clientID:
                    Global.get('serverAddress') +
                    ':' +
                    Global.get('serverName'),
                });
                Global.set('serverName', directory.id);
                Socket.emit('registerClient', {
                  clientID:
                    Global.get('serverAddress') +
                    ':' +
                    Global.get('serverName'),
                });

                $state.go($state.current, {}, { reload: true });
              },
              function(error) {
                // $sessionStorage.accesstoken = sessionBackup.accesstoken;
                // $sessionStorage.refreshtoken = sessionBackup.refreshtoken;
                // $http.defaults.headers.common.Authorization = 'Bearer ' + $sessionStorage.accesstoken;
                // Global.appendArray(globalBackup);
                // Restangular.setBaseUrl(Global.get('serverAddress'));
                Dialog.alert('Nastala chyba pri zmene agendy.', 'Chyba');
              }
            );
          closeToggleNav();
        };
        scope.switchPeriod = function(period) {
          Restangular.all('settings')
            .all('session')
            .post({ obdobie: period.rok })
            .then(
              function() {
                scope.obdobie = period;
                Auth.profile().set('obdobie', scope.obdobie.rok);
              },
              function() {
                Dialog.alert('Nastala chyba pri zmene obdobia.', 'Chyba');
              }
            );
          closeToggleNav();
        };
        scope.manageDirectories = function() {
          $location.url('/settings?tab=0');
          closeToggleNav();
        };
        scope.manageProfile = function() {
          $location.path('/users');
          closeToggleNav();
        };
        scope.manageSettings = function() {
          $location.path('/settings');
          closeToggleNav();
        };
        scope.switchLang = function(lang) {
          scope.lang = lang;
          $translate.uses(scope.lang);
          closeToggleNav();
        };

        scope.isAt = function(location) {
          if (location === '/') {
            if (
              $location
                .path()
                .match('/(priestory|odpocty|vyuctovanie|vlastnici)')
            ) {
              return true;
            }
          }
          return $location.path() === location;
        };
      },
    };
  },
]);
