'use strict';

angular.module('casist.admin')
  .constant('DanoveUrady', [
      {id: 'Bratislava', urad: 'Bratislava'},
      {id: 'Trnava', urad: 'Trnava'},
      {id: 'Trenčín', urad: 'Trenčín'},
      {id: 'Žilina', urad: 'Žilina'},
      {id: 'Banská Bystrica', urad: 'Banská Bystrica'},
      {id: 'Prešov', urad: 'Prešov'},
      {id: 'Košice', urad: 'Košice'},
      {id: 'pre vybrané daňové subjekty', urad: 'pre vybrané daňové subjekty'}
    ]);
angular.module('casist.admin')
  .factory('Agenda', ['Restangular', 'BaseModel', 'BaseCollectionModel', function (Restangular, BaseModel, BaseCollectionModel) {
    Restangular.extendModel('agendy', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.firma) || this.firma === '') {
            this.addError('firma', 'Firma nemôže byť prázdna');
            result = false;
          }
          if (!this.dic) {
            this.addError('dic', 'DIČ musí byť vyplnené');
            result = false;
          }
          if (!angular.isDefined(this.ulica) || this.ulica === '') {
            this.addError('ulica', 'Ulica nemôže byť prázdna');
            result = false;
          }
          if (!angular.isDefined(this.cislo) || this.cislo === '') {
            this.addError('cislo', 'Číslo nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.psc) || this.psc === '') {
            this.addError('psc', 'PSČ nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.mesto) || this.mesto === '') {
            this.addError('mesto', 'Mesto nemôže byť prázdne');
            result = false;
          }
          return result;
        }
      });
    });
    return angular.extend(BaseCollectionModel.object('settings/agendy'), {
      objectsUser: function() {
        return Restangular.service('myagendy', Restangular.all('settings'));
      }
    });
  }]);
