'use strict';

angular.module('casist.svb')
  .controller('OdpocetDetailCtrl', ['$scope', '$stateParams', '$state', 'Notification', 'Priestor', 'Dialog', '$timeout', 'Odpocet', 'Merac',
    function($scope, $stateParams, $state, Notification, Priestor, Dialog, $timeout, Odpocet, Merac) {
      var odpocet = {};

      var defaultOdpocet = {
        datum: getCurrentDate()
      };
      $scope.getDruhText = Merac.getDruhy;
      $scope.odpocet = angular.copy(defaultOdpocet);
      $scope.$on('vchodChanged', function(val) {
        $timeout(function() {
          loadPriestory($scope.odpocet.datum);
        });
      });
      var loadPriestory = function(val) {
        if (val && !isNaN(parseDate(val))) {
          Odpocet.getPriestory({datum: $scope.odpocet.datum, vchod: $scope.SearchCtrl.filters.top.vchod}).then(function (data) {
            $scope.priestory = data;
            _.each(data, function(obj) {
              obj.nazov = obj.nazov + ' / ' + obj.vlastnik_meno;
            });
            if (data.length) {
              $scope.odpocet.priestor = $scope.priestory[0].id;
            }
          });
        }
      };
      $scope.parsePriestorName = function(value) {
        return value;
      };
      $scope.$watch('odpocet.datum', loadPriestory);
      var events = Odpocet.registerCustomEvents(undefined, undefined, function(obj) {
        if (obj.datum === $scope.odpocet.datum) {
          loadPriestory($scope.odpocet.datum);
        }
      });
      $scope.$on('$destroy', function() {
        Odpocet.unregisterEvents(events);
      });
      $scope.priestorChanged = function() {
        $timeout(function() {
          var priestorData = _.find($scope.priestory, {id: parseInt($scope.odpocet.priestor)});
          if (priestorData) {
            $scope.odpocet.merace = angular.copy(priestorData.merace);
            _.each($scope.odpocet.merace, function(obj) {
              obj.sposob = Odpocet.getSposob()[0].id;
              obj.posledny_stav = obj.stav;
              delete obj.stav;
            });
          } else {
            $scope.odpocet.merace = [];
          }
          $timeout(function() {
            $('#stav-0').focus();
          });
        });
      };

      $scope.save = function() {
        var odpocet = [];
        _.each($scope.odpocet.merace, function(merac) {
          odpocet.push({
            datum: $scope.odpocet.datum,
            priestor: $scope.odpocet.priestor,
            merac: merac.id,
            koeficient: merac.koeficient,
            stav: merac.stav || 0,
            sposob: merac.sposob
          })
        });
        Odpocet.createBulk(odpocet).then(function(data) {
          $scope.priestory.splice($scope.priestory.indexOf(_.find($scope.priestory, {id: parseInt($scope.odpocet.priestor)})), 1);
          defaultOdpocet.datum = $scope.odpocet.datum;
          $scope.odpocet = angular.copy(defaultOdpocet);
          if ($scope.priestory.length) {
            $scope.odpocet.priestor = $scope.priestory[0].id;
            $scope.priestorChanged();
            $timeout(function() {
              $('#stav-0').focus();
            });
          }
          Notification.add('Odpočet bol uložený.', 'success', 5);
        });
      };
  }]);
