"use strict";

angular
  .module("casist.widgets")
  .directive("linkedElement", [
    "$parse",
    "$compile",
    "$timeout",
    "$position",
    "$q",
    "$document",
    function ($parse, $compile, $timeout, $position, $q, $document) {
      var HOT_KEYS = [9, 13, 27, 38, 40, 32];
      const DEFAULT_PAGINATE_BY = 10;
      return {
        restrict: "A",
        require: "?ngModel",
        scope: {
          ngModel: "=",
          linkedElementValue: "=",
          linkedElement: "&",
          selection: "=linkedElementSelection",
          query: "=linkedElementQuery",
          selectCallback: "&linkedOnSelect",
          loadingChanged: "&linkedLoadingChanged",
          removeCallback: "&linkedOnRemove",
          route: "@",
          parentObject: "=",
        },
        link: {
          post: function postLink(scope, element, attrs, ngModel) {
            if (!ngModel) return;
            var lookupField = angular.isDefined(attrs.linkedElementLookup)
              ? attrs.linkedElementLookup
              : "text";
            var editable = angular.isDefined(attrs.linkedElementEditable)
              ? scope.$eval(attrs.linkedElementEditable)
              : false;
            var fetchMissingValue = angular.isDefined(attrs.linkedFetchValue)
              ? scope.$eval(attrs.linkedFetchValue)
              : false;
            // var loadingProgress = $parse(attrs.linkedLoadingProgress).assign || angular.noop;
            if (!angular.isDefined(attrs.linkedLoadingChanged)) {
              scope.loadingChanged = function () {
                return angular.noop;
              };
            }
            var wait = scope.$eval(attrs.linkedElementWait);
            var loadingInProgress = false;
            var minSearch = angular.isDefined(attrs.linkedMinSearch)
              ? scope.$eval(attrs.linkedMinSearch)
              : 1;
            var callbackDelayed;
            scope.popupStyle = scope.$eval(attrs.popupStyle);
            scope.moreItems = 0;
            scope.pages = 0;
            scope.page = 1;

            if (!angular.isDefined(wait)) wait = true;

            var popUpEl = angular.element("<div linked-field-popup></div>");
            popUpEl.attr({
              matches: "matches",
              active: "activeIdx",
              select: "select(activeIdx)",
              "page-changed": "pageChanged(page)",
              query: "query",
              position: "position",
              "more-items": "moreItems",
              pages: "pages",
              page: "page",
              "popup-style": "popupStyle",
            });
            if (angular.isDefined(attrs.linkedPopupTemplate))
              popUpEl.attr("template-url", attrs.linkedPopupTemplate);
            if (angular.isDefined(attrs.linkedOnRemove))
              popUpEl.attr("remove", "remove(activeIdx)"), (scope.query = "");
            var resetMatches = function () {
              scope.matches = [];
              scope.activeIdx = -1;
            };

            resetMatches();

            scope.select = function (activeIdx) {
              var closePopup = angular.isDefined(arguments[1])
                ? arguments[1]
                : true;
              var changeFocus = angular.isDefined(arguments[2])
                ? arguments[2]
                : true;
              var callSelectCallback = angular.isDefined(arguments[3])
                ? arguments[3]
                : true;

              scope.selection = scope.matches[activeIdx].model;
              if (!editable) {
                if (scope.selection.id) scope.ngModel = scope.selection.id;
                else scope.ngModel = scope.selection;
              } else {
                if (attrs.linkedElementLookup)
                  scope.ngModel = scope.selection[attrs.linkedElementLookup];
                else scope.ngModel = scope.matches[activeIdx].model;
              }

              if (closePopup) {
                resetMatches();
              } else scope.activeIdx = activeIdx;

              if (element.hasClass("warning")) {
                element.removeClass("warning");
              }

              // loadingProgress(scope.$parent, false);
              scope.loadingChanged()(false);
              loadingInProgress = false;

              if (angular.isDefined(attrs.linkedElementValue)) {
                scope.linkedElementValue = scope.selection;
              }

              if (
                (callSelectCallback ||
                  (closePopup && callbackDelayed === scope.ngModel)) &&
                angular.isDefined(scope.selectCallback())
              ) {
                callbackDelayed = undefined;
                scope.selectCallback()(scope.selection, scope.parentObject);
              }

              if (changeFocus) {
                element[0].focus();
                hasFocus = true;
              }
            };
            scope.remove = function (activeIdx) {
              if (angular.isDefined(scope.selectCallback)) {
                var promise = scope.matches[activeIdx].model.remove({}, { 'content-type': 'application/json' });
                if (promise) {
                  promise.then(function (result) {
                    if (scope.selection == scope.matches[activeIdx].model) {
                      scope.selection = undefined;
                      if (angular.isDefined(attrs.linkedElementValue)) {
                        scope.linkedElementValue = scope.selection;
                      }
                    }
                    scope.matches.splice(activeIdx, 1);
                    if (activeIdx >= scope.matches.length) {
                      activeIdx = scope.matches.length - 1;
                    }
                  });
                }
              }
            };
            if (scope.route) {
              scope.$on("casist:dataChanged", function (event, data) {
                if (data.type === scope.route && data.operation === 3) {
                  if (
                    scope.selection &&
                    scope.selection.id === data.payload.id
                  ) {
                    var text = scope.selection[lookupField];
                    scope.selection = undefined;
                    if (angular.isDefined(attrs.linkedElementValue)) {
                      scope.linkedElementValue = scope.selection;
                    }
                    scope.ngModel = undefined;
                    $timeout(function () {
                      scope.query = text;
                      element.val(scope.query);
                      if (!editable) {
                        element.addClass("warning");
                      }
                    });
                  }
                }
              });
            }
            element.bind("keydown", function (evt) {
              if (scope.query && evt.ctrlKey && evt.which == 32) {
                hasFocus = true;
                getMatches(scope.query);
                evt.preventDefault();
              }
              //typeahead is open and an "interesting" key was pressed
              if (
                scope.matches.length === 0 ||
                HOT_KEYS.indexOf(evt.which) === -1
              ) {
                return;
              }

              if (evt.which === 40) {
                evt.preventDefault();
                scope.activeIdx = (scope.activeIdx + 1) % scope.matches.length;
                scope.$digest();
              } else if (evt.which === 38) {
                evt.preventDefault();
                scope.activeIdx =
                  (scope.activeIdx ? scope.activeIdx : scope.matches.length) -
                  1;
                scope.$digest();

                // } else if ((evt.which === 13 && !evt.altKey) || evt.which === 9) {
              } else if (evt.which === 13 && !evt.altKey) {
                evt.preventDefault();
                scope.$apply(function () {
                  scope.select(scope.activeIdx);
                });
              } else if (evt.which === 27) {
                evt.preventDefault();
                evt.stopPropagation();

                resetMatches();
                scope.$digest();
              } else if (evt.which == 9) {
                resetMatches();
                scope.$digest();
              }
            });

            var dismissClickHandler = function (evt) {
              if (element[0] !== evt.target) {
                resetMatches();
                scope.$digest();
              }
            };

            var focusHandler = function () {
              // trigger focus handler only when we have no selection and nothing is being loaded
              if (
                !hasFocus &&
                !loadingInProgress &&
                !scope.selection &&
                !scope.query &&
                !scope.matches.length
              ) {
                // loadingProgress(scope.$parent, true);
                scope.loadingChanged()(true);
                loadingInProgress = true;
                hasFocus = true;
                scope.page = 1;
                getMatches(ngModel.$viewValue);
              }
            };

            $document.bind("click", dismissClickHandler);

            scope.$on("$destroy", function () {
              $popup.remove();
              $document.unbind("click", dismissClickHandler);
              if (angular.isDefined(attrs.showOnFocus)) {
                element.unbind("click", focusHandler);
                element.unbind("focus", focusHandler);
              }
            });

            var hasFocus,
              suppressQueryClearing = false;
            var lastQuery = undefined;

            scope.pageChanged = function (page) {
              scope.page = page;
              // loadingProgress(scope.$parent, true);
              scope.loadingChanged()(true);
              loadingInProgress = true;
              hasFocus = true;
              getMatches(ngModel.$viewValue);
            };
            var getMatches = function (query) {
              $q.when(
                scope.$eval(scope.linkedElement)(
                  query,
                  {
                    page_size: attrs.paginateBy || DEFAULT_PAGINATE_BY,
                    page: scope.page,
                  },
                  scope.parentObject
                )
              ).then(
                function (matches) {
                  // it might happen that several async queries were in progress if a user were typing fast
                  // but we are interested only in responses that correspond to the current view value
                  // if (query === ngModel.$viewValue) { -- Nemoze sa pouzit, lebo ak bol nejaky iny parser predtym, ktory zmenil viewValue, tak query s tym nebude sediet
                  if (query === lastQuery) {
                    if (angular.isDefined(attrs.linkedElementQuery))
                      scope.query = query;
                    if (matches.length > 0) {
                      if (
                        scope.activeIdx == -1 ||
                        scope.activeIdx >= matches.length
                      )
                        scope.activeIdx = 0;
                      scope.matches = [];

                      var foundExactMatch = -1;
                      var maxItem = Math.min(
                        matches.length,
                        angular.isDefined(attrs.paginateBy) &&
                          !!attrs.paginateBy
                          ? parseInt(attrs.paginateBy)
                          : DEFAULT_PAGINATE_BY
                      );
                      if (angular.isDefined(attrs.linkedElementLookup)) {
                        for (var i = 0; i < maxItem; i++) {
                          scope.matches.push({
                            label: matches[i][attrs.linkedElementLookup],
                            model: matches[i],
                          });
                          if (scope.matches[i].label == query)
                            foundExactMatch = i;
                        }
                      } else {
                        for (var i = 0; i < maxItem; i++) {
                          scope.matches.push({
                            label: matches[i],
                            model: matches[i],
                          });
                          if (scope.matches[i].label === query)
                            foundExactMatch = i;
                        }
                      }
                      if (foundExactMatch !== -1) {
                        scope.select(
                          foundExactMatch,
                          !hasFocus,
                          hasFocus,
                          false
                        );
                        callbackDelayed = scope.ngModel;
                      } else if (!hasFocus) {
                        scope.matches = [];
                      }

                      scope.position = $position.offset(element);
                      scope.position.top =
                        scope.position.top + element.prop("offsetHeight");

                      if (angular.isDefined(attrs.paginateBy)) {
                        scope.pages = Math.ceil(
                          parseInt(matches.count) / parseInt(attrs.paginateBy)
                        );
                      } else {
                        scope.moreItems = matches.length - scope.matches.length;
                      }
                    } else {
                      resetMatches();
                    }
                  }
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                },
                function () {
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                  resetMatches();
                }
              );
            };

            var timeoutPromise = null;
            var suppressShowingResults = false;

            if (angular.isDefined(attrs.linkedElementValue)) {
              scope.$watch("linkedElementValue", function (val) {
                if (
                  (val && !element.val()) ||
                  (val &&
                    val[lookupField] &&
                    element.val() !== val[lookupField])
                ) {
                  ngModel.$setViewValue(val);
                }
              });
            }

            var checkItemExistence = function (query) {
              var deferred = $q.defer();
              // loadingProgress(scope.$parent, true);
              scope.loadingChanged()(true);
              loadingInProgress = true;
              $q.when(
                scope.$eval(scope.linkedElement)(
                  query,
                  {
                    page_size: attrs.paginateBy || DEFAULT_PAGINATE_BY,
                    page: scope.page,
                  },
                  scope.parentObject
                )
              ).then(
                function (matches) {
                  for (var i = 0; i < matches.length; i++) {
                    if (matches[i][attrs.linkedElementLookup] == query) {
                      deferred.resolve(matches[i]);
                      // loadingProgress(scope.$parent, false);
                      scope.loadingChanged()(false);
                      loadingInProgress = false;
                      return;
                    }
                  }
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                  deferred.reject();
                },
                function (error) {
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                  deferred.reject();
                }
              );
              return deferred.promise;
            };
            var fetchItemData = function (id) {
              var deferred = $q.defer();
              // loadingProgress(scope.$parent, true);
              scope.loadingChanged()(true);
              loadingInProgress = true;
              $q.when(
                scope.$eval(scope.linkedElement)(
                  undefined,
                  {
                    page_size: attrs.paginateBy || DEFAULT_PAGINATE_BY,
                    page: scope.page,
                  },
                  scope.parentObject
                )
              ).then(
                function (matches) {
                  for (var i = 0; i < matches.length; i++) {
                    if (matches[i].id === id) {
                      deferred.resolve(matches[i]);
                      // loadingProgress(scope.$parent, false);
                      scope.loadingChanged()(false);
                      loadingInProgress = false;
                      return;
                    }
                  }
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                  deferred.reject();
                },
                function (error) {
                  // loadingProgress(scope.$parent, false);
                  scope.loadingChanged()(false);
                  loadingInProgress = false;
                  deferred.reject();
                }
              );
              return deferred.promise;
            };
            var parseSelection = function (value) {
              var obj = {};
              obj[lookupField] = value;
              return obj;
            };
            var compareSelectionText = function (cmp1, cmp2) {
              if (angular.isDefined(attrs.linkedElementLookup))
                return (
                  cmp1[attrs.linkedElementLookup] ==
                  cmp2[attrs.linkedElementLookup]
                );
              else return cmp1["text"] == cmp2["text"];
            };

            if (angular.isDefined(attrs.showOnFocus)) {
              element.bind("click", focusHandler);
              element.bind("focus", focusHandler);
            }
            element.bind("keyup", function () {
              if (element.val() === "" && scope.selection) {
                scope.$apply(function () {
                  ngModel.$setViewValue(undefined);
                });
              }
            });

            ngModel.$parsers.push(function (viewValue) {
              lastQuery = viewValue;
              // this is called from value watcher
              if (angular.isObject(viewValue)) {
                scope.selection = viewValue;
                scope.query = viewValue[lookupField];
                element.val(scope.query);
                return viewValue.id || null;
              }
              hasFocus = true;
              scope.query = "";
              scope.page = 1;

              // assuming we have selection object
              if (suppressShowingResults && scope.selection) {
                suppressShowingResults = false;
                scope.query = viewValue;
                return scope.selection.id;
              }
              scope.selection = undefined;
              if (angular.isDefined(attrs.linkedElementValue)) {
                scope.linkedElementValue = scope.selection;
              }

              if (
                (viewValue && viewValue.length >= minSearch) ||
                angular.isDefined(attrs.showOnFocus)
              ) {
                // loadingProgress(scope.$parent, true);
                scope.loadingChanged()(true);
                loadingInProgress = true;
                if (wait) {
                  if (timeoutPromise) $timeout.cancel(timeoutPromise);
                  timeoutPromise = $timeout(function () {
                    getMatches(viewValue);
                  }, 100);
                } else {
                  getMatches(viewValue);
                }
              } else {
                // loadingProgress(scope.$parent, false);
                scope.loadingChanged()(false);
                loadingInProgress = false;
                if (!angular.isDefined(attrs.showOnFocus)) {
                  resetMatches();
                }
              }

              if (editable) {
                return viewValue;
              } else {
                if (!viewValue) {
                  // scope.linkedElementValue = {};
                  if (element.hasClass("warning"))
                    element.removeClass("warning");
                  return viewValue;
                } else {
                  return undefined;
                }
              }
            });

            ngModel.$formatters.push(function (modelValue) {
              lastQuery = modelValue;
              if (!modelValue) {
                if (scope.selection) {
                  scope.selection = undefined;
                  if (angular.isDefined(attrs.linkedElementValue)) {
                    scope.linkedElementValue = scope.selection;
                  }
                }
                scope.query = "";
                if (element.hasClass("warning")) {
                  element.removeClass("warning");
                }
                return modelValue;
              }

              // if editable, check make query to database and check if it exists to enable adding/editing
              if (editable && !angular.isDefined(scope.linkedElementValue)) {
                if (angular.isDefined(attrs.linkedElementQuery))
                  scope.query = modelValue;
                if (
                  !scope.selection ||
                  !scope.selection.id ||
                  !compareSelectionText(
                    scope.selection,
                    parseSelection(ngModel.$modelValue)
                  )
                ) {
                  checkItemExistence(modelValue).then(
                    function (found) {
                      scope.selection = found;
                    },
                    function () {
                      scope.selection = {};
                      scope.selection[lookupField] = modelValue;
                    }
                  );
                }
                return modelValue;
              } else {
                if (element.hasClass("warning")) {
                  element.removeClass("warning");
                }
                if (
                  !scope.selection ||
                  (!angular.isObject(modelValue) &&
                    angular.isDefined(scope.selection.id) &&
                    scope.selection.id !== modelValue) ||
                  (angular.isObject(modelValue) &&
                    scope.selection !== modelValue)
                ) {
                  if (angular.isObject(modelValue)) {
                    scope.query = modelValue[lookupField];
                    scope.selection = modelValue;
                    lastQuery = scope.query;
                    return scope.query;
                  } else {
                    scope.query = "";
                    if (fetchMissingValue) {
                      var deferred = $q.defer();
                      // loadingProgress(scope.$parent, true);
                      scope.loadingChanged()(true);
                      loadingInProgress = true;
                      fetchItemData(modelValue).then(
                        function (found) {
                          scope.selection = found;
                          scope.query = found[lookupField];
                          deferred.resolve(scope.query);
                          element.val(scope.query);
                          lastQuery = scope.query;
                          // loadingProgress(scope.$parent, false);
                          scope.loadingChanged()(false);
                          loadingInProgress = false;
                        },
                        function () {
                          scope.query = modelValue;
                          scope.selection = undefined;
                          deferred.resolve(scope.query);
                          element.val(scope.query);
                          lastQuery = scope.query;
                          // loadingProgress(scope.$parent, false);
                          scope.loadingChanged()(false);
                          loadingInProgress = false;
                        }
                      );
                      return "";
                    }
                  }
                } else {
                  if (!angular.isObject(scope.selection)) {
                    scope.query = scope.selection;
                  } else if (attrs.linkedElementLookup) {
                    scope.query = scope.selection[attrs.linkedElementLookup];
                  } else {
                    scope.query = modelValue;
                  }
                  if (scope.selection.id) {
                    scope.selection.id = modelValue;
                  }
                }
                lastQuery = scope.query;
                return scope.query;
              }
            });

            element.bind("blur", function (evt) {
              hasFocus = false;
              // put warning class to input in case there is no related model value
              if (!ngModel.$modelValue && scope.query) {
                if (!editable) {
                  element.addClass("warning");
                }
                suppressQueryClearing = true;
              } else if (
                callbackDelayed &&
                ngModel.$modelValue === callbackDelayed &&
                angular.isDefined(scope.selectCallback())
              ) {
                callbackDelayed = undefined;
                scope.selectCallback()(scope.selection, scope.parentObject);
              }
            });

            var $popup = $compile(popUpEl)(scope);
            $document.find("body").append($popup);
          },
        },
      };
    },
  ])
  .directive("linked", [
    "$compile",
    "$parse",
    "$timeout",
    function ($compile, $parse, $timeout) {
      return {
        restrict: "EA",
        replace: true,
        // transclude: true,
        require: "ngModel",
        scope: {
          source: "=",
          lookup: "@",
          wait: "@",
          value: "=",
          onAddCallback: "&onAdd",
          onEditCallback: "&onEdit",
          onSelectCallback: "&onSelect",
          onRemoveCallback: "&onRemove",
          // popupStyle: '=',
          model: "=ngModel",
          popupTemplate: "@",
          keypress: "@ngKeypress",
          errorMessages: "=errorModel",
          ngDisabled: "=",
          errorClass: "=",
          ngChange: "&",
          dataType: "@",
          parentObject: "=parent",
        },
        link: function (scope, element, attrs, ngModel) {
          scope.loadingInProgress = false;
          scope.linked = {
            selection: null,
            query: "",
          };
          // var editCallback = angular.isDefined(attrs.onEdit) ? $parse(attrs.onEdit) : undefined;
          // var addCallback = angular.isDefined(attrs.onAdd) ? $parse(attrs.onAdd) : undefined;
          var selectCallback = $parse(attrs.onSelect);
          var removeCallback = $parse(attrs.onRemove);
          var keypressCallback = $parse(attrs.ngKeypress);
          var onChangeCallback = angular.isDefined(attrs.ngChange);
          var showSelectionButton = angular.isDefined(attrs.showSelectionButton)
            ? scope.$eval(attrs.showSelectionButton)
            : true;
          scope.fetchMissingValue = angular.isDefined(attrs.fetchValue)
            ? scope.$eval(attrs.fetchValue)
            : false;
          var editable = angular.isDefined(attrs.editable)
            ? scope.$eval(attrs.editable)
            : false;

          scope.open = function () {
            console.log("Open better selection");
          };
          var updateViewValue = function (value) {
            var val = "";
            if (angular.isDefined(attrs.lookup)) val = value[attrs.lookup];
            else val = value["text"];
            if (editable && !angular.isDefined(attrs.value)) {
              scope.model = val;
            } else {
              scope.model = value.id;
              if (angular.isDefined(attrs.value)) {
                scope.value = value;
              }
            }
          };
          scope.add = function () {
            if (scope.loadingInProgress) return;
            var focusedElement = $el.find("input")[0] || angular.element(document.activeElement.id);
            if (scope.hasSelected()) {
              if (scope.canEdit()) {
                // var focusedElement = angular.element('#'+document.activeElement.id);
                var promise = scope.onEditCallback()(
                  scope.linked.selection,
                  scope.parentObject
                );
                if (promise) {
                  if (promise.then) {
                    promise.then(
                      function (result) {
                        scope.linked.selection = result;
                        updateViewValue(result);
                        scope.selected(result, scope.parentObject);
                        if (focusedElement) {
                          $timeout(function () {
                            focusedElement.focus();
                          });
                        }
                      },
                      function () {
                        if (focusedElement) {
                          $timeout(function () {
                            focusedElement.focus();
                          });
                        }
                      }
                    );
                  } else {
                    scope.linked.selection = promise;
                    updateViewValue(promise);
                    scope.selected(promise, scope.parentObject);
                    if (focusedElement) {
                      $timeout(function () {
                        focusedElement.focus();
                      });
                    }
                  }
                }
                //editCallback($scope.$parent, {sel: scope.selection});
              }
            } else {
              if (scope.canAdd()) {
                var promise = scope.onAddCallback()(
                  scope.linked.query,
                  scope.parentObject
                );
                if (promise) {
                  if (promise.then) {
                    promise.then(
                      function (result) {
                        scope.linked.selection = result;
                        updateViewValue(result);
                        scope.selected(result, scope.parentObject);
                        $el.find("input").removeClass("warning");
                        if (focusedElement) {
                          $timeout(function () {
                            focusedElement.focus();
                          });
                        }
                      },
                      function () {
                        if (focusedElement) {
                          $timeout(function () {
                            focusedElement.focus();
                          });
                        }
                      }
                    );
                  } else {
                    scope.linked.selection = promise;
                    updateViewValue(promise);
                    scope.selected(promise, scope.parentObject);
                    $el.find("input").removeClass("warning");
                    if (focusedElement) {
                      $timeout(function () {
                        focusedElement.focus();
                      });
                    }
                  }
                }
              }
            }
          };
          scope.selected = function () {
            if (selectCallback != angular.noop) {
              scope.onSelectCallback().apply(null, arguments);
            }
          };
          scope.remove = function () {
            if (removeCallback != angular.noop) {
              return scope.onRemoveCallback().apply(null, arguments);
            }
            return null;
          };
          scope.linkedReady = function () {
            return (
              scope.linked.query !== null &&
              scope.linked.query !== undefined &&
              scope.linked.query !== ""
            );
          };
          scope.loadingChanged = function (loading) {
            scope.loadingInProgress = loading;
            scope.loadingStatus = 0;
            if (loading) {
              angular.extend(scope.statusClass, {
                "fa-spinner": true,
                "fa-pencil": false,
                "fa-plus": false,
              });
            } else {
              scope.hasSelected();
            }
            // console.log(loading, scope.statusClass);
          };
          scope.canEdit = function () {
            return angular.isDefined(scope.onEditCallback());
          };
          scope.canAdd = function () {
            return angular.isDefined(scope.onAddCallback());
          };
          scope.hasSelected = function () {
            if (scope.loadingInProgress) {
              // scope.statusClass = 'fa-spinner fa-spin';
              return false;
            }
            var result =
              scope.linked.selection &&
              angular.isDefined(scope.linked.selection.id);
            angular.extend(scope.statusClass, {
              "fa-spinner": false,
              "fa-pencil": result === true,
              "fa-plus": result === false,
            });
            // console.log(attrs.inputName, 'has selected:', scope.statusClass);
            if (result) {
              scope.tooltipText = "Upraviť prepojenú položku (Shift+Enter)";
              // scope.statusClass = 'fa-pencil';
              scope.loadingStatus = 1;
            } else {
              scope.tooltipText = "Uložiť (Shift+Enter)";
              scope.loadingStatus = 2;
              // scope.statusClass = 'fa-plus';
              // console.log('2', scope.loadingInProgress, attrs.inputName, 'has selected:', result);
              if (angular.isDefined(scope.errorClass)) {
                if (showSelectionButton) {
                  scope.errorClass =
                    scope.linkedReady() || scope.loadingInProgress
                      ? "linked-add linked-selection-button"
                      : "linked linked-selection-button";
                } else {
                  scope.errorClass =
                    scope.linkedReady() || scope.loadingInProgress
                      ? "linked-add"
                      : "linked";
                }
              }
            }
            return result;
          };
          scope.buttonShown = function () {
            return (
              scope.loadingInProgress ||
              (scope.linkedReady() &&
                ((scope.canAdd() && !scope.hasSelected()) ||
                  (scope.canEdit() && scope.hasSelected())))
            );
          };
          var name = "";
          var id = "";
          var tabindex = "";
          var requestfocus = "";
          var removeattr = "";
          var keypress = "";
          var texteareaCollapse = "";
          var onchange = "";
          var disabled = "";
          var route = "";
          var linkedValue = "";
          if (angular.isDefined(attrs.value)) {
            linkedValue = ' linked-element-value="value"';
          }
          if (angular.isDefined(attrs.inputName))
            name = ' name="' + attrs.inputName + '"';
          if (angular.isDefined(attrs.inputId))
            id = ' id="' + attrs.inputId + '"';
          if (angular.isDefined(attrs.tabindex))
            tabindex = ' tabindex="' + attrs.tabindex + '"';
          if (angular.isDefined(attrs.requestFocus))
            requestfocus = ' request-focus="' + attrs.requestFocus + '"';
          if (angular.isDefined(attrs.collapsible)) {
            texteareaCollapse = ' collapsible="' + attrs.collapsible + '"';
          } else if (angular.isDefined(attrs.collapsed)) {
            texteareaCollapse = ' collapsed="' + attrs.collapsed + '"';
          }
          if (removeCallback != angular.noop)
            removeattr = ' linked-on-remove="remove"';
          if (keypressCallback != angular.noop)
            keypress = ' ng-keypress="$parent.' + attrs.ngKeypress + '"';
          if (angular.isDefined(attrs.ngDisabled)) {
            disabled = ' ng-disabled="ngDisabled"';
          }
          if (angular.isDefined(attrs.route)) {
            route = ' route="' + attrs.route + '"';
          }
          if (onChangeCallback) {
            onchange = ' ng-change="ngChange()"';
          }
          var inputBegin = "input";
          var inputEnd = "/>";
          if (attrs.type == "textarea") {
            inputBegin = "mctextarea";
            inputEnd = "></mctextarea>";
          }
          var inputClass = "";
          if (attrs.inputClass) {
            inputClass = " " + attrs.inputClass;
          }
          scope.loadingStatus = 0;
          scope.statusClass = {
            "fa-spinner": true,
            "fa-pencil": false,
            "fa-plus": false,
          };

          var el =
            '<div class="input-group input-group-sm col-xs-12"><' +
            inputBegin +
            disabled +
            ' ng-model="model"' +
            name +
            id +
            route +
            tabindex +
            requestfocus +
            removeattr +
            keypress +
            texteareaCollapse +
            onchange +
            ' autocomplete="off" linked-element="source" ' +
            (angular.isDefined(attrs.minSearch)
              ? ' linked-min-search="' + attrs.minSearch + '"'
              : "") +
            (angular.isDefined(attrs.placeholder)
              ? ' placeholder="' + attrs.placeholder + '"'
              : "") +
            (angular.isDefined(attrs.searchUtilsUnifilter)
              ? " search-utils-unifilter"
              : "") +
            (angular.isDefined(attrs.showOnFocus)
              ? ' show-on-focus="' + attrs.showOnFocus + '"'
              : "") +
            (angular.isDefined(attrs.paginateBy)
              ? ' paginate-by="' + attrs.paginateBy + '"'
              : "") +
            ' popup-style="' +
            attrs.popupStyle +
            '"' +
            linkedValue +
            ' linked-element-wait="{{wait}}" linked-loading-changed="loadingChanged" linked-element-lookup="{{lookup}}" linked-element-selection="linked.selection" linked-element-query="linked.query" linked-element-editable="' +
            (angular.isDefined(attrs.editable) ? attrs.editable : "false") +
            '" linked-on-select="selected" linked-popup-template="{{popupTemplate}}" linked-fetch-value="{{fetchMissingValue}}" parent-object="parentObject" class="form-control' +
            inputClass +
            '" mctooltip-event="focus" mctooltip-placement="top" mctooltip="errorMessages"' +
            inputEnd;
          el +=
            '<span style="vertical-align: top;" class="input-group-btn"' +
            (!showSelectionButton ? ' ng-show="buttonShown()"' : "") +
            '><div linked-status-indicator status="loadingStatus" ng-click="add()" ng-disabled="loadingInProgress" mctooltip="tooltipText" dynamic mctooltip-placement="bottom"></div>';
          if (showSelectionButton) {
            el +=
              '<button type="button" tabindex="-1" class="btn btn-primary btn-sm" ng-click="open()" mctooltip="Otvoriť ponuku (Alt+Enter)" mctooltip-placement="bottom"><i class="fa fa-bars"></i></button>';
          }
          el += "</span>";
          el += "</div>";
          var $el = $compile(el)(scope);
          $el.bind("keypress", function (evt) {
            if (!scope.linkedReady()) {
              return;
            }
            if (evt.altKey && evt.which == 13) {
              evt.stopPropagation();
              evt.preventDefault();
              scope.open();
            } else if (evt.shiftKey && evt.which == 13) {
              evt.stopPropagation();
              evt.preventDefault();
              scope.add();
            }
          });
          element.replaceWith($el);
        },
      };
    },
  ])
  .directive("linkedStatusIndicator", [
    "$timeout",
    function ($timeout) {
      return {
        restrict: "EA",
        scope: {
          status: "=",
        },
        replace: true,
        template:
          '<button type="button" tabindex="-1" class="btn btn-sm" style="width: 26px; padding-left: 2px; padding-right: 2px;"><div linked-status-indicator-icon="status"></div></button>',
        link: function (scope, element, attrs) {
          scope.$watch("status", function (val) {
            if (val === 1) {
              scope.indicatorClass = "fa-pencil";
              element.addClass("btn-default");
              element.removeClass("btn-success");
              element.removeClass("btn-info");
            } else if (val === 2) {
              scope.indicatorClass = "fa-plus";
              element.addClass("btn-success");
              element.removeClass("btn-info");
              element.removeClass("btn-default");
            } else {
              scope.indicatorClass = "fa-spinner";
              element.addClass("btn-info");
              element.removeClass("btn-success");
              element.removeClass("btn-default");
            }
          });
        },
      };
    },
  ])
  .directive("linkedStatusIndicatorIcon", [
    "$timeout",
    function ($timeout) {
      return {
        restrict: "EA",
        scope: {
          status: "=linkedStatusIndicatorIcon",
        },
        replace: true,
        template: '<i class="fa"></i>',
        link: function (scope, element, attrs) {
          scope.$watch("status", function (val) {
            if (val === 1) {
              element.addClass("fa-pencil");
              element.removeClass("fa-spinner fa-spin");
              element.removeClass("fa-plus");
            } else if (val === 2) {
              element.addClass("fa-plus");
              element.removeClass("fa-spinner fa-spin");
              element.removeClass("fa-pencil");
            } else {
              element.addClass("fa-spinner fa-spin");
              element.removeClass("fa-plus");
              element.removeClass("fa-pencil");
            }
          });
        },
      };
    },
  ])
  .directive("linkedFieldPopup", [
    "$parse",
    "$translate",
    "$window",
    "$timeout",
    function ($parse, $translate, $window, $timeout) {
      return {
        restrict: "EA",
        scope: {
          matches: "=",
          query: "=",
          active: "=",
          position: "=",
          select: "&",
          popupStyle: "=",
          remove: "&",
          moreItems: "=",
          pages: "=",
          page: "=",
          pageChanged: "&",
          templateUrl: "@",
        },
        replace: true,
        templateUrl: "components/widgets/linked-element-popup.html",
        link: function (scope, element, attrs) {
          scope.style = {};
          scope.dalsichZaznamov = $translate.instant("main.DALSICHZAZNAMOV");
          scope.$watch("popupStyle", function (val) {
            angular.copy(val, scope.style);
          });
          scope.$watch("position", function (val) {
            if (val) {
              var width = scope.style.width
                ? parseFloat(scope.style.width.replace("px", "")) || val.width
                : val.width;
              if (val.left + width > $window.innerWidth) {
                val.left = $window.innerWidth - width - 20;
              }
              $timeout(function () {
                var topHeight = val.top + element[0].offsetHeight;
                if (topHeight > $window.innerHeight + $window.scrollY) {
                  val.top -=
                    topHeight - ($window.innerHeight + $window.scrollY) + 20;
                }
                scope.style["top"] = val.top + "px";
                scope.style["left"] = val.left + "px";
              });
            }
            switch (scope.moreItems) {
              case 1:
                scope.dalsichZaznamov = $translate.instant("main.DALSIZAZNAM");
                break;
              case 2:
              case 3:
              case 4:
                scope.dalsichZaznamov = $translate.instant(
                  "main.DALSIEZAZNAMY"
                );
                break;
              default:
                scope.dalsichZaznamov = $translate.instant(
                  "main.DALSICHZAZNAMOV"
                );
            }
          });
          // if (angular.isDefined(attrs.templateUrl) && attrs.templateUrl != '')
          // scope.templateUrl = "components/widgets/"+attrs.templateUrl+".html";

          scope._select = function (activeIdx) {
            scope.select({ activeIdx: activeIdx });
          };
          scope._remove = function (activeIdx) {
            scope.remove({ activeIdx: activeIdx });
          };
          scope.canRemove = $parse(attrs.remove) !== angular.noop;
          // scope.isOpen = function () {
          //   return scope.matches.length > 0;
          // };
          scope.previousPage = function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            scope.pageChanged({ page: scope.page - 1 });
          };
          scope.nextPage = function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            scope.pageChanged({ page: scope.page + 1 });
          };
        },
      };
    },
  ])
  .directive("linkedFieldMatch", [
    "$http",
    "$templateCache",
    "$compile",
    "$parse",
    "$timeout",
    function ($http, $templateCache, $compile, $parse, $timeout) {
      return {
        restrict: "EA",
        scope: {
          matches: "=",
          query: "=",
          active: "=",
          select: "&",
          remove: "&",
          templateUrl: "=",
          canRemove: "=",
        },
        link: function (scope, element, attrs) {
          var tplUrl = scope.templateUrl
            ? "components/widgets/" + scope.templateUrl + ".html"
            : "components/widgets/linked-element-match.html";
          var removeCallback = $parse(attrs.remove);
          $http
            .get(tplUrl, { cache: $templateCache })
            .then(function (response) {
              element.replaceWith($compile(response.data.trim())(scope));
            });

          scope.isActive = function (matchIdx) {
            return scope.active == matchIdx;
          };

          scope.selectActive = function (matchIdx) {
            scope.active = matchIdx;
          };

          scope.selectMatch = function (activeIdx) {
            scope.select({ activeIdx: activeIdx });
          };

          scope.deleteMatch = function (event, activeIdx) {
            event.stopPropagation();
            scope.remove({ activeIdx: activeIdx });
          };
        },
      };
    },
  ]);
