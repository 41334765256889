"use strict";

angular.module("casist.ucto").factory("InternyDoklad", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    $translate
  ) {
    var ENDPOINT = "interne";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!angular.isDefined(this.firma) || this.firma === "") {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            result = false;
          }
          return result;
        },
      });
    });

    var openDetail = function (item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: "ucto/internedoklady/internedoklady-detail.html",
        controller: "InternyDokladDetailCtrl",
        backdrop: "static",
        keyboard: false,
        windowClass: "detail",
        resolve: {
          faktura: function () {
            return item ? item : {};
          },
          faktury: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return angular.extend({ predkontacie: true, ucty: true }, config);
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").internydoklad;
      },
      getUctoFields: function () {
        return [
          "zaklad_d1",
          "d1",
          "zaklad_d2",
          "d2",
          "zaklad_d0",
          "spolu",
          "suma1",
          "suma2",
          "suma3",
          "suma4",
          "suma5",
        ];
      },
      edit: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(doklad, list, searchCtrl, config).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
    });
  },
]);
