describe('Settings controller', function() {
  var $rootScope, $parentScope, $scope, Profile, ctrl, parentCtrl, createCtrl, $httpBackend, Restangular, $location, Agenda, Prevadzka, User, Group, Permission, Notification, DokladTypy;
  beforeEach(module('casist.core'));
  beforeEach(module('casist.sklad'));
  beforeEach(module('casist.ucto'));
  beforeEach(module('casist.admin'));
  beforeEach(module('casist.widgets'));
  beforeEach(module('angularFileUpload'));

  beforeEach(function(){
    this.addMatchers({
      toEqualData: function(expected) {
        return angular.equals(this.actual, expected);
      }
    });
  });

  var agendyData = [{firma: 'Ahoj', id: 1, active: true}, {firma: 'Cauko', id: 2, active: false}];
  var agendyActive = _.filter(agendyData, {active: true});
  var prevadzkyData = [{prevadzka: "Test", id: 1, agenda: 1}, {prevadzka: "Test 2", id: 2, agenda: 2}, {prevadzka: "Test 3", id: 3, agenda: 1}, {prevadzka: "Test 4", id: 4, agenda: 2}];
  var prevadzky2 = _.filter(prevadzkyData, {agenda: 2});
  var prevadzky1 = _.filter(prevadzkyData, {agenda: 1});
  var settingsData = [{sd1: 20, sd2: 20, desatiny_fa: 2}];
  var uctyData = [{"id":3,"record_created":"2015-02-17T17:22:35Z","kod":"B","iban":"---","bic":"---","interny":false}];

  beforeEach(inject(function(_$rootScope_, _$controller_, _$httpBackend_, _Profile_, _Restangular_, _$location_, _Agenda_, _Prevadzka_, _User_, _Group_, _Permission_, _Notification_) {
    $httpBackend = _$httpBackend_;
    $location = _$location_;
    $rootScope = _$rootScope_;
    Profile = _Profile_;
    Agenda = _Agenda_;
    Restangular = _Restangular_;
    Prevadzka = _Prevadzka_;
    User = _User_;
    Group = _Group_;
    Permission = _Permission_;
    Notification = _Notification_;

    $parentScope = $rootScope.$new();
    $scope = $parentScope.$new();

    parentCtrl = _$controller_('SettingsCtrl', {
      $scope: $parentScope
    });

    createCtrl = function(ctrlName) {
      return _$controller_(ctrlName, {
        $scope: $scope
      });
    };

    Profile.set('agenda', {id: 1}, true);
    Profile.set('agendy', agendyData, true);
    Profile.set('prevadzky', prevadzkyData, true);
    Profile.set('is_superuser', false, true);

    // configure http backend simulation
    $httpBackend.whenGET('/settings/agendy?active=True&page=last').respond(agendyActive);
    $httpBackend.whenGET('/settings/agendy?page=last').respond(agendyData);
    $httpBackend.whenGET('/settings/agendy/1/prevadzky').respond(prevadzky1);
    $httpBackend.whenGET('/settings').respond(settingsData);
    $httpBackend.whenGET('/settings/settings_ucty?interny=False').respond(uctyData);
  }));

  describe('general tab', function() {
    beforeEach(function() {
      $parentScope.setActiveTab(1);
    });

    it('should load general settings from backend', function() {
      $httpBackend.expectGET('/settings/settings_ucty?interny=False');
      $httpBackend.expectGET('/settings');
      ctrl = createCtrl('SettingsGeneralCtrl');
      $httpBackend.flush();
    });
  });

  // afterEach(function() {

  // });

  // it('should change active tab', function() {
  //   ctrl = createController();

  //   $scope.setActiveTab(0);
  //   expect($scope.tabs[0]).toBe(true);
  // });

  // it('should change active tab based on location path and filter sites for specified agenda', function() {
  //   $location.url("/settings?tab=2&agenda=2");

  //   $httpBackend.expectGET('/settings/agendy/2/prevadzky').respond(prevadzky2);
  //   ctrl = createController();

  //   expect($scope.tabs[2]).toBe(true);

  //   $httpBackend.flush();

  //   expect(Restangular.stripRestangular($scope.prevadzkyAgendy)).toEqualData(agendyData);
  //   expect(Restangular.stripRestangular($scope.prevadzky)).toEqualData(prevadzky2);
  // });

  describe('agendy tab', function() {
    beforeEach(function() {
      $scope.setActiveTab(0);
      ctrl = createCtrl('SettingsAgendyCtrl');
    });

    it('should display all active agendas on load', function() {
      $httpBackend.flush();

      expect(Restangular.stripRestangular($scope.agendy)).toEqualData(agendyData);

      $httpBackend.verifyNoOutstandingExpectation();
      $httpBackend.verifyNoOutstandingRequest();
    });

    it('should display only active agendas when filter is applied', function() {
      $httpBackend.flush();

      $scope.SearchCtrl.filterCheckbox('active');
      $httpBackend.flush();

      expect(Restangular.stripRestangular($scope.agendy)).toEqualData(agendyActive);

      $httpBackend.verifyNoOutstandingExpectation();
      $httpBackend.verifyNoOutstandingRequest();
    });

    it('should fetch agendas data from backend', function() {
      $httpBackend.flush();

      /*for (var i = 0; i < $scope.agendy.length; i++) {
        delete $scope.agendy[i].delete;
        delete $scope.agendy[i].postSave;
        delete $scope.agendy[i].update;
        delete $scope.agendy[i].update_sync;
        delete $scope.agendy[i].validate;
        delete $scope.agendy[i].errors;
        delete $scope.agendy[i].addError;
        delete $scope.agendy[i].clearErrors;
      }*/

      expect(Restangular.stripRestangular($scope.agendy)).toEqualData(agendyData);

      $httpBackend.verifyNoOutstandingExpectation();
      $httpBackend.verifyNoOutstandingRequest();
    });

    it('should append created agenda to the list', function() {
      $httpBackend.flush();

      var agenda = {id: 3, firma: 'Nova', ico: '123', dic: '123', ulica: 'Ulica', cislo: '1', psc: '12312', mesto: 'Mesto'};

      $httpBackend.expectPOST('/settings/agendy').respond(agenda);

      Agenda.create(agenda);
      $httpBackend.flush();
      $scope.$digest();

      expect(Restangular.stripRestangular($scope.agendy)).toEqualData(_.union(agendyData, Restangular.stripRestangular([angular.extend(agenda, {created: true})])));

      $httpBackend.verifyNoOutstandingExpectation();
      $httpBackend.verifyNoOutstandingRequest();
    });
  });

  // can't be done because socket factory is not testable!
  /*it('should refresh agenda list in sites tab, when agenda is added or removed', function() {
    ctrl = createController();

    $scope.setActiveTab(2);
    $httpBackend.flush();

    expect(Restangular.stripRestangular($scope.prevadzkyAgendy)).toEqualData(agendyData);

    var agenda = {id: 3, firma: 'Nova', ico: '123', dic: '123', ulica: 'Ulica', cislo: '1', psc: '12312', mesto: 'Mesto'};

    $httpBackend.expectPOST('/settings/agendy').respond(agenda);

    Agenda.create(agenda);
    $httpBackend.flush();
    $scope.$digest();
    dump(Restangular.stripRestangular($scope.prevadzkyAgendy));
  });*/
});
