"use strict";

angular.module("casist.sklad").controller("ZalohoveFakturyDetailCtrl", [
  "$scope",
  "$modalInstance",
  "zfaktura",
  "zfaktury",
  "$timeout",
  "$modal",
  "Global",
  "$http",
  "$controller",
  "Profile",
  "SearchCtrl",
  "ZalohovaFaktura",
  "Prevadzka",
  "Firma",
  "TypDPH",
  "Predkontacia",
  "$q",
  "Pohyb",
  "DokladTypy",
  "Doprava",
  "Mena",
  "Predajca",
  "Hlavicka",
  "Pata",
  "Ucet",
  "Outputs",
  "Dialog",
  "$filter",
  "Mailbox",
  "Karta",
  function (
    $scope,
    $modalInstance,
    zfaktura,
    zfaktury,
    $timeout,
    $modal,
    Global,
    $http,
    $controller,
    Profile,
    SearchCtrl,
    ZalohovaFaktura,
    Prevadzka,
    Firma,
    TypDPH,
    Predkontacia,
    $q,
    Pohyb,
    DokladTypy,
    Doprava,
    Mena,
    Predajca,
    Hlavicka,
    Pata,
    Ucet,
    Outputs,
    Dialog,
    $filter,
    Mailbox,
    Karta
  ) {
    $scope.hasList = zfaktury.length !== 0;
    $scope.jednoducheUctoClass = Profile.get("jednoducheUcto")
      ? "jednoduche-ucto"
      : "";

    $scope.Karta = Karta;
    $scope.Mena = Mena;
    $scope.Hlavicka = Hlavicka;
    $scope.Pata = Pata;
    $scope.skladChanged = function (item, parent) {
      if (!parent) {
        parent = $scope.newItem;
      }
      if (item) {
        parent.sklad = parseInt(item);
      }
      parent.sklad_data = _.find($scope.currentPrevadzka.sklady, {
        id: parent.sklad,
      });
    };
    $scope.getHlavicky = function (query) {
      return Hlavicka.get(query, { typ__isnull: 1 });
    };
    $scope.getPaty = function (query) {
      return Pata.get(
        query,
        $scope.zfaktura.typ ? { typ: $scope.zfaktura.typ } : { typ__isnull: 1 }
      );
    };
    var getDefaultHeaderFooter = function () {
      Hlavicka.get(undefined, { typ__isnull: 1, default: "True" }).then(
        function (data) {
          if (!angular.isDefined($scope.zfaktura.hlavicka)) {
            if (data.length) {
              $scope.zfaktura.hlavicka = data[0].text;
            } else {
              $scope.zfaktura.hlavicka = "";
            }
          }
          defaultZalohovaFaktura.hlavicka = $scope.zfaktura.hlavicka;
          Global.set("faktura_hlavicka", defaultZalohovaFaktura.hlavicka);
        }
      );
      Pata.get(undefined, { typ__isnull: 1, default: "True" }).then(function (
        data
      ) {
        if (!angular.isDefined($scope.zfaktura.pata)) {
          if (data.length) {
            $scope.zfaktura.pata = data[0].text;
          } else {
            $scope.zfaktura.pata = "";
          }
        }
        defaultZalohovaFaktura.pata = $scope.zfaktura.pata;
        Global.set("faktura_pata", defaultZalohovaFaktura.pata);
      });
    };
    $scope.Predajca = Predajca;
    $scope.Doprava = Doprava;
    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: ZalohovaFaktura,
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      pohladavkyPohyb: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_pohyb",
      }),
      pohladavkyRozuctovanie: _.find(Profile.get("layouts"), {
        layout_id: "pohladavky_rozuct",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.pohladavkyPohyb)
        ? layouts.pohladavkyPohyb.value
        : true,
      false,
      angular.isDefined(layouts.pohladavkyRozuctovanie)
        ? layouts.pohladavkyRozuctovanie.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "pohladavky_" + (panel === 0 ? "pohyb" : "rozuct");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function () {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.pohladavkyRozuctovanie.value) {
        $scope.panels[2] = layouts.pohladavkyRozuctovanie.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31),
      typDPHMaxPlatnost = new Date(1970, 1, 1);
    var updateTypDPH = function (datum) {
      if (!Profile.get("platcadph")) {
        return;
      }
      TypDPH.getValid(datum, "1,0").then(function (data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        if (
          data.data.length &&
          (!$scope.zfaktura.typ_dph ||
            !_.find(data.data, { id: $scope.zfaktura.typ_dph }))
        ) {
          $scope.zfaktura.typ_dph = data.data[0].id;
        } else if ($scope.zfaktura.id) {
          $scope.zfaktura.typ_dph = zfaktura.typ_dph;
        }
        $timeout(function () {
          $scope.recountSums();
        }, 1000);
        if (
          data.data.length &&
          !angular.isDefined(defaultZalohovaFaktura.typ_dph)
        ) {
          defaultZalohovaFaktura.typ_dph = data.data[0].id;
        }
      });
    };
    $scope.help = {};
    $scope.helpClosed = function () {
      delete $scope.help.typdph;
    };
    $scope.displayHelpTypDPH = function () {
      var el = '<table class="table table-condensed">';
      for (var i = 0; i < $scope.typyDPH.length; i++) {
        el +=
          '<tr><td width="70">' +
          $scope.typyDPH[i].typ +
          "</td><td>" +
          $scope.typyDPH[i].popis +
          "</td></tr>";
      }
      el += "</table>";
      $scope.help.typdph = el;
    };
    $scope.$watch("zfaktura.d_vyst", function (val, oldVal) {
      if (val) {
        var date = parseDate(val);
        if (
          !isNaN(date) &&
          (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)
        ) {
          updateTypDPH(val);
        }
      }
    });
    if (Profile.get("platcadph")) {
      $scope.$watch("zfaktura.typ_dph", function (val) {
        if (val) {
          $scope.zfaktura.typdph_data = _.find($scope.typyDPH, {
            id: parseInt(val),
          });
          if (
            !$scope.zfaktura.id &&
            !angular.isDefined(defaultZalohovaFaktura.typdph_data)
          ) {
            defaultZalohovaFaktura.typdph_data = $scope.zfaktura.typdph_data;
          }
        } else {
          $scope.zfaktura.typdph_data = undefined;
        }
      });
    }
    $scope.setDodanie = function () {
      $scope.zfaktura.d_zdp = $scope.zfaktura.d_vyst;
      $scope.setUplatDPH();
    };
    $scope.setUplatDPH = function () {
      $scope.zfaktura.uplat_dph = $scope.zfaktura.d_zdp;
      $scope.zfaktura.d_uctn = $scope.zfaktura.d_zdp;
    };

    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    var today = new Date();
    var splat = new Date(
      today.setDate(today.getDate() + Global.get("splatnost_fa"))
    );

    if (
      !angular.isDefined(Global.get("faktura_hlavicka")) ||
      !angular.isDefined(Global.get("faktura_pata"))
    ) {
      getDefaultHeaderFooter();
    }

    var defaultZalohovaFaktura = {
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get("lastPrevadzka").id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      hlavicka: Global.get("faktura_hlavicka"),
      konecny_prijemca: null,
      pata: Global.get("faktura_pata"),
      mena: null,
      sd1: Global.get("sd1"),
      sd2: Global.get("sd2"),
      d_splat: getCurrentDate(splat),
      rozuctovanie: [],
    };
    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.pohybColumns = [
      { width: 30 },
      { field: "kod", label: "pohyb.KOD", width: 120 },
      { field: "nazov", label: "pohyb.NAZOV" },
      { field: "sklad", label: "pohyb.SKLAD" },
      { field: "mv", label: "pohyb.MNOZSTVO", width: 90 },
      {
        field: "cena_vydaj",
        label: "pohyb.CENAMJ",
        width: 105,
        currencyAddon: "zfaktura",
      },
      { field: "zlava", label: "fakturacia.ZLAVAP", width: 65 },
      { field: "mj", label: "pohyb.MJ", width: 50 },
      { field: "dph", label: "pohyb.DPH", width: 60 },
      { field: "poznamka", label: "fakturacia.POZNAMKA", width: 150 },
      { width: 60 },
    ];
    if (!Profile.get("platcadph")) {
      var omitted = ["dph"];
      $scope.pohybColumns = _.reject($scope.pohybColumns, function (obj) {
        return omitted.indexOf(obj.field) !== -1;
      });
    }
    $scope.pohyb = [];

    var updateShowVAT = function () {
      $scope.showVATfields = true;
      $scope.zfaktura.dd = DokladTypy.ZF.dd;
      defaultZalohovaFaktura.dd = $scope.zfaktura.dd;
    };

    if (!isEmpty(zfaktura)) {
      $scope.zfaktura = ZalohovaFaktura.copy(zfaktura);
      _.defaults($scope.zfaktura, defaultZalohovaFaktura);
      $scope.prevadzky = _.filter($scope.prevadzky, {
        id: $scope.zfaktura.prevadzka,
      });
      if ($scope.prevadzky.length)
        $scope.currentPrevadzka = $scope.prevadzky[0];
      if (!zfaktura.pohyb || $scope.zfaktura.id) {
        $scope.zfaktura.getList("pohyb").then(function (pohyb) {
          $scope.RecordCtrl.related.paginateList(pohyb, 20, $scope.sorter);

          $timeout(function () {
            $scope.recountSums();
          });
        });
      } else {
        $timeout(function () {
          for (var i = 0; i < zfaktura.pohyb.length; i++) {
            $scope.RecordCtrl.related.add(zfaktura.pohyb[i]);
          }
          delete zfaktura.pohyb;
        });
      }
    } else {
      $scope.zfaktura = ZalohovaFaktura.restangularize(
        angular.copy(defaultZalohovaFaktura)
      );
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.zfaktura.prevadzka,
      });
      //$scope.currentPrevadzkaName = _.find($scope.prevadzky, {id: $scope.zfaktura.prevadzka}).prevadzka;
      $timeout(function () {
        $scope.RecordCtrl.related.paginateList([], 20, $scope.sorter);
      });
    }
    $scope.newItem = Pohyb.restangularize({
      dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
    });
    updateShowVAT();
    $scope.skladChanged(
      $scope.currentPrevadzka.sklady.length
        ? $scope.currentPrevadzka.sklady[0].id
        : undefined
    );
    var defaultPohybItem = angular.copy($scope.newItem);

    var pohybPreSave = function (item, create) {
      if (!item.karta_data) {
        item.kod = null;
        if (item.karta) {
          item.nazov = item.karta;
          delete item.karta;
        }
      }
      if (angular.isDefined(item.cena_edit)) {
        if ($scope.zfaktura.mena && $scope.zfaktura.kurz) {
          item.prepocitatKurzom($scope.zfaktura);
        } else {
          item.cena_vydaj = item.cena_edit;
          item.cena_zm = 0;
        }
        delete item.cena_edit;
      }

      return item;
    };
    var pohybPostSave = function (item, created) {
      $("#pohyb_kod").focus();
      $scope.recountSums();
      angular.extend($scope.newItem, {
        dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
      });
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
      Pohyb.updateSkladLabelsWithQty($scope);
      if (document.getElementById("pohyb_kod")) {
        document.getElementById("pohyb_kod").scrollIntoView();
      }
      return item;
    };
    var pohybPreEdit = function (item) {
      if ($scope.zfaktura.primarna_zm) item.cena_edit = item.cena_zm;
      else item.cena_edit = item.cena_vydaj;
      return item;
    };
    $scope.recountSums = function () {
      $scope.sumaFakturyDPH = 0;
      $scope.sumaFaktury = 0;
      var promise = $scope.zfaktura.recountSums(
        $scope.RecordCtrl.related.getFullList()
      );
      promise.then(function (sums) {
        $scope.sumaFakturyDPH = sums.spolu;
        $scope.sumaFaktury =
          Math.round((sums.zaklad_d1 + sums.zaklad_d2 + sums.zaklad_d0) * 100) /
          100.0;
      });
      return promise;
    };
    $scope.recountSumsDelayed = function () {
      $timeout(function () {
        $scope.recountSums();
      });
    };
    var fakturaDataChanged = function () {
      $scope.recountSums();
      if ($scope.zfaktura.id)
        $scope.prevadzky = _.filter(Profile.get("prevadzky"), {
          id: $scope.zfaktura.prevadzka,
        });
      else $scope.prevadzky = Profile.get("prevadzky");
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.zfaktura.prevadzka,
      });
      updateShowVAT();
    };
    var fakturaPreSave = function (data) {
      delete data.faktura;
      // vynulovat ceny v ZM, ak sa medzicasom zrusila mena
      if (parseFloat($scope.zfaktura.kurz)) {
        $scope.kurzChanged();
      }
      return data;
    };
    var fakturaPostSave = function (data) {
      // $scope.recountSums().then(function(newSums) {
      //   copyWeakSrc(newSums, data);
      // });
      Profile.parseObdobie(data.d_vyst);
      return data;
    };

    $scope.RecordCtrl.main.init({
      endpoint: "zfaktury",
      model: "zfaktura",
      master: zfaktura,
      list: zfaktury,
      defaultItem: defaultZalohovaFaktura,
      dataChanged: fakturaDataChanged,
      focusField: "firma",
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      validate: function () {
        if (
          $scope.zfaktura.d_vyst &&
          !Profile.checkObdobie($scope.zfaktura.d_vyst)
        ) {
          if (
            !window.confirm(
              "Dátum nespadá do prednastaveného obdobia!\nPokračovať?"
            )
          ) {
            return false;
          }
        }
      },
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "pohyb",
      lookup: "doklad",
      object: Pohyb,
      preSave: pohybPreSave,
      postSave: pohybPostSave,
      preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    $scope.setPrevadzka = function (prevadzka) {
      $scope.zfaktura.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
    };

    $scope.mainFormSubmitter = function ($event) {
      if ($event.which === 13 && !$event.shiftKey) {
        if (angular.equals($scope.newItem, defaultPohybItem)) {
          $scope.ok();
        } else {
          $scope.RecordCtrl.related.add($scope.newItem);
        }
      }
    };
    $scope.sumaFaktury = 0;
    $scope.sumaFakturyDPH = 0;

    $scope.activeCurrency =
      $scope.zfaktura.mena && $scope.zfaktura.primarna_zm
        ? $scope.zfaktura.mena_data.mena
        : Global.get("mena");

    $scope.toggleCurrency = function (item) {
      var kurz = parseFloat($scope.zfaktura.kurz);

      if ($scope.zfaktura.primarna_zm)
        $scope.newItem.cena_edit =
          Math.round((parseFloat($scope.newItem.cena_edit) / kurz) * 10000) /
          10000.0;
      else
        $scope.newItem.cena_edit =
          Math.round(parseFloat($scope.newItem.cena_edit) * kurz * 10000) /
          10000.0;

      if (item) {
        item.prepocitatKurzom($scope.zfaktura);
      } else {
        $("#add_cena_vydaj").focus();
      }
      $scope.zfaktura.primarna_zm = !$scope.zfaktura.primarna_zm;
      $scope.activeCurrency = $scope.zfaktura.primarna_zm
        ? $scope.zfaktura.mena_data.mena
        : Global.get("mena");

      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.zfaktura.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_vydaj;
        }
      });
    };
    $scope.kurzChanged = function () {
      var list = $scope.RecordCtrl.related.getFullList();
      $scope.zfaktura.prepocitatPohybKurzom(list);
      for (var i = 0; i < list.length; i++) {
        $scope.RecordCtrl.related.edit(list[i]);
      }
      // update also items which are currently being edited
      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.zfaktura.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj.cena_prijem;
        }
      });
    };

    $scope.inProgress = false;
    $scope.send = function () {
      if (!$scope.zfaktura.firma_data || !$scope.zfaktura.firma_data.email) {
        Dialog.alert("Firma nemá zadaný e-mail.");
        return;
      }
      if ($scope.RecordCtrl.main.validateAndSave()) {
        Outputs.sendMail(
          $scope.zfaktura.firma_data.email,
          Global.get(
            "text_email_zfaktura_subject",
            "Zálohova faktúra č. $DOKLAD"
          ).replace("$DOKLAD", $scope.zfaktura.iddokd),
          Global.get("text_email_zfaktura"),
          $scope.zfaktura,
          "Odoslanie faktúry"
        ).then(
          function (data) {
            Global.set("text_email_zfaktura", data.mailData.message);
            Global.set("text_email_zfaktura_subject", data.mailMessage.subject);
            Mailbox.syncCreate(data.mailMessage);
          },
          function () {
            console.log("cancelled");
          }
        );
      }
    };
    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function (firma) {
      $scope.zfaktura.firma_data = firma;
      $scope.zfaktura.zlava = firma.zlava;
      if (firma.splatnost) {
        today = angular.copy(parseDate($scope.zfaktura.d_vyst));
        $scope.zfaktura.d_splat = getCurrentDate(
          new Date(today.setDate(today.getDate() + firma.splatnost))
        );
      }
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: zfaktura });
      });
    };

    var selectKarta = function (item, parent) {
      copyWeakSrc(
        _.pick(item, "kod", "nazov", "dph", "mj"),
        parent || $scope.newItem
      );
      into.karta = item.id;
      if (!Profile.get("svb")) {
        (parent || $scope.newItem).cena_edit = item.mo;
      }
      if (!parent) {
        Pohyb.updateSkladLabelsWithQty($scope, item);
        $timeout(function () {
          $("#pohyb_mv").focus();
        });
      }
    };
    $scope.selectedItem = function (item) {
      selectKarta(item, null);
    };
    $scope.selectedEditItem = function (item, parent) {
      selectKarta(item, parent);
    };
    var extendKartaFromParent = function (parent) {
      if (!parent) {
        return {};
      }
      return {
        nazov: parent.nazov,
        mo: parent.cena_edit,
        dph: parent.dph,
        mj: parent.mj,
        modph:
          parseFloat(parent.cena_edit) * ((parseInt(parent.dph) + 100) / 100.0),
      };
    };
    $scope.addKartaKod = function (kod, parent) {
      return Karta.add(kod, extendKartaFromParent(parent));
    };
    $scope.addKartaNazov = function (nazov) {
      return Karta.add(
        undefined,
        angular.extend(extendKartaFromParent($scope.newItem), { nazov: nazov })
      );
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function () {
      var prevadzka = _.find($scope.prevadzky, {
        id: $scope.zfaktura.prevadzka,
      });
      var modalInstance = $modal.open({
        templateUrl: "admin/settings/printSettings.html",
        controller: "PrintSettingsCtrl",
        backdrop: "static",
        windowClass: "printsettings-detail",
        resolve: {
          record: function () {
            return prevadzka;
          },
          prevadzky: function () {
            return $scope.prevadzky;
          },
        },
      });
      modalInstance.result.then(function (data) {
        var profilePrevadzka = _.find(Profile.get("prevadzky"), {
          id: data.prevadzka.id,
        });
        if (profilePrevadzka) {
          angular.copy(data.prevadzka, profilePrevadzka);
        }
      });
    };
  },
]);
