'use strict';

angular.module('casist.sklad')
  .controller('MnozstvaCtrl', ['$scope', 'Restangular', 'KartaMnozstvo', 'Profile', 'Karta', 'Socket', 'KartaTyp', 'Sklad', '$controller', 'TabService', 'Outputs', 'Dialog', 'Notification', 'Skupina', 'Podskupina', 'Druh', 'Vyrobca', 'Firma', function ($scope, Restangular, KartaMnozstvo, Profile, Karta, Socket, KartaTyp, Sklad, $controller, TabService, Outputs, Dialog, Notification, Skupina, Podskupina, Druh, Vyrobca, Firma) {
    $scope.SearchCtrl = $controller('SearchCtrl');

    $scope.mnozstva = [];
    var filterChanged = function(filter) {
      var promise = KartaMnozstvo.objects().getList(filter);
      $scope.mnozstva.loading = true;
      promise.then(function(data) {
        TabService.registerScope('kartamnozstvo', $scope);
        $scope.mnozstva = data;
      });
      return promise;
    };
    $scope.kartatypy = KartaTyp.objects().getList().$object;
    $scope.sklady = Sklad.objects().getList().$object;
    $scope.skupiny = Skupina.objects().getList().$object;
    $scope.podskupiny = Podskupina.objects().getList().$object;
    $scope.druhy = Druh.objects().getList().$object;
    $scope.Vyrobca = Vyrobca;
    $scope.Firma = Firma;
    $scope.mnozstvaTable = [
      { },
      { field: 'typ', label: 'karty.TYP', filterCollapse: 'right', select2: { source: 'kartatypy', lookup: 'typ' } },
      { field: 'karta__kod', label: 'karty.KOD', filterCollapse: 'right' },
      { field: 'karta__nazov', label: 'karty.NAZOV' },
      { field: 'sklad', label: 'karty.SKLAD', select2: { source: 'sklady', lookup: 'kod' } },
      { field: 'mnozstvo', label: 'pohyb.MNOZSTVO', type: 'number', sum: true},
      { field: 'karta__cena_sklad', label: 'karty.CENA_SKLAD', type: 'number'},
      { field: 'mo', label: 'karty.MO', type: 'number', sum: true},
      { field: 'modph', label: 'karty.MODPH', type: 'number', sum: true},
      { field: 'marza', label: 'Marža', type: 'number'},
      { field: 'spolu', label: 'mnozstva.SPOLU', type: 'number', sum: true},
      { field: 'karta__vyrobca', label: 'karty.VYROBCA', linked: { source: 'Vyrobca.get(query)', lookup: 'firma', editable: false }},
      { field: 'karta__dodavatel', label: 'karty.DODAVATEL', linked: { source: 'Firma.get(query)', lookup: 'firma', editable: false }},
      { field: 'karta__popis', label: 'karty.POPIS'}
    ];
    if (!Profile.get('platcadph')) {
      var omitted = ['modph'];
      $scope.mnozstvaTable = _.filter($scope.mnozstvaTable, function(field) { return omitted.indexOf(field.field) === -1});
    }
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.mnozstvaTable,
      sort: {sort: 'kod'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(TabService.bus.get('kartamnozstvo'));

    var events = [];
    var endpoints = ['faktury', 'zavazky', 'pokladna', 'ponuky', 'nakupy'];

    var refreshFunction = function(data) {
      $scope.SearchCtrl.refresh();
    };

    for (var i in endpoints) {
      events.push(Socket.on(endpoints[i]+"Created", refreshFunction));
      events.push(Socket.on(endpoints[i]+"Changed", refreshFunction));
      events.push(Socket.on(endpoints[i]+"Removed", refreshFunction));
    }
    const prepocetSkladuFinished = function(data) {
      $scope.prepocetProgress = false;
      $scope.SearchCtrl.setLastPage();
      refreshFunction();
      Notification.clear();
      Notification.add('Globálny prepočet prebehol úspešne.', 'success', 5);
    }
    events.push(Socket.on("prepocetSkladuFinished", prepocetSkladuFinished));
    events.push($scope.$on('casist:dataChanged', function(event, data) {
      if (endpoints.indexOf(data.type) === -1) {
        return;
      }
      refreshFunction(data);
    }));
    $scope.$on('$destroy', function() {
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
      for (var i in events) {
        events[i]();
      }
    });

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.edit = function(karta) {
      return Karta.edit(karta);
    };

    $scope.prepocetProgress = false;
    $scope.prepocetSkladu = function() {
      $scope.prepocetProgress = true;
      Notification.add('Prosím počkajte, kým sa prepočet ukončí...', 'info');
      KartaMnozstvo.prepocet(Profile.get('obdobie')).then(function(data) {
      }, function() {
        $scope.prepocetProgress = false;
        Notification.clear();
        Notification.add('Pri globálnom prepočte nastala chyba.', 'danger', 5);
      });
    };
    $scope.uzavierkaSkladu = function() {
      Dialog.confirm('Naozaj chcete urobiť uzávierku skladu za rok <b>'+Profile.get('obdobie')+'</b>?').then(function() {
        $scope.prepocetProgress = true;
        KartaMnozstvo.uzavierka(Profile.get('obdobie')).then(function(data) {
          $scope.prepocetProgress = false;
          Notification.add('Uzávierka skladu prebehla úspešne.', 'success', 5);
        }, function() {
          $scope.prepocetProgress = false;
          Notification.add('Pri uzávierke skladu nastala chyba.', 'danger', 5);
        });
      });
    };

    $scope.printList = function(zostava) {
      Outputs.openPrintUrl('mnozstva', null, $scope.SearchCtrl.getQuery(), `print/${zostava}`);
    };
    $scope.exportList = function() {
      Outputs.openXLSExportUrl('mnozstva', $scope.SearchCtrl.getQuery());
    };

    $scope.exportMnozstvaSumarny = function() {
      Outputs.openXMLExportUrl('mnozstva', 'sumarny', $scope.SearchCtrl.getQuery());
    };
  }]);
