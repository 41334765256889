'use strict';
import { io } from 'socket.io-client';
declare var angular: angular.IAngularStatic;

angular.module('casist.core')
  .factory('Socket', ['Global', '$rootScope', function(Global, $rootScope) {
    const socket = io('/casist');
    socket.io.on('reconnect', function() {
      if (Global.get('serverName') != null) {
        socket.emit('registerClient', { clientID: Global.get('serverAddress') + ':' + Global.get('serverName') });
      }
    });
    return {
      on: function(eventName, callback) {
        var cb = function() {
          var args = arguments;
          $rootScope.$apply(function() {
            callback.apply(socket, args);
          });
        }
        var unregisterCb = function() {
          socket.off(eventName);
        }
        socket.on(eventName, cb);
        return unregisterCb;
      },
      emit: function(eventName, data, callback) {
        socket.emit(eventName, data, function() {
          var args = arguments;
          $rootScope.$apply(function() {
            if (callback) {
              callback.apply(socket, args);
            }
          });
        });
      },
      disconnect: function() {
        socket.disconnect();
      },
    };
  }]);
