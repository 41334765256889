'use strict';

angular.module('casist.ucto')
  .controller('UctyCtrl', ['$scope', 'Restangular', 'Ucet', 'Profile', '$controller', '$modal', '$q', function ($scope, Restangular, Ucet, Profile, $controller, $modal, $q) {
    /*$scope.slideAnimation = true;

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.firmy = [];

    var filterChanged = function(filter) {
      var promise = Firma.objects().getList(filter);
      promise.then(function(data) {
        $scope.firmy = data;
      });
      if (filter.zahranicne)
        $scope.currentView = 1;
      return promise;
    };
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.firmyTable,
      sort: {sort: 'firma'}
    });
    if (!$scope.helperController) {
      filterChanged($scope.SearchCtrl.getQuery());
    }

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      filterChanged($scope.SearchCtrl.getQuery());
    }));

    $scope.firmyTable = [
      { width: 40 },
      { field: 'firma', label: 'firmy.FIRMA', filterCollapse: 'left' },
      { field: 'tel', label: 'firmy.TELEFON', width: 100, filterType: 'icontains' },
      { field: 'email', label: 'firmy.EMAIL', width: 100, filterType: 'icontains' },
      { field: 'adresa', label: 'firmy.ULICA'},
      { field: 'mesto', label: 'firmy.MESTO'},
      { field: 'splatnost', label: 'firmy.SPLATNOST', type: 'int', width: 70},
      { field: 'zlava', label: 'firmy.ZLAVA', type: 'number', width: 90},
      { field: 'ico', label: 'agendy.ICO', width: 100},
      { field: 'icdph', label: 'agendy.ICDPH', width: 130}
    ];*/
    $scope.ucty = [];
    $scope.SearchCtrl = null;

    $scope.create = function(item) {
      return Ucet.edit(item, $scope.ucty, $scope.SearchCtrl);
    };
    /*var events = Firma.registerEvents(function() { return $scope.firmy; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Firma.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });*/
  }]);
