'use strict';

angular.module('casist.sklad')
  .controller('PozicieCtrl', [
    '$scope',
    'Pozicie',
    'Restangular',
    '$controller',
    'TabService',
    'Profile',
    'Karta',
    function (
      $scope,
      Pozicie,
      Restangular,
      $controller,
      TabService,
      Profile,
      Karta
    ) {

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.pozicie = [];

    $scope.pozicieTable = [
      { width: 60 },
      { field: 'karta__nazov', label: 'pozicie.NAZOV_KARTY' },
      { field: 'karta__kod', label: 'pozicie.KOD_KARTY' },
      { field: 'karta__kod_ean', label: 'pozicie.EAN_KARTY' },
      { field: 'sklad__nazov', label: 'pozicie.NAZOV_SKLADU' },
      { field: 'sklad__kod', label: 'pozicie.KOD_SKLADU' },
      { field: 'nazov', label: 'pozicie.POZICIA' },
      { field: 'poznamka', label: 'pozicie.POZNAMKA' },
    ];

    var filterChanged = function(filter) {
      var promise = Pozicie.objects().getList(filter);
      $scope.pozicie.loading = true;
      promise.then(function(data) {
        TabService.registerScope('pozicie', $scope);
        $scope.pozicie = data;
      });
      return promise;
    };

    $scope.SearchCtrl.init({
      model: Pozicie,
      onChange: filterChanged,
      cennikTable: $scope.pozicieTable,
      sort: {sort: 'nazov'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(
      TabService.bus.get('pozicie')
    );

    $scope.edit = function(item) {
      return Pozicie.edit(item, $scope.pozicie, $scope.SearchCtrl);
    };

    $scope.create = function(item) {
      return Pozicie.edit(item, $scope.pozicie, $scope.SearchCtrl);
    };

    $scope.copy = function(item) {
      var copied = Pozicie.copy(item);
      _.each(['id', 'karta', 'karta_data'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };

    $scope.editKarta = function(item) {
      Karta.edit(item.karta_data, $scope.cennikzaznamy, $scope.SearchCtrl).then(function () {
          $scope.SearchCtrl.refresh();
        }
      );
    };

    var events = Pozicie.registerEvents(function() { return $scope.pozicie; }, function() { return $scope.SearchCtrl; });
    var unregisterProfileObservers = [];

    $scope.$on('$destroy', function() {
      Pozicie.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

  }]);
