'use strict';

angular.module('casist.sklad')
  .controller('SkladyDetailCtrl', [
    '$scope',
    '$modalInstance',
    '$controller',
    'Sklad',
    'Prevadzka',
    'SearchCtrl',
    'item',
    'items',
    function(
      $scope,
      $modalInstance,
      $controller,
      Sklad,
      Prevadzka,
      SearchCtrl,
      item,
      items
    ) {

    $scope.items = items;

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Sklad
    });

    var defaultItem = {};

    $scope.RecordCtrl.main.init({
      endpoint: 'sklady',
      model: 'item',
      master: item,
      list: $scope.items,
      defaultItem: defaultItem,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });

    Prevadzka.get().then(function(data) {
      $scope.prevadzky = data;
    });

    if (!isEmpty(item)) {
      $scope.item = Sklad.copy(copyMissing(defaultItem, item));
    } else {
      $scope.item = angular.copy(Sklad.restangularize(defaultItem));
    }

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

   $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
   };

   $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: item});
      });
    };

  }]);
