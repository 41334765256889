'use strict';

angular.module('casist.ucto').controller('ZavazkyCtrl',
  ['$scope', '$rootScope', 'Profile', '$controller', 'Zavazok', 'Firma', 'Outputs', '$q', 'DokladTypy', 'Prikaz', 'TabService', 'Dialog', 'BankAccount', '$window', '$timeout', 'Doklad', '$modal', 'ZavazkyTyp', 'Predkontacia', 'Notification', 'Socket',
  function ($scope, $rootScope, Profile, $controller, Zavazok, Firma, Outputs, $q, DokladTypy, Prikaz, TabService, Dialog, BankAccount, $window, $timeout, Doklad, $modal, ZavazkyTyp, Predkontacia, Notification, Socket) {
  $scope.slideAnimation = true;

  var getDefaultPeriod = function() {
    return {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
  };
  var defaultPeriod = getDefaultPeriod();
  var defaultFilter = {
    'dd': DokladTypy.DF.dd
  };
  $scope.uctovanieTypy = [
    {id: 1, uctovanie: 'Nezaúčtované'},
    {id: 2, uctovanie: 'Ručne účtované'},
    {id: 3, uctovanie: 'Predkontácia'},
    {id: 4, uctovanie: 'Zaúčtované'}
  ];
  $scope.rozuctovanieTypy = [
    {id: 1, rozuctovanie: 'Nerozúčtované'},
    {id: 2, rozuctovanie: 'Rozúčtované'}
  ];
  $scope.neuhradeneTypy = [
    {id: 1, typ: 'Neuhradené'},
    {id: 2, typ: 'Na úhradu'},
    {id: 3, typ: 'Príkaz'}
  ];
  $scope.DokladTypy = DokladTypy;
  $scope.zavazkyTypy = [
    {id: DokladTypy.DF.dd, dd: 'Došlé faktúry'},
    {id: DokladTypy.OZ.dd, dd: 'Ostatné záväzky'},
    {id: 0, dd: 'Všetky'}
  ];
  $scope.typChanged = function(value) {
    if (value.id === DokladTypy.OZ.dd && $scope.currentView !== 1) {
      $scope.currentView = 1;
    } else if ($scope.currentView !== 0) {
      $scope.currentView = 0;
    }
    $scope.selected = {};
    updateOznacenych();
  };
  $scope.neuhradeneValue = 'True';
  $scope.customizeNeuhradene = function() {
    $modal.open({
      templateUrl: 'ucto/customize_neuhradene/customizeNeuhradene.html',
      controller: 'CustomizeNeuhradeneCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        neuhradene: function() {
          return $scope.neuhradeneValue;
        }
      }
    }).result.then(function(data) {
      $scope.neuhradeneValue = data;

      if (!$scope.SearchCtrl.filters.top.neuhradene) {
        $scope.SearchCtrl.filters.top.neuhradene = 1;
      }
      $scope.SearchCtrl.filters.top.neuhradeneDni = data;
      $scope.SearchCtrl.setLastPage(true);
    });
  };
  var updateOznacenych = function() {
    var l = _.keys($scope.selected).length;
    switch (l) {
      case 0:
        $scope.oznacenych = '';
        break;
      case 1:
        $scope.oznacenych = 'Označený '+l+' záznam';
        break;
      case 2: case 3: case 4:
        $scope.oznacenych = 'Označené '+l+' záznamy';
        break;
      default:
        $scope.oznacenych = 'Označených '+l+' záznamov';
        break;
    }
  };
  $scope.zavazky = [];
  $scope.selected = {};
  $scope.selectedAll = false;
  $scope.selectAll = function(state) {
    var selectedAll = !$scope.selectedAll;
    $scope.selected = {};
    for (var i = 0; i < $scope.zavazky.length; i++) {
      $scope.zavazky[i].selected = selectedAll;
      if (!$scope.zavazky[i].prikaz && !$scope.jeUhradena($scope.zavazky[i])) {
        if (selectedAll) {
          $scope.selected[$scope.zavazky[i].id] = $scope.zavazky[i];
        } else {
          delete $scope.selected[$scope.zavazky[i].id];
        }
      }
    }
    $scope.oznacenych = '';
  };
  $scope.selectFaktura = function(faktura) {
    if ($scope.selectedAll) {
      $scope.selectedAll = false;
    }
    if ($scope.selected[faktura.id]) {
      delete $scope.selected[faktura.id];
    } else {
      $scope.selected[faktura.id] = faktura;
    }
    updateOznacenych();
  };
  $scope.jeUhradena = function(faktura) {
    return parseFloat(faktura.spolu) <= parseFloat(faktura.uhradene);
  };
  $scope.selectedUhrada = function(value) {
    if (value && value.id === 2) {
      $scope.selectAll(true);
    } else if (!value && $scope.selectedAll) {
      $scope.selectAll(false);
    }
  };
  var filterChanged = function(filter) {
    if (filter.neuhradene) {
      $scope.SearchCtrl.setDefaultPeriod(undefined);
      if (_.every(_.keys(getDefaultPeriod()), function(key) {
        return filter[key] === getDefaultPeriod()[key];
      })) {
        filter = _.omit(filter, 'd_zdp__gte');
      }
    } else {
      $scope.SearchCtrl.setDefaultPeriod(getDefaultPeriod());
      if (!$scope.SearchCtrl.hasDateFilter()) {
        filter = _.extend(filter, getDefaultPeriod());
      }
    }
    var promise = Zavazok.objects().getList(filter);
    $scope.zavazky.loading = true;
    promise.then(function(data) {
      TabService.registerScope('zavazky', $scope);
      $scope.zavazky = data;
      if ($scope.selectedAll) {
        for (var i = 0; i < $scope.zavazky.length; i++) {
          $scope.zavazky[i].selected = true;
        }
      } else {
        var fa;
        _.every($scope.selected, function(obj) {
          if ( (fa = _.find($scope.zavazky, {id: obj.id})) ) {
            $scope.selected[obj.id] = fa;
            fa.selected = true;
          }
        });
      }
    });
    if (filter.zahranicne) {
      $scope.currentView = 1;
    }
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    loadPrevadzky();
    $scope.$broadcast('prevadzkyChanged');
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.getPredkontacia = function(query) {
    return Predkontacia.get(query, DokladTypy.DF.dd);
  };
  $scope.zavazkytypy = ZavazkyTyp.objects().getList().$object;

  var coreFields = [
    { width: 75 },
    { selectAllAddon: true },
    { width: 30 },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'poradove_cislo', label: 'ucto.PORADOVE_CISLO', type: 'int' },
    { field: 'd_zdp', label: 'ucto.SUPPLIED', type: 'date' },
    { field: 'd_splat', label: 'ucto.DUE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
    { field: 'uhradene', label: 'ucto.UHRADENE', type: 'number', sum: true },
    { field: 'rozdiel', label: 'ucto.ROZDIEL', type: 'number', sum: function(sums) { return parseFloat(sums['spolu']) - parseFloat(sums['uhradene']);} }
  ];

  var view1Fields = [
    { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
    { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
    { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
    { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
    { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
    { field: 'typ', label: 'zavazky.TYP', select2: { source: 'zavazkytypy', lookup: 'typ' }},
    { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
    { field: 'typ_dph__typ', label: 'ucto.TDPH'},
    { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
  ];

  var view2Fields = [
    { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
    { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
    { field: 'mena', label: 'ucto.MENA',  },
    { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
    { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
    { field: 'poradove_cislo', label: 'zavazky.PORADOVE_CISLO', filterType: 'icontains' },
    { field: 'povodna_faktura_cislo', label: 'fakturacia.POVODNA_FAKTURA', filterType: 'icontains' },
    { field: 'prijemka__iddokd', label: 'fakturacia.PRIJEMKA' }
  ];
  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'typ_dph__typ', 'uplat_dph'];
    view1Fields = _.filter(view1Fields, function(field) { return omitted.indexOf(field.field) === -1});
  }
  $scope.zavazkyTable = [
    _.union(coreFields, view1Fields),
    _.union(coreFields, view2Fields),
  ];

  $scope.exportSaldo = function() {
    var query = $scope.SearchCtrl.getQuery();
    query = _.omit(query, 'd_zdp__gte');
    Outputs.openXLSExportUrl('zavazky/saldo', query);
  };

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
  }
  loadPrevadzky();

  $scope.create = function(item, dd) {
    var config = {};
    // Pre ostatne pohladavky dame do configu typ okna bez DPHckovych poli
    if (dd === 2 || $scope.SearchCtrl.filters.top.dd === DokladTypy.OZ.dd) {
      config.VATfields = false;
    }
    return Zavazok.edit(item, $scope.zavazky, $scope.SearchCtrl, config);
  };
  $scope.copy = function(item) {
    var copied = Zavazok.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(['id', 'uhradene', 'locked'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };

  var events = Zavazok.registerEvents(function() { return $scope.zavazky; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Zavazok.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Zavazok,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.zavazkyTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh(TabService.bus.get('zavazky'));

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.zavazkyTable[view]);
  };

  $scope.print = function(zavazok, url) {
    Outputs.openPrintUrl('zavazky', zavazok.id, undefined, url);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('zavazky', null, $scope.SearchCtrl.getQuery());
  };

  BankAccount.objects().getList({interny: 'False'}).then(function(data) {
    $scope.bankaucty = data;
  });

  $scope.openPrikaz = function(faktura) {
    if (faktura.prikaz) {
      TabService.bus.send('prikazy', 'zavazky', {prikaz: faktura.prikaz});
      TabService.openTab('prikazy');
    }
  };

  $scope.uhrada = function(ucet) {
    var ids = _.keys($scope.selected);
    if (!$scope.selectedAll && !ids.length) {
      return;
    }
    var query = {ucet: ucet};
    if (!$scope.selectedAll) {
      angular.extend(query, {selection: ids});
    }
    Zavazok.uhrada(query).then(function(data) {
      if (data.noucet.length) {
        Dialog.alert('<p>'+(data.prikazy.length ? 'Niektoré' : 'Žiadne')+' príkazy neboli vytvorené, pretože nasledujúce firmy nemajú zadefinovaný účet:</p><ul><li>'+_.uniq(_.map(data.noucet, function(obj) { return obj.firma_data.firma; })).join('</li><li>')+'</li></ul>', 'Upozornenie');
      }
      if (data.prikazy.length) {
        var fa;
        var prikaz;
        for (var i = 0; i < data.prikazy.length; i++) {
          if ( (fa = _.find($scope.zavazky, {id: data.prikazy[i].zavazok})) ) {
            delete $scope.selected[fa.id];
            fa.selected = false;
            fa.prikaz = data.prikazy[i].prikaz;
          }
          Prikaz.syncCreate(data.prikazy[i]);
          prikaz = data.prikazy[i].prikaz;
        }
        if (!$scope.selectedAll) {
          updateOznacenych();
        }
        $scope.SearchCtrl.broadcastRefresh('zavazky');
        TabService.bus.send('prikazy', 'zavazky', {prikaz: prikaz});
        TabService.openTab('prikazy');
      }
    }, function() {
      alert('Vytvorenie príkazov sa nepodarilo!');
    });
  };

  $scope.fakturaQR = null;
  $scope.showQR = function(faktura) {
    if ($scope.jeUhradena(faktura)) {
      return;
    }
    faktura.all('qr').post().then(function(data) {
      faktura.qr = '<img src="'+$window.location.protocol+'//'+constants.applicationServer+'/'+data.imageURL+'" style="height: 200px;">';
    }, function(error) {
      Dialog.alert(error.data.error, 'Chyba');
    });
  };

  $scope.parovanie = {};
  $scope.showParovanie = function(faktura) {
    if (!parseFloat(faktura.uhradene)) {
      $scope.prepocetUhrad(faktura.id);
      return;
    }
    faktura.listUhradene().then(function(data) {
      var el = '<table class="table table-bordered table-condensed table-striped"><tr><th></th><th>Kód</th><th>Doklad</th><th>Dátum</th><th>Suma</th><th>Suma v ZM</th></tr>';
      for (var i = 0; i < data.length; i++) {
        var kod = data[i].ucet_kod ? data[i].ucet_kod : data[i].pokladna_kod;
        el += '<tr><td width="30" class="text-center"><a class="record-icon" ng-click="editUhrada('+data[i].id+', \''+data[i].dd+'\', '+faktura.id+')"><i class="fa fa-edit"></i></a></td><td>'+kod+'</td><td>'+data[i].iddokd+'</td><td>{{\''+data[i].d_zdp+'\' | date:\'dd.MM.yyyy\'}}</td><td width="100" class="text-right">{{'+data[i].spolu+' | suma:2}}</td><td width="100" class="text-right">{{'+data[i].spolu_zm+' | suma:2}}</td></tr>';
      }
      el += '</table><div class="text-center" style="margin-top: 5px;"><button class="btn btn-sm btn-default" ng-click="prepocetUhrad('+faktura.id+')"><i class="fa fa-refresh"></i> Prepočítať {{nu}}</button></div>';
      $scope.parovanie[faktura.id] = el;
    });
  };
  $scope.parovanieClosed = function(faktura) {
    delete $scope.parovanie[faktura.id];
  };
  $scope.QRClosed = function(faktura) {
    delete faktura.qr;
  };
  $scope.editUhrada = function(id, dd, fakturaID) {
    var preservePopover = function() {
      var faktura = _.find($scope.zavazky, {id: fakturaID});
      if (faktura) {
        $scope.showParovanie(faktura);
      }
    };
    Doklad.editRecord(id, dd).then(preservePopover, preservePopover);
  };
  $scope.prepocetUhrad = function(id) {
    if (id) {
      var faktura = _.find($scope.zavazky, {id: parseInt(id)});
      faktura.prepocetUhrad().then(function(data) {
        faktura.uhradene = parseFloat(data.uhradene);
        $scope.SearchCtrl.broadcastRefresh('zavazky');
        $scope.parovanieClosed(faktura);
      });
    } else {
      $modal.open({
        templateUrl: 'ucto/prepocetuhrad/prepocetuhrad.html',
        controller: 'PrepocetUhradCtrl',
        backdrop: 'static',
        windowClass: 'detail-sm',
        resolve: {
          query: function() {
            return $scope.SearchCtrl.getQuery();
          },
          model: function() {
            return Zavazok;
          }
        }
      })
    }
  };

  $scope.onDisplayAttachment = function (zavazok) {
    Outputs.openPrintUrl('zavazky', zavazok.id, undefined, 'attachment');
  };

  $scope.globalneUctovanie = function() {
    if (!$scope.SearchCtrl.hasFilter()) {
      Dialog.alert('Globálne účtovanie je možné použiť len keď je použitý filter.', 'Upozornenie');
      return;
    }
    $modal.open({
      templateUrl: 'ucto/globalne-uctovanie/globalne-uctovanie.html',
      controller: 'GlobalneUctovanieCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        list: function() {
          return $scope.zavazky;
        },
        query: function() {
          return $scope.SearchCtrl.getQuery();
        },
        model: function() {
          return Zavazok;
        },
        predkontacieGetter: function() {
          return function(query) { return $scope.getPredkontacia(query); };
        }
      }
    }).result.then(function(data) {
      Notification.add('Záznamy boli zaúčtované.', 'success', 5);
      var faktura;
      for (var i = 0; i < data.length; i++) {
        faktura = _.find($scope.zavazky, {id: data[i].id});
        if (faktura) {
          Zavazok.syncChange(data[i], angular.copy(faktura));
        }
      }
      // $scope.SearchCtrl.broadcastRefresh('faktury');
    });
  };
  $scope.openImport = function() {
    var modalInstance = $modal.open({
      templateUrl: 'core/import/import.html',
      controller: 'GenericImportCtrl',
      backdrop: 'static',
      windowClass: 'detail',
      resolve: {
        list: function() {
          return $scope.zavazky;
        },
        model: function() {
          return Zavazok;
        }
      }
    });
    modalInstance.result.then(function(data) {
      Notification.add('Záznamy boli aktualizované.', 'success', 5);
      $scope.SearchCtrl.refresh();
    });
  };
  $scope.itemsImport = function($files) {
    if (!$files.length) {
      Notification.add('Súbor sa nepodarilo nahrať.', 'danger', 10);
      return;
    }

    var file = $files[0];

    var parts = file.name.split('.');
    if (parts[1] !== 'xls') {
      Notification.add('Súbor bol nahratý v nesprávnom formáte. Požadovaný formát je .xls.', 'danger', 10);
      return;
    }
    Zavazok.itemsImport(file).then(function (response) {
      Notification.add(response.data, 'success', 10);
      $scope.SearchCtrl.refresh();
    }).catch(function (error) {
      if (error.status >= 400 && error.status < 500) {
        Notification.add(error.data, 'danger', 10);
      } else if (error.status >= 500) {
        Notification.add('Nastala serverová chyba.', 'danger', 10);
      }
    });
  };

  $scope.exportZavazky = function () {
    Outputs.openXLSExportUrl('zavazky', $scope.SearchCtrl.getQuery());
  };

   const prepocetUhradFinished = function(data) {
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
      $scope.SearchCtrl.broadcastRefresh('zavazky');
    }
    events.push(Socket.on("prepocetUhradFinished", prepocetUhradFinished));
}]);
