'use strict';

angular.module('casist.widgets')
  .directive('rozuctovanieTable', ['RozuctovanieSposob', '$timeout', 'Notification', '$q', '$translate', '$window', 'Auth', '$modal', 'DokladTypy', function (RozuctovanieSposob, $timeout, Notification, $q, $translate, $window, Auth, $modal, DokladTypy) {
    return {
      templateUrl: 'components/widgets/rozuctovanie.html',
      restrict: 'EA',
      scope: {
        model: '=rozuctovanieTable',
        doklad: '=',
        overflow: '=',
        valid: '='
      },
      link: function postLink(scope, element, attrs) {
        scope.addPanel = false;
        scope.selectedSposoby = [];

        /**
         * Ked si user vyberie sposob zauctovania, je to poznacene v selectedSposoby a este to nie je zapisane do modelu.
         * Do modelu sa to zapise az v momente, ze sa nahodi prva polozka a takisto sa to z modelu vymaze, ked sa vymaze posledna polozka
         */
        var vsetkySposoby = [];
        var pickFirst = function() {
          if (!scope.model.length) {
            if (vsetkySposoby.length === 1) {
              scope.selectedSposoby.push(angular.copy(vsetkySposoby[0]));
              scope.addPanel = false;
              scope.finishAdding(false);
            // call add only when we have things loaded
            } else if (scope.vsetkySposoby) {
              scope.add();
            }
          }
        };
        RozuctovanieSposob.objects().getList().then(function(data) {
          scope.vsetkySposoby = data;
          vsetkySposoby = angular.copy(data);
          pickFirst();
        });
        if (angular.isDefined(attrs.typ)) {
          scope.defaultTyp = scope.$eval(attrs.typ);
        } else {
          scope.defaultTyp = undefined;
        }

        scope.newRozuct = {};
        scope.newItem = {};
        scope.rozuctovanieTableAnimate = true;
        var rozuctItems = [];

        scope.add = function() {
          scope.addPanel = true;
        };

        scope.finishAdding = function(setFocus) {
          var item = null, selectedItem = null;
          for (var i = 0; i < scope.selectedSposoby.length; i++) {
            item = scope.selectedSposoby[i];
            if (!item.rozpis) {
              selectedItem = item;
              item.rozpis = [];
              item.typ = scope.defaultTyp || 0;
              if (angular.isDefined(scope.doklad.operacia)) {
                item.typ = scope.doklad.operacia === 1 ? 0 : 1;
              }
              if (scope.doklad.dd == DokladTypy.BD.dd) {
                item.suma = parseFloat(scope.doklad.spolu);
              } else {
                item.suma = parseFloat(scope.doklad.zaklad_d1) + parseFloat(scope.doklad.zaklad_d2) + parseFloat(scope.doklad.zaklad_d0);
              }
            }
          }
          // remove items model that are no longer there
          for (var i = scope.model.length-1; i >= 0; i--) {
            if (scope.selectedSposoby.indexOf(scope.model[i]) === -1) {
              scope.model.splice(i, 1);
            }
          }
          if (selectedItem || item) {
            scope.selectSposob(selectedItem || item, setFocus);
          }
          scope.addPanel = false;
        };

        scope.sposobyChanged = function() {
          for (var i = 0; i < scope.vsetkySposoby.length; i++) {
            if (scope.selectedSposoby.indexOf(scope.vsetkySposoby[i]) > -1) {
              scope.vsetkySposoby[i].rozpis = undefined;
              scope.vsetkySposoby[i].suma = undefined;
            }
          }
        };

        scope.toggleTyp = function() {
          scope.sposobyActive.typ = !scope.sposobyActive.typ;
          $timeout(function() {
            if (scope.sposobyActive.suma) {
              $('#rozuct_suma').select();
            } else {
              $('#rozuct_suma').focus();
            }
          });
        }

        scope.rozuctItems = {};
        scope.selectSposob = function(s, setFocus) {
          if (!angular.isDefined(setFocus)) {
            setFocus = true;
          }
          scope.rozuctovanieTableAnimate = false;
          $timeout(function() {
            scope.sposobyActive = s;
            scope.sposobyActive.polozkyProvider().objects(scope.sposobyActive).getList().then(function(data) {
              scope.rozuctItems = {};
              for (var i = 0; i < data.length; i++) {
                scope.rozuctItems[data[i].id] = data[i];
              }
              angular.copy(data, rozuctItems);
            });
            scope.rozuctovanieTableAnimate = true;
            if (setFocus) {
              $timeout(function() {
                if (!s.rozpis || !s.rozpis.length) {
                  if (s.suma) {
                    $('#rozuct_suma').select();
                  } else {
                    $('#rozuct_suma').focus();
                  }
                } else {
                  $('#newRozuct_item').focus();
                }
              });
            }
            scope.recountZostatok();
          });
        };

        scope.recountZostatok = function() {
          var zostatok = 0;
          if (!scope.sposobyActive.rozpis) {
            return zostatok;
          }
          for (var i = 0; i < scope.sposobyActive.rozpis.length; i++) {
            zostatok += parseFloat(scope.sposobyActive.rozpis[i].suma);
          }
          zostatok = Math.round( (parseFloat(scope.sposobyActive.suma) - zostatok)*10000) / 10000.0;
          if (scope.newRozuct) {
            scope.newRozuct.suma = zostatok;
          } else {
            scope.newRozuct = { suma: zostatok };
          }
          // zratat zostatky vsade, podla toho nastavit valid
          var zostatokAll = 0, tmp;
          var noRozpis = true;
          for (var i = 0; i < scope.model.length; i++) {
            tmp = 0;
            for (var j = 0; j < scope.model[i].rozpis.length; j++) {
              noRozpis = false;
              tmp += parseFloat(scope.model[i].rozpis[j].suma);
            }
            tmp = Math.round( (parseFloat(scope.model[i].suma) - tmp)*10000) / 10000.0;
            zostatokAll += tmp;
          }
          scope.valid = (zostatokAll === 0) || noRozpis;
          return zostatok;
        };

        var updateOverflow = function() {
          $timeout(function() {
            if (angular.isDefined(attrs.overflowClass)) {
              scope.$apply(function() {
                if (element[0].offsetHeight < element[0].scrollHeight) {
                  element.addClass(attrs.overflowClass);
                } else {
                  element.removeClass(attrs.overflowClass);
                }
              });
            }
          });
          $(element).animate({
              scrollTop : element[0].scrollHeight
          }, 250);
        };

        scope.createRozuct = function() {
          if (_.find(scope.vsetkySposoby, {sposob: scope.newRozuctSposob})) {
            alert('Taký spôsob rozúčtovania už existuje!');
            return;
          }
          RozuctovanieSposob.create({sposob: scope.newRozuctSposob}).then(function(data) {
            scope.vsetkySposoby.push(data);
            vsetkySposoby.push(data);
          }, function(error) {
            Notification.add('Nepodarilo sa vytvoriť záznam.', 'danger', 5);
          });
          scope.newRozuctSposob = undefined;
        };

        scope.rozuctItemValue = {};
        scope.getRozuctItems = function(query) {
          var deferred = $q.defer();
          var re = new RegExp(query, 'i');
          var matches = [];
          for (var i = 0; i < rozuctItems.length; i++) {
            if (rozuctItems[i].cislo.match(re) || rozuctItems[i].nazov.match(re)) {
              matches.push(angular.copy(rozuctItems[i]));
            }
          }
          deferred.resolve(matches);
          return deferred.promise;
        };

        scope.addRozuct = function() {
          if (!scope.sposobyActive) {
            return;
          }
          if (!scope.newRozuct.polozka) {
            alert('Musíte vybrať položku rozúčtovania!');
            return;
          }
          var item = angular.copy(scope.newRozuct);
          if (_.find(scope.sposobyActive.rozpis, {polozka: item.polozka})) {
            alert('Taká položka už v rozpise existuje!');
            return;
          }
          if (scope.model.indexOf(scope.sposobyActive) === -1) {
            scope.model.push(scope.sposobyActive);
          }
          scope.sposobyActive.rozpis.push(item);

          scope.newRozuct.polozka = undefined;

          scope.recountZostatok();
          $timeout(function() {
            $('#newRozuct_item').focus();
          });
          updateOverflow();
        };

        scope.getRozuctItem = function(id) {
          return _.find(rozuctItems, {id: id});
        };

        scope.addRozuctItem = function(rozuct) {
          var item = {cislo: rozuct};
          var modalInstance = $modal.open({
            templateUrl: 'ucto/rozuctovanie/rozuctovanie-polozka-detail.html',
            controller: 'RozuctovaniePolozkaDetailCtrl',
            backdrop: 'static',
            windowClass: 'detail-sm',
            resolve: {
              polozka: function() {
                return item ? item : {};
              },
              polozky: function() {
                return rozuctItems;
              },
              parent: function() {
                return scope.sposobyActive;
              }
            }
          });
          var deferred = $q.defer();
          modalInstance.result.then(function(data) {
            scope.sposobyActive.polozkyProvider().create(angular.extend(data, {sposob: scope.sposobyActive.id})).then(function(createdData) {
              rozuctItems.push(createdData);
              scope.rozuctItems[createdData.id] = createdData;
              deferred.resolve(createdData);
              $timeout(function() {
                $('#newRozuct_item').focus();
              });
            }, function(error) {
              deferred.reject(error);
              $timeout(function() {
                $('#newRozuct_item').focus();
              });
            });
          }, function() {
            deferred.reject();
            $timeout(function() {
              $('#newRozuct_item').focus();
            });
          });
          return deferred.promise;
        };

        scope.editRozuctItem = function(item) {
          var modalInstance = $modal.open({
            templateUrl: 'ucto/rozuctovanie/rozuctovanie-polozka-detail.html',
            controller: 'RozuctovaniePolozkaDetailCtrl',
            backdrop: 'static',
            windowClass: 'detail-sm',
            resolve: {
              polozka: function() {
                return item ? item : {};
              },
              polozky: function() {
                return rozuctItems;
              },
              parent: function() {
                return scope.sposobyActive;
              }
            }
          });
          var deferred = $q.defer();
          modalInstance.result.then(function(data) {
            data.update().then(function(updatedData) {
              angular.copy(updatedData, _.find(rozuctItems, {id: item.id}));
              scope.rozuctItems[updatedData.id] = updatedData;
              deferred.resolve(updatedData);
              $timeout(function() {
                $('#newRozuct_item').focus();
              });
            }, function(error) {
              deferred.reject(error);
              $timeout(function() {
                $('#newRozuct_item').focus();
              });
            });
          }, function() {
            deferred.reject();
            $timeout(function() {
              $('#newRozuct_item').focus();
            });
          });
          return deferred.promise;
        };

        scope.deleteRozuct = function(r) {
          if (!scope.sposobyActive) {
            return;
          }
          var idx = scope.sposobyActive.rozpis.indexOf(r);
          if (idx === -1) {
            return;
          }
          scope.sposobyActive.rozpis.splice(idx, 1);
          if (!scope.sposobyActive.rozpis.length && scope.model.indexOf(scope.sposobyActive) !== -1) {
            scope.model.splice(scope.model.indexOf(scope.sposobyActive), 1);
          }
          scope.recountZostatok();
          $timeout(function() {
            $('#newRozuct_item').focus();
          });
          updateOverflow();
        };

        scope.sposobyDelete = function(item) {
          var promise = item.delete();
          promise.then(function() {
            vsetkySposoby.splice(vsetkySposoby.indexOf(_.find(vsetkySposoby, {id: item.id})));
          });
          return promise;
        };

        scope.$watch('model', function(val) {
          scope.selectedSposoby = [];
          if (!val) {
            scope.model = [];
            return;
          }
          if (val && val.length && !isEmpty(val)) {
            for (var i = 0; i < scope.model.length; i++) {
              RozuctovanieSposob.restangularize(scope.model[i]);
              scope.selectedSposoby.push(scope.model[i]);
            }
            scope.selectSposob(val[0]);
          } else {
            scope.sposobyActive = undefined;
            scope.valid = true;
          }
          if (!val.length) {
            // pick the first one, but only if it's all loaded
            pickFirst();
          } else if (scope.addPanel) {
            scope.addPanel = false;
          }
        });

        scope.$watch('doklad', function(data) {
          if (scope.sposobyActive) {
            if (!scope.sposobyActive.suma || !scope.sposobyActive.rozpis.length) {
              if (angular.isDefined())
              scope.sposobyActive.suma = parseFloat(scope.doklad.zaklad_d1) + parseFloat(scope.doklad.zaklad_d2) + parseFloat(scope.doklad.zaklad_d0);
              scope.recountZostatok();
            }
            if (angular.isDefined(data.operacia)) {
              scope.sposobyActive.typ = data.operacia === 1 ? 0 : 1;
            }
          }
        }, true);
      }
    };
  }]);
