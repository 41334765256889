'use strict';

angular.module('casist.widgets')
  .factory('Dialog', ['$modal', '$timeout', function ($modal, $timeout) {
    var openDialog = function(templateUrl, msg, title, userinput, config) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: 'DialogAlertCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        resolve: {
          msg: function() {
            return msg;
          },
          title: function() {
            return title;
          },
          userinput: function() {
            return userinput;
          },
          config: function() {
            return config;
          }
        }
      });
      return modalInstance.result;
    };
    return {
      alert: function(msg, title) {
        return openDialog('components/widgets/dialog-alert.html', msg, title);
      },
      prompt: function(msg, title, userinput, config, template) {
        return openDialog(template || 'components/widgets/dialog-prompt.html', msg, title, userinput, config);
      },
      selection: function(msg, title, options, config) {
        return openDialog('components/widgets/dialog-selection.html', msg, title, options, config);
      },
      confirm: function(msg, title, config) {
        return openDialog('components/widgets/dialog-confirm.html', msg, title, undefined, config);
      }
    };
  }])
  .controller('DialogAlertCtrl', ['$scope', '$modalInstance', 'msg', 'title', 'userinput', 'config', function($scope, $modalInstance, msg, title, userinput, config) {
    $scope.msg = msg;
    $scope.title = title;
    if (angular.isObject(userinput)) {
      $scope.data = userinput;
    } else {
      $scope.data = {
        userinput: userinput
      };
    }
    $scope.config = config;
    $scope.iconClass = {};
    if (config && config.icon) {
      $scope.iconClass[config['icon']] = 1;
    } else {
      $scope.iconClass['fa-question-circle'] = 1;
    }

    $scope.type = config ? config.type || 'text' : 'text';

    $scope.select = function(value) {
      $modalInstance.close(value);
    };
    $scope.ok = function() {
      if (_.keys($scope.data).length === 1) {
        $modalInstance.close($scope.data.userinput);
      } else {
        $modalInstance.close($scope.data);
      }
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }])
  .directive('dialogNavigation', ['$document', '$timeout', function ($document, $timeout) {
    return {
      restrict: 'EA',
      link: function postLink(scope, element, attrs) {
        var keyPressFc = function(evt) {
          switch (evt.which) {
            case 39:
              angular.element('#cancel').focus();
              break;
            case 37:
              angular.element('#ok').focus();
              break;
          }
        };
        $document.bind('keydown', keyPressFc);
     
        scope.$on('$destroy', function () {
          $document.unbind('keydown', keyPressFc);
        });
      }
    };
  }]);
