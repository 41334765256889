'use strict';

angular.module('casist.widgets')
  .directive('salesViewer', ['Restangular', function (Restangular) {
    return {
      templateUrl: 'components/widgets/sales-viewer.html',
      restrict: 'EA',
      replace: true,
      scope: {
        record: '=',
        item: '=',
        firma: '=',
        // model: '=',
        versionChanged: '&',
        tooltipPlacement: '@',
        customStyle: "=customStyle"
      },
      link: function postLink(scope, element, attrs) {
        scope.sales = [];
        scope.loadingInProgress = false;

        attrs.$observe('tooltipPlacement', function (val) {
          if (!val)
            scope.tooltipPlacement = "right";
        });
        scope.buttonClass = "";
        attrs.$observe('buttonClass', function (val) {
          if (val) {
            var s = val.split(' ');
            for (var i = 0; i < s.length; i++)
              scope.buttonClass += " "+s[i];
          }
        });

        // watch changes of parent record (for record change through navigation)
        scope.$watch("record", function (val) {
          scope.currentVersion = 0;
          scope.currentPage = 0;
        });

        scope.pageSize = 5;

        scope.showSales = function() {
          scope.sales = [];
          scope.loadingInProgress = true;
          if (!scope.currentVersion) {
            scope.currentVersion = 0;
            scope.currentPage = 0;
          }
          Restangular.all("faktury").all("sales").getList({kod:scope.item, firma: scope.firma}).then(function (sales) {
            /*for (var i = 0; i < versions.length; i++) {
              var obj = JSON.parse(versions[i].record);
              versions[i].record = obj;
              versions[i].user = 'macav';
            }*/
            scope.sales = sales;
            scope.loadingInProgress = false;
          });
        }
        scope.numberOfPages = function() {
          return Math.ceil(scope.sales.length / scope.pageSize)-1;
        }

        /*scope.loadVersion = function (e, version) {
          e.preventDefault();
          e.stopPropagation();
          
          scope.currentVersion = scope.sales.indexOf(version);
          angular.forEach(version.record, function(value, key) {
            scope.model[key] = value;
          });
          var version_callback = scope.$eval(scope.versionChanged);
          if (angular.isDefined(version_callback))
            version_callback(version, scope.model, scope.currentVersion);
        }*/

        scope.showSalesNext = function (event) {
          event.preventDefault();
          event.stopPropagation();

          if (scope.currentPage < scope.numberOfPages())
            scope.currentPage++;
        }
        scope.showSalesPrevious = function (event) {
          event.preventDefault();
          event.stopPropagation();

          if (scope.currentPage > 0)
            scope.currentPage--;
        }

        scope.$on('$destroy', function() {
          scope.sales = [];
          // scope.currentVersion = 0;
        });
      }
    };
  }]);
