'use strict';

angular
  .module('casist.admin')
  .controller('SettingsGeneralCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      $scope.errors = {};
      $scope.Hlavicka = Hlavicka;
      $scope.UrgenciaText = UrgenciaText;
      $scope.Pata = Pata;
      $scope.Predkontacia = Predkontacia;
      $scope.accounts = [];
      $scope.TableEditor = $controller('TableEditor', {
        $scope: $scope,
        Model: BankAccount,
        List: $scope.accounts,
      });

      var generalSettingsMaster = {};
      $scope.general = {};
      var loadSettings = function () {
        BankAccount.objects()
          .getList({ interny: 'False' })
          .then(function (data) {
            angular.copy(data, $scope.accounts);
          });
        Settings.objects()
          .getList()
          .then(function (data) {
            if (data.length) {
              $scope.general = data[0];
              angular.copy($scope.general, generalSettingsMaster);
              updateImages();
            }
          });
      };
      $scope.createBankAccount = function () {
        $scope.TableEditor.startCreating('newAccount');
        $scope.newAccount = { active: true };
      };
      $scope.resetGeneralSettings = function () {
        angular.copy(generalSettingsMaster, $scope.general);
      };
      $scope.getPredkontacieVF = function (query) {
        return Predkontacia.objects().getList({
          search: query,
          typ: DokladTypy.VF.dd,
        });
      };
      $scope.addPredkontaciaVF = function (predkontacia) {
        return Predkontacia.add(predkontacia, DokladTypy.VF.dd);
      };

      $scope.saveSettings = function () {
        Settings.create(
          _.omit($scope.general, 'faktura_logo', 'peciatka')
        ).then(
          function (data) {
            angular.copy(data, $scope.general);
            Global.appendArray($scope.general);
            if ($scope.newAccount) {
              $scope.TableEditor.add('newAccount');
            }
            $scope.TableEditor.save().then(
              function () {
                Notification.add(
                  $translate.instant('settings.NASTAVENIA_ULOZENE'),
                  'success',
                  5
                );
              },
              function () {
                Notification.add(
                  'Nepodarilo sa uložiť bankové účty',
                  'danger',
                  5
                );
              }
            );
          },
          function (error) {
            $scope.errors.general = error.data;
            Notification.add(
              $translate.instant('settings.NASTAVENIA_NEULOZENE'),
              'danger',
              5
            );
          }
        );
      };

      $scope.uploadInProgress = [false, false];
      $scope.uploadProgress = [0, 0];
      var updateImages = function () {
        $scope.faktura_logo_url =
          $scope.general.faktura_logo_url + '?val=' + Math.random();
        $scope.peciatka_url =
          $scope.general.peciatka_url + '?val=' + Math.random();
      };
      var getImagePath = function (relativePath) {
        return (
          $window.location.protocol +
          '//' +
          constants.applicationServer +
          '/' +
          relativePath
        );
      };
      var onFileSelect = function ($files, fileName) {
        var type = fileName === 'faktura_logo.jpg' ? 0 : 1;
        //$files: an array of files selected, each file has name, size, and type.
        for (var i = 0; i < $files.length; i++) {
          $scope.uploadProgress[type] = 0;
          $scope.uploadInProgress[type] = true;
          var file = $files[i];
          $scope.upload = Upload.upload({
            url:
              $window.location.protocol +
              '//' +
              constants.applicationServer +
              '/upload',
            // method: "POST",
            headers: {
              Authorization: $http.defaults.headers.common.Authorization,
            },
            data: { fileName: fileName },
            file: file,
          })
            .progress(function (evt) {
              $scope.uploadProgress[type] = parseInt(
                (100.0 * evt.loaded) / evt.total
              );
            })
            .then(
              function (data) {
                data = data.data;
                // file is uploaded successfully
                if (type === 0) {
                  $scope.general.faktura_logo = data.filename;
                  $scope.general.faktura_logo_url = getImagePath(data.filename);
                } else {
                  $scope.general.peciatka = data.filename;
                  $scope.general.peciatka_url = getImagePath(data.filename);
                }
                updateImages();
                $scope.uploadProgress[type] = 0;
                $scope.uploadInProgress[type] = false;
              },
              function () {
                $scope.uploadProgress[type] = 0;
                $scope.uploadInProgress[type] = false;
              }
            );
        }
      };
      $scope.uploadLogo = function ($files) {
        onFileSelect($files, 'faktura_logo.jpg');
      };
      $scope.uploadPeciatka = function ($files) {
        onFileSelect($files, 'peciatka.jpg');
      };
      var deleteImage = function (fileName) {
        var promise = $http.delete(
          $window.location.protocol +
            '//' +
            constants.applicationServer +
            '/upload?fileName=' +
            fileName,
          {
            headers: {
              'Authorization': $http.defaults.headers.common['Authorization'],
              'X-Agenda': $http.defaults.headers.common['X-Agenda'],
            }
          }
        );
        promise.then(
          function () {
            updateImages();
          },
          function () {
            Notification.add('Logo sa nepodarilo vymazať.', 'danger', 5);
          }
        );
        return promise;
      };
      $scope.deleteLogo = function () {
        deleteImage('faktura_logo.jpg').then(function () {
          delete $scope.general.faktura_logo;
        });
      };
      $scope.deletePeciatka = function () {
        deleteImage('peciatka.jpg').then(function () {
          delete $scope.general.peciatka;
        });
      };
      if (parseInt($location.search().tab) === 1) {
        loadSettings();
      }
    },
  ])
  .controller('SettingsObdobiaCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      $scope.obdobia = [];
      var loadObdobia = function (filter) {
        $scope.obdobia.loading = true;
        Obdobie.objects()
          .getList(filter)
          .then(function (obdobia) {
            $scope.obdobia = obdobia;
          });
      };

      $scope.toggleLocked = function (obdobie) {
        obdobie.locked = !obdobie.locked;
        obdobie.update().then(function (data) {
          obdobie.locked = data.locked;
        });
      };

      var filterChanged = function (filter) {
        $scope.agendy.loading = true;
        var promise = Agenda.objects().getList(filter);
        promise.then(function (agendy) {
          $scope.agendy = agendy;
        });
        return promise;
      };

      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.SearchCtrl.init({
        onChange: loadObdobia,
        per_page: 999999,
      });

      if (parseInt($location.search().tab) === 5) {
        $scope.SearchCtrl.refresh();
      }
    },
  ])
  .controller('SettingsCislovanieCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      var rejectedDruhy = [
        DokladTypy.ID,
        DokladTypy.IN,
        DokladTypy.BD,
        DokladTypy.PD,
      ];
      var lastLoadedData = [];
      var loadCislaDokladov = function (filter, data) {
        $scope.prevadzky = Prevadzka.restangularize(
          angular.copy(Profile.get('prevadzky'))
        );
        $scope.cislaDokladov = [];
        var id = 1;
        angular.forEach(
          _.reject(DokladTypy, function (druh) {
            return rejectedDruhy.indexOf(druh) !== -1;
          }),
          function (val, key) {
            for (var i = 0; i < $scope.prevadzky.length; i++) {
              $scope.cislaDokladov.push({
                id: id++,
                dd: val.dd,
                prevadzka: $scope.prevadzky[i],
                fixne: val.shortName,
              });
            }
          }
        );
        var applyData = function (data) {
          for (var i = 0; i < data.length; i++) {
            var item = _.find($scope.cislaDokladov, function (obj) {
              return (
                obj.dd === data[i].dd && obj.prevadzka.id === data[i].prevadzka
              );
            });
            if (item) {
              item.iddokd = data[i].iddokd;
              item.vs = data[i].vs;
            }
          }
        };

        if (data) {
          applyData(data);
        } else {
          $scope.cislaDokladov.loading = true;
          CislaDokladov.objects()
            .getList()
            .then(function (data) {
              $scope.cislaDokladov.loading = false;
              applyData(data);
              lastLoadedData = data;
            });
        }
      };
      $scope.dokladTypy = _.reject(DokladTypy, function (obj) {
        return rejectedDruhy.indexOf(obj) !== -1;
      });
      var prevadzkaFilter, ddFilter;
      $scope.filterCislovaniePrevadzky = function (value) {
        if (!value) {
          prevadzkaFilter = undefined;
          return;
        }
        prevadzkaFilter = value.id;
      };
      $scope.filterCislovanieDD = function (value) {
        if (!value) {
          ddFilter = undefined;
          return;
        }
        ddFilter = value.dd;
      };
      $scope.filterCislaDokladov = function (value) {
        return (
          (!prevadzkaFilter || value.prevadzka.id === prevadzkaFilter) &&
          (!ddFilter || value.dd === ddFilter)
        );
      };
      $scope.filterCislaDokladovVS = function (value) {
        return value.dd === DokladTypy.VF.dd || value.dd === DokladTypy.OP.dd;
      };
      $scope.calculateIddokd = function (cislo) {
        var date = new Date();
        var variable = cislo.iddokd || '';
        var len = 2 - variable.length;
        for (var i = 0; i < len; i++) {
          variable += 'x';
        }
        return (
          String(cislo.fixne) +
          String(date.getFullYear()).slice(-2) +
          variable +
          'xxxxx'
        );
      };
      $scope.calculateVS = function (cislo) {
        var date = new Date();
        var variable = cislo.vs || '';
        var len = 2 - variable.length;
        for (var i = 0; i < len; i++) {
          variable += 'x';
        }
        return String(date.getFullYear()).slice(-2) + variable + 'xxxxx';
      };
      $scope.copyToPrevadzka = function (cislo, list, field) {
        _.each(_.filter(list, { prevadzka: cislo.prevadzka }), function (obj) {
          obj[field] = cislo[field];
        });
      };
      $scope.saveCislaDokladov = function () {
        CislaDokladov.create($scope.cislaDokladov).then(
          function (data) {
            loadCislaDokladov(null, data);
            Notification.add(
              $translate.instant('settings.NASTAVENIA_ULOZENE'),
              'success',
              5
            );
          },
          function () {
            Notification.add(
              $translate.instant('settings.NASTAVENIA_NEULOZENE'),
              'danger',
              5
            );
          }
        );
      };
      $scope.resetCislaDokladov = function () {
        loadCislaDokladov(lastLoadedData);
      };

      if (parseInt($location.search().tab) === 6) {
        loadCislaDokladov();
      }
    },
  ])
  .controller('SettingsGroupsCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      var permissions_map = {};
      var permissions = {};
      $scope.group = {
        modules: [],
      };
      $scope.modules = Permission.getAllModules();
      var permissionTypes = Permission.getAllTypes();
      $scope.prevadzkyAgendy = Profile.get('agendy');

      $scope.editGroupPerms = function (group) {
        $scope.selectedGroup = group;
        $scope.modules.available = angular.copy($scope.modules.availableAll);
        $scope.group.modules = [];

        Permission.objects()
          .getList()
          .then(function (perms) {
            permissions_map = Permission.buildPermissionMap(perms);
            for (var i = 0; i < perms.length; i++) {
              var parsed = perms[i].codename.split('_');
              if (!angular.isDefined(permissions[parsed[1]])) {
                permissions[parsed[1]] = {};
              }
            }

            for (var i in permissions) {
              for (var j in permissionTypes) {
                permissions[i][permissionTypes[j]] = false;
              }
            }
            for (var i = 0; i < $scope.selectedGroup.permissions.length; i++) {
              var perm = permissions_map[$scope.selectedGroup.permissions[i]];
              permissions[perm.module][perm.type] = true;
            }
            for (var i in permissions) {
              var mod = _.find($scope.modules.available, { codename: i });
              if (!mod || !permissions[i].view) {
                continue;
              }
              mod = $scope.addGroupModule(mod);
              angular.extend(mod, permissions[i]);
            }
          });
      };
      $scope.clearSelectedGroup = function () {
        $scope.selectedGroup = undefined;
      };
      $scope.addGroupModule = function (module) {
        var mod = angular.copy(module);
        // set default perms for the item
        for (var i in _.without(permissionTypes, 'delete')) {
          mod[permissionTypes[i]] = true;
        }
        mod.delete = false;
        $scope.group.modules.push(mod);
        $scope.group.modules = _.sortBy($scope.group.modules, function (obj) {
          return obj.name;
        });
        $scope.modules.available.splice(
          $scope.modules.available.indexOf(module),
          1
        );
        return mod;
      };

      $scope.removeGroupModule = function (module) {
        for (var i in permissionTypes) {
          delete module[permissionTypes[i]];
        }
        $scope.modules.available.push(module);
        $scope.modules.available = _.sortBy(
          $scope.modules.available,
          function (obj) {
            return obj.name;
          }
        );
        $scope.group.modules.splice($scope.group.modules.indexOf(module), 1);
      };

      $scope.saveGroup = function () {
        var newPerms = [];
        var types = permissionTypes;
        for (var i in $scope.group.modules) {
          var permObj = { module: $scope.group.modules[i].codename };
          var permIdx;
          for (var t in types) {
            if ($scope.group.modules[i][types[t]] || types[t] === 'view') {
              permObj.type = types[t];
              permIdx = -1;
              for (var perm in permissions_map) {
                if (angular.equals(permissions_map[perm], permObj)) {
                  permIdx = parseInt(perm);
                  break;
                }
              }
              if (permIdx !== -1) {
                newPerms.push(permIdx);
              } else {
                console.log('PERM NOT FOUND:', permObj);
              }
            }
          }
        }

        $scope.selectedGroup.patch({ permissions: newPerms }).then(
          function (data) {
            $scope.selectedGroup.permissions = data.permissions;
            Notification.add(
              $translate.instant('settings.SKUPINA_ULOZENA'),
              'success',
              5
            );
            $scope.selectedGroup = undefined;
          },
          function () {
            Notification.add(
              $translate.instant('settings.SKUPINA_NEULOZENA'),
              'danger',
              5
            );
            $scope.selectedGroup = undefined;
          }
        );
      };

      $scope.newGroup = null;
      $scope.createGroup = function () {
        $scope.newGroup = {
          agenda: $scope.prevadzkyAgendy.length
            ? $scope.prevadzkyAgendy[0].id
            : undefined,
        };
        $timeout(function () {
          $('#newGroup_name').focus();
        });
      };
      $scope.cancelCreatingGroup = function () {
        $scope.newGroup = null;
      };

      $scope.saveNewGroup = function () {
        $scope.newGroupSaving = true;
        $scope.newGroup.agenda = parseInt($scope.newGroup.agenda);
        Group.create($scope.newGroup).then(
          function (data) {
            Notification.add(
              $translate.instant('settings.SKUPINA_VYTVORENA'),
              'success',
              5
            );
            $scope.groups.push(data);
            $scope.newGroup = null;
            $scope.newGroupSaving = false;
          },
          function (error) {
            Notification.add(
              $translate.instant('settings.SKUPINA_NEVYTVORENA'),
              'danger',
              5
            );
            $scope.newGroupSaving = false;
          }
        );
      };

      $scope.deleteGroup = function (group) {
        if (
          window.confirm(
            $translate.instant('settings.SKUPINA_VYMAZAT') +
              '' +
              group.name +
              '?'
          )
        ) {
          group.remove().then(
            function (data) {
              $scope.groups.splice($scope.groups.indexOf(group), 1);
            },
            function () {
              Notification.add(
                $translate.instant('settings.SKUPINA_NEVYMAZANA'),
                'danger',
                5
              );
            }
          );
        }
      };

      $scope.editingGroup = [];

      $scope.editGroup = function (group) {
        $scope.editingGroup.push(angular.copy(group));
      };

      $scope.finishEditingGroup = function (group) {
        var edited = $scope.isEditingGroup(group);
        // group = _.find($scope.groups, {id: group.id});
        group.patch(edited).then(function (data) {
          angular.copy(data, group);
          $scope.editingGroup.splice($scope.editingGroup.indexOf(edited), 1);
        });
      };
      $scope.cancelEditingGroup = function (group) {
        var edited = $scope.isEditingGroup(group);
        $scope.editingGroup.splice($scope.editingGroup.indexOf(edited), 1);
      };
      $scope.groupFormSubmitter = function (evt, group) {
        if (evt.which === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          $scope.finishEditingGroup(group);
        }
      };

      $scope.isEditingGroup = function (group) {
        return _.find($scope.editingGroup, { id: group.id });
      };

      $scope.groups = [];
      var loadGroups = function (filter) {
        $scope.selectedGroup = undefined;
        if (!angular.isDefined(filter)) {
          filter = $scope.SearchCtrl.getQuery();
        }
        var query = {};
        if (filter.groupsAgenda) {
          query = { agenda: filter.groupsAgenda };
        }
        $scope.groups.loading = true;
        Group.objects()
          .getList(query)
          .then(function (groups) {
            $scope.groups = groups;
          });
      };

      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.SearchCtrl.init({
        onChange: loadGroups,
        per_page: 999999,
      });

      if (parseInt($location.search().tab) === 4) {
        $scope.SearchCtrl.refresh();
      }
    },
  ])
  .controller('SettingsUsersCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    'Sklad',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText,
      Sklad
    ) {
      var permissions_map = {};
      $scope.prevadzkyAgendy = Profile.get('userData').is_superuser
        ? Profile.get('agendy')
        : _.filter(Profile.get('agendy'), {
            client: Profile.get('userData').local_superuser,
          });
      $scope.sklady = Sklad.objects().getList().$object;

      var loadUsers = function (filter) {
        $scope.selectedUser = undefined;
        $scope.users = [];
        $scope.users.loading = true;
        User.objects()
          .getList(filter)
          .then(function (users) {
            $scope.users = users;
          });
      };

      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.SearchCtrl.init({
        onChange: loadUsers,
        per_page: null,
      });

      if (parseInt($location.search().tab) === 3) {
        $scope.SearchCtrl.refresh();
      }

      $scope.isLocalSuperuser = function (user) {
        return (
          user.id == Profile.get('userData').id &&
          Profile.get('userData').local_superuser
        );
      };

      var updateUserAgendas = function () {
        $scope.missingAgendy = [];

        if ($scope.selectedUser.is_superuser) {
          $scope.selectedUser.agendaFull = Profile.data().agendy;
          return;
        }

        $scope.selectedUser.agendaFull = [];
        for (var i = 0; i < $scope.prevadzkyAgendy.length; i++) {
          var userAgenda = _.find($scope.selectedUser.agenda, {
            agenda: $scope.prevadzkyAgendy[i].id,
          });
          if (userAgenda) {
            $scope.selectedUser.agendaFull.push($scope.prevadzkyAgendy[i]);
          } else {
            $scope.missingAgendy.push($scope.prevadzkyAgendy[i]);
          }
        }
      };
      $scope.editUserAgendas = function (user) {
        $scope.selectedUser = user;
        updateUserAgendas();
      };

      $scope.deleteUserAgendas = function (agenda) {
        User.objects()
          .one($scope.selectedUser.id)
          .all('agendas')
          .remove({ agenda: agenda.id }, { 'content-type': 'application/json' })
          .then(
            function () {
              $scope.selectedUser.agenda = _.without(
                $scope.selectedUser.agenda,
                _.find($scope.selectedUser.agenda, { agenda: agenda.id })
              );
              updateUserAgendas();
            },
            function () {
              Notification.add(
                $translate.instant('settings.ERROR_ODOBRATIE_AGENDY'),
                'danger',
                5
              );
            }
          );
      };
      $scope.userData = {
        newAgenda: undefined,
      };

      $scope.addUserAgendas = function (agenda) {
        if (!agenda) {
          return;
        }
        User.objects()
          .one($scope.selectedUser.id)
          .all('agendas')
          .post({ user: $scope.selectedUser.id, agenda: agenda })
          .then(
            function (data) {
              $scope.selectedUser.agenda.push(data.plain());
              updateUserAgendas($scope.prevadzkyAgendy);
              delete $scope.userData.newAgenda;
              console.log($scope.selectedUser.agenda);
            },
            function (error) {
              delete $scope.userData.newAgenda;
              var msg = $translate.instant('settings.ERROR_PRIDANIE_AGENDY');
              if (error && error.data && error.data.error) {
                msg += '\n' + error.data.error;
              }
              Notification.add(msg, 'danger', 5);
            }
          );
      };

      $scope.cancelUserAgendas = function () {
        $scope.selectedUser = null;
        $scope.clearUserPerms();
      };
      $scope.clearUserPerms = function () {
        $scope.selectedUserAgenda = null;
      };

      var permissions = {};
      var groupPermissions = {};
      $scope.panels = {
        prevadzky: {},
        pokladne: {},
        groupList: {},
      };
      $scope.user = {
        groups: [],
        prevadzky: [],
        modules: [],
        predvoleny_sklad: null,
      };

      $scope.selectedUser = null;
      $scope.selectedUserAgenda = null;
      $scope.pokladne = [];

      $scope.pickPrevadzkyVsetky = function () {
        $scope.user.prevadzky = [];
      };
      $scope.pickPokladneVsetky = function () {
        $scope.user.pokladne = [];
      };

      $scope.editPerms = function (agenda) {
        $scope.selectedUserAgenda = agenda;
        $scope.user = {
          groups: [],
          prevadzky: [],
          modules: [],
          predvoleny_sklad: null,
        };
        $scope.modules.available = angular.copy($scope.modules.availableAll);
        $scope.user.predvoleny_sklad = $scope.selectedUser.predvoleny_sklad;

        Prevadzka.objects()
          .getList({ agenda: agenda.id })
          .then(function (data) {
            $scope.prevadzky = data;
            $scope.user.prevadzky = _.filter($scope.prevadzky, function (obj) {
              return $scope.selectedUser.prevadzka.indexOf(obj.id) !== -1;
            });
            if (!isEmpty($scope.user.prevadzky)) {
              $scope.panels.prevadzky = 'vybrane';
            } else {
              $scope.panels.prevadzky = 'vsetky';
            }
          });
        Pokladna.objects()
          .getList({ agenda: agenda.id })
          .then(function (data) {
            $scope.pokladne = data;
            $scope.user.pokladne = _.filter($scope.pokladne, function (obj) {
              return $scope.selectedUser.pokladna.indexOf(obj.id) !== -1;
            });
            if (!isEmpty($scope.user.pokladne)) {
              $scope.panels.pokladne = 'vybrane';
            } else {
              $scope.panels.pokladne = 'vsetky';
            }
          });
        if ($scope.selectedUser.is_superuser) {
          angular.copy($scope.modules.available, $scope.user.modules);
          _.each($scope.user.modules, function (obj) {
            obj.add = true;
            obj.change = true;
            obj.delete = true;
            obj.export = true;
            obj.print = true;
          });
          $scope.modules.available = [];
        }
        Permission.objects()
          .getList()
          .then(function (perms) {
            permissions_map = Permission.buildPermissionMap(perms);
            for (var i = 0; i < perms.length; i++) {
              var parsed = perms[i].codename.split('_');
              if (!angular.isDefined(permissions[parsed[1]])) {
                permissions[parsed[1]] = {};
                groupPermissions[parsed[1]] = {};
              }
            }

            var agendaPerms = _.map(
              _.filter($scope.selectedUser.permissions, {
                agenda: $scope.selectedUserAgenda.id,
              }),
              (agenda) => agenda.permission
            );
            $scope.user.origPermissions = agendaPerms;
            $scope.user.rp_editcena = angular.isDefined(
              _.find(agendaPerms, function (p) {
                return p == getPermIndex('rp_editcena');
              })
            );
            $scope.user.rp_zlavapolozka = angular.isDefined(
              _.find(agendaPerms, function (p) {
                return p == getPermIndex('rp_zlavapolozka');
              })
            );
            $scope.user.rp_zlavadoklad = angular.isDefined(
              _.find(agendaPerms, function (p) {
                return p == getPermIndex('rp_zlavadoklad');
              })
            );
            $scope.user.ubytovanie_editprice = angular.isDefined(
              _.find(agendaPerms, function (p) {
                return p == getPermIndex('editprice_ubytovanie');
              })
            );

            Group.objects()
              .getList({ agenda: agenda.id })
              .then(function (groups) {
                $scope.panels.groupList = groups;
                $scope.user.groups = _.filter(
                  $scope.panels.groupList,
                  function (obj) {
                    return (
                      angular.isDefined(obj.id) &&
                      $scope.selectedUser.groups.indexOf(obj.id) !== -1
                    );
                  }
                );
                if (!$scope.selectedUser.is_superuser) {
                  for (var i in permissions) {
                    for (var j in permissionTypes) {
                      permissions[i][permissionTypes[j]] = false;
                    }
                  }
                  for (var i = 0; i < $scope.user.origPermissions.length; i++) {
                    var perm = permissions_map[$scope.user.origPermissions[i]];
                    permissions[perm.module][perm.type] = true;
                  }
                  $scope.user.modules = undefined;
                  $scope.recalculateGroupPermissions();
                }
              });
          });
      };

      $scope.recalculateGroupPermissions = function () {
        $scope.modules.available = angular.copy($scope.modules.availableAll);
        if ($scope.user.modules !== undefined) {
          // backup changed user modules
          for (var i in permissions) {
            for (var j in permissionTypes) {
              permissions[i][permissionTypes[j]] = false;
            }
          }
          var module;
          for (var i in $scope.user.modules) {
            module = $scope.user.modules[i];
            // skontrolovat v skupinach kazdu permission
            if (isEmpty(module.group)) {
              for (var j in permissionTypes) {
                permissions[module.codename][permissionTypes[j]] =
                  module[permissionTypes[j]];
              }
            } else {
              for (var j in permissionTypes) {
                permissions[module.codename][permissionTypes[j]] = _.every(
                  module.group,
                  function (obj) {
                    return !obj[permissionTypes[j]];
                  }
                )
                  ? module[permissionTypes[j]]
                  : false;
              }
              if (
                !permissions[module.codename].view &&
                _.some(_.without(permissionTypes, 'view'), function (type) {
                  return permissions[module.codename][type];
                })
              ) {
                permissions[module.codename].view = true;
              }
            }
          }
        }
        $scope.user.modules = [];
        for (var i in groupPermissions) {
          for (var j in permissionTypes) {
            groupPermissions[i][permissionTypes[j]] = false;
          }
        }

        for (var g = 0; g < $scope.user.groups.length; g++) {
          for (var i = 0; i < $scope.user.groups[g].permissions.length; i++) {
            var perm = permissions_map[$scope.user.groups[g].permissions[i]];
            if (groupPermissions[perm.module][perm.type]) {
              groupPermissions[perm.module][perm.type].push(
                $scope.user.groups[g].id
              );
            } else {
              groupPermissions[perm.module][perm.type] = [
                $scope.user.groups[g].id,
              ];
            }
          }
        }
        var allperms = angular.copy(groupPermissions);
        for (var i in permissions) {
          for (var j in permissions[i]) {
            if (!allperms[i][j]) {
              allperms[i][j] = permissions[i][j];
            }
          }
        }
        for (var i in allperms) {
          var mod = _.find($scope.modules.available, { codename: i });
          if (!mod || !allperms[i].view) {
            continue;
          }
          mod = $scope.addModule(mod);
          angular.extend(mod, allperms[i]);
          for (var j in allperms[i]) {
            if (allperms[i][j]) {
              mod[j] = true;
            }
            if (groupPermissions[i][j]) {
              for (var g in groupPermissions[i][j]) {
                var group;
                if (!mod.group) {
                  mod.group = [{ id: groupPermissions[i][j][g] }];
                  group = mod.group[0];
                } else {
                  group = _.find(mod.group, { id: groupPermissions[i][j][g] });
                  if (!group) {
                    var len = mod.group.push({ id: groupPermissions[i][j][g] });
                    group = mod.group[len - 1];
                  }
                }
                group[j] = true;
              }
            }
          }
        }
      };
      $scope.isGroupPerm = function (module, perm) {
        return (
          module.group &&
          _.some(module.group, function (obj) {
            return obj[perm];
          })
        );
      };
      $scope.changedPerm = function (module, perm) {
        if (module.group) {
          for (var i = module.group.length - 1; i >= 0; i--) {
            if (
              module.group[i][perm] &&
              module[perm] !== module.group[i][perm]
            ) {
              var group = _.find($scope.user.groups, {
                id: module.group[i].id,
              });
              _.each($scope.user.modules, function (obj) {
                if (obj.group) {
                  for (var g = obj.group.length - 1; g >= 0; g--) {
                    if (obj.group[g].id === group.id) {
                      obj.group.splice(g, 1);
                    }
                  }
                }
              });
              $scope.panels.groupList.push(angular.copy(group));
              $scope.user.groups.splice($scope.user.groups.indexOf(group), 1);
              angular.copy(
                _.sortBy($scope.panels.groupList, function (obj) {
                  return obj.name;
                }),
                $scope.panels.groupList
              );
            }
          }
        }
      };

      $scope.collapsed = [];
      $scope.modules = Permission.getAllModules();
      var pohybPerms = [
        'view_pohyb',
        'add_pohyb',
        'change_pohyb',
        'delete_pohyb',
      ];
      var faPerms = _.union(pohybPerms, [
        'view_fakturahlavicka',
        'add_fakturahlavicka',
        'change_fakturahlavicka',
        'delete_fakturahlavicka',
        'view_fakturapata',
        'add_fakturapata',
        'change_fakturapata',
        'delete_fakturapata',
        'view_fakturapredajca',
        'add_fakturapredajca',
        'change_fakturapredajca',
        'delete_fakturapredajca',
        'view_fakturadoprava',
        'add_fakturadoprava',
        'change_fakturadoprava',
        'delete_fakturadoprava',
      ]);
      var linkedPerms = {
        //'view_faktura': ['view_pohyb', 'view_fakturahlavicka', 'view_fakturapata', 'view_fakturapredajca', 'view_fakturadoprava'],
        //'change_faktura': faPerms,
        //'add_faktura': faPerms,
        //'add_pohladavka': faPerms,
        //'change_pohladavka': faPerms,
        //'view_zavazok': ['view_pohyb'],
        //'view_pokladnicnydoklad': ['view_pohyb'],
        //'change_zavazok': pohybPerms,
        //'change_pokladnicnydoklad': pohybPerms,
        //'add_zavazok': pohybPerms,
        //'add_pokladnicnydoklad': pohybPerms,
        change_myuser: [
          'view_useragenda',
          'add_useragenda',
          'change_useragenda',
          'delete_useragenda',
        ],
      };
      var permissionTypes = Permission.getAllTypes();

      $scope.addModule = function (module) {
        var mod = angular.copy(module);
        // set default perms for the item
        for (var i in _.without(permissionTypes, 'delete', 'export', 'print')) {
          mod[permissionTypes[i]] = true;
        }
        if (module.hasExport) {
          mod.export = true;
        }
        if (module.hasPrint) {
          mod.print = true;
        }
        mod.delete = false;
        $scope.user.modules.push(mod);
        $scope.user.modules = _.sortBy($scope.user.modules, function (obj) {
          return obj.name;
        });
        $scope.modules.available.splice(
          $scope.modules.available.indexOf(module),
          1
        );
        return mod;
      };

      $scope.removeModule = function (module) {
        module.view = false;
        $scope.changedPerm(module, 'view');
        for (var i in permissionTypes) {
          delete module[permissionTypes[i]];
        }
        $scope.modules.available.push(module);
        $scope.modules.available = _.sortBy(
          $scope.modules.available,
          function (obj) {
            return obj.name;
          }
        );
        $scope.user.modules.splice($scope.user.modules.indexOf(module), 1);
      };

      $scope.newUser = null;
      $scope.createUser = function () {
        $scope.newUser = {
          is_active: true,
        };
        $timeout(function () {
          $('#newUser_username').focus();
        });
      };
      $scope.cancelCreatingUser = function () {
        $scope.newUser = null;
      };

      $scope.saveNewUser = function () {
        $scope.newUser.password = 'password';
        $scope.newUserSaving = true;
        User.create($scope.newUser).then(
          function (data) {
            Notification.add(
              $translate.instant('settings.UZIVATEL_ULOZENY'),
              'success',
              5
            );
            $scope.users.push(data);
            $scope.newUser = null;
            $scope.newUserSaving = false;
            $scope.editUserAgendas(data);
          },
          function (error) {
            if (
              /exists/g.test(error.data.error) ||
              /exists/g.test(error.data.username)
            ) {
              Notification.add(
                'Používateľ s takým e-mailom je už v systéme zaregistrovaný.',
                'danger',
                5
              );
            } else {
              Notification.add(
                $translate.instant('settings.UZIVATEL_NEULOZENY'),
                'danger',
                5
              );
            }
            $scope.newUserSaving = false;
          }
        );
      };

      $scope.deleteUser = function (user) {
        if (
          window.confirm(
            'Naozaj chcete vymazať používateľa ' + user.username + '?'
          )
        ) {
          user.remove().then(
            function (data) {
              $scope.users.splice($scope.users.indexOf(user), 1);
              if ($scope.selectedUser && $scope.selectedUser.id === user.id) {
                $scope.selectedUser = undefined;
              }
            },
            function () {
              Notification.add(
                $translate.instant('settings.ERROR_UZIVATEL_DELETE'),
                'danger',
                5
              );
            }
          );
        }
      };

      $scope.userReactivate = function (user) {
        $scope.reactivateProgress = true;
        user
          .all('reactivate')
          .post()
          .then(
            function () {
              Notification.add(
                $translate.instant('settings.UZIVATEL_REAKTIVACIA_OK'),
                'success',
                5
              );
              $scope.reactivateProgress = false;
            },
            function () {
              Notification.add(
                $translate.instant('settings.UZIVATEL_REAKTIVACIA_ERROR'),
                'danger',
                5
              );
              $scope.reactivateProgress = false;
            }
          );
      };

      $scope.editingUser = [];

      $scope.editUser = function (user) {
        $scope.editingUser.push(angular.copy(user));
      };

      $scope.finishEditingUser = function (user) {
        var edited = $scope.isEditingUser(user);
        var user = _.find($scope.users, { id: user.id });
        user.patch(edited).then(function (data) {
          angular.copy(data, user);
          $scope.editingUser.splice($scope.editingUser.indexOf(edited), 1);
        });
      };
      $scope.cancelEditingUser = function (user) {
        var edited = $scope.isEditingUser(user);
        $scope.editingUser.splice($scope.editingUser.indexOf(edited), 1);
      };
      $scope.formSubmitter = function (evt, user) {
        if (evt.which === 13) {
          evt.preventDefault();
          evt.stopPropagation();
          $scope.finishEditingUser(user);
        }
      };

      $scope.isEditingUser = function (user) {
        return _.find($scope.editingUser, { id: user.id });
      };

      var getPermIndex = function (permObjOrKey) {
        var permObj = permObjOrKey;
        if (!angular.isObject(permObjOrKey)) {
          var parsed = permObjOrKey.split('_');
          permObj = { module: parsed[1], type: parsed[0] };
        }
        var permIdx = -1;
        for (var perm in permissions_map) {
          if (angular.equals(permissions_map[perm], permObj)) {
            permIdx = parseInt(perm);
            break;
          }
        }
        return permIdx;
      };

      $scope.saveUser = function () {
        // vyhodime tie, ktore patria vybranej agende, aby sme ich mohli zjednotit s prevadzkami vybranej agendy
        var ostatnePrevadzky = _.difference(
          $scope.selectedUser.prevadzka,
          _.map($scope.prevadzky, (prevadzka) => prevadzka.id)
        );
        var ostatnePokladne = _.difference(
          $scope.selectedUser.pokladna,
          _.map($scope.pokladne, (prevadzka) => prevadzka.id)
        );
        var newPerms = [];
        var _linkedPerms = [];
        var types = permissionTypes;
        for (var i in $scope.user.modules) {
          var permObj = { module: $scope.user.modules[i].codename };
          var permIdx;
          for (var t in types) {
            if ($scope.user.modules[i][types[t]] || types[t] === 'view') {
              permObj.type = types[t];
              if (
                _.every($scope.user.modules[i].group, function (obj) {
                  return !obj[types[t]];
                })
              ) {
                permIdx = getPermIndex(permObj);
                if (permIdx !== -1) {
                  newPerms.push(permIdx);
                } else {
                  console.log('PERM NOT FOUND:', permObj);
                }
              }
              var linkedPerm = linkedPerms[permObj.type + '_' + permObj.module];
              if (angular.isDefined(linkedPerm)) {
                for (var l in linkedPerm) {
                  _linkedPerms.push(getPermIndex(linkedPerm[l]));
                }
              }
            }
          }
        }
        newPerms = _.union(newPerms, _linkedPerms);
        var rpPerms = [];
        if ($scope.user.rp_editcena) {
          rpPerms.push(getPermIndex('rp_editcena'));
        }
        if ($scope.user.rp_zlavapolozka) {
          rpPerms.push(getPermIndex('rp_zlavapolozka'));
        }
        if ($scope.user.rp_zlavadoklad) {
          rpPerms.push(getPermIndex('rp_zlavadoklad'));
        }
        if ($scope.user.ubytovanie_editprice) {
          rpPerms.push(getPermIndex('editprice_ubytovanie'));
        }
        newPerms = _.union(newPerms, rpPerms);

        User.objects()
          .one($scope.selectedUser.id)
          .patch({
            groups: _.map($scope.user.groups, (group) => group.id),
            prevadzka: _.union(
              ostatnePrevadzky,
              _.map($scope.user.prevadzky, (prevadzka) => prevadzka.id)
            ),
            pokladna: _.union(
              ostatnePokladne,
              _.map($scope.user.pokladne, (pokladna) => pokladna.id)
            ),
            predvoleny_sklad: $scope.user.predvoleny_sklad,
          })
          .then(
            function (data) {
              $scope.selectedUser.groups = data.groups;
              $scope.selectedUser.prevadzka = data.prevadzka;
              $scope.selectedUser.pokladna = data.pokladna;
              $scope.selectedUser.predvoleny_sklad = data.predvoleny_sklad;
              // update permissions only if something changed
              var addedPerms = _.difference(
                newPerms,
                $scope.user.origPermissions
              );
              if (!isEmpty(addedPerms)) {
                var addedPerm;
                for (var i in addedPerms) {
                  addedPerms[i] = {
                    user: $scope.selectedUser.id,
                    agenda: $scope.selectedUserAgenda.id,
                    permission: addedPerms[i],
                  };
                }
                $scope.selectedUser
                  .all('permissions')
                  .post(addedPerms)
                  .then(function (data) {
                    for (var i = 0; i < data.length; i++) {
                      $scope.selectedUser.permissions.push(data[i]);
                    }
                  });
              }
              var removedPerms = _.difference(
                $scope.user.origPermissions,
                newPerms
              );
              if (!_.isEmpty(removedPerms)) {
                var removedPerm;
                for (var i in removedPerms) {
                  removedPerm = _.find($scope.selectedUser.permissions, {
                    agenda: $scope.selectedUserAgenda.id,
                    permission: removedPerms[i],
                  });
                  removedPerms[i] = removedPerm.id;
                }
                $scope.selectedUser
                  .all('permissions')
                  .remove(
                    { id: removedPerms },
                    { 'content-type': 'application/json' }
                  )
                  .then(function () {
                    for (var i in removedPerms) {
                      $scope.selectedUser.permissions.splice(
                        $scope.selectedUser.permissions.indexOf(
                          _.find($scope.selectedUser.permissions, {
                            id: removedPerms[i],
                          })
                        ),
                        1
                      );
                    }
                  });
              }
              $scope.user.origPermissions = newPerms;
              if (isEmpty($scope.user.groups)) {
                $scope.panels.prevadzky = 'vsetky';
              }
              Notification.add(
                $translate.instant('settings.UZIVATEL_ULOZENY'),
                'success',
                5
              );
              $scope.selectedUserAgenda = undefined;
            },
            function () {
              Notification.add(
                $translate.instant('settings.UZIVATEL_NEULOZENY'),
                'danger',
                5
              );
            }
          );
      };
    },
  ])
  .controller('SettingsAgendyCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      $scope.currentAgenda = Profile.get('agenda');
      $scope.agendy = [];

      var filterChanged = function (filter) {
        $scope.agendy.length = 0;
        $scope.agendy.loading = true;
        var promise = Agenda.objects().getList(filter);
        promise.then(function (agendy) {
          $scope.agendy = agendy;
        });
        return promise;
      };

      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.SearchCtrl.init({
        onChange: filterChanged,
        per_page: 999999,
      });

      if (parseInt($location.search().tab) === 0) {
        $scope.SearchCtrl.refresh();
      }

      $scope.createAgenda = function (item) {
        var modalInstance = $modal.open({
          templateUrl: 'admin/settings/agendy/agenda-detail.html',
          controller: 'AgendaCtrl',
          backdrop: 'static',
          windowClass: 'agenda-detail',
          resolve: {
            record: function () {
              return item ? item : {};
            },
            agendy: function () {
              return $scope.agendy;
            },
            SearchCtrl: function () {
              return $scope.SearchCtrl;
            },
          },
        });
      };

      $scope.manageUsers = function (agenda, prevadzka) {
        var query = { agenda: agenda.id };
        if (angular.isDefined(prevadzka)) {
          query.prevadzka = prevadzka.id;
        }
        $scope.setActiveTab(3, query);
      };

      $scope.manageSites = function (agenda) {
        $scope.setActiveTab(2, { agenda: agenda.id });
      };

      var events = Agenda.registerEvents(
        function () {
          return $scope.agendy;
        },
        function () {
          return $scope.SearchCtrl;
        }
      );
      $scope.$on('$destroy', function () {
        Agenda.unregisterEvents(events);
      });
    },
  ])
  .controller('SettingsPrevadzkyCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      $scope.createSite = function (site) {
        return $modal.open({
          templateUrl: 'admin/settings/prevadzky/prevadzka-detail.html',
          controller: 'PrevadzkaCtrl',
          backdrop: 'static',
          windowClass: 'prevadzka-detail',
          resolve: {
            record: function () {
              return site ? site : {};
            },
            prevadzky: function () {
              return $scope.prevadzky;
            },
            SearchCtrl: function () {
              return $scope.SearchCtrl;
            },
            config: function () {
              return undefined;
            },
          },
        });
      };

      var filterChanged = function (filter) {
        var activeAgenda = Agenda.restangularize(
          angular.copy(Profile.get('agenda'))
        );
        $scope.prevadzky = [];
        $scope.prevadzky.loading = true;
        activeAgenda.getList('prevadzky').then(function (prevadzky) {
          $scope.prevadzky = prevadzky;
        });
      };

      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.SearchCtrl.init({
        onChange: filterChanged,
        per_page: 999999,
      });

      if (parseInt($location.search().tab) === 2) {
        $scope.SearchCtrl.refresh();
      }

      var events = Prevadzka.registerEvents(
        function () {
          return $scope.prevadzky;
        },
        function () {
          return $scope.SearchCtrl;
        }
      );
      $scope.$on('$destroy', function () {
        Prevadzka.unregisterEvents(events);
      });
    },
  ])
  .controller('SettingsPlatbyCtrl', [
    '$scope',
    'Restangular',
    '$translate',
    'Dialog',
    'Profile',
    '$location',
    function ($scope, Restangular, $translate, Dialog, Profile, $location) {
      $scope.currentAgenda = Profile.get('agenda');
      $scope.platby = [];
      $scope.today = getCurrentDate();

      $scope.selectItem = function (item) {
        $scope.selectedItem = item;
        $scope.selectedItem.loading = true;
        Restangular.all('settings')
          .one('platby')
          .get({ datum: item.datum })
          .then(function (data) {
            $scope.selectedItem = data.platby[0];
            parseZaznamov($scope.selectedItem.rozpis);
          });
      };

      $scope.dobitKredit = function () {
        Dialog.alert(
          'Ak sa nechcete obťažovať platbou za program Casist 2 každý mesiac, môžete si dobiť kredit o ľubovoľnú sumu a každý mesiac sa bude automaticky znižovať o sumu, ktorú máte zaplatiť.<br><br>Kredit si môžete dobiť poslaním platby na naše číslo účtu s nasledujúcimi údajmi:<br><br>Číslo účtu: <b>2862256156/0200</b> (VÚB)<br>IBAN: <b>SK8102000000002862256156</b><br>VS: <b>' +
            $scope.clientData.client_id +
            '</b>',
          'Dobitie kreditu'
        );
      };

      var parseZaznamov = function (list) {
        for (var i = 0; i < list.length; i++) {
          var count = parseInt(list[i].count);
          switch (count) {
            case 1:
              list[i].zaznamov = 'záznam';
              break;
            case 2:
            case 3:
            case 4:
              list[i].zaznamov = 'záznamy';
              break;
            default:
              list[i].zaznamov = 'záznamov';
          }
        }
      };

      var filterChanged = function (filter) {
        $scope.platby.length = 0;
        $scope.platby.loading = true;
        var promise = Restangular.all('settings').one('platby').get(filter);
        promise.then(function (platby) {
          $scope.platby = platby.platby;
          parseZaznamov($scope.platby);
          $scope.clientData = platby.client;
          if ($scope.platby.length) {
            $scope.selectedItem = $scope.platby[0];
            parseZaznamov($scope.selectedItem.rozpis);
          }
        });
        return promise;
      };

      if (parseInt($location.search().tab) === 7) {
        filterChanged();
      }
    },
  ])
  .controller('SettingsCtrl', [
    '$scope',
    '$controller',
    '$location',
    'Profile',
    '$modal',
    'Agenda',
    'Prevadzka',
    'User',
    'Group',
    'Permission',
    '$window',
    '$timeout',
    'Settings',
    'Notification',
    '$rootScope',
    '$translate',
    'Global',
    'BankAccount',
    'Upload',
    '$http',
    'Obdobie',
    'DokladTypy',
    'CislaDokladov',
    'Hlavicka',
    'Pata',
    'Pokladna',
    'Predkontacia',
    'UrgenciaText',
    function (
      $scope,
      $controller,
      $location,
      Profile,
      $modal,
      Agenda,
      Prevadzka,
      User,
      Group,
      Permission,
      $window,
      $timeout,
      Settings,
      Notification,
      $rootScope,
      $translate,
      Global,
      BankAccount,
      Upload,
      $http,
      Obdobie,
      DokladTypy,
      CislaDokladov,
      Hlavicka,
      Pata,
      Pokladna,
      Predkontacia,
      UrgenciaText
    ) {
      if ($window.innerWidth < 1680) {
        $scope.tabStyle = 'tabs-top';
      } else {
        $scope.tabStyle = 'tabs-left';
      }
      $rootScope.notificationsContainerStyle = {
        'notifications-container-settings': true,
      };
      var search = $location.search();
      $scope.tabs = [];
      for (var i = 0; i < 8; i++) {
        $scope.tabs[i] = search.tab && parseInt(search.tab) === i;
      }

      $scope.$watch(
        'tabs',
        function (val) {
          var cnt = 0,
            idx = -1;
          for (var i = 0; i < val.length; i++) {
            if (val[i]) {
              cnt++;
              idx = i;
            }
          }
          if (cnt === 1) {
            $location.url('?tab=' + idx);
          }
        },
        true
      );

      $scope.$on('$destroy', function () {
        delete $rootScope.notificationsContainerStyle;
      });
    },
  ]);
