'use strict';
import defaultTranslations from './components/languages/defaultTranslations';
declare var angular: angular.IAngularStatic;

import { isEmpty } from './components/utils/utils-helpers';
import { buildTemplateCache } from './templates-helper';
import * as _ from 'lodash';

// TODO: Needed for bindonce library
(angular as any).lowercase =
  (angular as any).lowercase ||
  function (str) {
    return str.toLowerCase();
  };

angular.module('template', []).run([
  '$templateCache',
  function ($templateCache) {
    buildTemplateCache($templateCache);
  },
]);

angular
  .module('casist', [
    'casist.proof_of_concept',
    'casist.core',
    'template',
    'casist.widgets',
    'casist.admin',
    'casist.sklad',
    'casist.ucto',
    'ngRoute',
    'ngFileUpload',
    'ngTouch',
    'ngAnimate',
    'pasvaz.bindonce',
    'ui.router',
    'casist.svb',
    'casist.ubytovanie',
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.otherwise('/');
      $urlRouterProvider.when('', '/');

      $stateProvider
        .state('login', {
          url: '/login?demo',
          template: require('./components/auth/login.html'),
          controller: 'LoginCtrl',
        })
        .state('default', {
          url: '/',
          template: require('./app.html'),
          controller: 'MainCtrl',
        })
        .state('logout', {
          url: '/logout',
          templateUrl: 'components/auth/logout.html',
          controller: 'LogoutCtrl',
        })
        .state('profile', {
          url: '/profile',
          templateUrl: 'admin/users/profile.html',
          controller: 'UserProfileCtrl',
        })
        .state('settings', {
          url: '/settings?tab',
          templateUrl: 'admin/settings/settings.html',
        })
        .state('setup', {
          url: '/setup',
          templateUrl: 'admin/settings/setup.html',
          controller: 'SetupCtrl',
        })
        .state('register', {
          url: '/register',
          templateUrl: 'components/auth/register.html',
          controller: 'RegisterCtrl',
        })
        .state('activate', {
          url: '/activate',
          templateUrl: 'components/auth/activate.html',
          controller: 'ActivateCtrl',
        })
        .state('password-reset', {
          url: '/password-reset',
          templateUrl: 'components/auth/password-reset.html',
          controller: 'PasswordResetCtrl',
        });
    },
  ])
  .config([
    '$translateProvider',
    function ($translateProvider) {
      $translateProvider.translations('sk', defaultTranslations);
      $translateProvider.useSanitizeValueStrategy('escape');
      $translateProvider.useStaticFilesLoader({
        prefix: '/components/languages/',
        suffix: '.json',
      });
      $translateProvider.preferredLanguage('sk');
    },
  ])
  .config([
    '$tooltipProvider',
    function ($tooltipProvider) {
      $tooltipProvider.options({
        placement: 'top',
        animation: false,
        popupDelay: 0,
        appendToBody: true,
      });
    },
  ])
  .config([
    '$compileProvider',
    function ($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
    },
  ])
  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.interceptors.push([
        '$rootScope',
        '$q',
        function ($rootScope, $q) {
          return {
            request: function (config) {
              angular.forEach(config.params, function (value, key) {
                if (angular.isArray(value)) {
                  var newParamValue = value[0];
                  for (var i = 1; i < value.length; i++) {
                    newParamValue += ',' + value[i];
                  }
                  config.params[key] = newParamValue;
                }
              });
              return config;
            },
          };
        },
      ]);
    },
  ])
  .config([
    'RestangularProvider',
    function (RestangularProvider) {
      /*RestangularProvider.setResponseExtractor(function(response) {
      var newResponse = angular.copy(response);
      if (angular.isArray(response)) {
        for (var i = 0; i < newResponse.length; i++) {
          newResponse[i].originalElement = {};
          angular.forEach(response[i], function(value, key) {
              newResponse[i].originalElement[key] = angular.copy(value);
          });
        }
      } else {
        newResponse.originalElement = angular.copy(response);
      }
      return newResponse;
    });
    RestangularProvider.addRequestInterceptor(function(element, operation, what, url) {
      if (element)
        delete element.originalElement;
    });*/
      RestangularProvider.addResponseInterceptor(function (data, operation) {
        var extractedData;
        if (operation === 'getList' && angular.isDefined(data.results)) {
          extractedData = data.results;
          extractedData.count = data.count;
          if (angular.isDefined(data.sums)) {
            extractedData.sums = data.sums;
          }
          if (angular.isDefined(data.sumline)) {
            extractedData.sumline = data.sumline;
          }
          extractedData.nextPage = data.next;
          extractedData.previousPage = data.previous;
        } else {
          extractedData = data;
        }
        return extractedData;
      });
      RestangularProvider.addElementTransformer('pohyb', function (element) {
        if (element.cena_vydaj) {
          element.cena_vydaj = parseFloat(element.cena_vydaj);
        }
        if (element.cena_zm) {
          element.cena_zm = parseFloat(element.cena_zm);
        }
        if (element.nezlavnena) {
          element.nezlavnena = parseFloat(element.nezlavnena);
        }
        if (element.nezlavnena_zm) {
          element.nezlavnena_zm = parseFloat(element.nezlavnena_zm);
        }
        if (element.mv) {
          element.mv = parseFloat(element.mv);
        }
        return element;
      });
    },
  ])
  .run([
    '$rootScope',
    '$http',
    'Global',
    '$location',
    '$animate',
    'Socket',
    'Auth',
    'Restangular',
    'Agenda',
    'Permission',
    '$sessionStorage',
    '$templateCache',
    'TabService',
    '$timeout',
    function (
      $rootScope,
      $http,
      Global,
      $location,
      $animate,
      Socket,
      Auth,
      Restangular,
      Agenda,
      Permission,
      $sessionStorage,
      $templateCache,
      TabService,
      $timeout
    ) {
      $animate.enabled(true);
      // var safeUrls = ['components/auth/login.html', 'components/auth/activate.html', 'components/auth/register.html'];
      var safeUrls = [
        '/#!/login',
        '/#!/activate',
        '/#!/register',
        '/#!/password-reset',
      ];

      if ($sessionStorage.profile) {
        Auth.setProfile($sessionStorage.profile);
      } else {
        Auth.clearAuthData();
      }
      if ($sessionStorage.accesstoken) {
        $http.defaults.headers.common.Authorization =
          'Bearer ' + $sessionStorage.accesstoken;
        const agenda = Auth.profile().get('agenda');
        if (agenda && agenda.id) {
          Auth.setAgendaHeader(agenda.id);
        }
        if ($sessionStorage.settings) {
          Global.setArray($sessionStorage.settings);
          Restangular.setBaseUrl(Global.get('serverAddress'));
        }
        if (isEmpty(Auth.profile().get('agendy'))) {
          $location.path('/setup');
        }
        if (Global.get('serverName') !== null) {
          Socket.emit('registerClient', {
            clientID:
              Global.get('serverAddress') + ':' + Global.get('serverName'),
          });
          $rootScope.isAuth = true;
        }
        // load the session data used throughout the app
        /*Restangular.all('settings').all('session').getList().then(function(data) {
        for (var i = 0; i < data[0].agendy.length; i++) {
          data[0].agendy[i] = Agenda.restangularize(data[0].agendy[i]);
        }
        Auth.profile().set('agendy', data[0].agendy, true);
        Auth.profile().set('agenda', data[0].agenda, true);
        Auth.profile().set('platcadph', data[0].agenda.icdph !== '', true);
        Auth.profile().set('prevadzky', data[0].prevadzky, true);
        Auth.profile().set('pokladne', data[0].pokladne, true);
        Auth.profile().set('lastPrevadzka', data[0].lastPrevadzka, true);
        Auth.profile().set('lastPokladna', data[0].lastPokladna, true);
        Auth.profile().set('lastUcet', data[0].lastUcet, true);
        Auth.profile().set('lastVypis', data[0].lastVypis, true);
        Auth.profile().set('obdobie', data[0].obdobie, true);
        Auth.profile().set('obdobia', data[0].obdobia, true);
        Auth.profile().set('userData', data[0].user_data, true);
        delete data[0].settings.id;
        delete data[0].settings.agenda;
        Global.appendArray(data[0].settings);
        var workspace = (data[0].workspace && data[0].workspace.workspace) ? JSON.parse(data[0].workspace.workspace) : null;
        Auth.profile().set('workspace', workspace, true);
        Auth.profile().set('layouts', data[0].layouts, true);

        var permissions = Permission.parseUserPermissions(data[0].perms, data[0].user_data);
        Auth.profile().set('perms', permissions, true);

        $rootScope.$broadcast('event:auth-loginConfirmed');
        // Template preloading
        // var templates = ['sklad/fakturacia/fakturacia-detail.html', 'components/widgets/casist-date.html', 'components/widgets/version-viewer.html', 'components/widgets/sales-viewer.html'];
        // for (var i in templates) {
        //   $http.get(templates[i], { cache: $templateCache });
        // }
      });*/

        $timeout(function () {
          $rootScope.$broadcast('event:auth-loginConfirmed');
        });
      }
      $rootScope.$on('$stateChangeError', function (data) {
        console.log('state refused to change!!!', data);
      });
      $rootScope.$on(
        '$locationChangeStart',
        function (event, nextLoc, currentLoc) {
          if (!$sessionStorage.accesstoken) {
            if (
              _.every(safeUrls, function (url) {
                return !nextLoc.match(url);
              })
            ) {
              $location.path('/login');
              return;
            }
          }
          if (
            $sessionStorage.accesstoken &&
            isEmpty(Auth.profile().get('agendy')) &&
            currentLoc.match('#!/setup$') &&
            !nextLoc.match('#!/setup$') &&
            !nextLoc.match('#!/logout$')
          ) {
            event.preventDefault();
          }
        }
      );
    },
  ]);
