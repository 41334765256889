'use strict';

angular.module('casist.ubytovanie')
  .factory('Ubytovanie', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "ubytovanie";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          var result = BaseModel.validate();
          if (!this.prichod) {
            this.addError('prichod', 'Dátum príchodu nemôže byť prázdny.');
            result = false;
          }
          if (!this.odchod) {
            this.addError('odchod', 'Dátum odchodu nemôže byť prázdny.');
            result = false;
          }
          if (!this.osoba) {
            this.addError('osoba', 'Musíte vybrať osobu.');
            result = false;
          }
          if (!this.izba) {
            this.addError('izba', 'Musíte vybrať izbu.');
            result = false;
          }
          if (!this.sluzba) {
            this.addError('sluzba', 'Musíte vybrať službu.');
            result = false;
          }
          return result;
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ubytovanie/ubytovanie/ubytovanie-detail.html',
        controller: 'UbytovanieDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').ubytovanie;
      },
      parovanie: function() {
        return Restangular.all('ubytovanie').all('parovanie');
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(record) {
        var obj = parent.restangularize(record);
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      },
      uhrada: function(data) {
        return Restangular.all('ubytovanie').customPOST(data, Profile.get('agenda').jednoduche_ucto ? 'uhradarpju' : 'uhradarp');
      },
      fakturacia: function(data, query, canceller) {
        if (canceller) {
          return Restangular.all('ubytovanie').withHttpConfig({timeout: canceller}).customPOST(data, 'fakturacia', query);
        } else {
          return Restangular.all('ubytovanie').customPOST(data, 'fakturacia', query);
        }
      },
      prenos: function(data, query, canceller) {
        if (canceller) {
          return Restangular.all('ubytovanie').withHttpConfig({timeout: canceller}).customPOST(data, 'prenos', query);
        } else {
          return Restangular.all('ubytovanie').customPOST(data, 'prenos', query);
        }
      },
      updateBulk: function (ubytovania) {
        var promises = [];
        _.each(ubytovania, function (ubytovanie) {
          promises.push(
            Restangular.all(ENDPOINT).all(ubytovanie.id).customPUT(ubytovanie)
          );
        });
        return $q.all(promises);
      },
    });
  }]);
