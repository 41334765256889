'use strict';

angular.module('casist.utils')
  .filter('capitalize', function () {
    return function (input) {
      if (!input)
        return input;
      input = input.toString();
      var cap = input[0].toUpperCase();
      return cap+input.substr(1);
    };
  });
