'use strict';

angular.module('casist.ucto')
  .controller('GlobalneUctovanieCtrl', ['$scope', '$modalInstance', 'list', 'query', 'Dialog', 'predkontacieGetter', '$q', 'model', 'DokladTypy', function($scope, $modalInstance, list, query, Dialog, predkontacieGetter, $q, model, DokladTypy) {
    $scope.calendarOpened = false;
    $scope.data = {
      predkontacia: undefined,
      operation: 1
    };
    $scope.getPredkontacie = function(query) {
      return predkontacieGetter(query);
    }
    $scope.pocetZaznamov = list.count;
    $scope.oductovanieDisabled = function() {
      return _.keys(query).indexOf('predkontacia__nazov__iexact') === -1;
    };
    if ($scope.oductovanieDisabled()) {
      $scope.oductovanieText = 'Odúčtovanie je možné len keď je vybraný filter na predkontáciu.';
    } else {
      $scope.oductovanieText = 'Odúčtovanie';
    }

    var cancelPromise = $q.defer();
    $scope.inProgress = false;
    $scope.ok = function() {
      if (!$scope.data.predkontacia && parseInt($scope.data.operation) === 1) {
        return;
      }
      if (parseInt($scope.data.operation) === 2) {
        $scope.data.predkontacia = null;
      }
      $scope.inProgress = true;
      model.globalneUctovanie($scope.data.predkontacia, query, cancelPromise.promise).then(function(data) {
        $modalInstance.close(data);
      }, function(error) {
        if (error.status !== 0) {
          Dialog.alert('Nastala chyba pri zaúčtovaní.'+(error.data.error ? '<br/>'+error.data.error : ''));
        }
        $modalInstance.dismiss('error');
      });
    };
    $scope.cancelRequest = function() {
      cancelPromise.resolve();
      $scope.inProgress = false;
    }
    $scope.cancel = function() {
      if ($scope.inProgress) {
        $scope.cancelRequest();
      }
      $modalInstance.dismiss('cancelled');
    };
  }]);
