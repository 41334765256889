"use strict";

angular.module("casist.ucto").factory("BankovyDoklad", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "Upload",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    Upload,
    $translate
  ) {
    var ENDPOINT = "banka";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst === "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.ucet) {
            this.addError("ucet", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.vypis) {
            this.addError("vypis", "Pole nemôže byť prázdne");
            result = false;
          }
          // if (!this.firma) {
          // this.addError('firma', $translate.instant('errors.FIRMA'));
          //   result = false;
          // }
          if (!angular.isDefined(this.vs) || this.vs === "") {
            this.addError("vs", "Variabilný symbol musí byť zadaný!");
            result = false;
          }
          if (!/^[0-9]*$/.test(this.vs)) {
            this.addError("vs", "Variabilný symbol musí obsahovať iba čísla!");
            result = false;
          }
          return result;
        },
      });
    });

    var openDetail = function (item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: "ucto/bankovedoklady/bankovedoklady-detail.html",
        controller: "BankovyDokladDetailCtrl",
        backdrop: "static",
        keyboard: false,
        windowClass: "detail",
        resolve: {
          doklad: function () {
            return item ? item : {};
          },
          list: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return angular.extend({ predkontacie: true, ucty: true }, config);
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").pokladnicnydoklad;
      },
      parovanie: function () {
        return Restangular.all("pokladna").all("parovanie");
      },
      import: function (ucet, $files) {
        var deferred = $q.defer();
        for (var i = 0; i < $files.length; i++) {
          var file = $files[i];
          Upload.upload({
            url: Restangular.all("banka").all("import").getRestangularUrl(),
            // method: "POST",
            // headers: {'headerKey': 'headerValue'}, withCredential: true,
            data: { fileName: file.name, ucet: ucet },
            file: file,
          })
            .progress(function (evt) {
              deferred.notify(parseInt((100.0 * evt.loaded) / evt.total));
            })
            .then(
              function (data) {
                //file is uploaded successfully
                deferred.resolve(data.data);
              },
              function (error) {
                deferred.reject(error);
              }
            );
        }
        return deferred.promise;
      },
      importData: function (data) {
        return Restangular.all("banka").all("import").post(data, { import: 1 });
      },
      globalneUctovanie: function (predkontacia, query, canceller) {
        if (canceller) {
          return Restangular.all("banka")
            .withHttpConfig({ timeout: canceller })
            .customPUT(
              { predkontacia: predkontacia },
              "globalneuctovanie",
              query
            );
        } else {
          return Restangular.all("banka").customPUT(
            { predkontacia: predkontacia },
            "globalneuctovanie",
            query
          );
        }
      },
      getUctoFields: function () {
        return ["spolu", "suma1"];
      },
      edit: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(doklad, list, searchCtrl, config).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
    });
  },
]);
