'use strict';

angular.module('casist.core')
  .factory('Mailbox', ['Restangular', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = "mailbox";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
      });
    });

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').mailbox;
      }
    });
  }]);
