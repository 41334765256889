'use strict';

angular.module('casist.utils')
  .filter('time', [function() {
    return function(str) {
      if (!str) {
        return str;
      }
      var spl = str.split(':');
      if (spl.length >= 2) {
        return spl[0]+':'+spl[1];
      } else {
        return str;
      }
    };
  }]);