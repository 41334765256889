'use strict';

angular.module('casist.widgets').
  directive('imageStatus', ['$animate', function($animate) {
    return {
      scope: {
        imageStatus: '='
      },
      link: function(scope, element, attrs) {
        scope.imageStatus = false;

        element.bind('load', function() {
          $animate.removeClass(element, 'ng-hide');
          scope.$apply(function() {
            scope.imageStatus = true;
          });
        });

        element.bind('error', function() {
          if (scope.imageStatus) {
            $animate.addClass(element, 'ng-hide');
            scope.$apply(function() {
              scope.imageStatus = false;
            });
          }
        });
      }
    }
}]);