'use strict';

angular.module('casist.widgets')
  .directive('casistMonth', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.unshift(function(viewValue) {
          var sep;
          if (!viewValue.match('/')) {
            viewValue += '/'+(new Date().getFullYear());
          }
          var re = new RegExp("^([1-9]|1[012])([/ ]+)(19|20)?\\d\\d$", 'g');
          if (!re.test(viewValue)) {
            return null;
          }
          if (viewValue.match('/')) {
            sep = '/';
          } else {
            sep = ' ';
          }
          var parsed = viewValue.split(sep);
          var month = parseInt(parsed[0].trim());
          var year = parseInt(parsed[1].trim());
          var d = new Date();
          if (year && String(year).length === 2) {
            year = parseInt(new String(d.getFullYear()).slice(0, parseInt(4-String(year).length)) + String(year));
          }
          d = new Date(year, month-1, 1);
          return getCurrentDate(d);
        });

        var parseFormatter = function(modelValue) {
          var parsed = parseDate(modelValue);
          if (!angular.isDate(parsed)) {
            return modelValue;
          }
          return (parsed.getMonth()+1)+' / '+parsed.getFullYear();
        };

        ngModelCtrl.$formatters.unshift(function(modelValue) {
          if (!modelValue)
            return modelValue;

          return parseFormatter(modelValue);
        });

        element.bind('blur', function() {
          if (ngModelCtrl.$modelValue) {
            element.val(parseFormatter(ngModelCtrl.$modelValue));
            element.removeClass('warning');
          } else if (element.val()) {
            alert ('Nesprávny formát obdobia! Obdobie musí byť v tvare "mesiac / rok"');
            element.addClass('warning');
          }
        });
      }
    };
  });
