"use strict";
declare var angular: angular.IAngularStatic;
import * as _ from 'lodash';

(window as any).isEmpty = function(obj) {
  for (var key in obj) return false;
  return true;
};
export const isEmpty = (window as any).isEmpty;

(window as any).getCurrentDate = function(date) {
  var d = date;
  if (!angular.isDefined(date)) {
    d = new Date();
  }
  var month = d.getMonth() + 1;
  return d.getFullYear() + "-" + ("0" + month).slice(-2) + "-" + d.getDate();
};
(window as any).parseDate = function(str) {
  if (!str) {
    return undefined;
  }
  if (str.match("T")) {
    str = str.split("T")[0];
  }
  var parsed = str.split("-");
  return new Date(parsed[0], parsed[1] - 1, parsed[2]);
};
(window as any).makeSumFields = function(fields, lookups) {
  var newFields = [];
  for (var i = 0; i < fields.length; i++) {
    newFields.push(angular.copy(fields[i]));
    if (
      newFields[i] &&
      !newFields[i].sum &&
      lookups.indexOf(newFields[i].field) === -1
    ) {
      newFields[i].hidden = true;
    }
  }
  return newFields;
};
(window as any).processSumLines = function(list, data, lookup, getLookupValue) {
  if (!angular.isDefined(getLookupValue)) {
    getLookupValue = function(obj, lookup) {
      return obj[lookup];
    };
  }
  var spl = lookup.split("__");
  lookup = spl[spl.length - 1];
  var sums = {};
  _.each(data.sums, function(obj) {
    sums[obj[lookup]] = obj;
  });
  var lastItem = undefined;
  var sameItemCount = 0;
  var allData = angular.copy(data);
  var medzisumyCount = 0;
  var sumObj;
  for (var i = 0; i < allData.length; i++) {
    if (getLookupValue(allData[i], lookup) === lastItem) {
      sameItemCount++;
    }
    if (lastItem && lastItem !== getLookupValue(allData[i], lookup)) {
      sameItemCount = 1;
      sumObj = { medzisuma: true };
      sumObj[lookup] = lastItem;
      list.splice(
        i + medzisumyCount,
        0,
        angular.extend(sumObj, sums[lastItem])
      );
      medzisumyCount++;
    }
    lastItem = getLookupValue(allData[i], lookup);
  }
  if (
    !isEmpty(sums) &&
    lastItem &&
    (sums[lastItem].count === sameItemCount || !data.nextPage)
  ) {
    sumObj = { medzisuma: true };
    sumObj[lookup] = lastItem;
    list.splice(i + medzisumyCount, 0, angular.extend(sumObj, sums[lastItem]));
  }
};
(window as any).copyWeak = function(source, destination) {
  angular.forEach(destination, function(value, key) {
    if (!angular.isFunction(value)) {
      destination[key] = source[key];
    }
  });
};
(window as any).copyWeakSrc = function(source, destination) {
  angular.forEach(source, function(value, key) {
    if (!angular.isFunction(value)) {
      destination[key] = source[key];
    }
  });
};
(window as any).copyMissing = function(source, destination) {
  angular.forEach(source, function(value, key) {
    if (!angular.isDefined(destination[key])) {
      destination[key] = value;
    }
  });
  return destination;
};
(window as any).monthToName = function(month) {
  var name;
  switch (month) {
    case 1:
      name = "JANUAR";
      break;
    case 2:
      name = "FEBRUAR";
      break;
    case 3:
      name = "MAREC";
      break;
    case 4:
      name = "APRIL";
      break;
    case 5:
      name = "MAJ";
      break;
    case 6:
      name = "JUN";
      break;
    case 7:
      name = "JUL";
      break;
    case 8:
      name = "AUGUST";
      break;
    case 9:
      name = "SEPTEMBER";
      break;
    case 10:
      name = "OKTOBER";
      break;
    case 11:
      name = "NOVEMBER";
      break;
    case 12:
      name = "DECEMBER";
      break;
    default:
      name = "";
      break;
  }
  return name;
};
(window as any).objDiff = function(obj1, obj2) {
  var diff = [];
  var omitValues = ["$$hashKey", "fromServer", "parentResource"];
  for (var i = 0; i < omitValues.length; i++) {
    obj1 = _.omit(obj1, omitValues[i]);
    obj2 = _.omit(obj2, omitValues[i]);
  }
  angular.forEach(obj1, function(value, key) {
    if (
      (angular.isObject(obj1[key]) && !angular.isObject(obj2[key])) ||
      (!angular.isObject(obj1[key]) && angular.isObject(obj2[key]))
    ) {
      diff.push({ field: key, obj1: value, obj2: obj2[key] });
    } else if (angular.isObject(obj1[key]) && angular.isObject(obj2[key])) {
    } else {
      if (
        !angular.isFunction(value) &&
        (!angular.isDefined(obj2[key]) || obj2[key] != value) &&
        (value || obj2[key])
      )
        diff.push({ field: key, obj1: value, obj2: obj2[key] });
    }
  });
  angular.forEach(obj2, function(value, key) {
    if (
      !angular.isObject(value) &&
      !angular.isFunction(value) &&
      ((value && (!angular.isDefined(obj1[key]) || obj1[key] === null)) ||
        value != obj1[key])
    ) {
      var item = _.find(diff, { field: key });
      if (!item) {
        diff.push({ field: key, obj2: value, obj1: obj1[key] });
      }
    }
  });
  return diff;
};
(window as any).objDiffRecursive = function(obj1, obj2) {
  var diff = [];
  var omitValues = [
    "$$hashKey",
    "fromServer",
    "parentResource",
    "route",
    "restangularCollection",
    "restangularized",
    "created",
  ];
  for (var i = 0; i < omitValues.length; i++) {
    obj1 = _.omit(obj1, omitValues[i]);
    obj2 = _.omit(obj2, omitValues[i]);
  }
  angular.forEach(obj1, function(value, key) {
    if (
      (angular.isObject(obj1[key]) && !angular.isObject(obj2[key])) ||
      (!angular.isObject(obj1[key]) && angular.isObject(obj2[key]))
    ) {
      diff.push({ field: key, obj1: value, obj2: obj2[key] });
    } else if (angular.isObject(obj1[key]) && angular.isObject(obj2[key])) {
      diff = _.union(diff, (window as any).objDiffRecursive(obj1[key], obj2[key]));
    } else {
      if (
        !angular.isFunction(value) &&
        (!angular.isDefined(obj2[key]) || obj2[key] != value) &&
        (value || obj2[key])
      )
        diff.push({ field: key, obj1: value, obj2: obj2[key] });
    }
  });
  angular.forEach(obj2, function(value, key) {
    if (
      !angular.isObject(value) &&
      !angular.isFunction(value) &&
      ((value && (!angular.isDefined(obj1[key]) || obj1[key] === null)) ||
        value != obj1[key])
    ) {
      var item = _.find(diff, { field: key });
      if (!item) {
        diff.push({ field: key, obj2: value, obj1: obj1[key] });
      }
    } else if (
      (angular.isObject(obj1[key]) && !angular.isObject(obj2[key])) ||
      (!angular.isObject(obj1[key]) && angular.isObject(obj2[key]))
    ) {
      diff.push({ field: key, obj2: value, obj1: obj1[key] });
    } else if (angular.isObject(obj1[key]) && angular.isObject(obj2[key])) {
      diff = _.union(diff, (window as any).objDiffRecursive(obj1[key], obj2[key]));
    }
  });
  return diff;
};
(window as any).objEquals = function(obj1, obj2) {
  return isEmpty((window as any).objDiffRecursive(obj1, obj2));
};
(window as any).makeUnique = function(a) {
  var temp = {};
  for (var i = 0; i < a.length; i++) temp[a[i]] = true;
  var r = [];
  for (var k in temp) {
    r.push(k);
  }
  return r;
};
(window as any).sortList = function(list, sort) {
  list = _.sortBy(list, function(obj) {
    return obj[sort.sort];
  });
  var sortedList = [];
  if (sort.reverse) {
    for (var i = list.length - 1; i >= 0; i--) {
      sortedList.push(list[i]);
    }
  } else {
    sortedList = list;
  }
  return sortedList;
};
(window as any).isRegExp = function(value) {
  return toString.call(value) === "[object RegExp]";
};
(window as any).deepcopy = function(source, destination) {
  var destination = angular.copy(source);
  for (var key in source) {
    if (source.hasOwnProperty(key) && !/^[0-9]+$/.test(key)) {
      destination[key] = source[key];
    }
  }
  return destination;
};
