'use strict';

angular.module('casist.core')
  .controller('MailboxCtrl', ['$scope', 'Mailbox', 'Profile', '$controller', 'TabService', 'Outputs', 'Firma', function ($scope, Mailbox, Profile, $controller, TabService, Outputs, Firma) {
    $scope.slideAnimation = true;

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.Firma = Firma;

    $scope.mailbox = [];
    var filterChanged = function(filter) {
      var promise = Mailbox.objects().getList(filter);
      $scope.mailbox.loading = true;
      promise.then(function(data) {
        TabService.registerScope('mailbox', $scope);
        $scope.mailbox = data;
      });
      return promise;
    };
    $scope.mailboxTable = [
      { label: 'mailbox.SMS' },
      { field: 'date', label: 'mailbox.DATE', width: 150, type: 'date', filterCollapse: 'right' },
      { field: 'related_record__firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" }},
      { field: 'to', label: 'mailbox.TO', filterType: 'icontains' },
      { field: 'subject', label: 'mailbox.SUBJECT', filterType: 'icontains' },
      { field: 'related', label: 'mailbox.RELATED_RECORD'},
      {}//,
      // { field: 'attachment', label: 'mailbox.ATTACHMENT'}
    ];
    // TODO: Attachments
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.mailboxTable,
      sort: {sort: 'date'}
    });
    $scope.SearchCtrl.refresh(TabService.bus.get('mailbox'));

    var events = Mailbox.registerEvents(function() { return $scope.mailbox; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Mailbox.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.exportMailbox = function() {
      Outputs.openXLSExportUrl('mailbox', $scope.SearchCtrl.getQuery());
    };

    $scope.updateMail = function(obj) {
      delete obj.attachment;
      obj.sms = !obj.sms;
      obj.update();
    };

  }]);
