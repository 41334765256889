"use strict";

angular.module("casist.admin").controller("PrintSettingsCtrl", [
  "$scope",
  "$modalInstance",
  "$controller",
  "$location",
  "Settings",
  "Prevadzka",
  "record",
  "prevadzky",
  "Notification",
  "Global",
  "BankAccount",
  "$translate",
  function (
    $scope,
    $modalInstance,
    $controller,
    $location,
    Settings,
    Prevadzka,
    record,
    prevadzky,
    Notification,
    Global,
    BankAccount,
    $translate
  ) {
    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Prevadzka,
    });
    $scope.SettingsRecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Settings,
    });

    $scope.RecordCtrl.main.init({
      endpoint: "settings/prevadzky",
      model: "prevadzka",
      master: record,
      list: prevadzky,
      $modalInstance: $modalInstance,
    });

    $scope.errors = {
      prevadzka: {},
      settings: {},
    };

    $scope.prevadzka = Prevadzka.copy(record);
    $scope.accounts = [];
    $scope.TableEditor = $controller("TableEditor", {
      $scope: $scope,
      Model: BankAccount,
      List: $scope.accounts,
    });
    BankAccount.objects()
      .getList({ interny: "False" })
      .then(function (data) {
        angular.copy(data, $scope.accounts);
      });
    $scope.settings = {};
    Settings.objects()
      .getList()
      .then(function (data) {
        if (data.length) {
          $scope.settings = data[0];
        }
      });

    $scope.createBankAccount = function () {
      $scope.TableEditor.startCreating("newAccount");
      $scope.newAccount = { active: true };
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: record });
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(
          function (result) {
            Settings.create(
              _.omit($scope.settings, "faktura_logo", "peciatka")
            ).then(
              function (data) {
                angular.copy(data, $scope.settings);
                Global.appendArray($scope.settings);
                if ($scope.newAccount) {
                  $scope.TableEditor.add("newAccount");
                }
                $scope.TableEditor.save().then(
                  function () {
                    $modalInstance.close({
                      prevadzka: result.record,
                      settings: $scope.settings,
                      accounts: $scope.accounts,
                    });
                  },
                  function () {
                    Notification.add(
                      "Nepodarilo sa uložiť bankové účty",
                      "danger",
                      5
                    );
                  }
                );
              },
              function (error) {
                $scope.errors.settings = error.data;
                Notification.add(
                  $translate.instant("settings.NASTAVENIA_NEULOZENE"),
                  "danger",
                  5
                );
              }
            );
          },
          function (error) {
            Notification.add("Chyba: " + error, "danger", 5);
          }
        );
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);
