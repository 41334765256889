'use strict';

angular.module('casist.core')
  .controller('FirmaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'Firma', 'SearchCtrl', 'firma', 'firmy', '$timeout', '$modal', '$q', 'Predkontacia', 'DokladTypy', 'Outputs', 'CennikZaznam',
              function($scope, $modalInstance, $controller, Profile, Firma, SearchCtrl, firma, firmy, $timeout, $modal, $q, Predkontacia, DokladTypy, Outputs, CennikZaznam) {

    // this.editSite = function(site) {
    //   var promise = $q.defer();
    //   $scope.createSite(site).then(function(data) {
    //     var siteItem = _.find($scope.firma.prevadzky, {id: site.id});
    //     angular.copy(site, siteItem);
    //     $scope.RecordCtrl.related.edit(1, siteItem);
    //     $scope.RecordCtrl.main.save().then(function() {
    //       promise.resolve(data);
    //     }, function() {
    //       promise.reject();
    //     });
    //   }, function() {
    //     promise.reject();
    //   });
    //   return promise.promise;
    // };

    $scope.firmy = firmy;
    $scope.Predkontacia = Predkontacia;
    $scope.getPredkontaciePD = function(query) {
      return Predkontacia.objects().getList({search: query, typ: DokladTypy.PD.dd});
    };
    $scope.getPredkontacieDF = function(query) {
      return Predkontacia.objects().getList({search: query, typ: DokladTypy.DF.dd});
    };
    $scope.addPredkontaciaPD = function(predkontacia) {
      return Predkontacia.add(predkontacia, DokladTypy.PD.dd);
    };
    $scope.addPredkontaciaDF = function(predkontacia) {
      return Predkontacia.add(predkontacia, DokladTypy.DF.dd);
    };

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Firma
    });

    var defaultFirma = {
    };

    CennikZaznam.getCenniky().then(function(data) {
      $scope.cenniky = data;
    });

    var firmaPostSave = function(obj) {
      angular.copy($scope.osoby, obj.osoby);
      angular.copy($scope.prevadzky, obj.prevadzky);
      return obj;
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'firmy',
      model: 'firma',
      master: firma,
      list: firmy,
      defaultItem: defaultFirma,
      postSave: firmaPostSave,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });
    if (!isEmpty(firma)) {
      $scope.firma = Firma.copy(firma);
      if (firma.id) {
        $scope.osoby = $scope.firma.osobyProvider().restangularize(angular.copy($scope.firma.osoby));
        $scope.prevadzky = $scope.firma.prevadzkyProvider().restangularize(angular.copy($scope.firma.prevadzky));
      } else {
        if (firma.osoby) {
          $timeout(function() {
            for (var i = 0; i < firma.osoby.length; i++) {
              $scope.RecordCtrl.related.add(_.omit(firma.osoby[i], 'id'));
            }
          });
        }
        if (firma.prevadzky) {
          $timeout(function() {
            for (var i = 0; i < firma.prevadzky.length; i++) {
              $scope.RecordCtrl.related.add(1, _.omit(firma.prevadzky[i], 'id'));
            }
          });
        }
      }
    } else {
      $scope.firma = angular.copy(defaultFirma);
      $scope.osoby = [];
      $scope.prevadzky = [];
    }

    $scope.newPerson = undefined;
    $scope.createPerson = function() {
      $scope.newPerson = {};
      $timeout(function() {
        $('#newPerson_meno').focus();
      });
    };
    $scope.cancelCreatingPerson = function() {
      $scope.newPerson = undefined;
    };
    var getOsobyData = function(master) {
      $scope.osoby = master.osoby;
    };
    var getPrevadzkyData = function(master) {
      $scope.prevadzky = master.prevadzky;
    };

    $scope.RecordCtrl.related.init([
    {
      endpoint: 'osoby',
      model: 'osoby',
      lookup: 'firma',
      dataProvider: getOsobyData,
      postSave: $scope.cancelCreatingPerson
    },
    {
      endpoint: 'firmaprevadzky',
      model: 'prevadzky',
      lookup: 'parent',
      dataProvider: getPrevadzkyData
    }]);

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: firma});
      });
    };

    $scope.createSite = function(item) {
      var modalInstance = $modal.open({
        templateUrl: 'core/firmy/firmaprevadzka-detail.html',
        controller: 'FirmaPrevadzkaDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          firma: function() {
            return item ? item : {};
          },
          firmy: function() {
            return $scope.prevadzky;
          }
        }
      });

      modalInstance.result.then(function(site) {
        if (item) {
          angular.copy(site, item);
        } else {
          $scope.RecordCtrl.related.add(1, site);
        }
      }, function(reason) {
        if (reason === 'remove') {
          $scope.RecordCtrl.related.remove(1, item);
        }
      });
      return modalInstance.result;
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.printIdKlient = function (firma) {
      Outputs.openPrintUrl('firmy', firma.id, undefined, 'printidklient');
    };

  }]);
