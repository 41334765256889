'use strict';

angular.module('casist.ucto')
  .factory('DokladCommon', ['$q', 'DokladTypy', 'DokladTypyPrijem', 'DokladTypyBezDPH', '$timeout', function ($q, DokladTypy, DokladTypyPrijem, DokladTypyBezDPH, $timeout) {
    return {
      getCenaFields: function() {
        var mnozstvoField = DokladTypyPrijem.indexOf(this.dd) === -1 ? 'mv' : 'mp';
        var cenaField = DokladTypyPrijem.indexOf(this.dd) === -1 ? 'cena_vydaj' : 'cena_naklady';
        return {cena: cenaField, mnozstvo: mnozstvoField};
      },
      recountSums: function(pohyb) {
        var mnozstvoField = this.getCenaFields().mnozstvo;
        var cenaField = this.getCenaFields().cena;
        var deferred = $q.defer();
        var self = this;
        var countIt = function(pohyb) {
          var spolu = 0, z1 = 0, z2 = 0, z0 = 0, d1 = 0, d2 = 0;
          if (!pohyb.length) {
            // var sums = {zaklad_d1: z1, d1: d1, zaklad_d2: z2, d2: d2, zaklad_d0: z0, spolu: spolu};
            // ked nemame pohyb, nebudeme forcovat nulove hodnoty, lebo mozu byt zadane manualne cez pohladavky, etc.
            var sums = {};
            $timeout(function() {
              deferred.resolve(sums);
            });
            return deferred.promise;
          }
          var sd1 = parseFloat(self.sd1) || null;
          var sd2 = parseFloat(self.sd2) || null;
          var zlava = parseFloat(self.zlava) || 0;
          var typdph_data = self.typdph_data ? self.typdph_data : {nulldph: false};
          if (self.dd === 16) {
            var sums = {};
            $timeout(function() {
              deferred.resolve(sums);
            });
            return deferred.promise;
          }
          for (var i = 0; i < pohyb.length; i++) {
            if (!typdph_data.nulldph && pohyb[i].dph == sd1)
              z1 += parseFloat(pohyb[i][cenaField] || 0) * parseFloat(pohyb[i][mnozstvoField] || 0);
            else if (!typdph_data.nulldph && pohyb[i].dph == sd2)
              z2 += parseFloat(pohyb[i][cenaField] || 0) * parseFloat(pohyb[i][mnozstvoField] || 0);
            else z0 += parseFloat(pohyb[i][cenaField] || 0) * parseFloat(pohyb[i][mnozstvoField] || 0);
          }
          z1 = Math.round( (z1 * ((100-zlava) / 100.0))*100) / 100.0;
          z2 = Math.round( (z2 * ((100-zlava) / 100.0))*100) / 100.0;
          z0 = Math.round( (z0 * ((100-zlava) / 100.0))*100) / 100.0;
          d1 = Math.round( (z1 * ((sd1 || 0)/100.0)) * 100) / 100.0;
          d2 = Math.round( (z2 * ((sd2 || 0)/100.0)) * 100) / 100.0;
          spolu = Math.round( (z1 + z2 + z0 + d1 + d2) * 100) / 100.0;
          // OP a OZ
          if (DokladTypyBezDPH.indexOf(self.dd) !== -1) {
            z1 = 0;
            z2 = 0;
            z0 = 0;
            d1 = 0;
            d2 = 0;
          }
          var sums = {zaklad_d1: z1, d1: d1, zaklad_d2: z2, d2: d2, zaklad_d0: z0};
          var napocitane = {};
          var sumy15 = {
            suma1: 0,
            suma2: 0,
            suma3: 0,
            suma4: 0,
            suma5: 0
          }
          var field;
          for (var i = 0; i < pohyb.length; i++) {
            if (pohyb[i].karta_data && pohyb[i].karta_data.typ_data && pohyb[i].karta_data.typ_data.sumacia) {
              field = pohyb[i].karta_data.typ_data.sumacia;
              napocitane[field] = true;
              sumy15[field] += parseFloat(pohyb[i][cenaField] || 0) * parseFloat(pohyb[i][mnozstvoField] || 0);
            }
          }
          angular.forEach(sumy15, function(val, key) {
            // ak sa pouziva v rozpise, alebo bolo v doklade napocitane nieco a teraz uz nie je, tak nech to vymaze
            if (napocitane[key] || self[key]) {
              sums[key] = Math.round( (val * ((100-zlava) / 100.0))*100) / 100.0;
            }
          });
          if ((self.dd !== DokladTypy.DF.dd && self.dd !== DokladTypy.OZ.dd) || !parseFloat(self.kurz)) {
            angular.extend(sums, {spolu: spolu});
          }
          copyWeakSrc(sums, self);
          if (self.kurz) {
            self.spolu_zm = Math.round( (parseFloat(self.spolu) * parseFloat(self.kurz))*100) / 100.0;
            sums.spolu_zm = self.spolu_zm;
          }
          deferred.resolve(sums);
        }
        if (!pohyb) {
          this.getList('pohyb').then(function(data) {
            countIt(data);
          });
        } else {
          countIt(pohyb);
        }
        return deferred.promise;
      },
      countEditableSums: function(evt) {
        if (this[evt.target.id] === 0) {
          return;
        }
        var self = this;
        self[evt.target.id] = Math.round(self[evt.target.id] * 100) / 100.0;
        if (self.zaklad_d1 && !(self.d1 && evt.target.id === 'd1')) {
          self.d1 = Math.round( ((self.sd1 / 100.0) * self.zaklad_d1) * 100) / 100.0;
        } else if (self.d1) {
          self.zaklad_d1 = Math.round( (self.d1 / (self.sd1 / 100.0)) * 100) / 100.0;
        } else {
          self.zaklad_d1 = 0;
          self.d1 = 0;
        }
        if (self.zaklad_d2 && !(self.d2 && evt.target.id === 'd2')) {
          self.d2 = Math.round( ((self.sd2 / 100.0) * self.zaklad_d2) * 100) / 100.0;
        } else if (self.d2) {
          self.zaklad_d2 = Math.round( (self.d2 / (self.sd2 / 100.0)) * 100) / 100.0;
        } else {
          self.zaklad_d2 = 0;
          self.d2 = 0;
        }
        if (!self.zaklad_d0) {
          self.zaklad_d0 = 0;
        }
        self.spolu = Math.round( (parseFloat(self.zaklad_d1) + parseFloat(self.d1) + parseFloat(self.zaklad_d2) + parseFloat(self.d2) + parseFloat(self.zaklad_d0)) * 100) / 100.0;
      },
      countZM: function() {
        if (this.kurz) {
          this.spolu_zm = Math.round( (parseFloat(this.spolu) * parseFloat(this.kurz))*100 ) / 100.0;
        }
      },
      prepocitatPohybKurzom: function(pohyb) {
        var cenaField = this.getCenaFields().cena;
        var kurz = parseFloat(this.kurz);
        if (kurz) {
          for (var i = 0; i < pohyb.length; i++) {
            if (this.primarna_zm) {
              pohyb[i].nezlavnena = Math.round( ((parseFloat(pohyb[i].nezlavnena_zm) ? parseFloat(pohyb[i].nezlavnena_zm) : parseFloat(pohyb[i][cenaField])) / kurz)*10000) / 10000.0;
            } else {
              pohyb[i].nezlavnena_zm = Math.round( ((parseFloat(pohyb[i].nezlavnena) ? parseFloat(pohyb[i].nezlavnena) : parseFloat(pohyb[i][cenaField])) * kurz)*10000) / 10000.0;
            }
            pohyb[i].recountDiscountedPrice(this);
          }
        } else {
          for (var i = 0; i < pohyb.length; i++) {
            pohyb[i].nezlavnena_zm = 0;
            pohyb[i].recountDiscountedPrice(this);
          }
        }
      }
    };
  }]);
angular.module('casist.ucto')
  .factory('Doklad', ['Profile', 'Faktura', '$q', 'DokladTypy', 'Zavazok', 'PokladnicnyDoklad', 'BankovyDoklad', 'InternyDoklad', 'PenaznyDennik', 'Ponuka', 'ZalohovaFaktura', 'Nakup', function (Profile, Faktura, $q, DokladTypy, Zavazok, PokladnicnyDoklad, BankovyDoklad, InternyDoklad, PenaznyDennik, Ponuka, ZalohovaFaktura, Nakup) {
    var windowControllers = {};
    var modelMap = {};
    modelMap[DokladTypy.VF.dd] = Faktura;
    modelMap[DokladTypy.OP.dd] = Faktura;
    modelMap[DokladTypy.DF.dd] = Zavazok;
    modelMap[DokladTypy.OZ.dd] = Zavazok;
    if (Profile.get('jednoducheUcto')) {
      modelMap[DokladTypy.PD.dd] = PenaznyDennik;
      modelMap[DokladTypy.RP.dd] = PenaznyDennik;
      modelMap[DokladTypy.BD.dd] = PenaznyDennik;
    } else {
      modelMap[DokladTypy.PD.dd] = PokladnicnyDoklad;
      modelMap[DokladTypy.RP.dd] = PokladnicnyDoklad;
      modelMap[DokladTypy.BD.dd] = BankovyDoklad;
    }
    modelMap[DokladTypy.IN.dd] = InternyDoklad;
    modelMap[DokladTypy.PO.dd] = Ponuka;
    modelMap[DokladTypy.NA.dd] = Nakup;
    modelMap[DokladTypy.ZF.dd] = ZalohovaFaktura;
    // modelMap[DokladTypy.ID.dd] = InternyDoklad;
    // var recordTypeMap = {
    //   1: { controllerName: 'PohladavkyCtrl', model: Faktura, controller: null }
    // };

    return {
      getUctoFields: function() {
        return ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'spolu', 'suma1', 'suma2', 'suma3', 'suma4', 'suma5'];
      },
      editRecord: function(id, type) {
        if ((type === undefined || type === null) && !angular.isObject(id)) {
          throw new Error("You have to provide a type of record, or full object as a first parameter.");
          return;
        }
        var deferred = $q.defer();
        var model = modelMap[type || id.dd];
        id = id.id || id;
        model.objects().getList({id: id}).then(function(data) {
          // if (!type.controller) {
          //   var windowScope = scope.$new();
          //   windowScope.helperController = true;
          //   type.controller = $controller(type.controllerName, {
          //     $scope: windowScope
          //   });
          // }
          model.edit(data[0]).then(function(data) {
            deferred.resolve(data);
          }, function(error) {
            deferred.reject(error);
          });
        });
        return deferred.promise;
      },
      syncCreate: function(doklad) {
        if (!angular.isDefined(doklad.dd)) {
          return;
        }
        modelMap[doklad.dd].syncCreate(doklad);
      },
      syncChange: function(doklad, original) {
        if (!angular.isDefined(doklad.dd)) {
          return;
        }
        modelMap[doklad.dd].syncChange(doklad, original);
      },
      syncDelete: function(doklad) {
        if (!angular.isDefined(doklad.dd)) {
          return;
        }
        modelMap[doklad.dd].syncDelete(doklad);
      }
    };
  }]);
