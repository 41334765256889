'use strict';

angular.module('casist.ucto')
  .factory('Prikaz', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    var ENDPOINT = "prikazy";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!this.d_vyst === '') {
            this.addError('d_vyst', 'Pole nemôže byť prázdne');
            result = false;
          }
          if (!this.d_splat) {
            this.addError('d_zdp', 'Pole nemôže byť prázdne');
            result = false;
          }
          if (!this.ucet) {
            this.addError('ucet', 'Pole nemôže byť prázdne');
            result = false;
          }
          if (!this.ucet_kredit) {
            this.addError('ucet_kredit', 'Pole nemôže byť prázdne');
            result = false;
          }
          if (!this.prikaz) {
            this.addError('prikaz', 'Pole nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.vs) || this.vs === '') {
            this.addError('vs', 'Variabilný symbol musí byť zadaný!');
            result = false;
          }
          if (!/^[0-9]*$/.test(this.vs)) {
            this.addError('vs', 'Variabilný symbol musí obsahovať iba čísla!');
            result = false;
          }
          return result;
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'ucto/prikazy/prikazy-detail.html',
        controller: 'PrikazyDetailCtrl',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'detail-md',
        resolve: {
          doklad: function() {
            return item ? item : {};
          },
          list: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').prikaz;
      },
      parovanie: function() {
        return Restangular.all('prikazy').all('parovanie');
      },
      edit: function(doklad, list, searchCtrl) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(doklad, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      }
    });
  }]);
