'use strict';

angular.module('casist.sklad').controller('KartaTypyCtrl',
              ['$scope', '$modalInstance', '$timeout', 'list', '$modal', '$controller', 'Profile', 'KartaTyp', 'Ucet',
              function ($scope, $modalInstance, $timeout, list, $modal, $controller, Profile, KartaTyp, Ucet) {

    if (list) {
      $scope.typy = angular.copy(list);
    } else {
      $scope.typy = [];
      KartaTyp.objects().getList().then(function(data) {
        $scope.typy = data;
      });
    }
    $scope.Ucet = Ucet;
    $scope.ucetSelected = function(typ, parent) {
      $scope.TableEditor.edit(parent);
    };

    $scope.sumacie = [
      {id: 'suma1', label: 'Suma 1'},
      {id: 'suma2', label: 'Suma 2'},
      {id: 'suma3', label: 'Suma 3'},
      {id: 'suma4', label: 'Suma 4'},
      {id: 'suma5', label: 'Suma 5'}
    ];

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: KartaTyp
    });

    $scope.TableEditor = $controller('TableEditor', {
      $scope: $scope,
      Model: KartaTyp,
      List: $scope.typy
    });

    $scope.RecordCtrl.main.init({
      endpoint: 'list',
      model: 'doklad',
      master: undefined,
      list: undefined,
      defaultItem: {},
      searchCtrl: undefined,
      $modalInstance: $modalInstance
    });

    $scope.ok = function () {
      if ($scope.newTyp) {
        $scope.TableEditor.add('newTyp');
        return;
      }
      var promise = $scope.TableEditor.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close({record: $scope.typy});
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

  }]);
