'use strict';

angular.module('casist.ucto')
  .factory('Pokladna', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal) {
    Restangular.extendModel('pokladne', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var result = BaseModel.validate();
          if (!this.nazov) {
            this.addError('nazov', 'Pole nemôže byť prázdne');
            result = false;
          }
          return result;
        },
        typName: function() {
          return this.typ == 2 ? 'Registračná' : 'Účtovná';
        }
      });
    });
    var openDetail = function(list) {
      var modalInstance = $modal.open({
        templateUrl: 'ucto/pokladnicnedoklady/pokladne/pokladne.html',
        controller: 'PokladneCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          list: function() {
            return list;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object('pokladna/pokladne');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').pokladnicnydoklad;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      },
      add: function(pokladna) {
        var promise = parent.create({nazov: pokladna});
        promise.then(function (result) {
          console.log('Typ added: ', pokladna);
        }, function (error) {
          console.log('Chyba: ', error);
        });
        return promise;
      },
      editPokladne: function(list) {
        var promise = $q.defer();
        openDetail(list).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      edit: function (pokladna) {
        var str = null;
        if ( (str = window.prompt("Upravit pokladnu:", pokladna.nazov)) ) {
          pokladna.nazov = str;
          return pokladna.patch();
        }
      },
      remove: function (pokladna) {
        console.log("Remove pokladna: ", pokladna);
        return pokladna.remove();
      }
    });
  }]);
