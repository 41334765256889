'use strict';

angular.module('casist.widgets')
  .directive('mcDropdown', [function () {
    return {
      templateUrl: 'components/widgets/dropdown.html',
      restrict: 'EA',
      replace: true,
      scope: {
        model: '=ngModel',
        data: '=',
        label: '@',
        icon: '@',
        lookup: '@'
      },
      link: function postLink(scope, element, attrs) {
        if (!angular.isDefined(scope.lookup)) {
          scope.lookup = attrs.ngModel;
        }

        scope.required = angular.isDefined(attrs.required) ? scope.$eval(attrs.required) : false;

        scope.clearValue = function() {
          scope.model = undefined;
        };

        scope.selectValue = function(value) {
          if (angular.isDefined(value.id)) {
            scope.model = value.id;
          } else {
            scope.model = value;
          }
        };

        scope.getByID = function(id) {
          return _.find(scope.data, {id: id});
        };
      }
    };
  }]);
