'use strict';

angular.module('casist.search')
  .controller('SearchCtrl', ['$timeout', '$location', 'Global', function ($timeout, $location, Global) {
    var defaultPeriod = undefined;
    var defaultFilter = undefined;
    var onChangeHandler = angular.noop;
    var filterObservers = [];
    var sumsObservers = [];
    var universalQuery = undefined;
    var columnsDef = undefined;
    var scope;
    this.max_paginator_size = 10;
    this.per_page = Global.get('page_size', 20);
    // defaults to the last page
    this.page = 'last';
    this.num_pages = 0;
    this.filters = {
      top: {},
      table: []
    };
    this.sorter = {};
    this.model = undefined;
    this.pagination = true;

    this.init = function(data) {
      if (angular.isDefined(data.defaultPeriod))
        defaultPeriod = data.defaultPeriod;
      if (angular.isDefined(data.defaultFilter)) {
        defaultFilter = data.defaultFilter;
        this.filters.top = angular.copy(defaultFilter);
      }
      if (angular.isDefined(data.onChange))
        onChangeHandler = data.onChange;
      if (angular.isDefined(data.onSort))
        onSortHandler = data.onSort;
      if (angular.isDefined(data.columnsDef))
        columnsDef = data.columnsDef;
      if (angular.isDefined(data.sort))
        this.sorter = data.sort;
      if (angular.isDefined(data.per_page))
        this.per_page = data.per_page;
      if (angular.isDefined(data.model))
        this.model = data.model;
      if (angular.isDefined(data.scope)) {
        scope = data.scope;
      }
      if (angular.isDefined(data.pagination)) {
        this.pagination = data.pagination;
      }
      if (angular.isDefined(data.page)) {
        this.page = data.page;
      }
      this.useLocation = data.useLocation || false;
    };

    this.setDefaultPeriod = function(period) {
      defaultPeriod = period;
    };

    this.setDefaultFilter = function(filter) {
      defaultFilter = filter;
      this.filters.top = angular.copy(defaultFilter);
    };

    var fireObservers = function(filter) {
      // main change handler, which returns a promise to data
      var promise = onChangeHandler(filter);
      // other observers, such as sums line
      for (var i in filterObservers) {
        filterObservers[i](filter);
      }
      return promise;
    };
    var onSortHandler = fireObservers;

    this.addFilterObserver = function(observer) {
      filterObservers.push(observer);
    };
    this.addSumsObserver = function(observer) {
      sumsObservers.push(observer);
    };
    this.clearFilterObservers = function() {
      filterObservers = [];
    };
    this.clearSumsObservers = function() {
      sumsObservers = [];
    };
    this.emitSumsChange = function(add, subtract) {
      for (var i in sumsObservers) {
        sumsObservers[i](add, subtract);
      }
    };

    this.setSort = function(sort, callHandler) {
      this.sorter = sort;
      callHandler = angular.isDefined(callHandler) ? callHandler : true;
      if (callHandler) {
        onSortHandler(this.getQuery());
      }
    };

    this.cancelFilter = function(emitChange, cancelTable, cancelTop) {
      var callHandler = angular.isDefined(emitChange) ? emitChange : false;
      if (cancelTable) {
        this.filters.table = [];
      }
      if (cancelTop && !isEmpty(this.filters.top)) {
        this.filters.top = _.pick(this.filters.top, _.keys(defaultFilter));
        if (callHandler) {
          this.setLastPage();
          this.refresh();
        }
      }
    };
    this.hasFilter = function() {
      return !isEmpty(this.filters.table) || !isEmpty(_.omit(this.filters.top, _.keys(defaultFilter)));
    };
    this.hasDateFilter = function() {
      var filters = this.filters.table;
      var hasDateFilter = false;

      for (var i in filters) {
        if (!filters[i]) {
          continue;
        }
        if (filters[i].type == 'date') {
          hasDateFilter = true;
        }
      }
      return hasDateFilter;
    };
    this.broadcastRefresh = function(data) {
      if (!scope) {
        return;
      }
      var c = deepcopy(scope[data.route || data]);
      c.refreshing = true;
      scope[data.route || data] = c;
    };
    this.refresh = function(query) {
      var self = this;
      var promise = fireObservers(this.getQuery(query));
      // if (angular.isDefined(scope)) {
        // promise.then(function(data) {
          // $timeout(function() {
          // self.broadcastRefresh(data);
          // });
        // });
      // };
      if (promise && self.page === 'last') {
        promise.then(function(data) {
          $timeout(function() {
            self.page = self.num_pages;
            if (self.useLocation) {
              $location.search(angular.extend($location.search(), {page: self.page}));
            }
          });
        });
      }
      return promise;
    };
    this.filterChanged = function(filter) {
      this.setLastPage();
      this.refresh(filter);
    };
    this.changeColumnsDef = function(_columnsDef) {
      columnsDef = _columnsDef;
    };
    this.getNumPages = function() {
      return this.num_pages;
    };
    this.setLastPage = function(reload) {
      if (!angular.isDefined(reload))
        reload = false;
      this.page = 'last';
      if (reload) {
        this.refresh();
      }
    };
    this.setPerPage = function(_per_page) {
      this.per_page = _per_page;
    };
    this.setPage = function() {
      this.refresh();
    };
    this.getPage = function() {
      if (this.page === 'last')
        this.page = this.num_pages;
      return this.page;
    };
    this.updateLastPage = function(page) {
      if (angular.isDefined(page)) {
        this.num_pages = page;
      }
      // this._page = this.getNumPages();
      this.page = this.getNumPages();
    };
    this.hasMorePages = function() {
      return (this.getNumPages() > 1);
    };
    this.hasNextPage = function() {
      return (this.getPage() < this.getNumPages());
    };
    this.setPreviousPage = function() {
      if (this.page <= 1)
        return;
      if (this.page === 'last')
        this.page = this.num_pages;
      this.page--;
      return this.refresh();
    };
    this.setNextPage = function() {
      if (this.page === 'last')
        this.page = this.num_pages;
      if (this.page >= this.num_pages)
        return;
      this.page++;
      return this.refresh();
    };
    /**
     * Inserts item to the list
     * @param BaseCollectionModel list list where the item should be inserted
     * @param BaseModel item new item
     * @param bool observer if true, then the current page is not altered
     */
    function compare(x, y) {
      if (x === y) {
        return 0;
      }
      return x > y ? 1 : -1;
    };
    this.tableInsert = function(list, item, observer) {
      if (this.page === 'last') {
        this.page = this.num_pages;
      }
      observer = angular.isDefined(observer) ? observer : false;
      var self = this;
      var inserted = false;

      var filterStatus = this.inFilter(item);
      if (filterStatus.refreshFromServer) {
        this.refresh(this.getQuery());
      } else if (isEmpty(list)) {
        return true;
      } else if (filterStatus.result) {
        // get sorted index
        var index = list.length;
        var spl;
        if (!isEmpty(this.sorter)) {
          spl = this.sorter.sort.split(',');
        } else {
          spl = [];
        }
        spl.push('id');
        var sortList = angular.copy(list);
        sortList.push(item);

        sortList.sort(function(x, y) {
          for (var i = 0; i < spl.length; i++) {
            if (x[spl[i]] !== y[spl[i]]) {
              return compare(x[spl[i]], y[spl[i]]);
            }
          }
        });
        index = sortList.indexOf(item);
        if (this.sorter.reverse) {
          index = list.length - index;
        }
        // if it's in the beginning or at the end, we cannot reliably determine if it should be there or not, so we refresh the data .. if's length, then it doesn't need to refresh because it's on the next page
        if (index === 0 && this.page !== 1) {
          this.refresh();
        } else {
          // if the item should be placed after the last item, then we don't have to do anything because it's on the next page, so we just increase the counter
          if (index === list.length && this.page !== this.num_pages) {
            list.count++;
            return;
          }
          // if the item should be on a new page
          if (list.length && (list.length % this.per_page === 0)) {
            var page = this.page;
            list.count++;
            $timeout(function() {
              if (index === list.length) {
                if (!observer) {
                  self.setLastPage();
                  self.page = self.num_pages;
                  list.splice(0, list.length);
                  list.push(item);
                } else {
                  // to stay on the current page, override if it was 'last' page
                  self._page = page;
                  self.page = page;
                }
              } else {
                list.splice(index, 0, item);
                list.splice(self.per_page, 1);
              }
            });
          } else {
            list.count++;
            list.splice(index, 0, item);
          }
          if (!observer) {
            item.created = true;
            $timeout(function() {
              delete item.created;
            }, 5000);
          }
        }
      }
      return inserted;
    };
    /**
     * Removed item from the table
     * If it's on the last page and it was the last item, it deletes the page, otherwise it only refreshes the table
     * @param BaseCollectionModel list data with the item
     * @param BaseModel item item to delete
     */
    this.tableRemove = function(list, item) {
      if (!list)
        return;
      if (!item)
        return;
      if (this.page === 'last')
        this.page = this.num_pages;

      var listItem = _.find(list, {id: item.id});
      if (!listItem) {
        return;
      }
      list.count--;
      list.splice(list.indexOf(listItem), 1);
      if (this.page > 1) {
        if (!list.length && this.page === this.num_pages) {
          this.num_pages--;
          this.setLastPage(true);
        } else if (this.page !== this.num_pages) {
          this.refresh();
        }
      }
    };
    /**
     * Checks whether the record should be included in the filtered view
     * @param data Record
     * @return true/false
     */
    this.inFilter = function(data) {
      var ok = true;
      var hasDateFilter = false;
      var refreshNeeded = false;
      var filters = this.filters.table;
      // returns true if exists and is equal, otherwise false
      var compareObjectProperty = function(prop, obj, value) {
        if (!prop.match('__')) {
          return obj[prop] == value;
        } else {
          var spl = prop.split('__');
          var parent = obj;
          var i;
          for (i = 0; i < spl.length-1; i++) {
            parent = parent[spl[i]+'_data'];
            if (!parent) {
              return false;
            }
          }
          prop = spl[i];
          return parent[prop] === value;
        }
      };
      // check table filters
      for (var i in filters) {
        if (!filters[i])
          continue;
        var field = filters[i].sortField || filters[i].field;
        if (filters[i].type != 'date') {
          switch (filters[i].operation) {
            case 1:
              if (data[field] < filters[i].value)
                ok = false;
              break;
            case 2:
              if (data[field] > filters[i].value)
                ok = false;
              break;
            case 3:
              if (data[field] < filters[i].value || data[field] > filters[i].value2)
                ok = false;
              break;
            default:
              if (String(filters[i].value).match(',') && _.find(String(filters[i].value).split(','), function(obj) { return obj == data[field]; })) {
                continue;
              }
              if (!compareObjectProperty(field, data, filters[i].value))
                ok = false;
              break;
            }
        } else {
          hasDateFilter = true;
          var parsed_filtered_date = filters[i].value.split('-');
          var filtered_date = new Date(parsed_filtered_date[0], parseInt(parsed_filtered_date[1])-1, parsed_filtered_date[2]);
          if (filters[i].value2) {
            var parsed_filtered_date2 = filters[i].value2.split('-');
            var filtered_date2 = new Date(parsed_filtered_date2[0], parseInt(parsed_filtered_date2[1])-1, parsed_filtered_date2[2]);
          } else {
            var filtered_date2 = null;
          }
          var parsed_data_date = data[field].split('-');
          var data_date = new Date(parsed_data_date[0], parseInt(parsed_data_date[1])-1, parsed_data_date[2]);
          switch (filters[i].operation) {
            case 1:
              if ((!filtered_date2 && data_date < filtered_date) || (filtered_date2 && (data_date < filtered_date || data_date > filtered_date2)))
                ok = false;
              break;
            case 2:
              if ((!filtered_date2 && data_date > filtered_date) || (filtered_date2 && (data_date < filtered_date || data_date > filtered_date2)))
                ok = false;
              break;
            case 3:
              if (data_date < filtered_date || data_date > filtered_date2)
                ok = false;
              break;
            default:
              if ((!filtered_date2 && !angular.equals(data_date, filtered_date)) || (filtered_date2 && (data_date < filtered_date || data_date > filtered_date2))) {
                ok = false;
              }
              break;
          }
        }
      }
      // check top filters
      filters = angular.copy(this.filters.top);
      for (var i in filters) {
        if (filters[i] === null) {
          continue;
        }
        if (filters[i] === 'True') {
          filters[i] = true;
        } else if (filters[i] === 'False') {
          filters[i] = false;
        }
        if (!angular.isDefined(data[i])) {
          refreshNeeded = true;
          continue;
        }
        if (String(filters[i]).match(',') && _.find(String(filters[i]).split(','), function(obj) { return obj == data[i]; })) {
          continue;
        }
        if (!compareObjectProperty(i, data, filters[i])) {
          ok = false;
        }
      }
      // defaultFilter is not checked as it is applied in top filters
      // check defaultperiod filter
      if (!hasDateFilter && defaultPeriod) {
        for (var i in defaultPeriod) {
          var field = i;
          var parsed_filtered_date = defaultPeriod[i].split('-');
          var filtered_date = new Date(parsed_filtered_date[0], parsed_filtered_date[1]-1, parsed_filtered_date[2]);
          if (field.match('__gte')) {
            field = field.split('__gte')[0];
            if (!angular.isDefined(data[field])) {
              refreshNeeded = true;
              continue;
            }
            var parsed_data_date = data[field].split('-');
            var data_date = new Date(parsed_data_date[0], parsed_data_date[1]-1, parsed_data_date[2]);
            if (data_date < filtered_date)
              ok = false;
          }
          else if (field.match('__lte')) {
            field = field.split('__lte')[0];
            if (!angular.isDefined(data[field])) {
              refreshNeeded = true;
              continue;
            }
            var parsed_data_date = data[field].split('-');
            var data_date = new Date(parsed_data_date[0], parsed_data_date[1]-1, parsed_data_date[2]);
            if (data_date > filtered_date)
              ok = false;
          }
        }
      }
      if (universalQuery)
        refreshNeeded = true;
      return {result: ok, refreshFromServer: refreshNeeded};
    };
    this.addFilter = function(field, value, operation, emitChange) {
      if (!angular.isDefined(emitChange)) {
        emitChange = true;
      }
      if (!columnsDef)
        throw new Error("Column definition is missing! You have to init the Search Controller with table columns definition in order to add manual filter.");
      var col = _.find(columnsDef, {field: field});
      if (!col) {
        console.log('Nenasli sme col', field, columnsDef);
        return;
      }
      var a = angular.copy(this.filters.table);
      a[columnsDef.indexOf(col)] = {field: col.field, value: value, value2: undefined, operation: operation ? operation : 0, type: col.type};
      this.filters.table = a;
      this.setLastPage();
      if (emitChange) {
        this.refresh();
      }
    };
    this.getOrdering = function() {
      var ordering = this.sorter.sort;
      if (this.sorter.reverse && this.sorter.sort.match(',')) {
        var spl = this.sorter.sort.split(',');
        ordering = '-'+spl[0];
        for (var i = 1; i < spl.length; i++) {
          ordering += ',-'+spl[i];
        }
      } else if (this.sorter.reverse) {
        ordering = '-'+ordering;
      }
      return ordering;
    };
    this.getQuery = function(extendedFilters) {
      var filters = this.filters.table;

      var query = {};
      var hasDateFilter = false;
      var key;

      if (defaultFilter) {
        copyWeakSrc(defaultFilter, query);
      }
      _.extend(query, this.filters.top);

      for (var i in filters) {
        if (!filters[i])
          continue;
        key = filters[i].field;
        if (key.match('.')) {
          key = key.replace(/\./g, '__');
        }
        switch (filters[i].operation) {
          case 1:
            query[key+"__gte"] = filters[i].value;
            break;
          case 2:
            query[key+"__lte"] = filters[i].value;
            break;
          case 3:
            query[key+"__gte"] = filters[i].value;
            query[key+"__lte"] = filters[i].value2;
            break;
          default:
            query[key] = filters[i].value;
            break;
        }
        if (filters[i].type == 'date') {
          hasDateFilter = true;
          if (filters[i].operation != 3 && angular.isDefined(filters[i].value2)) {
            query[key+"__gte"] = filters[i].value;
            query[key+"__lte"] = filters[i].value2;
            delete query[key];
          }
        }
      }
      // do not copy default period filter if there are other date filters
      if (!hasDateFilter) {
        copyWeakSrc(defaultPeriod, query);
      }

      if (universalQuery) {
        var tmp = universalQuery;
        _.extend(tmp, query);
        return tmp;
      }
      if (this.pagination) {
        angular.extend(query, { page: (this.page > 0 || this.page === 'last') ? this.page : undefined, page_size: this.per_page });
      }
      if (!isEmpty(this.sorter)) {
        angular.extend(query, { ordering: this.getOrdering()});
      }

      var self = this;
      _.every(extendedFilters, function(obj) {
        if (obj) {
          angular.forEach(obj.data, function(value, key) {
            self.addFilter(key, value, null, false);
          });
          angular.extend(query, obj.data);
        }
      });

      return query;
    };

    this.performUniversalSearch = function(query) {
      if (query) {
        universalQuery = {search: query};
        this.setLastPage();
        onChangeHandler(universalQuery);
      } else {
        universalQuery = undefined;
        var tmp = this.filters.top;
        this.setLastPage();
        this.refresh();
      }
    };

    this.universalSearch = function(row, columns, query) {
      var q = query || '';
      q = q.toLowerCase();
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field && row[columns[i].field] && String(row[columns[i].field]).toLowerCase().indexOf(q) != -1)
          return true;
      }
      return false;
    };

    this.filterCheckbox = function(field, value, emitChange) {
      if (!angular.isDefined(value) || value === true || value === 'true') {
        value = 'True';
      }
      var callHandler = angular.isDefined(emitChange) ? emitChange : true;
      if (this.filters.top[field]) {
        delete this.filters.top[field];
      } else {
        this.filters.top[field] = value;
      }
      if (callHandler) {
        this.setLastPage();
        this.refresh();
      }
    };

    this.filterSelection = function(field, value, emitChange, trackBy) {
      var callHandler = angular.isDefined(emitChange) ? emitChange : true;
      if (value) {
        if (value[trackBy] !== undefined && value[trackBy] !== null) {
          // pripad id = 0
          if (value[trackBy] === 0) {
            this.filters.top[field] = null;
          } else {
            this.filters.top[field] = value[trackBy];
          }
        } else this.filters.top[field] = value;
      } else {
        delete this.filters.top[field];
      }
      if (callHandler) {
        this.setLastPage();
        this.refresh();
      }
    };

    this.getSelectionValue = function(field) {
      return this.filters.top[field];
    };

  }]);
