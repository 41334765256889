'use strict';

angular.module('casist.admin')
  .controller('PrevadzkaCtrl', ['$scope', '$modalInstance', '$controller', '$location', 'Auth', 'Prevadzka', 'SearchCtrl', 'record', 'prevadzky', 'config',
              function($scope, $modalInstance, $controller, $location, Auth, Prevadzka, SearchCtrl, record, prevadzky, config) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Prevadzka
    });

    if (!angular.isDefined(config)) {
      config = {};
    }
    $scope.config = config;

    var defaultPrevadzka = {
      agenda: SearchCtrl.getQuery().agenda
    }

    $scope.RecordCtrl.main.init({
      endpoint: 'settings/prevadzky',
      model: 'prevadzka',
      master: record,
      list: prevadzky,
      defaultItem: defaultPrevadzka,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });
    $scope.RecordCtrl.disableAdd(config.disableAdd);

    if (!isEmpty(record)) {
      $scope.prevadzka = Prevadzka.copy(record);
      $scope.agenda = SearchCtrl.getQuery().agenda;
    } else {
      $scope.prevadzka = defaultPrevadzka;
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: record});
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close('ok');
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
