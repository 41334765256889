"use strict";

angular.module("casist.core").factory("FirmaPrevadzka", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $translate
  ) {
    Restangular.extendModel("firmaprevadzky", function (model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function () {
          if (!this.firma) {
            this.addError("firma", $translate.instant("errors.FIRMA"));
            return false;
          }
          return BaseModel.validate();
        },
      });
    });
    return angular.extend(BaseCollectionModel.object("firmy/firmaprevadzky"), {
      getPerms: function () {
        return Profile.get("perms").firma;
      },
    });
  },
]);
