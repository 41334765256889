'use strict';

angular.module('casist.ucto')
  .factory('RozuctovaniePolozka', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = "rozuctovanie-polozky";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.cislo) {
            this.addError('cislo', 'Pole číslo je povinné.');
            res = false;
          }
          return res;
        }
      });
    });
    return angular.extend(BaseCollectionModel.object('rozuctovanie/'+ENDPOINT), {
      getPerms: function() {
        return Profile.get('perms').rozuctovanie;
      }
    });
  }]);

angular.module('casist.ucto')
  .factory('RozuctovanieSposob', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'RozuctovaniePolozka', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, RozuctovaniePolozka) {
    var ENDPOINT = "rozuctovanie-sposoby";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.sposob) {
            this.addError('sposob', 'Pole je povinné.');
            res = false;
          }
          return res;
        },
        polozkyProvider: function() {
          return RozuctovaniePolozka.parent(this);
        },
      });
    });
    return angular.extend(BaseCollectionModel.object('rozuctovanie/'+ENDPOINT), {
      getPerms: function() {
        return Profile.get('perms').rozuctovanie;
      }
    });
  }]);

angular.module('casist.ucto')
  .factory('Rozuctovanie', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    var ENDPOINT = "rozuctovanie";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });
    return angular.extend(BaseCollectionModel.object(ENDPOINT), {
      getPerms: function() {
        return Profile.get('perms').rozuctovanie;
      }
    });
  }]);