'use strict';

angular.module('casist.widgets')
.directive('casistDateInput', ['$compile', 'dateFilter', '$translate', '$document', '$timeout', '$position', function ($compile, dateFilter, $translate, $document, $timeout, $position) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        calendarOpened: '=',
        daySelectorValue: '=showDaySelector',
        onBlur: '&'
      },
      link: function postLink(scope, inputElement, attrs, ngModel) {
        if (!ngModel)
            return;

          var daySelectorRef = undefined;
          scope.daySelectorStyle = {};
          scope.switchActiveField = function(focused) {
            scope.daySelectorStyle = {
              'z-index': focused ? 10 : 0
            };
          };

          var VIEWFORMAT = 'dd.MM.yyyy';
          var DBFORMAT = 'yyyy-MM-dd';
          scope.calendarDate = new Date();

          if (angular.isDefined(scope.daySelectorValue)) {
            var tabindex = '';
            if (angular.isDefined(inputElement[0].tabIndex)) {
              tabindex = ' tabindex="'+inputElement[0].tabIndex+'"';
            }
            var daySelectorElement = angular.element('<span style="padding: 0 0"><input type="text" class="form-control input-sm" ng-change="daySelected($event)" ng-model="daySelector" style="text-align: center; width: 30px; margin-right: -1px;" ng-style="daySelectorStyle" ng-focus="switchActiveField(true)" ng-blur="switchActiveField(false)"'+tabindex+'></span>');
            if (angular.isDefined(attrs.daySelectorClass))
              daySelectorElement.addClass(attrs.daySelectorClass);
            var $daySelector = $compile(daySelectorElement)(scope);
            inputElement.before($daySelector);
          }
          var $popup = null;

          var calendarScope = null;
          scope.$watch('calendarOpened', function(newValue) {
            if (newValue) {
              calendarScope = scope.$new();
              $popup = $compile(angular.element('<div class="calendarWidget search-input well well-sm" ng-show="_calendarOpened"><div ng-model="calendarDate" ng-change="calendarSelection()" style="margin-left: auto; margin-right: auto;" datepicker show-weeks="false" starting-day="1" class="search-input"></div></div>'))(calendarScope);
              $document.find('body').append($popup);
              $popup.bind('click', elemClick);
              $timeout(function() {
                $document.bind('click', closeCalendar);
                var pos = $position.offset(inputElement);
                pos.top += 30;
                pos.top += 'px';
                pos.left += 'px';
                delete pos.width;
                delete pos.height;

                $popup.css(pos);
                scope._calendarOpened = newValue;
              });
            } else {
              scope._calendarOpened = newValue;
              closeCalendar();
              if ($popup) {
                $popup.unbind('click', elemClick);
                $popup.remove();
                $popup = null;
                calendarScope.$destroy();
              }
            }
          });

          scope.calendarSelection = function() {
            parseDate(null, calendarScope.calendarDate);
            scope.calendarOpened = false;
            if (angular.isDefined(scope.onBlur)) {
              $timeout(function() {
                scope.onBlur();
              });
            }
          }
          scope.$watch('daySelectorValue', function (value) {
            if (value) {
              var date = new Date(value);
              if (isNaN(date)) {
                var parsedRef = value.split('-');
                date = new Date(parsedRef[0], parsedRef[1]-1, parsedRef[2]);
              }
              // odpamatame si stary reference date a updatneme prepojeny datum (splatnosti napr.) iba ked bol prazdny - cize isiel z parsera v datume vystavenia.
              // ak nebol prazdny, tak sa to menilo priamo z formattera, ked sa menil cely model
              var oldRef = daySelectorRef;
              daySelectorRef = date;
              var parsed = undefined;
              if (ngModel.$modelValue && daySelectorRef) {
                parsed = ngModel.$modelValue.split('-');
              }
              if (!scope.daySelector) {
                if (parsed) {
                  scope.daySelector = Math.ceil((new Date(parsed[0], parsed[1]-1, parsed[2]) - daySelectorRef) / 3600000 / 24);
                } else scope.daySelector = '';
              } else {
                if (!oldRef) {
                  scope.daySelected();
                } else {
                  if (parsed) {
                    scope.daySelector = Math.ceil((new Date(parsed[0], parsed[1]-1, parsed[2]) - daySelectorRef) / 3600000 / 24);
                  } else {
                    scope.daySelector = '';
                  }
                }
              }
            } else {
              daySelectorRef = undefined;
            }
          });

          // fix for situations when enter submits the form immediately - we need to parse the date first (enter, page up, page down)
          var keyHandler = function(evt) {
            if (evt.which === 13 || evt.which === 33 || evt.which === 34) {
              scope.$apply(parseDate());
            }
          }
          inputElement.bind('keydown', keyHandler);

          ngModel.$parsers.unshift(function(viewValue) {
            if (viewValue && angular.isDate(viewValue)) {
              return dateFilter(viewValue, DBFORMAT);
            }
            if (!viewValue) {
              viewValue = null;
            }
            return viewValue;
          });
          ngModel.$formatters.unshift(function (modelValue) {
            if (!modelValue) {
              scope.daySelector = undefined;
              return modelValue;
            }

            if (String(new Date(modelValue)) == modelValue) {
              ngModel.$setViewValue(modelValue);
            }
            if (String(modelValue).match('-')) {
              var parsed = modelValue.split('-');
              modelValue = new Date(parsed[0], parsed[1]-1, parsed[2]);
            }
            if (daySelectorRef) {
              scope.daySelector = undefined;
              $timeout(function() {
                scope.daySelector = Math.ceil((modelValue - daySelectorRef) / 3600000 / 24);
              });
            }

            scope.calendarDate = modelValue;
            return dateFilter(modelValue, VIEWFORMAT);
          });

          var parseDate = function() {
            var d = new Date();
            if (arguments[1])
              d = arguments[1];
            else {
              var datumstr = inputElement.val();
              if (datumstr == "") {
                if (!scope.calendarOpened) {
                  ngModel.$setViewValue(null);
                  scope.daySelector = '';
                }
                return;
              }

              if (datumstr.match(','))
                datumstr = datumstr.replace(new RegExp(',', 'g'), '.');
              var ex = datumstr.split(".");

              var day = parseInt(ex[0]);
              var month = parseInt(ex[1]);
              var year = parseInt(ex[2]);

              if (!day)
                day = d.getDate();
              if (!month)
                month = d.getMonth()+1;
              if (year && (String(ex[2]).length === 2 || String(ex[2]).length === 1)) {
                year = new String(d.getFullYear()).substring (0, parseInt (4 - String(ex[2]).length)) + String(ex[2]);
              } else if (!year || year.length < 4)
                year = d.getFullYear();


              d = new Date(year, month-1, day);
            }
            if (isNaN(d)) {
              alert ($translate("errors.WRONG_DATE"));
              inputElement.val('');
              scope.daySelector = '';
              return;
            }

            ngModel.$setViewValue(d);
            inputElement.val(dateFilter(d, VIEWFORMAT));
            scope.daySelector = Math.ceil((d - daySelectorRef) / 3600000 / 24);
            scope.calendarDate = d;
          }

          scope.daySelected = function() {
            var value = scope.daySelector;
            if (value) {
              var d = new Date(angular.copy(daySelectorRef).setDate(daySelectorRef.getDate()+parseInt(value)));
              ngModel.$setViewValue(d);
              scope.calendarDate = d;
              inputElement.val(dateFilter(d, VIEWFORMAT));
            }
          }

          var elemClick = function(e) {
            e.stopPropagation();
          }
          inputElement.bind('blur', function() {
            parseDate();
            scope.$apply();
            if (angular.isDefined(scope.onBlur)) {
              scope.$apply(function() {
                scope.onBlur();
              });
            }
          });

          inputElement.bind('click', elemClick);

          scope.openCal = function($event) {
            if ($event) {
              $event.preventDefault();
              $event.stopPropagation();
            }

            scope.calendarOpened = !scope.calendarOpened;
          }
          var closeCalendar = function(event) {
            if (scope.calendarOpened) {
              scope.$apply(function() {
                scope.calendarOpened = false;
              });
            }
            $document.unbind('click', closeCalendar);
          };

          scope.$on('$destroy', function() {
            if ($popup) {
              $popup.unbind('click', elemClick);
              $popup.remove();
            }
            inputElement.unbind('click', elemClick);
            $document.unbind('click', closeCalendar);
            inputElement.unbind('keydown', keyHandler);
          });
      }
    };
  }])
  .directive('casistDate', ['$compile', 'dateFilter', '$translate', '$document', '$timeout', function ($compile, dateFilter, $translate, $document, $timeout) {
    return {
      restrict: 'EA',
      require: 'ngModel',
      replace: true,
      templateUrl: 'components/widgets/casist-date.html',
      scope: {
        containerStyle: '=style',
        showDaySelector: '=showDaySelector',
        errorMessages: '=errorModel',
        ngModel: '=',
        ngBlur: '&'
      },
      link: {
        post: function postLink(scope, element, attrs, ngModel) {
          if (!ngModel)
            return;

          if (angular.isDefined(attrs.ngDisabled)) {
            scope.$parent.$watch('ngDisabled', function(val) {
              scope.ngDisabled = val;
            });
          }
          scope.showCalendar = angular.isDefined(attrs.showCalendar) ? scope.$eval(attrs.showCalendar) : true;

          var inputElement = angular.element(element.find('input')[angular.isDefined(scope.showDaySelector) ? 1 : 0]);

          if (!inputElement)
            return;

          if (angular.isDefined(attrs.name)) {
            inputElement.attr('name', attrs.name);
            element.attr('name', null);
          }
          if (angular.isDefined(attrs.id)) {
            inputElement.attr('id', attrs.id);
            element.attr('id', null);
          }
          if (angular.isDefined(attrs.autocomplete)) {
            inputElement.attr('autocomplete', attrs.autocomplete);
            element.attr('autocomplete', null);
          }
          if (angular.isDefined(attrs.placeholder)) {
            inputElement.attr('placeholder', attrs.placeholder);
            element.attr('placeholder', null);
          }
          if (angular.isDefined(attrs.requestFocus)) {
            inputElement.attr('request-focus', attrs.requestFocus);
            element.attr('request-focus', null);
          }
          if (angular.isDefined(attrs.tabindex)) {
            inputElement.attr('tabindex', attrs.tabindex);
            if (angular.isDefined(scope.showDaySelector)) {
              angular.element(element.find('input')[0]).attr('tabindex', attrs.tabindex);
            }
            element.attr('tabindex', null);
          }
          if (angular.isDefined(attrs.class)) {
            inputElement.addClass(attrs.class);
          }

          scope.calendar = {
            opened: false
          };

          scope.toggleCal = function() {
            scope.calendar.opened = !scope.calendar.opened;
          }

          attrs.$observe('requestFocus', function(val) {
            if (val) {
              $timeout(function() {
                if (inputElement.val())
                  inputElement.select();
                else inputElement.focus();
              });
            }
          });
        }
      }
    };
  }]);
