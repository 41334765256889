import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { Phone } from './proof-of-concept-service';
import './app.module.ajs';

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
  ],
  providers: [
    Phone,
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap() {
    this.upgrade.bootstrap(document.documentElement, ['casist']);
  }
}
