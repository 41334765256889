'use strict';

angular.module('casist.widgets')
  .directive('versionViewer', ['Restangular', '$timeout', function (Restangular, $timeout) {
    return {
      templateUrl: 'components/widgets/version-viewer.html',
      restrict: 'EA',
      replace: true,
      scope: {
        lookup: '=',
        model: '=',
        versionChanged: '&',
        tooltipPlacement: '@',
        customStyle: "=style"
      },
      link: function postLink(scope, element, attrs) {
        scope.versions = [];
        var objectName = "";

        attrs.$observe('versionViewer', function (val) {
          objectName = val;
        });
        attrs.$observe('tooltipPlacement', function (val) {
          if (!val)
            scope.tooltipPlacement = "right";
        });
        scope.buttonClass = "";
        attrs.$observe('buttonClass', function (val) {
          if (val) {
            var s = val.split(' ');
            for (var i = 0; i < s.length; i++)
              scope.buttonClass += " "+s[i];
          }
        });

        // watch changes of parent record (for record change through navigation)
        scope.$watch("lookup", function (val) {
          scope.currentVersion = 0;
          scope.currentPage = 0;
        });

        scope.pageSize = 5;

        scope.showHistory = function() {
          if (!objectName)
            return;

          scope.versions = [];
          if (!scope.currentVersion) {
            scope.currentVersion = 0;
            scope.currentPage = 0;
          } else scope.currentPage = Math.floor(scope.currentVersion / scope.pageSize);
          Restangular.one(objectName, scope.lookup).all('history').getList().then(function (versions) {
            for (var i = 0; i < versions.length; i++) {
              try {
                var obj = JSON.parse(versions[i].record);
                versions[i].record = obj;
                versions[i].user = versions[i].user;
              } catch (e) {
                // version data corrupt, skip
              }
            }
            scope.versions = versions;
          });
        }
        scope.numberOfPages = function() {
          return Math.ceil(scope.versions.length / scope.pageSize)-1;
        }

        scope.loadVersion = function (e, version) {
          e.preventDefault();
          e.stopPropagation();

          scope.currentVersion = scope.versions.indexOf(version);
          angular.forEach(version.record, function(value, key) {
            scope.model[key] = value;
          });

          if (angular.isDefined(scope.versionChanged))
            scope.versionChanged({version: version, data: scope.model, version_id: scope.currentVersion});
        }

        scope.showHistoryNext = function (event) {
          event.preventDefault();
          event.stopPropagation();

          if (scope.currentPage < scope.numberOfPages())
            scope.currentPage++;
        }
        scope.showHistoryPrevious = function (event) {
          event.preventDefault();
          event.stopPropagation();

          if (scope.currentPage > 0)
            scope.currentPage--;
        }

        scope.$on('$destroy', function() {
          scope.versions = [];
          scope.currentVersion = 0;
        });
      }
    };
  }]);
