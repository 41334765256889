'use strict';

angular.module('casist.sklad')
  .factory('Hlavicka', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Dialog', 'Global', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog, Global) {
    Restangular.extendModel('hlavicky', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.text) {
            this.addError('text', 'Text nemôže byť prázdny!');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('faktury/hlavicky');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').faktura;
      },
      edit: function (hlavicka) {
        var wasDefault = hlavicka.default;
        var deferred = $q.defer();
        var promise = Dialog.prompt('Text hlavičky', 'Upraviť hlavičku', {userinput: hlavicka.text, default: hlavicka.default}, {type: 'textarea', rows: 8}, 'sklad/fakturacia/fakturacia-hlavicka-detail.html').then(function(userData) {
          hlavicka.text = userData.userinput;
          hlavicka.default = userData.default;
          if (!hlavicka.validate()) {
            Dialog.alert(hlavicka.errors().data.text[0], 'Chyba');
            return;
          }
          hlavicka.update().then(function(data) {
            if (!data.typ) {
              if (data.default) {
                Global.set('faktura_hlavicka', data.text);
              } else if (wasDefault) {
                Global.set('faktura_hlavicka', null);
              }
            }
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(hlavicka, attrs) {
        return parent.create(angular.extend({text: hlavicka}, attrs));
      },
      remove: function (hlavicka) {
        return hlavicka.remove();
      },
      get: function (query, otherParams) {
        return parent.objects().getList(angular.extend({search:query}, otherParams));
      }
    });
  }]);