"use strict";

angular.module("casist.ucto").controller("PokladnicnyDokladDetailCtrl", [
  "$scope",
  "$modalInstance",
  "doklad",
  "list",
  "config",
  "$timeout",
  "$modal",
  "Global",
  "$http",
  "$controller",
  "Profile",
  "SearchCtrl",
  "PokladnicnyDoklad",
  "Doklad",
  "Prevadzka",
  "Firma",
  "TypDPH",
  "Predkontacia",
  "$q",
  "Pohyb",
  "DokladTypy",
  "Pokladna",
  "Ucet",
  "Mena",
  "PenaznyDennik",
  "Karta",
  "Outputs",
  "Notification",
  function (
    $scope,
    $modalInstance,
    doklad,
    list,
    config,
    $timeout,
    $modal,
    Global,
    $http,
    $controller,
    Profile,
    SearchCtrl,
    PokladnicnyDoklad,
    Doklad,
    Prevadzka,
    Firma,
    TypDPH,
    Predkontacia,
    $q,
    Pohyb,
    DokladTypy,
    Pokladna,
    Ucet,
    Mena,
    PenaznyDennik,
    Karta,
    Outputs,
    Notification
  ) {
    $scope.hasList =
      (angular.isFunction(list) ? list().length : list.length) !== 0;
    $scope.Karta = Karta;
    $scope.jednoducheUctoClass = Profile.get("jednoducheUcto")
      ? "jednoduche-ucto"
      : "";

    $scope.endpoint = Profile.get("jednoducheUcto")
      ? "penaznydennik"
      : "pokladna";
    $scope.dokladNotRP = function () {
      var pokladna = _.find($scope.pokladne, {
        id: parseInt($scope.doklad.pokladna),
      });
      return !isEmpty(doklad)
        ? doklad.dd != DokladTypy.RP.dd
        : pokladna && pokladna.typ === 2
        ? false
        : true;
    };

    $scope.parovanieJU = function () {
      return Profile.get("jednoducheUcto") && $scope.doklad.faktura_field;
    };

    $scope.firmaLabel = "pokladna.VYPLATENE_KOMU";
    $scope.displayOperacia = function () {
      if (!$scope.doklad.pokladna || !$scope.pokladne.length) {
        return false;
      }
      var pokladna = _.find($scope.pokladne, {
        id: parseInt($scope.doklad.pokladna),
      });
      if (!pokladna) {
        return false;
      }
      if (
        (pokladna.cislo_prijem && pokladna.cislo_vydaj) ||
        (!pokladna.cislo_prijem && !pokladna.cislo_vydaj)
      ) {
        return true;
      } else {
        return false;
      }
    };
    $scope.toggleOperacia = function (operacia) {
      var cenaFieldOld = $scope.doklad.getCenaFields().cena;
      var mnozstvoFieldOld = $scope.doklad.getCenaFields().mnozstvo;
      $scope.doklad.operacia = operacia || $scope.doklad.operacia * -1;
      if (!angular.isDefined(operacia)) {
        $timeout(function () {
          $("#spolu").focus();
        });
      }
      var cenaField = $scope.doklad.getCenaFields().cena;
      var mnozstvoField = $scope.doklad.getCenaFields().mnozstvo;
      if (cenaField !== cenaFieldOld) {
        for (var i = 0; i < $scope.pohyb.length; i++) {
          $scope.pohyb[i][cenaField] = $scope.pohyb[i][cenaFieldOld];
          $scope.pohyb[i][mnozstvoField] = $scope.pohyb[i][mnozstvoFieldOld];
          delete $scope.pohyb[i][cenaFieldOld];
          delete $scope.pohyb[i][mnozstvoFieldOld];
        }
      }
    };
    $scope.getParovanie = function (query, pageQuery) {
      if (/^(VF|DF)[0-9]+$/.test(query)) {
        query = query.replace("VF", "").replace("DF", "");
      }
      var original = $scope.doklad.id
        ? _.find(angular.isFunction(list) ? list() : list, {
            id: parseInt($scope.doklad.id),
          })
        : undefined;
      return PokladnicnyDoklad.parovanie().getList(
        angular.extend(
          {
            firma: $scope.doklad.firma,
            search: query,
            include: original ? original.faktura : undefined,
          },
          pageQuery
        )
      );
    };
    $scope.clearParovanie = function () {
      $scope.doklad.faktura_field = undefined;
    };
    $scope.selectedParovanie = function (parovanie) {
      $scope.doklad.spolu =
        parseFloat(parovanie.spolu) - parseFloat(parovanie.uhradene);
      var fields = ["firma", "vs", "firma_data"];
      if (Profile.get("jednoducheUcto")) {
        fields = _.union(fields, [
          "zaklad_d1",
          "d1",
          "zaklad_d2",
          "d2",
          "zaklad_d0",
          "suma1",
          "suma2",
          "suma3",
          "suma4",
          "suma5",
        ]);
      }
      copyWeakSrc(_.pick(parovanie, fields), $scope.doklad);
      if (
        parovanie.dd === DokladTypy.VF.dd ||
        parovanie.dd === DokladTypy.OP.dd
      ) {
        $scope.toggleOperacia(1);
      } else {
        $scope.toggleOperacia(-1);
      }
      if (Profile.get("jednoducheUcto")) {
        if (parovanie.predkontacia) {
          $scope.doklad.predkontacia = parovanie.predkontacia;
          $scope.doklad.predkontacia_data = parovanie.predkontacia_data;
          $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
        } else if (parovanie.uctovanie) {
          var fields = Profile.get("jednoducheUcto")
            ? PenaznyDennik.getUctoFields()
            : PokladnicnyDoklad.getUctoFields();
          var jeUctovanie = false;
          for (var i = 0; i < fields.length; i++) {
            if (
              parovanie.uctovanie[fields[i] + "_md"] ||
              parovanie.uctovanie[fields[i] + "_d"]
            ) {
              jeUctovanie = true;
              break;
            }
          }
          if (jeUctovanie) {
            if (!$scope.doklad.uctovanie) {
              $scope.doklad.uctovanie = {};
            }
            for (var i = 0; i < fields.length; i++) {
              $scope.doklad.uctovanie[fields[i] + "_md"] =
                parovanie.uctovanie[fields[i] + "_md"];
              $scope.doklad.uctovanie[fields[i] + "_d"] =
                parovanie.uctovanie[fields[i] + "_d"];
              $scope.doklad.uctovanie[fields[i] + "_popis"] =
                parovanie.uctovanie[fields[i] + "_popis"];
            }
          } else if (
            $scope.doklad.operacia === 1 &&
            Global.get("predkontacia_vf")
          ) {
            $scope.doklad.predkontacia = Global.get("predkontacia_vf").id;
            $scope.doklad.predkontacia_data = Global.get(
              "predkontacia_vf_data"
            );
            $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
          }
        }
      } else {
        if (
          parovanie.predkontacia &&
          parovanie.predkontacia_data.predkontacia_pd
        ) {
          $scope.doklad.predkontacia =
            parovanie.predkontacia_data.predkontacia_pd.id;
          $scope.doklad.predkontacia_data =
            parovanie.predkontacia_data.predkontacia_pd_data;
          $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
        }
      }
      $timeout(function () {
        $("#d_zdp").select();
      });
    };
    $scope.editParovaciDoklad = function (doklad) {
      Doklad.editRecord(doklad).then(function (data) {
        $scope.selectedParovanie(data);
      });
    };
    $scope.showPokladne = function () {
      Pokladna.editPokladne($scope.pokladneAll).then(function (data) {
        $scope.pokladne = _.filter(data, { active: true });
        $scope.pokladneAll = data;
        Profile.set("pokladne", $scope.pokladneAll);
        Profile.save();
        var pokladna;
        if (
          !(pokladna = _.find($scope.pokladne, {
            id: parseInt($scope.doklad.pokladna),
          }))
        ) {
          if ($scope.pokladne.length) {
            $scope.doklad.pokladna = $scope.pokladne[0].id;
          } else {
            $scope.doklad.pokladna = undefined;
          }
        } else {
          $scope.doklad.pokladna = pokladna.id;
        }
      });
    };

    if (config.predkontacie) {
      $scope.Predkontacia = Predkontacia;
      $scope.getPredkontacie = function (query) {
        return Predkontacia.get(
          query,
          DokladTypy.PD.dd,
          $scope.doklad.operacia + ",null"
        );
      };
      $scope.addPredkontacia = function (nazov) {
        return Predkontacia.add(
          nazov,
          DokladTypy.PD.dd,
          $scope.doklad.uctovanie,
          $scope.doklad.operacia,
          $scope.doklad.popis
        );
      };
    }
    if (config.ucty) {
      var uctyQueries = [];
      Ucet.get().then(function (ucty) {
        $scope.ucty = ucty;

        $scope.getUcty = function (query, page, parent) {
          if (query === null) {
            query = undefined;
          }
          if (query) {
            query = query.replace(
              /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
              "\\$&"
            );
          }
          var re = new RegExp(query, "i");
          var matches = [];
          for (var i = 0; i < $scope.ucty.length; i++) {
            if (
              (!$scope.ucty[i].operacia ||
                $scope.ucty[i].operacia === $scope.doklad.operacia) &&
              (!$scope.ucty[i].only_md ||
                ($scope.ucty[i].only_md && parent.type === "md")) &&
              (!$scope.ucty[i].only_d ||
                ($scope.ucty[i].only_d && parent.type === "d")) &&
              ($scope.ucty[i].ucet.match(query) ||
                $scope.ucty[i].nazov.match(re))
            ) {
              matches.push(angular.copy($scope.ucty[i]));
            }
          }
          return matches;
        };
        var result;
        for (var i = 0; i < uctyQueries.length; i++) {
          result = $scope.getUcty(
            uctyQueries[i].query,
            uctyQueries[i].page,
            uctyQueries[i].parent
          );
          uctyQueries[i].deferred.resolve(result);
        }
      });
      $scope.getUcty = function (query, page, parent) {
        var deferred = $q.defer();
        uctyQueries.push({
          query: query,
          page: page,
          parent: parent,
          deferred: deferred,
        });
        return deferred.promise;
      };

      $scope.addUcet = function (ucet) {
        var promise = Ucet.add(
          ucet,
          Profile.get("jednoducheUcto")
            ? { only_d: true, operacia: $scope.doklad.operacia }
            : undefined
        );
        promise.then(function (data) {
          $scope.ucty.push(angular.copy(data));
        });
        return promise;
      };
    }
    $scope.Mena = Mena;
    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Profile.get("jednoducheUcto") ? PenaznyDennik : PokladnicnyDoklad,
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      pokladnaPohyb: _.find(Profile.get("layouts"), {
        layout_id: "pokladna_pohyb",
      }),
      pokladnaRozuct: _.find(Profile.get("layouts"), {
        layout_id: "pokladna_rozuct",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.pokladnaPohyb)
        ? layouts.pokladnaPohyb.value
        : true,
      false,
      angular.isDefined(layouts.pokladnaRozuct)
        ? layouts.pokladnaRozuct.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "pokladna_" + (panel === 0 ? "pohyb" : "rozuct");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function () {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.pokladnaRozuct.value) {
        $scope.panels[2] = layouts.pokladnaRozuct.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31),
      typDPHMaxPlatnost = new Date(1970, 1, 1);
    var typDPHPromise = undefined;
    var updateTypDPH = function (datum) {
      var oldTyp = angular.copy($scope.doklad.typ_dph);
      typDPHPromise = TypDPH.getValid(datum, "0,1,2", "typ");
      typDPHPromise.then(function (data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        if (_.find($scope.typyDPH, { id: oldTyp })) {
          $scope.doklad.typ_dph = oldTyp;
        } else if ($scope.doklad.typ_dph !== null) {
          if ($scope.doklad.d1 || $scope.doklad.d2) {
            $scope.doklad.typ_dph = undefined;
            pickDefaultTypDPH(
              $scope.doklad.d1 ? $scope.doklad.d1 : $scope.doklad.d2
            );
          } else {
            $scope.doklad.typ_dph = undefined;
          }
        }
      });
      return typDPHPromise;
    };
    $scope.help = {};
    $scope.helpClosed = function () {
      delete $scope.help.typdph;
    };
    $scope.displayHelpTypDPH = function () {
      var el = '<table class="table table-condensed">';
      for (var i = 0; i < $scope.typyDPH.length; i++) {
        el +=
          '<tr><td width="70">' +
          $scope.typyDPH[i].typ +
          "</td><td>" +
          $scope.typyDPH[i].popis +
          "</td></tr>";
      }
      el += "</table>";
      $scope.help.typdph = el;
    };
    $scope.setUplatDPH = function () {
      $scope.doklad.d_uctn = $scope.doklad.d_zdp;
      $scope.doklad.uplat_dph = $scope.doklad.d_zdp;
    };
    $scope.doplnSumyZM = function (evt) {
      $scope.kurzChanged();
      if (!parseFloat($scope.doklad.kurz)) {
        return;
      }
      if (evt.target.id === "spolu_zm") {
        $scope.doklad.spolu =
          Math.round(
            (parseFloat($scope.doklad.spolu_zm) /
              parseFloat($scope.doklad.kurz)) *
              100
          ) / 100.0;
      } else {
        $scope.doklad.spolu_zm =
          Math.round(
            parseFloat($scope.doklad.spolu) *
              parseFloat($scope.doklad.kurz) *
              100
          ) / 100.0;
      }
    };
    $scope.selectedPredkontacia = function (predkontacia) {
      var fields = Profile.get("jednoducheUcto")
        ? PenaznyDennik.getUctoFields()
        : PokladnicnyDoklad.getUctoFields();
      $scope.doklad.uctovanie = {};
      var foundAllUcty = true;
      for (var i = 0; i < fields.length; i++) {
        if (
          predkontacia[fields[i] + "_md"] &&
          !_.find($scope.ucty, { id: predkontacia[fields[i] + "_md"] })
        ) {
          foundAllUcty = false;
        }
        if (
          predkontacia[fields[i] + "_d"] &&
          !_.find($scope.ucty, { id: predkontacia[fields[i] + "_d"] })
        ) {
          foundAllUcty = false;
        }
      }
      var prekopirovatUctovanie = function () {
        for (var i = 0; i < fields.length; i++) {
          $scope.doklad.uctovanie[fields[i] + "_md"] =
            predkontacia[fields[i] + "_md"];
          $scope.doklad.uctovanie[fields[i] + "_d"] =
            predkontacia[fields[i] + "_d"];
          $scope.doklad.uctovanie[fields[i] + "_popis"] =
            predkontacia[fields[i] + "_popis"];
        }
      };
      // aktualizovat ucty v pamati, ak tam nejaky z predkontacie nepozname
      if (!foundAllUcty) {
        Ucet.get().then(function (ucty) {
          $scope.ucty = ucty;
          prekopirovatUctovanie();
        });
      } else {
        prekopirovatUctovanie();
      }
      if (!$scope.doklad.popis) {
        $scope.doklad.popis = predkontacia.popis;
      }
      if (predkontacia.typ_dph) {
        $scope.doklad.typ_dph = predkontacia.typ_dph;
      }
      if (predkontacia.operacia) {
        $scope.toggleOperacia(predkontacia.operacia);
      }
    };

    $scope.skladChanged = function (item, parent) {
      if (!parent) {
        parent = $scope.newItem;
      }
      if (item) {
        parent.sklad = parseInt(item);
      }
      parent.sklad_data = _.find($scope.currentPrevadzka.sklady, {
        id: parent.sklad,
      });
    };

    $scope.prevadzky = Prevadzka.restangularize(
      angular.copy(Profile.get("prevadzky"))
    );
    $scope.pokladneAll = Pokladna.restangularize(
      angular.copy(Profile.get("pokladne"))
    );
    $scope.pokladne = _.filter(
      Pokladna.restangularize(angular.copy(Profile.get("pokladne"))),
      { active: true }
    );

    var defaultPokladnicnyDoklad = {
      dd: DokladTypy.PD.dd,
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      d_uctn: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get("lastPrevadzka").id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      mena: null,
      sd1: Global.get("sd1"),
      sd2: Global.get("sd2"),
      rozuctovanie: [],
      operacia: 1,
      pokladna: $scope.pokladne.length ? $scope.pokladne[0].id : undefined,
    };
    if (
      Profile.get("lastPokladna") &&
      _.find($scope.pokladne, { id: Profile.get("lastPokladna").id })
    ) {
      defaultPokladnicnyDoklad.pokladna = Profile.get("lastPokladna").id;
    }
    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.pohybColumns = [
      { width: 30 },
      { field: "kod", label: "pohyb.KOD", width: 120 },
      { field: "nazov", label: "pohyb.NAZOV" },
      { field: "sklad", label: "pohyb.SKLAD" },
      { field: "mp", label: "pohyb.MNOZSTVO", width: 90 },
      {
        field: "cena_prijem",
        label: "pohyb.CENAMJ",
        width: 105,
        currencyAddon: "doklad",
      },
      { field: "zlava", label: "fakturacia.ZLAVAP", width: 65 },
      { field: "mj", label: "pohyb.MJ", width: 50 },
      { field: "dph", label: "pohyb.DPH", width: 60 },
      { field: "poznamka", label: "fakturacia.POZNAMKA", width: 150 },
      { width: 60 },
    ];
    if (!Profile.get("platcadph")) {
      var omitted = ["dph"];
      $scope.pohybColumns = _.reject($scope.pohybColumns, function (obj) {
        return omitted.indexOf(obj.field) !== -1;
      });
    }
    $scope.pohyb = [];

    if (!isEmpty(doklad)) {
      $scope.doklad = PokladnicnyDoklad.copy(doklad);
      _.defaults($scope.doklad, defaultPokladnicnyDoklad);
      $scope.prevadzky = _.filter($scope.prevadzky, {
        id: $scope.doklad.prevadzka,
      });
      if ($scope.prevadzky.length)
        $scope.currentPrevadzka = $scope.prevadzky[0];
      if (!doklad.pohyb || $scope.doklad.id) {
        $scope.doklad.getList("pohyb").then(function (pohyb) {
          $scope.RecordCtrl.related.paginateList(pohyb, 20, $scope.sorter);

          $timeout(function () {
            $scope.recountSums();
          });
        });
      } else {
        $timeout(function () {
          for (var i = 0; i < doklad.pohyb.length; i++) {
            $scope.RecordCtrl.related.add(doklad.pohyb[i]);
          }
          delete doklad.pohyb;
        });
      }
    } else {
      $scope.doklad = PokladnicnyDoklad.restangularize(
        angular.copy(defaultPokladnicnyDoklad)
      );
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.doklad.prevadzka,
      });
      $timeout(function () {
        $scope.RecordCtrl.related.paginateList([], 20, $scope.sorter);
      });
    }

    $scope.newItem = Pohyb.restangularize({
      dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
    });
    $scope.skladChanged(
      $scope.currentPrevadzka.sklady.length
        ? $scope.currentPrevadzka.sklady[0].id
        : undefined
    );
    var defaultPohybItem = angular.copy($scope.newItem);

    var pohybPreSave = function (item, create) {
      if (!item.karta_data) {
        item.kod = null;
        if (item.karta) {
          item.nazov = item.karta;
          delete item.karta;
        }
      }
      if (angular.isDefined(item.cena_edit)) {
        if ($scope.doklad.mena && $scope.doklad.kurz) {
          item.prepocitatKurzom($scope.doklad);
        } else {
          item[$scope.doklad.getCenaFields().cena] = item.cena_edit;
          item.cena_zm = 0;
        }
        item[$scope.doklad.getCenaFields().mnozstvo] = item.mnozstvo;
        delete item.mnozstvo;
        delete item.cena_edit;
      }
      $scope.skladChanged(item.sklad, item);

      return item;
    };
    var pohybPostSave = function (item, created) {
      $("#pohyb_kod").focus();
      $scope.recountSums();
      angular.extend($scope.newItem, {
        dph: Profile.get("platcadph") ? Global.get("sd1") : 0,
      });
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
      Pohyb.updateSkladLabelsWithQty($scope);
      if (document.getElementById("pohyb_kod")) {
        document.getElementById("pohyb_kod").scrollIntoView();
      }
      return item;
    };
    var pohybPreEdit = function (item) {
      if ($scope.doklad.primarna_zm) item.cena_edit = item.cena_zm;
      else item.cena_edit = item[$scope.doklad.getCenaFields().cena];
      item.mnozstvo = item[$scope.doklad.getCenaFields().mnozstvo];
      return item;
    };
    $scope.recountSums = function () {
      $scope.sumaFakturyDPH = 0;
      $scope.sumaFaktury = 0;
      var promise = $scope.doklad.recountSums(
        $scope.RecordCtrl.related.getFullList()
      );
      promise.then(function (sums) {
        $scope.sumaFakturyDPH = sums.spolu;
        $scope.sumaFaktury =
          Math.round((sums.zaklad_d1 + sums.zaklad_d2 + sums.zaklad_d0) * 100) /
          100.0;
      });
      return promise;
    };
    $scope.recountSumsDelayed = function () {
      $timeout(function () {
        $scope.recountSums();
      });
    };
    var fakturaDataChanged = function () {
      $scope.recountSums();
      if ($scope.doklad.id)
        $scope.prevadzky = _.filter(Profile.get("prevadzky"), {
          id: $scope.doklad.prevadzka,
        });
      else $scope.prevadzky = Profile.get("prevadzky");
      $scope.currentPrevadzka = _.find($scope.prevadzky, {
        id: $scope.doklad.prevadzka,
      });
    };
    var fakturaPreSave = function (data) {
      $scope.doklad.d_vyst = $scope.doklad.d_zdp;
      if (Profile.get("jednoducheUcto")) {
        $scope.doklad.ucet = $scope.doklad.pokladna;
      }
      // vynulovat ceny v ZM, ak sa medzicasom zrusila mena
      if (parseFloat($scope.doklad.kurz)) {
        $scope.kurzChanged();
      }
      return data;
    };
    var fakturaPostSave = function (data, created, original) {
      // $scope.recountSums().then(function(newSums) {
      //   copyWeakSrc(newSums, data);
      // });
      Profile.set("lastPokladna", data.pokladna);
      Profile.parseObdobie(data.d_vyst);
      if (data.faktura_field) {
        Doklad.syncChange(data.faktura_data, data.faktura_data);
      } else if (original && original.faktura_field) {
        original.faktura_data.uhradene =
          parseFloat(original.faktura_data.uhradene) -
          parseFloat(original.spolu);
        Doklad.syncChange(original.faktura_data, original.faktura_data);
      }
      return data;
    };
    var fakturaValidate = function () {
      if ($scope.doklad.uctovanie && $scope.uctovanie_suma.rozdiel !== 0) {
        alert("Rozdiel MD-D musí byť 0!");
        return false;
      }
      if ($scope.doklad.d_vyst && !Profile.checkObdobie($scope.doklad.d_vyst)) {
        if (
          !window.confirm(
            "Dátum nespadá do prednastaveného obdobia!\nPokračovať?"
          )
        ) {
          return false;
        }
      }
      if (!isEmpty($scope.doklad.rozuctovanie)) {
        if (!$scope.rozuctovanie.valid) {
          $scope.errors.rozuctovanie = true;
          alert("Chyba v rozúčtovaní!\nRozúčtovaná musí byť plná suma.");
          return false;
        } else {
          $scope.errors.rozuctovanie = false;
        }
      }
      return true;
    };

    $scope.RecordCtrl.main.init({
      endpoint: "list",
      model: "doklad",
      master: doklad,
      list: list,
      defaultItem: defaultPokladnicnyDoklad,
      dataChanged: fakturaDataChanged,
      focusField: "firma",
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      validate: fakturaValidate,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "pohyb",
      lookup: "doklad",
      object: Pohyb,
      preSave: pohybPreSave,
      postSave: pohybPostSave,
      preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    $scope.setPrevadzka = function (prevadzka) {
      $scope.doklad.prevadzka = prevadzka.id;
      $scope.currentPrevadzka = prevadzka;
      Profile.set("lastPrevadzka", prevadzka);
      $scope.skladChanged(
        $scope.currentPrevadzka.sklady.length
          ? $scope.currentPrevadzka.sklady[0].id
          : undefined
      );
    };

    $scope.mainFormSubmitter = function ($event) {
      if ($event.which === 13 && !$event.shiftKey) {
        if (angular.equals($scope.newItem, defaultPohybItem)) {
          $scope.ok();
        } else {
          $scope.RecordCtrl.related.add($scope.newItem);
        }
      }
    };
    $scope.sumaFaktury = 0;
    $scope.sumaFakturyDPH = 0;

    $scope.activeCurrency =
      $scope.doklad.mena && $scope.doklad.primarna_zm
        ? $scope.doklad.mena_data.mena
        : Global.get("mena");

    $scope.toggleCurrency = function (item) {
      var kurz = parseFloat($scope.doklad.kurz);

      if ($scope.doklad.primarna_zm)
        $scope.newItem.cena_edit =
          Math.round((parseFloat($scope.newItem.cena_edit) / kurz) * 10000) /
          10000.0;
      else
        $scope.newItem.cena_edit =
          Math.round(parseFloat($scope.newItem.cena_edit) * kurz * 10000) /
          10000.0;

      if (item) {
        item.prepocitatKurzom($scope.doklad);
      } else {
        $("#add_cena_vydaj").focus();
      }
      $scope.doklad.primarna_zm = !$scope.doklad.primarna_zm;
      $scope.activeCurrency = $scope.doklad.primarna_zm
        ? $scope.doklad.mena_data.mena
        : Global.get("mena");

      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.doklad.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj[$scope.doklad.getCenaFields().cena];
        }
      });
    };
    $scope.kurzChanged = function () {
      var list = $scope.RecordCtrl.related.getFullList();
      $scope.doklad.prepocitatPohybKurzom(list);
      for (var i = 0; i < list.length; i++) {
        $scope.RecordCtrl.related.edit(list[i]);
      }
      // update also items which are currently being edited
      var items = $scope.RecordCtrl.related.getEditingItems();
      angular.forEach(items, function (obj, id) {
        if (obj) {
          if ($scope.doklad.primarna_zm) items[id].cena_edit = obj.cena_zm;
          else items[id].cena_edit = obj[$scope.doklad.getCenaFields().cena];
        }
      });
    };

    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function (firma) {
      $scope.doklad.firma_data = firma;
      // if (firma.predkontacia_pd) {
      //   $scope.doklad.predkontacia = firma.predkontacia_pd;
      //   $scope.doklad.predkontacia_data = firma.predkontacia_pd_data;
      //   $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
      // }
    };

    var selectKarta = function (item, parent) {
      var into = parent || $scope.newItem;
      delete into.zlava;
      copyWeakSrc(_.pick(item, "kod", "nazov", "dph", "mj"), into);
      into.karta = item.id;
      if (!Profile.get("svb")) {
        (parent || $scope.newItem).cena_edit = item.mo;
      }
      if (!parent) {
        Pohyb.updateSkladLabelsWithQty($scope, item);
        $timeout(function () {
          $("#pohyb_mp").focus();
        });
      }
    };
    $scope.changedItem = function () {
      $timeout(function () {
        if (!$scope.newItem.karta_data) {
          Pohyb.updateSkladLabelsWithQty($scope);
        }
      });
    };
    $scope.selectedItem = function (item) {
      selectKarta(item, null);
    };
    $scope.selectedEditItem = function (item, parent) {
      selectKarta(item, parent);
    };
    var extendKartaFromParent = function (parent) {
      if (!parent) {
        return {};
      }
      return {
        nazov: parent.nazov,
        mo: parent.cena_edit,
        dph: parent.dph,
        mj: parent.mj,
        modph:
          parseFloat(parent.cena_edit) * ((parseInt(parent.dph) + 100) / 100.0),
      };
    };
    $scope.addKartaKod = function (kod, parent) {
      return Karta.add(kod, extendKartaFromParent(parent));
    };
    $scope.addKartaNazov = function (nazov) {
      return Karta.add(
        undefined,
        angular.extend(extendKartaFromParent($scope.newItem), { nazov: nazov })
      );
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: doklad });
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function () {
      var prevadzka = _.find($scope.prevadzky, { id: $scope.doklad.prevadzka });
      var modalInstance = $modal.open({
        templateUrl: "admin/settings/printSettings.html",
        controller: "PrintSettingsCtrl",
        backdrop: "static",
        windowClass: "printsettings-detail",
        resolve: {
          record: function () {
            return prevadzka;
          },
          prevadzky: function () {
            return $scope.prevadzky;
          },
        },
      });
    };

    $scope.$watch("doklad.d_zdp", function (val, oldVal) {
      if (val && $scope.doklad.pokladna) {
        var date = parseDate(val);
        if (
          !isNaN(date) &&
          (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)
        ) {
          updateTypDPH(val);
        }
      }
    });
    $scope.$watch("doklad.typ_dph", function (val) {
      if (angular.isDefined($scope.typyDPH)) {
        if (val) {
          $scope.doklad.typdph_data = _.find($scope.typyDPH, {
            id: parseInt(val),
          });
          if (
            !$scope.doklad.id &&
            !angular.isDefined(defaultPokladnicnyDoklad.typdph_data)
          ) {
            defaultPokladnicnyDoklad.typdph_data = $scope.doklad.typdph_data;
          }
        } else {
          $scope.doklad.typdph_data = undefined;
        }
      }
    });
    $scope.pokladnaChanged = function () {
      var val = $scope.doklad.pokladna;
      if (val && $scope.pokladne.length && !$scope.doklad.faktura_field) {
        $scope.doklad.operacia = _.find($scope.pokladne, {
          id: parseInt(val),
        }).default_operacia;
      }
    };
    var vsetkyTypyDPH = [];
    $scope.$watch("doklad.operacia", function (val, oldVal) {
      $scope.firmaLabel =
        val === -1 ? "pokladna.VYPLATENE_KOMU" : "pokladna.PRIJATE_OD";
      $timeout(function () {
        $scope.$broadcast("tooltipsRefresh");
      });
      var modul = val === 1 ? 1 : 2;
      if (
        $scope.doklad.predkontacia &&
        $scope.doklad.predkontacia_data.operacia &&
        $scope.doklad.predkontacia_data.operacia !== val
      ) {
        $scope.doklad.predkontacia = null;
        $scope.doklad.predkontacia_data = null;
      }
      if (Profile.get("jednoducheUcto") && val !== oldVal) {
        angular.forEach($scope.doklad.uctovanie, function (value, key) {
          if (value && key.match(/\_d$/)) {
            var ucet = _.find($scope.ucty, { id: value });
            if (ucet && ucet.operacia && ucet.operacia != val) {
              delete $scope.doklad.uctovanie[key];
              delete $scope.doklad.uctovanie[key.replace("_d", "_popis")];
            }
          }
        });
      }
      if (typDPHPromise) {
        typDPHPromise.then(function (data) {
          vsetkyTypyDPH = data.data;
          $scope.typyDPH = _.filter(data.data, function (obj) {
            return obj.modul === 0 || obj.modul === modul;
          });
          if (
            val !== oldVal &&
            $scope.doklad.typ_dph !== null &&
            ($scope.doklad.d1 || $scope.doklad.d2)
          ) {
            $scope.doklad.typ_dph = undefined;
            pickDefaultTypDPH(
              $scope.doklad.d1 ? $scope.doklad.d1 : $scope.doklad.d2
            );
          }
        });
      } else {
        updateTypDPH();
      }
    });
    var pickDefaultTypDPH = function (val) {
      if (
        !$scope.parovanieJU() &&
        parseFloat(val) &&
        !angular.isDefined($scope.doklad.typ_dph)
      ) {
        var typ;
        if (
          (typ = _.find($scope.typyDPH, {
            modul: $scope.doklad.operacia === 1 ? 1 : 2,
            default: true,
          }))
        ) {
          $scope.doklad.typ_dph = typ.id;
        }
      }
    };

    $scope.onUploadAttachment = function ($files) {
      if ($files.length !== 1) {
        return;
      }

      var file = $files.shift();

      PokladnicnyDoklad.uploadAttachment(file, $scope.doklad);
    };

    $scope.onRemoveAttachment = function () {
        PokladnicnyDoklad.removeAttachment($scope.doklad);
    };
    $scope.exportFakturaZjednodusena = function(doklad) {
      if ($scope.RecordCtrl.main.validateAndSave()) {
        Outputs.openExportUrlWithID('banka', doklad.id, undefined, 'faktura-zjednodusena');
      } else {
        Notification.add('Záznam sa nepodarilo uložiť.', 'danger', 10);
      }
    };

    $scope.$watch("doklad.d1", pickDefaultTypDPH);
    $scope.$watch("doklad.d2", pickDefaultTypDPH);
  },
]);
