export const buildTemplateCache = (templateCache) => {
  const sklad = [
    'karty',
    'fakturacia',
    'ponuky',
    'nakup',
    'mnozstva',
    'pohyb',
    'zalohovefaktury',
    'cenniky',
    'pozicie',
    'sklady',
  ].map((screen) => `sklad/${screen}/${screen}.html`);
  const skladDetail = ['karty', 'fakturacia', 'ponuky', 'nakup', 'sklady', 'pozicie', 'cenniky'].map(
    (screen) => `sklad/${screen}/${screen}-detail.html`
  );
  const core = ['firmy', 'mailbox', 'import'].map(
    (screen) => `core/${screen}/${screen}.html`
  );
  const ucto = [
    'pohladavky',
    'zavazky',
    'pokladnicnedoklady',
    'penaznydennik',
    'bankovedoklady',
    'prikazy',
    'internedoklady',
    'predkontacie',
    'hlavnakniha',
    'uctdennik',
    'uctosnova',
    'rozuctovanie',
    'supisdph',
    'urgencie',
    'globalne-uctovanie',
    'prepocetuhrad',
  ].map((screen) => `ucto/${screen}/${screen}.html`);
  const uctoDetail = [
    'zavazky',
    'pokladnicnedoklady',
    'pohladavky',
    'bankovedoklady',
    'prikazy',
    'internedoklady',
    'urgencie',
    'predkontacie',
    'uctosnova',
  ].map((screen) => `ucto/${screen}/${screen}-detail.html`);
  const ubytovanie = ['nastenka', 'ubytovanie', 'izby'].map(
    (screen) => `ubytovanie/${screen}/${screen}.html`
  );
  const ubytovanieDetail = ['osoby', 'ubytovanie', 'izby'].map(
    (screen) => `ubytovanie/${screen}/${screen}-detail.html`
  );
  const urls = [
    ...sklad,
    'sklad/karty/typy/typy.html',
    'sklad/ponuky/ponuky-typ-detail.html',
    'sklad/fakturacia/fakturacia-hlavicka-detail.html',
    'sklad/fakturacia/fakturacia-doprava-detail.html',
    ...skladDetail,
    ...core,
    'core/firmy/firma-detail.html',
    'core/firmy/firmaprevadzka-detail.html',
    'core/ucty/bankAccount-detail.html',
    'core/mailbox/sender.html',
    'core/components/image.html',
    ...ucto,
    'ucto/pokladnicnedoklady/pokladne/pokladne.html',
    'ucto/bankovedoklady/import/bankaimport.html',
    'ucto/customize_neuhradene/customizeNeuhradene.html',
    'ucto/rozuctovanie/rozuctovanie-polozka-detail.html',
    'ucto/hlavnakniha/uzavierka/uzavierka.html',
    ...uctoDetail,
    ...ubytovanie,
    ...ubytovanieDetail,
    'ubytovanie/ubytovanie/prenos.html',
    'ubytovanie/ubytovanie/fakturacia.html',
    'svb/domy/domy.html',
    'svb/odpocty/odpocty.html',
    'svb/odpocty/odpocty-detail.html',
    'svb/odpocty/odpocty-list.html',
    'svb/odpocty/vymena-meracov-print.html',
    'svb/prehlad/prehlad.html',
    'svb/priestory/priestory.html',
    'svb/priestory/priestory-list.html',
    'svb/priestory/priestory-detail.html',
    'svb/priestory/priestory-merac-detail.html',
    'svb/priestory/priestory-vlastnik-detail.html',
    'svb/vlastnici/vlastnici.html',
    'svb/vlastnici/vlastnici-list.html',
    'svb/vlastnici/vlastnici-detail.html',
    'svb/vlastnici/print-predpis.html',
    'svb/vyuctovanie/generovanie_predpisov.html',
    'svb/vyuctovanie/vyuctovanie-detail.html',
    'svb/vyuctovanie/vyuctovanie-list.html',
    'svb/vyuctovanie/vyuctovanie.html',
    'components/navigation/new-tab.html',
    'components/navigation/appmenu.html',
    'components/auth/logout.html',
    'components/auth/activate.html',
    'components/auth/register.html',
    'components/auth/password-reset.html',
    'components/widgets/popover-html-unsafe-popup.html',
    'components/widgets/linked-element-popup.html',
    'components/widgets/linked-element-match-table.html',
    'components/widgets/linked-element-match.html',
    'components/widgets/predkontacia-selection.html',
    'components/widgets/osoby-selection.html',
    'components/widgets/version-viewer.html',
    'components/widgets/dialog-confirm.html',
    'components/widgets/dialog-alert.html',
    'components/widgets/dialog-prompt.html',
    'components/widgets/dialog-selection.html',
    'components/widgets/dropdown.html',
    'components/widgets/casist-date.html',
    'components/widgets/sales-viewer.html',
    'components/widgets/karty-selection.html',
    'components/widgets/karty-selection-short.html',
    'components/widgets/ucet-selection.html',
    'components/widgets/izby-selection.html',
    'components/widgets/priestor-selection.html',
    'components/widgets/rozuct-selection.html',
    'components/widgets/parovanie-selection.html',
    'components/widgets/nakup-selection.html',
    'components/widgets/select_m2m.html',
    'components/widgets/select_m2m_panel.html',
    'components/widgets/rozuctovanie.html',
    'components/widgets/image-gallery.html',
    'admin/users/profile.html',
    'admin/settings/settings.html',
    'admin/settings/printSettings.html',
    'admin/settings/setup.html',
    'admin/settings/agendy/agenda-detail.html',
    'admin/settings/prevadzky/prevadzka-detail.html',
  ];
  urls.forEach((url) => {
    const data = require('./' + url);
    templateCache.put(url, data);
  });
};
