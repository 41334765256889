'use strict';

angular.module('casist.svb')
  .controller('PriestorDetailCtrl', ['$scope', '$stateParams', '$state', 'vlastnici', 'merace', 'Notification', 'Priestor', '$controller', 'Dialog', '$timeout', 'priestor',
    function($scope, $stateParams, $state, vlastnici, merace, Notification, Priestor, $controller, Dialog, $timeout, priestor) {
      var defaultPriestor = {};
      if (priestor) {
        $scope.priestor = Priestor.copy(priestor);
      } else {
        $scope.priestor = angular.copy(defaultPriestor);
      }
      $scope.vlastnici = vlastnici;
      $scope.merace = merace;
      $scope.breadcrumb = [];
      $timeout(function() {
        $scope.breadcrumb = [($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.dom_data.nazov : _.find($scope.domy, {id: $scope.SearchCtrl.filters.top.dom}).nazov, ($scope.priestor && $scope.priestor.vchod) ? $scope.priestor.vchod_data.nazov : _.find($scope.vchody, {id: $scope.SearchCtrl.filters.top.vchod}).nazov, priestor ? priestor.nazov : 'Nový priestor'];
      });
      $scope.isEdit = $state.current.name !== 'default.priestory.add';

      $scope.vlastniciTable = [
        { },
        { field: 'meno', label: 'vlastnici.MENO', filterCollapse: 'left' },
        { field: 'vs', label: 'vlastnici.VS', filterType: 'icontains' },
        { field: 'datum_od', label: 'vlastnici.DATUM_OD', filterType: 'icontains' },
        { field: 'datum_do', label: 'vlastnici.DATUM_DO'},
        { field: 'poznamka', label: 'vlastnici.POZNAMKA'}
      ];
      $scope.meraceTable = [
        { },
        { field: 'nazov', label: 'merace.NAZOV', filterCollapse: 'left' },
        { field: 'druh', label: 'merace.DRUH' },
        { field: 'sn', label: 'merace.SN', filterType: 'icontains' }
      ];
      $scope.goToBreadcrumb = function() {
        $state.go('default.priestory.list', {page: $scope.priestoryList.page});
      };
      $scope.recountPlocha = function() {
        $timeout(function() {
          $scope.priestor.plocha_celkom = parseFloat($scope.priestor.plocha_byt || 0) + parseFloat($scope.priestor.plocha_pivnica || 0);
        });
      };
      $scope.recountVykurovanie = function() {
        $timeout(function() {
          $scope.priestor.vykur_objem = Math.round(parseFloat($scope.priestor.vyska || 0) * parseFloat($scope.priestor.vykur_plocha || 0)*100) / 100.0;
        });
      };
      $scope.save = function() {
        $scope.RecordCtrl.main.save().then(function(data) {
          //$scope.priestor.save().then(function(data) {
          if (data.type === 'created') {
            // toto by slo aj s location: true bez reloadu, ale ak to chce vlozit na dalsiu stranku, tak to handler to neprida do zoznamu a tym padom sa na to neda nastavit... istejsi je reload
            //$state.go('default.priestory.detail.summary', {page: $scope.priestoryList.page, priestorID: $scope.priestor.id}, {reload: true});
            $state.go('default.priestory.add', {page: $scope.priestoryList.num_pages}, {reload: true});
          } else {
            $scope.goToBreadcrumb();
          }
          Notification.add('Priestor bol uložený.', 'success', 5);
        });
      };
      $scope.cancel = function() {
        detectAndSaveChanges(priestor ? priestor.id : null, $scope.goToBreadcrumb);
      };
      $scope.remove = function() {
        var idx = $scope.priestory.indexOf(priestor)-1;
        var removalHandler = null;
        if (idx < 0 && $scope.priestory.length > 1) {
          idx = 1;
        }
        if (idx < 0) {
          removalHandler = function() {
            $state.go('default.priestory.add', {page: $scope.priestoryList.page}, {reload: true});
          }
        } else {
          var noveID = $scope.priestory[idx].id;
          removalHandler = function() {
            $state.go('default.priestory.detail.summary', {page: $scope.priestoryList.page, priestorID: noveID}, {reload: true});
          }
        }
        $scope.RecordCtrl.main.remove().then(function() {
          removalHandler();
        });
      };

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        //$modalInstance: $modalInstance,
        Model: Priestor
      });
      $scope.RecordCtrl.main.init({
        endpoint: 'priestory',
        model: 'priestor',
        master: priestor,
        list: $scope.priestory,
        defaultItem: {},
        preSave: function(obj) {
          obj.vchod = $scope.SearchCtrl.filters.top.vchod;
          obj.dom = $scope.SearchCtrl.filters.top.dom;
          return obj;
        }
      });

      var isRecordChanged = function() {
        if ($scope.priestor.id) {
          return !objEquals(Priestor.stripRestangular(priestor), Priestor.stripRestangular($scope.priestor));
        } else {
          return !objEquals(defaultPriestor, Priestor.stripRestangular($scope.priestor));
        }
      };
      var setRecord = function(id) {
        $state.go('default.priestory.detail.summary', {page: $scope.priestoryList.page, priestorID: id});
      };
      var detectAndSaveChanges = function(id, handler) {
        if (isRecordChanged()) {
          Dialog.confirm('Chcete uložiť zmeny?', 'Uloženie záznamu', {okClass: 'success', cancelClass: 'default', okText: 'Áno', cancelText: 'Nie'}).then(function(data) {
            var promise = $scope.RecordCtrl.main.save(true);
            if (promise) {
              promise.then(function(success) {
                if (handler) {
                  handler(id);
                } else {
                  setRecord(id);
                }
              }, function(error) {
                Dialog.alert(error, 'Chyba');
              });
            }
          }, function() {
            if (handler) {
              handler(id);
            } else {
              setRecord(id);
            }
          });
        } else {
          if (handler) {
            handler(id);
          } else {
            setRecord(id);
          }
        }
      };
      $scope.changePriestor = function(item) {
        detectAndSaveChanges(item.id, function() {
          $state.go('default.priestory.detail.summary', {priestorID: item.id, page: $scope.priestoryList.page});
        });
      };
      $scope.addPriestor = function() {
        detectAndSaveChanges(null, function() {
          $state.go('default.priestory.add');
        });
      };
      var saveAndGo = function(state, params) {
        if (isRecordChanged()) {
          var promise = $scope.RecordCtrl.main.save(true);
          if (promise) {
            promise.then(function() {
              $state.go(state, params);
            }, function(error) {
              Dialog.alert(error, 'Chyba');
            });
          }
        } else {
          $state.go(state, params);
        }
      };
      $scope.changeVlastnik = function(vlastnik) {
        saveAndGo('default.priestory.detail.vlastnik', {priestorID: $scope.priestor.id, vlastnikID: vlastnik.id});
      };
      $scope.addVlastnik = function() {
        saveAndGo('default.priestory.detail.addVlastnik', {priestorID: $scope.priestor.id});
      };
      $scope.changeMerac = function(merac) {
        saveAndGo('default.priestory.detail.merac', {priestorID: $scope.priestor.id, meracID: merac.id});
      };
      $scope.addMerac = function() {
        saveAndGo('default.priestory.detail.addMerac', {priestorID: $scope.priestor.id});
      };
      $scope.nextWindowRecord = function(index) {
        var list = $scope.priestory;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find($scope.priestory, {id: parseInt($stateParams.priestorID)}));
        }
        var nextItem = null;
        if (index !== -1) {
          nextItem = list.length > index-1 ? list[index+1] : null;
        }
        if (!nextItem) {
          if ($scope.priestoryList.num_pages > $scope.priestoryList.page) {
            $scope.priestoryList.page++;
            $scope.refreshPriestoryList().then(function(data) {
              nextItem = data[0];
              detectAndSaveChanges(nextItem.id);
            });
          } else {
            detectAndSaveChanges(null, function() {
              $state.go('default.priestory.add');
            });
          }
        } else {
          detectAndSaveChanges(nextItem.id);
        }
      };
      $scope.prevWindowRecord = function(index) {
        var list = $scope.priestory;
        if (!angular.isDefined(index)) {
          index = list.indexOf(_.find($scope.priestory, {id: parseInt($stateParams.priestorID)}));
        }
        if ($scope.priestoryList.page == 1 && index === 0) {
          return;
        }
        if (!priestor && list.length) {
          index = list.length;
        }
        var prevItem = null;
        if (index !== -1) {
          prevItem = list.length > 0 ? list[index-1] : null;
        }
        if (prevItem) {
          detectAndSaveChanges(prevItem.id);
        } else {
          if ($scope.priestoryList.page > 1) {
            $scope.priestoryList.page--;
            $scope.refreshPriestoryList().then(function(data) {
              prevItem = data[data.length-1];
              detectAndSaveChanges(prevItem.id);
            });
          }
        }
      };
  }]);
