'use strict';

angular.module('casist.widgets')
.directive('casistTime', ['$compile', '$filter', '$document', '$timeout', '$position', function ($compile, $filter, $document, $timeout, $position) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(scope, inputElement, attrs, ngModel) {
        if (!ngModel)
            return;

          ngModel.$parsers.push(function(viewValue) {
            return $filter('time')(viewValue);
          });

          var re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

          var parseTime = function(modelValue) {
            if (!modelValue) {
              return modelValue;
            }
            if (!parseInt(modelValue)) {
              return modelValue;
            }
            if (modelValue.match(':')) {
              var spl = modelValue.split(':');
              if (spl.length !== 2 && spl.length !== 3) {
                return undefined;
              }
              modelValue = spl[0]+':'+spl[1];
              if (spl[0].length === 2 && spl[1].length === 2 && re.test(modelValue)) {
                return modelValue;
              } else {
                return undefined;
              }
            }
            if (modelValue.length === 4) {
              modelValue = modelValue[0]+modelValue[1]+':'+modelValue[2]+modelValue[3];
              if (!re.test(modelValue)) {
                modelValue = undefined;
              }
              ngModel.$setViewValue(modelValue);
              return modelValue;
            } else {
              return undefined;
            }
          };
          ngModel.$formatters.push(function (modelValue) {
            return parseTime(modelValue);
          });

          inputElement.bind('blur', function() {
            var val = parseTime(inputElement.val());
            ngModel.$setViewValue(val || null);
            inputElement.val(val);
          });
      }
    };
  }]);
