'use strict';

angular.module('casist.ucto').controller('InterneDokladyCtrl',
  ['$scope', '$rootScope', 'Profile', '$controller', 'InternyDoklad', 'Firma', 'Outputs', '$q', 'DokladTypy', 'TabService', 'Predkontacia',
  function ($scope, $rootScope, Profile, $controller, InternyDoklad, Firma, Outputs, $q, DokladTypy, TabService, Predkontacia) {

  $scope.slideAnimation = true;

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  // var defaultFilter = {
  //   'dd': null
  // };
  var defaultFilter = {};
  $scope.uctovanieTypy = [
    {id: 1, uctovanie: 'Nezaúčtované'},
    {id: 2, uctovanie: 'Ručne účtované'},
    {id: 3, uctovanie: 'Predkontácia'},
    {id: 4, uctovanie: 'Zaúčtované'}
  ];
  $scope.rozuctovanieTypy = [
    {id: 1, rozuctovanie: 'Nerozúčtované'},
    {id: 2, rozuctovanie: 'Rozúčtované'}
  ];
  $scope.DokladTypy = DokladTypy;
  $scope.interneTypy = [
    {id: DokladTypy.IN.dd, dd: 'Interné doklady bez DPH'},
    // {id: DokladTypy.ID.dd, dd: 'Interné doklady s DPH'},
    {id: 0, dd: 'Všetky'}
  ];
  $scope.typChanged = function(value) {
    if (value.id === DokladTypy.ID.dd && $scope.currentView !== 1) {
      $scope.currentView = 1;
    } else if ($scope.currentView !== 0) {
      $scope.currentView = 0;
    }
  };
  $scope.doklady = [];
  var filterChanged = function(filter) {
    var promise = InternyDoklad.objects().getList(filter);
    $scope.doklady.loading = true;
    promise.then(function(data) {
      TabService.registerScope('internedoklady', $scope);
      $scope.doklady = data;
    });
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.getPredkontacia = function(query) {
    return Predkontacia.get(query, DokladTypy.IN.dd);
  };

  var coreFields = [
    {  },
    {  },
    { field: 'iddokd', sortField: 'dd,iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'd_zdp', label: 'ucto.SUPPLIED', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } }
  ];

  // var view2Fields = [

  // ];
  // if (!Profile.get('platcadph')) {
  //   var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'typ_dph', 'uplat_dph'];
  //   view2Fields = _.filter(view2Fields, function(field) { return omitted.indexOf(field.field) === -1});
  // }
  $scope.interneTable = [
    _.union(coreFields, [
      { field: 'zaklad_d1', label: 'fakturacia.SUMA1', type: 'number', sum: true },
      { field: 'd1', label: 'fakturacia.SUMA2', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'fakturacia.SUMA3', type: 'number', sum: true },
      { field: 'd2', label: 'fakturacia.SUMA4', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'fakturacia.SUMA5', type: 'number', sum: true },
      { field: 'spolu', label: 'fakturacia.SUMA6', type: 'number', sum: true },
      { field: 'suma1', label: 'fakturacia.SUMA7', type: 'number', sum: true },
      { field: 'suma2', label: 'fakturacia.SUMA8', type: 'number', sum: true },
      { field: 'suma3', label: 'fakturacia.SUMA9', type: 'number', sum: true },
      { field: 'suma4', label: 'fakturacia.SUMA10', type: 'number', sum: true },
      { field: 'suma5', label: 'fakturacia.SUMA11', type: 'number', sum: true },
      { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
    ]),
    _.union(coreFields, [
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
      { field: 'typ_dph__typ', label: 'ucto.TDPH' },
      { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE' }
    ])
  ];

  $scope.create = function(item, dd) {
    var config = {};
    // Pre ostatne pohladavky dame do configu typ okna bez DPHckovych poli
    // if (dd === 2 || $scope.SearchCtrl.filters.top.dd === DokladTypy.ID.dd) {
    //   config.VATfields = true;
    // }
    return InternyDoklad.edit(item, $scope.doklady, $scope.SearchCtrl, config);
  };
  $scope.copy = function(item) {
    var copied = InternyDoklad.copy(item);
    _.each(['id', 'd_vyst', 'd_zdp', 'd_uctn', 'uplat_dph', 'locked'], function(field) {
      delete copied[field];
    });
    $scope.create(copied);
  };

  var events = InternyDoklad.registerEvents(function() { return $scope.doklady; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    InternyDoklad.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: InternyDoklad,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.interneTable[0],
    sort: {sort: 'dd,iddokd', reverse: false}
  });

  $scope.SearchCtrl.refresh();

  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.interneTable[view]);
  };

  $scope.print = function(InternyDoklad) {
    Outputs.openPrintUrl('interne', InternyDoklad.id);
  };
  $scope.printList = function() {
    Outputs.openPrintUrl('interne', null, $scope.SearchCtrl.getQuery());
  };
}]);
