'use strict';

angular.module('casist.core')
  .controller('FirmaPrevadzkaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'FirmaPrevadzka', 'firma', 'firmy', '$timeout',
              function($scope, $modalInstance, $controller, Profile, FirmaPrevadzka, firma, firmy, $timeout) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: FirmaPrevadzka
    });

    var defaultFirma = {
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'prevadzky',
      model: 'firma',
      master: firma,
      list: firmy,
      defaultItem: defaultFirma,
      $modalInstance: $modalInstance
    });
    $scope.RecordCtrl.disableAdd(true);

    if (!isEmpty(firma)) {
      $scope.firma = FirmaPrevadzka.copy(firma);
    } else {
      $scope.firma = defaultFirma;
    }

    $scope.ok = function () {
      $modalInstance.close($scope.firma);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.deleteRecord = function() {
      $modalInstance.dismiss('remove');
    };
  }]);
