'use strict';

angular.module('casist.core')
  .controller('TableEditor', ['$scope', '$timeout', 'Model', 'List', '$q', 'Dialog', function ($scope, $timeout, Model, List, $q, Dialog) {
    var addedItems = [];
    var editedItems = [];
    var editingItems = {};
    var originalItems = {};
    var deletedItems = [];
    $scope.errors_related = {};

    this.startCreating = function(newModelKey) {
      $scope[newModelKey] = {};
      $timeout(function() {
        $('#'+newModelKey+"_first").focus();
      });
    };

    this.cancelCreating = function(newModelKey) {
      delete $scope[newModelKey];
    };

    this.add = function(newModelKey) {
      $scope.errors_related = {};
      var item;
      if (angular.isObject(newModelKey)) {
        item = newModelKey;
      } else {
        item = $scope[newModelKey];
      }
      var obj = Model.restangularize(angular.copy(item));
      if (!angular.equals(item, {}))
      {
        if (!obj.validate()) {
          $scope.errors_related = obj.errors().data;
          Dialog.alert ("Chyba pri zápise položky.", "Chyba");
          return;
        }
        addedItems.push(obj);
        // bind a virtual id field to the item by finding max id from the list and increasing it by one (to enable editing feature)
        obj.id = 0;
        for (var i = 0; i < List.length; i++) {
          if (List[i].id > obj.id)
            obj.id = List[i].id;
        }
        obj.id++;

        List.push(obj);
        if (!angular.isObject(newModelKey)) {
          delete $scope[newModelKey];
        }
        return obj;
      } else {
        return null;
      }
    };
    this.remove = function (item) {
      var index = List.indexOf(item);
      if (index == -1)
        return;

      // if item was just added, delete it from addedItems, else push it to deleted and delete it also from edited, in case it was there
      var addedItemIndex = -1;
      if ( (addedItemIndex = addedItems.indexOf(item)) > -1)
        addedItems.splice(addedItemIndex, 1);

      var editedItemIndex = -1;
      if ( (editedItemIndex = editedItems.indexOf(item)) > -1)
        editedItems.splice(editedItemIndex, 1);

      deletedItems.push(item);

      List.splice(index, 1);
    }
    this.edit = function(item) {
      // if creating, then added should be the only source of data
      var addedItemIndex = addedItems.indexOf(item);
      if (addedItemIndex > -1) {
        addedItems[addedItemIndex] = item;
      } else if (editedItems.indexOf(item) == -1) {
        editedItems.push(item);
      }
    }
    this.isEditing = function (item) {
      return angular.isDefined(editingItems[item.id]);
    }
    this.getEditingItem = function(id) {
      return editingItems[id];
    }
    this.getEditingItems = function() {
      return editingItems;
    }
    this.startEditing = function(item) {
      originalItems[item.id] = angular.copy(item);
      editingItems[item.id] = item;
    }
    this.finishEditing = function (item) {
      this.edit(item);

      delete editingItems[item.id];
      delete originalItems[item.id];
    }

    this.cancelEditing = function (item) {
      angular.copy(originalItems[item.id], editingItems[item.id]);

      delete editingItems[item.id];
      delete originalItems[item.id];
    }

    this.save = function() {
      for (var i in editingItems) {
        this.finishEditing(editingItems[i]);
      }
      var _addedItems = angular.copy(addedItems);
      var _editedItems = angular.copy(editedItems);
      var _deletedItems = angular.copy(deletedItems);
      var promises = [];
      if (_addedItems.length) {
        for (var i in _addedItems) {
          delete _addedItems[i].id;
        }
        promises.push(Model.createBulk(_addedItems));
      }
      // adjust edited items before sending to the server
      if (_editedItems.length) {
        for (var i in _editedItems) {
          _editedItems[i] = Model.stripRestangular(_editedItems[i]);
        }
        promises.push(Model.updateBulk(_editedItems));
      }
      if (_deletedItems.length) {
        var deletedIDs = _.map(_deletedItems, item => item.id);
        promises.push(Model.removeBulk({id: deletedIDs}, deletedItems));
      }
      var deferred = $q.defer();
      $q.all(promises).then(function(data) {
        var idx = 0;
        var diff = { added: [], edited: [], deleted: [] };
        if (_addedItems.length) {
          for (var i = 0; i < data[idx].length; i++) {
            copyWeakSrc(data[idx][i], addedItems[i]);
          }
          diff.added = angular.copy(addedItems);
          addedItems = [];
          idx++;
        }
        if (_editedItems.length) {
          for (var i = 0; i < data[idx].length; i++) {
            copyWeakSrc(data[idx][i], editedItems[i]);
          }
          diff.edited = angular.copy(editedItems);
          editedItems = [];
          idx++;
        }
        if (_deletedItems.length) {
          diff.deleted = angular.copy(deletedItems);
          deletedItems = [];
        };
        deferred.resolve(diff);
      }, function(error) {
        deferred.reject(error);
        var msg = 'Zmeny sa nepodarilo zapísať.';
        if (error && error.data && error.data.error) {
          msg += '<br>'+error.data.error;
        }
        Dialog.alert(msg, 'Chyba');
      });
      return deferred.promise;
    };

    this.formSubmitter = function(evt, fc) {
      if (!evt.shiftKey && !evt.altKey && evt.which === 13) {
        var args = Array.prototype.splice.call(arguments, 2);
        var obj = this;
        $timeout(function() {
          // to apply ng-blur before function
          if (evt.target)
            evt.target.blur();
          $scope.$eval(fc).apply(obj, args);
        });
        evt.preventDefault();
        evt.stopPropagation();
      }
    };
  }]);
