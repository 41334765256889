'use strict';

angular.module('casist.ucto').controller('PenaznyDennikCtrl',
  ['$scope', '$rootScope', 'Profile', '$controller', 'PenaznyDennik', 'Firma', 'Outputs', '$q', 'DokladTypy', 'Pokladna', 'TypDPH', 'TabService', 'BankAccount', 'BankovyDoklad', 'PokladnicnyDoklad', '$modal', 'Notification', 'Dialog', 'Predkontacia', 'Doklad',
  function ($scope, $rootScope, Profile, $controller, PenaznyDennik, Firma, Outputs, $q, DokladTypy, Pokladna, TypDPH, TabService, BankAccount, BankovyDoklad, PokladnicnyDoklad, $modal, Notification, Dialog, Predkontacia, Doklad) {

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {
    'dd': [DokladTypy.PD.dd, DokladTypy.BD.dd].join(',')
  };
  $scope.uctovanieTypy = [
    {id: 1, uctovanie: 'Nezaúčtované'},
    {id: 2, uctovanie: 'Ručne účtované'},
    {id: 3, uctovanie: 'Predkontácia'},
    {id: 4, uctovanie: 'Zaúčtované'}
  ];
  $scope.rozuctovanieTypy = [
    {id: 1, rozuctovanie: 'Nerozúčtované'},
    {id: 2, rozuctovanie: 'Rozúčtované'}
  ];
  $scope.penaznydennikTypy = [
    {id: [DokladTypy.PD.dd, DokladTypy.BD.dd].join(','), dd: 'Všetky', hidden: false},
    {id: DokladTypy.PD.dd, dd: 'Pokladničné doklady', hidden: false},
    {id: DokladTypy.BD.dd, dd: 'Bankové doklady', hidden: false},
    {id: DokladTypy.RP.dd, dd: 'RP doklady', hidden: true}
  ];
  $scope.pociatocnyStav = undefined;
  $scope.penaznydennik = [];
  var filterChanged = function(filter) {
    var promise = PenaznyDennik.objects().getList(filter);
    $scope.pociatocnyStav = undefined;
    $scope.penaznydennik.loading = true;
    promise.then(function(data) {
      TabService.registerScope('penaznydennik', $scope);
      if (data.sumline.length > 1) {
        var sums = angular.copy(data.sumline);
        $scope.pociatocnyStav = sums[1];
        data.sumline[1].spolu = parseFloat(data.sumline[0].spolu) + parseFloat(sums[1].poc_stav);
      }
      $scope.penaznydennik = data;
    });
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;
  $scope.getPredkontacia = function(query) {
    return Predkontacia.get(query);
  };
  $scope.operacie = [
    {id: '1', operacia: '+'},
    {id: '-1', operacia: '-'}
  ];
  $scope.pokladne = [];
  Pokladna.objects().getList({active: 'True'}).then(function(data) {
    angular.copy(data, $scope.pokladne);
    BankAccount.objects().getList({active: 'True'}).then(function(data) {
      $scope.ucty_neinterne = _.reject(data, function(obj) { return obj.interny });
      for (var i = 0; i < data.length; i++) {
        $scope.pokladne.push({id: data[i].id, kod: data[i].kod});
      }
    });
  });
  $scope.typydph = TypDPH.objects().getList({ordering: 'typ'}).$object;

  $scope.views = [ 'Účtovné doklady', 'Prehľad RP' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.SearchCtrl.changeColumnsDef($scope.penaznydennikTable[view]);
    if (view === 1) {
      $scope.SearchCtrl.setDefaultFilter(angular.extend(defaultFilter, {dd: DokladTypy.RP.dd}));
      $scope.SearchCtrl.setSort({sort: 'iddokd', reverse: false}, false);
    } else if ($scope.currentView === 1) {
      $scope.SearchCtrl.setDefaultFilter(angular.extend(defaultFilter, {dd: [DokladTypy.PD.dd, DokladTypy.BD.dd].join(',')}));
      $scope.SearchCtrl.setSort({sort: 'riadok', reverse: false}, false);
    }
    $scope.SearchCtrl.setLastPage(true);
    $scope.currentView = view;
  };

  var coreFields = [
    {  },
    {  },
    { field: 'riadok', label: 'penaznydennik.RIADOK', type: 'int', filterCollapse: 'right' },
    { field: 'ucet', label: 'penaznydennik.KUKP', filterCollapse: 'right', select2: { source: 'pokladne', lookup: 'kod' }, tooltip: 'penaznydennik.KUKP_FULL' },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' },
    { field: 'vypis', label: 'banka.VYPIS', width: 70, type: 'int' },
    { field: 'd_zdp', label: 'pokladna.UHRADENE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'operacia', label: 'pokladna.OPERACIA', width: 30, select2: { source: 'operacie', lookup: 'operacia' } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
    { field: 'danove', label: 'penaznydennik.DANOVE', type: 'number', sum: true },
    { field: 'nedanove', label: 'penaznydennik.NEDANOVE', type: 'number', sum: true },
    { field: 'faktura_field__iddokd', label: 'pokladna.UHRADA' },
    { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
    { field: 'typ_dph', label: 'ucto.TDPH', select2: { source: 'typydph', lookup: 'typ' } },
    { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
  ];
  var rpFields = [
    { },
    { },
    { field: 'ucet', label: 'pokladna.KP', filterCollapse: 'right', select2: { source: 'pokladne', lookup: 'kod' }, tooltip: 'pokladna.KOD_POKLADNE' },
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'd_zdp', label: 'pokladna.UHRADENE', type: 'date' },
    { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
    { field: 'operacia', label: 'pokladna.OPERACIA', width: 30, select2: { source: 'operacie', lookup: 'operacia' } },
    { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
    { field: 'hotovost', label: 'pokladna.HOTOVOST', type: 'number', sum: true },
    { field: 'karta', label: 'pokladna.KARTA', type: 'number', sum: true },
    { field: 'zlava_suma', label: 'fakturacia.ZLAVA_SUMA', type: 'number', sum: true },
    { field: 'blok', label: 'pokladna.BLOK', type: 'int' },
    { field: 'faktura_field__iddokd', label: 'pokladna.UHRADA' },
    { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
    { field: 'typ_dph', label: 'ucto.TDPH', select2: { source: 'typydph', lookup: 'typ' } },
    { field: 'predkontacia__nazov', label: 'ucto.UCTOVANIE', width: 150, filterCollapse: 'left', filterType: 'iexact', linked: { source: 'getPredkontacia(query)', template: 'predkontacia-selection', lookup: 'nazov', style: "{width: '400px'}" } }
  ];

  if (!Profile.get('platcadph')) {
    var omitted = ['typ_dph', 'uplat_dph'];
    coreFields = _.filter(coreFields, function(field) { return omitted.indexOf(field.field) === -1});
    rpFields = _.filter(rpFields, function(field) { return omitted.indexOf(field.field) === -1});
  }

  $scope.showPrint = function(doklad) {
    return doklad.dd === DokladTypy.PD.dd;
  };

  $scope.parseSums = function(obj, key) {
    return parseFloat(obj[key]*obj.operacia);
  };

  $scope.penaznydennikTable = [
    coreFields,
    rpFields
  ];

  $scope.create = function(item, dd) {
    if (dd === 2 || (item && item.dd === DokladTypy.BD.dd) || $scope.SearchCtrl.filters.top.dd === DokladTypy.BD.dd) {
      return PenaznyDennik.editBanka(item, function() { return _.filter($scope.penaznydennik, {dd: DokladTypy.BD.dd}); }, $scope.SearchCtrl);
    } else if (item && item.dd === DokladTypy.RP.dd) {
      return PenaznyDennik.editPokladna(item, function() { return _.filter($scope.penaznydennik, {dd: DokladTypy.RP.dd}); }, $scope.SearchCtrl);
    }
    else {
      return PenaznyDennik.editPokladna(item, function() { return _.filter($scope.penaznydennik, {dd: DokladTypy.PD.dd}); }, $scope.SearchCtrl);
    }
  };
  $scope.copy = function(item) {
    var copied;
    if (item.dd === DokladTypy.BD.dd) {
      copied = PenaznyDennik.copy(item);
      _.each(['id', 'd_vyst', 'd_zdp', 'd_uctn', 'faktura', 'faktura_data'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    } else {
      copied = PenaznyDennik.copy(item);
      copied.getList('pohyb').then(function(pohyb) {
        copied.pohyb = pohyb;
        _.each(['id', 'd_vyst', 'd_zdp', 'd_uctn', 'uplat_dph', 'faktura', 'faktura_data'], function(field) {
          delete copied[field];
        });
        $scope.create(copied);
      });
    }
  };

  var refreshUcty = function() {
    var ucty = deepcopy($scope.ucty_neinterne);
    ucty.refreshing = true;
    $scope.ucty_neinterne = ucty;
  };

  var events = PenaznyDennik.registerEvents(function() { return $scope.penaznydennik; }, function() { return $scope.SearchCtrl; });
  var bankEvents = BankAccount.registerCustomEvents(function(ucet) {
    $scope.ucty_neinterne.push(ucet);
    refreshUcty();
  }, function(data) {
    var ucet = _.find($scope.ucty_neinterne, {id: data.id});
    if (ucet) {
      copyWeakSrc(data, ucet);
    }
    refreshUcty();
  }, function(ucet) {
    var ucet = _.find($scope.ucty_neinterne, {id: ucet.id});
    if (ucet) {
      $scope.ucty_neinterne.splice($scope.ucty_neinterne.indexOf(ucet), 1);
    }
    refreshUcty();
  });
  $scope.$on('$destroy', function() {
    PenaznyDennik.unregisterEvents(events);
    BankAccount.unregisterEvents(bankEvents);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: PenaznyDennik,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.penaznydennikTable[0],
    sort: {sort: 'riadok', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh();

  $scope.printList = function() {
    Outputs.openPrintUrl('penaznydennik', null, $scope.SearchCtrl.getQuery());
  };
  $scope.printDennyObrat = function() {
    var query = $scope.SearchCtrl.getQuery();
    delete query.ordering;
    Outputs.openPrintUrl('penaznydennik', null, query, 'print-denny-obrat');
  };
  $scope.print = function(doklad) {
    Outputs.openPrintUrl('penaznydennik', doklad.id);
  };

  $scope.uploadImport = function(ucet, $files) {
    $scope.uploadInProgress = true;
    BankovyDoklad.import(ucet, $files).then(function(data) {
      $scope.uploadInProgress = false;
      var modalInstance = $modal.open({
        templateUrl: 'ucto/bankovedoklady/import/bankaimport.html',
        controller: 'BankaImportCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          ucty: function() {
            return $scope.ucty_neinterne;
          },
          ucet: function() {
            return ucet;
          },
          list: function() {
            return data;
          },
          filename: function() {
            return $files[0].name;
          }
        }
      });
      modalInstance.result.then(function(data) {
        BankovyDoklad.importData(data).then(function(importData) {
          for (var i = 0; i < importData.length; i++) {
            PenaznyDennik.syncCreate(importData[i]);
            if (importData[i].faktura) {
              Doklad.syncChange(importData[i].faktura_data, importData[i].faktura_data);
            }
            Profile.parseObdobie(importData[i].d_zdp);
          }
        });
      }, function() {
      });
    }, function(error) {
      $scope.uploadInProgress = false;
      Notification.add(error ? error.error || error : 'Nastala chyba pri importe.', 'danger', 10);
    });
  };

  $scope.precislovanieRiadkov = function() {
    $scope.pociatocnyStav = undefined;
    $scope.penaznydennik.length = 0;
    $scope.penaznydennik.loading = true;
    PenaznyDennik.precislovanieRiadkov($scope.SearchCtrl.getQuery()).then(function(data) {
      for (var i = 0; i < data.length; i++) {
        $scope.penaznydennik.push(data[i]);
      }
      $scope.penaznydennik.loading = false;
      $scope.SearchCtrl.broadcastRefresh('penaznydennik');
      Notification.add('Riadky boli prečíslované.', 'success', 5);
    }, function() {
      Notification.add('Prečíslovanie riadkov sa nepodarilo.', 'danger', 5);
    });
  };

  $scope.globalneUctovanie = function() {
    if (!$scope.SearchCtrl.hasFilter()) {
      Dialog.alert('Globálne účtovanie je možné použiť len keď je použitý filter.', 'Upozornenie');
      return;
    }
    $modal.open({
      templateUrl: 'ucto/globalne-uctovanie/globalne-uctovanie.html',
      controller: 'GlobalneUctovanieCtrl',
      backdrop: 'static',
      windowClass: 'detail-sm',
      resolve: {
        list: function() {
          return $scope.penaznydennik;
        },
        query: function() {
          return $scope.SearchCtrl.getQuery();
        },
        model: function() {
          return PenaznyDennik;
        },
        predkontacieGetter: function() {
          return function(query) { return $scope.getPredkontacia(query); };
        }
      }
    }).result.then(function(data) {
      Notification.add('Záznamy boli zaúčtované.', 'success', 5);
      var faktura;
      for (var i = 0; i < data.length; i++) {
        faktura = _.find($scope.penaznydennik, {id: data[i].id});
        if (faktura) {
          PenaznyDennik.syncChange(data[i], angular.copy(faktura));
        }
      }
    });
  };
}]);
