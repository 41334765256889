'use strict';

angular.module('casist.widgets')
  .directive('uctovanieTable', ['$compile', 'Profile', 'DokladTypy', function ($compile, Profile, DokladTypy) {
    return {
      restrict: 'EA',
      require: 'ngModel',
      scope: {
        // uctovanieTable: '=',
        source: '=',
        ngModel: '=',
        doklad: '=',
        onAdd: '&',
        sumacia: '='
      },
      link: function postLink(scope, element, attrs, ngModelCtrl) {
        var showLabels = angular.isDefined(attrs.labels) ? scope.$eval(attrs.labels) : false;
        var options = angular.isDefined(attrs.options) ? scope.$eval(attrs.options) : {};

        var labels = {
          'zaklad_d1': 'Základ DPH 1',
          'd1': 'DPH 1',
          'zaklad_d2': 'Základ DPH 2',
          'd2': 'DPH 2',
          'zaklad_d0': 'Základ 0',
          'spolu': 'Spolu',
          'suma1': 'Suma 1',
          'suma2': 'Suma 2',
          'suma3': 'Suma 3',
          'suma4': 'Suma 4',
          'suma5': 'Suma 5'
        };
        var tabindex = '';
        if (angular.isDefined(attrs.tabindex)) {
          tabindex = ' tabindex="'+attrs.tabindex+'"';
        }
        var labelsHeader = '', labelsRow = '';
        if (showLabels) {
          labelsHeader = '<div class="uctovanie-field field-label">Suma</div>';
        }

        var fields = [], fields_all = [];
        var parsedType = undefined;
        var $el = null;

        var parseTable = function(val) {
          if ($el) {
            $el.remove();
          }
          val = parseInt(val);
          var labelsMD = Profile.get('jednoducheUcto') ? '' : '<div class="uctovanie-field">{{\'ucto.UCTOVANIE_MD\' | jednoducheUctoLabels | translate}}</div>';
          var el = '<div><div class="uctovanie-header bg-primary">'+labelsHeader+labelsMD+'<div class="uctovanie-field">{{\'ucto.UCTOVANIE_D\' | jednoducheUctoLabels | translate}}</div><div class="uctovanie-field poznamka">{{\'predkontacie.POPIS\' | jednoducheUctoLabels | translate}}</div></div></div>';
          fields = [];
          fields_all = [];
          switch (val) {
            case DokladTypy.VF.dd: case DokladTypy.DF.dd: case DokladTypy.PD.dd: case DokladTypy.IN.dd: case DokladTypy.RP.dd:
              fields = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'spolu', 'suma1', 'suma2', 'suma3', 'suma4', 'suma5'];
              if (val === DokladTypy.IN.dd) {
                labels = {
                  'zaklad_d1': 'Suma 1',
                  'd1': 'Suma 2',
                  'zaklad_d2': 'Suma 3',
                  'd2': 'Suma 4',
                  'zaklad_d0': 'Suma 5',
                  'spolu': 'Suma 6',
                  'suma1': 'Suma 7',
                  'suma2': 'Suma 8',
                  'suma3': 'Suma 9',
                  'suma4': 'Suma 10',
                  'suma5': 'Suma 11'
                };
              }
              break;
            case DokladTypy.OP.dd: case DokladTypy.OZ.dd:
              fields = ['spolu', 'suma1', 'suma2', 'suma3', 'suma4', 'suma5'];
              break;
            case DokladTypy.BD.dd:
              if (Profile.get('jednoducheUcto')) {
                fields = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'spolu', 'suma1', 'suma2', 'suma3', 'suma4', 'suma5'];
              } else {
                fields = ['spolu', 'suma1'];
              }
              break;
            default:
              break;
          }

          if (!Profile.get('platcadph') && val !== DokladTypy.IN.dd) {
            fields = _.without(fields, 'zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0');
          }

          scope.values = {};
          for (var f = 0; f < fields.length; f++) {
            fields_all.push(fields[f]+'_md');
            fields_all.push(fields[f]+'_d');
            fields_all.push(fields[f]+'_popis');
            if (showLabels) {
              labelsRow = '<div class="uctovanie-field field-label"><label class="form-label control-label">'+labels[fields[f]]+':</label></div>';
            }
            scope.values['md_'+fields[f]] = '';
            scope.values['d_'+fields[f]] = '';
            var stranaMD = Profile.get('jednoducheUcto') ? '' : '<div class="uctovanie-field">'+
                  '<div class="form-control input-sm ucet-input"'+tabindex+' input-class="text-center" linked type="text" on-select="clearPredkontacia" ng-change="clearPredkontacia()"'+(Profile.get('jednoducheUcto') ? ' show-on-focus' : '')+' input-name="'+fields[f]+'_md" input-id="'+fields[f]+'_md" source="source" lookup="ucet" value="values.md_'+fields[f]+'" parent="::{type: \'md\', field: \''+fields[f]+'\'}"'+(options.md_readonly ? '' : ' on-add="_onAdd"')+' editable="false" wait="false" show-selection-button="false" ng-model="ngModel.'+fields[f]+'_md" fetch-value="true" popup-template="ucet-selection" popup-style="{width: \'400px\'}"></div>'+
                  '</div>';
            el += '<div><div class="uctovanie-row'+(f === fields.length-1 ? ' last' : '')+'">'+labelsRow+stranaMD+'<div class="uctovanie-field">'+
                  '<div class="form-control input-sm ucet-input"'+tabindex+' input-class="text-center" linked type="text" on-select="clearPredkontacia" ng-change="clearPredkontacia()"'+(Profile.get('jednoducheUcto') ? ' show-on-focus' : '')+' input-name="'+fields[f]+'_d" input-id="'+fields[f]+'_d" source="source" lookup="ucet" value="values.d_'+fields[f]+'" parent="::{type: \'d\', field: \''+fields[f]+'\'}"'+(options.d_readonly ? '' : ' on-add="_onAdd"')+' editable="false" wait="false" show-selection-button="false" ng-model="ngModel.'+fields[f]+'_d" fetch-value="true" popup-template="ucet-selection" popup-style="{width: \'400px\'}"></div>'+
                  '</div><div class="uctovanie-field poznamka"><input class="form-control input-sm"'+tabindex+' ng-model="ngModel.'+fields[f]+'_popis"></div></div></div>';
          }
          // nezobrazovat v predkontaciach, kde su zobrazene labels
          if (!Profile.get('jednoducheUcto') && angular.isDefined(attrs.sumacia)) {
            scope.sumacia.sumaMD = 0;
            scope.sumacia.sumaD = 0;
            el += '<div><div class="uctovanie-row sumline" ng-class="{danger: (sumacia.rozdiel)}"><div class="uctovanie-field">'+
                    '<div><p class="form-control-static" style="padding-right: 3px">{{sumacia.sumaMD | suma:2}}</p></div>'+
                    '</div><div class="uctovanie-field">'+
                    '<div><p class="form-control-static" style="padding-right: 3px">{{sumacia.sumaD | suma:2}}</p></div>'+
                    '</div><div class="uctovanie-field poznamka"></div></div></div>';
          }
          $el = $compile(el)(scope);
          element.html($el);
          parsedType = val;
        };
        scope.uctovanieTable = scope.$parent.$eval(attrs.uctovanieTable);
        parseTable(scope.uctovanieTable);
        scope.$parent.$watch(attrs.uctovanieTable, function(val) {
          if (!val) {
            return;
          }
          if (parsedType !== parseInt(val)) {
            parseTable(val);
          }
        });

        if (angular.isDefined(attrs.sumacia)) {
          if (!Profile.get('jednoducheUcto')) {
            scope.$watch('doklad', function(val) {
              scope.sumacia.sumaMD = 0;
              scope.sumacia.sumaD = 0;
              scope.sumacia.rozdiel = 0;
              if (!scope.ngModel) {
                return;
              }
              for (var i = 0; i < fields.length; i++) {
                if (scope.ngModel[fields[i]+'_md']) {
                  scope.sumacia.sumaMD += parseFloat(scope.doklad[fields[i]] || 0);
                }
                if (scope.ngModel[fields[i]+'_d']) {
                  scope.sumacia.sumaD += parseFloat(scope.doklad[fields[i]] || 0);
                }
              }
              scope.sumacia.rozdiel = Math.round( (scope.sumacia.sumaMD - scope.sumacia.sumaD)*100) / 100.0;
            }, true);
          } else {
            scope.sumacia.rozdiel = 0;
          }
        }

        scope.clearPredkontacia = !angular.isDefined(attrs.doklad) ? angular.noop : function() {
          scope.doklad.predkontacia = null;
          scope.doklad.predkontacia_data = null;
        };

        scope._onAdd = function() {
          return scope.onAdd().apply(null, arguments);
        };
      }
    };
  }]);
