'use strict';

angular.module('casist.ucto').controller('SupisDPHCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'SupisDPH', 'Firma', 'Outputs', 'Doklad', 'TypDPH', 'TabService',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, SupisDPH, Firma, Outputs, Doklad, TypDPH, TabService) {
  $scope.slideAnimation = true;

  var defaultSort = {sort: 'dd,iddokd', reverse: false};
  $scope.supisdph = [];

  var filterChanged = function(filter) {
    var promise = SupisDPH.objects().getList(filter);
    $scope.supisdph.loading = true;
    promise.then(function(data) {
      TabService.registerScope('uctdennik', $scope);
      $scope.supisdph = data;
      SupisDPH.objects().getList(angular.extend(angular.copy(filter), {medzisumy: true})).then(function(sumData) {
        var sums = {};
        _.each(sumData, function(obj) {
          sums[obj.typ_dph] = obj;
        });
        var lastTyp = undefined;
        var sameTypCount = 1;
        var allData = angular.copy(data);
        var medzisumyCount = 0;
        for (var i = 0; i < allData.length; i++) {
          if (lastTyp && allData[i].typ_dph === lastTyp.id) {
            sameTypCount++;
          }
          if (lastTyp && lastTyp.id !== allData[i].typ_dph) {
            sameTypCount = 1;
            $scope.supisdph.splice(i+medzisumyCount, 0, angular.extend({typ_dph: lastTyp.typ, medzisuma: true, typdph_data: { typ: lastTyp.typ }}, sums[lastTyp.id]));
            medzisumyCount++;
          }
          lastTyp = allData[i].typdph_data;
        }
        if (lastTyp && (sums[lastTyp.id].count === sameTypCount || !data.nextPage)) {
          $scope.supisdph.splice(i+medzisumyCount, 0, angular.extend({typ_dph: lastTyp.typ, medzisuma: true, typdph_data: { typ: lastTyp.typ }}, sums[lastTyp.id]));
        }
      });
    });
    return promise;
  };
  var unregisterAuthObservers = [];

  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;

  $scope.currentView = 0;
  $scope.supisDPHTable = [
    [
      { },
      { field: 'iddokd', sortField: 'dd,iddokd', label: 'ucto.RECORD', width: 90, type: 'int', filterCollapse: 'right' },
      { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains', filterCollapse: 'right' },
      { field: 'd_zdp', label: 'ucto.SUPPLIED', width: 90, type: 'date' },
      { field: 'd_uctn', label: 'ucto.UCTOVANIE', width: 95, type: 'date' },
      { field: 'firma__firma', label: 'firmy.FIRMA', minWidth: 200, placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'firma__icdph', label: 'agendy.ICDPH', width: 105 },
      { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70, sortable: false },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true }
    ],
    []
  ];

  var setObdobia = function() {
    $scope.obdobia = Profile.get('obdobia');
    if ($scope.obdobia && $scope.obdobia.length) {
      $scope.obdobie = (Profile.get('obdobie') && _.find($scope.obdobia, {rok: Profile.get('obdobie')})) ? {rok: Profile.get('obdobie')} : $scope.obdobia[0];
    }
  };
  Profile.addObserver('obdobia', setObdobia);
  setObdobia();
  $scope.mesiace = [
    {id: 1, mesiac: 'Január'},
    {id: 2, mesiac: 'Február'},
    {id: 3, mesiac: 'Marec'},
    {id: 4, mesiac: 'Apríl'},
    {id: 5, mesiac: 'Máj'},
    {id: 6, mesiac: 'Jún'},
    {id: 7, mesiac: 'Júl'},
    {id: 8, mesiac: 'August'},
    {id: 9, mesiac: 'September'},
    {id: 10, mesiac: 'Október'},
    {id: 11, mesiac: 'November'},
    {id: 12, mesiac: 'December'},
  ];
  $scope.typydph = TypDPH.valid().objects().getList().$object;
  $scope.typy = [
    {id: 1, typ: 'Vstup aj výstup'},
    {id: 2, typ: 'Výstup'},
    {id: 3, typ: 'Vstup'}
  ];

  $scope.selectedMonth = function(value) {
    if ($scope.SearchCtrl.filters.top.stvrtrok) {
      $scope.SearchCtrl.filterSelection('stvrtrok', null, false);
    }
  };

  $scope.selectedStvrtrok = function(value) {
    if ($scope.SearchCtrl.filters.top.mesiac) {
      $scope.SearchCtrl.filterSelection('mesiac', null, false);
    }
  };

  $scope.selectedRok = function(value) {
    var selected = undefined;
    // kedze sa stvrtrok trackuje podla atributu datum, zistim si ak bol vybrany a premapujem ho na novy s upravenym rokom
    if ($scope.SearchCtrl.filters.top.stvrtrok) {
      selected = _.find($scope.stvrtroky, {datum: $scope.SearchCtrl.filters.top.stvrtrok});
      if (selected) {
        selected = selected.id;
      }
    }
    _.each($scope.stvrtroky, function(obj) {
      obj.datum = value.rok+'-'+('0'+((obj.id-1)*3+1)).slice(-2)+'-01';
    });
    if (selected) {
      $scope.SearchCtrl.filterSelection('stvrtrok', _.find($scope.stvrtroky, {id: selected}).datum, false);
    }
  };
  // aby neukazovalo tlacitko zrusit filter, ked je jeden z tychto filtrov
  $scope.cancelFilterExcludes = ['mesiac', 'stvrtrok', 'rok', 'typ', 'typ_dph'];

  $scope.sumLabels = ['Vstup aj výstup', 'Výstup', 'Vstup', 'DPH výstup - vstup'];
  $scope.sumClasses = ['warning', 'info', 'info', 'active'];

  $scope.SearchCtrl.init({
    model: SupisDPH,
    onChange: filterChanged,
    columnsDef: $scope.supisDPHTable[0],
    sort: defaultSort
  });

  var mesiac = new Date().getMonth() || 12;
  var rok = $scope.obdobie.rok || new Date().getFullYear();
  if (mesiac === 12 && $scope.obdobie.rok === new Date().getFullYear()) {
    rok--;
  }

  $scope.SearchCtrl.filterSelection('typ', 1, false);
  $scope.SearchCtrl.filterSelection('rok', rok, false);
  $scope.SearchCtrl.filterSelection('mesiac', mesiac, false);
  $scope.stvrtroky = [
    {id: 1, stvrtrok: '1', datum: $scope.SearchCtrl.getSelectionValue('rok')+'-01-01'},
    {id: 2, stvrtrok: '2', datum: $scope.SearchCtrl.getSelectionValue('rok')+'-04-01'},
    {id: 3, stvrtrok: '3', datum: $scope.SearchCtrl.getSelectionValue('rok')+'-07-01'},
    {id: 4, stvrtrok: '4', datum: $scope.SearchCtrl.getSelectionValue('rok')+'-10-01'}
  ];
  $scope.SearchCtrl.refresh();

  $scope.printList = function(zostava) {
    Outputs.openPrintUrl('supisdph', null, $scope.SearchCtrl.getQuery(), zostava);
  };
  $scope.vykazy = [
    {id: 'dph', vykaz: 'DPH'},
    {id: 'kv', vykaz: 'Kontrolný výkaz DPH'},
    {id: 'sv', vykaz: 'Súhrnný výkaz DPH'}
  ];
  $scope.vykazSelected = function(value) {
    Outputs.openXMLExportUrl('supisdph', value.id, $scope.SearchCtrl.getQuery());
  };

  $scope.editDoklad = function(doklad) {
    Doklad.editRecord(doklad, null);
  };

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    filterChanged($scope.SearchCtrl.getQuery());
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    $scope.SearchCtrl.filterSelection('rok', obdobie);
  }));

  var priuctuj = function(data, record) {
    var changedSomething = 0;
    var recordDate = parseDate(record.d_zdp);
    if (recordDate.getFullYear() !== $scope.obdobie.rok) {
      return changedSomething;
    }
    var spl, field, typ, ucet, ucetField;
    angular.forEach(record.uctovanie, function(value, key) {
      if (changedSomething === 2) {
        return;
      }
      if (value) {
        changedSomething = 1;
        spl = key.split('_');
        typ = spl[spl.length-1];
        spl.splice(spl.length-1);
        field = spl.join('_');
        if (typ !== 'popis') {
          ucet = _.find(data, {id: value});
          if (!ucet) {
            changedSomething = 2;
            return;
          }
          ucetField = 'm'+('0'+(recordDate.getMonth()+1)).slice(-2)+typ;
          ucet[ucetField] = parseFloat(ucet[ucetField]) + parseFloat(record[field]);
          vymazNulovy(data, ucet);
        }
      }
    });
    return changedSomething;
  };
  var oductuj = function(data, record) {
    var changedSomething = 0;
    var recordDate = parseDate(record.d_zdp);
    if (recordDate.getFullYear() !== $scope.obdobie.rok) {
      return changedSomething;
    }
    var spl, field, typ, ucet, ucetField;
    angular.forEach(record.uctovanie, function(value, key) {
      if (changedSomething === 2) {
        return;
      }
      if (value) {
        changedSomething = 1;
        spl = key.split('_');
        typ = spl[spl.length-1];
        spl.splice(spl.length-1);
        field = spl.join('_');
        if (typ !== 'popis') {
          ucet = _.find(data, {id: value});
          if (!ucet) {
            changedSomething = 2;
            return;
          }
          ucetField = 'm'+('0'+(recordDate.getMonth()+1)).slice(-2)+typ;
          ucet[ucetField] = parseFloat(ucet[ucetField]) - parseFloat(record[field]);
          vymazNulovy(data, ucet);
        }
      }
    });
    return changedSomething;
  };

  var sumy = ['spolu', 'zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0'];
  var events = [];
  var createHandler = function(data, observer) {
    if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
      return;
    }
    $scope.SearchCtrl.refresh();
  };
  var changeHandler = function(data, observer) {
    if (angular.isDefined(data.obj.agenda) && Profile.get('agenda').id !== data.obj.agenda) {
      return;
    }
    if (!objEquals(data.original, data.obj)) {
      var item = _.find($scope.supisdph, {id: data.obj.id});
      if (item) {
        var diff = objDiffRecursive(data.obj, data.original);
        // make changes only if sums changed, otherwise refresh
        if (_.every(diff, function(obj) { return sumy.indexOf(obj.field) !== -1; })) {
          var sumline = _.find($scope.supisdph, {medzisuma: true, typ_dph: data.obj.typ_dph});
          if (sumline) {
            angular.copy(data.obj, _.find($scope.supisdph, {id: data.obj.id}));
            for (var i in sumy) {
              sumline[sumy[i]] = parseFloat(sumline[sumy[i]]) - parseFloat(data.original[sumy[i]]);
              sumline[sumy[i]] = parseFloat(sumline[sumy[i]]) + parseFloat(data.obj[sumy[i]]);
            }
            $scope.SearchCtrl.emitSumsChange();
          } else {
            $scope.SearchCtrl.refresh();
          }
        } else {
          $scope.SearchCtrl.refresh();
        }
      } else {
        $scope.SearchCtrl.refresh();
      }
    }
  };
  var removeHandler = function(data) {
    if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
      return;
    }
    var sumline = _.find($scope.supisdph, {medzisuma: true, typ_dph: parseInt(data.typ_dph)});
    $scope.SearchCtrl.emitSumsChange(null, data);
    $scope.SearchCtrl.tableRemove($scope.supisdph, data);
    if (sumline) {
      sumline[sumy[i]] = parseFloat(sumline[sumy[i]]) - parseFloat(data[sumy[i]]);
      sumline.count = parseInt(sumline.count)-1;
      if (sumline.count === 0) {
        $scope.supisdph.splice($scope.supisdph.indexOf(sumline), 1);
      }
      $scope.SearchCtrl.emitSumsChange();
    }
  };
  var endpoints = ['faktury', 'zavazky', 'pokladna', 'banka', 'interne', 'penaznydennik'];

  for (var i in endpoints) {
    events.push(Socket.on(endpoints[i]+"Created", function(data) {
      createHandler(data, true);
    }));
    events.push(Socket.on(endpoints[i]+"Changed", function(data) {
      changeHandler(data, true);
    }));
    events.push(Socket.on(endpoints[i]+"Removed", function(data) {
      removeHandler(data);
    }));
  }
  events.push($scope.$on('casist:dataChanged', function(event, data) {
    if (endpoints.indexOf(data.type) === -1) {
      return;
    }
    switch (data.operation) {
      case 1:
        createHandler(data.payload, false);
        break;
      case 2:
        changeHandler(data.payload, false);
        break;
      case 3:
        removeHandler(data.payload);
        break;
      default:
        break;
    }
  }));
  $scope.$on('$destroy', function() {
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
    for (var i in events) {
      events[i]();
    }
  });
}]);
