"use strict";

angular.module("casist.ucto").controller("UrgencieDetailCtrl", [
  "$scope",
  "$modalInstance",
  "$controller",
  "Urgencia",
  "SearchCtrl",
  "urgencia",
  "urgencie",
  "DokladTypy",
  "Profile",
  "UrgenciaRozpis",
  "UrgenciaText",
  "Firma",
  "$translate",
  "$timeout",
  "Doklad",
  "Outputs",
  function (
    $scope,
    $modalInstance,
    $controller,
    Urgencia,
    SearchCtrl,
    urgencia,
    urgencie,
    DokladTypy,
    Profile,
    UrgenciaRozpis,
    UrgenciaText,
    Firma,
    $translate,
    $timeout,
    Doklad,
    Outputs
  ) {
    $scope.RecordCtrl = $controller("RecordCtrl", {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Urgencia,
    });

    var defaultUrgencia = {
      datum: getCurrentDate(),
      hlavicka: $translate.instant("urgencie.URGENCIA"),
    };

    $scope.Firma = Firma;
    $scope.UrgenciaText = UrgenciaText;
    $scope.Urgencia = Urgencia;

    var pohybFilterChanged = function (filter) {
      $scope.RecordCtrl.related.renderListPage($scope.sorter);
    };
    var pohybPostSave = function () {
      $scope.urgencia.recountSums($scope.rozpis);
    };
    var pohybSearchCtrl = $controller("SearchCtrl");
    pohybSearchCtrl.init({
      onChange: pohybFilterChanged,
    });
    $scope.sorter = { sort: "id", reverse: false };

    $scope.rozpisColumns = [
      { width: 60 },
      { field: "vs", label: "fakturacia.VS", width: 120 },
      { field: "d_splat", label: "fakturacia.SPLATNOST" },
      { field: "spolu", label: "fakturacia.SPOLU", width: 100 },
      { field: "uhradene", label: "pokladna.UHRADENE", width: 100 },
      { field: "dni", label: "Dni", width: 60 },
      { field: "urok", label: "Úrok", width: 80 },
      { field: "kuhrade", label: "fakturacia.KUHRADE", width: 100 },
      { width: 30 },
    ];
    $scope.rozpis = [];

    $scope.RecordCtrl.main.init({
      endpoint: "urgencie",
      model: "urgencia",
      master: urgencia,
      list: urgencie,
      defaultItem: defaultUrgencia,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance,
    });
    $scope.RecordCtrl.related.init({
      model: "rozpis",
      lookup: "urgencia",
      object: UrgenciaRozpis,
      // preSave: pohybPreSave,
      postSave: pohybPostSave,
      // preEdit: pohybPreEdit,
      postDelete: pohybPostSave,
      searchCtrl: pohybSearchCtrl,
    });

    if (!isEmpty(urgencia)) {
      $scope.urgencia = Urgencia.copy(urgencia);
      $scope.urgencia.getList("urgenciarozpis").then(function (rozpis) {
        _.each(rozpis, function (obj) {
          obj.k_uhrade =
            parseFloat(obj.spolu) -
            parseFloat(obj.uhradene) +
            parseFloat(obj.urok);
        });
        $scope.RecordCtrl.related.paginateList(rozpis, 20, $scope.sorter);

        // $timeout(function() {
        //   $scope.recountSums();
        // });
      });
    } else {
      $scope.urgencia = angular.copy(Urgencia.restangularize(defaultUrgencia));
    }

    var layouts = {
      urgencieHlavicka: _.find(Profile.get("layouts"), {
        layout_id: "urgencie_hlavicka",
      }),
      urgenciePohyb: _.find(Profile.get("layouts"), {
        layout_id: "urgencie_pohyb",
      }),
    };
    $scope.panels = [
      angular.isDefined(layouts.urgencieHlavicka)
        ? layouts.urgencieHlavicka.value
        : false,
      angular.isDefined(layouts.urgenciePohyb)
        ? layouts.urgenciePohyb.value
        : true,
    ];
    $scope.togglePanel = function (panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = "urgencie_" + (panel === 0 ? "hlavicka" : "pohyb");
      var layout = _.find(Profile.get("layouts"), { layout_id: layoutID });
      if (!angular.isDefined(layout)) {
        Profile.get("layouts").push({
          layout_id: layoutID,
          value: $scope.panels[panel],
        });
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    var maxDate = function (date1, date2) {
      if (date1 > date2) {
        return date1;
      } else {
        return date2;
      }
    };

    var countUroky = function (faktura) {
      if ($scope.urgencia.urok && $scope.urgencia.uroky_od) {
        var od = parseDate(maxDate(faktura.d_splat, $scope.urgencia.uroky_od));
        if (parseFloat(faktura.spolu) === parseFloat(faktura.uhradene)) {
          faktura.dni = "...";
          faktura.urok = "...";
        } else {
          faktura.dni = Math.floor(
            (parseDate(
              $scope.urgencia.uroky_do ||
                $scope.urgencia.datum ||
                getCurrentDate()
            ) -
              od) /
              3600000 /
              24
          );
          faktura.urok =
            Math.round(
              (parseFloat(faktura.spolu) - parseFloat(faktura.uhradene)) *
                (parseFloat($scope.urgencia.urok) / 36500.0) *
                parseFloat(faktura.dni) *
                100
            ) / 100.0;
        }
      } else {
        faktura.dni = 0;
        faktura.urok = 0;
      }
      return faktura;
    };
    $scope.recountUroky = function () {
      $timeout(function () {
        var list = $scope.RecordCtrl.related.getFullList();
        for (var i = 0; i < list.length; i++) {
          list[i] = countUroky(list[i]);
        }
      });
    };

    $scope.selectedFaktura = function (obj) {
      $timeout(function () {
        var faktura = {
          doklad: angular.copy($scope.newUrgencia.id),
          doklad_data: angular.copy($scope.newUrgencia),
        };
        angular.extend(
          faktura,
          _.pick($scope.newUrgencia, "d_splat", "vs", "spolu", "uhradene")
        );
        faktura = countUroky(faktura);
        faktura.k_uhrade =
          Math.round(
            (parseFloat(faktura.spolu) -
              parseFloat(faktura.uhradene) +
              parseFloat(faktura.urok || 0)) *
              100
          ) / 100.0;
        $scope.RecordCtrl.related.add(faktura);
        $scope.newUrgenciaID = undefined;
      });
    };

    $scope.getNeuhradene = function (query, pageQuery) {
      return Urgencia.neuhradene().getList(
        angular.extend(
          {
            exclude: _.map(
              $scope.RecordCtrl.related.getFullList(),
              (urgencia) => urgencia.doklad
            ),
            firma: $scope.urgencia.firma,
            search: query,
          },
          pageQuery
        )
      );
    };

    $scope.otvoritDoklad = function (item) {
      Doklad.editRecord(item.doklad_data).then(function (data) {
        var original = angular.copy(item);
        angular.copy(data, item.doklad_data);
        angular.extend(
          item,
          _.pick(data, "d_splat", "vs", "spolu", "uhradene")
        );
        item = countUroky(item);
        item.k_uhrade =
          Math.round(
            (parseFloat(item.spolu) -
              parseFloat(item.uhradene) +
              parseFloat(item.urok || 0)) *
              100
          ) / 100.0;
        if (!angular.equals(original, item)) {
          $scope.RecordCtrl.related.edit(item);
        }
      });
    };
    $scope.tlacitDoklad = function (item) {
      Outputs.openPrintUrl("faktury", item.doklad);
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({ type: "removed", item: urgencia });
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function (result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };
  },
]);
