'use strict';

angular.module('casist.admin')
  .factory('CislaDokladov', ['Restangular', 'BaseModel', 'BaseCollectionModel', function (Restangular, BaseModel, BaseCollectionModel) {
    Restangular.extendModel('cisladokladov', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          return BaseModel.validate();
        }
      });
    });
    return BaseCollectionModel.object('settings/cisladokladov');
  }]);
