"use strict";

angular.module("casist.ucto").controller("PrehladCtrl", [
  "$scope",
  "PrehladNakladov",
  "$controller",
  "Profile",
  "$translate",
  "Karta",
  "Outputs",
  "Socket",
  "TabService",
  "Notification",
  "$timeout",
  "Dialog",
  "$modal",
  function (
    $scope,
    PrehladNakladov,
    $controller,
    Profile,
    $translate,
    Karta,
    Outputs,
    Socket,
    TabService,
    Notification,
    $timeout,
    Dialog,
    $modal
  ) {
    $scope.prehladnakladov = [];
    var lastLoadedData = undefined;
    var mesiac = new Date().getMonth() + 1;
    var loadData = function (data) {
      lastLoadedData = data;
      $scope.prehladnakladov = [];
      $scope.sums = {
        psmd: 0,
        psd: 0,
        ksmd: 0,
        ksd: 0,
        kumulativmd: 0,
        kumulativd: 0,
        mesiacmd: 0,
        mesiacd: 0,
        psrozdiel: 0,
        kumulativrozdiel: 0,
        mesiacrozdiel: 0,
        ksrozdiel: 0,
      };
      for (var i = 0; i < data.length; i++) {
        var rozuct = Karta.getSposob(data[i].sposob_rozuctovania);
        data[i].sposob_rozuctovania = rozuct ? rozuct.sposob : "";
        data[i].psmd = parseFloat(data[i].psmd);
        data[i].psd = parseFloat(data[i].psd);
        data[i].kumulativmd = data[i].psmd;
        data[i].kumulativd = data[i].psd;
        for (var j = 1; j < mesiac; j++) {
          data[i].kumulativmd += parseFloat(
            data[i]["m" + ("0" + j).slice(-2) + "md"]
          );
          data[i].kumulativd += parseFloat(
            data[i]["m" + ("0" + j).slice(-2) + "d"]
          );
        }
        data[i].mesiacmd = parseFloat(
          data[i]["m" + ("0" + mesiac).slice(-2) + "md"]
        );
        data[i].mesiacd = parseFloat(
          data[i]["m" + ("0" + mesiac).slice(-2) + "d"]
        );
        data[i].ksmd = data[i].mesiacmd + data[i].kumulativmd;
        data[i].ksd = data[i].mesiacd + data[i].kumulativd;
        data[i].psrozdiel = data[i].psmd - data[i].psd;
        data[i].kumulativrozdiel = data[i].kumulativmd - data[i].kumulativd;
        data[i].mesiacrozdiel = data[i].mesiacmd - data[i].mesiacd;
        data[i].ksrozdiel = data[i].ksmd - data[i].ksd;

        $scope.prehladnakladov.push(angular.copy(data[i]));
        angular.forEach($scope.sums, function (value, key) {
          $scope.sums[key] += data[i][key];
        });
      }
      $scope.SearchCtrl.broadcastRefresh("prehladnakladov");
    };
    var updateTableTranslations = function (filter) {
      mesiac = filter.mesiac || new Date().getMonth() + 1;
      //_.find($scope.prehladTable, {field: 'ps'}).translationData.rok = $scope.SearchCtrl.getSelectionValue('rok');
      _.find($scope.prehladTable, {
        field: "mesiac",
      }).translationData.mesiac = $translate.instant(
        "mesiace." + monthToName(mesiac)
      );
    };
    var filterChanged = function (filter) {
      var promise = PrehladNakladov.objects().getList(filter);
      updateTableTranslations(filter);

      promise.then(function (data) {
        loadData(data);
        TabService.registerScope("prehladnakladov", $scope);
      });
      return promise;
    };

    var unregisterAuthObservers = [];
    unregisterAuthObservers.push(
      Profile.addObserver("obdobie", function (obdobie) {
        $scope.SearchCtrl.filterSelection(
          "rok",
          Profile.get("obdobie", new Date().getFullYear())
        );
      })
    );
    $scope.SearchCtrl = $controller("SearchCtrl");

    var setObdobia = function () {
      $scope.obdobia = Profile.get("obdobia");
      if ($scope.obdobia && $scope.obdobia.length) {
        $scope.obdobie =
          Profile.get("obdobie") &&
          _.find($scope.obdobia, { rok: Profile.get("obdobie") })
            ? { rok: Profile.get("obdobie") }
            : $scope.obdobia[0];
      }
    };
    Profile.addObserver("obdobia", setObdobia);
    setObdobia();
    $scope.mesiace = [
      { id: 1, mesiac: "Január" },
      { id: 2, mesiac: "Február" },
      { id: 3, mesiac: "Marec" },
      { id: 4, mesiac: "Apríl" },
      { id: 5, mesiac: "Máj" },
      { id: 6, mesiac: "Jún" },
      { id: 7, mesiac: "Júl" },
      { id: 8, mesiac: "August" },
      { id: 9, mesiac: "September" },
      { id: 10, mesiac: "Október" },
      { id: 11, mesiac: "November" },
      { id: 12, mesiac: "December" },
    ];

    $scope.prehladTable = [
      { width: 30, rowspan: 2 },
      {
        field: "karta.kod",
        label: "karty.KOD",
        rowspan: 2,
        width: 100,
        filterCollapse: "right",
      },
      { field: "karta.nazov", label: "karty.NAZOV", rowspan: 2 },
      {
        field: "karta.sposob_rozuctovania",
        label: "karty.SPOSOB_ROZUCTOVANIA",
        rowspan: 2,
      },
      //{ field: 'ps', label: 'uctosnova.PS', translationData: {rok: ''}, colspan: 3, class: 'text-center', searchable: false, sortable: false, sum: true },
      {
        field: "kumulativ",
        label: "uctosnova.KUMULATIV",
        colspan: 3,
        class: "text-center",
        searchable: false,
        sortable: false,
        sum: true,
      },
      {
        field: "mesiac",
        label: "uctosnova.OBRAT",
        colspan: 3,
        class: "text-center",
        translationData: { mesiac: "" },
        searchable: false,
        sortable: false,
        sum: true,
      },
      {
        field: "ks",
        label: "uctosnova.KSMD",
        colspan: 3,
        class: "text-center",
        searchable: false,
        sortable: false,
        sum: true,
      },
    ];
    $scope.SearchCtrl.init({
      model: PrehladNakladov,
      onChange: filterChanged,
      columnsDef: $scope.prehladTable,
      sort: { sort: "karta.kod", reverse: false },
      scope: $scope,
    });

    $scope.SearchCtrl.filterSelection("rok", $scope.obdobie.rok, false);
    $scope.SearchCtrl.filterSelection("mesiac", new Date().getMonth() + 1);

    $scope.editKarta = function (item) {
      Karta.objects()
        .one(item.karta)
        .get()
        .then(function (karta_data) {
          Karta.edit(karta_data).then(function (data) {
            filterChanged($scope.SearchCtrl.getQuery());
          });
        });
    };

    $scope.printList = function () {
      Outputs.openPrintUrl("hlavnakniha", null, $scope.SearchCtrl.getQuery());
    };
    $scope.printListPodrobne = function () {
      Outputs.openPrintUrl(
        "hlavnakniha",
        null,
        $scope.SearchCtrl.getQuery(),
        "print-podrobne"
      );
    };

    var vymazNulovy = function (data, ucet) {
      var isNull =
        parseFloat(ucet["psmd"]) === 0 && parseFloat(ucet["psd"]) === 0;
      if (isNull) {
        for (var j = 1; j <= 12; j++) {
          if (
            parseFloat(ucet["m" + ("0" + j).slice(-2) + "md"]) !== 0 ||
            parseFloat(ucet["m" + ("0" + j).slice(-2) + "d"]) !== 0
          ) {
            isNull = false;
            break;
          }
        }
      }
      if (isNull) {
        data.splice(data.indexOf(ucet), 1);
      }
    };

    var priuctuj = function (data, record) {
      var changedSomething = 0;
      var recordDate = parseDate(record.d_zdp);
      if (recordDate.getFullYear() !== $scope.obdobie.rok) {
        return changedSomething;
      }
      var spl, field, typ, ucet, ucetField;
      angular.forEach(record.uctovanie, function (value, key) {
        if (changedSomething === 2) {
          return;
        }
        if (value) {
          changedSomething = 1;
          spl = key.split("_");
          typ = spl[spl.length - 1];
          spl.splice(spl.length - 1);
          field = spl.join("_");
          if (typ !== "popis") {
            ucet = _.find(data, { id: value });
            if (!ucet) {
              changedSomething = 2;
              return;
            }
            ucetField =
              "m" + ("0" + (recordDate.getMonth() + 1)).slice(-2) + typ;
            ucet[ucetField] =
              parseFloat(ucet[ucetField]) + parseFloat(record[field]);
            vymazNulovy(data, ucet);
          }
        }
      });
      return changedSomething;
    };
    var oductuj = function (data, record) {
      var changedSomething = 0;
      var recordDate = parseDate(record.d_zdp);
      if (recordDate.getFullYear() !== $scope.obdobie.rok) {
        return changedSomething;
      }
      var spl, field, typ, ucet, ucetField;
      angular.forEach(record.uctovanie, function (value, key) {
        if (changedSomething === 2) {
          return;
        }
        if (value) {
          changedSomething = 1;
          spl = key.split("_");
          typ = spl[spl.length - 1];
          spl.splice(spl.length - 1);
          field = spl.join("_");
          if (typ !== "popis") {
            ucet = _.find(data, { id: value });
            if (!ucet) {
              changedSomething = 2;
              return;
            }
            ucetField =
              "m" + ("0" + (recordDate.getMonth() + 1)).slice(-2) + typ;
            ucet[ucetField] =
              parseFloat(ucet[ucetField]) - parseFloat(record[field]);
            vymazNulovy(data, ucet);
          }
        }
      });
      return changedSomething;
    };

    var events = [];
    var createHandler = function (data, observer) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      if (!isEmpty(data.uctovanie)) {
        var newData = angular.copy(lastLoadedData);

        var changed = priuctuj(newData, data);

        if (changed === 2) {
          filterChanged($scope.SearchCtrl.getQuery());
          return;
        }

        if (changed) {
          loadData(newData);
        }
      }
    };
    var changeHandler = function (data, observer) {
      if (
        angular.isDefined(data.obj.agenda) &&
        Profile.get("agenda").id !== data.obj.agenda
      ) {
        return;
      }
      if (!objEquals(data.original, data.obj)) {
        var newData = angular.copy(lastLoadedData);

        var changedOld = oductuj(newData, data.original);
        var changedNew = priuctuj(newData, data.obj);

        if (changedOld === 2 || changedNew === 2) {
          filterChanged($scope.SearchCtrl.getQuery());
          return;
        }

        if (changedOld || changedNew) {
          loadData(newData);
        }
      }
    };
    var removeHandler = function (data) {
      if (
        angular.isDefined(data.agenda) &&
        Profile.get("agenda").id !== data.agenda
      ) {
        return;
      }
      if (!isEmpty(data.uctovanie)) {
        var newData = angular.copy(lastLoadedData);

        if (oductuj(newData, data)) {
          loadData(newData);
        }
      }
    };
    var endpoints = ["faktury", "zavazky", "pokladna", "banka", "interne"];

    for (var i in endpoints) {
      events.push(
        Socket.on(endpoints[i] + "Created", function (data) {
          createHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Changed", function (data) {
          changeHandler(data, true);
        })
      );
      events.push(
        Socket.on(endpoints[i] + "Removed", function (data) {
          removeHandler(data);
        })
      );
    }
    events.push(
      $scope.$on("casist:dataChanged", function (event, data) {
        if (endpoints.indexOf(data.type) === -1) {
          return;
        }
        switch (data.operation) {
          case 1:
            createHandler(data.payload, false);
            break;
          case 2:
            changeHandler(data.payload, false);
            break;
          case 3:
            removeHandler(data.payload);
            break;
          default:
            break;
        }
      })
    );

    $scope.openPohyb = function (kod) {
      TabService.bus.send("pohyb", "prehladsvb", { kod: kod });
      TabService.openTab("pohyb");
    };

    $scope.$on("$destroy", function () {
      for (var i in unregisterAuthObservers) {
        unregisterAuthObservers[i]();
      }
      for (var i in events) {
        events[i]();
      }
    });
  },
]);
