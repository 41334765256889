'use strict';

angular.module('casist.ucto').controller('InternyDokladDetailCtrl',
              ['$scope', '$modalInstance', 'faktura', 'faktury', 'config', '$timeout', '$modal', 'Global', '$http', '$controller', 'Profile', 'SearchCtrl', 'InternyDoklad', 'Prevadzka', 'Firma', 'TypDPH', 'Predkontacia', '$q', 'Pohyb', 'DokladTypy', 'ZavazkyTyp', 'Ucet', 'Mena',
              function ($scope, $modalInstance, faktura, faktury, config, $timeout, $modal, Global, $http, $controller, Profile, SearchCtrl, InternyDoklad, Prevadzka, Firma, TypDPH, Predkontacia, $q, Pohyb, DokladTypy, ZavazkyTyp, Ucet, Mena) {

    $scope.hasList = faktury.length !== 0;

    if (config.predkontacie) {
      $scope.Predkontacia = Predkontacia;
      $scope.getPredkontacie = function(query) {
        return Predkontacia.get(query, DokladTypy.IN.dd);
      };
      $scope.addPredkontacia = function(nazov) {
        return Predkontacia.add(nazov, DokladTypy.IN.dd, $scope.doklad.uctovanie, undefined, $scope.doklad.popis);
      };
    }
    if (config.ucty) {
      var uctyQueries = [];
      Ucet.get().then(function(ucty) {
        $scope.ucty = ucty;

        $scope.getUcty = function(query) {
          var re = new RegExp(query, 'i');
          var matches = [];
          for (var i = 0; i < $scope.ucty.length; i++) {
            if ($scope.ucty[i].ucet.match(query) || $scope.ucty[i].nazov.match(re)) {
              matches.push(angular.copy($scope.ucty[i]));
            }
          }
          return matches;
        };
        var result;
        for (var i = 0; i < uctyQueries.length; i++) {
          result = $scope.getUcty(uctyQueries[i].query);
          uctyQueries[i].deferred.resolve(result);
        }
      });
      $scope.getUcty = function(query) {
        var deferred = $q.defer();
        uctyQueries.push({query: query, deferred: deferred});
        return deferred.promise;
      };

      $scope.addUcet = function(ucet) {
        var promise = Ucet.add(ucet);
        promise.then(function(data) {
          $scope.ucty.push(angular.copy(data));
        });
        return promise;
      };
    }
    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: InternyDoklad
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      'internePohyb': _.find(Profile.get('layouts'), {layout_id: 'interne_pohyb'}),
      'interneRozuctovanie': _.find(Profile.get('layouts'), {layout_id: 'interne_rozuct'})
    }
    $scope.panels = [angular.isDefined(layouts.internePohyb) ? layouts.internePohyb.value : true, false, angular.isDefined(layouts.interneRozuctovanie) ? layouts.interneRozuctovanie.value : true];
    $scope.togglePanel = function(panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = 'interne_' + (panel === 0 ? 'pohyb' : 'rozuct');
      var layout = _.find(Profile.get('layouts'), {layout_id: layoutID});
      if (!angular.isDefined(layout)) {
        Profile.get('layouts').push({layout_id: layoutID, value: $scope.panels[panel]});
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function() {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.interneRozuctovanie.value) {
        $scope.panels[2] = layouts.interneRozuctovanie.value;
      }
    };

    var typDPHMinPlatnost = new Date(2099, 12, 31), typDPHMaxPlatnost = new Date(1970, 1, 1);
    var updateTypDPH = function(datum) {
      TypDPH.getValid(datum, '1,2,0').then(function(data) {
        typDPHMaxPlatnost = data.maxPlatnost;
        typDPHMinPlatnost = data.minPlatnost;
        $scope.typyDPH = data.data;
        // if ($scope.doklad.dd === DokladTypy.ID.dd) {
        //   if (data.data.length && (!$scope.doklad.typ_dph || !_.find(data.data, {id: $scope.doklad.typ_dph}))) {
        //     $scope.doklad.typ_dph = data.data[0].id;
        //   } else if ($scope.doklad.id) {
        //     $scope.doklad.typ_dph = faktura.typ_dph;
        //   }
        // }
        if (data.data.length && !angular.isDefined(defaultDoklad.typ_dph)) {
          // defaultDoklad.typ_dph = data.data[0].id;
        }
      });
    };
    $scope.$watch('faktura.d_vyst', function(val, oldVal) {
      if (val) {
        var date = parseDate(val);
        if (!isNaN(date) && (date < typDPHMinPlatnost || date > typDPHMaxPlatnost)) {
          // updateTypDPH(val);
        }
      }
    });
    $scope.$watch('faktura.typ_dph', function(val) {
      if ($scope.doklad.dd === DokladTypy.DF.dd) {
        if (val) {
          $scope.doklad.typdph_data = _.find($scope.typyDPH, {id: parseInt(val)});
          if (!$scope.doklad.id && !angular.isDefined(defaultDoklad.typdph_data)) {
            defaultDoklad.typdph_data = $scope.doklad.typdph_data;
          }
        } else {
          $scope.doklad.typdph_data = undefined;
        }
      }
    });
    updateTypDPH(getCurrentDate());
    $scope.setUplatDPH = function() {
      $scope.doklad.d_uctn = $scope.doklad.d_zdp;
      $scope.doklad.uplat_dph = $scope.doklad.d_zdp;
    };
    $scope.selectedPredkontacia = function(predkontacia) {
      var fields = InternyDoklad.getUctoFields();
      $scope.doklad.uctovanie = {};
      var foundAllUcty = true;
      for (var i = 0; i < fields.length; i++) {
        if (predkontacia[fields[i]+'_md'] && !_.find($scope.ucty, {id: predkontacia[fields[i]+'_md']})) {
          foundAllUcty = false;
        }
        if (predkontacia[fields[i]+'_d'] && !_.find($scope.ucty, {id: predkontacia[fields[i]+'_d']})) {
          foundAllUcty = false;
        }
      }
      var prekopirovatUctovanie = function() {
        for (var i = 0; i < fields.length; i++) {
          $scope.doklad.uctovanie[fields[i]+'_md'] = predkontacia[fields[i]+'_md'];
          $scope.doklad.uctovanie[fields[i]+'_d'] = predkontacia[fields[i]+'_d'];
          $scope.doklad.uctovanie[fields[i]+'_popis'] = predkontacia[fields[i]+'_popis'];
        }
      };
      // aktualizovat ucty v pamati, ak tam nejaky z predkontacie nepozname
      if (!foundAllUcty) {
        Ucet.get().then(function(ucty) {
          $scope.ucty = ucty;
          prekopirovatUctovanie();
        });
      } else {
        prekopirovatUctovanie();
      }
      if (!$scope.doklad.popis) {
        $scope.doklad.popis = predkontacia.popis;
      }
      if ($scope.showVATfields && predkontacia.typ_dph) {
        $scope.doklad.typ_dph = predkontacia.typ_dph;
      }
    };

    $scope.prevadzky = Prevadzka.restangularize(angular.copy(Profile.get('prevadzky')));

    var defaultDoklad = {
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      d_uctn: getCurrentDate(),
      uplat_dph: getCurrentDate(),
      prevadzka: Profile.get('lastPrevadzka').id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      sd1: Global.get('sd1'),
      sd2: Global.get('sd2'),
      rozuctovanie: []
    };

    var updateShowVAT = function() {
      // if (!angular.isDefined(config.VATfields)) {
      //   $scope.showVATfields = $scope.doklad.id ? $scope.doklad.dd === DokladTypy.ID.dd : false;
      // } else {
      //   $scope.showVATfields = config.VATfields;
      // }
      $scope.showVATfields = false;
      $scope.doklad.dd = DokladTypy.IN.dd;
      defaultDoklad.dd = $scope.doklad.dd;
    };

    if (!isEmpty(faktura)) {
      $scope.doklad = InternyDoklad.copy(faktura);
      _.defaults($scope.doklad, defaultDoklad);
      $scope.prevadzky = _.filter($scope.prevadzky, {id: $scope.doklad.prevadzka});
      if ($scope.prevadzky.length) {
        $scope.currentPrevadzkaName = $scope.prevadzky[0].prevadzka;
      }
    } else {
      $scope.doklad = InternyDoklad.restangularize(angular.copy(defaultDoklad));
      $scope.currentPrevadzkaName = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka}).prevadzka;
    }
    updateShowVAT();

    if ($scope.showVATfields) {
      $scope.labels = {
        zaklad_d1: 'fakturacia.ZAKLAD_D1',
        d1: 'fakturacia.DPH1',
        zaklad_d2: 'fakturacia.ZAKLAD_D2',
        d2: 'fakturacia.DPH2',
        zaklad_d0: 'fakturacia.ZAKLAD_0',
        spolu: 'fakturacia.SPOLU',
        suma1: 'fakturacia.SUMA1',
        suma2: 'fakturacia.SUMA2',
        suma3: 'fakturacia.SUMA3',
        suma4: 'fakturacia.SUMA4',
        suma5: 'fakturacia.SUMA5'
      };
    } else {
      $scope.labels = {
        zaklad_d1: 'fakturacia.SUMA1',
        d1: 'fakturacia.SUMA2',
        zaklad_d2: 'fakturacia.SUMA3',
        d2: 'fakturacia.SUMA4',
        zaklad_d0: 'fakturacia.SUMA5',
        spolu: 'fakturacia.SUMA6',
        suma1: 'fakturacia.SUMA7',
        suma2: 'fakturacia.SUMA8',
        suma3: 'fakturacia.SUMA9',
        suma4: 'fakturacia.SUMA10',
        suma5: 'fakturacia.SUMA11'
      };
    }

    var fakturaDataChanged = function() {
      if ($scope.doklad.id) {
        $scope.prevadzky = _.filter(Profile.get('prevadzky'), {id: $scope.doklad.prevadzka});
      } else {
        $scope.prevadzky = Profile.get('prevadzky');
      }
      $scope.currentPrevadzkaName = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka}).prevadzka;
      updateShowVAT();
    };
    var fakturaPreSave = function(data) {
      $scope.doklad.d_vyst = $scope.doklad.d_zdp;
      delete $scope.doklad.typ_dph;
      return data;
    }
    var fakturaPostSave = function(data) {
      Profile.parseObdobie(data.d_vyst);
      return data;
    }
    var fakturaValidate = function() {
      if ($scope.doklad.uctovanie && $scope.uctovanie_suma.rozdiel !== 0) {
        alert('Rozdiel MD-D musí byť 0!');
        return false;
      }
      if ($scope.doklad.d_vyst && !Profile.checkObdobie($scope.doklad.d_vyst)) {
        if (!window.confirm('Dátum nespadá do prednastaveného obdobia!\nPokračovať?')) {
          return false;
        }
      }
      if (!isEmpty($scope.doklad.rozuctovanie)) {
        if (!$scope.rozuctovanie.valid) {
          $scope.errors.rozuctovanie = true;
          alert('Chyba v rozúčtovaní!\nRozúčtovaná musí byť plná suma.');
          return false;
        } else {
          $scope.errors.rozuctovanie = false;
        }
      }
      return true;
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'interne',
      model: 'doklad',
      master: faktura,
      list: faktury,
      defaultItem: defaultDoklad,
      dataChanged: fakturaDataChanged,
      focusField: 'firma',
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      validate: fakturaValidate,
      $modalInstance: $modalInstance
    });

    $scope.countEditableSumsIfVATfields = function(evt) {
      return $scope.showVATfields ? $scope.doklad.countEditableSums(evt) : null;
    };

    $scope.setPrevadzka = function(prevadzka) {
      $scope.doklad.prevadzka = prevadzka.id;
      $scope.currentPrevadzkaName = prevadzka.prevadzka;
      Profile.set('lastPrevadzka', prevadzka);
    }

    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function(firma) {
      $scope.doklad.firma_data = firma;
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: faktura});
      });
    }

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function() {
      var prevadzka = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka});
      var modalInstance = $modal.open({
        templateUrl: 'admin/settings/printSettings.html',
        controller: 'PrintSettingsCtrl',
        backdrop: 'static',
        windowClass: 'printsettings-detail',
        resolve: {
          record: function() {
            return prevadzka;
          },
          prevadzky: function() {
            return $scope.prevadzky;
          }
        }
      });
    };
  }]);
