'use strict';

angular.module('casist.sklad')
  .controller('KartyDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'Karta', 'KartaTyp', 'SearchCtrl', 'karta', 'karty', 'Global', '$timeout', 'DokladTypy', 'Skupina', 'Podskupina', 'Druh', 'Vyrobca', 'Firma', 'Notification', 'VariantOptionTypes', 'EshopLabels',
              function($scope, $modalInstance, $controller, Profile, Karta, KartaTyp, SearchCtrl, karta, karty, Global, $timeout, DokladTypy, Skupina, Podskupina, Druh, Vyrobca, Firma, Notification, VariantOptionTypes, EshopLabels) {

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Karta
    });
    $scope.Skupina = Skupina;
    $scope.Podskupina = Podskupina;
    $scope.Druh = Druh;
    $scope.Firma = Firma;
    $scope.Vyrobca = Vyrobca;
    $scope.Karta = Karta;
    $scope.variantOptionTypes = VariantOptionTypes;
    $scope.eshopLabels = EshopLabels;

    $scope.getVarianty = function (query) {
      return Karta.get(
        query,
        { is_master_variant: 1, excluding: $scope.karta.id }
      );
    };

    $scope.showTypy = function() {
      KartaTyp.edit($scope.typy).then(function(data) {
        $scope.typy = data;
        var typ;
        if (!(typ = _.find($scope.typy, {id: parseInt($scope.karta.typ)}))) {
          if ($scope.typy.length) {
            $scope.karta.typ = $scope.typy[0].id;
          } else {
            $scope.karta.typ = undefined;
          }
        } else {
          $scope.karta.typ = typ.id;
        }
      });
    };

    $scope.karty = karty;

    var defaultKarta = {
      dph: Profile.get('platcadph') ? Global.get('sd1') : 0,
      mj: 'ks'
    };

    $scope.sposobyRozuctovania = Karta.getSposob();
    KartaTyp.get().then(function(data) {
      $scope.typy = data;
      if (data.length) {
        defaultKarta.typ = data[0].id;
        if (!angular.isDefined($scope.karta.typ)) {
          $scope.karta.typ = defaultKarta.typ;
        }
      }
    });

    $scope.RecordCtrl.main.init({
      endpoint: 'karty',
      model: 'karta',
      master: karta,
      list: karty,
      defaultItem: defaultKarta,
      searchCtrl: SearchCtrl,
      focusField: 'kod',
      $modalInstance: $modalInstance
    });
    if (!isEmpty(karta)) {
      $scope.karta = Karta.copy(copyMissing(defaultKarta, karta));
    } else {
      $scope.karta = angular.copy(Karta.restangularize(defaultKarta));
    }
    $scope.helperSums = $scope.karta.countZisk();
    if ($scope.karta.id) {
      Karta.getCennikItems($scope.karta).then(function (cennikzaznamy) {
          $scope.cennikzaznamy = cennikzaznamy;
      });
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: karta});
      });
    };
    $scope.countSums = function($event) {
      $scope.karta.countSums($event);
      $scope.helperSums = $scope.karta.countZisk();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.onUploadImage = function ($files) {
      if ($files.length !== 1) {
        return;
      }

      Karta.uploadImage($files.shift(), $scope.karta)
      .then(
        function (response) {
          var elements = document.querySelectorAll('[data-indentifier^="image_container_"][class$="active"]');
          if (elements.length === 1) {
            var image = elements[0];
            // remove class from previous active image to prevent displaying 2 active images for a short while
            image.classList.remove('active');
          }

          $scope.karta.images = response.data;
          Notification.add('Obrázok sa podarilo úspešne nahrať.', 'success', 10);
        }
      ).catch(
        function (error) {
          console.log(error);
          Notification.add('Obrázok sa nepodarilo úspešne nahrať.', 'danger', 10);
        }
      );
    };

    /*$scope.blurAndOk = function() {
      $timeout(function() {
        var active = angular.element('#'+document.activeElement.id);
        if (active) {
          active.blur();
        }
        $scope.ok();
      });
    };*/

    $scope.getPodskupiny = function(query) {
      return Podskupina.get(query, {skupina: $scope.karta.skupina});
    };
    $scope.addPodskupina = function(podskupina) {
      return Podskupina.add(podskupina, {skupina: $scope.karta.skupina});
    };
    $scope.skupinaChanged = function() {
      delete $scope.karta.podskupina;
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.splitNumber = function(number) {
      if (number === null || number.length === 0) {
        return "";
      }
      var tmp = parseFloat(number).toFixed(2);

      return tmp.toString().split('.');
    };
  }]);
