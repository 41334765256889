"use strict";

angular.module("casist.widgets").directive("tableHeader", [
  "$timeout",
  "$parse",
  "$document",
  "$translate",
  "$filter",
  "$sce",
  "Auth",
  "Global",
  function (
    $timeout,
    $parse,
    $document,
    $translate,
    $filter,
    $sce,
    Auth,
    Global
  ) {
    return {
      template: require("./table.html"),
      restrict: "EA",
      scope: {
        sort: "=",
        tableHeader: "=",
        view: "=",
        filter: "=filter",
      },
      link: function postLink(scope, element, attrs) {
        scope.filterShown = -1;
        scope.tableName = attrs.tableHeader;
        scope.headerDef = [];
        scope.linkedSelection = {};
        scope.defaultMena = Global.get("mena");
        let allHeaderDefs = [];

        scope.searchEnabled = angular.isDefined(attrs.search)
          ? scope.$eval(attrs.search)
          : false;
        var filterChanged = angular.isDefined(attrs.filterChanged)
          ? $parse(attrs.filterChanged)
          : angular.noop;
        var sortChanged = angular.isDefined(attrs.sortChanged)
          ? $parse(attrs.sortChanged)
          : angular.noop;
        // bound to scope.filter via watcher and every change in scope.filters needs to be done also on scope.filter
        scope.filters = [];
        scope.filterValues = [];
        scope.filterValues2 = [];

        Promise.all([
          $translate("main.VALUE_EXACT"),
          $translate("main.VALUE_MORE"),
          $translate("main.VALUE_LESS"),
          $translate("main.VALUE_RANGE"),
        ]).then((labels) => {
          scope.operations = [
            { name: "=", tooltip: labels[0] },
            { name: ">", tooltip: labels[1] },
            { name: "<", tooltip: labels[2] },
            {
              name: '<i class="fa fa-arrows-h search-input"></i>',
              tooltip: labels[3],
            },
          ];
        });
        scope.activeOperations = [];

        scope.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        scope.filterDate = [];
        var getMaxDate = function (month) {
          var current = new Date();
          var tmp = new Date(current.getFullYear(), month, 0);
          return tmp.getDate();
        };
        var updatePlaceholders = function (idx) {
          $translate(
            scope.headerDef[idx].placeholder || scope.headerDef[idx].label
          ).then((label) => {
            $timeout(function () {
              if (scope.activeOperations[idx] == 3) {
                $("#" + attrs.tableHeader + "_filterValue" + idx).attr(
                  "placeholder",
                  $filter("capitalize")(label) +
                    " " +
                    $translate.instant("main.FROM")
                );
                $("#" + attrs.tableHeader + "_filterValueMonth" + idx).attr(
                  "placeholder",
                  $filter("capitalize")($translate.instant("main.FROM"))
                );
                $("#" + attrs.tableHeader + "_filterValueMonth2" + idx).attr(
                  "placeholder",
                  $filter("capitalize")($translate.instant("main.TO"))
                );
              } else {
                $("#" + attrs.tableHeader + "_filterValue" + idx).attr(
                  "placeholder",
                  $filter("capitalize")(label)
                );
                $("#" + attrs.tableHeader + "_filterValueMonth" + idx).attr(
                  "placeholder",
                  ""
                );
                $("#" + attrs.tableHeader + "_filterValueMonth2" + idx).attr(
                  "placeholder",
                  ""
                );
              }
            });
          });
        };
        scope.selectMonthField = function (idx, month, field) {
          scope.filterDate[idx][field] = month;
          $timeout(function () {
            $("#" + attrs.tableHeader + "_filterValueMonth" + idx).focus();
          });
        };
        scope.selectOperation = function (idx, o) {
          scope.activeOperations[idx] = o;
          if (scope.filterDate[idx].searchType == 1)
            $("#" + attrs.tableHeader + "_filterValueMonth" + idx).select();
          else $("#" + attrs.tableHeader + "_filterValue" + idx).select();

          updatePlaceholders(idx);
        };
        scope.selectDateOperation = function (idx, o) {
          scope.filterDate[idx].searchType = o;
          updatePlaceholders(idx);
          // reset values
          if (o == 0) {
            scope.filterValues[idx] = undefined;
            scope.filterValues2[idx] = undefined;
            $timeout(function () {
              $("#" + attrs.tableHeader + "_filterValue" + idx).focus();
            });
          } else if (o == 1) {
            $timeout(function () {
              $("#" + attrs.tableHeader + "_filterValueMonth" + idx).focus();
            });
          } else {
            $timeout(function () {
              $("#" + attrs.tableHeader + "_filterValueYear" + idx).focus();
            });
          }
        };
        var toggleOperation = function (idx) {
          scope.activeOperations[idx]++;
          scope.activeOperations[idx] %= 4;
          scope.selectOperation(idx, scope.activeOperations[idx]);
          $timeout(function () {
            $("#" + attrs.tableHeader + "_filterValue" + idx).focus();
          });
        };
        var toggleDateOperation = function (idx) {
          var o = scope.filterDate[idx].searchType;
          o++;
          o %= 3;
          scope.selectDateOperation(idx, o);
        };
        scope.obdobia = Auth.profile().get("obdobia");
        var defaultDateFilter = {
          searchType: 0,
          year: scope.obdobia[0].rok,
          year2: scope.obdobia[0].rok,
        };
        scope.calendarOpened = false;

        scope.checkButtonStyle = {
          "border-bottom-left-radius": "0px",
          "border-top-left-radius": "0px",
        };

        // reset filter values if the filter is cleared from outside
        scope.$watch("filter", function (val, oldVal) {
          scope.filters = val || [];
          if (isEmpty(val) && isEmpty(oldVal)) return;
          if (val != oldVal && isEmpty(val)) {
            scope.filterValues = [];
            scope.filterValues2 = [];
            filterChanged(scope.$parent, { filter: [] });
          } else {
            // update filter values from the new filter
            for (var i in val) {
              if (!val[i]) continue;
              scope.filterValues[i] = val[i].value;
              scope.filterValues2[i] = val[i].value2;
            }
          }
        });

        var currentView = 0;

        var viewChanged = function (val) {
          if (!val) val = 0;

          parseHeaderDef(val);

          // remap existing filters to new positions, if they exist
          var mappedEverything = true;
          if (currentView != val) {
            var oldFilters = scope.filters;
            var oldValues = scope.filterValues;
            var oldValues2 = scope.filterValues2;
            scope.filters = [];
            scope.filterValues = [];
            scope.filterValues2 = [];
            for (var key in oldFilters) {
              var newIdx = -1;
              for (var i = 0; i < scope.headerDef.length; i++) {
                if (
                  oldFilters[key] &&
                  oldFilters[key].field == scope.headerDef[i].field
                ) {
                  newIdx = i;
                  break;
                }
              }
              if (newIdx != -1) {
                scope.filters[newIdx] = oldFilters[key];
                scope.filterValues[newIdx] = oldValues[key];
                scope.filterValues2[newIdx] = oldValues2[key];
              } else mappedEverything = false;
            }
            scope.filter = scope.filters;
          }
          // if not everything could be mapped due to different columns,
          if (!mappedEverything)
            filterChanged(scope.$parent, { filter: scope.filters });
          currentView = val;
        };
        scope.isFieldSorted = function (col) {
          return new RegExp("^-?" + parseSortField(col) + "$", "g").test(
            scope.sort.sort
          );
        };
        scope.$watch("view", viewChanged);
        scope.translationData = {};
        scope.select2Options = {};

        const prepareColumns = function (cols) {
          return cols.map((column, i) => {
            const hasField = angular.isDefined(column.field);
            if (!angular.isDefined(column.searchable)) {
              column.searchable = scope.searchEnabled && hasField;
            }
            if (!angular.isDefined(column.sortable)) {
              column.sortable = hasField;
            }
            column.filterCollapse = angular.isDefined(column.filterCollapse)
              ? column.filterCollapse
              : "center";
            const classes = [
              column.class,
              column.type === "date" ? "date" : "",
              column.type === "number" ? "text-right" : "",
            ].filter((cls) => angular.isDefined(cls) && cls !== "");
            column.class = classes.join(" ");
            column.colspan = column.colspan || 1;
            column.rowspan = column.rowspan || 1;
            if (column.icon) {
              column.icon = $sce.trustAsHtml(column.icon);
            }
            if (
              angular.isDefined(cols[i].minWidth) ||
              angular.isDefined(cols[i].width)
            ) {
              column.style = {
                minWidth: cols[i].minWidth,
                width: cols[i].width,
              };
            }
            scope.translationData[i] = column.translationData || {};
            scope.activeOperations[i] = 0;
            scope.filterDate[i] = defaultDateFilter;
            if (angular.isDefined(column.select2)) {
              column.select2Options = scope.$parent.$eval(
                column.select2.source
              );
            }
            if (angular.isDefined(column.linked)) {
              column.linkedGetter = $parse(column.linked.source);
              column.linkedGetterPaginated = function (query, pagination) {
                return column.linkedGetter(scope.$parent, {
                  query: query,
                  pagination: pagination,
                });
              };
            }
            return column;
          });
        };

        const parseHeaderDef = function (view) {
          const header = _.cloneDeep(scope.tableHeader);
          if (angular.isArray(header[0])) {
            allHeaderDefs = header.map((header) => prepareColumns(header));
            scope.headerDef =
              allHeaderDefs[angular.isDefined(view) ? view : currentView];
          } else {
            scope.headerDef = prepareColumns(header);
            allHeaderDefs = scope.headerDef;
          }
        };
        scope.select2changed = function (sender) {
          $("#" + scope.tableName + "_filterButtonApply" + sender).focus();
        };
        var tableHeaderChanged = function (val) {
          if (!val) return;

          var headerDef = scope.tableHeader;
          if (!headerDef || !headerDef.length) return;

          parseHeaderDef();
        };

        scope.$watch("tableHeader", function (val, _oldVal) {
          if (val) {
            tableHeaderChanged(val);
            viewChanged(scope.view);
          }
        });
        var parseSortField = function (col) {
          if (!scope.headerDef[col]) {
            return;
          }
          var field;
          if (angular.isDefined(scope.headerDef[col].sortField)) {
            if (angular.isFunction(scope.headerDef[col].sortField)) {
              field = $parse(scope.headerDef[col].sortField)(
                scope.headerDef[col].field,
                scope.sort.reverse
              );
            } else {
              field = scope.headerDef[col].sortField;
            }
          } else {
            field = scope.headerDef[col].field;
          }
          return field;
        };
        scope.setSort = function (col) {
          var field = parseSortField(col);
          if (field == scope.sort.sort)
            scope.sort.reverse = !scope.sort.reverse;
          else {
            scope.sort = { sort: field, reverse: false };
          }
          $timeout(function () {
            sortChanged(scope.$parent, { sort: scope.sort });
          });
        };
        scope.isFilterActive = function (idx) {
          if (angular.isDefined(idx)) {
            return angular.isDefined(scope.filters[idx]);
          } else return scope.filters.length != 0;
        };
        var closeFilterWindow = function () {
          if (scope.filterShown == -1) return;

          if (scope.isFilterActive(scope.filterShown)) {
            scope.filterValues[scope.filterShown] =
              scope.filters[scope.filterShown].value;
          } else scope.filterValues[scope.filterShown] = "";
          scope.filterShown = -1;
        };
        var dismissFilterWindow = function (evt) {
          var target = angular.element(evt.target);
          if (target.hasClass("fa-search") || target.hasClass("search-input"))
            return;
          // fix vyberania datumu cez calendarWidget
          target = target[0];
          while (target.parentNode) {
            if (target.classList.contains("search-input")) {
              return;
            }
            target = target.parentNode;
          }
          $document.unbind("click", dismissFilterWindow);
          $document.unbind("keydown", dismissFilterWindowKey);

          closeFilterWindow();
          scope.$digest();
        };
        var dismissFilterWindowKey = function (evt) {
          if (evt.which == 27) {
            closeFilterWindow();
            scope.$digest();
            evt.preventDefault();
            $document.unbind("click", dismissFilterWindow);
            $document.unbind("keydown", dismissFilterWindowKey);
          }
        };
        scope.toggleFilter = function (column) {
          scope.obdobia = Auth.profile().get("obdobia");
          if (scope.filterShown === column) {
            closeFilterWindow();
            $document.unbind("click", dismissFilterWindow);
            $document.unbind("keydown", dismissFilterWindowKey);
          } else {
            if (scope.isFilterActive(column)) {
              scope.checkButtonStyle = {
                "border-radius": "0px",
              };
            } else {
              scope.checkButtonStyle = {
                "border-bottom-left-radius": "0px",
                "border-top-left-radius": "0px",
              };
            }
            updatePlaceholders(column);
            scope.filterShown = column;

            $timeout(function () {
              if (scope.headerDef[column].type == "date") {
                if (scope.filterDate[column].searchType == 0) {
                  if (scope.isFilterActive(column))
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).select();
                  else
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).focus();
                } else if (scope.filterDate[column].searchType == 1) {
                  if (scope.isFilterActive(column))
                    $(
                      "#" + attrs.tableHeader + "_filterValueMonth" + column
                    ).select();
                  else
                    $(
                      "#" + attrs.tableHeader + "_filterValueMonth" + column
                    ).focus();
                }
              } else {
                if (angular.isDefined(scope.headerDef[column].select2)) {
                  if (!scope.isFilterActive(column)) {
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).select2("open");
                  } else {
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).select2("focus");
                  }
                } else {
                  if (scope.isFilterActive(column))
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).select();
                  else
                    $(
                      "#" + attrs.tableHeader + "_filterValue" + column
                    ).focus();
                }
              }
            });
            $document.bind("click", dismissFilterWindow);
            $document.bind("keydown", dismissFilterWindowKey);
          }
        };
        scope.clearFilter = function (idx) {
          scope.filterValues[idx] = undefined;
          scope.filterValues2[idx] = undefined;
          scope.filterDate[idx] = defaultDateFilter;
          delete scope.filters[idx];
          scope.filter = scope.filters;
          filterChanged(scope.$parent, { filter: scope.filters });
          $timeout(function () {
            scope.activeOperations[idx] = 0;
            scope.filterDate[idx].searchType = 0;
          });
        };
        scope.applyFilter = function (evt, idx) {
          if (!evt || (evt && evt.which == 13)) {
            if (evt) {
              evt.stopPropagation();
              evt.preventDefault();
              if (evt.altKey) {
                toggleOperation(idx);
                return;
              } else if (evt.ctrlKey) {
                toggleDateOperation(idx);
                return;
              }
            }

            scope.filterShown = -1;

            if (scope.activeOperations[idx] == 0)
              scope.filterValues2[idx] = undefined;

            // parse date filters
            if (scope.headerDef[idx].type == "date") {
              var month = scope.filterDate[idx].month;
              var year = scope.filterDate[idx].year;
              if (scope.filterDate[idx].searchType == 1) {
                if (!month && !scope.filterDate[idx].month2) {
                  scope.clearFilter(idx);
                  return;
                }
                switch (scope.activeOperations[idx]) {
                  case 1:
                    scope.filterValues[idx] = year + "-" + month + "-01";
                    scope.filterValues2[idx] = undefined;
                    break;
                  case 2:
                    scope.filterValues[idx] =
                      year + "-" + month + "-" + getMaxDate(month);
                    scope.filterValues2[idx] = undefined;
                    break;
                  case 3:
                    if (month)
                      scope.filterValues[idx] = year + "-" + month + "-01";
                    else scope.filterValues[idx] = undefined;
                    if (scope.filterDate[idx].month2)
                      scope.filterValues2[idx] =
                        scope.filterDate[idx].year2 +
                        "-" +
                        scope.filterDate[idx].month2 +
                        "-" +
                        getMaxDate(scope.filterDate[idx].month2);
                    else scope.filterValues2[idx] = undefined;
                    break;
                  default:
                    scope.filterValues[idx] = year + "-" + month + "-01";
                    scope.filterValues2[idx] =
                      year + "-" + month + "-" + getMaxDate(month);
                    break;
                }
              } else if (scope.filterDate[idx].searchType == 2) {
                switch (scope.activeOperations[idx]) {
                  case 1:
                    scope.filterValues[idx] = year + "-01-01";
                    scope.filterValues2[idx] = undefined;
                    break;
                  case 2:
                    scope.filterValues[idx] = year + "-12-31";
                    scope.filterValues2[idx] = undefined;
                    break;
                  case 3:
                    if (year) scope.filterValues[idx] = year + "-01-01";
                    else scope.filterValues[idx] = undefined;
                    if (scope.filterDate[idx].year2)
                      scope.filterValues2[idx] =
                        scope.filterDate[idx].year2 + "-12-31";
                    else scope.filterValues2[idx] = undefined;
                    break;
                  default:
                    scope.filterValues[idx] = year + "-01-01";
                    scope.filterValues2[idx] = year + "-12-31";
                    break;
                }
              }
            }

            if (!scope.filterValues[idx] && !scope.filterValues2[idx]) {
              scope.clearFilter(idx);
              return;
            }

            scope.filters[idx] = {
              field: scope.headerDef[idx].field,
              sortField: scope.headerDef[idx].sortField,
              value: scope.filterValues[idx],
              value2: scope.filterValues2[idx],
              operation: scope.activeOperations[idx],
              type: scope.headerDef[idx].type,
            };
            if (
              scope.activeOperations[idx] === 0 &&
              angular.isDefined(scope.headerDef[idx].filterType)
            ) {
              scope.filters[idx].field +=
                "__" + scope.headerDef[idx].filterType;
            }
            scope.filter = scope.filters;
            filterChanged(scope.$parent, { filter: scope.filters });
          }
        };

        scope.$on("$destroy", function () {
          if (scope.filterShown) $document.unbind("click", dismissFilterWindow);
        });
      },
    };
  },
]);
