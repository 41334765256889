'use strict';

angular.module('casist.core').controller('MailSenderCtrl', 
              ['$scope', '$modalInstance', 'to', 'subject', 'title', 'message', 'object', 'Notification', 'Outputs', 'Dialog',
              function ($scope, $modalInstance, to, subject, title, message, object, Notification, Outputs, Dialog) {

    $scope.mail = {
      to: to,
      subject: subject,
      title: title,
      message: message
    };
    $scope.title = title;

    $scope.send = function() {
      $scope.sendInProgress = true;
      var mailData = {
        to: $scope.mail.to,
        subject: $scope.mail.subject,
        message: $scope.mail.message
      };
      object.all('send').post(mailData).then(function(data) {
        $scope.sendInProgress = false;
        Notification.add('E-mail bol úspešne odoslaný.', 'success', 5);
        $scope.ok({mailData: mailData, mailMessage: data});
      }, function(error) {
        $scope.sendInProgress = false;
        var data;
        if (error && error.data) {
          data = '<br>' + (error.data.error || error.data);
        }
        Notification.add('Odoslanie e-mailu sa nepodarilo!'+data, 'danger', 5);
      });
    };
    $scope.ok = function (data) {
      $modalInstance.close(data);
    };

    $scope.cancel = function (reason) {
      $modalInstance.dismiss(reason || 'cancel');
    };
  }]);