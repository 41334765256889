'use strict';

angular.module('casist.utils')
  .directive('requestFocus', ['$timeout', function ($timeout) {
    return {
      //template: '<div></div>',
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        attrs.$observe('requestFocus', function (value) {
          if (value) {
            $timeout(function() {
              element[0].focus();
            });
          }
        });
      }
    };
  }]);
