'use strict';

angular.module('casist.auth')
  .controller('RegisterCtrl', ['$scope', '$location', '$http', 'Notification', 'Dialog', function ($scope, $location, $http, Notification, Dialog) {
    var lastNotifIdx = -1;
    $scope.register = {
      jednoduche_ucto: 0,
    };
    $scope.errors = {};
    $scope.inProgress = false;
    $scope.typUcto = [
      {id: 0, label: 'Podvojné'},
      {id: 1, label: 'Jednoduché'}
    ];

    $scope.sendRegistration = function() {
      if (lastNotifIdx !== -1) {
        Notification.close(lastNotifIdx);
      }
      $scope.errors = {};
      $scope.$broadcast('errorsRefresh');
      if (!$scope.register.suhlas) {
        Dialog.alert('Musíte súhlasiť so všeobecnými podmienkami používania.', 'Upozornenie');
        return;
      }
      var required = ['email', 'first_name', 'last_name', 'dic', 'ulica', 'cislo', 'mesto', 'psc'];
      $scope.register.captcha = document.getElementById('recaptcha').value;

      for (var i in required) {
        var key = required[i];
        if (!$scope.register[key])
          $scope.errors[key] = ["Pole nemôže byť prázdne."];
      }
      if ($scope.errors['email']) {
        $scope.errors['email'] = ["E-mail musí byť zadaný v správnom tvare."];
      } else if ($scope.register.email && $scope.register.email.indexOf('@') == -1) {
        $scope.errors['email'] = ["E-mail nie je v správnom tvare!"];
      }
      if (isEmpty($scope.errors)) {
        $scope.inProgress = true;
        $http.post(constants.authServer+'register', $scope.register).then(function() {
          $scope.inProgress = false;
          Notification.add('Registrácia prebehla úspešne. Potvrďte prosím Váš e-mail kliknutím na odkaz, ktorý sme Vám práve zaslali.', 'success');
          $location.path("/login");
        }, function(error) {
          var desc = '';
          if (error) {
            $scope.errors = error;
            $scope.$broadcast('errorsRefresh');
            if (error) {
              desc = '';
              angular.forEach(error, function(value, key) {
                for (var i = 0; i < value.length; i++) {
                  desc += value[i]+'\n';
                }
              });
              // desc = error.captcha;
            } else {
              desc = 'Kliknite na červené polia pre detail.';
            }
          }
          lastNotifIdx = Notification.add('Registrácia sa nepodarila!\n'+desc, 'danger');
          // } else if (error === '"Casist user with this Email already exists."') {
          //   Notification.add('Používateľ s takýmto e-mailom je úž v systéme Casist zaregistrovaný!', 'danger');
          // }
          $scope.inProgress = false;
        });
      } else {
        $scope.$broadcast('errorsRefresh');
        Dialog.alert('Registračný formulár obsahuje chyby.<br>Skontrolujte červené polia.', 'Chyba');
        return;
      }
    }
  }]);
