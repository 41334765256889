'use strict';

angular.module('casist.admin')
  .controller('AgendaCtrl', ['$scope', '$modalInstance', '$controller', '$location', 'Auth', 'Agenda', 'SearchCtrl', 'record', 'agendy', 'Restangular', 'Prevadzka', 'Socket', 'DanoveUrady',
              function($scope, $modalInstance, $controller, $location, Auth, Agenda, SearchCtrl, record, agendy, Restangular, Prevadzka, Socket, DanoveUrady) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Agenda
    });

    $scope.danoveUrady = DanoveUrady;

    var defaultAgenda = {
      active: true
    };

    var agendaPostSave = function(data, created) {
      // prevadzka sa vytvori na backende, takze len poslat socket o jej vytvoreni
      if (!created) {
        return data;
      }

      var prevadzka = {
        agenda: data.id,
        prevadzka: data.firma,
        ulica: data.ulica,
        cislo: data.cislo,
        mesto: data.mesto,
        psc: data.psc
      }
      Socket.emit('dataChanged', {type: 'prevadzky', operation: 1, payload: prevadzka});
      var agendy = Auth.profile().get('agendy');
      angular.extend(data, {external: false, client_id: Auth.profile().get('client_id')});
      agendy.push(data);
      Auth.profile().set('agendy', agendy);

      return data;
    };
    var agendaPostDelete = function(data) {
      var agendy = Auth.profile().get('agendy');
      var item = _.find(agendy, {id: data.id});
      if (!item) {
        return;
      }
      agendy.splice(agendy.indexOf(item), 1);
      Auth.profile().set('agendy', agendy);
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'settings/agendy',
      model: 'agenda',
      master: record,
      list: agendy,
      defaultItem: defaultAgenda,
      // dataChanged: fakturaDataChanged,
      // preSave: fakturaPreSave,
      postSave: agendaPostSave,
      postDelete: agendaPostDelete,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });

    if (!isEmpty(record)) {
      $scope.agenda = Restangular.copy(record);
    } else {
      $scope.agenda = defaultAgenda;
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: record});
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close('ok');
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
