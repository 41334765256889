'use strict';

angular.module('casist.search')
  .directive('searchUtilsCheckbox', [function () {
    return {
      template: require('./search-utils-checkbox.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        SearchCtrl: '=controller',
        label: '@',
        field: '@searchUtilsCheckbox',
        onSelect: '&',
        value: '@'
      },
      link: function postLink(scope, element, attrs) {
        if (!angular.isDefined(attrs.onSelect)) {
          scope.onSelect = function() {
            scope.SearchCtrl.filterCheckbox(scope.field, scope.value);
          }
        }
      }
    };
  }])
  .directive('searchUtilsSelection', [function () {
    return {
      template: require('./search-utils-selection.html'),
      restrict: 'EA',
      scope: {
        SearchCtrl: '=controller',
        data: '=',
        unfiltered: '@',
        label: '@',
        field: '@searchUtilsSelection',
        icon: '@',
        onSelect: '&'
      },
      link: function postLink(scope, element, attrs) {
        if (!angular.isDefined(attrs.lookup)) {
          scope.lookup = attrs.searchUtilsSelection;
        } else {
          scope.lookup = attrs.lookup;
        }
        var track = angular.isDefined(attrs.track) ? scope.$eval(attrs.track) : true;
        var emitChange = angular.isDefined(attrs.emitChange) ? scope.$eval(attrs.emitChange) : true;
        if (!angular.isDefined(attrs.trackBy)) {
          scope.trackBy = 'id';
        } else {
          scope.trackBy = attrs.trackBy;
        }
        scope.itemActive = false;
        scope.required = angular.isDefined(attrs.required) ? attrs.required === '' || scope.$eval(attrs.required) : false;
        if (!angular.isDefined(attrs.controller)) {
          scope.$watch('data', function(val) {
            var active = _.find(val, {active: true});
            if (!angular.isDefined(attrs.unfiltered)) {
              if (active) {
                scope.unfiltered = active[scope.lookup];
              } else {
                scope.unfiltered = undefined;
              }
            }
          });
        }
        scope.getByID = function(id) {
          if (id === null) {
            return _.find(scope.data, {id: 0});
          }
          return _.find(scope.data, function(obj) {
            return obj[scope.trackBy] === id;
          });
        };
        var clearActive = function() {
          scope.itemActive = undefined;
        };
        scope.filterSelection = function(value) {
          if (angular.isDefined(attrs.onSelect)) {
            scope.onSelect({value: value});
          }
          clearActive();
          if (angular.isDefined(attrs.controller)) {
            if (emitChange) {
              scope.SearchCtrl.filterSelection(scope.field, value, true, scope.trackBy);
            }
            if (value[scope.trackBy] === 0) {
              scope.itemActive = value;
            }
          } else {
            if (track) {
              scope.itemActive = value;
            }
            if (!angular.isDefined(attrs.unfiltered)) {
              scope.unfiltered = value[scope.lookup];
            }
          }
        };
        scope.clearSelection = function() {
          if (angular.isDefined(attrs.onSelect)) {
            scope.onSelect({value: null});
          }
          if (angular.isDefined(attrs.controller)) {
            if (emitChange) {
              scope.SearchCtrl.filterSelection(scope.field);
            }
          } else {
            clearActive();
            if (!angular.isDefined(attrs.unfiltered)) {
              scope.unfiltered = undefined;
            }
          }
        };
      }
    };
  }])
  .directive('searchUtilsCancel', [function () {
    return {
      template: require('./search-utils-cancel.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        SearchCtrl: '=searchUtilsCancel',
        exclude: '='
      },
      link: function postLink(scope, element, attrs) {
        if (angular.isDefined(attrs.cancelTop)) {
          scope.cancelTop = scope.$eval(attrs.cancelTop);
        } else {
          scope.cancelTop = true;
        }
        if (angular.isDefined(attrs.cancelTable)) {
          scope.cancelTable = scope.$eval(attrs.cancelTable);
        } else {
          scope.cancelTable = true;
        }
        if (angular.isDefined(attrs.separator)) {
          scope.separator = scope.$eval(attrs.separator);
        } else {
          scope.separator = true;
        }

        scope.inExcludes = function() {
          if (!angular.isDefined(attrs.exclude) || !isEmpty(scope.SearchCtrl.filters.table)) {
            return false;
          }
          var filter = scope.SearchCtrl.filters.top;
          var keys = [];
          angular.forEach(filter, function(val, key) {
            keys.push(key);
          });
          return _.every(_.without(keys, 'ordering', 'page'), function(obj) {
            return scope.exclude.indexOf(obj) !== -1;
          });
        };
      }
    };
  }])
  .directive('searchUtilsUniversal', ['$document', '$timeout', function ($document, $timeout) {
    return {
      template: require('./search-utils-universal.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        SearchCtrl: '=searchUtilsUniversal'
      },
      link: function postLink(scope, element, attrs) {
        var timer = null;
        var performedSearch = false;
        scope.hasInput = function() {
          return performedSearch;
        }
        scope.clearInput = function() {
          scope.filter = '';
          scope.performSearch();
        }
        scope.performSearch = function() {
          if (!scope.filter) {
            scope.SearchCtrl.performUniversalSearch(undefined);
            performedSearch = false;
            if (timer)
              $timeout.cancel(timer);
            return;
          }
          if (scope.filter && scope.filter.length < 3)
            return;
          if (timer)
            $timeout.cancel(timer);
          timer = $timeout(function() {
            scope.SearchCtrl.performUniversalSearch(scope.filter);
            performedSearch = true;
          }, 500);
        }
        var searchHotkey = function(evt) {
          if ($(document.activeElement)[0].localName !== 'body') {
            return;
          }
          if (evt.which === 47) {
            $(element.find('input')).focus();
            evt.preventDefault();
          }
        }
        var elementHotkey = function(evt) {
          if (evt.which === 27) {
            scope.clearInput();
          }
        };
        $document.bind('keypress', searchHotkey);
        element.bind('keydown', elementHotkey);
        scope.$on('$destroy', function() {
          if (timer) {
            $timeout.cancel(timer);
            timer = null;
          }
          $document.unbind('keypress', searchHotkey);
          element.unbind('keydown', elementHotkey);
        });
      }
    };
  }])
  .directive('searchUtilsUnifilter', ['$document', '$timeout', '$locale', 'dateFilter', function ($document, $timeout, $locale, dateFilter) {
    return {
      restrict: 'EA',
      require: 'ngModel',
      link: function postLink(scope, element, attrs, ngModel) {
        if (!ngModel) {
          return;
        }
        var replaceSeparator = function(viewValue, decimals) {
          var formats = $locale.NUMBER_FORMATS;
          var re = new RegExp(' ', 'g');
          var re2 = new RegExp(',', 'g');
          var viewValue2 = viewValue.replace(re, '').replace(re2, '.');
          viewValue2 = formatNumber(parseFloat(viewValue2), formats.PATTERNS[0], '', '.', decimals);

          return viewValue2;
        };
        var parseDate = function(datumstr) {
          var d = new Date();
          if (datumstr === '') {
            return;
          }

          if (datumstr.match(','))
            datumstr = datumstr.replace(new RegExp(',', 'g'), '.');
          var ex = datumstr.split(".");

          var day = parseInt(ex[0]);
          var month = parseInt(ex[1]);
          var year = parseInt(ex[2]);

          if (!day)
            day = d.getDate();
          if (!month)
            month = d.getMonth()+1;
          if (year && String(year).length == 2) {
            year = new String(d.getFullYear()).substring (0, parseInt (4 - String(year).length)) + String(year);
          } else if (!year || year.length < 4)
            year = d.getFullYear();

          d = new Date(year, month-1, day);
          if (isNaN(d)) {
            return datumstr;
          }

          return dateFilter(d, 'yyyy-MM-dd');
        };
        ngModel.$parsers.unshift(function(viewValue) {
          if (!viewValue || angular.isObject(viewValue)) {
            return viewValue;
          }
          var val = viewValue;
          var viewValueNum = replaceSeparator(viewValue);
          var count = viewValue.replace(' ', '').replace(/,/g, '.').match(/\./g);
          if (count && count.length === 2) {
            val = parseDate(viewValue);
          } else if (/^([0-9]+)\.?([0-9]*)$/.test(viewValueNum) && !/^0+/.test(val)) {
            var num = parseFloat(viewValueNum);
            if (isNaN(num)) {
              val = viewValue;
            } else {
              val = String(num);
            }
          }
          return val;
        });
      }
    };
  }]);
