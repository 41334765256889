'use strict';

angular.module('casist.sklad')
  .factory('Predajca', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Dialog', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Dialog) {
    Restangular.extendModel('predajca', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (!this.text) {
            this.addError('text', 'Text nemôže byť prázdny!');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('faktury/predajca');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').faktura;
      },
      edit: function (predajca) {
        var deferred = $q.defer();
        var promise = Dialog.prompt('Predajca', 'Upraviť predajcu', predajca.text).then(function(str) {
          predajca.text = str;
          predajca.update().then(function(data) {
            deferred.resolve(data);
          }, function() {
            deferred.reject();
          });
        }, function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      add: function(predajca) {
        return parent.create({text: predajca});
      },
      remove: function (predajca) {
        return predajca.remove();
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);