'use strict';

angular.module('casist.sklad')
  .controller('SkladyCtrl', [
    '$scope',
    'Restangular',
    'CennikZaznam',
    '$controller',
    'TabService',
    'Outputs',
    '$modal',
    'Notification',
    'Sklad',
    function (
      $scope,
      Restangular,
      CennikZaznam,
      $controller,
      TabService,
      Outputs,
      $modal,
      Notification,
      Sklad
    ) {

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.sklady = [];

    $scope.table = [
      { width: 20 },
      { field: 'nazov', label: 'sklad.NAZOV' },
      { field: 'kod', label: 'sklad.KOD' },
      { field: 'prevadzka__prevadzka', label: 'sklad.PREVADZKA' },
    ];

    var filterChanged = function(filter) {
      var promise = Sklad.objects().getList(filter);
      $scope.sklady.loading = true;
      promise.then(function(data) {
        TabService.registerScope('sklady', $scope);
        $scope.sklady = data;
      });

      return promise;
    };

    $scope.SearchCtrl.init({
      model: Sklad,
      onChange: filterChanged,
      columnsDef: $scope.table,
      sort: {sort: 'nazov'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(TabService.bus.get('sklady'));

    $scope.edit = function(item) {
      return Sklad.edit(item, $scope.sklady, $scope.SearchCtrl);
    };

    $scope.create = function(item) {
      return Sklad.edit(item, $scope.sklady, $scope.SearchCtrl);
    };

    var unregisterProfileObservers = [];

    var events = Sklad.registerEvents(
      function () {
        return $scope.sklady;
      },
      function () {
        return $scope.SearchCtrl;
      }
    );
    $scope.$on("$destroy", function () {
      Sklad.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });
  }]);
