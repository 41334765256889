'use strict';

angular.module('casist.admin')
  .factory('Settings', ['Restangular', 'BaseModel', 'BaseCollectionModel', function (Restangular, BaseModel, BaseCollectionModel) {
    Restangular.extendModel('settings', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.sd1) || this.sd1 === '') {
            this.addError('sd1', 'Sadzba DPH nemôže byť prázdna');
            result = false;
          }
          if (!angular.isDefined(this.sd2) || this.sd2 === '') {
            this.addError('sd2', 'Sadzba DPH nemôže byť prázdna');
            result = false;
          }
          if (!angular.isDefined(this.desatiny_fa) || this.desatiny_fa === '') {
            this.addError('desatiny_fa', 'Počet desatín nemôže byť prázdny');
            result = false;
          }
          return result;
        }
      });
    });
    return BaseCollectionModel.object('settings');
  }]);
