'use strict';

angular.module('casist.widgets')
  .directive('imageGallery', ['$document', 'Karta', 'Notification', function ($document, Karta, Notification) {
    return {
      templateUrl: 'components/widgets/image-gallery.html',
      scope: {
        karta: '=',
        removable: '<',
        imageStyle: '@',
        carouselStyle: '@',
      },
      link: function (scope) {
        scope.removeActiveImage = function () {
          var elements = document.querySelectorAll('[data-indentifier^="image_container_"][class$="active"]');
          if (elements.length !== 1) {
            Notification.add('Obrázok sa nepodarilo odstrániť.', 'danger', 10);
            return;
          }

          var image = elements[0];
          Karta.removeImage(image.id)
          .then(
            function (response) {
              // remove class from previous active image to prevent displaying 2 active images for a short while
              image.classList.remove('active');

              scope.karta.images = response.data;
              Notification.add('Obrázok sa podarilo úspešne odstrániť.', 'success', 10);
            }
          ).catch(
            function (error) {
              console.log(error);
              Notification.add('Obrázok sa nepodarilo odstrániť.', 'danger', 10);
            }
          );
        };
      }
    };
  }]);
