'use strict';

angular.module('casist.ucto')
  .factory('Predkontacia', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'DokladTypy', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, DokladTypy) {
    var ENDPOINT = "predkontacie";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.nazov) {
            this.addError('nazov', 'Pole názov je povinné.');
            res = false;
          }
          return res;
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl, config) {
      var modalInstance = $modal.open({
        templateUrl: 'ucto/predkontacie/predkontacie-detail.html',
        controller: 'PredkontaciaDetailCtrl',
        backdrop: 'static',
        keyboard: false,
        windowClass: 'detail-md',
        resolve: {
          predkontacia: function() {
            return item ? item : {};
          },
          predkontacie: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          },
          config: function() {
            return config;
          }
        }
      });
      return modalInstance.result;
    };

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').predkontacia;
      },
      typy: function() {
        return [
          {id: DokladTypy.VF.dd, nazov: 'Pohľadávky'},
          {id: DokladTypy.DF.dd, nazov: 'Záväzky'},
          {id: DokladTypy.PD.dd, nazov: DokladTypy.PD.name},
          {id: DokladTypy.BD.dd, nazov: DokladTypy.BD.name},
          {id: DokladTypy.IN.dd, nazov: DokladTypy.IN.name}
        ];
      },
      edit: function (item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(nazov, typ, uctovanie, operacia, popis) {
        var novaPredkontacia = {nazov: nazov, popis: popis};
        var config = {};
        if (angular.isDefined(typ)) {
          novaPredkontacia.typ = typ;
          config.typ = typ;
        }
        var typDokladu = _.find(DokladTypy, {dd: typ});
        if (!operacia && typDokladu.operacia) {
          operacia = typDokladu.operacia;
        }
        novaPredkontacia.operacia = operacia;
        if (angular.isDefined(uctovanie)) {
          angular.forEach(uctovanie, function(value, key) {
            novaPredkontacia[key] = value;
          });
        }
        var obj = parent.restangularize(novaPredkontacia);
        var promise = $q.defer();
        openDetail(obj, undefined, undefined, config).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      remove: function (predkontacia) {
        return predkontacia.remove();
      },
      get: function (query, typ, operacia) {
        return parent.objects().getList({search:query, typ: typ, operacia: operacia});
      }
    });
  }]);
