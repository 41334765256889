"use strict";

angular.module("casist.auth").factory("Profile", [
  "Socket",
  "Global",
  "$sessionStorage",
  function (Socket, Global, $sessionStorage) {
    var data = {};
    var observers = [];
    var cachedTpls = [];
    return {
      set: function (key, value, suppressCallback) {
        suppressCallback = angular.isDefined(suppressCallback)
          ? suppressCallback
          : false;
        data[key] = value;
        // fire callbacks for observers linked to this key
        if (angular.isDefined(value) && !suppressCallback) {
          for (var i in observers) {
            if (observers[i].attribute === key) {
              observers[i].fc(value);
            }
          }
        }
      },
      get: function (key, defaultValue) {
        if (!data[key]) {
          return defaultValue;
        }
        return data[key];
      },
      addObserver: function (key, fc) {
        var obj = { attribute: key, fc: fc };
        observers.push(obj);
        return function () {
          observers.splice(observers.indexOf(obj), 1);
        };
      },
      load: function (_data) {
        data = {};
        angular.forEach(_data, function (value, key) {
          data[key] = value;
        });
      },
      data: function () {
        return angular.copy(data);
      },
      parseObdobie: function (date) {
        if (!date || !date.match("-")) {
          return;
        }
        var obdobia = this.get("obdobia");
        if (!obdobia) {
          return;
        }
        var year = parseInt(date.split("-")[0]);
        var found = _.find(obdobia, { rok: year });
        if (!found) {
          var obdobie = { rok: year };
          obdobia.splice(0, 0, obdobie);
          this.set("obdobia", obdobia);
          Socket.emit("dataChanged", {
            type: "obdobia",
            operation: 1,
            payload: obdobie,
          });
          this.save();
        }
      },
      checkObdobie: function (date) {
        if (!date || !date.match("-")) {
          return false;
        }
        var obdobie = this.get("obdobie");
        if (!obdobie) {
          return true;
        }
        var year = parseInt(date.split("-")[0]);
        return obdobie === year;
      },
      addCachedTemplate: function (tpl) {
        cachedTpls.push(tpl);
      },
      getCachedTemplates: function () {
        return cachedTpls;
      },
      isTplCached: function (url) {
        return cachedTpls.indexOf(url) !== -1;
      },
      clearTplCache: function () {
        Global.clearTplCache(cachedTpls);
        cachedTpls = [];
      },
      clear: function () {
        data = {};
        this.clearTplCache();
      },
      isEmpty: function () {
        return isEmpty(data);
      },
      save: function () {
        $sessionStorage.profile = this.data();
        return $sessionStorage.profile;
      },
    };
  },
]);

angular.module("casist.auth").factory("Auth", [
  "authService",
  "$http",
  "Global",
  "$location",
  "Socket",
  "Profile",
  "$sessionStorage",
  function (
    authService,
    $http,
    Global,
    $location,
    Socket,
    Profile,
    $sessionStorage
  ) {
    // var profile = Profile;
    var renewalInProgress = false;

    return {
      resetRenewalStatus: function() {
        renewalInProgress = false;
      },
      setAgendaHeader: function(agendaId) {
        $http.defaults.headers.common['X-Agenda'] = agendaId;
      },
      renewTokenAsync: function () {
        if (renewalInProgress) {
          return Promise.reject("Renewal in progress");
        }
        var token = $sessionStorage.refreshtoken;
        renewalInProgress = true;
        return $http
          .post(
            Global.get("serverAddress") +
              "oauth2/token/?grant_type=refresh_token&refresh_token=" + token,
            {},
            { timeout: 5000 }
          )
          .then(
            function (response) {
              response = response.data;
              $sessionStorage.accesstoken = response.access_token;
              $sessionStorage.refreshtoken = response.refresh_token;
              $http.defaults.headers.common.Authorization =
                "Bearer " + response.access_token;
              // add new access token to the config for tokens in buffer
              authService.tokenConfirmed(function (config) {
                config.headers.Authorization =
                  "Bearer " + response.access_token;
                return config;
              });
              renewalInProgress = false;
            },
            function (error) {
              // nepodarilo sa refreshnut token, ideme naspat na login
              Global.clear();
              Global.clearCookies();
              // $location.path('/logout');
              renewalInProgress = false;
            }
          );
      },
      renewTokenSync: function () {
        var token = $sessionStorage.refreshtoken;
        if (!token) {
          return false;
        }
        var success = false;
        $.ajax({
          url: Global.get("serverAddress") + "oauth2/token/?grant_type=refresh_token&refresh_token=" + token,
          async: false,
          type: "POST",
          timeout: 5000,
          headers: {
            Authorization: $http.defaults.headers.common["Authorization"],
          },
        }).done(
          function (response) {
            $sessionStorage.accesstoken = response.access_token;
            $sessionStorage.refreshtoken = response.refresh_token;
            $http.defaults.headers.common.Authorization =
              "Bearer " + response.access_token;
            // add new access token to the config for tokens in buffer
            authService.tokenConfirmed(function (config) {
              config.headers.Authorization = "Bearer " + response.access_token;
              return config;
            });
            success = true;
          }).fail(
          function () {
            // nepodarilo sa refreshnut token, ideme naspat na login, vymazeme cookies
            Global.clearCookies();
            $location.path("/login");
            success = false;
          }
        );

        return success;
      },
      profile: function () {
        return Profile;
      },
      setProfile: function (_profile) {
        Profile.load(_profile);
      },
      clearAuthData: function () {
        Profile.clear();
        delete $sessionStorage.profile;
        delete $sessionStorage.accesstoken;
        delete $sessionStorage.refreshtoken;
        delete $sessionStorage.settings;
      },
    };
  },
]);
