'use strict';

angular.module('casist.sklad')
  .controller('CennikCtrl', [
    '$scope',
    'Restangular',
    'CennikZaznam',
    '$controller',
    'TabService',
    'Outputs',
    '$modal',
    'Notification',
    'Profile',
    'Karta',
    function (
      $scope,
      Restangular,
      CennikZaznam,
      $controller,
      TabService,
      Outputs,
      $modal,
      Notification,
      Profile,
      Karta
    ) {

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.cennikzaznamy = [];

    $scope.cennikTable = [
      { width: 60 },
      { field: 'cennik__nazov', label: 'cenniky.NAZOV' },
      { field: 'karta__kod', label: 'karty.KOD' },
      { field: 'karta__nazov', label: 'cenniky.NAZOV_KARTY' },
      { field: 'mo', label: 'cenniky.MO', type: 'number'},
      { label: 'cenniky.MCC', type: 'number'},
      { field: 'karta__mo', label: 'cenniky.MO_KARTA', type: 'number'},
      { label: 'cenniky.MMO', type: 'number'},
      { field: 'karta__vo', label: 'cenniky.VO_KARTA', type: 'number'},
      { label: 'cenniky.MVO', type: 'number'},
      { field: 'karta__mo_cz', label: 'cenniky.MO_CZ_KARTA', type: 'number'},
      { field: 'karta__modph_cz', label: 'cenniky.MODPH_CZ_KARTA', type: 'number'},
      { field: 'karta__cena_sklad', label: 'cenniky.CENA_SKLAD_KARTA', type: 'number'},
      { field: 'karta__kod_ean', label: 'karty.KOD_EAN' },
      { field: 'nazov_alt', label: 'cenniky.NAZOV_KARTY_ALT' },
      { field: 'poznamka', label: 'cenniky.POZNAMKA' },
    ];

    var filterChanged = function(filter) {
      var promise = CennikZaznam.objects().getList(filter);
      $scope.cennikzaznamy.loading = true;
      promise.then(function(data) {
        TabService.registerScope('cenniky', $scope);
        $scope.cennikzaznamy = data;
      });

      return promise;
    };

    $scope.SearchCtrl.init({
      model: CennikZaznam,
      onChange: filterChanged,
      columnsDef: $scope.cennikTable,
      sort: {sort: 'kod'},
      scope: $scope
    });

    $scope.SearchCtrl.refresh(TabService.bus.get('cennikzaznamy'));

    $scope.edit = function(item) {
      return CennikZaznam.edit(item, $scope.cennikzaznamy, $scope.SearchCtrl);
    };

    $scope.create = function(item) {
      return CennikZaznam.edit(item, $scope.cennikzaznamy, $scope.SearchCtrl);
    };

    $scope.copy = function(item) {
      var copied = CennikZaznam.copy(item);
      _.each(['id', 'karta_data'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };

    $scope.editKarta = function(item) {
      Karta.edit(item.karta_data, $scope.cennikzaznamy, $scope.SearchCtrl).then(function () {
          $scope.SearchCtrl.refresh();
        }
      );
    };

    $scope.importCennik = function() {
      var modalInstance = $modal.open({
        templateUrl: 'core/import/import.html',
        controller: 'GenericImportCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          list: function() {
            return $scope.cennikzaznamy;
          },
          model: function() {
            return CennikZaznam;
          }
        }
      });
      modalInstance.result.then(function() {
        Notification.add('Záznamy boli aktualizované.', 'success', 5);
        $scope.SearchCtrl.refresh();
      });
    };

    $scope.exportCennikTemplate = function() {
      Outputs.openXLSExportUrl('cennikzaznamy', $scope.SearchCtrl.getQuery());
    };

    var unregisterProfileObservers = [];

    var events = CennikZaznam.registerEvents(
      function () {
        return $scope.cennikzaznamy;
      },
      function () {
        return $scope.SearchCtrl;
      }
    );
    $scope.$on("$destroy", function () {
      CennikZaznam.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    $scope.exportCennikItems = function() {
      Outputs.openExportUrlWithID('cennikzaznamy', null, $scope.SearchCtrl.getQuery(), 'export/items');
    };

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

  }]);
