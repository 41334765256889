'use strict';

angular.module('casist.admin')
  .factory('Group', ['Restangular', 'BaseModel', 'BaseCollectionModel', function (Restangular, BaseModel, BaseCollectionModel) {
    Restangular.extendModel('groups', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.name) || this.name === '') {
            this.addError('name', 'Názov skupiny musí byť vyplnený');
            result = false;
          }
          return result;
        }
      });
    });
    return BaseCollectionModel.object('settings/groups');
  }]);
