'use strict';

angular.module('casist.admin')
  .factory('User', ['Restangular', 'BaseModel', 'BaseCollectionModel', function (Restangular, BaseModel, BaseCollectionModel) {
    Restangular.extendModel('users', function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.username) || this.username === '') {
            this.addError('username', 'Prihlasovací e-mail musí byť vyplnený');
            result = false;
          }
          if (!angular.isDefined(this.first_name) || this.first_name === '') {
            this.addError('ico', 'Meno nemôže byť prázdne');
            result = false;
          }
          if (!angular.isDefined(this.last_name) || this.last_name === '') {
            this.addError('ulica', 'Priezvisko nemôže byť prázdne');
            result = false;
          }
          return result;
        },
        changePassword: function(currentPassword, newPassword) {
          return this.all('change-password').post({passwordCurrent: currentPassword, password: newPassword});
        },
        getAgendy: function() {
          return this.all('agendas').getList();
        }
      });
    });
    return BaseCollectionModel.object('settings/users');
  }]);
