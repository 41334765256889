'use strict';

angular.module('casist.svb')
  .controller('PriestoryBaseCtrl', ['$scope', '$timeout', 'priestory', '$location', 'Priestor', 'TabService', '$state', '$stateParams', '$controller', 'vchody',
    function($scope, $timeout, priestory, $location, Priestor, TabService, $state, $stateParams, $controller, vchody) {
      $scope.SearchCtrl = $controller('SearchCtrl');
      $scope.priestory = priestory;
      $scope.priestoryList = {page: $stateParams.page || Math.ceil(priestory.count / $scope.SearchCtrl.per_page) || 'last', num_pages: 0};
      $scope.filterChanged = function(filter) {
        var promise = Priestor.objects().getList(filter);
        $scope.priestory.loading = true;
        promise.then(function(data) {
          TabService.registerScope('priestor', $scope);
          $scope.priestory = data;
          delete $scope.priestory.loading;
        });
        return promise;
      };
      $scope.refreshPriestoryList = function() {
        return $scope.filterChanged(angular.extend($scope.SearchCtrl.getQuery(), {page: $scope.priestoryList.page}));
      };
      $scope.refreshVchody = function(dom) {
        $scope.firstSetup = $scope.domy.length === 0;
        if ($scope.firstSetup && $scope.SearchCtrl.filters.top.dom) {
          delete $scope.SearchCtrl.filters.top.dom;
        }
        if (!$scope.firstSetup && !$scope.SearchCtrl.filters.top.dom) {
          $scope.SearchCtrl.filterSelection('dom', $scope.domy[0], false);
          $scope.domChanged($scope.domy[0]);
        }
        if (!dom) {
          dom = _.find($scope.domy, {id: parseInt($scope.SearchCtrl.filters.top.dom)});
        }
        $scope.vchody = [];
        if (!dom) {
          return;
        }
        _.each(vchody, function(obj) {
          if (obj.dom === dom.id) {
            $scope.vchody.push(obj);
          }
        });
      };
      $scope.domChanged = function(dom) {
        var searchParams = {page: 'last', dom: dom.id};
        $scope.refreshVchody(dom);
        if ($scope.vchody.length) {
          searchParams.vchod = $scope.vchody[0].id;
          $scope.SearchCtrl.filterSelection('vchod', $scope.vchody[0].id, false);
        }
        $location.search(searchParams);
      };
      $scope.vchodChanged = function(vchod) {
        $location.search({page: 'last', dom: _.find(vchody, {id: vchod.id}).dom, vchod: vchod.id});
      };
      $scope.vchody = [];
      $scope.domy = [];
      $scope.firstSetup = false;
      Priestor.getQuery(vchody).then(function(vchod) {
        if (!vchod) {
          $scope.firstSetup = true;
          return;
        }
        _.each(vchody, function(obj) {
          if (!_.find($scope.domy, {id: obj.dom})) {
            $scope.domy.push({id: obj.dom, nazov: obj.dom_data.nazov});
          }
          if (obj.dom === vchod.dom) {
            $scope.vchody.push(obj);
          }
        });
        $scope.SearchCtrl.filterSelection('dom', vchod.dom, false);
        $scope.SearchCtrl.filterSelection('vchod', vchod.id, false);
      });

      $scope.priestoryTable = [
        { width: 40, rowspan: 2 },
        { field: 'nazov', label: 'priestory.NAZOV', filterCollapse: 'right', rowspan: 2 },
        { field: 'poschodie', label: 'priestory.POSCHODIE', rowspan: 2 },
        { field: 'vlastnik', label: 'vlastnici.VLASTNIK', rowspan: 2, searchable: false, sortable: false },
        { field: 'plocha_celkom', label: 'priestory.PLOCHA_CELKOM', type: 'number', sum: true },
        { field: 'plocha_byt', label: 'priestory.PLOCHA_BYT', type: 'number', sum: true },
        { field: 'plocha_pivnica', label: 'priestory.PLOCHA_PIVNICA', type: 'number', sum: true },
        { field: 'plocha_balkon', label: 'priestory.PLOCHA_BALKON', type: 'number', sum: true },
        { field: 'vykur_plocha', label: 'priestory.VYKUR_PLOCHA', type: 'number', sum: true },
        { field: 'vyska', label: 'priestory.VYSKA', type: 'number' },
        { field: 'vykur_objem', label: 'priestory.VYKUR_OBJEM', type: 'number', sum: true },
        { field: 'podiel', label: 'priestory.PODIEL', type: 'int', sum: true },
        { field: 'poznamka', label: 'priestory.POZNAMKA' }
      ];
      $scope.SearchCtrl.init({
        onChange: $scope.filterChanged,
        columnsDef: $scope.priestoryTable,
        sort: {sort: 'nazov'},
        page: $scope.priestoryList.page
      });
      var events = Priestor.registerEvents(function() { return $scope.priestory; }, function() { return $scope.SearchCtrl; });
      $scope.$on('$destroy', function() {
        Priestor.unregisterEvents(events);
      });
    }
  ])
  .controller('PriestoryCtrl', ['$scope', 'Restangular', 'Priestor', 'Profile', '$controller', '$q', 'TabService', 'Outputs', '$modal', 'Notification', '$state', '$stateParams', 'vchody',
    function ($scope, Restangular, Priestor, Profile, $controller, $q, TabService, Outputs, $modal, Notification, $state, $stateParams, vchody) {
    $scope.changePage = function() {
      $state.go($state.current, {page: $scope.SearchCtrl.page});
    };

    $scope.manageDomy = function() {
      $modal.open({
        templateUrl: 'svb/domy/domy.html',
        controller: 'DomyCtrl',
        backdrop: 'static',
        windowClass: 'detail-md',
        resolve: {
          domy: function() {
            return $scope.domy;
          },
          vchody: function() {
            return vchody;
          }
        }
      }).result.then(function() {
          $scope.refreshVchody();
      });
    };

    //var unregisterProfileObservers = [];
    //
    //unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
    //  $scope.SearchCtrl.cancelFilter(false);
    //  $scope.SearchCtrl.setLastPage();
    //  $scope.SearchCtrl.refresh();
    //}));
    //$scope.$on('$destroy', function() {
    //  for (var i = 0; i < unregisterProfileObservers.length; i++) {
    //    unregisterProfileObservers[i]();
    //  }
    //});

  }]);
