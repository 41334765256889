'use strict';

angular.module('casist')
  .directive('appMenuItem', ['TabService', function (TabService) {
    return {
      template: '<a href="" class="list-group-item smaller" ng-click="openWindow()">{{ label | jednoducheUctoLabels | translate }}</a>',
      replace: true,
      scope: {
        'label': '=',
        'newTabHandler': '&',
      },
      restrict: 'EA',
      link: function postLink(scope, element, attrs) {
        scope.openWindow = function() {
          scope.$eval(scope.newTabHandler()(scope.id, scope.label, scope.loc));
        }
        var tabs = TabService.getTabs();
        for (var i in tabs) {
          if (tabs[i].title == scope.label) {
            element.addClass('opened');
            break;
          }
        }
        TabService.registerObserverCallback(function() {
          var tabs = TabService.getTabs();
          var found = false;
          for (var i in tabs) {
            if (tabs[i].title == scope.label) {
              element.addClass('opened');
              found = true;
              break;
            }
          }
          if (!found)
            element.removeClass('opened');
        });
        scope.loc = (angular.isDefined(attrs.location) ? attrs["location"] : "/");
        scope.id = attrs.id;
      }
    };
  }]);
