'use strict';

angular.module('casist.svb')
  .factory('AbstractVlastnik', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    return {
      objectModel: function(model) {
        angular.extend(model, BaseModel);
        return angular.extend(model, {
          validate: function() {
            if (!this.meno) {
              this.addError('meno', 'Pole meno je povinné.');
              return false;
            }
            if (!this.adresat) {
              this.addError('adresat', 'Pole adresát je povinné.');
              return false;
            }
            return BaseModel.validate();
          }
        });
      },
      collectionModel: function() {
        return {
          getPerms: function () {
            return Profile.get('perms').vlastnik;
          },
          getQuery: function(vchody, params) {
            var query = {};
            if (vchody.length) {
              query = {vchod: vchody[0].id};
            }
            if (params.vchod) {
              query.vchod = params.vchod;
            }
            return query;
          },
          get: function (query) {
            return Restangular.all('vlastnici').getList({search:query});
          }
        };
      }
    }
  }]).factory('Vlastnik', ['Restangular', 'AbstractVlastnik', 'BaseCollectionModel', function (Restangular, AbstractVlastnik, BaseCollectionModel) {
    Restangular.extendModel('vlastnici', function(model) {
      return angular.extend(model, AbstractVlastnik.objectModel(model));
    });
    return angular.extend(BaseCollectionModel.object('vlastnici'), AbstractVlastnik.collectionModel());
  }]).factory('PriestorVlastnik', ['Restangular', 'AbstractVlastnik', 'BaseCollectionModel', function (Restangular, AbstractVlastnik, BaseCollectionModel) {
    Restangular.extendModel('vlastnici', function(model) {
      return angular.extend(model, AbstractVlastnik.objectModel(model));
    });
    return angular.extend(BaseCollectionModel.object('priestory/vlastnici'), AbstractVlastnik.collectionModel());
  }])
  .factory('Vchod', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile',
    function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
      var ENDPOINT = "vchody";
      var vchodModel = function(model) {
        angular.extend(model, BaseModel);
        return angular.extend(model, {
          validate: function() {
            if (!this.nazov) {
              this.addError('nazov', 'Pole vchod je povinné.');
              return false;
            }
            return BaseModel.validate();
          }
        });
      };
      Restangular.extendModel(ENDPOINT, vchodModel);
      Restangular.extendModel('vchodybulk', vchodModel);

      var parent = BaseCollectionModel.object(ENDPOINT);
      return angular.extend(parent, {
        getPerms: function() {
          return Profile.get('perms').vchod;
        },
        get: function (query) {
          return parent.objects().getList({search:query});
        },
        bulk: function() {
          return BaseCollectionModel.object('vchody/vchodybulk');
        }
      });
    }])
  .factory('Priestor', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'Vlastnik', 'FirmaPrevadzka', '$modal', 'Upload', 'Vchod', '$stateParams',
    function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, Vlastnik, FirmaPrevadzka, $modal, Upload, Vchod, $stateParams) {
    var ENDPOINT = "priestory";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.nazov) {
            this.addError('nazov', 'Pole názov je povinné.');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').priestor;
      },
      get: function (query, pageQuery, otherParams) {
        return parent.objects().getList(angular.extend(angular.extend({search:query}, otherParams), pageQuery));
      },
      getQuery: function(vchody, stateParams) {
        var deferred = $q.defer();
        if (!stateParams) {
          stateParams = $stateParams;
        }
        var parseActive = function(vchody) {
          var active;
          if (stateParams.vchod) {
            active = _.find(vchody, {id: parseInt(stateParams.vchod)});
          } else {
            active = _.find(vchody, {active: true});
          }
          if (!active && vchody.length) {
            active = vchody[0];
          }
          return active;
        };
        if (vchody) {
          deferred.resolve(parseActive(vchody));
        } else {
          Vchod.objects().getList().then(function (data) {
            deferred.resolve(parseActive(data));
          }, function () {
            deferred.reject();
          });
        }
        return deferred.promise;
      }
    });
  }])
  .factory('Merac', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    var druhy = [
      {id: 1, druh: 'SV'},
      {id: 2, druh: 'TV'},
      {id: 3, druh: 'Kúrenie'}
    ];

    Restangular.extendModel('merace', function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.nazov) {
            this.addError('nazov', 'Pole názov je povinné.');
            return false;
          }
          return BaseModel.validate();
        },
        getDruhText: function() {
          return _.find(druhy, {id: this.druh}).druh;
        }
      });
    });

    var parent = BaseCollectionModel.object('priestory/merace');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').merac;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      },
      getDruhy: function(druh) {
        if (druh) {
          return _.find(druhy, {id: druh}).druh;
        } else {
          return druhy;
        }
      }
    });
  }])
  .factory('Osoba', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    Restangular.extendModel('vlastnikosoby', function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.datum_od) {
            this.addError('datum_od', 'Dátum od musí byť zadaný.');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('priestory/vlastnici/vlastnikosoby');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').osoba;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }])
  .factory('Dom', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile) {
    Restangular.extendModel('domy', function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          if (!this.nazov) {
            this.addError('nazov', 'Názov musí byť zadaný.');
            return false;
          }
          return BaseModel.validate();
        }
      });
    });

    var parent = BaseCollectionModel.object('domy');
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').dom;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
