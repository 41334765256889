'use strict';

angular.module('casist.ucto').controller('BankaImportCtrl',
              ['$scope', '$modalInstance', 'ucty', 'ucet', 'list', 'filename', '$timeout', 'Profile', 'BankovyDoklad', 'Firma', 'BankAccount', 'Predkontacia', 'Dialog', 'DokladTypy', 'Global', 'Prevadzka',
              function ($scope, $modalInstance, ucty, ucet, list, filename, $timeout, Profile, BankovyDoklad, Firma, BankAccount, Predkontacia, Dialog, DokladTypy, Global, Prevadzka) {

    $scope.ucet = _.find(ucty, {id: ucet}).kod;
    $scope.list = list;
    for (var i = 0; i < list.length; i++) {
      if (!list[i].popis) {
        list[i].popis = list[i].poznamka;
      }
    }
    var originalList = deepcopy(list);
    $scope.filename = filename;
    $scope.Firma = Firma;
    $scope.Predkontacia = Predkontacia;
    $scope.page = 1;
    $scope.perPage = 10;
    $scope.total = $scope.list.length;

    $scope.setPage = function(page) {
      $scope.page = page;
    };

    $scope.revert = function() {
      $scope.list = deepcopy(originalList);
    };

    $scope.getParovanie = function(query, pageQuery, item) {
      if (/^(VF|DF)[0-9]+$/.test(query)) {
        query = query.replace('VF', '').replace('DF', '');
      }
      var q = angular.extend({search: query, firma: item.firma, dd: item.operacia === 1 ? DokladTypy.VF.dd : DokladTypy.DF.dd}, pageQuery);
      if (item.suggestions.length) {
        q.vs = item.vs;
      }
      return BankovyDoklad.parovanie().getList(q);
    };
    $scope.selectedParovanie = function(doklad, item) {
      copyWeakSrc(_.pick(doklad, 'vs', 'firma', 'firma_data'), item);
      if (doklad.predkontacia && doklad.predkontacia_data.predkontacia_bd) {
        item.predkontacia = doklad.predkontacia_data.predkontacia_bd;
        item.predkontacia_data = doklad.predkontacia_data.predkontacia_bd_data;
        item.popis = doklad.predkontacia_data.predkontacia_bd_data.popis;
      }
    };
    $scope.cancelSuggestions = function(item) {
      item.suggestions = [];
    };
    $scope.getPredkontacie = function(query, pageQuery, item) {
      return Predkontacia.get(query, DokladTypy.BD.dd, item.operacia+',null');
    };
    $scope.addPredkontacia = function(nazov, item) {
      return Predkontacia.add(nazov, DokladTypy.BD.dd, undefined, item.operacia);
    };
    $scope.selectedPredkontacia = function(predkontacia, item) {
      item.popis = predkontacia.popis;
    };

    $scope.selectedSuggestion = function(item) {
      var suggestion = _.find(item.suggestions, {faktura_field: parseInt(item.faktura_field)});
      if (!suggestion) {
        return;
      }
      var popisBackup = angular.copy(item.popis);
      angular.extend(item, _.pick(suggestion, 'firma', 'firma_data', 'predkontacia', 'predkontacia_data', 'popis'));
      if (!item.popis) {
        item.popis = popisBackup;
      }
    };
    $scope.prevadzky = Prevadzka.restangularize(angular.copy(Profile.get('prevadzky')));

    $scope.ok = function () {
      if (!$scope.vypis) {
        Dialog.alert('Výpis nemôže byť prázdny.');
        return;
      }
      for (var i = 0; i < $scope.list.length; i++) {
        $scope.list[i].vypis = $scope.vypis;
        $scope.list[i].d_zdp = $scope.list[i].d_vyst;
        $scope.list[i].prevadzka = Profile.get('lastPrevadzka').id || $scope.prevadzky[0].id;
        $scope.list[i].sd1 = Global.get('sd1');
        $scope.list[i].sd2 = Global.get('sd2');
        $scope.list[i].dd = DokladTypy.BD.dd;
      }
      $modalInstance.close($scope.list);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
