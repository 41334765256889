describe('Zalohove faktury', function() {
	var $rootScope, $scope, Auth, createController;
  beforeEach(module('casist.core'));
	beforeEach(module('casist.sklad'));
  beforeEach(module('casist.ucto'));

	beforeEach(inject(function($injector) {
		$rootScope = $injector.get('$rootScope');
		Auth = $injector.get('Auth');
    $scope = $rootScope.$new();
    var $controller = $injector.get('$controller');
    createController = function() {
      return $controller('FakturaciaCtrl', {
        $scope: $scope
      });
    };
		Auth.profile().set('agenda', {id: 1}, true);
		Auth.profile().set('prevadzky', [{prevadzka: "Test", id: 1, agenda: 1}], true);
    Auth.profile().set('perms', {faktura: {}});
	}));

	it('should have controller defined', function() {
		var controller = createController();
		expect(controller).toBeDefined();
		expect($scope.prevadzky).toBeDefined();
		expect($scope.prevadzky.length).toBeGreaterThan(0);
	});
});