"use strict";

angular.module("casist.ucto").factory("PenaznyDennik", [
  "Restangular",
  "$q",
  "BaseModel",
  "BaseCollectionModel",
  "Profile",
  "$modal",
  "DokladCommon",
  "DokladTypy",
  "$translate",
  function (
    Restangular,
    $q,
    BaseModel,
    BaseCollectionModel,
    Profile,
    $modal,
    DokladCommon,
    DokladTypy,
    $translate
  ) {
    var ENDPOINT = "penaznydennik";
    Restangular.extendModel(ENDPOINT, function (model) {
      angular.extend(model, BaseModel);
      angular.extend(model, DokladCommon);
      angular.extend(model, {
        getCenaFields: function () {
          var mnozstvoField = this.operacia === 1 ? "mv" : "mp";
          var cenaField = this.operacia === 1 ? "cena_vydaj" : "cena_prijem";
          return { cena: cenaField, mnozstvo: mnozstvoField };
        },
      });
      return angular.extend(model, {
        validate: function () {
          BaseModel.validate();
          var result = true;
          if (!angular.isDefined(this.d_vyst) || this.d_vyst === "") {
            this.addError("d_vyst", "Pole nemôže byť prázdne");
            result = false;
          }
          if (!this.d_zdp) {
            this.addError("d_zdp", "Pole nemôže byť prázdne");
            result = false;
          }
          if (this.dd === DokladTypy.PD.dd && !this.pokladna) {
            this.addError("pokladna", "Pole nemôže byť prázdne");
            result = false;
          }
          if (this.dd === DokladTypy.BD.dd) {
            if (!this.ucet) {
              this.addError("ucet", "Pole nemôže byť prázdne");
              result = false;
            }
            if (!this.vypis) {
              this.addError("vypis", "Pole nemôže byť prázdne");
              result = false;
            }
          }
          //if (this.dd === DokladTypy.PD.dd && !this.firma) {
          //  this.addError('firma', $translate.instant('errors.FIRMA'));
          //  result = false;
          //}
          if (this.vs && !/^[0-9]*$/.test(this.vs)) {
            this.addError("vs", "Variabilný symbol musí obsahovať iba čísla!");
            result = false;
          }
          return result;
        },
      });
    });

    var openDetail = function (
      item,
      list,
      SearchCtrl,
      config,
      templateUrl,
      controllerName
    ) {
      var modalInstance = $modal.open({
        templateUrl: templateUrl,
        controller: controllerName,
        backdrop: "static",
        windowClass: "detail",
        resolve: {
          doklad: function () {
            return item ? item : {};
          },
          list: function () {
            return list || [];
          },
          SearchCtrl: function () {
            return SearchCtrl;
          },
          config: function () {
            return angular.extend({ predkontacie: true, ucty: true }, config);
          },
        },
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function () {
        return Profile.get("perms").penaznydennik;
      },
      parovanie: function () {
        return Restangular.all("pokladna").all("parovanie");
      },
      getUctoFields: function () {
        return [
          "zaklad_d1",
          "d1",
          "zaklad_d2",
          "d2",
          "zaklad_d0",
          "spolu",
          "suma1",
          "suma2",
          "suma3",
          "suma4",
          "suma5",
        ];
      },
      precislovanieRiadkov: function (query) {
        return Restangular.all(ENDPOINT)
          .all("precislovanie-riadkov")
          .post({}, query);
      },
      globalneUctovanie: function (predkontacia, query, canceller) {
        if (canceller) {
          return Restangular.all(ENDPOINT)
            .withHttpConfig({ timeout: canceller })
            .customPUT(
              { predkontacia: predkontacia },
              "globalneuctovanie",
              query
            );
        } else {
          return Restangular.all(ENDPOINT).customPUT(
            { predkontacia: predkontacia },
            "globalneuctovanie",
            query
          );
        }
      },
      editPokladna: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(
          doklad,
          list,
          searchCtrl,
          config,
          "ucto/pokladnicnedoklady/pokladnicnedoklady-detail.html",
          "PokladnicnyDokladDetailCtrl"
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      editBanka: function (doklad, list, searchCtrl, config) {
        var promise = $q.defer();
        if (doklad) {
          parent.restangularize(doklad);
        }
        openDetail(
          doklad,
          list,
          searchCtrl,
          config,
          "ucto/bankovedoklady/bankovedoklady-detail.html",
          "BankovyDokladDetailCtrl"
        ).then(
          function (data) {
            promise.resolve(data.record);
          },
          function () {
            promise.reject();
          }
        );
        return promise.promise;
      },
      edit: function (doklad, list, searchCtrl, config) {
        if (doklad.dd === DokladTypy.BD.dd) {
          return this.editBanka(doklad, list, searchCtrl, config);
        } else {
          return this.editPokladna(doklad, list, searchCtrl, config);
        }
      },
    });
  },
]);
