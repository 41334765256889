'use strict';

angular.module('casist.ucto')
  .controller('PredkontaciaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Predkontacia', 'SearchCtrl', 'predkontacia', 'predkontacie', 'Ucet', 'DokladTypy', 'Doklad', 'config', 'TypDPH', 'Profile', '$q', 'Dialog',
              function($scope, $modalInstance, $controller, Predkontacia, SearchCtrl, predkontacia, predkontacie, Ucet, DokladTypy, Doklad, config, TypDPH, Profile, $q, Dialog) {
    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Predkontacia
    });

    var defaultPredkontacia = {
      typ: 1
    };

    // $scope.Ucet = Ucet;
    var uctyQueries = [];
    Ucet.get().then(function(ucty) {
      $scope.ucty = ucty;

      $scope.getUcty = function(query, page, parent) {
        if (query === null) {
          query = undefined;
        }
        var re = new RegExp(query, 'i');
        var matches = [];
        for (var i = 0; i < $scope.ucty.length; i++) {
          if ((($scope.predkontacia.operacia && (!$scope.ucty[i].operacia || ($scope.ucty[i].operacia === $scope.predkontacia.operacia))) || !$scope.predkontacia.operacia) && (!$scope.ucty[i].only_md || ($scope.ucty[i].only_md && parent.type === 'md')) && (!$scope.ucty[i].only_d || ($scope.ucty[i].only_d && parent.type === 'd')) && ($scope.ucty[i].ucet.match(query) || $scope.ucty[i].nazov.match(re))) {
            matches.push(angular.copy($scope.ucty[i]));
          }
        }
        return matches;
      };
      var result;
      for (var i = 0; i < uctyQueries.length; i++) {
        result = $scope.getUcty(uctyQueries[i].query, uctyQueries[i].page, uctyQueries[i].parent);
        uctyQueries[i].deferred.resolve(result);
      }
    });
    $scope.getUcty = function(query, page, parent) {
      var deferred = $q.defer();
      uctyQueries.push({query: query, page: page, parent: parent, deferred: deferred});
      return deferred.promise;
    };

    $scope.addUcet = function(ucet) {
      var promise = Ucet.add(ucet);
      promise.then(function(data) {
        $scope.ucty.push(angular.copy(data));
      });
      return promise;
    };
    $scope.PD_DD = DokladTypy.PD.dd;
    $scope.BD_DD = DokladTypy.BD.dd;
    $scope.operacie = [
      {id: 1, operacia: '+'},
      {id: -1, operacia: '-'}
    ];

    $scope.showOperacia = function() {
      return [DokladTypy.BD.dd, DokladTypy.PD.dd].indexOf(parseInt($scope.predkontacia.typ)) !== -1;
    };
    $scope.showPredkontaciaUhrada = function() {
      return !Profile.get('jednoducheUcto') && (parseInt($scope.predkontacia.typ) === DokladTypy.VF.dd || parseInt($scope.predkontacia.typ) === DokladTypy.DF.dd);
    };
    $scope.showPredkontaciaTypDPH = function() {
      return [DokladTypy.IN.dd, DokladTypy.BD.dd].indexOf(parseInt($scope.predkontacia.typ)) === -1;
    };
    $scope.Predkontacia = Predkontacia;
    $scope.getPredkontaciePD = function(query) {
      return Predkontacia.objects().getList({search: query, typ: DokladTypy.PD.dd});
    };
    $scope.getPredkontacieBD = function(query) {
      return Predkontacia.objects().getList({search: query, typ: DokladTypy.BD.dd});
    };
    $scope.addPredkontaciaPD = function(predkontacia) {
      return Predkontacia.add(predkontacia, DokladTypy.PD.dd);
    };
    $scope.addPredkontaciaBD = function(predkontacia) {
      return Predkontacia.add(predkontacia, DokladTypy.BD.dd);
    };

    $scope.$watch('predkontacia', function() {
      if ($scope.predkontacia.id) {
        $scope.predefinedType = $scope.predkontacia.typ;
      } else if (config && config.typ) {
        $scope.predefinedType = config.typ;
      } else {
        $scope.predefinedType = undefined;
      }
    });

    $scope.typyDPH = [];
    if (Profile.get('platcadph')) {
      TypDPH.valid().objects().getList({modul: '0,1,2'}).then(function(data) {
        $scope.typyDPH = data;
      });
    }

    $scope.RecordCtrl.main.init({
      endpoint: 'predkontacie',
      model: 'predkontacia',
      master: predkontacia,
      list: predkontacie,
      defaultItem: defaultPredkontacia,
      searchCtrl: SearchCtrl,
      $modalInstance: $modalInstance
    });
    $scope.typy = $scope.typy = Predkontacia.typy();

    if (!isEmpty(predkontacia)) {
      $scope.predkontacia = Predkontacia.copy(predkontacia);
      _.defaults($scope.predkontacia, defaultPredkontacia);
      if ($scope.predkontacia.typ) {
        $scope.predefinedType = $scope.predkontacia.typ;
      }
    } else {
      $scope.predkontacia = angular.copy(defaultPredkontacia);
    }

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: predkontacia});
      });
    };

    $scope.ok = function () {
      var zapis = function() {
        var promise = $scope.RecordCtrl.main.save();
        if (promise) {
          promise.then(function(result) {
            $modalInstance.close(result);
          });
        }
      };
      var changed = false;
      if (!isEmpty(predkontacia)) {
        var fields = Doklad.getUctoFields();
        for (var i = 0; i < fields.length; i++) {
          if (angular.copy(predkontacia)[fields[i]+'_md'] !== $scope.predkontacia[fields[i]+'_md'] || angular.copy(predkontacia)[fields[i]+'_d'] !== $scope.predkontacia[fields[i]+'_d']) {
            changed = true;
            break;
          }
        }
      }
      if ($scope.predkontacia.id && changed) {
        Dialog.confirm('Účtovanie v predkontácii bolo pozmenené.<br/>Predkontácia bude vymazaná zo všetkých dokladov, v ktorých je použitá. Chcete pokračovať?').then(function() {
          zapis();
          return;
        });
      } else {
        zapis();
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };
  }]);
