'use strict';

var directiveSettings = function(directiveName) {
  var directiveAltName = directiveName;
  if (directiveName == "tooltipHtmlUnsafe")
    directiveAltName = "tooltip";

  var showEvent = 'show-'+directiveAltName;
  var hideEvent = 'hide-'+directiveAltName;

  var directiveConfig = ['$tooltipProvider', function($tooltipProvider) {
    var trigger = {};
    trigger[showEvent] = hideEvent;
    $tooltipProvider.setTriggers(trigger);
    $tooltipProvider.options({
      'placement': 'right',
      'appendToBody': true
    });
  }];

  var directiveFactory = function() {
    return ['$timeout', function($timeout) {
      var directive = {
        restrict: 'A',
        link: function (scope, element, attrs) {
          // change default behaviour only when toggle attribute is present
          if (attrs[directiveAltName+'Toggle'])
            attrs[directiveAltName+'Trigger'] = showEvent;

          var redrawPromise;
          $(window).on('resize', function() {
            if (redrawPromise)
              $timeout.cancel(redrawPromise);
            
            redrawPromise = $timeout(function() {
              if (!scope['tt_isOpen'])
                return;

              element.triggerHandler(hideEvent);
              element.triggerHandler(showEvent);
            }, 100);
          });
          var tooltipToggled = false;
          var focused = false;
          if (angular.isDefined(attrs[directiveAltName+'Toggle'])) {
            scope.$watch(attrs[directiveAltName+'Toggle'], function(value) {
              if (value == undefined)
                return;
              tooltipToggled = value;
              if (focused && tooltipToggled && !scope['tt_isOpen']) {
                $timeout(function() {
                  element.triggerHandler(showEvent);
                });
              }
              if (!tooltipToggled && scope['tt_isOpen']) {
                $timeout(function() { 
                  element.triggerHandler(hideEvent);
                });
              }
            });
          }
          element.bind('focus', function() {
            focused = true;
            if (tooltipToggled && !scope['tt_isOpen']) {
              $timeout(function() {
                element.triggerHandler(showEvent);
              });
            }
          });
          element.bind('blur', function() {
            focused = false;
            if (scope['tt_isOpen']) {
              $timeout(function() { 
                element.triggerHandler(hideEvent);
              });
            }
          });
        }
      }
      return directive;
    }];
  }
  var directive = directiveFactory();

  var directiveSettings = {
    directiveName: directiveName,
    directive: directive,
    directiveConfig: directiveConfig,
  };
  return directiveSettings;
}

var popoverToggle = directiveSettings('popover');
var tooltipToggle = directiveSettings('tooltip');
var tooltipToggleHTML = directiveSettings('tooltipHtmlUnsafe');

angular.module('casist.utils')
  .directive(popoverToggle.directiveName, popoverToggle.directive)
    .config(popoverToggle.directiveConfig)
  .directive(tooltipToggle.directiveName, tooltipToggle.directive)
    .config(tooltipToggle.directiveConfig)
  .directive(tooltipToggleHTML.directiveName, tooltipToggleHTML.directive)
    .config(tooltipToggleHTML.directiveConfig);