'use strict';

angular.module('casist.ubytovanie')
  .controller('PrenosDlgCtrl', ['$scope', '$modalInstance', 'Dialog', '$q', function($scope, $modalInstance, Dialog, $q) {
    $scope.data = {
      mesiac: getCurrentDate()
    };

    $scope.ok = function() {
      $modalInstance.close($scope.data);
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancelled');
    };
  }]);
