'use strict';

angular.module('casist.sklad')
  .factory('Pohyb', ['Restangular', 'BaseModel', 'BaseCollectionModel', 'DokladTypyPrijem', '$filter', '$timeout', '$q', function (Restangular, BaseModel, BaseCollectionModel, DokladTypyPrijem, $filter, $timeout, $q) {
    var ENDPOINT = "pohyb";

    Restangular.extendModel('pohyb', function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function(record) {
          var ret = BaseModel.validate();
          var dph = parseInt(this.dph);
          if (this.dph && (dph !== 0 && dph !== parseInt(record.sd1) && dph !== parseInt(record.sd2))) {
            this.addError('dph', 'DPH sa nezhoduje so sadzbou v hlavičke.');
            return false;
          }
          return ret;
        },
        prepocitatKurzom: function(faktura) {
          var kurz = parseFloat(faktura.kurz);
          var cenaField = faktura.getCenaFields().cena;
          kurz = parseFloat(kurz);
          if (faktura.primarna_zm) {
            this.cena_zm = this.cena_edit;
            this[cenaField] = Math.round( (parseFloat(this.cena_edit) / kurz)*10000) / 10000.0;
          } else {
            this.cena_zm = Math.round( (parseFloat(this.cena_edit) * kurz)*10000) / 10000.0;
            this[cenaField] = this.cena_edit;
          }
        },
        /**
         * Nastavi nezlavnene sumy, ak neboli nastavene a prepocita cena_prijem a cena_zm zlavou na polozke
         * Nastavi editacnu cenu podla toho, ci sa zobrazuje cudzia mena alebo hlavna
        **/
        recountDiscountedPrice: function(faktura) {
          if (!this.zlava)
            this.zlava = 0;

          var cenaField = faktura.getCenaFields().cena;
          if (!this[cenaField]) {
            if (faktura.mena && faktura.kurz) {
              this.prepocitatKurzom(faktura);
            } else {
              this[cenaField] = this.cena_edit;
              this.cena_zm = 0;
            }
          }
          if (!parseFloat(this.nezlavnena))
            this.nezlavnena = this[cenaField];
          if (!parseFloat(this.nezlavnena_zm))
            this.nezlavnena_zm = this.cena_zm;

          this.cena_zm = Math.round( (parseFloat(this.nezlavnena_zm) * ((100 - parseFloat(this.zlava)) / 100.0)) * 1000000) / 1000000.0;
          this[cenaField] = Math.round( (parseFloat(this.nezlavnena) * ((100 - parseFloat(this.zlava)) / 100.0)) * 1000000) / 1000000.0;

          if (faktura.primarna_zm)
            this.cena_edit = this.cena_zm;
          else this.cena_edit = this[cenaField];
        },
        /**
         * Resetovanie zlavy pri zadavani novej sumy.
         * Podla toho, ci pracujeme s hlavnou alebo vedlajsou menou, nastavi prislusne ceny (aj nezlavnene) a dopocita podla kurzu tie druhe
         */
        resetDiscount: function(faktura) {
          var cenaField = faktura.getCenaFields().cena;
          // this is the case when user pressed enter on this field
          if (!angular.isDefined(this.cena_edit))
            return;
          var kurz = parseFloat(faktura.kurz);
          if (isNaN(kurz)) {
            kurz = 0;
          }

          this.zlava = 0;
          if (faktura.primarna_zm) {
            this.cena_zm = this.nezlavnena_zm = this.cena_edit;
            this[cenaField] = this.nezlavnena = Math.round( (parseFloat(this.cena_zm) / kurz)*10000) / 10000.0;
          } else {
            this[cenaField] = this.nezlavnena = this.cena_edit;
            this.cena_zm = this.nezlavnena_zm = Math.round( (parseFloat(this[cenaField]) * kurz)*10000) / 10000.0;
          }
        }
      });
    });
    var parent = BaseCollectionModel.object('pohyb');
    return angular.extend(parent, {

      deleteBulk: function (pohyby) {
        var promises = []
        _.each(pohyby, function (pohyb) {
          promises.push(
            Restangular.all(ENDPOINT).all(pohyb.id).remove()
          )
        });
        return $q.all(promises);
      },

      updateSkladLabelsWithQty: function(scope, item) {
        for (var i = 0; i < scope.currentPrevadzka.sklady.length; i++) {
          delete scope.currentPrevadzka.sklady[i].label;
        }
        if (item) {
          var sklady = scope.currentPrevadzka.sklady;
          var mnozstvo, mnozstvo_num;
          for (var i = 0; i < sklady.length; i++) {
            mnozstvo = _.find(item.mnozstva, {sklad: sklady[i].id});
            if (mnozstvo) {
              sklady[i].label = sklady[i].kod + ': '+$filter('suma')(mnozstvo.mnozstvo, 0)+(item.mj || 'ks');
            } else {
              sklady[i].label = sklady[i].kod + ': 0'+(item.mj || 'ks');
            }
          }
        }
        // force reload of currently selected item
        var currentSklad = parseInt(angular.copy(scope.newItem.sklad));
        scope.newItem.sklad = null;
        if (currentSklad) {
          $timeout(function() {
            scope.newItem.sklad = _.find(scope.currentPrevadzka.sklady, {id: currentSklad}).id;
          });
        }
      }
    });
  }]);
