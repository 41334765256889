angular.module('casist.auth').config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push([
      '$q', '$templateCache', 'Profile',
      function($q, $templateCache, Profile) {

    // Keep track which HTML templates have already been modified !!! in Profile

    // Tests if there are any keep/omit attributes.
    var HAS_FLAGS_EXP = /data-(profileclass|profilekeep|profileomit|keep|omit)/;

    // Tests if the requested url is a html page.
    var IS_HTML_PAGE = /\.html$|\.html\?/i;

    var checkFeature = function(features, profile) {
      var module = undefined;
      var feature = features;
      if (features.match('.')) {
        var parsed_features = features.split('.');
        module = parsed_features[0];
        feature = parsed_features[1];
      }
      return (feature !== undefined) ? (profile.get('perms')[module] && profile.get('perms')[module][feature]) || false : profile.get('perms')[features] || false;
    };

    var checkHasFeature = function(features, profile) {
      return _.every(features.split(','), function(topFeature) {
        topFeature = topFeature.trim();
        var hasFeature1 = !topFeature.match('&') ? true : _.every(topFeature.split('&'), function(feature) {
          return checkFeature(feature.trim(), profile);
        });
        var hasFeature2 = !topFeature.match('\\|') ? true : _.some(topFeature.split('|'), function(feature) {
          return checkFeature(feature.trim(), profile);
        });
        var hasFeature3 = (!topFeature.match('\\|') && !topFeature.match('&')) ? checkFeature(topFeature, profile) : true;
        return hasFeature1 && hasFeature2 && hasFeature3;
      });
    };

    var checkProfileHasFeature = function(features, profile) {
      return _.every(features.split(','), function(topFeature) {
        topFeature = topFeature.trim();
        var hasFeature1 = !topFeature.match('&') ? true : _.every(topFeature.split('&'), function(feature) {
          return profile.get(feature.trim());
        });
        var hasFeature2 = !topFeature.match('\\|') ? true : _.some(topFeature.split('|'), function(feature) {
          return profile.get(feature.trim());
        });
        var hasFeature3 = (!topFeature.match('\\|') && !topFeature.match('&')) ? profile.get(topFeature) : true;
        return hasFeature1 && hasFeature2 && hasFeature3;
      });
    };

    return {
      'response': function(response) {
        var url = response.config.url;
        var responseData = response.data;
        var profile = Profile;
        if (profile.isEmpty()) {
          return response;
        }

        if (!profile.isTplCached(url) && IS_HTML_PAGE.test(url) && HAS_FLAGS_EXP.test(responseData)) {
          // Create a DOM fragment from the response HTML.
          var template = $('<div>').append(responseData);

          // Find and parse the keep/omit attributes in the view.
          template.find('[data-keep],[data-omit]').each(function() {
            var element = $(this),
                data = element.data();
                // keep = data.keep,
                // features = keep || data.omit || '';

            // Check if the user has all of the specified features.
            // var hasFeature = checkHasFeature(features, profile);

            // console.log("Parsujem template ", url, ', checkujem feature: ', features, hasFeature, keep);
            // if (((!keep && hasFeature) || (keep && !hasFeature)) && !profile.get('perms')['is_superuser']) {
              // element.remove();
              // console.log("Vyhadzujem feature: ", features);
            // }
            var shouldKeep = checkHasFeature(data.keep || '', profile);
            var shouldOmit = checkHasFeature(data.omit || '', profile);
            if (!profile.get('perms')['is_superuser'] && ((data.omit && shouldOmit) || (data.keep && !shouldKeep)) && (!profile.get('perms')['local_superuser'] || profile.get('perms')['local_superuser'] !== profile.get('agenda').client)) {
              element.remove();
            }
          });

          template.find('[data-profilekeep],[data-profileomit]').each(function() {
            var element = $(this),
                data = element.data();
                // keep = data.profilekeep,
                // features = keep || data.profileomit || '';

            // var hasFeature = checkProfileHasFeature(features, profile);

            // if (((!keep && hasFeature) || (keep && !hasFeature))) {
              // element.remove();
            // }

            var shouldKeep = checkProfileHasFeature(data.profilekeep || '', profile);
            var shouldOmit = checkProfileHasFeature(data.profileomit || '', profile);
            if ((data.profileomit && shouldOmit) || (data.profilekeep && !shouldKeep)) {
              element.remove();
            }
          });

          template.find('[data-profileclass]').each(function() {
            var element = $(this),
                data = element.data();

            var parsedObject = JSON.parse(data.profileclass.replace(/\'/g, '"'));
            angular.forEach(parsedObject, function(val, key) {
              if (checkProfileHasFeature(val, profile)) {
                element.addClass(key);
              }
            });
          });

          // Set the modified template.
          response.data = template.html();

          // Replace the template in the template cache, and mark the
          // template as modified.
          $templateCache.put(url, response.data);
          profile.addCachedTemplate(url);
        }

        return response;
      },
      // Reject the response in case of an error.
      'responseError': function(response) {
        return $q.reject(response);
      }
    };
  }]);
}]);
