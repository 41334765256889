'use strict';

angular.module('casist.core')
  .factory('Firma', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', 'FirmaOsoba', 'FirmaPrevadzka', '$modal', 'Upload', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, FirmaOsoba, FirmaPrevadzka, $modal, Upload) {
    var ENDPOINT = "firmy";
    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel)
      return angular.extend(model, {
        validate: function() {
          if (this.ulica !== '' || this.cislo !== '') {
            this.adresa = this.ulica+' '+this.cislo;
          }
          return BaseModel.validate();
        },
        osobyProvider: function() {
          return FirmaOsoba.parent(this);
        },
        prevadzkyProvider: function() {
          return FirmaPrevadzka.parent(this);
        }
      });
    });

    var openDetail = function(item, list, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'core/firmy/firma-detail.html',
        controller: 'FirmaDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          firma: function() {
            return item ? item : {};
          },
          firmy: function() {
            return list || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };
    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').firma;
      },
      getImportConfig: function() {
        return Restangular.all(ENDPOINT).all('import').customGET();
      },
      import: function($files, config) {
        var deferred = $q.defer();
        if (!$files.length) {
          return;
        }
        var file = $files[0];
        Upload.upload({
          url: Restangular.all(ENDPOINT).all('import').getRestangularUrl(),
          data: {"fileName" : file.name, 'config': JSON.stringify(config)},
          file: file
        }).progress(function(evt) {
          deferred.notify(parseInt(100.0 * evt.loaded / evt.total));
        }).then(function(data) {
          deferred.resolve(data.data);
        }, function (error) {
          deferred.reject(error);
        });
        return deferred.promise;
      },
      importData: function(data) {
        return Restangular.all(ENDPOINT).all('import').post(data, {import: 1});
      },
      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(firma) {
        var obj = parent.restangularize({firma: firma});
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
