import "jquery";
(window as any).$ = $;
(window as any).jQuery = $;
import "./main";
import "bootstrap3";
import "lodash";
import "select2";
import "angular-ui-select2/src/select2";
import "angular-route";
import "angular-ui-router";
import "angular-resource";
import "angular-cookies";
import "angular-animate";
import "angular-ui-scroll";
import "angular-bindonce";
import "angular-sortable-view";
import "ng-file-upload";
import "angular-translate";
import "angular-translate-loader-static-files";
import "restangular";
import "angular-touch";
import "ngstorage";
import "chartjs";
import io from "socket.io-client";
(window as any).io = io;
import "tc-angular-chartjs";
import "angular-bootstrap/ui-bootstrap-tpls";

import "./components/auth/auth.module";
import "./components/auth/authApplication-directive";
import "./components/auth/oauth-http-interceptor.module";
import "./components/auth/auth-service";
import "./components/auth/template-parser";
import "./components/auth/constants-constant";
import "./components/auth/logout-controller";
import "./components/auth/login-controller";
import "./components/auth/register-controller";
import "./components/auth/activate-controller";
import "./components/auth/password-reset-controller";

import './components/utils/utils.module';
import './components/utils/requestfocus-directive';
import './components/utils/dropdown-fix-directive';
import './components/utils/validationtrigger-directive';
import './components/utils/record-navigation-directive';
import './components/utils/suma-filter';
import './components/utils/time-filter';
import './components/utils/text-cut-filter';
import './components/utils/mesiac-filter';
import './components/utils/start-from-filter';
import './components/utils/capitalize-filter';
import './components/utils/utils-helpers';
import './components/utils/checkmark-filter';
import './components/utils/doklad-filter';
import './components/utils/jednoduche-ucto-labels-filter';

import './core/core.module';
import './components/search/search.module';
import './app.module';
import './main-controller';
import './core/base-model';
import './core/ucty/bankAccount-model';
import './core/ucty/bankAccount-detail-controller';
import './components/record/record-controller';
import './components/record/tableEditor-controller';
import './components/search/search-controller';
import './components/search/search-directive';
import './components/navigation/appMenu-directive';
import './components/navigation/appMenuItem-directive';
import './components/navigation/newTab-directive';
import './components/navigation/tabService-service';
import './components/sockets/socket-service';
import './components/widgets/widgets.module';
import './components/widgets/mctextarea-directive';
import './components/widgets/mctooltip-directive';
import './components/widgets/table/table-directive';
import './components/widgets/table-loading/table-loading-directive';
import './components/widgets/table-sums-directive';
import './components/widgets/linked-element-directive';
import './components/widgets/sales-viewer-directive';
import './components/widgets/casist-date-directive';
import './components/widgets/casist-time-directive';
import './components/widgets/input-suma-directive';
import './components/widgets/form-element-directive';
import './components/widgets/version-viewer-directive';
import './components/widgets/dropdown-directive';
import './components/widgets/select_m2m-directive';
import './components/widgets/notification-service';
import './components/widgets/input-percent-directive';
import './components/widgets/uctovanie-table-directive';
import './components/widgets/imageStatus-directive';
import './components/widgets/rozuctovanie-table-directive';
import './components/widgets/casist-month-directive';
import './components/widgets/dialog-service';
import './components/widgets/image-gallery-directive';
import './components/global/global-service';
import './components/outputs/outputs-service';
import './sklad/sklad.module';
import './sklad/fakturacia/fakturacia-controller';
import './sklad/fakturacia/fakturacia-detail-controller';
import './sklad/fakturacia/fakturacia-model';
import './sklad/fakturacia/fakturacia-doprava-model';
import './sklad/fakturacia/fakturacia-predajca-model';
import './sklad/fakturacia/fakturacia-hlavicka-model';
import './sklad/fakturacia/fakturacia-pata-model';
import './sklad/pohyb/pohyb-model';
import './sklad/pohyb/pohyb-controller';
import './admin/admin.module';
import './admin/users/profile-controller';
import './admin/users/users-model';
import './admin/groups/groups-model';
import './admin/groups/permissions-model';
import './admin/settings/settings-model';
import './admin/settings/settings-controller';
import './admin/settings/printSettings-controller';
import './admin/settings/setup-controller';
import './admin/settings/agendy/agenda-detail-controller';
import './admin/settings/agendy/agenda-model';
import './admin/settings/prevadzky/prevadzka-detail-controller';
import './admin/settings/prevadzky/prevadzka-model';
import './admin/settings/typdph/typdph-model';
import './admin/settings/cisladokladov/cisladokladov-model';
import './sklad/karty/karty-controller';
import './sklad/karty/karty-detail-controller';
import './sklad/karty/karty-model';
import './sklad/karty/typy/typy-controller';
import './core/meny/meny-model';
import './core/obdobia/obdobie-model';
import './core/firmy/firmy-controller';
import './core/firmy/firma-model';
import './core/firmy/firmaosoba-model';
import './core/firmy/firmaprevadzka-model';
import './core/firmy/firmaprevadzka-detail-controller';
import './core/firmy/firma-detail-controller';
import './ucto/ucto.module';
import './ucto/doklad-model';
import './ucto/doklad-typy-constant';
import './ucto/pohladavky/pohladavky-controller';
import './ucto/predkontacie/predkontacie-model';
import './ucto/predkontacie/predkontacie-controller';
import './ucto/predkontacie/predkontacie-detail-controller';
import './ucto/ucty/ucty-model';
import './ucto/ucty/ucty-controller';
import './ucto/ucty/ucty-detail-controller';
import './ucto/prehlady/prehlady-controller';
import './ucto/hlavnakniha/hlavnakniha-controller';
import './ucto/uctosnova/uctosnova-model';
import './ucto/uctosnova/uctosnova-controller';
import './ucto/uctosnova/uctosnova-detail-controller';
import './ucto/rozuctovanie/rozuctovanie-model';
import './ucto/rozuctovanie/rozuctovanie-controller';
import './ucto/rozuctovanie/rozuctovanie-polozka-detail-controller';
import './ucto/uctdennik/uctdennik-controller';
import './ucto/uctdennik/uctdennik-model';
import './ucto/supisdph/supisdph-model';
import './ucto/supisdph/supisdph-controller';
import './ucto/zavazky/zavazky-model';
import './ucto/zavazky/zavazky-typ-model';
import './ucto/zavazky/zavazky-controller';
import './ucto/zavazky/zavazky-detail-controller';
import './ucto/bankovedoklady/bankovedoklady-model';
import './ucto/bankovedoklady/bankovedoklady-controller';
import './ucto/bankovedoklady/bankovedoklady-detail-controller';
import './ucto/bankovedoklady/import/bankaimport-controller';
import './ucto/pokladnicnedoklady/pokladnicnedoklady-model';
import './ucto/pokladnicnedoklady/pokladne/pokladna-model';
import './ucto/pokladnicnedoklady/pokladne/pokladne-controller';
import './ucto/pokladnicnedoklady/pokladnicnedoklady-controller';
import './ucto/pokladnicnedoklady/pokladnicnedoklady-detail-controller';
import './ucto/penaznydennik/penaznydennik-controller';
import './ucto/penaznydennik/penaznydennik-model';
import './ucto/internedoklady/internedoklady-model';
import './ucto/internedoklady/internedoklady-controller';
import './ucto/internedoklady/internedoklady-detail-controller';
import './ucto/urgencie/urgencie-model';
import './ucto/urgencie/urgencie-controller';
import './ucto/urgencie/urgencie-detail-controller';
import './ucto/urgencie/hromadne/urgenciehromadne-controller';
import './ucto/prikazy/prikazy-model';
import './ucto/prikazy/prikazy-controller';
import './ucto/prikazy/prikazy-detail-controller';
import './ucto/prepocetuhrad/prepocetuhrad-controller';
import './ucto/customize_neuhradene/customizeNeuhradene-controller';
import './core/mailbox/mailbox-controller';
import './core/mailbox/sender-controller';
import './core/mailbox/mailbox-model';
import './sklad/ponuky/ponuky-controller';
import './sklad/ponuky/ponuky-detail-controller';
import './sklad/ponuky/ponuky-model';
import './sklad/nakup/nakup-controller';
import './sklad/nakup/nakup-detail-controller';
import './sklad/nakup/nakup-model';
import './sklad/zalohovefaktury/zalohovefaktury-controller';
import './sklad/zalohovefaktury/zalohovefaktury-detail-controller';
import './sklad/zalohovefaktury/zalohovefaktury-model';
import './sklad/cenniky/cenniky-model';
import './sklad/cenniky/cenniky-controller';
import './sklad/cenniky/cenniky-detail-controller';
import './sklad/pozicie/pozicie-model';
import './sklad/pozicie/pozicie-controller';
import './sklad/pozicie/pozicie-detail-controller';
import './ucto/globalne-uctovanie/globalne-uctovanie-controller';
import './core/import/import-controller';
import './sklad/sklady/sklad-model';
import './sklad/sklady/sklady-controller';
import './sklad/sklady/sklady-detail-controller';
import './sklad/mnozstva/mnozstva-model';
import './sklad/mnozstva/mnozstva-controller';
import './svb/svb.module';
import './svb/priestory/priestory-model';
import './svb/priestory/priestory-controller';
import './svb/priestory/priestory-detail-controller';
import './svb/priestory/priestory-merac-controller';
import './svb/priestory/priestory-vlastnik-controller';
import './svb/domy/domy-controller';
import './svb/odpocty/odpocty-model';
import './svb/odpocty/odpocty-controller';
import './svb/odpocty/odpocty-detail-controller';
import './svb/vlastnici/vlastnici-controller';
import './svb/vlastnici/vlastnici-detail-controller';
import './svb/vlastnici/print-predpis-controller';
import './svb/vyuctovanie/vyuctovanie-controller';
import './svb/vyuctovanie/vyuctovanie-model';
import './svb/vyuctovanie/vyuctovanie-detail-controller';
import './svb/vyuctovanie/generovanie_predpisov-controller';
import './svb/prehlad/prehlad-controller';
import './svb/prehlad/prehlad-model';
import './ucto/hlavnakniha/uzavierka/uzavierka-controller';
import './ubytovanie/ubytovanie.module';
import './ubytovanie/ubytovanie/ubytovanie-model';
import './ubytovanie/ubytovanie/ubytovanie-controller';
import './ubytovanie/ubytovanie/ubytovanie-detail-controller';
import './ubytovanie/izby/izby-controller';
import './ubytovanie/izby/izby-model';
import './ubytovanie/izby/izby-detail-controller';
import './ubytovanie/osoby/osoby-model';
import './ubytovanie/osoby/osoby-detail-controller';
import './ubytovanie/ubytovanie/prenos-controller';
import './ubytovanie/ubytovanie/fakturacia-controller';
import './ubytovanie/nastenka/nastenka-controller';
import './ubytovanie/nastenka/nastenka-model';
