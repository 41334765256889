'use strict';

angular.module('casist.sklad')
  .factory('KartaMnozstvo', ['Restangular', '$q', 'BaseModel', 'BaseCollectionModel', 'Profile', '$modal', 'Upload', function (Restangular, $q, BaseModel, BaseCollectionModel, Profile, $modal, Upload) {
    var ENDPOINT = "mnozstva";

    Restangular.extendModel(ENDPOINT, function(model) {
      return angular.extend(model, BaseModel);
    });

    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').kartamnozstvo;
      },
      prepocet: function(rok) {
        return Restangular.all('mnozstva').all('prepocet').post({}, {rok: rok});
      },
      uzavierka: function(rok) {
        return Restangular.all('mnozstva').all('uzavierka').post({}, {rok: rok});
      }
      // edit: function(item, list, searchCtrl) {
      //   var promise = $q.defer();
      //   if (item) {
      //     parent.restangularize(item);
      //   }
      //   openDetail(item, list, searchCtrl).then(function(data) {
      //     promise.resolve(data.record);
      //   }, function() {
      //     promise.reject();
      //   });
      //   return promise.promise;
      // },
      // add: function(kod, options) {
      //   var obj = parent.restangularize(angular.extend({kod: kod}, options));
      //   var promise = $q.defer();
      //   openDetail(obj).then(function(data) {
      //     promise.resolve(data.record);
      //   }, function() {
      //     promise.reject();
      //   });
      //   return promise.promise;
      // },
      // get: function (query, page, parentObject) {
      //   return parent.objects().getList(angular.extend({search:query}, page));
      // }
    });
  }]);
