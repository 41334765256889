'use strict';

angular.module('casist.ucto').controller('BankovyDokladDetailCtrl',
              ['$scope', '$modalInstance', 'doklad', 'list', 'config', '$timeout', '$modal', 'Global', '$http', '$controller', 'Profile', 'SearchCtrl', 'BankovyDoklad', 'Doklad', 'Prevadzka', 'Firma', 'Predkontacia', '$q', 'DokladTypy', 'BankAccount', 'Ucet', 'Mena', 'PenaznyDennik',
              function ($scope, $modalInstance, doklad, list, config, $timeout, $modal, Global, $http, $controller, Profile, SearchCtrl, BankovyDoklad, Doklad, Prevadzka, Firma, Predkontacia, $q, DokladTypy, BankAccount, Ucet, Mena, PenaznyDennik) {

    $scope.hasList = (angular.isFunction(list) ? list().length : list.length) !== 0;
    $scope.endpoint = Profile.get('jednoducheUcto') ? 'penaznydennik' : 'banka';
    $scope.jednoducheUctoClass = Profile.get('jednoducheUcto') ? 'jednoduche-ucto' : '';

    $scope.firmaLabel = 'pokladna.VYPLATENE_KOMU';
    $scope.toggleOperacia = function() {
      $scope.doklad.operacia = $scope.doklad.operacia * (-1);
      $timeout(function() {
        $('#spolu').focus();
      });
    };
    $scope.getParovanie = function(query, pageQuery) {
      if (/^(VF|DF)[0-9]+$/.test(query)) {
        query = query.replace('VF', '').replace('DF', '');
      }
      return BankovyDoklad.parovanie().getList(angular.extend({firma: $scope.doklad.firma, search: query, include: doklad ? doklad.faktura_field : undefined}, pageQuery));
    };
    $scope.clearParovanie = function() {
      $scope.doklad.faktura_field = undefined;
    };
    $scope.selectedParovanie = function(parovanie) {
      if ((doklad.id && !parseFloat($scope.doklad.spolu)) || !doklad.id) {
        $scope.doklad.spolu = parseFloat(parovanie.spolu) - parseFloat(parovanie.uhradene);
      }
      if ((doklad.id && !parseFloat($scope.doklad.spolu_zm)) || !doklad.id) {
        $scope.doklad.spolu_zm = parseFloat(parovanie.spolu_zm) - parseFloat(parovanie.uhradene);
      }
      var fields = ['firma', 'vs', 'firma_data'];
      if (Profile.get('jednoducheUcto')) {
        fields = _.union(fields, ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'suma1', 'suma2', 'suma3', 'suma4', 'suma5']);
      }
      copyWeakSrc(_.pick(parovanie, fields), $scope.doklad);
      if (parovanie.dd === DokladTypy.VF.dd || parovanie.dd === DokladTypy.OP.dd) {
        $scope.doklad.operacia = 1;
      } else {
        $scope.doklad.operacia = -1;
      }
      if (Profile.get('jednoducheUcto')) {
        if (parovanie.predkontacia) {
          $scope.doklad.predkontacia = parovanie.predkontacia;
          $scope.doklad.predkontacia_data = parovanie.predkontacia_data;
          $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
        } else if (parovanie.uctovanie) {
          var fields = Profile.get('jednoducheUcto') ? PenaznyDennik.getUctoFields() : PokladnicnyDoklad.getUctoFields();
          var jeUctovanie = false;
          for (var i = 0; i < fields.length; i++) {
            if (parovanie.uctovanie[fields[i]+'_md'] || parovanie.uctovanie[fields[i]+'_d']) {
              jeUctovanie = true;
              break;
            }
          }
          if (jeUctovanie) {
            if (!$scope.doklad.uctovanie) {
              $scope.doklad.uctovanie = {};
            }
            for (var i = 0; i < fields.length; i++) {
              $scope.doklad.uctovanie[fields[i]+'_md'] = parovanie.uctovanie[fields[i]+'_md'];
              $scope.doklad.uctovanie[fields[i]+'_d'] = parovanie.uctovanie[fields[i]+'_d'];
              $scope.doklad.uctovanie[fields[i]+'_popis'] = parovanie.uctovanie[fields[i]+'_popis'];
            }
          } else if ($scope.doklad.operacia === 1 && Global.get('predkontacia_vf')) {
            $scope.doklad.predkontacia = Global.get('predkontacia_vf').id;
            $scope.doklad.predkontacia_data = Global.get('predkontacia_vf_data');
            $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
          }
        }
      } else {
        if (parovanie.predkontacia && parovanie.predkontacia_data.predkontacia_bd) {
          $scope.doklad.predkontacia = parovanie.predkontacia_data.predkontacia_bd;
          $scope.doklad.predkontacia_data = parovanie.predkontacia_data.predkontacia_bd_data;
          $scope.selectedPredkontacia($scope.doklad.predkontacia_data);
        }
      }
      $timeout(function() {
        $('#vypis').select();
      });
    };
    $scope.editParovaciDoklad = function(doklad) {
      Doklad.editRecord(doklad).then(function(data) {
        $scope.selectedParovanie(data);
      });
    };
    $scope.showUcty = function() {
      BankAccount.editUcty($scope.bankauctyAll).then(function(data) {
        $scope.bankaucty = _.filter(data, {active: true});
        var ucet;
        if (!(ucet = _.find($scope.bankaucty, {id: parseInt($scope.doklad.ucet)}))) {
          if ($scope.bankaucty.length) {
            $scope.doklad.ucet = $scope.bankaucty[0].id;
          } else {
            $scope.doklad.ucet = undefined;
          }
        } else {
          $scope.doklad.ucet = ucet.id;
        }
      });
    };

    if (config.predkontacie) {
      $scope.Predkontacia = Predkontacia;
      $scope.getPredkontacie = function(query) {
        return Predkontacia.get(query, DokladTypy.BD.dd, $scope.doklad.operacia+',null');
      };
      $scope.addPredkontacia = function(nazov) {
        return Predkontacia.add(nazov, DokladTypy.BD.dd, $scope.doklad.uctovanie, $scope.doklad.operacia, $scope.doklad.popis);
      };
    }
    if (config.ucty) {
      var uctyQueries = [];
      Ucet.get().then(function(ucty) {
        $scope.ucty = ucty;

        $scope.getUcty = function(query, page, parent) {
          if (query === null) {
            query = undefined;
          }
          if (query) {
            query = query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
          }
          var re = new RegExp(query, 'i');
          var matches = [];
          for (var i = 0; i < $scope.ucty.length; i++) {
            if ((!$scope.ucty[i].operacia || ($scope.ucty[i].operacia === $scope.doklad.operacia)) && (!$scope.ucty[i].only_md || ($scope.ucty[i].only_md && parent.type === 'md')) && (!$scope.ucty[i].only_d || ($scope.ucty[i].only_d && parent.type === 'd')) && ($scope.ucty[i].ucet.match(query) || $scope.ucty[i].nazov.match(re))) {
              matches.push(angular.copy($scope.ucty[i]));
            }
          }
          return matches;
        };
        var result;
        for (var i = 0; i < uctyQueries.length; i++) {
          result = $scope.getUcty(uctyQueries[i].query, uctyQueries[i].page, uctyQueries[i].parent);
          uctyQueries[i].deferred.resolve(result);
        }
      });
      $scope.getUcty = function(query, page, parent) {
        var deferred = $q.defer();
        uctyQueries.push({query: query, page: page, parent: parent, deferred: deferred});
        return deferred.promise;
      };

      $scope.addUcet = function(ucet) {
        var promise = Ucet.add(ucet, Profile.get('jednoducheUcto') ? {only_d: true, operacia: $scope.doklad.operacia} : undefined);
        promise.then(function(data) {
          $scope.ucty.push(angular.copy(data));
        });
        return promise;
      };
    }
    $scope.Mena = Mena;
    $scope.Firma = Firma;

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Profile.get('jednoducheUcto') ? PenaznyDennik : BankovyDoklad
    });
    $scope.uctovanie_suma = {};
    $scope.rozuctovanie = { valid: true };

    var layouts = {
      'bankaPohyb': _.find(Profile.get('layouts'), {layout_id: 'banka_pohyb'}),
      'bankaRozuct': _.find(Profile.get('layouts'), {layout_id: 'banka_rozuct'})
    }
    $scope.panels = [angular.isDefined(layouts.bankaPohyb) ? layouts.bankaPohyb.value : true, false, angular.isDefined(layouts.bankaRozuct) ? layouts.bankaRozuct.value : true];
    $scope.togglePanel = function(panel) {
      $scope.panels[panel] = !$scope.panels[panel];
      var layoutID = 'banka_' + (panel === 0 ? 'pohyb' : 'rozuct');
      var layout = _.find(Profile.get('layouts'), {layout_id: layoutID});
      if (!angular.isDefined(layout)) {
        Profile.get('layouts').push({layout_id: layoutID, value: $scope.panels[panel]});
      } else {
        layout.value = $scope.panels[panel];
      }
    };
    $scope.toggleMainPanel = function() {
      $scope.panels[1] = !$scope.panels[1];
      if ($scope.panels[1] && !$scope.panels[2]) {
        $scope.panels[2] = !$scope.panels[2];
      } else if (!$scope.panels[1] && !layouts.bankaRozuct.value) {
        $scope.panels[2] = layouts.bankaRozuct.value;
      }
    };

    $scope.setUplatDPH = function() {
      $scope.doklad.d_uctn = $scope.doklad.d_zdp;
    };
    $scope.selectedPredkontacia = function(predkontacia) {
      var fields = Profile.get('jednoducheUcto') ? PenaznyDennik.getUctoFields() : BankovyDoklad.getUctoFields();
      $scope.doklad.uctovanie = {};
      var foundAllUcty = true;
      for (var i = 0; i < fields.length; i++) {
        if (predkontacia[fields[i]+'_md'] && !_.find($scope.ucty, {id: predkontacia[fields[i]+'_md']})) {
          foundAllUcty = false;
        }
        if (predkontacia[fields[i]+'_d'] && !_.find($scope.ucty, {id: predkontacia[fields[i]+'_d']})) {
          foundAllUcty = false;
        }
      }
      var prekopirovatUctovanie = function() {
        for (var i = 0; i < fields.length; i++) {
          $scope.doklad.uctovanie[fields[i]+'_md'] = predkontacia[fields[i]+'_md'];
          $scope.doklad.uctovanie[fields[i]+'_d'] = predkontacia[fields[i]+'_d'];
          $scope.doklad.uctovanie[fields[i]+'_popis'] = predkontacia[fields[i]+'_popis'];
        }
      };
      // aktualizovat ucty v pamati, ak tam nejaky z predkontacie nepozname
      if (!foundAllUcty) {
        Ucet.get().then(function(ucty) {
          $scope.ucty = ucty;
          prekopirovatUctovanie();
        });
      } else {
        prekopirovatUctovanie();
      }
      if (!$scope.doklad.popis) {
        $scope.doklad.popis = predkontacia.popis;
      }
      if (predkontacia.operacia) {
        $scope.doklad.operacia = predkontacia.operacia;
      }
    };

    $scope.prevadzky = Prevadzka.restangularize(angular.copy(Profile.get('prevadzky')));
    BankAccount.objects().getList().then(function(data) {
      $scope.bankauctyAll = data;
      $scope.bankaucty = _.filter(data, {active: true});
      defaultBankovyDoklad.ucet = $scope.bankaucty.length ? $scope.bankaucty[0].id : undefined;
      if (Profile.get('lastUcet') && _.find($scope.bankaucty, {id: Profile.get('lastUcet').id})) {
        defaultBankovyDoklad.ucet = Profile.get('lastUcet').id;
      }
      if (!$scope.doklad.ucet) {
        $scope.doklad.ucet = defaultBankovyDoklad.ucet;
      }
    });

    var defaultBankovyDoklad = {
      dd: DokladTypy.BD.dd,
      d_vyst: getCurrentDate(),
      d_zdp: getCurrentDate(),
      d_uctn: getCurrentDate(),
      // uplat_dph: getCurrentDate(),
      prevadzka: Profile.get('lastPrevadzka').id || $scope.prevadzky[0].id,
      predkontacia_data: null,
      mena: null,
      sd1: Global.get('sd1'),
      sd2: Global.get('sd2'),
      rozuctovanie: [],
      operacia: 1,
      vypis: Profile.get('lastVypis')
    };

    if (!isEmpty(doklad)) {
      $scope.doklad = BankovyDoklad.copy(doklad);
      _.defaults($scope.doklad, defaultBankovyDoklad);
      $scope.prevadzky = _.filter($scope.prevadzky, {id: $scope.doklad.prevadzka});
      if ($scope.prevadzky.length) {
        $scope.currentPrevadzkaName = $scope.prevadzky[0].prevadzka;
      }
    } else {
      $scope.doklad = BankovyDoklad.restangularize(angular.copy(defaultBankovyDoklad));
      $scope.currentPrevadzkaName = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka}).prevadzka;
    }

    var fakturaDataChanged = function() {
      if ($scope.doklad.id)
        $scope.prevadzky = _.filter(Profile.get('prevadzky'), {id: $scope.doklad.prevadzka});
      else $scope.prevadzky = Profile.get('prevadzky');
      $scope.currentPrevadzkaName = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka}).prevadzka;
    };
    var fakturaPreSave = function(data) {
      $scope.doklad.d_vyst = $scope.doklad.d_zdp;
      return data;
    }
    var fakturaPostSave = function(data, created, original) {
      Profile.set('lastUcet', data.ucet);
      Profile.set('lastVypis', data.vypis);
      defaultBankovyDoklad.vypis = Profile.get('lastVypis');
      defaultBankovyDoklad.ucet = Profile.get('lastUcet');
      Profile.parseObdobie(data.d_vyst);
      if (data.faktura_field) {
        Doklad.syncChange(data.faktura_data, data.faktura_data);
      } else if (original && original.faktura_field) {
        original.faktura_data.uhradene = parseFloat(original.faktura_data.uhradene) - parseFloat(original.spolu);
        Doklad.syncChange(original.faktura_data, original.faktura_data);
      }
      return data;
    }
    var fakturaValidate = function() {
      if ($scope.doklad.uctovanie && $scope.uctovanie_suma.rozdiel !== 0) {
        alert('Rozdiel MD-D musí byť 0!');
        return false;
      }
      if ($scope.doklad.d_vyst && !Profile.checkObdobie($scope.doklad.d_vyst)) {
        if (!window.confirm('Dátum nespadá do prednastaveného obdobia!\nPokračovať?')) {
          return false;
        }
      }
      if (!isEmpty($scope.doklad.rozuctovanie)) {
        if (!$scope.rozuctovanie.valid) {
          $scope.errors.rozuctovanie = true;
          alert('Chyba v rozúčtovaní!\nRozúčtovaná musí byť plná suma.');
          return false;
        } else {
          $scope.errors.rozuctovanie = false;
        }
      }
      return true;
    };

    $scope.RecordCtrl.main.init({
      endpoint: 'list',
      model: 'doklad',
      master: doklad,
      list: list,
      defaultItem: defaultBankovyDoklad,
      focusField: 'firma',
      dataChanged: fakturaDataChanged,
      preSave: fakturaPreSave,
      postSave: fakturaPostSave,
      searchCtrl: SearchCtrl,
      validate: fakturaValidate,
      $modalInstance: $modalInstance
    });

    $scope.doplnSumyZM = function(evt) {
      if (!parseFloat($scope.doklad.kurz)) {
        return;
      }
      if (evt.target.id === 'spolu_zm') {
        $scope.doklad.spolu = Math.round( (parseFloat($scope.doklad.spolu_zm) / parseFloat($scope.doklad.kurz))* 100) / 100.0;
      } else {
        $scope.doklad.spolu_zm = Math.round( (parseFloat($scope.doklad.spolu) * parseFloat($scope.doklad.kurz))* 100) / 100.0;
      }
    };

    $scope.setPrevadzka = function(prevadzka) {
      $scope.doklad.prevadzka = prevadzka.id;
      $scope.currentPrevadzkaName = prevadzka.prevadzka;
      Profile.set('lastPrevadzka', prevadzka);
    };

    $scope.firmaPrevadzky = [];
    $scope.selectedFirma = function(firma) {
      $scope.doklad.firma_data = firma;
    };

    $scope.deleteRecord = function () {
      $scope.RecordCtrl.main.remove().then(function () {
        $modalInstance.dismiss({type: 'removed', item: doklad});
      });
    };

    $scope.ok = function () {
      var promise = $scope.RecordCtrl.main.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close(result);
        });
      }
    };

    $scope.cancel = function () {
      $scope.RecordCtrl.main.dismiss();
    };

    $scope.editPrintSettings = function() {
      var prevadzka = _.find($scope.prevadzky, {id: $scope.doklad.prevadzka});
      var modalInstance = $modal.open({
        templateUrl: 'admin/settings/printSettings.html',
        controller: 'PrintSettingsCtrl',
        backdrop: 'static',
        windowClass: 'printsettings-detail',
        resolve: {
          record: function() {
            return prevadzka;
          },
          prevadzky: function() {
            return $scope.prevadzky;
          }
        }
      });
    };

    $scope.$watch('doklad.operacia', function(val) {
      $scope.firmaLabel = val === -1 ? 'pokladna.VYPLATENE_KOMU' : 'pokladna.PRIJATE_OD';
      $timeout(function() {
        $scope.$broadcast('tooltipsRefresh');
      });
      if ($scope.doklad.predkontacia && $scope.doklad.predkontacia_data.operacia && $scope.doklad.predkontacia_data.operacia !== val) {
        $scope.doklad.predkontacia = undefined;
        $scope.doklad.predkontacia_data = undefined;
      }
      if (Profile.get('jednoducheUcto')) {
        angular.forEach($scope.doklad.uctovanie, function(value, key) {
          if (value && key.match(/\_d$/)) {
            var ucet = _.find($scope.ucty, {id: value});
            if (ucet && ucet.operacia && ucet.operacia != val) {
              delete $scope.doklad.uctovanie[key];
            }
          }
        });
      }
    });
  }]);
