'use strict';

angular.module('casist.ucto')
  .controller('PredkontacieCtrl', ['$scope', 'Restangular', 'Predkontacia', 'Profile', '$controller', '$modal', '$q', 'TabService', function ($scope, Restangular, Predkontacia, Profile, $controller, $modal, $q, TabService) {
    $scope.predkontacie = [];
    $scope.SearchCtrl = null;

    $scope.create = function(item) {
      return Predkontacia.edit(item, $scope.predkontacie, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
    var copied = Predkontacia.copy(item);
    _.each(['id'], function(field) {
      delete copied[field];
    });
    $scope.create(copied);
  };

    $scope.slideAnimation = true;

    $scope.SearchCtrl = $controller('SearchCtrl');
    $scope.typy = Predkontacia.typy();

    $scope.getTypName = function(typ) {
      return _.find($scope.typy, {id: typ}).nazov || 'Neznámy';
    };

    $scope.predkontacie = [];
    var filterChanged = function(filter) {
      var promise = Predkontacia.objects().getList(filter);
      $scope.predkontacie.loading = true;
      promise.then(function(data) {
        TabService.registerScope('predkontacie', $scope);
        $scope.predkontacie = data;
      });
      return promise;
    };
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.predkontacieTable,
      sort: {sort: 'nazov'}
    });
    if (!$scope.helperController) {
      $scope.SearchCtrl.refresh();
    }

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.predkontacieTable = [
      {  },
      { field: 'nazov', label: 'predkontacie.NAZOV', filterCollapse: 'right', filterType: 'icontains' },
      { field: 'popis', label: 'predkontacie.POPIS' },
      { field: 'typ', label: 'predkontacie.TYP', select2: { source: 'typy', lookup: 'nazov' }}
    ];
    if (!Profile.get('jednoducheUcto')) {
      $scope.predkontacieTable = _.union($scope.predkontacieTable, [
                                           { field: 'predkontacia_pd', label: 'predkontacie.PREDKONTACIA_PD'},
                                           { field: 'predkontacia_bd', label: 'predkontacie.PREDKONTACIA_BD'}
                                         ]);
    }
    $scope.predkontacieTable.push({ field: 'typ_dph', label: 'fakturacia.TYPDPH'});

    var events = Predkontacia.registerEvents(function() { return $scope.predkontacie; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Predkontacia.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });
  }]);
