'use strict';

angular.module('casist.sklad')
  .factory('Sklad', [
    'Restangular',
    '$q',
    'BaseModel',
    'BaseCollectionModel',
    'Profile',
    '$modal',
    function (
      Restangular,
      $q,
      BaseModel,
      BaseCollectionModel,
      Profile,
      $modal,
    ) {
    var ENDPOINT = "sklady";

    Restangular.extendModel(ENDPOINT, function(model) {
      angular.extend(model, BaseModel);
      return angular.extend(model, {
        validate: function() {
          var res = BaseModel.validate();
          if (!this.kod) {
            this.addError('kod', 'Pole kód je povinné.');
            res = false;
          }
          if (!this.nazov) {
            this.addError('nazov', 'Pole názov je povinné.');
            res = false;
          }
          return res;
        }
      });
    });

    var openDetail = function(item, items, SearchCtrl) {
      var modalInstance = $modal.open({
        templateUrl: 'sklad/sklady/sklady-detail.html',
        controller: 'SkladyDetailCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        keyboard: false,
        resolve: {
          item: function() {
            return item ? item : {};
          },
          items: function() {
            return items || [];
          },
          SearchCtrl: function() {
            return SearchCtrl;
          }
        }
      });
      return modalInstance.result;
    };


    var parent = BaseCollectionModel.object(ENDPOINT);
    return angular.extend(parent, {
      getPerms: function() {
        return Profile.get('perms').sklad;
      },

      updateSklady: function (sklady) {
        var prevadzka = Profile.get('lastPrevadzka');
        prevadzka.sklady = sklady;
        Profile.set('lastPrevadzka', prevadzka);
      },

      edit: function(item, list, searchCtrl) {
        var promise = $q.defer();
        if (item) {
          parent.restangularize(item);
        }
        openDetail(item, list, searchCtrl).then(function(data) {
          parent.updateSklady(list);
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      add: function(kod) {
        var obj = parent.restangularize({kod: kod});
        var promise = $q.defer();
        openDetail(obj).then(function(data) {
          promise.resolve(data.record);
        }, function() {
          promise.reject();
        });
        return promise.promise;
      },
      get: function (query) {
        return parent.objects().getList({search:query});
      }
    });
  }]);
