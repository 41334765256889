'use strict';

angular.module('casist.utils')
  .directive('recordNavigation', ['$document', '$timeout', function ($document, $timeout) {
    return {
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        var activeParent = null;
        var keyPressFc = function (e) {
          if ([33, 34, 27].indexOf(e.which) !== -1) {
            var modals = $document.find('div.modal');
            var parentModals = element.parents('div.modal');
            if (!parentModals.length) {
              throw new Error("Record navigation directive requires parent modal window.");
            }
            var topLevelZIndex = -1;
            for (var i = 0; i < modals.length; i++) {
              if (parseInt(modals[i].style.zIndex) > topLevelZIndex) {
                topLevelZIndex = parseInt(modals[i].style.zIndex);
              }
            }
            // ignore non-top-level windows
            if (topLevelZIndex !== parseInt(parentModals[0].style.zIndex)) {
              return;
            }
          }
          switch (e.which) {
            case 33:
              activeParent = $(document.activeElement).parents('div.modal-body');
              if (e.target) {
                activeParent.click();
              }
              scope.$apply(function() {
                scope.$eval(attrs.pagePrev);
              });
              e.preventDefault();
              e.stopPropagation();
              break;
            case 34:
              activeParent = $(document.activeElement).parents('div.modal-body');
              if (e.target) {
                activeParent.click();
              }
              scope.$apply(function() {
                scope.$eval(attrs.pageNext);
              });
              e.preventDefault();
              e.stopPropagation();
              break;
            case 27:
              if (angular.isDefined(attrs.pageClose)) {
                scope.$apply(function() {
                  scope.$eval(attrs.pageClose);
                });
                e.preventDefault();
                e.stopPropagation();
              }
              break;
            default:
              break;
          }
        };
        if ((angular.isDefined(attrs.pagePrev) && angular.isDefined(attrs.pageNext)) || angular.isDefined(attrs.pageClose)) {
          $document.bind('keydown', keyPressFc);

          scope.$on('$destroy', function () {
            $document.unbind('keydown', keyPressFc);
          });
        }
      }
    };
  }])
  .directive('recordWindowNavigation', ['$document', function ($document) {
    return {
      restrict: 'A',
      link: function postLink(scope, element, attrs) {
        var keyPressFc = function (e) {
          switch (e.which) {
            case 33:
              scope.$apply(function() {
                scope.$eval(attrs.pagePrev);
              });
              e.preventDefault();
              e.stopPropagation();
              break;
            case 34:
              scope.$apply(function() {
                scope.$eval(attrs.pageNext);
              });
              e.preventDefault();
              e.stopPropagation();
              break;
            case 27:
              if (angular.isDefined(attrs.pageClose)) {
                scope.$apply(function() {
                  scope.$eval(attrs.pageClose);
                });
                e.preventDefault();
                e.stopPropagation();
              }
              break;
            default:
              break;
          }
        };
        if ((angular.isDefined(attrs.pagePrev) && angular.isDefined(attrs.pageNext)) || angular.isDefined(attrs.pageClose)) {
          $document.bind('keydown', keyPressFc);

          scope.$on('$destroy', function () {
            $document.unbind('keydown', keyPressFc);
          });
        }
      }
    };
  }]);
