import 'reflect-metadata';
import 'zone.js';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import * as angular from 'angular';

setAngularJSGlobal(angular);
import { AppModule } from './app.module';

platformBrowserDynamic().bootstrapModule(AppModule);
