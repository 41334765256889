'use strict';

angular.module('casist.ucto').controller('GenericImportCtrl',
              ['$scope', '$modalInstance', 'model', 'list', '$timeout', 'Profile', 'Firma', 'BankAccount', 'Predkontacia', 'Dialog', 'DokladTypy', 'Global', 'Notification',
              function ($scope, $modalInstance, model, list, $timeout, Profile, Firma, BankAccount, Predkontacia, Dialog, DokladTypy, Global, Notification) {

    var originalList = deepcopy(list);
    $scope.page = 1;
    $scope.perPage = 10;
    $scope.total = 0;
    $scope.data = [];

    $scope.setPage = function(page) {
      $scope.page = page;
    };
    $scope.panels = [true];
    $scope.importData = {
      selected: [],
      all: []
    };
    var dataAll = [];
    var defaultData;
    model.getImportConfig().then(function(config) {
      dataAll = config.default_headers;
      defaultData = angular.copy(config);
      $scope.importData = config;
      $scope.importData.all = _.filter(config.default_headers, function(obj) { return !_.find(config.headers, {id: obj.id}); });
      $scope.importData.selected = config.headers;
    });
    $scope.addSelection = function(item) {
      $scope.importData.selected.splice($scope.importData.selected.length, 0, item);
      $scope.importData.all.splice($scope.importData.all.indexOf(item), 1);
    };
    $scope.removeSelection = function(item) {
      $scope.importData.all.splice($scope.importData.all.length, 0, _.find(dataAll, {id: item.id}));
      $scope.importData.selected.splice($scope.importData.selected.indexOf(item), 1);
    };
    $scope.setDefault = function() {
      $scope.importData.selected = angular.copy(dataAll);
      $scope.importData.all = [];
      angular.extend($scope.importData, _.pick(defaultData, 'can_create', 'can_update', 'has_header'));
    };
    $scope.resetImport = function() {
      $scope.filename = undefined;
    };

    $scope.uploadFile = function($files) {
      var importConfig = angular.copy($scope.importData);
      delete importConfig.all;
      importConfig.headers = angular.copy(importConfig.selected);
      delete importConfig.selected;
      model.import($files, importConfig).then(function(data) {
        $scope.headers = data.headers;
        $scope.data = _.union(data.data, data.not_found);
        $scope.total = $scope.data.length;
        $scope.filename = ' - '+$files[0].name;
      }, function(error) {
        Notification.add(error ? error.error || error : 'Nastala chyba pri importe.', 'danger', 10);
      });
    };

    $scope.ok = function () {
      model.importData($scope.data).then(function(importData) {
        $modalInstance.close(importData);
      }, function(error) {
        Notification.add((error && error.data) ? error.data.error || error.data : 'Nastala chyba pri importe.', 'danger', 10);
        $modalInstance.dismiss('error');
      });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
