'use strict';

angular.module('casist.ucto').controller('PokladneCtrl',
              ['$scope', '$modalInstance', '$timeout', 'list', '$modal', 'Global', '$http', '$controller', 'Profile', 'PokladnicnyDoklad', 'Doklad', 'Prevadzka', 'Firma', 'TypDPH', 'Predkontacia', '$q', 'Pohyb', 'DokladTypy', 'Pokladna', 'Ucet', 'Mena',
              function ($scope, $modalInstance, $timeout, list, $modal, Global, $http, $controller, Profile, PokladnicnyDoklad, Doklad, Prevadzka, Firma, TypDPH, Predkontacia, $q, Pohyb, DokladTypy, Pokladna, Ucet, Mena) {

    if (list) {
      $scope.pokladne = angular.copy(list);
    } else {
      $scope.pokladne = [];
      Pokladna.objects().getList().then(function(data) {
        $scope.pokladne = data;
      });
    }
    $scope.prevadzky = Prevadzka.restangularize(angular.copy(Profile.get('prevadzky')));

    $scope.RecordCtrl = $controller('RecordCtrl', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      Model: Pokladna
    });

    $scope.TableEditor = $controller('TableEditor', {
      $scope: $scope,
      Model: Pokladna,
      List: $scope.pokladne
    });

    $scope.RecordCtrl.main.init({
      endpoint: 'list',
      model: 'doklad',
      master: undefined,
      list: undefined,
      defaultItem: {},
      searchCtrl: undefined,
      $modalInstance: $modalInstance
    });

    $scope.getPrevadzkaName = function(prevadzka) {
      var prev = _.find($scope.prevadzky, {id: parseInt(prevadzka)});
      return prev ? prev.prevadzka : '?';
    };

    $scope.createPokladna = function() {
      $scope.TableEditor.startCreating('newPokladna');
      $scope.newPokladna = {prevadzka: $scope.prevadzky.length ? $scope.prevadzky[0].id : undefined, default_operacia: 1, active: true, typ: 1};
    };

    $scope.cisloChanged = function(pokladna) {
      if (pokladna.cislo_prijem && !pokladna.cislo_vydaj) {
        pokladna.default_operacia = 1;
      } else if (pokladna.cislo_vydaj && !pokladna.cislo_prijem) {
        pokladna.default_operacia = -1;
      } else {
        pokladna.default_operacia = 1;
      }
    };

    $scope.calculateIddokd = function(pokladna, field) {
      var date = new Date();
      var variable = pokladna[field] || '';
      var len = 2-variable.length;
      for (var i = 0; i < len; i++) {
        variable += 'x';
      }
      return 'PD' + String(date.getFullYear()).slice(-2) + (variable) + 'xxxxx';
    };

    $scope.ok = function () {
      var promise = $scope.TableEditor.save();
      if (promise) {
        promise.then(function(result) {
          $modalInstance.close({record: $scope.pokladne});
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

  }]);
