'use strict';

angular.module('casist.core')
  .factory('BaseCollectionModel', ['Restangular', 'Socket', 'Global', '$q', '$http', '$rootScope', 'Profile', 'TabService', function (Restangular, Socket, Global, $q, $http, $rootScope, Profile, TabService) {
    return {
      object: function(endpoint, parent) {
        var self = this;
        var endpoint_full, parent, parent_minus_one;
        if (angular.isDefined(parent)) {
          parent = parent.all(endpoint);
          parent_minus_one = parent.parentResource;
          endpoint_full = endpoint;
        } else {
          endpoint_full = endpoint;
          parent = Restangular.all(endpoint);
          parent_minus_one = null;
          if (endpoint_full.match('/')) {
            var splitted = endpoint_full.split('/');
            parent = Restangular.all(splitted[0]);
            for (var i = 1; i < splitted.length; i++) {
              parent_minus_one = parent;
              parent = parent.all(splitted[i]);
            }
            endpoint = splitted[splitted.length-1];
          }
        }
        return {
          parent: function(restangularObj) {
            return self.object(endpoint, restangularObj);
          },
          restangularize: function(obj) {
            if (!obj) {
              return obj;
            }
            Restangular.restangularizeElement(parent_minus_one, obj, endpoint);
            if (angular.isArray(obj)) {
              for (var i = 0; i < obj.length; i++) {
                Restangular.restangularizeElement(parent_minus_one, obj[i], endpoint);
              }
            }
            return obj;
          },
          stripRestangular: function(obj, additionalMethods) {
            obj = Restangular.stripRestangular(obj);
            var objList = angular.isArray(obj) ? obj : [obj];
            for (var i = 0; i < objList.length; i++) {
              delete objList[i].delete;
              delete objList[i].postSave;
              delete objList[i].update;
              delete objList[i].update_sync;
              delete objList[i].validate;
              delete objList[i].errors;
              delete objList[i].addError;
              delete objList[i].clearErrors;
              if (additionalMethods) {
                for (var j = 0; j < additionalMethods.length; j++) {
                  delete objList[i][additionalMethods[j]];
                }
              }
            }
            return obj;
          },
          copy: function(obj) {
            return Restangular.copy(obj);
          },
          getPerms: function() {
            return {};
          },
          getEndpoint: function() {
            return endpoint;
          },
          getEndpointFull: function() {
            return endpoint_full;
          },
          createBulk: function(data) {
            var self = this;
            var deferred = $q.defer();
            parent.post(data).then(function(data) {
              for (var i = 0; i < data.length; i++) {
                Restangular.restangularizeElement(null, data[i], endpoint);
                data[i].postSave();
                self.syncCreate(data[i]);
                deferred.resolve(data);
              }
            }, function(error) {
              deferred.reject(error);
            });
            return deferred.promise;
          },
          updateBulk: function(bulkData) {
            var self = this;
            var deferred = $q.defer();
            parent.customPUT(bulkData).then(function(data) {
              for (var i = 0; i < data.length; i++) {
                self.syncChange(data[i], _.find(bulkData, {id: data[i].id}));
              }
              deferred.resolve(data);
            }, function(error) {
              deferred.reject(error);
            });
            return deferred.promise;
          },
          removeBulk: function(bulkData, bulkItems) {
            var self = this;
            var deferred = $q.defer();
            parent.remove(bulkData, {'content-type': 'application/json'}).then(function() {
              for (var i = 0; i < bulkItems.length; i++) {
                self.syncDelete(bulkItems[i]);
              }
              deferred.resolve(bulkItems);
            }, function(error) {
              deferred.reject(error);
            });
            return deferred.promise;
          },
          create: function(data) {
            var self = this;
            var deferred = $q.defer();
            var obj = Restangular.restangularizeElement(null, data, endpoint);
            if (obj.validate()) {
              parent.post(data).then(function(newObj) {
                if (newObj) {
                  newObj.postSave();
                  self.syncCreate(newObj);
                }
                deferred.resolve(newObj);
              }, function(error) {
                deferred.reject(error);
              });
            } else {
              deferred.reject(angular.copy(obj.errors()));
            }
            return deferred.promise;
          },
          /**
           * Spread create change across my workspace
           * This pushes the change only to me
           * @param  obj object to push
           */
          syncCreate: function(obj) {
            Socket.emit('dataChanged', {type: endpoint, operation: 1, payload: obj});
            $rootScope.$broadcast('casist:dataChanged', {type: endpoint, operation: 1, payload: obj});
          },
          syncChange: function(obj, original) {
            var payload = {obj: obj, original: original};
            Socket.emit('dataChanged', {type: endpoint, operation: 2, payload: payload});
            $rootScope.$broadcast('casist:dataChanged', {type: endpoint, operation: 2, payload: payload});
          },
          syncDelete: function(obj) {
            Socket.emit('dataChanged', {type: endpoint, operation: 3, payload: obj});
            $rootScope.$broadcast('casist:dataChanged', {type: endpoint, operation: 3, payload: obj});
          },
          create_sync: function(obj) {
            var self = this;
            return {
              then: function(successCB, errorCB) {
                var orig = angular.copy(obj);
                Restangular.restangularizeElement(null, obj, endpoint);
                if (!obj.validate()) {
                  errorCB(obj.errors());
                  return;
                }
                var mainUrl = Global.get('serverAddress')+endpoint_full;

                var params = {
                  url: mainUrl,
                  data: JSON.stringify(orig),
                  async: false,
                  headers: {
                    'Authorization': $http.defaults.headers.common['Authorization'],
                    'X-Agenda': $http.defaults.headers.common['X-Agenda'],
                    'Content-Type': 'application/json'
                  },
                  type: "POST"
                };

                $.ajax(params).done(function(response) {
                  Restangular.restangularizeElement(null, response, endpoint);
                  response.postSave();
                  successCB(response);
                  self.syncCreate(response);
                }).fail(function(data) {
                  var response = JSON.parse(data.responseText).code;
                  if (data.status == 403 && response == "token_not_valid") {
                    var tokenConfirmed = Auth.renewTokenSync();
                    if (tokenConfirmed) {
                      params.headers['Authorization'] = $http.defaults.headers.common['Authorization'];
                      $.ajax(params).done(function(response) {
                        Restangular.restangularizeElement(null, response, endpoint);
                        response.postSave();
                        successCB(response);
                        self.syncCreate(response);
                      }).fail(function() {
                        if (errorCB)
                          errorCB();
                      });
                    } else {
                      if (errorCB)
                        errorCB(JSON.parse(data.responseText));
                    }
                  } else {
                    if (errorCB) {
                      errorCB(JSON.parse(data.responseText));
                    }
                  }
                });
              }
            }
          },
          objects: function(parent) {
            if (!angular.isDefined(parent))
              parent = parent_minus_one;
            return Restangular.service(endpoint, parent);
          },
          registerEvents: function(listGetter, listSearchGetter, createCB, changeCB, removeCB) {
            var events = [];
            var createHandler = function(data, observer) {
              if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
                return;
              }
              var list = listGetter();
              var searchCtrl = listSearchGetter();
              Restangular.restangularizeElement(parent_minus_one, data, endpoint);
              var item = _.find(list, {id: data.id});
              if (!angular.isDefined(item)) {
                if (angular.isDefined(searchCtrl)) {
                  searchCtrl.tableInsert(list, angular.copy(data), observer);
                  searchCtrl.emitSumsChange(data, null);
                } else {
                  list.push(data);
                }
              }
              if (createCB) {
                createCB();
              }
            };
            var changeHandler = function(data, observer) {
              if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
                return;
              }
              var list = listGetter();
              var searchCtrl = listSearchGetter();
              Restangular.restangularizeElement(parent_minus_one, data, endpoint);
              var item = _.find(list, {id: data.id});
              var filterStatus = searchCtrl.inFilter(data);
              if (angular.isDefined(item)) {
                if (!filterStatus.result && !data.partial_change) {
                  searchCtrl.emitSumsChange(null, item);
                  searchCtrl.tableRemove(list, item);
                } else {
                  if (filterStatus.refreshFromServer && !data.partial_change) {
                    searchCtrl.refresh(searchCtrl.getQuery());
                  } else {
                    if (!data.partial_change) {
                      searchCtrl.emitSumsChange(data, angular.copy(item));
                    }
                    copyWeakSrc(data, item);
                    searchCtrl.broadcastRefresh(item);
                  }
                }
              } else {
                if (filterStatus.result) {
                  // TODO: Iba ak sa zmeni udaj, ktory aktivny filter vyhodil zo zobrazenia (filter na mesiac, zmeni sa datum tak ze uz bude zaznam spadat do filtrovaneho mesiaca)
                  // searchCtrl.tableInsert(list, data, observer);
                  searchCtrl.emitSumsChange(data, null);
                }
              }
              if (changeCB) {
                changeCB();
              }
            };
            var removeHandler = function(data) {
              if (angular.isDefined(data.agenda) && Profile.get('agenda').id !== data.agenda) {
                return;
              }
              var list = listGetter();
              var searchCtrl = listSearchGetter();
              var item = _.find(list, {id: data.id});
              if (angular.isDefined(searchCtrl)) {
                searchCtrl.emitSumsChange(null, item);
                searchCtrl.tableRemove(list, item);
              } else {
                list.splice(list.indexOf(item), 1);
              }
              if (removeCB) {
                removeCB();
              }
            };
            events.push(Socket.on(endpoint+"Created", function(data) {
              createHandler(data, true);
            }));
            events.push(Socket.on(endpoint+"Changed", function(data) {
              changeHandler(data.obj, true);
              $rootScope.$broadcast('casist:recordChanged', data.obj);
            }));
            events.push(Socket.on(endpoint+"Removed", function(data) {
              removeHandler(data);
              $rootScope.$broadcast('casist:recordRemoved', data);
            }));
            events.push($rootScope.$on('casist:dataChanged', function(event, data) {
              if (endpoint !== data.type) {
                return;
              }
              // var tabs = TabService.getTabsByEndpoint(endpoint);
              // var suspended = _.filter(tabs, {active: false});
              switch (data.operation) {
                case 1:
                  createHandler(data.payload, false);
                  // for (var s in suspended) {
                  //   suspended[s].missed.push(function() {
                  //     createHandler(data.payload, false);
                  //   });
                  // }
                  break;
                case 2:
                  changeHandler(data.payload.obj, false);
                  // for (var s in suspended) {
                  //   suspended[s].missed.push(function() {
                  //     changeHandler(data.payload.obj, false);
                  //   });
                  // }
                  break;
                case 3:
                  removeHandler(data.payload);
                  // for (var s in suspended) {
                  //   suspended[s].missed.push(function() {
                  //     removeHandler(data.payload);
                  //   });
                  // }
                  break;
                default:
                  break;
              }
            }));
            return events;
          },
          registerCustomEvents: function(createCB, changeCB, removeCB) {
            var events = [];
            if (angular.isDefined(createCB)) {
              events.push(Socket.on(endpoint+"Created", function(data) {
                createCB(data, true);
              }));
            }
            if (angular.isDefined(changeCB)) {
              events.push(Socket.on(endpoint+"Changed", function(data) {
                changeCB(data.obj, true);
              }));
            }
            if (angular.isDefined(removeCB)) {
              events.push(Socket.on(endpoint+"Removed", function(data) {
                removeCB(data);
              }));
            }
            events.push($rootScope.$on('casist:dataChanged', function(event, data) {
              if (endpoint !== data.type) {
                return;
              }
              switch (data.operation) {
                case 1:
                  if (angular.isDefined(createCB)) {
                    createCB(data.payload, false);
                  }
                  break;
                case 2:
                  if (angular.isDefined(changeCB)) {
                    changeCB(data.payload.obj, false);
                  }
                  break;
                case 3:
                  if (angular.isDefined(removeCB)) {
                    removeCB(data.payload);
                  }
                  break;
                default:
                  break;
              }
            }));
            return events;
          },
          unregisterEvents: function(events) {
            for (var i in events) {
              events[i]();
            }
          }
        };
      }
    };
  }])
  .factory('BaseModel', ['Restangular', 'Socket', 'Global', 'Auth', '$q', '$http', '$rootScope', function (Restangular, Socket, Global, Auth, $q, $http, $rootScope) {
    var errors = {
      data: {}
    };
    return {
      /**
       * Hook called after saving of an object
       */
      postSave: function(obj) {},
      delete: function() {
        var route = this.route;
        var obj = this;
        var promise = this.remove(undefined, { 'Content-Type': 'application/json' });
        promise.then(function() {
          Socket.emit('dataChanged', {type: route, operation: 3, payload: obj});
          $rootScope.$broadcast('casist:dataChanged', {type: route, operation: 3, payload: obj});
        });
        return promise;
      },
      update: function(originalRecord) {
        var obj = this;
        var deferred = $q.defer();
        if (obj.validate()) {
          this.put().then(function(data) {
            var payload = {obj: data, original: originalRecord};
            Socket.emit('dataChanged', {type: data.route, operation: 2, payload: payload});
            $rootScope.$broadcast('casist:dataChanged', {type: data.route, operation: 2, payload: payload});
            deferred.resolve(data);
          }, function(error) {
            deferred.reject(error)
          });
        } else {
          deferred.reject(obj.errors());
        }
        return deferred.promise;
      },
      update_sync: function(originalRecord) {
        var self = this;
        return {
          then: function(successCB, errorCB) {
            if (!self.validate()) {
              errorCB(self.errors());
              return;
            }
            var mainUrl = Global.get('serverAddress')+self.route;
            if (self.id)
              mainUrl += "/"+self.id;

            var params = {
              url: mainUrl,
              async: false,
              headers: {
                'Authorization': $http.defaults.headers.common['Authorization'],
                'X-Agenda': $http.defaults.headers.common['X-Agenda'],
                'Content-Type': 'application/json'
              }
            };
            params.data = JSON.stringify(Restangular.stripRestangular(angular.copy(self)));
            params.type = "PUT";
            var payload = {obj: self, original: originalRecord};

            $.ajax(params).done(function(response) {
              successCB(response);
              Socket.emit('dataChanged', {type: self.route, operation: 2, payload: payload});
              $rootScope.$broadcast('casist:dataChanged', {type: self.route, operation: 2, payload: payload});
            }).fail(function(data) {
              if (data.status == 403 && JSON.parse(data.responseText).code == "token_not_valid") {
                var tokenConfirmed = Auth.renewTokenSync();
                if (tokenConfirmed) {
                  params.headers['Authorization'] = $http.defaults.headers.common['Authorization'];
                  $.ajax(params).done(function(response) {
                    successCB(response);
                    Socket.emit('dataChanged', {type: self.route, operation: 2, payload: payload});
                    $rootScope.$broadcast('casist:dataChanged', {type: self.route, operation: 2, payload: payload});
                  }).fail(function() {
                    if (errorCB)
                      errorCB();
                  });
                } else {
                  if (errorCB)
                    errorCB(JSON.parse(data.responseText));
                }
              } else {
                if (errorCB)
                  errorCB(JSON.parse(data.responseText));
              }
            });
          }
        }
      },
      validate: function() {
        this.clearErrors();
        return true;
      },
      errors: function() {
        return errors;
      },
      addError: function(field, message) {
        if (!angular.isDefined(errors.data[field]))
          errors.data[field] = [];
        errors.data[field].push(message);
      },
      clearErrors: function() {
        errors.data = {};
      }
    };
  }]);
